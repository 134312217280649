// Vendors
import { find, kebabCase, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'

// Functions
import { useRole } from 'hooks/useRole'

// Components
import { DrawerRoleNew } from './DrawerRoleNew'
import { FieldToggle } from 'components/FieldToggle'
import { RequirementDocuments } from 'components/FormRequirementDocumentsFields'

// Styles
import { Grid, Stack, Heading, GridItem, useDisclosure } from '@chakra-ui/react'

// Types
import { Role } from 'types/Role'
import { useFeedback } from 'contexts/feedback'

type UserPermissionsProps = {
  id: string
  userRoles: Role[]
}

export const UserPermissions = (props: UserPermissionsProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id, userRoles } = props

  const { t } = useTranslation('common')
  const { feedback } = useFeedback()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { roles, enable, disable, setUserRole } = useRole()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [roleOptions, setRoleOptions] = useState<Role[]>([])
  const [roleUpdated, setRoleUpdated] = useState<Role>({} as Role)
  const [missingDocuments, setMissingDocuments] = useState<
    RequirementDocuments[]
  >([])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!userRoles || !roles) return

    const roleUser = map(userRoles, 'roleUser[0]')

    const formattedRole = roles.map((role) => {
      const roleExistsInUser = find(roleUser, { roleId: role.id })

      if (roleExistsInUser) {
        return { ...role, status: roleExistsInUser.status }
      }

      return { ...role, status: 'DISABLED' }
    })

    setRoleOptions(formattedRole)
  }, [userRoles, roles])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const changeStatusState = useCallback(
    (roleId, status) => {
      const newRoleOptions = roleOptions.map((role) => {
        if (role.id === roleId) return { ...role, status }

        return role
      })

      setRoleOptions(newRoleOptions)
    },
    [roleOptions]
  )

  const onChangeStatusRole = useCallback(
    async (event: ChangeEvent<HTMLInputElement>, role: Role) => {
      try {
        const { checked } = event.target

        if (checked) {
          setRoleUpdated(role)

          const response = await setUserRole(id, role.slug)

          if (!response) return

          if (response.requiredRequirements.length) {
            setMissingDocuments(response.requiredRequirements)
            onOpen()
            return
          }

          await enable(id, role.id)
          changeStatusState(role.id, 'ENABLED')
        } else {
          await disable(id, role.id)
          changeStatusState(role.id, 'DISABLED')
        }
      } catch {
        feedback({
          title: 'Erro ao ativar role',
          description: 'Não foi possível ativar role',
          status: 'error',
        })
      }
    },
    [setUserRole, id, enable, changeStatusState, onOpen, disable, feedback]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Stack spacing="4">
      <DrawerRoleNew
        userId={id}
        role={roleUpdated}
        title="Adicionar role"
        disclosure={{ isOpen, onClose }}
        onChangeStatusState={changeStatusState}
        missingDocumentsDefault={missingDocuments}
      />

      <Heading fontSize="xl" lineHeight="6">
        Permissões de usuário
      </Heading>

      <Grid
        gap="10"
        templateColumns={{ base: 'repeat(4, 1fr)', xl: 'repeat(8, 1fr)' }}
      >
        {roleOptions.map((role) => {
          return (
            <GridItem colSpan={2} key={role.id}>
              <FieldToggle
                size="md"
                id={role.id}
                name={role.id}
                labelDirection="right"
                label={t(`roles.${kebabCase(role.name)}`)}
                isChecked={role.status === 'ENABLED'}
                onChange={(e) => onChangeStatusRole(e, role)}
              />
            </GridItem>
          )
        })}
      </Grid>
    </Stack>
  )
}
