// Vendors
import React, { useCallback, useMemo } from 'react'
import { IconType } from 'react-icons'

// Styles
import {
  Text,
  Badge,
  Image,
  TextProps,
  BadgeProps,
  StyleProps,
  AspectRatio,
  Box,
} from '@chakra-ui/react'
import { formatDate } from 'utils/formatDate'

// Interfaces
export enum ListTableRenderAs {
  'TEXT' = 'TEXT',
  'MULTILINE_TEXT' = 'MULTILINE_TEXT',
  'BADGE' = 'BADGE',
  'DATE' = 'DATE',
  'LIST_USERS' = 'LIST_USERS',
  'ICON' = 'ICON',
  'IMAGE' = 'IMAGE',
}

const CompanyType = {
  'testfy-base': 'Testfy Base',
  logistics: 'Logistica',
  clinic: 'Clínica',
  laboratory: 'Laboratório',
} as const

export const StatusRecollect = {
  REQUESTED_RECOLLECT: 'Recoleta solicitada',
  ATTENDED_RECOLLECT: 'Recoleta atendida',
}

export const StatusExpedition = {
  SENT: 'enviado',
  DELIVERED: 'entregue',
  IN_TRANSIT: 'em trânsito',
  IN_EXPEDITION: 'em expedição',
  SHIPPING_ALERT: 'alerta de envio',
} as const

const StatusReverseLogistic = {
  IN_ANALYSIS: 'Em Análise',
  KIT_ACTIVATED: 'Kit Ativado',
  KIT_DENIED: 'Amostra Negada',
  SAMPLE_DENIED: 'Amostra Negada',
  KIT_ACCEPTED: 'Amostra Aprovada',
  REPORT_GENERATED: 'Laudo Entregue',
  DIVERGENT_DATA: 'Dados Divergentes',
  PENDING_ANALYSIS: 'Análise Pendente',
  KIT_RECEIVED: 'Recebido em Laboratório',
  PENDING_REVERSE_CODE: 'Logística Reversa Pendente',
} as const

const StatusContractSignature = {
  CONTRACT_PENDING: 'Pendente',
  CONTRACT_SIGNED: 'Assinado',
} as const

const StatusPurchase = {
  WAITING_PAYMENT: 'Aguardando Pagamento',
  REFUSED: 'Recusado',
  PAID: 'Pagamento Aprovado',
  CANCELED: 'Cancelado',
} as const

const StatusBlog = {
  DRAFT: 'Rascunho',
  REVISION: 'Revisão',
  PUBLISHED: 'Publicado',
  TRASH: 'Lixeira',
} as const

export const ListTableStatuses = {
  ACTIVE: 'Ativo',
  DISABLED: 'Inativo',
  ENABLED: 'Ativo',
  PENDING_RESET_PASSWORD: 'Aguardando redefinição de senha',
  PENDING_ACTIVATION: 'Aguardando ativação',
  PENDING_DOCUMENTS: 'Aguardando documentos',
  ...StatusExpedition,
  ...StatusReverseLogistic,
  ...StatusBlog,
  ...CompanyType,
  ...StatusPurchase,
  ...StatusRecollect,
  ...StatusContractSignature,
} as const

enum BadgeStylesEnum {
  RED = 'RED',
  GREY = 'GREY',
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
}

type StylesType = Record<BadgeStylesEnum, StyleProps>

/*
  |-----------------------------------------------------------------------------
  | Text
  |-----------------------------------------------------------------------------
  |
  |
  */
const ListTableRowText = (props: TextProps) => {
  return <Text whiteSpace="nowrap" {...props} />
}

/*
  |-----------------------------------------------------------------------------
  | MultilineText
  |-----------------------------------------------------------------------------
  |
  |
  */
const ListTableRowMultilineText = (props: TextProps) => {
  return <Text {...props} />
}

/*
  |-----------------------------------------------------------------------------
  | Date
  |-----------------------------------------------------------------------------
  |
  |
  */
const ListTableRowDate = ({ children, ...rest }: TextProps) => {
  return <Text {...rest}>{formatDate(String(children), 'dd/MM/yyyy')}</Text>
}

/*
  |-----------------------------------------------------------------------------
  | Badges
  |-----------------------------------------------------------------------------
  |
  |
  */
const ListTableRowBadge = (props: BadgeProps) => {
  const status = props.children
  const styles: StylesType = useMemo(() => {
    return {
      GREEN: { color: '#0F6F87', bg: '#D1FBEE', borderRadius: 2 },
      RED: { bg: '#FFE5D9', color: '#931538', borderRadius: 2 },
      GREY: { colorScheme: 'gray', borderRadius: 2 },
      BLUE: { bg: '#CFEDFF', color: '#043193', borderRadius: 2 },
      YELLOW: { bg: '#FEFBCB', color: '#786400', borderRadius: 2 },
    }
  }, [])

  const renderStatus = useCallback(
    (styles: StyleProps, statusName?: string) => {
      return (
        <Badge {...styles} {...props}>
          {statusName}
        </Badge>
      )
    },
    [props]
  )

  const renderStatusByType = useMemo(() => {
    return {
      CONTRACT_PENDING: renderStatus(
        styles.RED,
        ListTableStatuses.CONTRACT_PENDING
      ),
      CONTRACT_SIGNED: renderStatus(
        styles.GREEN,
        ListTableStatuses.CONTRACT_SIGNED
      ),
      REQUESTED_RECOLLECT: renderStatus(
        styles.BLUE,
        ListTableStatuses.REQUESTED_RECOLLECT
      ),
      ATTENDED_RECOLLECT: renderStatus(
        styles.GREEN,
        ListTableStatuses.ATTENDED_RECOLLECT
      ),
      SHIPPING_ALERT: renderStatus(
        styles.RED,
        ListTableStatuses.SHIPPING_ALERT
      ),
      CANCELED: renderStatus(styles.RED, ListTableStatuses.CANCELED),
      WAITING_PAYMENT: renderStatus(
        styles.YELLOW,
        ListTableStatuses.WAITING_PAYMENT
      ),
      PENDING_DOCUMENTS: renderStatus(
        styles.YELLOW,
        ListTableStatuses.PENDING_DOCUMENTS
      ),
      PAID: renderStatus(styles.GREEN, ListTableStatuses.PAID),
      REFUSED: renderStatus(styles.RED, ListTableStatuses.REFUSED),
      TRASH: renderStatus(styles.RED, ListTableStatuses.TRASH),
      DRAFT: renderStatus(styles.BLUE, ListTableStatuses.DRAFT),
      REVISION: renderStatus(styles.YELLOW, ListTableStatuses.REVISION),
      PUBLISHED: renderStatus(styles.GREEN, ListTableStatuses.PUBLISHED),
      SENT: renderStatus(styles.BLUE, ListTableStatuses.SENT),
      KIT_DENIED: renderStatus(styles.RED, ListTableStatuses.KIT_DENIED),
      IN_TRANSIT: renderStatus(styles.YELLOW, ListTableStatuses.IN_TRANSIT),
      DELIVERED: renderStatus(styles.GREEN, ListTableStatuses.DELIVERED),
      KIT_ACTIVATED: renderStatus(
        styles.GREEN,
        ListTableStatuses.KIT_ACTIVATED
      ),
      KIT_RECEIVED: renderStatus(styles.BLUE, ListTableStatuses.KIT_RECEIVED),
      IN_ANALYSIS: renderStatus(styles.YELLOW, ListTableStatuses.IN_ANALYSIS),
      KIT_ACCEPTED: renderStatus(styles.GREEN, ListTableStatuses.KIT_ACCEPTED),
      IN_EXPEDITION: renderStatus(styles.BLUE, ListTableStatuses.IN_EXPEDITION),
      REPORT_GENERATED: renderStatus(
        styles.GREEN,
        ListTableStatuses.REPORT_GENERATED
      ),
      PENDING_ACTIVATION: renderStatus(
        styles.YELLOW,
        ListTableStatuses.PENDING_ACTIVATION
      ),
      PENDING_REVERSE_CODE: renderStatus(
        styles.YELLOW,
        ListTableStatuses.PENDING_REVERSE_CODE
      ),
      ENABLED: renderStatus(styles.GREEN, ListTableStatuses.ENABLED),
      DISABLED: renderStatus(styles.RED, ListTableStatuses.DISABLED),
      ACTIVE: renderStatus(styles.GREEN, ListTableStatuses.ACTIVE),
      PENDING_RESET_PASSWORD: renderStatus(
        styles.YELLOW,
        ListTableStatuses.PENDING_RESET_PASSWORD
      ),
      SAMPLE_DENIED: renderStatus(styles.RED, ListTableStatuses.SAMPLE_DENIED),
      DIVERGENT_DATA: renderStatus(
        styles.RED,
        ListTableStatuses.DIVERGENT_DATA
      ),

      'testfy-base': renderStatus(styles.RED, ListTableStatuses['testfy-base']),
      logistics: renderStatus(styles.RED, ListTableStatuses.logistics),
      clinic: renderStatus(styles.RED, ListTableStatuses.clinic),
      laboratory: renderStatus(styles.RED, ListTableStatuses.laboratory),
    }
  }, [renderStatus, styles.BLUE, styles.GREEN, styles.RED, styles.YELLOW])

  if (!props) return <></>

  const renderComponent =
    renderStatusByType[status as keyof typeof renderStatusByType]

  if (!renderComponent) return <>{renderStatus(styles.RED, status as string)}</>

  return renderComponent
}

/*
  |-----------------------------------------------------------------------------
  | Icon
  |-----------------------------------------------------------------------------
  |
  |
  */
const ListTableRowIcon = (props: { icon: IconType; children: any }) => {
  const { icon: Icon, children, ...rest } = props

  return (
    <Icon
      color={children ? '#f47d2e' : 'none'}
      fill={children ? '#f47d2e' : 'none'}
      {...rest}
    />
  )
}

/*
  |-----------------------------------------------------------------------------
  | Icon
  |-----------------------------------------------------------------------------
  |
  |
  */

const ListTableRowImage = (props: any) => {
  const { children, handleIsOpen, ...rest } = props

  return (
    <AspectRatio w="20" ratio={16 / 9}>
      {children ? (
        <Image
          borderRadius="6px"
          src={children}
          onClick={() => !!handleIsOpen && handleIsOpen(children)}
          {...rest}
        />
      ) : (
        <Box />
      )}
    </AspectRatio>
  )
}

/*
  |-----------------------------------------------------------------------------
  | Exports.
  |-----------------------------------------------------------------------------
  |
  |
  */
export const ListTableRowComponents = {
  TEXT: ListTableRowText,
  MULTILINE_TEXT: ListTableRowMultilineText,
  DATE: ListTableRowDate,
  BADGE: ListTableRowBadge,
  ICON: ListTableRowIcon,
  IMAGE: ListTableRowImage,
}
