// Vendors
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, useCallback, useMemo } from 'react'

// Functions
import { useError } from './useError'
import { apiPut } from 'services/put'
import { apiShow } from 'services/get'
import { apiPost } from 'services/post'
import { apiPatch } from 'services/patch'
import { apiDelete } from 'services/delete'
import { OptionsParser, parserForm } from 'utils/parser'

// Styles
import { useToast } from '@chakra-ui/react'

// Types
type ListRecord<T> = {
  endpoint: string | { public: string; private: string }
  currentId: string
  optionsParser?: OptionsParser<T>[]
}

export type RecordResponse<T, K> = {
  current: T
  update: (newData: K) => Promise<void>
  disable: () => Promise<void>
  enable: () => Promise<void>
  delete: () => Promise<void>
  reload: () => void
  clear: () => void
  upload: (
    file: File,
    endpointUpload: string,
    options?: object,
    httpMethod?: 'POST' | 'PATCH'
  ) => Promise<void>
}

type EditRecordResponse<T, K> = {
  record: RecordResponse<T, K>
}

export function useEditRecords<T, K = T>(
  props: ListRecord<T>
): EditRecordResponse<T, K> {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { endpoint, currentId, optionsParser } = props
  const { handleError } = useError()
  const { t } = useTranslation('common')
  const history = useHistory()
  const toast = useToast({ position: 'top-right' })

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const httpMethods = useMemo(
    () => ({
      POST: apiPost,
      PATCH: apiPatch,
    }),
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [record, setRecord] = useState<T>({} as T)

  const publicEndpoint = useMemo(() => {
    if (typeof endpoint === 'string') return endpoint
    return endpoint.public
  }, [endpoint])

  const privateEndpoint = useMemo(() => {
    if (typeof endpoint === 'string') return endpoint
    return endpoint.private
  }, [endpoint])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */
  const showRecord = useCallback(() => {
    apiShow<T>(`${publicEndpoint}/${currentId}`).then((payload) => {
      if (!payload) return
      setRecord(optionsParser ? parserForm(payload, optionsParser) : payload)
    })
  }, [publicEndpoint, currentId, optionsParser])
  const deleteRecord = useCallback(async () => {
    try {
      await apiDelete<T>(`${privateEndpoint}/${currentId}`)
      history.goBack()
      toast({
        title: t('toast.success.default', { type: t('toast.success.delete') }),
        status: 'success',
      })
    } catch (error: unknown) {
      if (!axios.isAxiosError(error)) {
        console.trace(error)
        return
      }

      if (!error.response) {
        console.trace(error)
        return
      }

      handleError(error)
    }
  }, [privateEndpoint, currentId, history, toast, t, handleError])

  const disableRecord = useCallback(async () => {
    await apiPatch(`${privateEndpoint}/${currentId}/disable`)
  }, [privateEndpoint, currentId])

  const enableRecord = useCallback(async () => {
    await apiPatch(`${privateEndpoint}/${currentId}/enable`)
  }, [privateEndpoint, currentId])

  const reloadRecord = useCallback(() => {
    showRecord()
  }, [showRecord])

  const updateRecord = useCallback(
    async (newData: K) => {
      if (!newData) return

      try {
        await apiPut(`${privateEndpoint}/${currentId}`, newData)
      } catch (error: any) {
        switch (error.response.status) {
          case 422: {
            const errorHandled = handleError(error, undefined, { 422: true })

            if (errorHandled && errorHandled instanceof Array) {
              throw errorHandled
            } else {
              handleError(error)
            }

            break
          }
          default: {
            handleError(error)

            console.trace(error)
            throw new Error()
          }
        }
      }
    },
    [privateEndpoint, currentId, handleError]
  )

  const clear = useCallback<() => void>(() => {
    setRecord({} as T)
  }, [])

  const uploadFile = useCallback(
    async (
      file: File,
      endpointUpload: string,
      options?: object,
      httpMethod?: 'POST' | 'PATCH'
    ) => {
      const formData = new FormData()
      const httpMethodApi = httpMethods[httpMethod || 'POST'] as any

      Object.entries(options || {}).forEach(([key, value]) => {
        formData.append(key, value)
      })

      formData.append('file', file)

      await httpMethodApi(endpointUpload, formData)
    },
    [httpMethods]
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    showRecord()
  }, [showRecord])

  /*
  |-----------------------------------------------------------------------------
  | Return
  |-----------------------------------------------------------------------------
  |
  |
  */
  return {
    record: {
      current: record,
      delete: deleteRecord,
      disable: disableRecord,
      enable: enableRecord,
      update: updateRecord,
      reload: reloadRecord,
      clear: clear,
      upload: uploadFile,
    },
  }
}
