// Vendors
import React from 'react'
import { get } from 'lodash'

// Functions
import formatCurrency from 'utils/formatCurrency'

// Components
import { CardDetails } from 'components/CardDetails'

// Styles
import { Link, Stack } from '@chakra-ui/react'
import { PaymentMethodType } from '../types'
import { ListTableStatuses } from 'components/ListTableRowComponent'

// Types
type TabDeliveryProps = {
  paymentLink: string | null
  paymentMethod: PaymentMethodType
  billingData: any
  paymentStatus: string
}

export const TabPayment = (props: TabDeliveryProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { paymentLink, billingData, paymentMethod, paymentStatus } = props
  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  function defineMethod() {
    if (billingData?.boletoUrl) {
      return 'Boleto'
    } else if (billingData?.pixQrCode) {
      return 'Pix'
    } else {
      return 'Cartão de Crédito'
    }
  }
  return (
    <Stack spacing="8">
      <CardDetails
        title="Informações de pagamento"
        data={[
          {
            label: 'Método',
            value: paymentMethod?.name ?? '-',
          },
          {
            label: 'Status do pagamento',
            value: get(ListTableStatuses, paymentStatus?.toUpperCase()) ?? '-',
          },
          {
            label: 'Forma de pagamento',
            value: billingData?.card?.brand
              ? String(billingData?.card?.brand) +
                ` xxxx.xxxx.xxxx.${billingData?.card?.lastDigits}`
              : defineMethod(),
          },
          {
            label: 'Valor transação',
            value: billingData?.amount
              ? formatCurrency(billingData.amount / 100) +
                ` em ${billingData.installments}x sem juros`
              : formatCurrency(0),
          },
          {
            label: 'ID da transação',
            value: billingData?.id ?? '-',
          },
          {
            label: 'authid',
            value: billingData?.authorizationCode ?? '-',
          },
          {
            label: 'nsu',
            value: billingData?.nsu ?? '-',
          },
          {
            label: 'Link de pagemento',
            value: paymentLink ? (
              <Link href={paymentLink} isExternal>
                {paymentLink}
              </Link>
            ) : (
              '-'
            ),
          },
        ]}
      />
    </Stack>
  )
}
