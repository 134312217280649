// Vendors
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'

// Functions
import { useCreateRecord } from 'hooks/useCreateRecord'
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FieldText } from 'components/FieldText'
import { FieldDate } from 'components/FieldDate'
import { FormFooter } from 'components/FormFooter'
import { FieldToggle } from 'components/FieldToggle'
import { FieldNumber } from 'components/FieldNumber'
import { FieldSelect } from 'components/FieldSelect'
import { FieldTextArea } from 'components/FieldTextArea'

// Schemas
import { schema } from '../schema'

// Styles
import { Flex, useToast, Grid, GridItem } from '@chakra-ui/react'
import fieldDateRangePickerStyles from 'components/FieldDateRangePicker/styles'

// Types
import { CouponNewForm, CouponNewCreateRecord } from '../types'
import { Product } from 'types/Product'

import { optionsDiscountType } from '../../selects'
import { get } from 'lodash'
import { useCan } from 'hooks/useCan'
import { RULES } from '../rules'

type TabCouponNewProps = {
  endpoint: string
}

export const TabCouponNew = (props: TabCouponNewProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { options: optionsProducts, isLoading } = useSelectParser<Product>({
    accessor: { value: 'id', label: 'name' },
    endpoint: 'public/store_product',
  })

  const selectAssigned = useSelectParser<{ userId: string; name: string }>({
    accessor: { value: 'userId', label: 'name' },
    endpoint: 'app/influencer',
  })

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props

  const { can } = useCan({ rules: RULES })

  const toast = useToast({ position: 'top-right' })
  const { t } = useTranslation('common')
  const history = useHistory()
  const { create } = useCreateRecord<CouponNewCreateRecord>({
    endpoint,
  })

  const {
    watch,
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema(t, can)),
  })

  const watchIsEligibleAllStoreProducts = watch('isEligibleAllStoreProducts')
  const watchValidFrom = watch('validFrom')

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = useCallback(
    async (data: CouponNewForm) => {
      try {
        await create(data)
        history.goBack()
        toast({
          title: t('toast.success.default', {
            type: t('common:toast.success.create'),
          }),
          status: 'success',
        })

        history.push('/coupons')
      } catch (error: unknown) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [create, history, setError, t, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex
      as="form"
      h="full"
      flexDirection="column"
      justifyContent="space-between"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="status"
        control={control}
        defaultValue={true}
        render={({ field: { onChange, value, ref, name } }) => (
          <FieldToggle
            name={name}
            position="absolute"
            top={-16}
            right={0}
            ref={ref}
            isChecked={value}
            onChange={onChange}
            error={errors.status}
          />
        )}
      />
      <Grid
        sx={{ ...fieldDateRangePickerStyles }}
        my="4"
        gap="10"
        templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(4, 1fr)' }}
      >
        <GridItem colSpan={{ lg: 2 }}>
          <FieldText
            label="Nome"
            maxLength={120}
            currentLength={get(watch('name'), 'length')}
            error={errors.name}
            isRequired
            {...register('name')}
          />
        </GridItem>

        <GridItem>
          <FieldText
            canModify={false}
            maxLength={45}
            currentLength={get(watch('voucherCode'), 'length')}
            label="Código do voucher"
            error={errors.voucherCode}
            isRequired
            {...register('voucherCode')}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="discountType"
            control={control}
            render={({ field }) => (
              <FieldSelect
                canModify={false}
                label="Selecione a forma de desconto"
                error={errors.discountType}
                options={optionsDiscountType}
                {...field}
              />
            )}
          />
        </GridItem>

        <GridItem>
          <FieldNumber
            canModify={false}
            control={control}
            name="discountPercentage"
            max={!can.discountPercentage50 ? 50 : 15}
            label="Porcentagem de desconto (%)"
            placeholder="Para valor zero, não preencher."
            error={errors.discountPercentage}
          />
        </GridItem>

        <GridItem>
          <FieldNumber
            canModify={false}
            control={control}
            name="discountPrice"
            label="Preço de desconto"
            error={errors.discountPrice}
            placeholder="Para valor zero, não preencher."
          />
        </GridItem>

        <GridItem colSpan={{ lg: 2 }}>
          <FieldTextArea
            canModify={false}
            label="Descrição"
            maxLength={120}
            currentLength={get(watch('description'), 'length')}
            error={errors.description}
            isRequired
            {...register('description')}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="validFrom"
            control={control}
            render={({ field }) => (
              <FieldDate
                canModify={false}
                label="Válido de"
                name={field.name}
                selectsStart
                selected={field.value}
                startDate={field.value}
                endDate={watch('validUntil')}
                onChange={field.onChange}
                error={errors.validFrom}
                isRequired
                autoComplete="off"
              />
            )}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="validUntil"
            control={control}
            render={({ field }) => (
              <FieldDate
                canModify={false}
                label="Válido até"
                minDate={watchValidFrom}
                name={field.name}
                selectsEnd
                selected={field.value}
                startDate={watch('validFrom')}
                endDate={field.value}
                onChange={field.onChange}
                error={errors.validUntil}
                autoComplete="off"
              />
            )}
          />
        </GridItem>

        <GridItem>
          <FieldNumber
            canModify={false}
            label="Uso máximo por cliente"
            error={errors.maximumClientUsage}
            control={control}
            name="maximumClientUsage"
          />
        </GridItem>

        <GridItem>
          <FieldNumber
            canModify={false}
            control={control}
            name="maximumUsage"
            label="Uso máximo do cupom"
            error={errors.maximumUsage}
          />
        </GridItem>

        <GridItem colSpan={{ lg: 2 }}>
          <Controller
            control={control}
            name="isEligibleAllStoreProducts"
            render={({ field: { name, value, onChange } }) => (
              <FieldToggle
                canModify={false}
                label="Aplicável a todos os produtos da loja"
                name={name}
                isChecked={value}
                onChange={onChange}
                error={errors.isEligibleAllStoreProducts}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={{ lg: 2 }}>
          <Controller
            name="storeProductsIds"
            control={control}
            render={({ field }) => (
              <FieldSelect
                canModify={false}
                isDisabled={watchIsEligibleAllStoreProducts}
                label="Produtos vinculados"
                error={errors.storeProductsIds}
                isMulti
                options={optionsProducts}
                isLoading={isLoading}
                isRequired={!watchIsEligibleAllStoreProducts}
                {...field}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={{ lg: 2 }}>
          <Controller
            name="assignedUserId"
            control={control}
            render={({ field }) => (
              <FieldSelect
                canModify={false}
                label="Atribuir cupom ao usuário"
                error={errors.assignedUserId}
                options={selectAssigned.options}
                isLoading={selectAssigned.isLoading}
                {...field}
              />
            )}
          />
        </GridItem>
      </Grid>

      <FormFooter isSubmitting={isSubmitting} hasAlert />
    </Flex>
  )
}
