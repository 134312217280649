const fieldDateRangePickerStyles = {
  '.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name':
    {
      margin: '0',
    },
  '.react-datepicker__day--in-range': {
    bg: 'blue.300',
    color: 'gray.700',
    borderRadius: '0',
  },
  '.react-datepicker__day--selected': {
    bg: 'blue.500',
    color: 'white',
    borderRadius: '0.3rem',
  },
  '.react-datepicker__day--range-start.react-datepicker__day--in-range': {
    bg: 'blue.500',
    color: 'white',
    borderRadius: '0.3rem 0 0 0.3rem',
  },
  '.react-datepicker__day--range-end.react-datepicker__day--in-range': {
    bg: 'blue.500',
    color: 'white',
    borderRadius: '0 0.3rem 0.3rem 0',
  },
  '.react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--in-range':
    {
      bg: 'blue.500',
      color: 'white',
      borderRadius: '0.3rem',
    },
  '.react-datepicker__day--in-selecting-range': {
    bg: 'blue.300',
    color: 'gray.700',
    borderRadius: '0',
  },
  '.react-datepicker__day--selecting-range-start': {
    bg: 'blue.500',
    color: 'white',
    borderRadius: '0.3rem 0 0 0.3rem',
  },
}

export default fieldDateRangePickerStyles
