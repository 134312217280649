import { get } from 'lodash'
import { format } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'

import { apiDelete } from 'services/delete'

import { useError } from 'hooks/useError'
import { useTimer } from 'hooks/useTimer'
import { useDownload } from 'hooks/useDownload'

import { useConfirmationDialog } from 'contexts/confirmationDialog'

import { HistoryUpdate } from '../AccordionItem'

import { Box, Text, Flex, Heading, Button, Stack } from '@chakra-ui/react'

type AccordionContentItemProps = {
  reload: () => void
  historyUpdate: HistoryUpdate
}

export const AccordionContentItem = (props: AccordionContentItemProps) => {
  const { reload, historyUpdate } = props

  const { durationConverted, isRunning } = useTimer({
    date: new Date(historyUpdate.updatedAt),
    duration: {
      minutes: 5,
    },
  })

  const { getConfirmation } = useConfirmationDialog()
  const { handleError } = useError()
  const { onDownload } = useDownload()

  const [isDownloading, setIsDownloading] = useState(false)

  const addPad = (text: string) => {
    return text.padStart(2, '0')
  }

  const handleDownload = useCallback(async () => {
    try {
      setIsDownloading(true)

      const attachmentHistoryFileId = get(
        historyUpdate,
        'attachmentHistoryFile[0].id'
      )
      const attachmentHistoryFileName = get(
        historyUpdate,
        'attachmentHistoryFile[0].originalFileName'
      )

      const endpoint = `/app/history_update/${historyUpdate.id}/file/${attachmentHistoryFileId}/download`

      await onDownload(endpoint, attachmentHistoryFileName, undefined, {
        handleError: false,
      })
    } catch (error) {
      handleError(error)
    } finally {
      setIsDownloading(false)
    }
  }, [handleError, onDownload, historyUpdate])

  const handleDelete = useCallback(async () => {
    const { id } = historyUpdate

    const isConfirmed = await getConfirmation({
      title: 'Alerta de exclusão',
      message: (
        <Text>
          Você tem certeza que deseja excluir este item?
          <Text as="strong"> Esta ação não pode ser desfeita.</Text>
        </Text>
      ),
    })

    if (isConfirmed) {
      try {
        await apiDelete(`/app/history_update/${id}/note`)

        reload()
      } catch (error) {
        handleError(error)
      }
    }
  }, [getConfirmation, handleError, reload, historyUpdate])

  const RenderCountdown = useCallback(() => {
    const { hours, minutes, seconds } = durationConverted

    const hoursText = addPad(String(hours))
    const minutesText = addPad(String(minutes))
    const secondsText = addPad(String(seconds))

    return (
      <Text as="span" color="gray.500">
        {hours !== 0 && `${hoursText}:`}
        {minutesText}:{secondsText}
      </Text>
    )
  }, [durationConverted])

  const attachmentHistoryFileTitle = useMemo(() => {
    return get(historyUpdate, 'attachmentHistoryFile[0].title')
  }, [historyUpdate])

  return (
    <Flex justifyContent="space-between" flex="1">
      <Box ml={4}>
        <Heading as="h3" fontSize={16} fontWeight={700} mb="2">
          {historyUpdate.title || historyUpdate.description}
        </Heading>

        {historyUpdate.comments && <Text>{historyUpdate.comments}</Text>}

        {attachmentHistoryFileTitle && (
          <Text>{attachmentHistoryFileTitle}</Text>
        )}

        <Text fontSize="sm" mt="2">
          {historyUpdate.origin === 'AUTO'
            ? 'Gerado automaticamente'
            : historyUpdate.updatedByPersonalDataName}{' '}
          <Text as="span" color="gray.500">
            {format(
              new Date(historyUpdate.updatedAt),
              "'-' dd/MM/yyyy 'às' HH:mm'h'"
            )}
          </Text>
          {isRunning && (
            <Stack d="inline" isInline ml="2">
              <Text as="span" color="gray.500">
                -
              </Text>
              <Button variant="link" color="gray.500" onClick={handleDelete}>
                Deletar
              </Button>
              <RenderCountdown />
            </Stack>
          )}
        </Text>
      </Box>

      {historyUpdate.attachmentHistoryFile?.length > 0 && (
        <Button
          size="sm"
          variant="outline"
          colorScheme="blue"
          onClick={handleDownload}
          isLoading={isDownloading}
        >
          Baixar anexo
        </Button>
      )}
    </Flex>
  )
}
