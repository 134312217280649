import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

// Custom Route
import { Route } from 'routes/Route'

// Development Pages
import PageEmpty from 'pages/development/PageEmpty'
import PageRecord from 'pages/development/PageRecord'
import { PageListRecords } from 'pages/development/PageListRecords'

// Public Pages
import Login from 'pages/public/Login'
import ResetPassword from 'pages/public/ResetPassword'
import ForgotPassword from 'pages/public/ForgotPassword'
import ActivateAndReset from 'pages/public/ActivateAndResetPassword'

// Private Pages
import { PageRecollectNewRecord } from 'pages/private/Recollect/new'
import { PageRecollectShowRecord } from 'pages/private/Recollect/show'
import { PageRecollectListRecords } from 'pages/private/Recollect/list'
import { PageClinicListRecords } from 'pages/private/Clinic/list'
import { PageAuditListRecords } from 'pages/private/Audit/list'
import { PageSellerListRecords } from 'pages/private/Seller/list'
import { PageSellerNewRecord } from 'pages/private/Seller/new'
import { PageSellerShowRecord } from 'pages/private/Seller/show'
import { PageMotoboyListRecords } from 'pages/private/Motoboy/list'
import { PageSalesCategoryNewRecord } from 'pages/private/SalesCategory/new'
import { PageSalesCategoryShowRecord } from 'pages/private/SalesCategory/show'
import { PageSalesCategoryListRecords } from 'pages/private/SalesCategory/list'
import Dashboard from 'pages/_private/Dashboard'
import Page404 from 'pages/private/404'
import Page500 from 'pages/private/500'

import { PageSampleTransportKitShowRecord } from 'pages/private/SampleTransportKit/show'
import { PageUserShowRecord } from 'pages/private/User/show'
import { PageCouponRecord } from 'pages/private/Coupon/show'
import { PageClientShowRecord } from 'pages/private/Client/show'
import { PageProductShowRecord } from 'pages/private/Product/show'
import { PageInfluencerShowRecord } from 'pages/private/Influencer/show'
import { PageProfessionalShowRecord } from 'pages/private/Professional/show'
import { PageLaboratorialAnalysisShowRecord } from 'pages/private/LaboratorialAnalysis/show'

import { PageSampleTransportKitListRecords } from 'pages/private/SampleTransportKit/list'
import { PageUserListRecords } from 'pages/private/User/list'
import { PageCouponListRecords } from 'pages/private/Coupon/list'
import { PageClientListRecords } from 'pages/private/Client/list'
import { PageProductListRecords } from 'pages/private/Product/list'
import { PageCompanyListRecords } from 'pages/private/Company/list'
import { PageInfluencerListRecords } from 'pages/private/Influencer/list'
import { PageProfessionalListRecords } from 'pages/private/Professional/list'
import { PageLaboratorialAnalysisListRecords } from 'pages/private/LaboratorialAnalysis/list'

import { PageSampleTransportKitNewRecord } from 'pages/private/SampleTransportKit/new'
import { PageCouponNewRecord } from 'pages/private/Coupon/new'
import { PageClientNewRecord } from 'pages/private/Client/new'
import { PageProductNewRecord } from 'pages/private/Product/new'
import { PageInfluencerNewRecord } from 'pages/private/Influencer/new'
import { PageProfessionalNewRecord } from 'pages/private/Professional/new'
import { PageLaboratorialAnalysisNewRecord } from 'pages/private/LaboratorialAnalysis/new'

import { PageBlogNewRecord } from 'pages/private/Blog/new'
import { PageBlogShowRecord } from 'pages/private/Blog/show'
import { PageBlogListRecords } from 'pages/private/Blog/list'

import { PageUserAdminNewRecord } from 'pages/private/User/UserAdmin/new'
import { PageUserSellerNewRecord } from 'pages/private/User/UserSeller/new'
import { PageUserMotoboyNewRecord } from 'pages/private/User/UserMotoboy/new'
import { PageUserFinancialNewRecord } from 'pages/private/User/UserFinancial/new'
import { PageUserBackofficeNewRecord } from 'pages/private/User/UserBackoffice/new'
import { PageUserExpeditionNewRecord } from 'pages/private/User/UserExpedition/new'
import { PageUserContentManagerNewRecord } from 'pages/private/User/UserContentManager/new'
import { PageUserLaboratoryAgentNewRecord } from 'pages/private/User/UserLaboratoryAgent/new'
import { PageUserLogisticsManagerNewRecord } from 'pages/private/User/UserLogisticsManager/new'
import { PageUserLaboratoryManagerNewRecord } from 'pages/private/User/UserLaboratoryManager/new'

import { PageCompanyBaseTestfyNewRecord } from 'pages/private/Company/CompanyTestfyBase/new'
import { PageCompanyBaseTestfyShowRecord } from 'pages/private/Company/CompanyTestfyBase/show'

import { PageCompanyClinicNewRecord } from 'pages/private/Company/CompanyClinic/new'
import { PageCompanyClinicShowRecord } from 'pages/private/Company/CompanyClinic/show'

import { PageCompanyLogisticNewRecord } from 'pages/private/Company/CompanyLogistics/new'
import { PageCompanyLogisticShowRecord } from 'pages/private/Company/CompanyLogistics/show'

import { PageCompanyLaboratoryNewRecord } from 'pages/private/Company/CompanyLaboratory/new'
import { PageCompanyLaboratoryShowRecord } from 'pages/private/Company/CompanyLaboratory/show'

import { PageCategoryNewRecord } from 'pages/private/Category/new'
import { PageCategoryShowRecord } from 'pages/private/Category/show'
import { PageCategoryListRecords } from 'pages/private/Category/list'

import { KitCheckIn } from 'pages/private/Laboratorial/KitCheckIn'
import { PageLaboratoryShowRecord } from 'pages/private/Laboratorial/show'
import { PageLaboratoryListRecords } from 'pages/private/Laboratorial/list'
import { PageCheckInShowRecord } from 'pages/private/Laboratorial/KitCheckIn/show'
import { DashboardExpedition } from 'pages/_private/Dashboard/DashboardExpedition'

import { PageInvoiceListRecords } from 'pages/private/Financial/list'

// Types
import { UserRolesNames } from 'types/User'
import { PageCorreiosListRecords } from 'pages/private/Correios/list'
import { PageCorreiosShowRecord } from 'pages/private/Correios/show'
import { PageOrdersListRecords } from 'pages/private/Orders/list'
import { PageOrderShowRecord } from 'pages/private/Orders/show'
import { DashboardBackoffice } from 'pages/_private/Dashboard/DashboardBackoffice'
import { PageCheckout } from 'pages/private/Checkout'
import { CartContextProvider } from 'contexts/cart'
import { PageKitsListRecords } from 'pages/private/Kits/list'
import { PageKitsShowRecord } from 'pages/private/Kits/show'
import { PageNotificationsListRecords } from 'pages/private/Notification/list'
import { PageProspectListRecords } from 'pages/private/Prospect/list'
import { PageContractListRecords } from 'pages/private/Contracts/list'

export const Routes = () => (
  <Switch>
    {/*
    |-----------------------------------------------------------------------------
    | Public Routes
    |-----------------------------------------------------------------------------
    |
    |
    */}
    <Route
      path="/forgotpassword"
      component={ForgotPassword}
      isPrivate={false}
    />
    <Route
      path="/accounts/activation_and_reset_password"
      component={ActivateAndReset}
      isPrivate={false}
    />
    <Route path="/accounts/reset" component={ResetPassword} isPrivate={false} />
    <Route path="/login" component={Login} isPrivate={false} />

    {/*
    |-----------------------------------------------------------------------------
    | Private Routes
    |-----------------------------------------------------------------------------
    |
    |
    */}

    <Route
      path="/notifications"
      component={PageNotificationsListRecords}
      allowedRoles={[UserRolesNames.BACKOFFICE]}
    />

    {/* Plop routes here  */}
    <Route
      path="/recollects"
      component={PageRecollectListRecords}
      allowedRoles={[UserRolesNames.ADMIN, UserRolesNames.BACKOFFICE]}
    />

    <Route
      path="/recollects/new"
      component={PageRecollectNewRecord}
      allowedRoles={[UserRolesNames.ADMIN, UserRolesNames.BACKOFFICE]}
    />

    <Route
      path="/recollects/:id"
      component={PageRecollectShowRecord}
      allowedRoles={[UserRolesNames.ADMIN, UserRolesNames.BACKOFFICE]}
    />

    <Route
      path="/recollects"
      component={PageRecollectListRecords}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    <Route
      path="/clinics/new"
      component={PageCompanyClinicNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    <Route
      path="/clinics/:id"
      component={PageCompanyClinicShowRecord}
      allowedRoles={[UserRolesNames.ADMIN, UserRolesNames.FINANCIAL]}
    />

    <Route
      path="/clinics"
      component={PageClinicListRecords}
      allowedRoles={[UserRolesNames.ADMIN, UserRolesNames.FINANCIAL]}
    />

    <Route
      path="/kits/:id"
      component={PageKitsShowRecord}
      allowedRoles={[UserRolesNames.ADMIN, UserRolesNames.BACKOFFICE]}
    />

    <Route
      path="/kits"
      component={PageKitsListRecords}
      allowedRoles={[UserRolesNames.ADMIN, UserRolesNames.BACKOFFICE]}
    />

    <Route
      path="/invoices"
      component={PageInvoiceListRecords}
      allowedRoles={[UserRolesNames.ADMIN, UserRolesNames.FINANCIAL]}
    />
    <Route path="/audits" component={PageAuditListRecords} allowedRoles={[]} />

    <Route
      path="/sellers/new"
      component={PageSellerNewRecord}
      allowedRoles={[]}
    />

    <Route
      path="/sellers/:id"
      component={PageSellerShowRecord}
      allowedRoles={[]}
    />

    <Route
      path="/sellers"
      component={PageSellerListRecords}
      allowedRoles={[]}
    />

    <Route
      path="/motoboy"
      component={PageMotoboyListRecords}
      allowedRoles={[]}
    />

    {/* Sales category */}

    <Route
      path="/sales-category/new"
      component={PageSalesCategoryNewRecord}
      allowedRoles={[]}
    />

    <Route
      path="/sales-category/:id"
      component={PageSalesCategoryShowRecord}
      allowedRoles={[]}
    />

    <Route
      path="/sales-category"
      component={PageSalesCategoryListRecords}
      allowedRoles={[]}
    />

    {/* Checkout */}

    <Route
      path="/checkout"
      component={PageCheckout}
      provider={CartContextProvider}
      allowedRoles={[]}
    />

    {/* Dashboard */}
    <Route
      path="/dashboard"
      component={DashboardExpedition}
      allowedRoles={[]}
    />
    <Route
      path="/dashboard"
      component={DashboardBackoffice}
      allowedRoles={[]}
    />
    <Route path="/dashboard" component={Dashboard} allowedRoles={[]} />
    <Route path="/404" component={Page404} allowedRoles={[]} />
    <Route path="/500" component={Page500} allowedRoles={[]} />

    {/* Laboratory */}
    <Route
      path="/laboratory/kit-check-in/:id"
      component={PageCheckInShowRecord}
      allowedRoles={[
        UserRolesNames.ADMIN,
        UserRolesNames.LABORATORY_MANAGER,
        UserRolesNames.LABORATORY_AGENT,
      ]}
    />
    <Route
      path="/laboratory/kit-check-in"
      component={KitCheckIn}
      allowedRoles={[
        UserRolesNames.ADMIN,
        UserRolesNames.LABORATORY_MANAGER,
        UserRolesNames.LABORATORY_AGENT,
      ]}
    />
    <Route
      path="/laboratory/:id"
      component={PageLaboratoryShowRecord}
      allowedRoles={[
        UserRolesNames.ADMIN,
        UserRolesNames.LABORATORY_MANAGER,
        UserRolesNames.LABORATORY_AGENT,
      ]}
    />
    <Route
      path="/laboratory"
      component={PageLaboratoryListRecords}
      allowedRoles={[
        UserRolesNames.ADMIN,
        UserRolesNames.LABORATORY_MANAGER,
        UserRolesNames.LABORATORY_AGENT,
      ]}
    />
    {/* Correios */}
    <Route
      path="/correios/:id"
      component={PageCorreiosShowRecord}
      allowedRoles={[
        UserRolesNames.ADMIN,
        UserRolesNames.LOGISTIC_MANAGER,
        UserRolesNames.EXPEDITION,
      ]}
    />
    <Route
      path="/correios"
      component={PageCorreiosListRecords}
      allowedRoles={[
        UserRolesNames.ADMIN,
        UserRolesNames.LOGISTIC_MANAGER,
        UserRolesNames.EXPEDITION,
      ]}
    />

    {/* Pedidos */}
    <Route
      path="/orders/:id"
      component={PageOrderShowRecord}
      allowedRoles={[
        UserRolesNames.ADMIN,
        UserRolesNames.BACKOFFICE,
        UserRolesNames.FINANCIAL,
        UserRolesNames.EXPEDITION,
      ]}
    />
    <Route
      path="/orders"
      component={PageOrdersListRecords}
      allowedRoles={[
        UserRolesNames.ADMIN,
        UserRolesNames.BACKOFFICE,
        UserRolesNames.FINANCIAL,
        UserRolesNames.EXPEDITION,
      ]}
    />

    {/* Category */}
    <Route
      path="/category/new"
      component={PageCategoryNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/category/:id"
      component={PageCategoryShowRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/category"
      component={PageCategoryListRecords}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    {/* Sales: Coupons */}
    <Route
      path="/coupons/new"
      component={PageCouponNewRecord}
      allowedRoles={[
        UserRolesNames.ADMIN,
        UserRolesNames.SELLER,
        UserRolesNames.FINANCIAL,
        UserRolesNames.BACKOFFICE,
      ]}
    />
    <Route
      path="/coupons/:id"
      component={PageCouponRecord}
      allowedRoles={[
        UserRolesNames.ADMIN,
        UserRolesNames.SELLER,
        UserRolesNames.FINANCIAL,
        UserRolesNames.BACKOFFICE,
      ]}
    />
    <Route
      path="/coupons"
      component={PageCouponListRecords}
      allowedRoles={[
        UserRolesNames.ADMIN,
        UserRolesNames.SELLER,
        UserRolesNames.FINANCIAL,
        UserRolesNames.BACKOFFICE,
      ]}
    />

    {/* Sales: Influencers */}
    <Route
      path="/influencer/new"
      component={PageInfluencerNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/influencer/:id"
      component={PageInfluencerShowRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/influencers"
      component={PageInfluencerListRecords}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    {/* Records: Users */}

    {/* <Route
      path="/users/professionals/new"
      component={PageProfessionalShowRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    /> */}
    {/* <Route
      path="/users/professionals/:id"
      component={PageProfessionalShowRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    /> */}
    <Route
      path="/users/admin/new"
      component={PageUserAdminNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    <Route
      path="/users/backoffice/new"
      component={PageUserBackofficeNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    <Route
      path="/users/expedition/new"
      component={PageUserExpeditionNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    <Route
      path="/users/finance/new"
      component={PageUserFinancialNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    <Route
      path="/users/logistic-manager/new"
      component={PageUserLogisticsManagerNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    <Route
      path="/users/logistic-agent/new"
      component={PageUserMotoboyNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    <Route
      path="/users/seller/new"
      component={PageUserSellerNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    <Route
      path="/users/laboratory-manager/new"
      component={PageUserLaboratoryManagerNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    <Route
      path="/users/laboratory-agent/new"
      component={PageUserLaboratoryAgentNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    <Route
      path="/users/content-manager/new"
      component={PageUserContentManagerNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    <Route path="/users/:id" component={PageUserShowRecord} allowedRoles={[]} />
    <Route
      path="/users/"
      component={PageUserListRecords}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    {/* Records: Partners (Companies) */}
    <Route
      path="/company/testfy-base/new"
      component={PageCompanyBaseTestfyNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/company/testfy-base/:id"
      component={PageCompanyBaseTestfyShowRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    <Route
      path="/company/clinic/new"
      component={PageCompanyClinicNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/company/clinic/:id"
      component={PageCompanyClinicShowRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/company/logistics/new"
      component={PageCompanyLogisticNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/company/logistics/:id"
      component={PageCompanyLogisticShowRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/company/laboratory/new"
      component={PageCompanyLaboratoryNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/company/laboratory/:id"
      component={PageCompanyLaboratoryShowRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    {/* ? */}
    {/* <Route
      path="/company/transport/new"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    /> */}

    {/* ? */}
    {/* <Route
      path="/companies/transport/:id"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/companies/clinic/new"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/companies/clinic/:id"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/companies/testfy/new"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/companies/testfy/:id"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    /> */}
    <Route
      path="/companies"
      component={PageCompanyListRecords}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    {/* Records: Professionals */}
    <Route
      path="/professionals/new"
      component={PageProfessionalNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/professionals/:id"
      component={PageProfessionalShowRecord}
      allowedRoles={[UserRolesNames.ADMIN, UserRolesNames.BACKOFFICE]}
    />
    <Route
      path="/professionals"
      component={PageProfessionalListRecords}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    {/* Records: Lab Analysis */}
    <Route
      path="/laboratorial_analysis/new"
      component={PageLaboratorialAnalysisNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/laboratorial_analysis/:id"
      component={PageLaboratorialAnalysisShowRecord}
      allowedRoles={[UserRolesNames.ADMIN, UserRolesNames.BACKOFFICE]}
    />
    <Route
      path="/laboratorial_analysis"
      component={PageLaboratorialAnalysisListRecords}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    {/* Records: Kits */}
    <Route
      path="/sample_transport_kit/new"
      component={PageSampleTransportKitNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/sample_transport_kit/:id"
      component={PageSampleTransportKitShowRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/sample_transport_kit"
      component={PageSampleTransportKitListRecords}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    {/* Records: Products */}
    <Route
      path="/products/new"
      component={PageProductNewRecord}
      allowedRoles={[UserRolesNames.ADMIN, UserRolesNames.SELLER]}
    />
    <Route
      path="/products/:id"
      component={PageProductShowRecord}
      allowedRoles={[UserRolesNames.ADMIN, UserRolesNames.SELLER]}
    />
    <Route
      path="/products"
      component={PageProductListRecords}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    {/* Records: Services */}
    {/* <Route
      path="/services/new"
      component={}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/services/:id"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/services"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    /> */}

    {/* Records: Clients */}
    <Route
      path="/clients/new"
      component={PageClientNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/clients/:id"
      component={PageClientShowRecord}
      allowedRoles={[UserRolesNames.ADMIN, UserRolesNames.BACKOFFICE]}
    />
    <Route
      path="/clients"
      component={PageClientListRecords}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    {/* Records: Prospects */}
    <Route
      path="/prospects"
      component={PageProspectListRecords}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    {/* Records: Contracts */}
    <Route
      path="/contracts"
      component={PageContractListRecords}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    {/* Records: Blog */}

    <Route
      path="/blog/new"
      component={PageBlogNewRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/blog/:id"
      component={PageBlogShowRecord}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/blog"
      component={PageBlogListRecords}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    {/* Reports */}
    <Route
      path="/reports/orders"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN, UserRolesNames.FINANCIAL]}
    />
    <Route
      path="/reports/revenue"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/reports/motoboy"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/reports/mail"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/reports/salesbyproduct"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/reports/salesbycoupon"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/reports/returns"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    />
    <Route
      path="/reports"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    {/* Configuration */}
    <Route
      path="/sysconfig"
      component={PageEmpty}
      allowedRoles={[UserRolesNames.ADMIN]}
    />

    {/*
    |-----------------------------------------------------------------------------
    | Development Routes
    |-----------------------------------------------------------------------------
    |
    |
    */}

    <Route path="/genericrecord/:id" component={PageRecord} />
    <Route path="/genericrecord" component={PageRecord} />
    <Route path="/genericrecordslist" component={PageListRecords} />

    {/*
    |-----------------------------------------------------------------------------
    | Fallback Routes
    |-----------------------------------------------------------------------------
    |
    |
    */}

    <Redirect to="/login" />
  </Switch>
)
