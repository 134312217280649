// Vendors
import { map, orderBy } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo } from 'react'

// Functions
import { useError } from 'hooks/useError'
import { apiPatch } from 'services/patch'
import { formatDate } from 'utils/formatDate'
import formatCurrency from 'utils/formatCurrency'
import { useListRecords } from 'hooks/useListRecords'
import { useConfirmationDialog } from 'contexts/confirmationDialog'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ModalAssingId } from 'components/ModalAssignId'
import { ListPagination } from 'components/ListPagination'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'
import {
  Stack,
  useToast,
  Text,
  useDisclosure,
  Select,
  HStack,
} from '@chakra-ui/react'

// Types
import { Motoboy } from './types'
import { PurchaseHasProductType } from 'pages/private/Correios/show/types'

import { PageMetaSetting } from './meta'
import { useFeedback } from 'contexts/feedback'

export const PageMotoboyListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('motoboy')
  const { getConfirmation } = useConfirmationDialog()
  const toast = useToast({ position: 'top-right' })
  const { feedback } = useFeedback()

  const { handleError } = useError()
  const methodsAssignId = useForm()
  const disclosureAssignId = useDisclosure()

  const { endpoint, title } = PageMetaSetting
  const { records, query, pagination } = useListRecords<Motoboy>({
    endpoint,
  })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    records.list.forEach((record) => {
      if (!record.status) {
        record.status = record.purchaseStatus.toUpperCase()
      }

      record.kitsAmount = String(record.purchaseHasProducts.length)

      record.purchaseTypeFormatted = record.isCobranding
        ? 'COBRANDING'
        : record.purchaseType

      record.createdAtFormatted = formatDate(record.createdAt, 'dd/MM/yyyy')
      record.priceFormatted = formatCurrency(+record.price)
      record.buyerName = record.clinicName || record.buyerName
    })
  }, [records.list])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const listTableHeaders = useMemo(
    () => [
      {
        label: t('table.headers.purchaseNumber'),
        accessor: 'purchaseNumber',
      },
      {
        label: 'Nome do destinatário',
        accessor: 'buyerName',
      },
      {
        label: t('table.headers.createdAt'),
        accessor: 'createdAtFormatted',
      },
      {
        label: t('table.headers.kitsAmount'),
        accessor: 'kitsAmount',
        render: { as: ListTableRenderAs.MULTILINE_TEXT },
      },
      {
        label: t('table.headers.price'),
        accessor: 'priceFormatted',
      },
      {
        label: t('table.headers.status'),
        accessor: 'status',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
      {
        label: t('table.headers.paymentMethodName'),
        accessor: 'paymentMethodName',
      },
      {
        label: t('table.headers.purchaseOrigin'),
        accessor: 'purchaseOrigin',
        render: {
          as: ListTableRenderAs.TEXT,
          options: {
            textTransform: 'uppercase',
          },
        },
      },
      {
        label: '',
        accessor: 'purchaseTypeFormatted',
        render: {
          as: ListTableRenderAs.TEXT,
          options: {
            textTransform: 'uppercase',
          },
        },
      },
    ],
    [t]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleOpenAssignId = useCallback(
    (_, item: Motoboy) => {
      const purchaseHasProducts = orderBy(
        item.purchaseHasProducts.map((purchaseHasProduct) => ({
          ...purchaseHasProduct,
          activationCode: '',
        })),
        ['storeProduct.mktTitle'],
        ['asc']
      )

      methodsAssignId.setValue('activationCodes', purchaseHasProducts)
      disclosureAssignId.onOpen()
    },
    [disclosureAssignId, methodsAssignId]
  )

  const handleAssignId = useCallback(
    async (data: { activationCodes: PurchaseHasProductType[] }) => {
      const { activationCodes } = data

      const purchaseHasProductsFormatted = map(activationCodes, (item) => ({
        purchaseHasProductId: item.id,
        activationCode: item.activationCode,
      }))

      try {
        await apiPatch('/app/purchase_product/activation_code', {
          activationCodes: purchaseHasProductsFormatted,
        })

        toast({
          title: 'Sucesso',
          description: 'Códigos de ativação alterado com sucesso!',
          status: 'success',
        })

        disclosureAssignId.onClose()
      } catch (error) {
        handleError(error)
      }
    },
    [disclosureAssignId, handleError, toast]
  )

  const handleChangeStatus = useCallback(
    async (_, item: Motoboy, status: 'SENT' | 'DELIVERED' | 'IN_TRANSIT') => {
      try {
        const isConfirmed = await getConfirmation({
          title: 'Alerta de alteração de status',
          message: (
            <Text>
              Você tem certeza que deseja alterar o status do pedido?
              <Text as="strong"> Esta ação não pode ser desfeita.</Text>
            </Text>
          ),
        })

        if (!isConfirmed) return

        await apiPatch(
          `/app/expedition/${item.purchaseDeliveryId}/motoboy/status`,
          {
            status,
          }
        )

        toast({
          title: 'Sucesso',
          description: 'Status atualizado com sucesso!',
          status: 'success',
        })

        records.reload()
      } catch (error: any) {
        handleError(error)
      }
    },
    [handleError, getConfirmation, records, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={title}>
      <FormProvider {...methodsAssignId}>
        <ModalAssingId
          isOpen={disclosureAssignId.isOpen}
          onCancel={disclosureAssignId.onClose}
          onConfirm={methodsAssignId.handleSubmit(handleAssignId)}
        />
      </FormProvider>

      <Stack
        spacing="4"
        justify="space-between"
        direction={{ base: 'column', md: 'row' }}
      >
        <ListFilters query={query}>
          <HStack>
            <Select
              size="sm"
              placeholder="Status..."
              onChange={(e) => {
                query.set({
                  ...query.current,
                  status: e.target.value,
                })
              }}
            >
              <option selected value="IN_EXPEDITION">
                Em expedição
              </option>
              <option value="SENT">Enviado</option>
            </Select>

            <Select
              size="sm"
              onChange={(e) =>
                query.set({ ...query.current, sort: e.target.value })
              }
            >
              <option value="-purchaseNumber">Número do pedido</option>
              <option value="-updatedAt">Mais recentes</option>
            </Select>
          </HStack>
        </ListFilters>
      </Stack>

      <ListTable
        records={records}
        defaultActions="none"
        headers={listTableHeaders}
        actions={[
          {
            type: 'alterShippingMethod',
            options: {
              reload: records.reload,
              showWhen: { status: 'IN_EXPEDITION' },
            },
          },
          {
            type: 'assignId',
            options: {
              callback: handleOpenAssignId,
              showWhen: { status: 'IN_EXPEDITION' },
            },
          },
          {
            type: 'delivery',
            options: {
              callback: (e, item) => handleChangeStatus(e, item, 'SENT'),
              showWhen: { status: 'IN_EXPEDITION' },
            },
          },
          {
            type: 'delivered',
            options: {
              callback: (e, item) => handleChangeStatus(e, item, 'DELIVERED'),
              showWhen: (item) => {
                const { status } = item
                return status === 'SENT' || status === 'IN_TRANSIT'
              },
            },
          },
        ]}
      />

      <ListPagination pagination={pagination} query={query} />
    </LayoutLoggedPageList>
  )
}
