import React from 'react'
import { get } from 'lodash'

import { Comment } from '../Comment'
import { HistoryUpdate, TypeHistoryUpdate } from '../AccordionItem'

import { Box, Divider, Flex, VStack } from '@chakra-ui/react'
import { AccordionContentItem } from '../AccordionContentItem'
import { iconsMap } from '../AccordionContentItem/iconsMap'

type AccordionContentProps = {
  productId?: string
  reload: () => void
  type?: TypeHistoryUpdate
  historyUpdates: HistoryUpdate[]
}

export const AccordionContent = (props: AccordionContentProps) => {
  const { type, reload, productId, historyUpdates } = props

  return (
    <VStack>
      {historyUpdates.map((historyUpdate, index) => {
        const historyUpdateType = get(historyUpdate, 'type')
        let Icon

        if (historyUpdateType) {
          const iconMap = get(iconsMap, historyUpdateType)

          Icon = get(iconMap, 'icon')
        }

        return (
          <Flex
            py={5}
            px={10}
            w="full"
            key={historyUpdate.id}
            bg={index % 2 !== 0 ? '#F7FAFC' : 'white'}
          >
            <Box position="relative">
              <Flex
                w={8}
                h={8}
                color="white"
                align="center"
                justify="center"
                fontWeight="bold"
                bgColor="gray.500"
                borderRadius="full"
              >
                {Icon ? <Icon /> : index + 1}
              </Flex>

              {index !== historyUpdates.length - 1 && (
                <Box
                  w="2px"
                  my="12px"
                  height="40px"
                  bgColor="#E2E8F0"
                  position="absolute"
                  css={{
                    left: '15px',
                  }}
                />
              )}
            </Box>

            <AccordionContentItem
              reload={reload}
              historyUpdate={historyUpdate}
            />
          </Flex>
        )
      })}

      <Divider my="1.5rem !important" />

      {type && productId && (
        <Comment type={type} reload={reload} productId={productId} />
      )}
    </VStack>
  )
}
