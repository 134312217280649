import React, { useCallback } from 'react'
import { get } from 'lodash'

import { ModalCancelIssuedInvoice } from 'components/ModalCancelIssuedInvoice'

import { useDisclosure } from '@chakra-ui/react'

import { ActionProps } from './types'
import { WrapperAction } from './WrapperAction'
import { HiReceiptRefund } from 'react-icons/hi'

export const CancelIssuedInvoiceAction = <T extends unknown>({
  record,
  options,
}: ActionProps<T>) => {
  const modalCancelIssuedInvoiceDisclosure = useDisclosure()

  const { callback, actionType = 'menu', reload, ...optionsRest } = options

  if (!reload) {
    throw new Error('reload is required')
  }

  const cta = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, data: T) => {
      modalCancelIssuedInvoiceDisclosure.onOpen()

      if (callback) {
        callback(e, data)
      }
    },
    [modalCancelIssuedInvoiceDisclosure, callback]
  )

  return (
    <>
      <ModalCancelIssuedInvoice
        reload={reload}
        purchaseId={get(record, 'id')}
        isOpen={modalCancelIssuedInvoiceDisclosure.isOpen}
        onCancel={modalCancelIssuedInvoiceDisclosure.onClose}
      />

      <WrapperAction
        cta={cta}
        record={record}
        options={optionsRest}
        icon={HiReceiptRefund}
        actionType={actionType}
        label={'Cancelar nota fiscal emitida'}
      />
    </>
  )
}
