// Vendors
import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { validate } from 'uuid'

// Styles and Icons
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  LightMode,
  Button,
  useColorModeValue as mode,
  useToast,
} from '@chakra-ui/react'

// Components
import { Logo } from 'components/Logo'

// Hooks
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'

// Services
import api from 'services/api'
import { FieldText } from 'components/FieldText'
import { schema } from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFeedback } from 'contexts/feedback'

// Types
type Inputs = {
  password: string
  confirmPassword: string
}

/*
|-----------------------------------------------------------------------------
| Page
|-----------------------------------------------------------------------------
|
|
*/

const ActivationAndResetPassword = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('common')

  const {
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema(t)),
  })
  const toast = useToast()
  const { feedback } = useFeedback()

  const { push } = useHistory()
  const query = new URLSearchParams(useLocation().search)
  const resetToken = query.get('reset_token')
  const activationToken = query.get('activation_token')

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [invalidToken, setInvalidToken] = React.useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<Inputs> = React.useCallback(
    (formData) => {
      api
        .patch('/public/person/activate_and_reset_password', {
          ...formData,
          resetPasswordToken: resetToken,
          accountActivationToken: activationToken,
        })
        .then(() => {
          toast({
            title: 'Conta ativada',
            description: 'Agora você já pode fazer login.',
            status: 'success',
          })

          push('/')
        })
        .catch((error) => {
          console.error(error)
          if (error.response.status === 400) {
            setInvalidToken(true)
            return
          }

          if (
            error.response.data.errors &&
            error.response.data.errors instanceof Array
          ) {
            error.response.data.errors.forEach((fieldError) => {
              setError(fieldError.field, { message: fieldError.message })
            })
          }

          feedback({
            title: 'Atenção',
            description: 'Não foi possível executar a operação',
            status: 'error',
          })
        })
    },
    [activationToken, feedback, push, resetToken, setError, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    /**
     * Check if both tokens are provided and have good shape.
     */
    if (
      !resetToken ||
      !activationToken ||
      !validate(resetToken) ||
      !validate(activationToken)
    ) {
      toast({
        title: 'Dados inválidos',
        description: 'Acesse novamente o link recebido por e-mail',
        status: 'warning',
      })

      push('/')
    }
  }, [activationToken, push, resetToken, toast])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex minH="100vh" direction={{ base: 'column', md: 'row' }}>
      <Box
        display={{ base: 'none', md: 'block' }}
        maxW={{ base: '20rem', lg: '40rem' }}
        flex="1"
        backgroundImage="url('/login-bg.jpg')"
        backgroundSize="cover"
        color="white"
        px="10"
        py="8"
      >
        <Box mb="16">
          <Logo w="auto" h="14" iconColor="white" />
        </Box>
      </Box>
      <Flex
        flex="1"
        align="center"
        px={{ base: '10', md: '40' }}
        backgroundImage={{ base: "url('login-bg.jpg')", md: '' }}
        backgroundSize="cover"
      >
        <Box maxW="xl" w={{ base: '100%', md: 'auto' }}>
          <Box textAlign={{ base: 'center', md: 'left' }}>
            <Logo
              display={{ md: 'none' }}
              mb="16"
              w="auto"
              h="14"
              iconColor="white"
              mx="auto"
            />
            <Heading
              color={{ base: 'white', md: 'teal.400' }}
              as="h1"
              size="2xl"
              fontWeight="extrabold"
              letterSpacing="tight"
            >
              {t('authentication.activation_title')}
            </Heading>
            <Text
              mt="3"
              fontSize={{ base: 'xl', md: '3xl' }}
              fontWeight="bold"
              color={{ base: 'white', md: 'gray.500' }}
            >
              {t('authentication.activation_description')}
            </Text>
          </Box>

          <Box
            minW={{ md: '420px' }}
            mt="10"
            rounded="xl"
            bg={{ md: mode('white', 'gray.700') }}
            shadow={{ md: 'lg' }}
            px={{ md: '10' }}
            pt={{ base: '8', md: '12' }}
            pb="8"
          >
            {invalidToken ? (
              <Text
                mt="3"
                fontWeight="bold"
                fontSize={{ base: 'xl', md: '2xl' }}
                color={{ base: 'red', md: 'red.500' }}
              >
                {t('authentication.activation_invalid_token')}
              </Text>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing="8">
                  <FieldText
                    type="password"
                    label="Senha nova"
                    error={errors.password}
                    backgroundColor={'white'}
                    _focus={{ borderColor: 'teal.600' }}
                    isRequired
                    {...register('password')}
                  />

                  <FieldText
                    type="password"
                    label="Confirmar"
                    error={errors.confirmPassword}
                    _focus={{ borderColor: 'teal.600' }}
                    isRequired
                    {...register('confirmPassword')}
                  />
                </Stack>
                <Flex
                  spacing="4"
                  direction={{ base: 'column-reverse', md: 'row' }}
                  mt="6"
                  align="center"
                  justify="space-between"
                >
                  <LightMode>
                    <Button
                      mb={{ base: '4', md: '0' }}
                      w={{ base: 'full', md: 'auto' }}
                      type="submit"
                      colorScheme="teal"
                      ml="auto"
                      size="lg"
                      fontSize="md"
                      fontWeight="bold"
                    >
                      {t('authentication.reset_button')}
                    </Button>
                  </LightMode>
                </Flex>
              </form>
            )}
          </Box>
        </Box>
      </Flex>
    </Flex>
  )
}

export default ActivationAndResetPassword
