// Vendors
import React, { ReactNode } from 'react'

// Components

// Styles
import { Box, Stack, Text } from '@chakra-ui/react'

// Interfaces
export type FormFieldEditerFieldPreviewProps = {
  children: ReactNode
}

export const FormFieldEditerFieldPreview = (
  props: FormFieldEditerFieldPreviewProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { children } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Box w="100%">
      <Stack direction="row" mb="2">
        <Text color="blue.500" fontWeight="bold">
          PRÉ-VISUALIZAÇÃO
        </Text>
      </Stack>

      {children}
    </Box>
  )
}
