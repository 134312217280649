import { chakra, HTMLChakraProps, useToken } from '@chakra-ui/react'
import * as React from 'react'

export const FastAndConvenient = (
  props: HTMLChakraProps<'svg'> & {
    iconColor?: string
    colorStopInit: string
    colorStopEnd: string
  }
) => {
  const { iconColor, colorStopInit, colorStopEnd, ...rest } = props
  const color = iconColor || 'white'
  return (
    <chakra.svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="url(#paint0_linear_194:729)"
      />
      <path
        d="M19.9906 33.067C17.7299 33.067 15.52 32.3966 13.6404 31.1407C11.7607 29.8847 10.2957 28.0996 9.43061 26.011C8.5655 23.9225 8.33915 21.6243 8.78018 19.4071C9.22121 17.1899 10.3098 15.1533 11.9083 13.5547C13.5068 11.9562 15.5435 10.8676 17.7607 10.4266C19.9779 9.98557 22.2761 10.2119 24.3646 11.077C26.4532 11.9421 28.2383 13.4072 29.4942 15.2868C30.7502 17.1665 31.4206 19.3763 31.4206 21.637C31.4171 24.6673 30.2118 27.5726 28.069 29.7154C25.9262 31.8582 23.0209 33.0635 19.9906 33.067ZM19.9906 11.956C18.0766 11.956 16.2057 12.5235 14.6143 13.5868C13.0229 14.6502 11.7826 16.1615 11.0502 17.9297C10.3177 19.698 10.1261 21.6437 10.4995 23.5209C10.8729 25.398 11.7945 27.1223 13.1479 28.4756C14.5012 29.829 16.2255 30.7506 18.1027 31.124C19.9798 31.4974 21.9255 31.3058 23.6938 30.5734C25.462 29.8409 26.9734 28.6006 28.0367 27.0092C29.1 25.4179 29.6676 23.5469 29.6676 21.633C29.6644 19.0675 28.6438 16.6079 26.8297 14.7938C25.0156 12.9797 22.5561 11.9591 19.9906 11.956Z"
        fill={color}
      />
      <path
        d="M19.9903 11.295C19.9189 11.295 19.8481 11.2809 19.7821 11.2536C19.7161 11.2262 19.6562 11.1862 19.6056 11.1357C19.5551 11.0851 19.515 11.0252 19.4877 10.9592C19.4604 10.8932 19.4463 10.8224 19.4463 10.751V7.48499C19.4463 7.34058 19.5037 7.20208 19.6058 7.09997C19.7079 6.99786 19.8464 6.94049 19.9908 6.94049C20.1352 6.94049 20.2737 6.99786 20.3758 7.09997C20.4779 7.20208 20.5353 7.34058 20.5353 7.48499V10.751C20.5353 10.8225 20.5212 10.8933 20.4938 10.9594C20.4664 11.0255 20.4262 11.0855 20.3756 11.136C20.325 11.1865 20.2649 11.2266 20.1988 11.2539C20.1327 11.2811 20.0618 11.2951 19.9903 11.295Z"
        fill={color}
      />
      <path
        d="M27.6873 14.485C27.5796 14.4851 27.4743 14.4533 27.3847 14.3935C27.2951 14.3338 27.2252 14.2488 27.184 14.1493C27.1428 14.0498 27.132 13.9403 27.1531 13.8347C27.1741 13.7291 27.226 13.6321 27.3022 13.556L29.9472 10.911C30.0493 10.8089 30.1878 10.7515 30.3322 10.7515C30.4766 10.7515 30.6151 10.8089 30.7172 10.911C30.8194 11.0131 30.8767 11.1516 30.8767 11.296C30.8767 11.4404 30.8194 11.5789 30.7172 11.681L28.0722 14.326C28.0218 14.3765 27.962 14.4165 27.8961 14.4438C27.8302 14.4711 27.7595 14.4851 27.6882 14.485H27.6873Z"
        fill={color}
      />
      <path
        d="M20.8652 15.436C20.8652 14.9527 20.4737 14.561 19.9907 14.561C19.5077 14.561 19.1162 14.9527 19.1162 15.436V21.306C19.1162 21.7892 19.5077 22.181 19.9907 22.181C20.4737 22.181 20.8652 21.7892 20.8652 21.306V15.436Z"
        fill={color}
      />
      <path
        d="M30.8221 12.0734C31.1638 11.7317 31.164 11.1778 30.8225 10.8363C30.481 10.4948 29.9271 10.495 29.5854 10.8367L27.1742 13.2479C26.8325 13.5896 26.8323 14.1435 27.1738 14.485C27.5153 14.8265 28.0692 14.8264 28.4109 14.4847L30.8221 12.0734Z"
        fill={color}
      />
      <path
        d="M23.3008 8.68198C23.784 8.68198 24.1758 8.29046 24.1758 7.80748C24.1758 7.32451 23.784 6.93298 23.3008 6.93298L16.6808 6.93298C16.1975 6.93298 15.8058 7.32451 15.8058 7.80748C15.8058 8.29046 16.1975 8.68198 16.6808 8.68198H23.3008Z"
        fill={color}
      />
      <path
        d="M19.1162 10.551C19.1162 11.0342 19.5078 11.426 19.9907 11.426C20.4737 11.426 20.8652 11.0342 20.8652 10.551V8.60896C20.8652 8.12572 20.4737 7.73396 19.9907 7.73396C19.5078 7.73396 19.1162 8.12572 19.1162 8.60896V10.551Z"
        fill={color}
      />
      <defs>
        <linearGradient
          id="paint0_linear_194:729"
          x1="34.68"
          y1="-1.09564e-06"
          x2="5.48"
          y2="36.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colorStopInit} />
          <stop offset="1" stopColor={colorStopEnd} />
        </linearGradient>
      </defs>
    </chakra.svg>
  )
}
