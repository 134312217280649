// Vendors
import { capitalize, find, get, upperCase } from 'lodash'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo } from 'react'

// Functions
import { apiShow } from 'services/get'
import { formatDate } from 'utils/formatDate'

// Components
import { TabReport } from './tabs/TabReport'
import { TabDetail, Tabs } from 'components/Tabs'
import { TabGeneralData } from './tabs/TabGeneralData'
import { ListTableStatuses } from 'components/ListTableRowComponent'
import { ButtonCopyData } from 'pages/private/Orders/show/components/ButtonCopyData'

// Styles
import { Box, Text } from '@chakra-ui/react'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Types
import { PageMetaSetting } from './meta'
import { PurchaseType } from 'pages/private/Orders/show/tabs/TabOrder'
import { KitCheckInShow } from 'pages/private/Laboratorial/KitCheckIn/show/types'

export const PageKitsShowRecord = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const history = useHistory()
  const { t } = useTranslation('laboratory')
  const { id } = useParams<{ id: string }>()
  const { endpoint, title } = PageMetaSetting

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [record, setRecord] = React.useState<KitCheckInShow | undefined>(
    undefined
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const getKitShow = useCallback(async () => {
    try {
      const response = await apiShow<KitCheckInShow>(`${endpoint}/${id}`)

      setRecord(response)
    } catch (error) {
      console.error(error)
    }
  }, [endpoint, id])

  const sanitize = useMemo(() => {
    if (!record) return ''

    const activationCode = get(record, 'activationCode', '[ALERTA]')
    const originActivation = capitalize(
      get(record, 'originActivation', '[ALERTA]')
    )
    const activationByPersonalDataEmail = get(
      record,
      'activationByPersonalData.email',
      '[ALERTA]'
    )
    const activationForPersonalDataEmail = get(
      record,
      'activationForPersonalData.email',
      '[ALERTA]'
    )
    const kitStatus = get(ListTableStatuses, get(record, 'status', '[ALERTA]'))
    const purchaseNumber = get(record, 'purchase.purchaseNumber', '[ALERTA]')
    const purchaseType = get(
      PurchaseType,
      get(record, 'purchase.purchaseType'),
      '[ALERTA]'
    )
    const isCobranding = get(record, 'purchase.isCobranding')
      ? 'Cobranding'
      : ''
    const purchaseStatus = capitalize(
      get(
        ListTableStatuses,
        upperCase(get(record, 'purchase.status')),
        '[ALERTA]'
      )
    )
    const deliveryStatus = capitalize(
      get(
        ListTableStatuses,
        get(record, 'purchase.purchaseDelivery.status'),
        '[ALERTA]'
      )
    )
    const purchaseOrigin = capitalize(
      get(record, 'purchase.purchaseOrigin', '[ALERTA]')
    )
    const historyUpdates = get(record, 'historyUpdates')
    const userName = get(
      find(historyUpdates, { type: 'KIT_NEW' }),
      'updatedByPersonalDataName'
    )
    const userEmail = get(record, 'purchase.user.username', '[ALERTA]')

    return `
    Kit 1: ${activationCode}
Ativado via ${originActivation} por ${activationByPersonalDataEmail}
Status ${kitStatus}
${
  purchaseType === 'B2B'
    ? `Médico ${activationByPersonalDataEmail}
Paciente ${activationForPersonalDataEmail}
`
    : `Paciente ${activationForPersonalDataEmail}`
}
Do pedido #${purchaseNumber} ${purchaseType} ${isCobranding}
${purchaseStatus}
Status ${deliveryStatus}
Realizado via ${purchaseOrigin} por ${userName}
Para o cliente ${userEmail}
    `.trim()
  }, [record])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    async function getData() {
      await getKitShow()
    }

    getData()
  }, [endpoint, getKitShow, history, id, t])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: t('tabs.generalData'),
        component: TabGeneralData,
        options: {
          record,
          reload: getKitShow,
          activationCode: get(record, 'activationCode'),
        },
      },
      {
        title: t('tabs.report'),
        component: TabReport,
        options: {
          activationCode: get(record, 'activationCode'),
          purchaseHasProductHasAnalysis: get(
            record,
            'purchaseHasProductHasAnalysis'
          ),
        },
      },
    ],
    [t, record, getKitShow]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={`${title} ${id}`}>
      <Box position="relative">
        {record && (
          <Box position="absolute" top="-3.6rem" left="23rem">
            <ButtonCopyData text={sanitize} />
          </Box>
        )}

        {get(record, 'activatedAt') && (
          <Text mt="-6" mb="4">
            Ativado em {formatDate(get(record, 'activatedAt'), 'dd/MM/yyyy')}
          </Text>
        )}

        <Tabs tabsDetails={tabsDetails} />
      </Box>
    </LayoutLoggedPageList>
  )
}
