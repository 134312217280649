import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import {
  FormQuestionType,
  LaboratorialAnalysis,
} from 'types/LaboratorialAnalysis'
import { FormFieldEditorList } from './FormFieldEditor/FormFieldEditorList'

interface MedicalRecordProps {
  laboratoryAnalysis: LaboratorialAnalysis[]
}

export type FormFieldEditorProps = {
  question: FormQuestionType
}

export const MedicalRecord = (props: MedicalRecordProps) => {
  const { laboratoryAnalysis } = props

  const defaultIndex = useMemo(() => {
    if (laboratoryAnalysis.length > 1) {
      return
    }

    return [0]
  }, [laboratoryAnalysis.length])

  return (
    <Accordion allowToggle defaultIndex={defaultIndex}>
      {laboratoryAnalysis.map((analysis) => {
        return (
          <AccordionItem key={analysis.id} borderWidth="1px" borderRadius="md">
            <AccordionButton
              py="4"
              px="3"
              d="flex"
              bg="gray.50"
              justifyContent="space-between"
            >
              <Heading fontSize="lg">{analysis.name}</Heading>

              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel>
              <FormFieldEditorList laboratoryAnalysis={analysis} />
            </AccordionPanel>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}
