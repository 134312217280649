// Vendors
import React, { forwardRef } from 'react'
import { Controller, FieldError, RegisterOptions } from 'react-hook-form'

// Components

// Styles
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputProps,
  NumberInputField,
  NumberInputStepper,
  Flex,
  Text,
} from '@chakra-ui/react'
import { useFormHelperText } from 'hooks/useFormHelperText'
import { TooltipProps, Tooltip } from 'components/Tooltip'

// Interfaces
export interface IFieldNumber
  extends Omit<NumberInputProps, 'value' | 'name' | 'onChange'> {
  name: string
  label?: string
  caption?: string
  error?: FieldError
  control?: any
  canModify?: boolean
  showNumberStepper?: boolean
  customOnChange?: (event: string) => void
  helperText?:
    | string
    | {
        text: string
        color?: string
      }
  tooltipProps?: TooltipProps
  isRequired?: boolean
  rules?: Omit<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >
}

const FieldNumberBase: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IFieldNumber
> = (props, ref) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    name,
    label,
    rules,
    caption,
    error,
    control,
    helperText,
    canModify = true,
    showNumberStepper = true,
    customOnChange,
    tooltipProps,
    placeholder,
    isRequired,
    ...rest
  } = props
  const componentHelperText = useFormHelperText({ helperText, canModify })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormControl isInvalid={!!error}>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        {!!label && (
          <FormLabel htmlFor={name} m={0}>
            {label}
            {isRequired && (
              <Text
                as="span"
                color="red.500"
                ml={1}
                fontWeight="bold"
                userSelect="none"
              >
                *
              </Text>
            )}
          </FormLabel>
        )}

        {tooltipProps && Object.keys(tooltipProps).length && (
          <Tooltip {...tooltipProps} verticalAlign="middle" />
        )}
      </Flex>

      {control ? (
        <Controller
          name={name}
          rules={rules}
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <NumberInput
              id={name}
              data-cy={name}
              name={name}
              onChange={(e) => {
                customOnChange && customOnChange(e)
                onChange(e)
              }}
              value={value}
              {...rest}
            >
              <NumberInputField placeholder={placeholder} />
              {showNumberStepper && (
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              )}
            </NumberInput>
          )}
        />
      ) : (
        <NumberInput id={name} name={name}>
          <NumberInputField />
          {showNumberStepper && (
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          )}
        </NumberInput>
      )}

      {!!caption && (
        <FormLabel htmlFor={name} fontWeight="normal" fontSize="sm" ml="1">
          {caption}
        </FormLabel>
      )}

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      {(helperText || !canModify) && !error && componentHelperText}
    </FormControl>
  )
}

export const FieldNumber = forwardRef(FieldNumberBase)
