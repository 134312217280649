// Vendors
import React, { useCallback } from 'react'
import { get } from 'lodash'
import { Controller, FieldError, useForm } from 'react-hook-form'

// Components
import { FieldSelect } from 'components/FieldSelect'
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useError } from 'hooks/useError'
import { useSelectParser } from 'hooks/useSelectParser'
import { apiPost } from 'services/post'

type Client = {
  id: string
  userId: string
  name: string
  email: string
  phoneNumber: string
  birthdate: Date
  status: 'ENABLED' | 'DISABLED'
  createdAt: Date
  updatedAt: Date
  documentNumber?: string
}

type ModalAssingIdProps = {
  isOpen: boolean
  reload: () => void
  onCancel: () => void
  activationCode: string
}

type FormActiveKit = {
  patient?: {
    label: string
    value: string
  }
  comments: string
}

export const ModalLegacyActiveKit = (props: ModalAssingIdProps) => {
  const { isOpen, reload, onCancel, activationCode } = props

  const selectPatient = useSelectParser<Client>({
    accessor: {
      label: ({ name, documentNumber }) =>
        `${name} ${
          documentNumber &&
          `(${documentNumber.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})$/,
            '$1.$2.$3-$4'
          )})`
        }`,
      value: 'userId',
    },
    endpoint: isOpen ? '/app/client' : undefined,
  })

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormActiveKit>()

  const { handleError } = useError()

  const onClose = useCallback(() => {
    reset()
    onCancel()
  }, [onCancel, reset])

  const onSubmit = useCallback(
    async (data: FormActiveKit) => {
      try {
        const { comments, patient } = data

        const activateForUserId = get(patient, 'value')

        await apiPost(`/app/legacy/activate_kit/${activationCode}`, {
          ...(activateForUserId && { activateForUserId }),
          comments,
        })

        reload()
        onClose()
      } catch (error) {
        handleError(error)
      }
    },
    [activationCode, handleError, onClose, reload]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Legado: Ativar kit sem ficha clínica</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text color="gray.500" mb="4">
            Esta ferramenta é para uso exclusivo de kits legados que já estão em
            laboratório. Ao ativar, verifique se a ficha clínica já foi
            informada por outro meio e está disponível.
          </Text>

          <Stack>
            <Controller
              control={control}
              name="patient"
              render={({ field }) => (
                <FieldSelect
                  label="Escolha um paciente"
                  error={errors.patient as FieldError}
                  options={selectPatient.options}
                  isLoading={selectPatient.isLoading}
                  placeholder="Selecione um paciente..."
                  {...field}
                />
              )}
            />

            <FieldTextArea
              label="Comentários"
              error={errors.comments}
              placeholder="Digite algum comentário"
              {...register('comments', {
                required: 'Por favor, preencha o campo comentários',
              })}
            />
          </Stack>

          <Text fontSize="sm" color="orange.500" mt="4" fontStyle="italic">
            Ativar o kit por essa ferramenta NÃO preenche a ficha clínica
          </Text>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose}>Cancelar</Button>
            <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
