// Vendors
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

// Functions
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FieldText } from 'components/FieldText'
import { FieldNumber } from 'components/FieldNumber'
import { FieldSelect } from 'components/FieldSelect'
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import { Grid, GridItem } from '@chakra-ui/react'
import { LaboratorialAnalysis } from 'types/LaboratorialAnalysis'

export const TabGeneral = () => {
  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectLaboratorialAnalysis = useSelectParser<LaboratorialAnalysis>({
    accessor: { value: 'id', label: 'name' },
    endpoint: '/app/laboratory_analysis',
  })

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext()

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Grid
      gap="10"
      my="4"
      templateColumns={{ lg: 'repeat(4, 1fr)', xl: 'repeat(8, 1fr)' }}
    >
      <GridItem colSpan={4}>
        <FieldText
          label="Nome"
          error={errors.name}
          isRequired
          {...register('name')}
        />
      </GridItem>

      <GridItem>
        <FieldNumber
          min={1}
          max={999}
          control={control}
          name="heightInCentimeters"
          label="Altura (cm)"
          error={errors.heightInCentimeters}
          isRequired
        />
      </GridItem>

      <GridItem>
        <FieldNumber
          min={1}
          max={999}
          control={control}
          name="widthInCentimeters"
          label="Largura (cm)"
          error={errors.widthInCentimeters}
          isRequired
        />
      </GridItem>

      <GridItem>
        <FieldNumber
          min={1}
          max={999}
          control={control}
          name="lengthInCentimeters"
          label="Comprimento (cm)"
          error={errors.lengthInCentimeters}
          isRequired
        />
      </GridItem>

      <GridItem>
        <FieldNumber
          min={1}
          max={999}
          control={control}
          name="weightInGrams"
          label="Peso (g)"
          error={errors.weightInGrams}
          isRequired
        />
      </GridItem>

      <GridItem colSpan={4}>
        <FieldTextArea
          label="Descrição"
          error={errors.description}
          isRequired
          {...register('description')}
        />
      </GridItem>

      <GridItem colSpan={4}>
        <Controller
          name="laboratoryAnalysisIds"
          control={control}
          render={({ field }) => (
            <FieldSelect
              isMulti
              label="Selecione as análises laboratoriais"
              error={errors.laboratoryAnalysisIds}
              isLoading={selectLaboratorialAnalysis.isLoading}
              options={selectLaboratorialAnalysis.options}
              isRequired
              {...field}
            />
          )}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <FieldText
          label="SKU"
          error={errors.sku}
          isRequired
          {...register('sku')}
        />
      </GridItem>
    </Grid>
  )
}
