// Vendors
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, FieldError, useForm } from 'react-hook-form'

// Hooks
import { useError } from 'hooks/useError'

// Components
import { FieldSelect } from 'components/FieldSelect'
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import {
  Text,
  Stack,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react'
import { apiPatch } from 'services/patch'
import { get } from 'lodash'
import { FieldText } from 'components/FieldText'

enum PurchaseStatus {
  IN_TRANSIT = 'Em trânsito',
  SENT = 'Enviado',
  DELIVERED = 'Entregue',
}

type ModalAlterPurchaseStatusProps = {
  isOpen: boolean
  reload: () => void
  onCancel: () => void
  purchaseDeliveryId: string
}

type FormAlterCobrandingStatus = {
  deliveryTrackingCode?: string
  purchaseStatus: {
    label: string
    value: string
  }
  comments: string
}

export const ModalAlterPurchaseStatus = (
  props: ModalAlterPurchaseStatusProps
) => {
  const selectKitStatus = Object.entries(PurchaseStatus).map(
    ([value, label]) => ({
      label,
      value,
    })
  )

  const { isOpen, onCancel, reload, purchaseDeliveryId } = props

  const {
    reset,
    watch,
    control,
    register,
    unregister,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormAlterCobrandingStatus>()

  const purchaseStatus = watch('purchaseStatus')

  const { handleError } = useError()

  useEffect(() => {
    if (get(purchaseStatus, 'label') !== PurchaseStatus.IN_TRANSIT) {
      unregister('deliveryTrackingCode')
    } else {
      register('deliveryTrackingCode')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseStatus?.label, register, unregister])

  const onClose = useCallback(() => {
    reset()
    onCancel()
  }, [onCancel, reset])

  const onSubmit = useCallback(
    async (data: FormAlterCobrandingStatus) => {
      try {
        const {
          comments,
          deliveryTrackingCode,
          purchaseStatus: { value },
        } = data

        await apiPatch(`/app/expedition/${purchaseDeliveryId}/status`, {
          status: value,
          note: comments,
          deliveryTrackingCode,
        })

        reload()
        onClose()
      } catch (error) {
        handleError(error)
      }
    },
    [handleError, onClose, purchaseDeliveryId, reload]
  )

  const footerMessages = useMemo(() => {
    return {
      [PurchaseStatus.IN_TRANSIT]: (
        <Text>
          Ao mudar o status do pedido para <Text as="strong">Em trânsito</Text>{' '}
          informando o código de rastreio, o sistema enviará um email ao cliente
          para informar sobre o envio
        </Text>
      ),
      [PurchaseStatus.DELIVERED]: (
        <Text>
          Ao mudar o status do pedido para <Text as="strong">Entregue</Text>, o
          sistema enviará um email ao cliente para confirmar a entrega
        </Text>
      ),
    }
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Atenção</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Stack mt="4">
            <Controller
              control={control}
              name="purchaseStatus"
              rules={{
                required: 'Por favor, selecione o status do pedido',
              }}
              render={({ field }) => (
                <FieldSelect
                  label="Escolha um status"
                  error={errors.purchaseStatus as FieldError}
                  options={selectKitStatus}
                  placeholder="Selecione o status do pedido..."
                  {...field}
                />
              )}
            />

            {get(purchaseStatus, 'label') === PurchaseStatus.IN_TRANSIT && (
              <FieldText
                label="Insira o código"
                error={errors.deliveryTrackingCode}
                {...register('deliveryTrackingCode', {
                  required: 'Código é obrigatório',
                  pattern: {
                    value: /^[A-Z]{2}[0-9]{9}[A-Z]{2}$/,
                    message: 'Código é inválido exem.: SL123456789BR',
                  },
                })}
              />
            )}

            <FieldTextArea
              label="Justificativa"
              error={errors.comments}
              placeholder="Digite uma justificativa..."
              {...register('comments', {
                required: 'Por favor, preencha o campo de justificativa',
              })}
            />
          </Stack>

          {get(footerMessages, get(purchaseStatus, 'label')) && (
            <Text fontSize="sm" color="orange.500" mt="4" fontStyle="italic">
              {get(footerMessages, get(purchaseStatus, 'label'))}
            </Text>
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose}>Cancelar</Button>
            <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
              Confirmar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
