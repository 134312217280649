// Vendors
import React from 'react'

// Components
import {
  AccordionItem,
  HistoryUpdate,
  TypeHistoryUpdate,
} from '../components/AccordionItem'

// Styles
import { Accordion, Heading, Stack } from '@chakra-ui/react'

import { PurchaseHasProductType } from '../types'

type TabHistoryProps = {
  reload: () => void
  purchaseId: string
  purchaseNumber?: string
  purchaseHistory?: HistoryUpdate[]
  products?: PurchaseHasProductType[]
}

export const TabHistory = (props: TabHistoryProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { products, purchaseNumber, purchaseHistory, purchaseId, reload } =
    props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Stack spacing="4" maxW="container.md">
      {purchaseHistory && purchaseHistory.length && (
        <>
          <Heading fontSize={20}>Histórico do pedido</Heading>
          <Accordion allowToggle>
            <AccordionItem
              key={purchaseId}
              reload={reload}
              productId={purchaseId}
              historyUpdates={purchaseHistory}
              type={TypeHistoryUpdate.PURCHASE}
              label={`Pedido #${purchaseNumber}`}
            />
          </Accordion>
        </>
      )}

      <Heading fontSize={20}>Histórico por kit</Heading>
      <Accordion allowToggle>
        {products?.map((product) => {
          const label = product.storeProduct.mktTitle
          const historyUpdates = product.historyUpdates
          return (
            <AccordionItem
              key={product.id}
              label={label}
              reload={reload}
              productId={product.id}
              historyUpdates={historyUpdates}
              type={TypeHistoryUpdate.PURCHASE_PRODUCT}
            />
          )
        })}
      </Accordion>
    </Stack>
  )
}
