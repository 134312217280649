// Vendors
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

// Hooks
import { useCreateRecord } from 'hooks/useCreateRecord'

// Components
import { Tabs } from 'components/Tabs'
import { FormFooter } from 'components/FormFooter'
import { TabPersonalData } from './tabs/TabPersonalData'

// Schemas
import { schema } from './schema'

// Styles
import { Flex, useToast } from '@chakra-ui/react'

// Types
import { SellerNewForm } from './types'
import { useFeedback } from 'contexts/feedback'

type SellerFormNewProps = {
  endpoint: string
}

export const SellerFormNew = (props: SellerFormNewProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props

  const { t } = useTranslation('seller')
  const history = useHistory()

  const { create } = useCreateRecord<SellerNewForm>({ endpoint })

  const methods = useForm<SellerNewForm>({
    resolver: yupResolver(schema(t)),
  })
  const toast = useToast({ position: 'top-right' })
  const { feedback } = useFeedback()

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails = useMemo(
    () => [
      {
        title: t('tabs.personalData'),
        component: TabPersonalData,
      },
    ],
    [t]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<SellerNewForm> = React.useCallback(
    async (data) => {
      try {
        await create(data)

        toast({
          title: t('toast.success.title'),
          description: t('toast.success.create'),
          status: 'success',
        })

        history.push('/sellers')
      } catch (error: any) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        }

        const errorMessage =
          error?.response?.data.message ||
          error?.message ||
          t('toast.error.default')

        feedback({
          title: 'Atenção',
          description: errorMessage,
          status: 'error',
        })
      }
    },
    [create, feedback, history, methods, t, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Tabs tabsDetails={tabsDetails} />

        <FormFooter isSubmitting={methods.formState.isSubmitting} />
      </Flex>
    </FormProvider>
  )
}
