import { Box, Stack, Text } from '@chakra-ui/react'
import * as React from 'react'
import { NavItem, NavItemProps } from './NavItem'

interface NavGroupProps {
  label: string
  data: NavItemProps[]
}

export const NavGroup = (props: NavGroupProps) => {
  const { label, data } = props
  return (
    <Box>
      <Text
        px="3"
        fontSize="xs"
        fontWeight="semibold"
        textTransform="uppercase"
        letterSpacing="widest"
        color="gray.500"
        mb="3"
      >
        {label}
      </Text>
      <Stack spacing="1">
        {data.map((item) => (
          <NavItem key={item.label} {...item} />
        ))}
      </Stack>
    </Box>
  )
}
