import React, { MouseEvent } from 'react'
import { get } from 'lodash'

import { ModalAlterKitStatus } from 'components/ModalAlterKitStatus'

import { RiExchangeFundsLine } from 'react-icons/ri'
import { MenuItem, Tooltip, useDisclosure } from '@chakra-ui/react'

import { ActionProps } from './types'

export const AlterKitStatusAction = <T extends unknown>({
  record,
  options,
}: ActionProps<T>) => {
  const modalAlterKitStatusDisclosure = useDisclosure()
  const { callback, reload, ...rest } = options
  const activationCode = get(record, 'activationCode')

  if (!reload) {
    throw new Error('reload is required')
  }

  return (
    <>
      <ModalAlterKitStatus
        reload={reload}
        isOpen={modalAlterKitStatusDisclosure.isOpen}
        onCancel={modalAlterKitStatusDisclosure.onClose}
        activationCode={activationCode}
      />

      <Tooltip
        placement="left"
        shouldWrapChildren
        label="O kit precisa esta ativo para alterar o status"
      >
        <MenuItem
          aria-label="Alterar status do kit"
          icon={<RiExchangeFundsLine size="18" />}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            modalAlterKitStatusDisclosure.onOpen()

            if (callback) {
              callback(record)
            }
          }}
          {...rest}
        >
          Alterar status do kit
        </MenuItem>
      </Tooltip>
    </>
  )
}
