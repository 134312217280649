// Vendors
import React from 'react'

// Components
// import Todo from 'tools/Todo'

// Styles
import { Heading } from '@chakra-ui/react'

// Interfaces
export interface IFormSection {
  label: string
}

export const FormSection: React.FC<IFormSection> = (props: IFormSection) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { label } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Heading as="h4" size="md" textAlign="left" w="full" pt={6}>
      {label}
    </Heading>
  )
}
