// Vendors
import { map } from 'lodash'
import { Link, useHistory } from 'react-router-dom'
import React, { useMemo, useCallback, useEffect } from 'react'

// Functions
import { apiShow } from 'services/get'
import { apiPatch } from 'services/patch'
import { formatDate } from 'utils/formatDate'
import { useTranslation } from 'react-i18next'
import { useListRecords } from 'hooks/useListRecords'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListPagination } from 'components/ListPagination'
import { ListTableRenderAs } from 'components/ListTableRowComponent'
import { FieldDateRangePicker } from 'components/FieldDateRangePicker'

// Styles
import { Button, Stack, HStack, Select } from '@chakra-ui/react'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Types
import { Contract } from './types'
import { PersonPurchaseAndKit } from 'types/PersonPurchaseAndKit'

// Hooks & Contexts
import { useDialogDelete } from 'hooks/useDialogDelete'
import { useConfirmationDialog } from 'contexts/confirmationDialog'
import { useDownload } from 'hooks/useDownload'
import { useError } from 'hooks/useError'

import { PageMetaSetting } from './meta'

export const PageContractListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('common')
  const history = useHistory()
  const { endpoint, title } = PageMetaSetting
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<Contract>({
    endpoint: { public: endpoint, private: `/app/person` },
  })

  const { onDownload } = useDownload()
  const { handleError } = useError()
  const { dialog } = useDialogDelete()
  const { getConfirmation } = useConfirmationDialog()

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    Records.list.forEach((record) => {
      record.createdAtFormatted = formatDate(record.createdAt, 'dd/MM/yyyy')
      record.signedAtFormatted = formatDate(record.signedAt, 'dd/MM/yyyy')
      record.statusFormatted = record.signedAt
        ? 'CONTRACT_SIGNED'
        : 'CONTRACT_PENDING'
    })
  }, [Records.list])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const ListTableHeaders = useMemo(
    () => [
      {
        label: t('list_table.headers.name'),
        accessor: 'signedByPersonalData.name',
      },
      {
        label: t('list_table.headers.signed_at'),
        accessor: 'signedAtFormatted',
      },
      {
        label: t('list_table.headers.created_at'),
        accessor: 'createdAtFormatted',
      },
      {
        label: t('list_table.headers.status'),
        accessor: 'statusFormatted',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
    ],
    [t]
  )

  const ListTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/professionals/${Record.signedByPersonalData.id}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutLoggedPageList title={title}>
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ListFilters query={Query}>
          <HStack>
            <FieldDateRangePicker
              isSmall
              name="filterDate"
              placeholderText="Filtrar por data"
              containerProps={{
                marginBottom: '0',
              }}
              showMonthDropdown
              dropdownMode="select"
              query={Query}
            />
            <Select
              size="sm"
              onChange={(e) =>
                Query.set({ ...Query.current, sort: e.target.value })
              }
            >
              <option value="-createdAt">Mais recentes</option>
              <option value="+createdAt">Mais antigos</option>
            </Select>
          </HStack>
        </ListFilters>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={ListTableRowAction}
        defaultActions="none"
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
