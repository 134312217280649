// Vendors
import { get, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, FieldError, useForm } from 'react-hook-form'

// Functions
import { apiPost } from 'services/post'
import { useError } from 'hooks/useError'

// Components
import { FieldFile } from 'components/FieldFile'
import { FieldText } from 'components/FieldText'
import { FieldSelect } from 'components/FieldSelect'
import { DrawerListContent } from 'components/DrawerListContent'

// Styles
import {
  Button,
  Divider,
  useToast,
  DrawerBody,
  ButtonGroup,
  DrawerFooter,
  Stack,
} from '@chakra-ui/react'

// Schemas
import { schema } from './DrawerUploadSchema'

// Types
import { PurchaseHasProductHasAnalysisType } from './tabs/TabReport'

type DrawerUploadProps = {
  isOpen: boolean
  onClose: () => void
  activationCode?: string
  recordReload: () => Promise<void>
  purchaseHasProductHasAnalysis?: PurchaseHasProductHasAnalysisType[]
}

type RHFInputProps = {
  title: string
  file: File[]
  comment: string
  laboratoryAnalysisIds: string[]
}

export const DrawerUpload = (props: DrawerUploadProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Components
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    isOpen,
    onClose,
    recordReload,
    activationCode,
    purchaseHasProductHasAnalysis,
  } = props

  const { t } = useTranslation('laboratory')
  const toast = useToast({ position: 'top-right' })
  const { handleError } = useError()

  const {
    reset,
    watch,
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<RHFInputProps>({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectLaboratorialAnalysis = useMemo(() => {
    if (!purchaseHasProductHasAnalysis) return []

    const laboratorialAnalysis = map(
      purchaseHasProductHasAnalysis,
      'laboratoryAnalysis'
    )

    return map(laboratorialAnalysis, (laboratoryAnalysis) => ({
      label: laboratoryAnalysis.name,
      value: laboratoryAnalysis.id,
    }))
  }, [purchaseHasProductHasAnalysis])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleClose = useCallback(() => {
    reset({})
    onClose()
  }, [onClose, reset])

  const handleUpload = useCallback(
    async (data: RHFInputProps) => {
      try {
        const { file, comment, title, laboratoryAnalysisIds } = data

        const formData = new FormData()
        formData.append('file', file[0])
        formData.append('comment', comment)
        formData.append('title', title)
        laboratoryAnalysisIds.forEach((laboratoryAnalysisId, index) => {
          formData.append(
            `laboratoryAnalysisIds[${index}]`,
            laboratoryAnalysisId
          )
        })

        await apiPost(
          `/app/laboratory/${activationCode}/upload_report`,
          formData
        )

        toast({
          title: 'Sucesso',
          description: 'Laudo enviado com sucesso',
          status: 'success',
        })

        handleClose()
        await recordReload()
      } catch (error: any) {
        console.error(error)

        handleError(error)
      }
    },
    [activationCode, handleError, handleClose, recordReload, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <DrawerListContent
      title={t('drawerUpload.title')}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <Divider my={4} />

      <DrawerBody>
        <Stack spacing="10">
          <Controller
            name="laboratoryAnalysisIds"
            control={control}
            render={({ field }) => {
              return (
                <FieldSelect
                  isMulti
                  options={selectLaboratorialAnalysis}
                  label={t('drawerUpload.form.analysisLaboratorialIds.label')}
                  helperText={t(
                    'drawerUpload.form.analysisLaboratorialIds.helperText'
                  )}
                  error={errors.laboratoryAnalysisIds as FieldError}
                  {...field}
                  onChange={(e) => {
                    const fileNameLaboratoryAnalysis = e
                      .map(
                        (laboratoryAnalysis: any) => laboratoryAnalysis.label
                      )
                      .join('-')

                    setValue('title', fileNameLaboratoryAnalysis)
                    field.onChange(e)
                  }}
                  isRequired
                />
              )
            }}
          />

          <FieldText
            label={t('drawerUpload.form.fileName.label')}
            maxLength={50}
            currentLength={get(watch('title'), 'length')}
            helperText={t('drawerUpload.form.fileName.helperText')}
            error={errors.title as FieldError}
            isRequired
            {...register('title')}
          />

          <FieldText
            label={t('drawerUpload.form.internalComment.label')}
            maxLength={200}
            currentLength={get(watch('comment'), 'length')}
            helperText={t('drawerUpload.form.internalComment.helperText')}
            error={errors.comment as FieldError}
            isRequired
            {...register('comment')}
          />

          <FieldFile
            label={t('drawerUpload.form.uploadReport.label')}
            helperText={t('drawerUpload.form.uploadReport.helperText')}
            error={errors.file as FieldError}
            fileName={get(watch('file'), '[0].name')}
            isRequired
            {...register('file')}
          />
        </Stack>
      </DrawerBody>

      <Divider my={4} />

      <DrawerFooter>
        <ButtonGroup>
          <Button onClick={handleClose}>
            {t('drawerUpload.button.cancel')}
          </Button>
          <Button
            isLoading={isSubmitting}
            onClick={handleSubmit(handleUpload)}
            colorScheme="blue"
          >
            {t('drawerUpload.button.upload')}
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </DrawerListContent>
  )
}
