// Vendors
import React from 'react'

// Components
import {
  OrderTimelineItem,
  OrderTimelineItemProps,
} from 'components/OrderTimelineItem'

// Styles
import { Box, Flex, FlexProps, Stack } from '@chakra-ui/react'

// Interfaces
export type OrderTimelineListProps = FlexProps & {
  data: OrderTimelineItemProps[]
}

export const OrderTimelineList = (
  props: OrderTimelineListProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { data, ...rest } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex justifyContent="space-around" direction="row" {...rest}>
      {data.map((item, index) => {
        let bgColor = 'gray.100'

        if (item.isActive) {
          bgColor = 'blue.100'
        }

        if (item.isError) {
          bgColor = 'red.100'
        }

        return (
          <Box key={item.title} position="relative" w="full">
            <OrderTimelineItem {...item} />

            {index !== 0 && (
              <Box
                h="1"
                w="full"
                top="8"
                right="50%"
                position="absolute"
                bg={bgColor}
              />
            )}
          </Box>
        )
      })}
    </Flex>
  )
}
