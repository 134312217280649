import { useDisclosure } from '@chakra-ui/react'
import { AlertDelivery } from 'components/AlertDelivery'
import { get } from 'lodash'
import React, { cloneElement, useCallback, useMemo } from 'react'

import {
  ConfirmationDialogContextValues,
  ConfirmationDialogProviderProps,
  DialogConfigType,
} from './types'

const ConfirmationDialogContext =
  React.createContext<ConfirmationDialogContextValues>(
    {} as ConfirmationDialogContextValues
  )

export const ConfirmationDialogProvider = ({
  children,
}: ConfirmationDialogProviderProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [dialogConfig, setDialogConfig] = React.useState<DialogConfigType>(
    {} as DialogConfigType
  )

  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const openDialog = ({
    title,
    message,
    confirmText = 'Deletar',
    onConfirm,
    callback,
  }: DialogConfigType) => {
    onOpen()
    setDialogConfig({ title, message, confirmText, onConfirm, callback })
  }

  const resetDialog = useCallback(() => {
    onClose()
    setDialogConfig({} as DialogConfigType)
  }, [onClose])

  const onConfirm = async () => {
    if (dialogConfig.onConfirm) {
      setIsLoading(true)
      await dialogConfig.onConfirm()
      setIsLoading(false)
    }

    resetDialog()
    dialogConfig.callback(true)
  }

  const onDismiss = useCallback(() => {
    resetDialog()
    dialogConfig.callback(false)
  }, [dialogConfig, resetDialog])

  const message = useMemo(() => {
    const msg = get(dialogConfig, 'message', '')

    if (msg === '' || typeof msg === 'string') {
      return msg
    }

    if ('onDismiss' in msg?.props) {
      return cloneElement(msg, { onDismiss })
    }

    return msg
  }, [dialogConfig, onDismiss])

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      <AlertDelivery
        isOpen={isOpen}
        title={dialogConfig.title}
        message={message}
        confirmText={dialogConfig.confirmText}
        onConfirm={onConfirm}
        onCancel={onDismiss}
        confirmProps={{ isLoading }}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  )
}

export const useConfirmationDialog = () => {
  const { openDialog } = React.useContext(ConfirmationDialogContext)

  const getConfirmation = ({
    ...options
  }: {
    title: string
    confirmText?: string
    onConfirm?: () => Promise<void>
    message: string | React.ReactElement
  }) =>
    new Promise((resolve: (value?: boolean) => void, reject) => {
      openDialog({ callback: resolve, ...options })
    })

  return { getConfirmation }
}
