// Vendors
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// Functions
import { arrayOfIntegers } from 'utils/array-of-integers'

// Components
import { CardStat } from 'components/CardStat'
import { FastActions } from 'components/FastActions'
import { FieldSelect } from 'components/FieldSelect'

// Styles
import Layout from 'layout/Private'
import { Box, SimpleGrid, Stack, Grid, GridItem } from '@chakra-ui/react'
import { useHistory } from 'react-router'
import { apiShow } from 'services/get'
import { IndicatorType } from 'types/Indicators'
import { GraphStacks } from 'components/GraphStacks'
import { FieldDate } from 'components/FieldDate'

export const DashboardBackoffice = () => {
  const { t } = useTranslation('common')

  const [backofficeIndicators, setBackofficeIndicators] = useState<
    IndicatorType | undefined
  >(undefined)

  const [period, setPeriod] = useState(new Date())

  useEffect(() => {
    async function getIndicators() {
      const response = await apiShow<IndicatorType>(
        `/app/backoffice/indicators`,
        {
          yearNumber: period.getFullYear(),
          monthNumber: period.getMonth() + 1,
        }
      )

      if (!response) return
      setBackofficeIndicators(response)
    }

    getIndicators()
  }, [period])

  return (
    <Layout title={t('pages_private.dashboard_title')}>
      <Stack direction="row" my="4" spacing="6">
        <Box w="56" zIndex="dropdown">
          <FieldDate
            name="filterDate"
            dateFormat="dd/MM/yyyy"
            onChange={(date) => setPeriod(date as Date)}
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            selected={period}
            label="Filtrar por data"
          />
        </Box>
      </Stack>

      <SimpleGrid columns={4} gap="4">
        <CardStat
          label="Recoletas pendentes"
          value={backofficeIndicators?.indicators.pendingRecollection || 0}
          series={[
            {
              name: 'Volume financeiro',
              data: arrayOfIntegers(12, 100),
            },
          ]}
        />
        <CardStat
          label="Amostras Recusadas"
          value={backofficeIndicators?.indicators.deniedSample || 0}
          series={[
            {
              name: 'Volume financeiro',
              data: arrayOfIntegers(12, 100),
            },
          ]}
        />
        <CardStat
          label="Dados divergentes"
          value={backofficeIndicators?.indicators.divergentData || 0}
          series={[
            {
              name: 'Volume financeiro',
              data: arrayOfIntegers(12, 100),
            },
          ]}
        />
      </SimpleGrid>
      <Grid my="4" gap="4" templateColumns={{ lg: 'repeat(2, 1fr)' }}>
        <GridItem>
          <GraphStacks
            title="Histórico de Pedidos"
            subtitle="Ultimos 12 meses"
            series={[
              {
                name: 'Pedidos',
                data: [44, 55, 10, 11, 25, 35, 70, 80, 40, 30, 18, 42],
              },
            ]}
          />
        </GridItem>
        <GridItem>
          <GraphStacks
            title="Histórico em análise"
            subtitle="Ultimos 12 meses"
            series={[
              {
                name: 'Em análise',
                data: [44, 55, 10, 11, 25, 35, 70, 80, 40, 30, 18, 42],
              },
            ]}
          />
        </GridItem>
      </Grid>
    </Layout>
  )
}
