// Vendors
import { sub } from 'date-fns'
import { kebabCase } from 'lodash'
import React, { useEffect, useMemo } from 'react'

// Functions
import formatCurrency from 'utils/formatCurrency'
import { formatDate } from 'utils/formatDate'

// Hooks
import { useListRecords } from 'hooks/useListRecords'
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { ListTable } from 'components/ListTable'
import { MenuExports } from 'components/MenuExports'
import { ListActions } from 'components/ListActions'
import { ListFilters } from 'components/ListFilters'
import { ListPagination } from 'components/ListPagination'
import { ListTableRenderAs } from 'components/ListTableRowComponent'
import { FieldDateRangePicker } from 'components/FieldDateRangePicker'

// Styles
import { Box, Select, Stack } from '@chakra-ui/react'

type TabPerformanceProps = {
  sellerId: string
}

export const TabPerformance = (props: TabPerformanceProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectVoucherCode = useSelectParser({
    accessor: { value: 'voucherCode', label: 'voucherCode' },
    endpoint: '/app/coupon',
  })

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { sellerId } = props

  const endpoint = `/app/expedition/order`

  const { records, query, pagination } = useListRecords<any>({
    endpoint,
    query: {
      sellerId,
      startDate: sub(new Date(), { days: 7 }),
      endDate: new Date(),
    },
  })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!sellerId) return

    query.set((oldState) => ({
      ...oldState,
      sellerId,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerId])

  useEffect(() => {
    records.list.forEach((record) => {
      if (!record.status) {
        record.status = record.purchaseStatus.toUpperCase()
      }

      if (kebabCase(record.paymentMethodName) === 'credit-card') {
        record.paymentMethodName = 'Cartão de crédito'
      }

      record.kitsAmount = String(record.purchaseHasProducts.length)

      record.purchaseTypeFormatted = record.isCobranding
        ? 'COBRANDING'
        : record.purchaseType

      record.createdAtFormatted = formatDate(record.createdAt, 'dd/MM/yyyy')
      record.priceFormatted = formatCurrency(+record.price)
    })
  }, [records.list])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const listTableHeaders = useMemo(
    () => [
      {
        label: 'Cód. pedido',
        accessor: 'purchaseNumber',
      },
      {
        label: 'Data da compra',
        accessor: 'createdAtFormatted',
      },
      {
        label: 'QTD',
        accessor: 'kitsAmount',
        render: { as: ListTableRenderAs.MULTILINE_TEXT },
      },
      {
        label: 'Total R$',
        accessor: 'priceFormatted',
      },
      {
        label: 'Status',
        accessor: 'status',
        render: { as: ListTableRenderAs.BADGE },
      },
      {
        label: 'Origem',
        accessor: 'purchaseOrigin',
        render: {
          as: ListTableRenderAs.TEXT,
          options: {
            textTransform: 'uppercase',
          },
        },
      },
      {
        label: '',
        accessor: 'purchaseTypeFormatted',
        render: {
          as: ListTableRenderAs.TEXT,
          options: {
            textTransform: 'uppercase',
          },
        },
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <Stack
        spacing="4"
        justify="space-between"
        direction={{ base: 'column', md: 'row' }}
      >
        <ListFilters query={query}>
          <FieldDateRangePicker
            isSmall
            name="filterDate"
            query={query}
            placeholderText="Filtrar por data"
            containerProps={{
              marginBottom: '0',
            }}
            showMonthDropdown
            dropdownMode="select"
          />

          <Select
            size="sm"
            placeholder="Todos os coupons"
            onChange={(e) =>
              query.set({ ...query.current, voucherCode: e.target.value })
            }
          >
            {selectVoucherCode.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </ListFilters>

        <ListActions>
          <MenuExports endpoint={endpoint} query={query.current} />
        </ListActions>
      </Stack>

      <ListTable
        records={query.current.sellerId ? records : { list: [] }}
        defaultActions="none"
        headers={listTableHeaders}
      />
      <ListPagination pagination={pagination} query={query} />
    </Box>
  )
}
