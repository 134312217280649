// Vendors
import React, {
  useState,
  Dispatch,
  ReactNode,
  FocusEvent,
  useCallback,
  ChangeEvent,
} from 'react'

// Functions
import { HandleQuestionType } from 'hooks/useFormFieldEditer'

// Components
import { FieldText } from 'components/FieldText'

// Styles
import { Box, Stack, Text } from '@chakra-ui/react'

// Interfaces
export type FormFieldEditerFieldTypeProps = {
  index: number
  children?: ReactNode
  fieldTypeLabel: string
  fieldName: {
    get: string
    set: Dispatch<React.SetStateAction<string>>
  }
  handleQuestion: HandleQuestionType
}

export const FormFieldEditerFieldType = (
  props: FormFieldEditerFieldTypeProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { fieldTypeLabel, children, fieldName, handleQuestion, index } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isDirty, setIsDirty] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      fieldName.set(value)
      setIsDirty(true)
    },
    [fieldName]
  )

  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const { value } = e.target
      if (value !== '' && isDirty) {
        handleQuestion.update(index, 'label', value)
        setIsDirty(false)
      }
    },
    [handleQuestion, index, isDirty]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Box w="100%">
      <Stack direction="row" mb="2">
        <Text color="blue.500" fontWeight="medium">
          Campo do tipo
        </Text>

        <Text color="blue.500" fontWeight="bold" textTransform="uppercase">
          {fieldTypeLabel}
        </Text>
      </Stack>

      <FieldText
        name={`fieldName-${index}`}
        label="Digite o nome do campo"
        value={fieldName.get}
        onChange={onChange}
        onBlur={onBlur}
      />

      {children && <Box w="100%">{children}</Box>}
    </Box>
  )
}
