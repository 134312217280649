// Vendors
import React, { useEffect } from 'react'
import QRCode from 'qrcode.react'

// Components
import { AlertDialog } from 'components/AlertDialog'

// Styles
import { AlertDialogBody, Box, Stack } from '@chakra-ui/react'
import { useGenerateQrCode } from 'hooks/useGenerateQrCode'

// Interfaces
export type AlertDialogPrinterProps = {
  kitCode: string[]
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
}

export const AlertDialogPrinter = (
  props: AlertDialogPrinterProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { kitCode, isOpen, onCancel } = props
  const cancelRef = React.useRef<HTMLButtonElement>(null)

  const canvasRef = React.useRef<any>([])
  const canvasCtxRef = React.useRef<any>([])

  canvasRef.current = []
  canvasCtxRef.current = []

  const {
    kitsCode,
    printTag,
    canvasDrawTag,
    printerTagWidth,
    printerTagHeight,
  } = useGenerateQrCode()

  const setKitsCode = kitsCode.set

  useEffect(() => {
    if (!kitCode) return
    if (kitCode.length === 0) return

    setKitsCode(kitCode)
  }, [kitCode, setKitsCode])

  const addToRefs = (el: any) => {
    if (el && !canvasRef.current.includes(el)) {
      canvasRef.current.push(el)
    }

    if (canvasRef.current.length === kitCode.length) {
      kitCode.forEach((code, index) => {
        canvasDrawTag(
          code,
          printerTagWidth,
          printerTagHeight,
          canvasCtxRef.current[index],
          canvasRef.current[index]
        )
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <AlertDialog
      title="Área de impressão"
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={printTag}
      cancelRef={cancelRef}
      confirmLabel="Imprimir etiqueta"
    >
      <AlertDialogBody id="tag">
        {kitCode.map((code, index) => (
          <Stack key={`Tag-${code}`}>
            <Box
              id={`no-print-${index}`}
              visibility="hidden"
              position="absolute"
            >
              <QRCode id={`QRCode-${code}`} value={code} size={140} level="L" />
            </Box>

            <Box
              height={`${printerTagHeight + 20}px`}
              width={`${printerTagWidth + 20}px`}
              letterSpacing={'0.03em'}
              textTransform={'uppercase'}
              mt="8"
            >
              <canvas
                ref={addToRefs}
                id={`Tag${index}`}
                width={printerTagWidth}
                height={printerTagHeight}
              ></canvas>
            </Box>
          </Stack>
        ))}
      </AlertDialogBody>
    </AlertDialog>
  )
}
