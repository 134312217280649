import { Query } from 'types/Requests'
import api from './api'

const handlePutFailure = (error: unknown): void => {
  console.log(
    `Um erro ocorreu na edição do item, por favor tente novamente.`,
    error
  )
}

export async function apiPut<T>(
  endpoint: string,
  data: T,
  query?: Query
): Promise<T | undefined> {
  let payload
  try {
    const { data: response } = await api.put(endpoint, data, {
      params: { ...query },
    })
    payload = response
  } catch (error: unknown) {
    payload = undefined
    throw error
  }
  return payload
}
