// Vendors
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'

// Hooks
import { apiShow } from 'services/get'
import { useError } from 'hooks/useError'
import { useGlobalConfig } from 'contexts/globalConfig'
import { useConfirmationDialog } from 'contexts/confirmationDialog'

// Components
import { FieldText } from 'components/FieldText'
import { ModalQrCodeScanner } from 'components/ModalQrCodeScanner'

// Styles
import Layout from 'layout/Private'
import { FiInfo } from 'react-icons/fi'
import { ImQrcode, ImBarcode } from 'react-icons/im'
import {
  Box,
  Text,
  Stack,
  Button,
  Heading,
  ButtonGroup,
  useDisclosure,
} from '@chakra-ui/react'
import { ModalBarCodeScanner } from 'components/ModalBarCodeScanner'

import { KitCheckInShow } from './show/types'

export const KitCheckIn = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('laboratory')
  const history = useHistory()
  const { getConfirmation } = useConfirmationDialog()
  const disclosure = useDisclosure()
  const disclosureBarCode = useDisclosure()
  const { handleError } = useError()

  const { formatActivationCode, validateActivationCode } = useGlobalConfig()

  const { control, setValue, handleSubmit, getValues } = useForm()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isLoading, setIsLoading] = React.useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = useCallback(
    async (activateCode: string) => {
      try {
        setIsLoading(true)

        const isValidActivationsCode = validateActivationCode(activateCode)

        if (!isValidActivationsCode) {
          throw new Error('Código de ativação inválido')
        }

        const response = await apiShow<KitCheckInShow>(
          `/app/kit/${activateCode}`
        )

        if (!response) {
          throw new Error('Kit não encontrado')
        }

        if (response.status === 'PENDING_ACTIVATION') {
          throw new Error(t('toast.error.pendingActivation'))
        }

        if (response.isLegacy) {
          throw new Error('LEGACY')
        }

        history.push(`/laboratory/kit-check-in/${activateCode}`)
      } catch (error: any) {
        if (error.message === 'LEGACY') {
          getConfirmation({
            title: 'Atenção',
            message: (
              <>
                <Text>
                  A amostra <Text as="strong">{activateCode}</Text> ainda não
                  está cadastrada no sistema novo. Para proceder, envie para o
                  Stuart as informações abaixo:
                </Text>

                <Box my="6" bg="#f6f6f6" p="6">
                  <Text textAlign="center">PEDIDO DE CADASTRO EM SISTEMA</Text>
                  <Text textAlign="center">{activateCode}</Text>
                </Box>

                <Text>
                  Após a confirmação de cadastro você poderá fazer o check-in
                  normalmente.
                </Text>
              </>
            ),
          })
          return
        }

        handleError(error)
      } finally {
        setIsLoading(false)
      }
    },
    [getConfirmation, handleError, history, t, validateActivationCode]
  )

  const handleCheckIn = useCallback(
    async (data: { activateCode: string }) => {
      await onSubmit(data.activateCode)
    },
    [onSubmit]
  )

  const handleScan = useCallback(
    async (activateCode: string) => {
      const oldActivateCode = getValues('activateCode')

      if (oldActivateCode === activateCode) return

      await onSubmit(activateCode)

      setValue('activateCode', activateCode)
    },
    [getValues, onSubmit, setValue]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout title={t('title.kitCheckIn')}>
      <ModalQrCodeScanner
        onScan={handleScan}
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        isLoading={isLoading}
      />

      <ModalBarCodeScanner
        onScan={handleScan}
        isLoading={isLoading}
        isOpen={disclosureBarCode.isOpen}
        onClose={disclosureBarCode.onClose}
      />

      <Stack spacing={6}>
        <Heading size="md">{t('title.kitValidation')}</Heading>

        <Stack direction="row" alignItems="center" w="container.sm" h="100px">
          <Box h="full">
            <Controller
              name="activateCode"
              control={control}
              rules={{ required: `${t('idKitTestfy.required')}` }}
              render={({ field, formState: { errors } }) => (
                <FieldText
                  isRequired
                  label={t('idKitTestfy.label')}
                  error={errors.documentNumber}
                  {...field}
                  onChange={(e) => {
                    field.onChange(formatActivationCode(e.target.value))
                  }}
                />
              )}
            />
          </Box>

          <Button
            onClick={disclosure.onOpen}
            variant="outline"
            rightIcon={<ImQrcode />}
            colorScheme="blue"
            px={8}
          >
            {t('button.readCode')}
          </Button>

          <Button
            onClick={disclosureBarCode.onOpen}
            variant="outline"
            rightIcon={<ImBarcode />}
            colorScheme="blue"
            px={8}
          >
            {t('button.barCode')}
          </Button>
        </Stack>

        <ButtonGroup>
          <Button
            onClick={handleSubmit(handleCheckIn)}
            isLoading={isLoading}
            loadingText={t('button.loading')}
            leftIcon={
              <FiInfo
                size="18"
                fill="white"
                color="var(--chakra-colors-blue-500)"
              />
            }
            colorScheme="blue"
          >
            {t('button.checkId')}
          </Button>
        </ButtonGroup>
      </Stack>
    </Layout>
  )
}
