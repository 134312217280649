// Vendors
import { v4 } from 'uuid'
import { pick, get } from 'lodash'
import { useCallback, useState, useMemo } from 'react'

// Components
import { FormFieldEditerDate } from 'components/FormFieldEditerDate'
import { FormFieldEditerText } from 'components/FormFieldEditerText'
import { FormFieldEditerTitle } from 'components/FormFieldEditerTitle'
import { FormFieldEditerNumber } from 'components/FormFieldEditerNumber'
import { FormFieldEditerSelect } from 'components/FormFieldEditerSelect'
import { FormFieldEditerTextArea } from 'components/FormFieldEditerTextArea'
import { FormFieldEditerSingleChoice } from 'components/FormFieldEditerSingleChoice'
import { FormFieldEditerMultipleChoice } from 'components/FormFieldEditerMultipleChoice'

// Types
import { FieldTypeEnum, FormQuestionType } from 'types/LaboratorialAnalysis'
import { FormFieldEditerProps } from 'components/FormFieldEditer'

type FieldType = keyof typeof FieldTypeEnum
type UpdateAccessor = keyof Pick<
  QuestionType,
  'label' | 'options' | 'required' | 'constraints' | 'value'
>

export type QuestionType = FormQuestionType & {
  component: (props: FormFieldEditerProps) => JSX.Element
  id: string
}
export type HandleQuestionType = {
  remove: (id: string) => void
  parser: () => FormQuestionType[]
  defaultValues: (formQuestions: FormQuestionType[]) => void
  add: (fieldType: FieldType) => void
  move: (from: number, to: number) => void
  update<T extends UpdateAccessor>(
    index: number,
    accessor: T,
    value: QuestionType[T]
  ): void
}

type UseFormFieldEditerType = {
  questions: QuestionType[]
  handleQuestion: HandleQuestionType
}

export function useFormFieldEditer(): UseFormFieldEditerType {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [questions, setQuestions] = useState<QuestionType[]>([])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const fieldTypeComponentMap = useMemo(
    () => ({
      [FieldTypeEnum.date]: FormFieldEditerDate,
      [FieldTypeEnum.text]: FormFieldEditerText,
      [FieldTypeEnum.range]: FormFieldEditerText,
      [FieldTypeEnum.title]: FormFieldEditerTitle,
      [FieldTypeEnum.number]: FormFieldEditerNumber,
      [FieldTypeEnum.select]: FormFieldEditerSelect,
      [FieldTypeEnum.textarea]: FormFieldEditerTextArea,
      [FieldTypeEnum.checkbox]: FormFieldEditerMultipleChoice,
      [FieldTypeEnum.radiobutton]: FormFieldEditerSingleChoice,
    }),
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const questionFactory = useCallback(
    (fieldType: FieldType): QuestionType => ({
      id: v4(),
      label: '',
      value: '',
      fieldType,
      options: [],
      required: false,
      constraints: [],
      component: fieldTypeComponentMap[fieldType],
    }),
    [fieldTypeComponentMap]
  )

  const addQuestion = useCallback(
    (fieldType: FieldType) => {
      const question = questionFactory(fieldType)

      setQuestions((oldState) => [...oldState, question])
    },
    [questionFactory]
  )

  const removeQuestion = useCallback((id: string) => {
    setQuestions((oldState) => oldState.filter((item) => item.id !== id))
  }, [])

  const updateQuestion = useCallback(
    <T extends UpdateAccessor>(
      index: number,
      accessor: T,
      value: QuestionType[T]
    ) => {
      const newQuestions = questions.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [accessor]: value,
          }
        }

        return item
      })

      setQuestions(newQuestions)
    },
    [questions]
  )

  const moveQuestion = useCallback(
    (from: number, to: number) => {
      const newQuestions = [...questions]

      const question = newQuestions.splice(from, 1)[0]

      newQuestions.splice(to, 0, question)

      setQuestions(newQuestions)
    },
    [questions]
  )

  const parserQuestion = useCallback(() => {
    const formQuestion: FormQuestionType[] = questions.map((item, index) => {
      return {
        ...pick(item, [
          'label',
          'value',
          'options',
          'required',
          'fieldType',
          'constraints',
        ]),
        order: index + 1,
      }
    })

    return formQuestion
  }, [questions])

  const questionsDefaultValues = useCallback(
    (formQuestions: FormQuestionType[]) => {
      const formatedQuestions: QuestionType[] = formQuestions.map((item) => {
        const component = get(fieldTypeComponentMap, item.fieldType)

        return {
          ...item,
          id: v4(),
          component: component,
        }
      })

      setQuestions(formatedQuestions)
    },
    [fieldTypeComponentMap]
  )

  /*
  |-----------------------------------------------------------------------------
  | Return
  |-----------------------------------------------------------------------------
  |
  |
  */

  return {
    questions,
    handleQuestion: {
      add: addQuestion,
      move: moveQuestion,
      remove: removeQuestion,
      update: updateQuestion,
      parser: parserQuestion,
      defaultValues: questionsDefaultValues,
    },
  }
}
