// Vendors
import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { debounce } from 'lodash'

// Styles
import {
  FormControl,
  InputGroup,
  FormLabel,
  InputLeftElement,
  Input,
  Stack,
} from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'

// Types
import { Query } from 'types/Requests'

// Interfaces
export type ListFiltersProps = {
  children?: React.ReactNode
  query: {
    current: Query
    set: Dispatch<SetStateAction<Query>>
  }
}

export const ListFilters = (props: ListFiltersProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { children, query } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateQuerySearch = useCallback(
    debounce((e) => {
      query.set((current: Query) => {
        return { ...current, q: e.target.value, page: 1 }
      })
    }, 500),
    [query]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Stack>
      <FormControl minW={{ md: '320px' }} id="search">
        <InputGroup size="sm">
          <FormLabel srOnly>Filtrar por nome ou e-mail</FormLabel>
          <InputLeftElement pointerEvents="none" color="gray.400">
            <FiSearch />
          </InputLeftElement>
          <Input
            rounded="base"
            type="search"
            placeholder="Filtrar por nome ou e-mail"
            onChange={(e) => {
              updateQuerySearch(e)
            }}
          />
        </InputGroup>
      </FormControl>
      {children}
    </Stack>
  )
}
