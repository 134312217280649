// Vendors
import React, { useMemo } from 'react'

// Components
import { TabDetail, Tabs } from 'components/Tabs'
import { TabInfluencerNew } from './tabs/TabInfluencerNew'

// Styles
import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

// Types
type InfluencerFormNewProps = {
  endpoint: string
}

export const InfluencerFormNew = (
  props: InfluencerFormNewProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('influencer')
  const { endpoint } = props

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: t('tabs.personalData'),
        component: TabInfluencerNew,
        options: { endpoint },
      },
    ],
    [t, endpoint]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex h="full" flexDirection="column" justifyContent="space-between">
      <Tabs tabsDetails={tabsDetails} />
    </Flex>
  )
}
