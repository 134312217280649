import { SelectType } from 'hooks/useSelectParser'
import { Record } from 'types/Requests'

export enum GenderEnum {
  MALE = 'M',
  FEMALE = 'F',
  NOT_INFORMED = 'NOT_INFORMED',
}

export enum CouncilEnum {
  councilValue = 'council-value',
  councilType = 'council-type',
  councilStateValue = 'council-state-value',
  councilFileFront = 'council-file-front',
  councilFileBack = 'council-file-back',
  councilFileSelfie = 'council-file-selfie',
}

export enum DocumentEnum {
  cpfFileFront = 'cpf-file-front',
  cpfFileBack = 'cpf-file-back',
}

export enum ClinicEnum {
  recordClinicId = 'record-clinic-id',
}

export type ProfessionalShowForm = {
  name: string
  email: string
  phoneNumber: string
  documentNumber: string
  birthdate: Date
  status: string
  gender: keyof typeof GenderEnum | SelectType<any>
  sellerUserId: string | SelectType<any>
  statusRoleProfessional: string
  [CouncilEnum.councilValue]: string
  [CouncilEnum.councilType]: string
  [CouncilEnum.councilStateValue]: string | SelectType<any>
  [CouncilEnum.councilFileFront]: PersonalDataFileType | undefined
  [CouncilEnum.councilFileBack]: PersonalDataFileType | undefined
  [CouncilEnum.councilFileSelfie]: PersonalDataFileType | undefined
  [ClinicEnum.recordClinicId]: string[] | SelectType<any>[]
}

export type ProfessionalShowAPI = Record & {
  pendingContract: boolean
  userId: string
  dependentOfUserId: string | null
  name: string
  socialName: string | null
  email: string
  gender: keyof typeof GenderEnum
  documentNumber: string
  documentType: string
  birthdate: Date
  phoneNumber: string
  language: string | null
  [ClinicEnum.recordClinicId]: string
  personalDataHasDocuments: PersonalDataHasDocumentType[]
  user: UserType
}

type PersonalDataHasDocumentType = Record & {
  personalDataId: string
  personalDataRequirementId: string
  personalDataFileId: string | null
  documentData: string | null
  personalDataRequirement: Record & {
    name: string
    slug: string
    requirementType: string
  }
  personalDataFile: PersonalDataFileType | null
}

export type PersonalDataFileType = Record & {
  originalFileName: string
  fileName: string
  filePath: string
  group: string | null
  mimeType: string
  size: string
}

type UserType = Record & {
  username: string
  rememberMeToken: string | null
  status: string
  lastLoginAt: string | null
  roles: RoleType[]
  companies: CompanyType[]
  professionalHasSeller: {
    id: string
    createdAt: string
    updatedAt: string
    sellerUserId: string
    professionalUserId: string
  }
}

type CompanyType = {
  id: string
  status: string
  internalIdentification: string
  endActivity: Date | null
  createdAt: Date
  updatedAt: Date
  companyTypeId: string
}

type RoleType = Record & {
  name: string
  slug: string
  status: string
  roleUser: RoleUserType[]
}

type RoleUserType = Record & {
  status: string
  userId: string
  roleId: string
}
