// Vendors
import React from 'react'

// Components
import { CardDetailsItem, ICardDetailsItem } from '../CardDetailsItem'

// Styles
import { Box, useColorModeValue, Flex, Heading } from '@chakra-ui/react'

// Interfaces
export interface ICardDetails {
  icon?: React.ReactElement
  title?: string
  data: (ICardDetailsItem | null)[]
}

export const CardDetails: React.FC<ICardDetails> = (props) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { title, data, icon } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Box as="section" w="full">
      <Box
        bg={useColorModeValue('white', 'gray.700')}
        rounded={{ md: 'lg' }}
        shadow="base"
        overflow="hidden"
      >
        {title && (
          <Box>
            <Flex
              align="center"
              justify="space-between"
              px="6"
              py="4"
              borderBottomWidth="1px"
            >
              <Heading as="h2" fontSize="lg" display="flex">
                {icon && (
                  <Box as="span" pr="3">
                    {icon}
                  </Box>
                )}
                {title}
              </Heading>
            </Flex>
          </Box>
        )}
        <Box>
          {data &&
            data.map((item, i) => {
              if (!item) return null
              return <CardDetailsItem key={item.label} {...item} />
            })}
        </Box>
      </Box>
    </Box>
  )
}
