// Vendors
import React, { useCallback } from 'react'
import { remove } from 'lodash'

// Components

// Styles
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Box,
  AlertStatus,
} from '@chakra-ui/react'

// Interfaces
export interface IFormFeedbackItem {
  title?: string
  content: React.ReactNode
  type: AlertStatus
  layout?: 'left' | 'center'
  setData?: any
}

export const FormFeedbackItem: React.FC<IFormFeedbackItem> = (props) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { title, content, type, layout = 'left', setData } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */
  const handleRemoveItem = useCallback((item: IFormFeedbackItem) => {
    if (!item.setData) return

    item.setData((current: any) => {
      remove(current, {
        type: item?.type,
        title: item.title,
        content: item.content,
      })
      return [...current]
    })
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <>
      {layout === 'left' && (
        <Alert status={type} borderRadius="6">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle mr={2}>{title}</AlertTitle>
            <AlertDescription display="block">{content}</AlertDescription>
          </Box>

          <CloseButton
            onClick={() => {
              handleRemoveItem({ type, title, content, layout, setData })
            }}
            position="absolute"
            right="8px"
            top="8px"
          />
        </Alert>
      )}
      {layout === 'center' && (
        <Alert
          status={type}
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
          borderRadius="6"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            {title}
          </AlertTitle>
          <AlertDescription maxWidth="sm">{content}</AlertDescription>
          <CloseButton
            onClick={() => {
              handleRemoveItem({ type, title, content, layout, setData })
            }}
            position="absolute"
            right="8px"
            top="8px"
          />
        </Alert>
      )}
    </>
  )
}
