import { chakra, HTMLChakraProps, useToken } from '@chakra-ui/react'
import * as React from 'react'

export const GeneticsThree = (
  props: HTMLChakraProps<'svg'> & {
    iconColor?: string
    colorStopInit: string
    colorStopEnd: string
  }
) => {
  const { iconColor, colorStopInit, colorStopEnd, ...rest } = props
  const color = iconColor || 'white'

  const [colorInit, colorEnd] = useToken('colors', [
    colorStopInit,
    colorStopEnd,
  ])

  return (
    <chakra.svg
      id="Componente_54_1"
      data-name="Componente 54 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="53.73"
      height="53.73"
      viewBox="0 0 53.73 53.73"
      {...rest}
    >
      <svg>
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.846"
            y1="0.041"
            x2="0.097"
            y2="0.903"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor={colorInit} />
            <stop offset="1" stopColor={colorEnd} />
          </linearGradient>
          <clipPath id="clip-path">
            <path
              id="Caminho_158"
              data-name="Caminho 158"
              d="M26.865,53.73A26.865,26.865,0,1,0,0,26.865,26.865,26.865,0,0,0,26.865,53.73Z"
              transform="translate(0 0)"
              fill="url(#linear-gradient)"
            />
          </clipPath>
        </defs>
        <path
          id="Caminho_10"
          data-name="Caminho 10"
          d="M26.865,53.73A26.865,26.865,0,1,0,0,26.865,26.865,26.865,0,0,0,26.865,53.73Z"
          fill="url(#linear-gradient)"
        />
        <g
          id="Grupo_de_máscara_1"
          data-name="Grupo de máscara 1"
          clipPath="url(#clip-path)"
        >
          <g
            id="Grupo_6"
            data-name="Grupo 6"
            transform="translate(-12.847 19.008)"
          >
            <g
              id="Grupo_3"
              data-name="Grupo 3"
              transform="translate(20.63 6.564)"
            >
              <path
                id="Caminho_155"
                data-name="Caminho 155"
                d="M295.405,47.548H254.95a1.2,1.2,0,0,1,0-2.4h40.455a1.2,1.2,0,0,1,0,2.4Z"
                transform="translate(-253.751 -45.148)"
                fill={color}
              />
            </g>
            <g id="Grupo_4" data-name="Grupo 4" transform="translate(36.573)">
              <path
                id="Caminho_156"
                data-name="Caminho 156"
                d="M303.381,44.261H262.926a1.2,1.2,0,0,1,0-2.4h40.456a1.2,1.2,0,1,1,0,2.4Z"
                transform="translate(-261.729 -41.863)"
                fill={color}
              />
            </g>
            <g id="Grupo_5" data-name="Grupo 5" transform="translate(0 13.317)">
              <path
                id="Caminho_157"
                data-name="Caminho 157"
                d="M285.081,50.925H244.624a1.2,1.2,0,0,1,0-2.4h40.457a1.2,1.2,0,1,1,0,2.4Z"
                transform="translate(-243.427 -48.527)"
                fill={color}
              />
            </g>
          </g>
        </g>
      </svg>
    </chakra.svg>
  )
}
