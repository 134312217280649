// Vendors
import { debounce } from 'lodash'
import React, { useCallback, useState } from 'react'
import { Controller, FieldError, useFormContext } from 'react-hook-form'

// Functions
import { apiList } from 'services/get'
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FieldSet } from 'components/FieldSet'
import { FieldNumber } from 'components/FieldNumber'
import { FieldSelect } from 'components/FieldSelect'
import { OptionsType } from 'components/FormFieldEditerOptions'
import { FormCompanyInfo } from 'pages/private/Company/form/FormCompanyInfo'
import { FormCompanyAddress } from 'pages/private/Company/form/FormCompanyAddress'

// Styles
import { FormOtherInfoType } from '../show/types'
import { Box, Grid, GridItem } from '@chakra-ui/react'

export const TabGeneralShow = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control,
    formState: { errors },
  } = useFormContext<FormOtherInfoType>()

  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectStates = useSelectParser({
    endpoint: '/app/state',
    accessor: { label: 'abbreviatedName', value: 'id' },
    requestWithoutMeta: true,
  })

  const selectLogisticManagers = useSelectParser({
    endpoint: '/app/person?type=logistic-manager',
    accessor: { label: 'name', value: 'userId' },
  })

  const selectLogisticAgents = useSelectParser({
    endpoint: '/app/person?type=logistic-agent',
    accessor: { label: 'name', value: 'userId' },
  })

  const [selectCities, setSelectCities] = useState<{
    options: OptionsType[]
    isLoading: boolean
  }>({
    options: [],
    isLoading: false,
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onInputChange = useCallback(
    debounce(async (value) => {
      if (value.length < 3) return

      try {
        setSelectCities((oldState) => ({
          options: oldState.options,
          isLoading: true,
        }))

        const response = await apiList<{ name: string; id: string }[]>(
          `/app/city?q=${value}`
        )

        if (!response) return

        const selectCitiesFormatted = response.map((item) => ({
          label: item.name,
          value: item.id,
        }))

        setSelectCities({ options: selectCitiesFormatted, isLoading: false })
      } catch (error) {
        console.error(error)
      } finally {
        setSelectCities((oldState) => ({
          options: oldState.options,
          isLoading: false,
        }))
      }
    }, 500),
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <FormCompanyInfo />
      <FormCompanyAddress />

      <FieldSet title="Outras informações">
        <Grid my="4" gap="10" templateColumns={{ lg: 'repeat(2, 1fr)' }}>
          <GridItem>
            <Controller
              name="states"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label="Estados atendidos"
                  error={errors.states as FieldError}
                  isLoading={selectStates.isLoading}
                  options={selectStates.options}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="cities"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label="Cidades atendidas"
                  error={errors.cities as FieldError}
                  isLoading={selectCities.isLoading}
                  options={selectCities.options}
                  placeholder="Digite pelo menos 3 caracteres..."
                  onInputChange={(value) => {
                    onInputChange(value)
                  }}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="logisticManagers"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label="Gestores vinculados"
                  error={errors.logisticManagers as FieldError}
                  isLoading={selectLogisticManagers.isLoading}
                  options={selectLogisticManagers.options}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="logisticAgents"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label="Motoboys vinculados"
                  error={errors.logisticAgents as FieldError}
                  isLoading={selectLogisticAgents.isLoading}
                  options={selectLogisticAgents.options}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <FieldNumber
              control={control}
              name="deliveryPrice"
              label="Valor por entrega por cidade"
              error={errors.deliveryPrice}
              isRequired
            />
          </GridItem>

          <GridItem>
            <FieldNumber
              control={control}
              name="maxHeightInCentimeter"
              label="Altura máxima da embalagem (cm)"
              error={errors.maxHeightInCentimeter}
              isRequired
            />
          </GridItem>

          <GridItem>
            <FieldNumber
              control={control}
              name="maxWidthInCentimeter"
              label="Largura máxima da embalagem (cm)"
              error={errors.maxWidthInCentimeter}
              isRequired
            />
          </GridItem>

          <GridItem>
            <FieldNumber
              control={control}
              name="maxLengthInCentimeter"
              label="Comprimento máximo da embalagem (cm)"
              error={errors.maxLengthInCentimeter}
              isRequired
            />
          </GridItem>

          <GridItem>
            <FieldNumber
              control={control}
              name="maxWeigthInGrams"
              label="Peso máximo da embalagem (g)"
              error={errors.maxWeigthInGrams}
              isRequired
            />
          </GridItem>
        </Grid>
      </FieldSet>
    </Box>
  )
}
