import { AxiosError } from 'axios'
import { Query } from 'types/Requests'
import api from './api'

export async function apiPost<T, K = T>(
  endpoint: string,
  data?: T,
  query?: Query
): Promise<K | undefined> {
  let payload
  try {
    const { data: response } = await api.post(endpoint, data, {
      params: { ...query },
    })
    payload = response
  } catch (error: unknown | AxiosError) {
    payload = undefined
    throw error
  }
  return payload
}
