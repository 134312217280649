// Vendors
import { filter, kebabCase } from 'lodash'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// Context
import { useCart } from 'contexts/cart'

// Hooks
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FieldSelect } from 'components/FieldSelect'
import { FormWrapper } from 'components/FormWrapper'

// Styles
import { Grid, GridItem } from '@chakra-ui/react'

// Types
import { Record } from 'types/Requests'

export type PaymentMethodType = Record & {
  name: string
  status: 'ENABLED' | 'DISABLED'
  incentiveDiscountPercentage: number | null
  incentiveDiscountDescription: number | null
  slug: string
}

export type ShippingMethodType = Record & {
  providerName: string
  description: string
  serviceCode: string
  serviceCollectType: null
  serviceName: string
  isReverse: boolean
  status: 'ENABLED' | 'DISABLED'
  isAttendanceAtHome: boolean
}

export const PAYMENT_METHODS_NAMES = [
  'maquina-de-cartao',
  'link-de-pagamento',
  'bonificado',
  'mediado-pelo-financeiro',
]

export const SHIPPING_METHODS_SERVICE_NAME = [
  'order-pickup',
  'motoboy',
  'sedex',
]

export const PaymentAndShipping = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('checkout')

  const { addShippingMethodToCart, addPaymentMethodToCart } = useCart()

  const {
    control,
    formState: { errors },
  } = useFormContext()

  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectShippingMethods = useSelectParser<ShippingMethodType>({
    accessor: { label: 'providerName', value: 'id' },
    endpoint: '/public/purchase/shipping_method',
    callback: (data) => {
      return filter(data, (item) =>
        SHIPPING_METHODS_SERVICE_NAME.includes(kebabCase(item.serviceName))
      )
    },
  })

  const selectPaymentMethods = useSelectParser<PaymentMethodType>({
    accessor: { label: 'name', value: 'id' },
    endpoint: '/public/purchase/payment_method',
    callback: (data) => {
      return filter(data, (item) =>
        PAYMENT_METHODS_NAMES.includes(kebabCase(item.name))
      )
    },
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleAddPaymentMethod = useCallback(
    async (paymentMethodId: string) => {
      try {
        await addPaymentMethodToCart(paymentMethodId)
      } catch (error) {
        console.error(error)
      }
    },
    [addPaymentMethodToCart]
  )

  const handleAddShippingMethod = useCallback(
    async (shippingMethodId: string) => {
      try {
        await addShippingMethodToCart(shippingMethodId)
      } catch (error) {
        console.error(error)
      }
    },
    [addShippingMethodToCart]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormWrapper title={t('group.paymentAndShipping')}>
      <Grid
        my="4"
        gap="10"
        w={{ lg: 'container.md' }}
        templateColumns={{ lg: 'repeat(2, 1fr)' }}
      >
        <GridItem>
          <Controller
            name="paymentMethodId"
            control={control}
            render={({ field }) => (
              <FieldSelect
                isRequired
                error={errors.paymentMethodId}
                label={t('form.paymentMethodId.label')}
                options={selectPaymentMethods.options}
                isLoading={selectPaymentMethods.isLoading}
                placeholder={t('form.paymentMethodId.placeholder')}
                {...field}
                onChange={(e: any) => {
                  handleAddPaymentMethod(e.value)
                  field.onChange(e)
                }}
              />
            )}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="shippingMethodId"
            control={control}
            render={({ field }) => (
              <FieldSelect
                isRequired
                error={errors.shippingMethodId}
                options={selectShippingMethods.options}
                label={t('form.shippingMethodId.label')}
                isLoading={selectShippingMethods.isLoading}
                placeholder={t('form.shippingMethodId.placeholder')}
                {...field}
                onChange={(e: any) => {
                  handleAddShippingMethod(e.value)
                  field.onChange(e)
                }}
              />
            )}
          />
        </GridItem>
      </Grid>
    </FormWrapper>
  )
}
