import React from 'react'

import { AlertDialogBody, ButtonProps } from '@chakra-ui/react'
import { AlertDialog } from 'components/AlertDialog'

type AlertDeliveryProps = {
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => Promise<void>
  cancelRef?: React.RefObject<HTMLButtonElement>
  title: string
  message: string | React.ReactElement
  confirmText?: string
  confirmProps?: ButtonProps
}

export const AlertDelivery = (props: AlertDeliveryProps) => {
  const {
    title,
    message,
    isOpen,
    onCancel,
    onConfirm,
    confirmText = 'Confirmar',
    cancelRef,
    confirmProps,
  } = props

  return (
    <AlertDialog
      title={title}
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelRef={cancelRef}
      confirmLabel={confirmText}
      confirmProps={confirmProps}
    >
      <AlertDialogBody>{message}</AlertDialogBody>
    </AlertDialog>
  )
}
