// Vendors
import React from 'react'

// Components

import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

// Styles
import { Box, Text } from '@chakra-ui/react'

// Interfaces
export interface IGraphPie {
  title: string
  subtitle: string
  options?: ApexOptions
  series?: number[]
}

const DEFAULT_OPTIONS: ApexOptions = {
  colors: ['#008ffb', '#3ae396', '#3c92a8'],
  labels: ['Correios', 'Motoboy'],
  legend: {
    horizontalAlign: 'center',
    position: 'bottom',
    fontSize: '14px',
  },
}

export const GraphPie: React.FC<IGraphPie> = (props) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { title, subtitle, options, series } = props
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Box
      bg={'white'}
      shadow="base"
      rounded="lg"
      px={4}
      py={6}
      position="relative"
    >
      <Text as={'h2'} fontSize="lg" fontWeight="bold" color={'blue.500'}>
        {title}
      </Text>
      <Text as={'p'} fontSize="md" color={'gray.500'} pt={2}>
        {subtitle}
      </Text>
      <ReactApexChart
        series={series}
        type="pie"
        height={350}
        options={{ ...DEFAULT_OPTIONS, ...options }}
      />
    </Box>
  )
}
