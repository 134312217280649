// Vendors
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form'

// Hooks
import { useCreateRecord } from 'hooks/useCreateRecord'

// Components
import { TabSeo } from 'components/TabSeo'
import { TabNewPost } from '../tabs/TabNewPost'
import { TabDetail, Tabs } from 'components/Tabs'
import { FormFooter } from 'components/FormFooter'
import { FieldSelect } from 'components/FieldSelect'

// Styles
import { Box, Flex, useToast } from '@chakra-ui/react'
import { schema } from './schema'

// Types
import { BlogStatusEnum } from 'types/Blog'
type BlogFormNewProps = {
  endpoint: string
}

export const BlogFormNew = (props: BlogFormNewProps): JSX.Element => {
  const optionsStatus = useMemo(() => {
    return Object.entries(BlogStatusEnum).map(([key, value]) => {
      return {
        label: value.charAt(0).toUpperCase() + value.substr(1),
        value: key,
      }
    })
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props
  const { create } = useCreateRecord({ endpoint })

  const { t } = useTranslation('common')
  const history = useHistory()
  const toast = useToast({ position: 'top-right' })

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [textEditorData, setTextEditorData] = useState('')

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Postagem',
        component: TabNewPost,
        options: { setTextEditorData },
        names: [
          'featured',
          'title',
          'subtitle',
          'articleAuthor',
          'articleLead',
          'accentColor',
          'tagIds',
          'categoryId',
          'publishingDate',
        ],
      },
      {
        title: 'SEO',
        component: TabSeo,
        names: ['slug', 'seoTitle', 'seoKeywords', 'seoDescription'],
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        await create({ ...data, articleBody: textEditorData })
        history.goBack()

        toast({
          title: 'Criado com sucesso',
          description: 'Postagem criada com sucesso',
          status: 'success',
        })
      } catch (error) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [create, history, methods, textEditorData, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box position="absolute" top={-14} right={0} w="32">
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => (
              <FieldSelect
                label="Status"
                error={methods.formState.errors.status as FieldError}
                options={optionsStatus}
                isRequired
                {...field}
              />
            )}
          />
        </Box>

        <Tabs tabsDetails={tabsDetails} errors={methods.formState.errors} />

        <FormFooter isSubmitting={methods.formState.isSubmitting} hasAlert />
      </Flex>
    </FormProvider>
  )
}
