import * as React from 'react'
import { Box, Heading } from '@chakra-ui/react'

interface PageHeaderProps {
  title: string
}

const PageHeader: React.FC<PageHeaderProps> = (props: PageHeaderProps) => {
  const { title } = props
  return (
    <Box as="section">
      <Heading size="lg" mb="6">
        {title}
      </Heading>
    </Box>
  )
}

export default PageHeader
