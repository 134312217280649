import { chakra, HTMLChakraProps, useToken } from '@chakra-ui/react'
import * as React from 'react'

export const ReportModel = (
  props: HTMLChakraProps<'svg'> & {
    colorStopInit: string
    colorStopEnd: string
  }
) => {
  const { colorStopInit, colorStopEnd, ...rest } = props
  return (
    <chakra.svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="url(#paint0_linear_1918:3921)"
      />
      <rect
        x="10.75"
        y="8.75"
        width="18.5"
        height="22.5"
        rx="1.25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <rect
        x="13.25"
        y="11.25"
        width="1.5"
        height="0.5"
        stroke="white"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <rect
        x="13.25"
        y="13.25"
        width="1.5"
        height="0.5"
        stroke="white"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <rect
        x="16.25"
        y="11.25"
        width="1.5"
        height="0.5"
        stroke="white"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <rect
        x="16.25"
        y="13.25"
        width="1.5"
        height="0.5"
        stroke="white"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M24 17C23.2257 17.001 22.4809 16.7027 21.9212 16.1676C21.3614 15.6324 21.03 14.9018 20.9961 14.1281C20.9621 13.3544 21.2283 12.5975 21.739 12.0153C22.2497 11.4332 22.9654 11.0708 23.7369 11.0039C24.5083 10.937 25.2758 11.1707 25.8791 11.6561C26.4824 12.1416 26.8749 12.8414 26.9746 13.6094C27.0744 14.3774 26.8737 15.1542 26.4145 15.7778C25.9552 16.4013 25.2729 16.8233 24.51 16.9558C24.3416 16.9854 24.171 17.0002 24 17ZM24 11.741C23.5533 11.7411 23.1167 11.8738 22.7454 12.1221C22.3741 12.3704 22.0847 12.7233 21.9139 13.1361C21.7431 13.5489 21.6985 14.003 21.7858 14.4412C21.8731 14.8793 22.0883 15.2817 22.4042 15.5975C22.7202 15.9134 23.1226 16.1284 23.5607 16.2154C23.9989 16.3025 24.453 16.2577 24.8656 16.0866C25.2783 15.9156 25.6309 15.626 25.879 15.2545C26.1271 14.883 26.2595 14.4463 26.2594 13.9995C26.2596 13.7028 26.2013 13.4089 26.0878 13.1348C25.9743 12.8606 25.8078 12.6115 25.598 12.4017C25.3881 12.192 25.139 12.0257 24.8648 11.9123C24.5906 11.799 24.2967 11.7408 24 11.7411V11.741Z"
        fill="white"
      />
      <path
        d="M24.0006 14.3704H21.3716C21.2733 14.3704 21.179 14.3314 21.1095 14.2618C21.04 14.1923 21.001 14.0981 21.001 13.9998C21.001 13.9015 21.04 13.8072 21.1095 13.7377C21.179 13.6682 21.2733 13.6291 21.3716 13.6291H23.6305V11.3699C23.6305 11.2716 23.6695 11.1773 23.739 11.1078C23.8085 11.0383 23.9028 10.9993 24.0011 10.9993C24.0993 10.9993 24.1936 11.0383 24.2631 11.1078C24.3326 11.1773 24.3716 11.2716 24.3716 11.3699V13.9993C24.3717 14.048 24.3622 14.0963 24.3435 14.1414C24.3249 14.1864 24.2976 14.2274 24.2631 14.2618C24.2286 14.2963 24.1877 14.3237 24.1426 14.3423C24.0976 14.3609 24.0493 14.3705 24.0006 14.3704Z"
        fill="white"
      />
      <path
        d="M25.8548 16.2257C25.7564 16.2255 25.6622 16.1863 25.5927 16.1167L23.7386 14.2614C23.6691 14.192 23.63 14.0978 23.6299 13.9996C23.6298 13.9014 23.6687 13.8071 23.7381 13.7376C23.8075 13.6681 23.9016 13.629 23.9998 13.6289C24.098 13.6288 24.1922 13.6677 24.2617 13.7371L26.1169 15.5925C26.1686 15.6444 26.2038 15.7105 26.218 15.7823C26.2322 15.8542 26.2249 15.9287 26.1968 15.9964C26.1688 16.0641 26.1214 16.122 26.0605 16.1628C25.9996 16.2036 25.928 16.2254 25.8548 16.2256V16.2257Z"
        fill="white"
      />
      <line
        x1="13.5"
        y1="20.5"
        x2="26.5"
        y2="20.5"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="13.5"
        y1="22.5"
        x2="26.5"
        y2="22.5"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="13.5"
        y1="24.5"
        x2="26.5"
        y2="24.5"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="13.5"
        y1="26.5"
        x2="26.5"
        y2="26.5"
        stroke="white"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1918:3921"
          x1="34.68"
          y1="-1.09564e-06"
          x2="5.48"
          y2="36.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colorStopInit} />
          <stop offset="1" stopColor={colorStopEnd} />
        </linearGradient>
      </defs>
    </chakra.svg>
  )
}
