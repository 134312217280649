// Vendors
import React from 'react'

// Components
import Todo from 'tools/Todo'

// Styles
import {
  Stack,
  Flex,
  Circle,
  Text,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react'
import { FiBox, FiDroplet, FiPlus } from 'react-icons/fi'
import { format } from 'date-fns'

// Enums
export const ActionIcon = {
  IN_EXPEDITION: { icon: <FiDroplet />, label: 'Em expedição' },
  SENT: { icon: <FiBox />, label: 'Enviado' },
} as const

// Interfaces
export interface ITimelineItem {
  action: keyof typeof ActionIcon
  date: string
  userName: string
  isLastItem?: boolean
  description?: string
  id: string
}

export const TimelineItem: React.FC<ITimelineItem> = (props) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { date, action, userName, isLastItem, description } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Stack as="li" direction="row" spacing="4" width="full">
      <Flex direction="column" alignItems="center" aria-hidden="true">
        <Circle
          bg={useColorModeValue('blue.500', 'blue.300')}
          size="12"
          borderWidth="4px"
          borderColor={useColorModeValue('white', 'gray.800')}
          color={useColorModeValue('white', 'black')}
        >
          {ActionIcon[action]?.icon || <FiPlus />}
        </Circle>

        {!isLastItem && <Flex flex="1" borderRightWidth="1px" mb="-12" />}
      </Flex>

      <Stack spacing="4" pt="1" flex="1">
        <Flex width="full" direction="column">
          <Heading fontSize="md" fontWeight="semibold">
            {ActionIcon[action]?.label || action}
          </Heading>

          <Text
            fontSize="sm"
            color={useColorModeValue('gray.600', 'gray.400')}
            display="flex"
          >
            <Text fontWeight="bold" pr={1}>
              {userName}
            </Text>

            {format(new Date(date), "'em' dd/MM/yyyy 'às' HH:mm'h'")}
          </Text>
        </Flex>

        <Flex>
          <Text color="gray.500">{description}</Text>
        </Flex>
      </Stack>
    </Stack>
  )
}
