// Vendors
import { filter, get, map, split } from 'lodash'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo, useEffect, useCallback } from 'react'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'

// Functions
import { useEditRecords } from 'hooks/useEditRecord'
import { useFormTransform } from 'hooks/useFormTransform'

// Components
import { TabSales } from './tabs/TabSales'
import { TabGeneral } from './tabs/TabGeneral'
import { TabDetail, Tabs } from 'components/Tabs'
import { FormFooter } from 'components/FormFooter'
import { FieldStatus } from 'components/FieldStatus'
import { TabPromotional } from './tabs/TabPromotional'
import { TabLaboratorialAnalysis } from './tabs/TabLaboratorialAnalysis'

// Schemas
import { schema } from './schema'

// Styles
import { Box, Flex, useToast } from '@chakra-ui/react'

// Types
import { Product } from 'types/Product'
import { ProductShowRecord } from './types'
import { apiDelete } from 'services/delete'
import { apiPost } from 'services/post'
import { TabSeo } from 'components/TabSeo'

type ProductFormShowProps = {
  id: string
  endpoint: string
}

export type DefaultImagesUrlType = {
  featuredImage: { list: any[] }
  reportOverviewImage: { list: any[] }
  complementaryImages: { list: any[] }
  specificKitImage: { list: any[] }
  backgroundImage: { list: any[] }
}

export const ProductFormShow = (props: ProductFormShowProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id, endpoint } = props
  const { t } = useTranslation('products')
  const { statusParser } = useFormTransform()
  const toast = useToast({ position: 'top-right' })

  const { record } = useEditRecords<Product, ProductShowRecord>({
    currentId: id,
    endpoint,
  })

  const recordCurrent = record.current

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const memoDefaultImagesSEO = useMemo(() => {
    const seo = recordCurrent.seoImages

    return {
      list: seo || [],
    }
  }, [recordCurrent.seoImages])

  const memoDefaultImages = useMemo(() => {
    const featuredImageFilter = filter(recordCurrent.images, {
      group: 'PROMOTIONAL_LISTING',
    })[0]

    const reportOverviewImageFilter = filter(recordCurrent.images, {
      group: 'REPORT',
    })[0]

    const complementaryImagesFilter = filter(recordCurrent.images, {
      group: 'PRODUCT',
    })

    const specificKitImageFilter = filter(recordCurrent.images, {
      group: 'SPECIFIC_KIT',
    })[0]

    const backgroundImage = filter(recordCurrent.images, {
      group: 'CUSTOM_BACKGROUND',
    })[0]

    return {
      featuredImage: { list: featuredImageFilter ? [featuredImageFilter] : [] },
      complementaryImages: {
        list: complementaryImagesFilter || [],
      },
      reportOverviewImage: {
        list: reportOverviewImageFilter ? [reportOverviewImageFilter] : [],
      },
      specificKitImage: {
        list: specificKitImageFilter ? [specificKitImageFilter] : [],
      },
      backgroundImage: {
        list: backgroundImage ? [backgroundImage] : [],
      },
    }
  }, [recordCurrent.images])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sanitizeRecord = useCallback(() => {
    if (!recordCurrent || !Object.keys(recordCurrent).length) return

    const storeProductHasLaboratoryAnalysisIds = map(
      recordCurrent.storeProductHasLaboratoryAnalysis,
      'id'
    )

    const faq = recordCurrent.faq || []

    const attributes = recordCurrent.attributes || []

    const status = statusParser(recordCurrent.status)

    const testfyBaseIds = map(
      filter(
        recordCurrent.testfyBases,
        (testfyBase) => testfyBase.status === 'ENABLED'
      ),
      'id'
    )

    const storeProductCategoryIds = map(recordCurrent.categories, 'id')

    const isServiceIncluded = String(recordCurrent.isServiceIncluded)

    const seoKeywords = recordCurrent.keywords?.map((keyword) => ({
      value: keyword.id,
      label: keyword.name,
    }))

    return {
      // General
      testfyBaseIds,
      storeProductCategoryIds,
      name: recordCurrent.name,
      storeProductCode: recordCurrent.storeProductCode,
      sampleTransportKitId: recordCurrent.sampleTransportKitId,
      // Sales
      priceB2c: recordCurrent.priceB2c,
      priceB2b: recordCurrent.priceB2b,
      isB2bAvailable: recordCurrent.isB2bAvailable,
      isB2cAvailable: recordCurrent.isB2cAvailable,
      isSellerAvailable: recordCurrent.isSellerAvailable,
      progressiveDiscounts: recordCurrent.progressiveDiscounts,
      isEligibleB2bDiscount: recordCurrent.isEligibleB2bDiscount,
      isAvailableInStore: get(recordCurrent, 'isAvailableInStore', false),
      // Laboratorial Analysis
      isServiceIncluded,
      storeProductHasLaboratoryAnalysisIds,
      // Marketing
      faq,
      status,
      attributes,
      mktTitle: recordCurrent.mktTitle,
      accentColor: recordCurrent.accentColor,
      mktAbstract: recordCurrent.mktAbstract,
      mktDescription: recordCurrent.mktDescription,
      reportOverviewFile: recordCurrent.reportOverviewFile,
      reportOverviewTitle: recordCurrent.reportOverviewTitle,
      mktCompactDescription: recordCurrent.mktCompactDescription || '',
      reportOverviewAbstract: recordCurrent.reportOverviewAbstract,
      // SEO
      seoKeywords,
      slug: recordCurrent.slug,
      seoTitle: recordCurrent.seoTitle,
      seoDescription: recordCurrent.seoDescription,
      seoAdditionalKeywords: recordCurrent.seoAdditionalKeywords,
    }
  }, [recordCurrent, statusParser])

  useEffect(() => {
    methods.reset(sanitizeRecord())
  }, [methods, statusParser, recordCurrent, sanitizeRecord])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: t('tabs.tabGeneral'),
        component: TabGeneral,
        names: [
          'name',
          'testfyBaseIds',
          'storeProductCode',
          'sampleTransportKitId',
          'storeProductCategoryId',
        ],
      },
      {
        title: t('tabs.sales'),
        component: TabSales,
        names: [
          'priceB2c',
          'priceB2b',
          'isB2bAvailable',
          'isB2cAvailable',
          'isSellerAvailable',
          'progressiveDiscounts',
          'isEligibleB2bDiscount',
        ],
      },
      {
        title: t('tabs.laboratorialAnalysis'),
        component: TabLaboratorialAnalysis,
        names: ['isServiceIncluded', 'storeProductHasLaboratoryAnalysisIds'],
      },
      {
        title: t('tabs.marketing'),
        component: TabPromotional,
        names: [
          'faq',
          'status',
          'attributes',
          'mktTitle',
          'accentColor',
          'mktAbstract',
          'mktDescription',
          'reportOverviewPDF',
          'reportOverviewTitle',
          'reportOverviewAbstract',
        ],
        options: {
          id,
          recordReload: record.reload,
          defaultImages: memoDefaultImages,
        },
      },
      {
        title: 'SEO',
        component: TabSeo,
        names: [
          'slug',
          'seoTitle',
          'seoKeywords',
          'seoDescription',
          'seoAdditionalKeywords',
        ],
        options: {
          id,
          memoDefaultImagesSEO,
          recordReload: record.reload,
          endpointUploadImage: {
            post: `/app/store_product/${id}/seo_upload`,
            delete: `/app/store_product/${id}/seo_image`,
          },
        },
      },
    ],
    [t, id, record.reload, memoDefaultImages, memoDefaultImagesSEO]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<ProductShowRecord> = React.useCallback(
    async (data) => {
      try {
        await record.update(data)

        if (data.reportOverviewPDF && data.reportOverviewPDF[0]) {
          const reportId = split(recordCurrent.reportOverviewFile, '.', 1)[0]
          if (reportId) {
            await apiDelete(`/app/store_product/${id}/report`)
          }

          const formData = new FormData()
          formData.append('file', data.reportOverviewPDF[0])
          await apiPost(`/app/store_product/${id}/upload_report`, formData)
        }

        toast({
          title: t('toast.success.title', {
            type: t('common:toast.success.update'),
          }),
          status: 'success',
        })
      } catch (error) {
        console.trace(error)
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [id, methods, record, recordCurrent.reportOverviewFile, t, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box position="absolute" top={-5} right={0}>
          <FieldStatus
            methods={methods}
            disable={record.disable}
            enable={record.enable}
          />
        </Box>

        <Tabs tabsDetails={tabsDetails} errors={methods.formState.errors} />
        <FormFooter isSubmitting={methods.formState.isSubmitting} />
      </Flex>
    </FormProvider>
  )
}
