// Vendors
import React, { useMemo } from 'react'
import { addMinutes } from 'date-fns'
import { FieldError } from 'react-hook-form'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'

// Components
import { Tooltip, TooltipProps } from 'components/Tooltip'

// Styles
import {
  Flex,
  Input,
  FormLabel,
  InputProps,
  FormControl,
  FormErrorMessage,
  FormControlProps,
  Text,
} from '@chakra-ui/react'
import 'react-datepicker/dist/react-datepicker.css'
import { useFormHelperText } from 'hooks/useFormHelperText'

// Interfaces
export type FieldDateProps = ReactDatePickerProps & {
  name: string
  label?: string
  error?: FieldError
  canModify?: boolean
  containerProps?: FormControlProps
  inputProps?: InputProps
  helperText?:
    | string
    | {
        text: string
        color?: string
      }
  tooltipProps?: TooltipProps
  isRequired?: boolean
}

export const FieldDate: React.FC<FieldDateProps> = (
  props: FieldDateProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const {
    name,
    label,
    error,
    placeholderText,
    selected,
    helperText,
    canModify = true,
    tooltipProps,
    containerProps,
    inputProps,
    isRequired,
    ...rest
  } = props
  const componentHelperText = useFormHelperText({ helperText, canModify })

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectedFormatted = useMemo(() => {
    if (!selected) return

    return addMinutes(
      new Date(selected),
      new Date(selected).getTimezoneOffset()
    )
  }, [selected])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormControl isInvalid={!!error}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        {...containerProps}
      >
        {!!label && (
          <FormLabel htmlFor={name} m={0}>
            {label}
            {isRequired && (
              <Text
                as="span"
                color="red.500"
                ml={1}
                fontWeight="bold"
                userSelect="none"
              >
                *
              </Text>
            )}
          </FormLabel>
        )}

        {tooltipProps && Object.keys(tooltipProps).length && (
          <Tooltip {...tooltipProps} verticalAlign="middle" />
        )}
      </Flex>

      <DatePicker
        id={name}
        name={name}
        dateFormat="dd/MM/yyyy"
        customInput={<Input {...inputProps} />}
        selected={selectedFormatted}
        placeholderText={placeholderText || 'Selecione a data...'}
        {...rest}
      />

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      {(helperText || !canModify) && !error && componentHelperText}
    </FormControl>
  )
}
