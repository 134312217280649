import { UserRolesNames } from 'types/User'

export const FILE_SIZE_1MB = 10 ** 6

export const FILE_SIZE = 10 * 1000 * 500 * 3 // 15MB
export const FILE_SIZE_BYTES = FILE_SIZE / 1000 / 1000 // 15MB

export const REPORT_DOWNLOAD_PERMISSION_ROLES = [
  UserRolesNames.CLIENT,
  UserRolesNames.ADMIN,
  UserRolesNames.SUPERADMIN,
  UserRolesNames.LABORATORY_AGENT,
  UserRolesNames.LABORATORY_MANAGER,
]
