import axios from 'axios'
import { STORAGE_KEYS } from 'contexts/auth'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://api-stage.testfy.com.br/',
})

let isUnauthorized = false

api.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  (error: any) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 401) {
      if (!isUnauthorized) {
        isUnauthorized = true
        localStorage.removeItem(STORAGE_KEYS)
        api.defaults.headers.Authorization = ''

        window.location.href = '/'
      }
    }

    return Promise.reject(error)
  }
)

export default api
