import { isArray } from 'lodash'

export enum TypeAllowed {
  select = 'select',
}

export type OptionsParser<T> = {
  key: keyof T
  type: TypeAllowed
  options?: Array<{
    value: string | number
    label: string
  }>
}

export function parserForm<T>(data: T, optionsParser: OptionsParser<T>[]): any {
  return Object.entries(data).reduce((acc, [key, value]) => {
    const optionParser = optionsParser.find(
      ({ key: keyParser }) => keyParser === key
    )

    if (!optionParser) return { ...acc, [key]: value }

    switch (optionParser.type) {
      case TypeAllowed.select: {
        if (isArray(value)) {
          const valueFormatted = value.map((item) =>
            optionParser.options?.find(
              ({ value: valueParser }) => valueParser === item
            )
          )

          return {
            ...acc,
            [key]: valueFormatted,
          }
        }

        const valueFormatted = optionParser.options?.find(
          ({ value: valueParser }) => valueParser === value
        )

        return {
          ...acc,
          [key]: valueFormatted,
        }
      }
      default:
        return {
          ...acc,
          [key]: value,
        }
    }
  }, {})
}
