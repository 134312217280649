// Vendors
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

// Functions
import { useCreateRecord } from 'hooks/useCreateRecord'

// Components
import { TabGeneral } from './tabs/TabGeneral'
import { TabDetail, Tabs } from 'components/Tabs'
import { FormFooter } from 'components/FormFooter'
import { TabOperation } from './tabs/TabOperation'

// Schemas
import { schema } from './schema'

// Styles
import { Flex, useToast } from '@chakra-ui/react'

// Types
import { CompanyBaseTestfyNewForm, CompanyBaseTestfyNewRecord } from './types'
import { useFeedback } from 'contexts/feedback'

type CompanyBaseTestfyFormNewProps = {
  endpoint: string
}

export const CompanyBaseTestfyFormNew = (
  props: CompanyBaseTestfyFormNewProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { endpoint } = props

  const { create } = useCreateRecord({ endpoint })
  const { t } = useTranslation('common')
  const history = useHistory()

  const toast = useToast({ position: 'top-right' })
  const { feedback } = useFeedback()

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Geral',
        component: TabGeneral,
        names: [
          'name',
          'street',
          'number',
          'cityId',
          'stateId',
          'cardCnpj',
          'complement',
          'postalCode',
          'neighborhood',
          'documentNumber',
          'referencePoint',
          'socialContract',
        ],
      },
      {
        title: 'Operação',
        component: TabOperation,
        names: [
          'cities',
          'states',
          'employees',
          'storeProductIds',
          'additionalInformations',
        ],
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<CompanyBaseTestfyNewForm> = React.useCallback(
    async (data) => {
      try {
        const formattedData: CompanyBaseTestfyNewRecord = {
          ...data,
          companyType: 'TESTFY_BASE',
        }

        await create(formattedData)

        toast({
          title: 'Criado com sucesso',
          description: 'Base testfy criada com sucesso',
          status: 'success',
        })

        history.push('/companies')
      } catch (error: any) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            if (fieldError.field === 'documentNumber') {
              fieldError.message = 'Verifique se o CNPJ está correto'
            }
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [create, history, methods, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Tabs tabsDetails={tabsDetails} errors={methods.formState.errors} />

        <FormFooter isSubmitting={methods.formState.isSubmitting} />
      </Flex>
    </FormProvider>
  )
}
