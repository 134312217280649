import React from 'react'

import { chakra, HTMLChakraProps } from '@chakra-ui/react'

export type IconProps = HTMLChakraProps<'svg'>

export const ArchiveIn = (props: IconProps) => {
  return (
    <chakra.svg
      width="10px"
      height="10px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.958 1.896 9.125.229A.427.427 0 0 0 8.75 0h-7.5a.427.427 0 0 0-.375.23L.042 1.895A.469.469 0 0 0 0 2.083v7.084A.833.833 0 0 0 .833 10h8.334A.833.833 0 0 0 10 9.167V2.083a.469.469 0 0 0-.042-.187ZM1.505.833h6.99l.416.834H1.09l.416-.834Zm7.662 8.334H.833V2.5h8.334v6.667ZM7.063 5.854a.417.417 0 0 1 0 .589l-1.766 1.77a.427.427 0 0 1-.594 0l-1.766-1.77a.417.417 0 0 1 .589-.589l1.057 1.057V3.75a.417.417 0 1 1 .834 0v3.161l1.057-1.057a.417.417 0 0 1 .588 0Z"
        fill="#5D5D5D"
      />
    </chakra.svg>
  )
}
