// Vendors
import { find } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

// Functions
import { apiList } from 'services/get'
import { apiPost } from 'services/post'
import { apiPatch } from 'services/patch'
import { apiDelete } from 'services/delete'

// Types
import { Role } from 'types/Role'
import { RequirementDocuments } from 'components/FormRequirementDocumentsFields'
import { useFeedback } from 'contexts/feedback'

export type MissingDocumentType = {
  id: string
  name: string
  slug: string
  mandatory: boolean
  documentType: 'value' | 'file'
}

type SetUserRole = {
  roleUser: { name: string; status: string }
  requiredRequirements: RequirementDocuments[]
}

type UseRoleProps = {
  roleName?: string
}

const ROLES_NOT_CREATABLE = [
  'client',
  'professional',
  'logistic-agent',
  'influencer',
]

export const useRole = (props?: UseRoleProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { feedback } = useFeedback()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [role, setRole] = useState<Role | undefined>(undefined)
  const [roles, setRoles] = useState<Role[]>([])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    async function fetchData() {
      const response = await apiList<Role[]>('/app/role')

      if (!response) return

      const rolesFiltered = response.filter(
        (role) => !ROLES_NOT_CREATABLE.includes(role.slug)
      )

      setRoles(rolesFiltered)

      if (props?.roleName) {
        const roleData = find(response, { name: props.roleName })
        if (!roleData) return
        setRole(roleData)
      }
    }

    fetchData()
  }, [props?.roleName])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const enable = useCallback(
    async (userId: string, roleUpdateId: string) => {
      try {
        await apiPatch(`/app/person/${userId}/role/${roleUpdateId}/enable`)
      } catch {
        feedback({
          title: 'Atenção',
          description: 'Não foi possível ativar role',
          status: 'error',
        })
      }
    },
    [feedback]
  )

  const disable = useCallback(
    async (userId: string, roleUpdateId: string) => {
      try {
        await apiPatch(`/app/person/${userId}/role/${roleUpdateId}/disable`)
      } catch {
        feedback({
          title: 'Atenção',
          description: 'Não foi possível desativar a role',
          status: 'error',
        })
      }
    },
    [feedback]
  )

  const setUserRole = useCallback(
    async (userId: string, roleUpdateSlug: string) => {
      try {
        return await apiPatch<SetUserRole>(`/app/person/${userId}/role`, {
          roleSlug: roleUpdateSlug,
        })
      } catch {
        feedback({
          title: 'Atenção',
          description: 'Erro ao definer role ao usuário',
          status: 'error',
        })
      }
    },
    [feedback]
  )

  const addUserRole = useCallback(
    async (userId, roleId, data) => {
      try {
        await apiPost(`/app/person/${userId}/role/${roleId}`, data)
      } catch {
        feedback({
          title: 'Atenção',
          description: 'Erro ao adicionar role ao usuário',
          status: 'error',
        })
      }
    },
    [feedback]
  )

  const deleteUserRole = useCallback(
    async (userId: string, roleId: string) => {
      try {
        await apiDelete(`/app/person/${userId}/role/${roleId}`)
      } catch {
        feedback({
          title: 'Atenção',
          description: 'Erro ao deletar role do usuário',
          status: 'error',
        })
      }
    },
    [feedback]
  )

  const checkDocumentRequirements = useCallback(async (roleId) => {
    try {
      return await apiList<RequirementDocuments[]>(
        `/app/role/${roleId}/document`
      )
    } catch (error) {
      console.error(error)
    }
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Return
  |-----------------------------------------------------------------------------
  |
  |
  */

  return {
    role,
    roles,
    enable,
    disable,
    setUserRole,
    addUserRole,
    deleteUserRole,
    checkDocumentRequirements,
  }
}
