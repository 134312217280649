import { AccentColorEnum } from 'pages/private/Category/form/show/types'
import { TFunction } from 'react-i18next'
import { testIsRequired } from 'utils/testIsRequired'
import { yupSchema } from 'utils/yupSchema'

import * as yup from 'yup'

export const schema = (t: TFunction<'products'>) => {
  yupSchema(t)

  let isAvailableInStore = false

  return yup.object().shape({
    name: yup.string().required().label(t('form.name.label')),

    mktDescription: yup
      .string()
      .when('isAvailableInStore', (isAvailableInStore, field) => {
        return isAvailableInStore ? field.required() : field.notRequired()
      })
      .label(t('form.description.label')),

    isAvailableInStore: yup
      .boolean()
      .transform((value) => {
        isAvailableInStore = value
        return value
      })
      .label(t('form.isAvailableInStore.label')),

    priceB2b: yup.number().min(0).required().label(t('form.priceB2b.label')),

    priceB2c: yup.number().min(0).required().label(t('form.priceB2c.label')),

    status: yup.string().getStatus().required().label(t('form.status.label')),

    isEligibleB2bDiscount: yup
      .boolean()
      .label(t('form.isEligibleB2bDiscount.label')),

    isB2cAvailable: yup.boolean().label(t('form.isB2cAvailable.label')),

    isB2bAvailable: yup.boolean().label(t('form.isB2bAvailable.label')),

    isLaboratoryAnalysisIncluded: yup
      .boolean()
      .label(t('form.isLaboratoryAnalysisIncluded.label')),

    isSellerAvailable: yup.boolean().label(t('form.isSellerAvailable.label')),

    testfyBaseIds: yup
      .array()
      .selectValues()
      .min(1)
      .required()
      .label(t('form.testfyBaseIds.label')),

    storeProductHasLaboratoryAnalysisIds: yup
      .array()
      .selectValues()
      .min(1)
      .required()
      .label(t('form.storeProductAttendLaboratoryAnalysisIds.label')),

    sampleTransportKitId: yup
      .string()
      .selectValue()
      .required()
      .label(t('form.sampleTransportKitId.label')),

    storeProductCategoryIds: yup
      .array()
      .selectValues()
      .required()
      .label(t('form.storeProductCategoryId.label')),

    progressiveDiscounts: yup
      .array()
      .min(1)
      .required()
      .label(t('form.progressiveDiscount.label')),

    mktTitle: yup
      .string()
      .when('isAvailableInStore', (isAvailableInStore, field) => {
        return isAvailableInStore ? field.required() : field.notRequired()
      })
      .label(t('form.title.label')),

    mktAbstract: yup
      .string()
      .when('isAvailableInStore', (isAvailableInStore, field) => {
        return isAvailableInStore ? field.required() : field.notRequired()
      })
      .label(t('form.abstract.label')),

    attributes: yup
      .array(
        yup.object().shape({
          icon: yup
            .string()
            .test({
              name: 'isRequired',
              exclusive: true,
              message: 'Ícone é obrigatório',
              test: function (value) {
                return testIsRequired(value, isAvailableInStore)
              },
            })
            .label('Ícone'),
          label: yup
            .string()
            .test({
              name: 'isRequired',
              exclusive: true,
              message: 'Título é obrigatório',
              test: function (value) {
                return testIsRequired(value, isAvailableInStore)
              },
            })
            .label('Título'),
          url: yup.string(),
        })
      )
      .transform((value) =>
        value.map((v: any) => ({ ...v, icon: v.icon.value }))
      )
      .min(1)
      .when('isAvailableInStore', (isAvailableInStore, field) => {
        return isAvailableInStore ? field.required() : field.notRequired()
      })
      .label(t('form.attributes.label')),

    faq: yup
      .array(
        yup.object().shape({
          label: yup
            .string()
            .test({
              name: 'isRequired',
              exclusive: true,
              message: 'Título na lateral é obrigatório',
              test: function (value) {
                return testIsRequired(value, isAvailableInStore)
              },
            })
            .label('Título na lateral'),
          title: yup
            .string()
            .test({
              name: 'isRequired',
              exclusive: true,
              message: 'Título no texto é obrigatório',
              test: function (value) {
                return testIsRequired(value, isAvailableInStore)
              },
            })
            .label('Título no texto'),
          description: yup
            .string()
            .test({
              name: 'isRequired',
              exclusive: true,
              message: 'Descrição é obrigatório',
              test: function (value) {
                return testIsRequired(value, isAvailableInStore)
              },
            })
            .label('Descrição'),
        })
      )
      .min(1)
      .when('isAvailableInStore', (isAvailableInStore, field) => {
        return isAvailableInStore ? field.required() : field.notRequired()
      })
      .label(t('form.attributes.label')),

    reportOverviewTitle: yup.string().nullable(),

    reportOverviewAbstract: yup.string().nullable(),

    accentColor: yup
      .string()
      .selectValue()
      .when('isAvailableInStore', (isAvailableInStore, field) => {
        return isAvailableInStore ? field.required() : field.notRequired()
      })
      .oneOf(Object.keys(AccentColorEnum))
      .label(t('form.category.accentColor.label')),

    // SEO
    seoTitle: yup
      .string()
      .required()
      .label(t('common:form.tabSeo.seoTitle.label')),

    seoDescription: yup
      .string()
      .required()
      .label(t('common:form.tabSeo.seoDescription.label')),

    slug: yup
      .string()
      .kebabCase()
      .required()
      .label(t('common:form.tabSeo.slug.label')),

    seoKeywords: yup
      .array()
      .required()
      .selectValues()
      .label(t('common:form.tabSeo.seoKeywords.label')),

    seoAdditionalKeywords: yup
      .string()
      .nullable()
      .label(t('common:form.tabSeo.seoAdditionalKeywords.label')),
  })
}
