// Vendors
import React, { ReactNode } from 'react'

// Components
import { Link as RouterLink } from 'react-router-dom'

// Styles
import { Flex, Box, useColorModeValue, Link } from '@chakra-ui/react'

// Interfaces
export interface ICardDetailsItem {
  value: string | ReactNode
  label: string
  type?: string
  link?: string
}

export const CardDetailsItem: React.FC<ICardDetailsItem> = (props) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { value, label, type, link } = props
  const bg = useColorModeValue('gray.50', 'gray.600')

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <>
      {link ? (
        <RouterLink to={link}>
          <Flex
            as="dl"
            direction={{ base: 'column', sm: 'row' }}
            px="6"
            py="4"
            borderBottom={'1px'}
            borderColor={'gray.100'}
            fontSize="sm"
            _even={{ bg: bg }}
            transition="all 0.2s ease-in-out"
            _hover={{ bg: 'gray.50' }}
          >
            <Box as="dd" minWidth="180px" color={'gray.600'}>
              {label}
            </Box>

            <Box as="dd" flex="1" fontWeight="semibold" color={'black'}>
              {value}
            </Box>
          </Flex>
        </RouterLink>
      ) : (
        <Flex
          as="dl"
          direction={{ base: 'column', sm: 'row' }}
          px="6"
          py="4"
          borderBottom={'1px'}
          borderColor={'gray.100'}
          fontSize="sm"
          _even={{ bg: bg }}
        >
          <Box as="dd" minWidth="180px" color={'gray.600'}>
            {label}
          </Box>
          {type && type === 'link' ? (
            <Link
              as="dd"
              flex="1"
              fontWeight="semibold"
              color={'blue.500'}
              href={link || ''}
              target="_blank"
            >
              {value}
            </Link>
          ) : (
            <Box as="dd" flex="1" fontWeight="semibold" color={'black'}>
              {value}
            </Box>
          )}
        </Flex>
      )}
    </>
  )
}
