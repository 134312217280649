// Vendors
import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'

// Styles and Icons
import {
  Box,
  Flex,
  Heading,
  Text,
  LightMode,
  Button,
  useColorModeValue as mode,
  useToast,
} from '@chakra-ui/react'

// Components
import { Logo } from 'components/Logo'
import { FieldText } from 'components/FieldText'
import { apiPatch } from 'services/patch'
import { useFeedback } from 'contexts/feedback'
import { useError } from 'hooks/useError'

type Inputs = {
  email: string
}

/*
|-----------------------------------------------------------------------------
| Page
|-----------------------------------------------------------------------------
|
|
*/

const ForgotPassword = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('common')

  const toast = useToast()
  const { handleError } = useError()
  const { feedback } = useFeedback()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<Inputs> = React.useCallback(
    async (data) => {
      try {
        await apiPatch('/public/backoffice/forget_password', data)
        toast({
          title: t('toast.password.forgot'),
          status: 'success',
        })
      } catch (error) {
        handleError(error)
      }
    },
    [handleError, t, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Content
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex minH="100vh" direction={{ base: 'column', md: 'row' }}>
      <Box
        display={{ base: 'none', md: 'block' }}
        maxW={{ base: '20rem', lg: '40rem' }}
        flex="1"
        backgroundImage="url('/login-bg.jpg')"
        backgroundSize="cover"
        color="white"
        px="10"
        py="8"
      >
        <Box mb="16">
          <Logo w="auto" h="14" iconColor="white" />
        </Box>
      </Box>
      <Flex
        flex="1"
        align="center"
        px={{ base: '10', md: '40' }}
        backgroundImage={{ base: "url('login-bg.jpg')", md: '' }}
        backgroundSize="cover"
      >
        <Box maxW="xl" w={{ base: '100%', md: 'auto' }}>
          <Box textAlign={{ base: 'center', md: 'left' }}>
            <Logo
              display={{ md: 'none' }}
              mb="16"
              w="auto"
              h="14"
              iconColor="white"
              mx="auto"
            />
            <Heading
              color={{ base: 'white', md: 'teal.400' }}
              as="h1"
              size="2xl"
              fontWeight="extrabold"
              letterSpacing="tight"
            >
              {t('authentication.forgot_title')}
            </Heading>
            <Text
              mt="3"
              fontSize={{ base: 'xl', md: '3xl' }}
              fontWeight="bold"
              color={{ base: 'white', md: 'gray.500' }}
            >
              {t('authentication.forgot_description')}
            </Text>
          </Box>

          <Box
            minW={{ md: '420px' }}
            mt="10"
            rounded="xl"
            bg={{ md: mode('white', 'gray.700') }}
            shadow={{ md: 'lg' }}
            px={{ md: '10' }}
            pt={{ base: '8', md: '12' }}
            pb="8"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <FieldText
                type="email"
                label="Email"
                error={errors.email}
                {...register('email', {
                  required: `${t('authentication.email_required')}`,
                })}
              />
              <Flex
                spacing="4"
                direction={{ base: 'column-reverse', md: 'row' }}
                mt="6"
                align="center"
                justify="space-between"
              >
                <Text
                  color={{ base: 'white', md: 'teal.600' }}
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  <Link to="/login">{t('button.go_back')}</Link>
                </Text>
                <LightMode>
                  <Button
                    mb={{ base: '4', md: '0' }}
                    w={{ base: 'full', md: 'auto' }}
                    type="submit"
                    colorScheme="teal"
                    ml="auto"
                    size="lg"
                    fontSize="md"
                    fontWeight="bold"
                    isLoading={isSubmitting}
                  >
                    {t('authentication.forgot_button')}
                  </Button>
                </LightMode>
              </Flex>
            </form>
          </Box>
        </Box>
      </Flex>
    </Flex>
  )
}

export default ForgotPassword
