import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import { yupSchema } from 'utils/yupSchema'

import { UserRolesNames } from 'types/User'

export const schema = (t: TFunction<'influencer'>) => {
  yupSchema(t)

  return yup.object().shape({
    name: yup.string().required().label(t('form.name.label')),

    email: yup.string().email().required().label(t('form.email.label')),

    status: yup.string().getStatus().required().label(t('form.status.label')),
    phoneNumber: yup
      .string()
      .required()
      .getCharacters()
      .transform((value: string) => (value === '' ? undefined : `+${value}`))
      .label(t('form.phoneNumber.label')),

    roleName: yup.string().default(UserRolesNames.INFLUENCER),

    gender: yup.string().required().label(t('form.gender.label')),

    birthdate: yup.date().required().label(t('form.birthdate.label')),
  })
}
