export default [
  {
    value: 'CapillaryBloodCollection',
    label: 'Coleta de Sangue Capilar',
  },
  {
    value: 'FastAndConvenient',
    label: 'Rápido e conveniente',
  },
  {
    value: 'IggReaction',
    label: 'Anticorpos 1',
  },
  {
    value: 'ReportModel',
    label: 'Modelo de relatório',
  },
  {
    value: 'Swab',
    label: 'Swab',
  },
  {
    value: 'InfectionsOne',
    label: 'Infecções 1',
  },
  {
    value: 'InfectionsTwo',
    label: 'Infecções 2',
  },
  {
    value: 'GeneticsOne',
    label: 'Genética 1',
  },
  {
    value: 'GeneticsTwo',
    label: 'Genética 2',
  },
  {
    value: 'GeneticsThree',
    label: 'Genética 3',
  },
  {
    value: 'Antibodies',
    label: 'Anticorpos 2',
  },
  {
    value: 'Spatula',
    label: 'Espatula',
  },
  {
    value: 'CollectionBrush',
    label: 'Escova de coleta',
  },
  {
    value: 'Gluten',
    label: 'Glúten',
  },
]
