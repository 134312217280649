// Vendors
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form'

// Functions
import { useEditRecords } from 'hooks/useEditRecord'
import { useFormTransform } from 'hooks/useFormTransform'

// Components
import { TabDetail, Tabs } from 'components/Tabs'
import { FormFooter } from 'components/FormFooter'
import { TabMarketing } from './tabs/TabMarketing'
import { TabGeneral } from './tabs/TabGeneral'

// Schemas
import { schema } from './schema'

// Styles
import {
  Box,
  Flex,
  ListItem,
  UnorderedList,
  useToast,
  Text,
} from '@chakra-ui/react'

// Types

import { CategoryShow, CategoryShowRecord, AccentColorEnum } from './types'
import { Link } from 'react-router-dom'
import { map, some } from 'lodash'
import { TabSeo } from 'components/TabSeo'
import { useError } from 'hooks/useError'

type CategoryFormShowProps = {
  endpoint: string
  id: string
}

export const CategoryFormShow = (props: CategoryFormShowProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint, id } = props

  const { handleError } = useError()
  const { t } = useTranslation('categories')
  const toast = useToast({ position: 'top-right' })
  const { selectParser } = useFormTransform()

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  const { record } = useEditRecords<CategoryShow, CategoryShowRecord>({
    endpoint: { public: endpoint, private: '/app/store_product_category' },
    currentId: id,
  })
  const recordCurrent = record.current

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [textEditorData, setTextEditorData] = useState('')

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const memoDefaultImagesSEO = useMemo(() => {
    const seo = recordCurrent.seoImages

    return {
      list: seo || [],
    }
  }, [recordCurrent.seoImages])
  const accentColorOptions = useMemo(() => {
    return Object.entries(AccentColorEnum).map(([key, value]) => ({
      label: value,
      value: key,
    }))
  }, [])

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: t('tabs.tabGeneral'),
        component: TabGeneral,
        names: ['title', 'description'],
        options: {
          id: id,
          endpoint: endpoint,
          record: record,
        },
      },
      {
        title: t('tabs.marketing'),
        component: TabMarketing,
        names: [
          'accentColor',
          'isPage',
          'mktTitle',
          'mktDescription',
          'mktAbstract',
          'mktTitleIntrodutoryText',
          'mktFeatures',
          'mktTitleReport',
          'mktDescriptionReport',
        ],
        options: {
          id: id,
          endpoint: endpoint,
          record: record,
          setTextEditorData: setTextEditorData,
          textEditorData: textEditorData,
          accentColorOptions: accentColorOptions,
        },
      },
      {
        title: 'SEO',
        component: TabSeo,
        names: ['slug', 'seoTitle', 'seoKeywords', 'seoDescription'],
        options: {
          id,
          recordReload: record.reload,
          memoDefaultImagesSEO,
          endpointUploadImage: {
            post: `/app/store_product_category/${id}/seo_upload`,
            delete: `/app/store_product_category/${id}/seo_image`,
          },
        },
      },
    ],
    [
      t,
      id,
      record,
      endpoint,
      textEditorData,
      accentColorOptions,
      memoDefaultImagesSEO,
    ]
  )

  const deleteDialog = useMemo(() => {
    if (!recordCurrent || !recordCurrent.storeProducts) return

    if (recordCurrent.storeProducts.length > 0) {
      const productsLength = recordCurrent.storeProducts.length
      const bodyDialog = map(recordCurrent.storeProducts, (product) => {
        return (
          <Link to={`/products/${product.id}`} key={product.id}>
            <ListItem
              color="blue.600"
              _hover={{
                filter: 'brightness(0.85)',
                textDecoration: 'underline',
              }}
            >
              {product.mktTitle}
            </ListItem>
          </Link>
        )
      })

      return {
        title: 'Este registro não pode ser excluído',
        body: (
          <>
            <Box>
              Atualmente, {productsLength}{' '}
              {productsLength === 1
                ? 'produto ativo utiliza'
                : 'produtos ativos utilizam'}{' '}
              este registro:{' '}
            </Box>
            <UnorderedList>{bodyDialog}</UnorderedList>
          </>
        ),
        isDisabled: some(recordCurrent.storeProducts, { status: 'ENABLED' }),
      }
    } else {
      return {
        title: 'Por favor confirme a exclusão.',
        isDisabled: false,
        body: (
          <Text>
            Não existem produtos ativos utilizando este registro. Ao excluir
            este item, é possível que produtos desativados que dependam dele não
            possam mais ser ativados. Você tem certeza que deseja excluir este
            item?
          </Text>
        ),
      }
    }
  }, [recordCurrent])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sanitize = useCallback(
    (data: CategoryShow) => {
      if (Object.keys(data).length === 0) return {}

      const accentColor = selectParser(data.accentColor, accentColorOptions)
      const seoKeywords = data.keywords?.map((keyword) => ({
        value: keyword.id,
        label: keyword.name,
      }))

      return {
        ...data,
        accentColor,
        // SEO
        seoKeywords,
        slug: data.slug,
        seoTitle: data.seoTitle,
        seoDescription: data.seoDescription,
        seoAdditionalKeywords: data.seoAdditionalKeywords,
      }
    },
    [accentColorOptions, selectParser]
  )

  useEffect(() => {
    if (recordCurrent.mktIntrodutoryText) {
      setTextEditorData(recordCurrent.mktIntrodutoryText)
    }

    methods.reset(sanitize(recordCurrent))
  }, [recordCurrent, methods, selectParser, accentColorOptions, sanitize])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */
  const onSubmit: SubmitHandler<CategoryShowRecord> = useCallback(
    async (data) => {
      try {
        const newData = { ...data, mktIntrodutoryText: textEditorData }
        await record.update(newData)

        toast({
          title: 'Sucesso',
          description: 'Categoria atualizada com sucesso',
          status: 'success',
        })
      } catch (error) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        } else {
          handleError(error)
        }
      }
    },
    [handleError, methods, record, textEditorData, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormProvider {...methods}>
      <Flex
        h="full"
        flexDirection="column"
        justifyContent="space-between"
        as="form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Tabs tabsDetails={tabsDetails} errors={methods.formState.errors} />
        <FormFooter
          isSubmitting={methods.formState.isSubmitting}
          recordDelete={record.delete}
          titleDialog={deleteDialog?.title}
          bodyDialog={deleteDialog?.body}
          isDisabledDialog={deleteDialog?.isDisabled}
        />
      </Flex>
    </FormProvider>
  )
}
