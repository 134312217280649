// Vendors
import React, {
  useMemo,
  useState,
  Dispatch,
  useCallback,
  SetStateAction,
} from 'react'

// Functions
import { useError } from 'hooks/useError'
import { apiDelete } from 'services/delete'
import { useFeedback } from 'contexts/feedback'

// Components
import { ListTable } from 'components/ListTable'
import { ListActions } from 'components/ListActions'
import { DrawerFormImage } from 'components/DrawerFormImage'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles
import { FiPlus } from 'react-icons/fi'
import { CgFileDocument } from 'react-icons/cg'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormLabel,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import FsLightbox from 'fslightbox-react'

// Interfaces
export type ListImagesProps = {
  group: string
  title: string
  type?: 'image' | 'document'
  label?: string
  labelFieldFile?: string
  isSingle?: boolean
  reloadRecord: () => void
  isDisabled?: boolean
  records: {
    get: { list: any[] }
    set: Dispatch<SetStateAction<any>>
  }
  endpoint: {
    patch?: string
    post: string
    delete?: string
    isMain?: boolean
  }
  hasTitle?: boolean
  hasDescription?: boolean
}

export const ListImages = (props: ListImagesProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  const {
    group,
    label,
    title,
    type = 'image',
    records,
    endpoint,
    reloadRecord,
    labelFieldFile,
    isSingle = false,
    hasTitle = true,
    isDisabled = false,
    hasDescription = true,
  } = props

  const { handleError } = useError()
  const toast = useToast({ position: 'top-right' })
  const { feedback } = useFeedback()
  const disclosureDrawerFormImage = useDisclosure()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [editImage, setEditImage] = useState(undefined)

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const openLightboxOnSlide = useCallback(
    (fileUri: string) => {
      let index = records.get.list.findIndex((item) => item.fileUri === fileUri)
      if (index === -1) index = 0

      setLightboxController({
        toggler: !lightboxController.toggler,
        slide: index + 1,
      })
    },
    [lightboxController.toggler, records.get.list]
  )

  const memoIsSingle = useMemo(() => {
    if (isSingle) {
      return records.get.list.length === 1
    } else {
      return false
    }
  }, [isSingle, records.get.list.length])

  const messages = useMemo(() => {
    return {
      image: {
        post: {
          success: 'Imagem cadastrada com sucesso',
          error: 'Erro ao cadastrar imagem',
        },
        patch: {
          success: 'Imagem atualizada com sucesso',
          error: 'Erro ao editar imagem',
        },
        delete: {
          success: 'Imagem excluída com sucesso!',
          error: 'Erro ao deletar imagem',
        },
      },
      document: {
        post: {
          success: 'Documento cadastrado com sucesso',
          error: 'Erro ao cadastrar documento',
        },
        patch: {
          success: 'Documentado atualizado com sucesso',
          error: 'Erro ao atualizar documento',
        },
        delete: {
          success: 'Documento excluído com sucesso!',
          error: 'Erro ao excluir documento',
        },
      },
    }
  }, [])

  const listTableHeaders = useMemo(
    () => [
      {
        label: title,
        accessor: 'fileUri',
        render: {
          as:
            type === 'document'
              ? ListTableRenderAs.ICON
              : ListTableRenderAs.IMAGE,
          options: {
            icon: type === 'document' ? CgFileDocument : undefined,
            size: 30,
            color: '#1FB7BC',
            fill: '#1FB7BC',
            handleIsOpen: openLightboxOnSlide,
          },
        },
      },
      {
        label: hasTitle ? 'Titulo' : '',
        accessor: hasTitle ? 'title' : '',
      },
      {
        label: hasDescription ? 'Descrição' : '',
        accessor: hasDescription ? 'description' : '',
      },
    ],
    [hasDescription, hasTitle, openLightboxOnSlide, title, type]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDelete = useCallback(
    async (e, record) => {
      try {
        await apiDelete(`${endpoint.delete}/${record.id}`)

        records.set(records.get.list.filter((item) => item.id !== record.id))
        reloadRecord()
        toast({
          title: 'Sucesso',
          description: messages[type].delete.success,
          status: 'success',
        })
      } catch (error) {
        handleError(error)
      }
    },
    [endpoint.delete, handleError, messages, records, reloadRecord, toast, type]
  )

  const handleEdit = useCallback(
    (record: any) => {
      setEditImage({ ...record })
      disclosureDrawerFormImage.onOpen()
    },
    [disclosureDrawerFormImage]
  )

  const handleOnClose = useCallback(() => {
    setEditImage(undefined)
    disclosureDrawerFormImage.onClose()
  }, [disclosureDrawerFormImage])

  const handleOpenLink = useCallback(() => {
    window.open(records.get.list[0].fileUri, 'blank')?.focus()
  }, [records.get.list])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <DrawerFormImage
        type={type}
        group={group}
        title={title}
        endpoint={endpoint}
        onClose={handleOnClose}
        defaultValues={editImage}
        reloadRecord={reloadRecord}
        labelFieldFile={labelFieldFile}
        isOpen={disclosureDrawerFormImage.isOpen}
        hasTitle={hasTitle}
        hasDescription={hasDescription}
        messages={messages}
      />

      {!memoIsSingle && !isDisabled && (
        <Flex
          justifyContent={!label ? 'flex-end' : 'space-between'}
          alignItems="end"
        >
          {!!label && <FormLabel mb={0}>{label}</FormLabel>}
          <ListActions>
            <Alert status="warning" borderRadius="md">
              <AlertIcon />
              <AlertDescription>
                <Text as="strong">Atenção: </Text> Ao adicionar uma imagem as
                informações não salvas serão perdidas, salve o registro para
                adicionar novas imagens.
              </AlertDescription>
            </Alert>
            <Button
              leftIcon={<FiPlus />}
              onClick={disclosureDrawerFormImage.onOpen}
            >
              Adicionar
            </Button>
          </ListActions>
        </Flex>
      )}

      <ListTable
        records={records.get}
        defaultActions="none"
        emptySize="small"
        headers={listTableHeaders}
        actions={
          !isDisabled
            ? [
                ...(type === 'document'
                  ? [{ type: 'link', options: { callback: handleOpenLink } }]
                  : ([] as any)),
                ...(endpoint.patch
                  ? [{ type: 'edit', options: { callback: handleEdit } }]
                  : []),
                ...(endpoint.delete
                  ? [{ type: 'delete', options: { callback: handleDelete } }]
                  : []),
              ]
            : []
        }
      />

      <FsLightbox
        type="image"
        key={records.get.list.length}
        slide={lightboxController.slide}
        toggler={lightboxController.toggler}
        sources={records.get.list.map((item) => item.fileUri)}
      />
    </Box>
  )
}
