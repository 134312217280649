// Vendors
import { get } from 'lodash'
import { useHistory, Link } from 'react-router-dom'
import React, { useMemo, useCallback, useEffect } from 'react'

// Functions
import { useTranslation } from 'react-i18next'
import formatCurrency from 'utils/formatCurrency'
import { useListRecords } from 'hooks/useListRecords'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListActions } from 'components/ListActions'
import { ListPagination } from 'components/ListPagination'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles
import { FiPlus } from 'react-icons/fi'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'
import { Stack, Button, useDisclosure } from '@chakra-ui/react'

// Types
import { Product } from './types'

import { PageMetaSetting } from './meta'
import { DrawerListNew } from '../form/DrawerListNew'
import { DuplicateFrom } from 'components/DuplicateFrom'

export const PageProductListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation(['products', 'common'])
  const history = useHistory()
  const { endpoint, title } = PageMetaSetting
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<Product>({ endpoint })
  const disclosureTag = useDisclosure()

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    Records.list.forEach((record) => {
      record.priceB2b = formatCurrency(+record.priceB2b)
      record.priceB2c = formatCurrency(+record.priceB2c)

      record.image = get(record, 'images.0.fileUri', undefined)
    })
  }, [Records.list])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const ListTableHeaders = useMemo(
    () => [
      {
        label: '',
        accessor: 'image',
        render: {
          as: ListTableRenderAs.IMAGE,
        },
        thProps: {
          width: '100px',
        },
      },
      {
        label: t('table.name'),
        accessor: 'name',
      },
      {
        label: t('table.storeProductCode'),
        accessor: 'storeProductCode',
      },
      {
        label: t('table.description'),
        accessor: 'mktDescription',
        render: { as: ListTableRenderAs.MULTILINE_TEXT },
      },
      {
        label: t('table.priceB2b'),
        accessor: 'priceB2b',
      },
      {
        label: t('table.priceB2c'),
        accessor: 'priceB2c',
      },
      {
        label: 'SKU',
        accessor: 'sku',
      },
      {
        label: t('table.status'),
        accessor: 'status',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
    ],
    [t]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const ListTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/products/${Record.id}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutLoggedPageList title={t(title)}>
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ListFilters query={Query}></ListFilters>

        <ListActions>
          <Button
            colorScheme="gray"
            leftIcon={<FiPlus />}
            onClick={disclosureTag.onOpen}
          >
            {t('button_tag')}
          </Button>
          <DrawerListNew
            title={t('button_tag')}
            disclosure={disclosureTag}
            endpoint="/app/store_product_tag"
          />

          <DuplicateFrom
            href="/products/new"
            endpoint={endpoint}
            accessor={{ label: 'name', value: 'id' }}
          />
        </ListActions>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={ListTableRowAction}
        defaultActions="edit"
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
