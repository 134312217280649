// Vendors
import React, { useCallback, useMemo, useState, MouseEvent } from 'react'
import { v4 } from 'uuid'
import { get } from 'lodash'

// Components
import {
  ListTableRenderAs,
  ListTableRowComponents,
} from 'components/ListTableRowComponent'
import { AlertDialogDelete } from 'components/AlertDialogDelete'
import { ActionType, ListTableAction } from 'components/ListTableAction'

// Styles
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  TableProps,
  Text,
  Skeleton,
  Image,
  TableCellProps,
  ButtonProps,
  Stack,
  TableColumnHeaderProps,
  TableRowProps,
  Flex,
} from '@chakra-ui/react'

// Interfaces

type TableHeader = {
  label: string
  accessor: string
  tdProps?: TableCellProps
  thProps?: TableColumnHeaderProps
  render?: {
    as: keyof typeof ListTableRenderAs
    options?: unknown
  }
}

export type ListTableProps<T> = TableProps & {
  emptyMessage?: string
  emptySize?: 'small' | 'large'
  tableActionProps?: TableCellProps | ((record: T) => TableCellProps)
  menuButtonProps?: ButtonProps
  trProps?: (record: T) => TableRowProps
  headers: TableHeader[]
  actions?: ActionType<T>[]
  records: {
    list: T[]
    delete?: (id: string) => void
    filtered?: () => T[]
    reload?: () => void
    clear?: () => void
    isLoading?: boolean
  }
  rowAction?: (Record: T) => void
  defaultActions?: 'edit' | 'delete' | 'both' | 'none'
}

export const ListTable = <T extends any>({
  actions = [],
  records,
  headers,
  trProps,
  rowAction,
  emptyMessage,
  menuButtonProps,
  tableActionProps,
  emptySize = 'large',
  defaultActions = 'both',
  ...rest
}: ListTableProps<T>): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const disclosureDelete = useDisclosure()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [itemToDelete, setItemToDelete] = useState<T>({} as T)

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const emptyData = useCallback(() => {
    if (emptySize === 'small') {
      return (
        <Tr>
          <Td colSpan={headers.length + 1} mx="auto">
            <Stack
              p="5"
              spacing="10"
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Image src="/taken.svg" h="90px" />

              <Stack spacing="4">
                <Text fontSize="2xl" color="gray.500">
                  Ops... Está vazio!
                </Text>

                <Text fontSize="lg" color="gray.500">
                  {emptyMessage ||
                    'Não encontramos nenhuma informação cadastrada no momento.'}
                </Text>
              </Stack>
            </Stack>
          </Td>
        </Tr>
      )
    }

    return (
      <Tr>
        <Td colSpan={headers.length + 1} mx="auto">
          <Stack p="5" spacing="4" alignItems="center" justifyContent="center">
            <Text fontSize="2xl" color="gray.500">
              Ops... Está vazio!
            </Text>

            <Text
              fontSize="lg"
              color="gray.500"
              whiteSpace="break-spaces"
              textAlign="center"
              lineHeight="7"
            >
              {emptyMessage ||
                'Não encontramos nenhuma informação cadastrada no momento.'}
            </Text>

            <Image src="/taken.svg" mt={{ lg: '3rem !important' }} />
          </Stack>
        </Td>
      </Tr>
    )
  }, [emptyMessage, emptySize, headers.length])

  const listActions: ActionType<T>[] = useMemo(() => {
    switch (defaultActions) {
      case 'edit':
        return [...actions, { type: 'edit', options: { callback: rowAction } }]

      case 'both':
        return [
          ...actions,
          { type: 'edit', options: { callback: rowAction } },
          {
            type: 'delete',
            options: {
              callback: (e: MouseEvent<HTMLButtonElement>, record: T) => {
                e.stopPropagation()
                setItemToDelete(record)
                disclosureDelete.onOpen()
              },
            },
          },
        ]

      case 'delete':
        return [
          ...actions,
          {
            type: 'delete',
            options: {
              callback: (e: MouseEvent<HTMLButtonElement>, record: T) => {
                e.stopPropagation()
                setItemToDelete(record)
                disclosureDelete.onOpen()
              },
            },
          },
        ]

      default:
        return actions
    }
  }, [actions, defaultActions, rowAction, disclosureDelete])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDelete = useCallback(() => {
    if (!records.delete) return
    records.delete(get(itemToDelete, 'id'))
    disclosureDelete.onClose()
  }, [disclosureDelete, records, itemToDelete])

  const getCellLayout = useCallback((record: T, header: TableHeader) => {
    if (!header.render) header.render = { as: ListTableRenderAs.TEXT }

    const Component = get(ListTableRowComponents, header.render.as)
    if (!Component)
      throw new Error('O componente não existe em ListTableRowComponents')

    return (
      <Component {...header.render.options}>
        {get(record, header.accessor)}
      </Component>
    )
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex flex="1">
      <Flex flex="1" overflowX="auto" my="4">
        <Box w="full">
          <Table borderWidth="1px" fontSize="sm" {...rest}>
            <AlertDialogDelete
              isOpen={disclosureDelete.isOpen}
              onCancel={disclosureDelete.onClose}
              onConfirm={handleDelete}
            />
            <Thead bg="gray.50">
              <Tr>
                {headers.map((headerItem, index) => (
                  <Th
                    key={v4()}
                    flex="1"
                    width={index === 0 ? '300px' : 'auto'}
                    {...headerItem.thProps}
                  >
                    {headerItem.label}
                  </Th>
                ))}
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {records.isLoading && (
                <>
                  <Tr>
                    <Td colSpan={headers.length + 1}>
                      <Skeleton height="40px" />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={headers.length + 1}>
                      <Skeleton height="40px" />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={headers.length + 1}>
                      <Skeleton height="40px" />
                    </Td>
                  </Tr>
                </>
              )}

              {records.list.length > 0 && !records.isLoading
                ? records.list.map((item) => (
                    <Tr
                      key={get(item, 'id')}
                      transition="all 150ms ease-in-out"
                      {...(trProps && trProps(item))}
                      {...(rowAction
                        ? {
                            onClick: () => {
                              rowAction(item)
                            },
                            cursor: 'pointer',
                            _hover: { bg: 'gray.50' },
                          }
                        : {})}
                    >
                      {headers.map((headerItem) => (
                        <Td {...headerItem.tdProps} key={v4()}>
                          {getCellLayout(item, headerItem)}
                        </Td>
                      ))}

                      <ListTableAction
                        menuButtonProps={menuButtonProps}
                        tableActionProps={tableActionProps}
                        record={item}
                        actions={listActions}
                      />
                    </Tr>
                  ))
                : !records.isLoading && emptyData()}
            </Tbody>
          </Table>
        </Box>
      </Flex>
    </Flex>
  )
}
