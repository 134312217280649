// Vendors
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

// Functions
import { useCreateRecord } from 'hooks/useCreateRecord'

// Components
import { TabDetail, Tabs } from 'components/Tabs'
import { FormFooter } from 'components/FormFooter'
import { TabGeneralNew } from '../tabs/TabGeneralNew'

// Schemas
import { schema } from './schema'

// Styles
import { Flex, useToast } from '@chakra-ui/react'

// Types
import { CompanyClinicNewForm, CompanyClinicNewRecord } from './types'

type CompanyClinicFormNewProps = {
  endpoint: string
}

export const CompanyClinicFormNew = (
  props: CompanyClinicFormNewProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props

  const { create, upload } = useCreateRecord({ endpoint })
  const { t } = useTranslation('common')
  const history = useHistory()

  const toast = useToast({ position: 'top-right' })

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Geral',
        component: TabGeneralNew,
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<CompanyClinicNewForm> = React.useCallback(
    async (data) => {
      try {
        const formattedData: CompanyClinicNewRecord = {
          ...data,
          companyType: 'CLINIC',
        }

        const response = await create(formattedData)

        if (!response) {
          throw new Error()
        }

        const { id } = response

        await upload(data.cardCnpj[0], `/app/company/${id}/upload/cnpj`, {
          group: 'CARD_CNPJ',
        })
        await upload(
          data.socialContract[0],
          `/app/company/${id}/upload/social_contract`,
          { group: 'SOCIAL_CONTRACT' }
        )

        toast({
          title: 'Criado com sucesso',
          description: 'Clinica criada com sucesso',
          status: 'success',
        })

        history.push('/companies')
      } catch (error: any) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            if (fieldError.field === 'documentNumber') {
              fieldError.message = 'Verifique se o CNPJ está correto'
            }
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [create, history, methods, toast, upload]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Tabs tabsDetails={tabsDetails} />

        <FormFooter isSubmitting={methods.formState.isSubmitting} />
      </Flex>
    </FormProvider>
  )
}
