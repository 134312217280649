// Vendors
import { filter, get } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, FieldError, useFormContext } from 'react-hook-form'

// Functions
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FieldDate } from 'components/FieldDate'
import { FieldText } from 'components/FieldText'
// import { TextEditor } from 'components/TextEditor'
import { ListImages } from 'components/ListImages'
import { FieldSelect } from 'components/FieldSelect'
import { FieldToggle } from 'components/FieldToggle'
import { FieldTextArea } from 'components/FieldTextArea'
import { FieldSelectColor } from 'components/FieldSelectColor'

// Styles
import { Box, Grid, GridItem, Image, Text } from '@chakra-ui/react'
import BlogBannerExample from 'assets/images/blog_banner.png'

// Types
import { AccentColorEnum, BlogStatusEnum } from 'types/Blog'
import { useFormTransform } from 'hooks/useFormTransform'

const TextEditor = React.lazy(() => import('components/TextEditor'))
type TabShowPostProps = {
  textEditorData: string
  setTextEditorData: string
  recordCurrent: any
  record: any
  id: string
}

export const TabShowPost = (props: TabShowPostProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const accentColorOptions = useMemo(() => {
    return Object.entries(AccentColorEnum).map(([key, value]) => ({
      label: value,
      value: key,
    }))
  }, [])

  const optionsStatus = useMemo(() => {
    return Object.entries(BlogStatusEnum).map(([key, value]) => {
      return {
        label: value.charAt(0).toUpperCase() + value.substr(1),
        value: key,
      }
    })
  }, [])

  const selectCategory = useSelectParser({
    accessor: { label: 'title', value: 'id' },
    endpoint: '/public/store_product_category',
  })

  const selectTags = useSelectParser({
    accessor: { label: 'name', value: 'id' },
    endpoint: '/app/store_product_tag',
  })

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id, record, recordCurrent, textEditorData, setTextEditorData } = props

  const { selectParser } = useFormTransform()

  const {
    watch,
    control,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<any>()

  const watchTagIds = watch('tagIds')
  const watchStatus = watch('status')
  const watchCategoryId = watch('categoryId')
  const watchAccentColor = watch('accentColor')

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const memoFeaturedImage = useMemo(() => {
    const image = filter(recordCurrent.images, { group: 'LISTING_POST' })[0]

    if (!image) {
      return { list: [] }
    }

    return {
      list: [image],
    }
  }, [recordCurrent.images])

  const memoComplementaryImages = useMemo(() => {
    const images = filter(recordCurrent.images, { group: 'IMAGES_POST' })

    if (!images || !images.length) {
      return { list: [] }
    }

    return {
      list: images,
    }
  }, [recordCurrent.images])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!watchStatus) return
    if (!optionsStatus.length) return
    if (typeof watchStatus === 'object') return

    const optionsCategory = selectParser(watchStatus, optionsStatus)

    setValue('status', optionsCategory)
  }, [optionsStatus, selectParser, setValue, watchStatus])

  useEffect(() => {
    if (!watchAccentColor) return
    if (!accentColorOptions.length) return
    if (typeof watchAccentColor === 'object') return

    const optionsCategory = selectParser(watchAccentColor, accentColorOptions)

    setValue('accentColor', optionsCategory)
  }, [accentColorOptions, selectParser, setValue, watchAccentColor])

  useEffect(() => {
    if (!watchCategoryId) return
    if (!selectCategory.options.length) return
    if (typeof watchCategoryId === 'object') return

    const optionsCategory = selectParser(
      watchCategoryId,
      selectCategory.options
    )

    setValue('categoryId', optionsCategory)
  }, [selectCategory.options, selectParser, setValue, watchCategoryId])

  useEffect(() => {
    if (!watchTagIds) return
    if (!selectTags.options.length) return
    if (typeof watchTagIds[0] === 'object') return

    const optionsTags = selectParser(watchTagIds, selectTags.options)

    setValue('tagIds', optionsTags)
  }, [selectParser, selectTags.options, setValue, watchTagIds])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tooltipFeatured = useCallback(() => {
    return (
      <Box>
        <Image src={BlogBannerExample} alt="blog banner example" />

        <Text padding="4">
          Use este campo para dar destaque a esse post no header do blog. Apenas
          o post mais recente marcado como favorito aparecerá no destaque.
        </Text>
      </Box>
    )
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <>
      <Box position="absolute" top={-14} right={0} w="32">
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <FieldSelect
              label="Status"
              error={errors.status as FieldError}
              options={optionsStatus}
              isRequired
              {...field}
            />
          )}
        />
      </Box>

      <Grid
        gap="10"
        my="4"
        templateColumns={{ base: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
      >
        <GridItem colSpan={2}>
          <Controller
            name="featured"
            control={control}
            defaultValue={!!recordCurrent.featured}
            render={({ field: { onChange, value, ref, name } }) => (
              <FieldToggle
                label="Favorito"
                name={name}
                ref={ref}
                tooltipProps={{
                  label: tooltipFeatured(),
                  padding: 0,
                }}
                isChecked={value}
                onChange={onChange}
                error={errors.status}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <FieldText
            maxLength={255}
            currentLength={get(watch('title'), 'length')}
            label="Título"
            error={errors.title}
            isRequired
            {...register('title')}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <FieldText
            maxLength={255}
            currentLength={get(watch('subtitle'), 'length')}
            label="Linha fina"
            error={errors.subtitle}
            isRequired
            {...register('subtitle')}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <FieldText
            maxLength={120}
            currentLength={get(watch('articleAuthor'), 'length')}
            label="Autor"
            error={errors.articleAuthor}
            {...register('articleAuthor')}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <FieldTextArea
            maxLength={280}
            currentLength={get(watch('articleLead'), 'length')}
            label="Resumo do post"
            error={errors.articleLead}
            {...register('articleLead')}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Controller
            name="accentColor"
            control={control}
            render={({ field }) => (
              <FieldSelectColor
                label="Cores"
                error={errors.accentColor as FieldError}
                options={accentColorOptions}
                isRequired
                {...field}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Controller
            name="tagIds"
            control={control}
            render={({ field }) => (
              <FieldSelect
                isMulti
                label="Tags vinculadas"
                error={errors.tagIds as FieldError}
                isLoading={selectTags.isLoading}
                options={selectTags.options}
                isRequired
                {...field}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Controller
            name="categoryId"
            control={control}
            render={({ field }) => (
              <FieldSelect
                label="Categorias"
                error={errors.categories as FieldError}
                isLoading={selectCategory.isLoading}
                options={selectCategory.options}
                isRequired
                {...field}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Controller
            name="publishingDate"
            control={control}
            render={({ field }) => (
              <FieldDate
                label="Data de publicação"
                name={field.name}
                selected={field.value}
                onChange={field.onChange}
                isRequired
                error={errors.publishingDate as FieldError}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={{ base: 2, xl: 4 }}>
          <TextEditor
            defaultValue={textEditorData}
            setTextEditorData={setTextEditorData}
          />
        </GridItem>

        <GridItem colSpan={{ base: 2, lg: 4 }}>
          <ListImages
            group="LISTING_POST"
            title="Imagem de destaque"
            records={{ get: memoFeaturedImage, set: () => {} }}
            endpoint={{
              post: `/app/blog/${id}/upload`,
              delete: `/app/blog/${id}/image`,
              patch: `/app/blog/${id}/image`,
            }}
            reloadRecord={record.reload}
            isSingle
          />
        </GridItem>

        <GridItem colSpan={{ base: 2, xl: 4 }}>
          <ListImages
            group="IMAGES_POST"
            title="Imagem complementar"
            records={{ get: memoComplementaryImages, set: () => {} }}
            endpoint={{
              post: `/app/blog/${id}/upload`,
              delete: `/app/blog/${id}/image`,
              patch: `/app/blog/${id}/image`,
            }}
            reloadRecord={record.reload}
            isSingle
          />
        </GridItem>
      </Grid>
    </>
  )
}
