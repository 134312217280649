// Vendors
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { filter, includes, some, map } from 'lodash'

// Components
import { NavItem } from './NavItem'
import { NavUser } from '../NavUser'
import { NavGroup } from './NavGroup'
import { Logo } from 'components/Logo'

// Styles
import {
  Box,
  Flex,
  Stack,
  FlexProps,
  HStack,
  Button,
  Text,
  Badge,
} from '@chakra-ui/react'
import { FiGrid, FiSearch } from 'react-icons/fi'

// Hooks
import { useAuth } from 'contexts/auth'

// Constants
import { SIDEBAR_RULES } from './rules'
import { useSearch } from 'contexts/search'

const Sidebar: React.FC<FlexProps> = (props: FlexProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { user } = useAuth()
  const { pathname } = useLocation()
  const { searchDisclosure } = useSearch()
  const { t } = useTranslation(['common'])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sidebarRole = useMemo(() => {
    const roleUserNames = map(user?.roleUser, 'role.name')

    const navGroup = filter(SIDEBAR_RULES, (rule) => {
      if (roleUserNames.includes('SUPERADMIN')) return true

      return some(roleUserNames, (roleName) =>
        includes(rule.allowedRoles, roleName)
      )
    })

    const unFormattedSidebar = map(navGroup, (rule) => {
      const childrenFilter = filter(rule.children, (child) => {
        if (roleUserNames.includes('SUPERADMIN')) return true

        return some(roleUserNames, (roleName) =>
          includes(child.allowedRoles, roleName)
        )
      })

      return { ...rule, children: childrenFilter }
    })

    return map(unFormattedSidebar, (rule) => {
      const childrenFormatted = map(rule.children, (child) => ({
        ...child,
        item: { ...child.item, label: t(child.item.label) },
      }))

      return { ...rule, children: childrenFormatted }
    })
  }, [user?.roleUser, t])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex
      direction="column"
      width="64"
      bg="gray.800"
      color="white"
      fontSize="sm"
      {...props}
    >
      <Flex h="full" direction="column" px="4" py="4">
        <Text
          as="a"
          href="https://hubxp.atlassian.net/wiki/spaces/TFY/blog"
          target="_blank"
          transition="all 0.2s"
          cursor="pointer"
          _hover={{
            filter: 'brightness(0.8)',
          }}
          letterSpacing="0px"
          fontSize={10}
          color="gray.300"
        >
          v1.0.14
        </Text>

        <Box py="2" pt="2" pb="6">
          <Link to="/">
            <Logo h="8" iconColor="white" color="white" w="full" />
          </Link>
        </Box>

        <NavUser />

        <Stack
          spacing="8"
          flex="1"
          overflow="auto"
          pt="8"
          css={{
            '&::-webkit-scrollbar': {
              width: '2px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#A8A6A8',
              borderRadius: '0px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#878587',
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: '0px',
              boxShadow: 'inset 0px 0px 0px 0px #F0F0F0',
            },
          }}
        >
          <Stack spacing="1">
            <NavItem
              path="/dashboard"
              active={pathname.startsWith('/dashboard')}
              icon={FiGrid}
              label={t('menu.dashboard')}
            />

            <Button
              color="inherit"
              variant="ghost"
              fontSize="inherit"
              fontWeight="inherit"
              justifyContent="flex-start"
              _active={{ bg: 'gray.800' }}
              _hover={{ bg: 'gray.700' }}
              leftIcon={<FiSearch size="18" />}
              onClick={searchDisclosure.onOpen}
            >
              <HStack flex="1" justifyContent="space-between">
                <Text>Busca global</Text>
                <Badge ml="2">Beta</Badge>
              </HStack>
            </Button>
          </Stack>
          {map(sidebarRole, (item) => (
            <NavGroup
              key={item.title}
              label={t(item.title)}
              data={map(item.children, (child) => ({
                ...child.item,
                active: pathname.startsWith(child.item.path ?? ''),
              }))}
            />
          ))}
        </Stack>
        <Box>
          <Stack spacing="1">
            {/* <NavItem
              path="/sysconfig"
              label={t('menu.config')}
              icon={FiSettings}
              subtle
            /> */}
            <HStack
              w="full"
              cursor="pointer"
              userSelect="none"
              rounded="md"
              transition="all 0.2s"
            >
              {/* <ButtonGroup spacing="0.5" size="sm" rounded="md"> */}
              {/* <Button
                  flex="1"
                  fontWeight="inherit"
                  color={'gray.400'}
                  bg={'unset'}
                  _hover={{ bg: 'gray.700' }}
                  _active={{ bg: 'gray.600' }}
                  onClick={() => {
                    i18n.changeLanguage('pt-BR')
                  }}
                >
                  PT
                </Button> */}

              {/* <Button
                  flex="1"
                  fontWeight="inherit"
                  color={'gray.400'}
                  bg={'unset'}
                  _hover={{ bg: 'gray.700' }}
                  _active={{ bg: 'gray.600' }}
                  onClick={() => {
                    i18n.changeLanguage('en-US')
                  }}
                >
                  EN
                </Button> */}

              {/* <Button
                  flex="1"
                  fontWeight="inherit"
                  color={'gray.400'}
                  bg={'unset'}
                  _hover={{ bg: 'gray.700' }}
                  _active={{ bg: 'gray.600' }}
                  onClick={() => {
                    i18n.changeLanguage('es')
                  }}
                >
                  ES
                </Button> */}

              {/* <Button
                  flex="1"
                  fontWeight="inherit"
                  color={'gray.400'}
                  bg={'unset'}
                  _hover={{ bg: 'gray.700' }}
                  _active={{ bg: 'gray.600' }}
                  onClick={() => {
                    i18n.changeLanguage('zh-Hans')
                  }}
                >
                  CN
                </Button> */}

              {/* <Button
                  flex="1"
                  fontWeight="inherit"
                  color={'gray.400'}
                  bg={'unset'}
                  _hover={{ bg: 'gray.700' }}
                  _active={{ bg: 'gray.600' }}
                  onClick={() => {
                    i18n.changeLanguage('ko')
                  }}
                >
                  KR
                </Button> */}
              {/* </ButtonGroup> */}
            </HStack>
          </Stack>
        </Box>
      </Flex>
    </Flex>
  )
}

export default Sidebar
