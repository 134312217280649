import React, { useMemo } from 'react'

import {
  Td,
  Th,
  Tr,
  Box,
  Text,
  Flex,
  Image,
  Table,
  Tbody,
  Thead,
  AspectRatio,
} from '@chakra-ui/react'
import { PurchaseShow } from '../../types'
import formatCurrency from 'utils/formatCurrency'

type TabFinancialProps = {
  order?: PurchaseShow
}

export const TabFinancial = (props: TabFinancialProps) => {
  const { order } = props

  const shoppingCartData = useMemo(() => {
    if (!order) return

    return order.shoppingCartData
  }, [order])

  if (!shoppingCartData) {
    return (
      <Box my="6" bg="#f6f6f6" p="6">
        <Text textAlign="center">
          O detalhamento de valores não está disponível para este pedido
        </Text>
      </Box>
    )
  }

  return (
    <Box>
      <Table>
        <Thead bg="gray.50">
          <Tr>
            <Th>Producto</Th>
            <Th>Valor</Th>
            <Th>Quantidade</Th>
            <Th>Subtotal</Th>
            <Th>Descontos</Th>
            <Th>Linha Subtotal</Th>
          </Tr>
        </Thead>

        <Tbody>
          {shoppingCartData?.storeProductsList.map((storeProduct) => (
            <Tr key={storeProduct.storeProductId} h="20">
              <Td>
                <Flex alignItems="center">
                  {storeProduct?.image && (
                    <AspectRatio w="98px" ratio={49 / 33}>
                      <Image
                        src={storeProduct.image}
                        alt={storeProduct.storeProductMktTitle}
                      />
                    </AspectRatio>
                  )}

                  <Text w="80" ml={storeProduct?.image ? '2' : '106px'}>
                    {storeProduct.storeProductMktTitle}
                  </Text>
                </Flex>
              </Td>

              <Td>{formatCurrency(storeProduct.unitPrice)}</Td>

              <Td>{storeProduct.quantity}</Td>

              <Td>
                {formatCurrency(storeProduct.subTotalPriceWithoutDiscount)}
              </Td>

              <Td>{formatCurrency(storeProduct.discountApplied)}</Td>

              <Td>{formatCurrency(storeProduct.subTotalPrice)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Table mt="6">
        <Thead bg="gray.50">
          <Tr>
            <Th w="96">Frete</Th>
            <Th>Valor</Th>
            <Th>Desconto</Th>
            <Th>Total com desconto</Th>
          </Tr>
        </Thead>

        <Tbody>
          <Tr h="20">
            <Td>{order?.shippingMethod?.providerName}</Td>

            <Td>
              {formatCurrency(shoppingCartData?.totalPriceWithoutDiscount || 0)}
            </Td>

            <Td>{formatCurrency(shoppingCartData?.totalDiscount || 0)}</Td>

            <Td fontWeight="semibold">
              {formatCurrency(shoppingCartData?.totalPrice || 0)}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  )
}
