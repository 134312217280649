// Vendors
import React, { ReactNode } from 'react'
import { Draggable } from 'react-beautiful-dnd'

// Components
import { FormFieldEditerFooter } from 'components/FormFieldEditerFooter'

// Styles
import { FiMenu, FiMinus } from 'react-icons/fi'
import { Stack, Flex, Divider, IconButton, SimpleGrid } from '@chakra-ui/react'

// Interfaces
import { HandleQuestionType, QuestionType } from 'hooks/useFormFieldEditer'

export type FormFieldEditerProps = {
  index: number
  children?: ReactNode
  hideFooter?: boolean
  question: QuestionType
  handleQuestion: HandleQuestionType
}

export const FormFieldEditer = (props: FormFieldEditerProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { index, children, question, hideFooter, handleQuestion } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Draggable draggableId={`form-field-editer-${question.id}`} index={index}>
      {(provided) => (
        <Flex
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          pl="10"
          pr="5"
          py="5"
          borderRadius="1"
          borderWidth="1px"
          position="relative"
          alignItems="center"
          borderStyle="solid"
          bg="white"
          borderColor="#E2E8F0"
          justifyContent="space-between"
          boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
        >
          <Stack
            bg="inherit"
            borderRadius="18"
            left="-4"
            spacing="1"
            direction="column"
            alignItems="center"
            position="absolute"
          >
            <IconButton
              size="sm"
              aria-label="drag"
              icon={<FiMenu />}
              borderRadius="full"
            />
            <IconButton
              size="sm"
              variant="outline"
              borderWidth="2px"
              colorScheme="red"
              borderRadius="full"
              aria-label="remove editor"
              icon={<FiMinus strokeWidth="4px" />}
              onClick={() => handleQuestion.remove(question.id)}
            />
          </Stack>

          <Stack w="100%" spacing="4" divider={<Divider />}>
            <SimpleGrid columns={{ md: 1, lg: 2 }} gap="8" w="100%">
              {children}
            </SimpleGrid>

            {!hideFooter && (
              <FormFieldEditerFooter
                index={index}
                question={question}
                handleQuestion={handleQuestion}
              />
            )}
          </Stack>
        </Flex>
      )}
    </Draggable>
  )
}
