// Vendors
import { kebabCase, map } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form'

// Services
import { apiPatch } from 'services/patch'

// Hooks
import { useError } from 'hooks/useError'
import { useSelectParser } from 'hooks/useSelectParser'

// Functions
import { formatResponseMedicalRecord } from './formatResponseMedicalRecord'
import { formatClinicalRecordFormResponse } from './formatClinicalRecordFormResponse'

// Components
import { FieldSelect } from 'components/FieldSelect'

// Styles
import {
  Modal,
  Stack,
  Button,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react'
import { MedicalRecord } from './MedicalRecord'
import {
  FormQuestionType,
  LaboratorialAnalysis,
} from 'types/LaboratorialAnalysis'

type Client = {
  id: string
  userId: string
  name: string
  email: string
  phoneNumber: string
  birthdate: Date
  status: 'ENABLED' | 'DISABLED'
  createdAt: Date
  updatedAt: Date
  documentNumber?: string
}

export type LaboratoryAnalysisType = LaboratorialAnalysis & {
  clinicalRecordFormResponse: {
    questions: FormQuestionType[]
  }
}

type ModalShowActiveKitProps = {
  isOpen: boolean
  reload: () => void
  onCancel: () => void
  activationCode: string
  laboratoryAnalysis: LaboratoryAnalysisType[]
  activationByPersonalData?: { id?: string; name?: string }
  activationForPersonalData?: { id?: string; name?: string }
}

type FormActiveKit = {
  activationByPersonalData: { value: string } | null
  activationForPersonalData: { value: string } | null
}

export const ModalShowActiveKit = (props: ModalShowActiveKitProps) => {
  const selectPatient = useSelectParser<Client>({
    accessor: {
      label: ({ name, documentNumber }) =>
        `${name} ${
          documentNumber &&
          `(${documentNumber.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})$/,
            '$1.$2.$3-$4'
          )})`
        }`,
      value: 'id',
    },
    endpoint: '/app/client',
  })
  const selectProfessional = useSelectParser<Client>({
    accessor: {
      label: ({ name }) => `${name}`,
      value: 'id',
    },
    endpoint: '/app/professional',
  })

  const {
    isOpen,
    reload,
    onCancel,
    activationCode,
    laboratoryAnalysis,
    activationByPersonalData,
    activationForPersonalData,
  } = props

  const methods = useForm()

  useEffect(() => {
    const newActivationByPersonalData = activationByPersonalData && {
      value: activationByPersonalData.id,
      label: activationByPersonalData.name,
    }

    const newActivationForPersonalData = activationForPersonalData && {
      value: activationForPersonalData.id,
      label: activationForPersonalData.name,
    }

    methods.reset({
      activationByPersonalData: newActivationByPersonalData,
      activationForPersonalData: newActivationForPersonalData,
      ...formatClinicalRecordFormResponse(laboratoryAnalysis),
    })
  }, [
    activationByPersonalData,
    activationForPersonalData,
    laboratoryAnalysis,
    methods,
  ])

  const { handleError } = useError()

  const onClose = useCallback(() => {
    methods.reset()
    onCancel()
  }, [methods, onCancel])

  const formatResponse = useCallback(
    (data: FormActiveKit) => {
      const analysis = map(laboratoryAnalysis, (analysis) => {
        return {
          laboratoryAnalysisId: analysis.id,
          questions: analysis?.clinicalRecord?.clinicalRecordForm?.questions,
        }
      })

      Object.entries(data).forEach(([key, value]) => {
        analysis.forEach(({ questions }) => {
          const draft = questions.find((q) => q.id === key)
          if (!draft) return

          formatResponseMedicalRecord(
            draft,
            (value as any)[kebabCase(draft.label)]
          )
        })
      })

      return analysis
    },
    [laboratoryAnalysis]
  )

  const formatPayload = useCallback(
    (
      response: Array<{
        laboratoryAnalysisId: string
        questions: FormQuestionType[]
      }>,
      data: FormActiveKit
    ) => {
      return {
        activatedByPersonalDataId: data.activationByPersonalData?.value,
        activatedForPersonalDataId: data.activationForPersonalData?.value,
        clinicalRecordFormResponses: response,
      }
    },
    []
  )

  const onSubmit = useCallback(
    async (data: FormActiveKit) => {
      try {
        if (!laboratoryAnalysis) return
        const response = formatResponse(data)
        const payload = formatPayload(response, data)

        await apiPatch(
          `/app/purchase_has_product/kit/${activationCode}`,
          payload
        )

        reload()
        onClose()
      } catch (error) {
        handleError(error)
      }
    },
    [
      laboratoryAnalysis,
      formatResponse,
      formatPayload,
      activationCode,
      reload,
      onClose,
      handleError,
    ]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />

      <FormProvider {...methods}>
        <ModalContent as="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalHeader>Edição do kit</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Stack spacing={4}>
              <Controller
                control={methods.control}
                name="activationForPersonalData"
                render={({ field }) => (
                  <FieldSelect
                    label="Escolha um paciente"
                    error={
                      methods.formState.errors
                        .activationForPersonalData as FieldError
                    }
                    options={selectPatient.options}
                    isLoading={selectPatient.isLoading}
                    placeholder="Selecione um paciente..."
                    {...field}
                  />
                )}
              />

              <Controller
                control={methods.control}
                name="activationByPersonalData"
                render={({ field }) => (
                  <FieldSelect
                    label="Escolha um profissional"
                    error={
                      methods.formState.errors
                        .activationByPersonalData as FieldError
                    }
                    options={selectProfessional.options}
                    isLoading={selectProfessional.isLoading}
                    placeholder="Selecione um profissional..."
                    {...field}
                  />
                )}
              />

              {laboratoryAnalysis && (
                <MedicalRecord laboratoryAnalysis={laboratoryAnalysis} />
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button onClick={onClose}>Cancelar</Button>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={methods.formState.isSubmitting}
              >
                Salvar
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </Modal>
  )
}
