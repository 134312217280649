// Vendors
import { find, get, intersection, map } from 'lodash'
import { format, parseISO } from 'date-fns'
import { Link, useHistory } from 'react-router-dom'
import React, { useMemo, useCallback, useEffect, ChangeEvent } from 'react'

// Functions
import { useAuth } from 'contexts/auth'
import { UserRolesNames } from 'types/User'
import { useTranslation } from 'react-i18next'
import { useListRecords } from 'hooks/useListRecords'
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListActions } from 'components/ListActions'
import { ListPagination } from 'components/ListPagination'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles
import { Button, HStack, Select, Stack } from '@chakra-ui/react'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Types
import { Laboratory } from './types'
import { PageMetaSetting } from './meta'

export const PageLaboratoryListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectSampleTransportKit = useSelectParser({
    accessor: { label: 'name', value: 'id' },
    endpoint: '/public/store_product?status=ENABLED',
  })

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { userRolesNames } = useAuth()
  const { t } = useTranslation('laboratory')
  const history = useHistory()
  const { endpoint, title } = PageMetaSetting
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<Laboratory>({
    endpoint,
    query: {
      sort: '-updatedAt',
    },
  })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!Records) return

    Records.list.forEach((record) => {
      // We are simply getting the first purchaseHasProductHasAnalysis here
      // because all files we'll have the same estimated issue date for now.
      // In future we may have different dates for each analysis report file.

      const reportFileEstimatedIssueDate = get(
        record,
        'purchaseHasProduct.purchaseHasProductHasAnalysis[0].reportFileEstimatedIssueDate'
      )

      record.formattedDateLimit = reportFileEstimatedIssueDate
        ? format(parseISO(reportFileEstimatedIssueDate), 'dd/MM/yyyy')
        : 'Sem previsão de entrega'

      const dateCheckIn = find(record.productReverseLogisticStatusUpdate, [
        'status',
        'KIT_ACCEPTED',
      ])?.createdAt

      record.formattedActivatedAt = record.purchaseHasProduct?.activatedAt
        ? format(parseISO(record.purchaseHasProduct.activatedAt), 'dd/MM/yyyy')
        : '-'

      record.formattedInternalLaboratoryId = record.internalLaboratoryId ?? '-'
      record.formattedDateCheckIn = dateCheckIn
        ? format(parseISO(dateCheckIn), 'dd/MM/yyyy')
        : '-'

      record.formattedIsCobranding = record.isCobranding ? 'COBRANDING' : '-'
      record.activationCode = record.purchaseHasProduct.activationCode
      record.mktTitle = record.purchaseHasProduct.storeProduct.mktTitle

      record.formattedlaboratoryAnalysisNames = map(
        record.purchaseHasProduct.purchaseHasProductHasAnalysis,
        (analysis) => get(analysis, 'laboratoryAnalysis.name')
      )?.join(', ')
    })
  }, [Records])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const ListTableHeaders = useMemo(
    () => [
      {
        label: t('table.idKit'),
        accessor: 'purchaseHasProduct.activationCode',
      },
      {
        label: t('table.checkIn'),
        accessor: 'formattedDateCheckIn',
      },
      {
        label: t('table.deliveryForecast'),
        accessor: 'formattedDateLimit',
      },
      {
        label: t('table.activatedAt'),
        accessor: 'formattedActivatedAt',
      },
      {
        label: t('table.kitName'),
        accessor: 'mktTitle',
      },
      {
        label: t('table.buyerName'),
        accessor: 'patientName',
      },
      {
        label: t('table.kitStatus'),
        render: { as: ListTableRenderAs.BADGE },
        accessor: 'status',
      },
      {
        label: t('table.isCobranding'),
        accessor: 'formattedIsCobranding',
      },
    ],
    [t]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const ListTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/laboratory/${Record.purchaseHasProduct.activationCode}`)
    },
    [history]
  )

  const fnTableActionProps = useCallback((record: any) => {
    return {
      bg: record.isCobranding
        ? 'linear-gradient(90deg, rgba(253, 201, 11, 0.144) 0.01%, rgba(254, 223, 110, 0.207217) 0.06%, rgba(255, 255, 255, 0.3) 5%);'
        : '',
    }
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={title}>
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ListFilters query={Query}>
          <HStack>
            <Select
              size="sm"
              placeholder="Todos os status"
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                Query.set({
                  ...Query.current,
                  status: e.target.value,
                })
              }}
            >
              <option value="KIT_ACTIVATED">Kit ativado</option>
              <option value="PENDING_REVERSE_CODE">
                Logístca reversa pendente
              </option>
              <option value="IN_ANALYSIS">Em análise</option>
              <option value="REPORT_GENERATED">Laudo gerado</option>
              <option value="KIT_ACCEPTED">Kit aceito</option>
              <option value="SAMPLE_DENIED">Amostra negada</option>
              <option value="DIVERGENT_DATA">Dados divergentes</option>
              <option value="REQUESTED_RECOLLECT">Recoleta solicitada</option>
              <option value="ATTENDED_RECOLLECT">Recoleta atendida</option>
            </Select>

            <Select
              size="sm"
              placeholder="Todos os produtos"
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                Query.set({
                  ...Query.current,
                  storeProductId: e.target.value,
                })
              }}
            >
              {selectSampleTransportKit.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>

            <Select
              size="sm"
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                Query.set({
                  ...Query.current,
                  sort: e.target.value,
                })
              }}
            >
              <option value="-updatedAt">Mais recentes</option>
              <option value="-dateLimit">Data de entrega mais próxima</option>
            </Select>
          </HStack>
        </ListFilters>

        <ListActions>
          <Button
            as={Link}
            to={'/laboratory/kit-check-in'}
            colorScheme="blue"
            variant="solid"
          >
            KIT Check-in
          </Button>
        </ListActions>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={ListTableRowAction}
        defaultActions="none"
        trProps={fnTableActionProps}
        emptyMessage={`Para kits comercializados antes de 18/04 que não estiverem na lista ${'\n'} por favor procurar o Stuart e informar o ID do kit em questão`}
        actions={[
          {
            type: 'requestCollection',
            options: {
              reload: Records.reload,
              tooltipProps: {
                label: 'Apenas o gestor pode solicitar a recoleta',
                shouldWrapChildren: true,
                isDisabled:
                  intersection(userRolesNames, [
                    UserRolesNames.LABORATORY_MANAGER,
                    UserRolesNames.SUPERADMIN,
                  ]).length > 0,
              },
              showWhen: ({ status }) => {
                return (
                  status !== 'KIT_ACTIVATED' &&
                  status !== 'PENDING_REVERSE_CODE' &&
                  status !== 'REQUESTED_RECOLLECT' &&
                  status !== 'ATTENDED_RECOLLECT'
                )
              },
              notDisableWhen: () => {
                return (
                  intersection(userRolesNames, [
                    UserRolesNames.LABORATORY_MANAGER,
                    UserRolesNames.SUPERADMIN,
                  ]).length === 0
                )
              },
            },
          },
        ]}
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
