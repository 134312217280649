// Vendors
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

// Components
import { FormAddress } from '../FormAddress'
import { FormWrapper } from 'components/FormWrapper'

// Styles
import { Checkbox } from '@chakra-ui/react'
import { useCart } from 'contexts/cart'
import { apiShow } from 'services/get'
import { useQuery } from 'hooks/useQuery'
import { AddressType } from './types'

export const ShippingData = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('checkout')
  const { addPostalCodeToCart } = useCart()

  const { watch, register } = useFormContext()
  const query = useQuery()

  const watchShippingAddressId = watch('shippingAddressId')
  const watchPostalCode = watch('shippingAddress.postalCode')

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!watchShippingAddressId) return

    apiShow<AddressType>(
      `/app/person/${query.get('personId')}/address/${watchShippingAddressId}`
    ).then((address) => {
      if (!address) return

      addPostalCodeToCart(address.postalCode).catch((error) =>
        console.error(error)
      )
    })
  }, [addPostalCodeToCart, query, watchShippingAddressId])

  useEffect(() => {
    if (!watchPostalCode) return

    const postalCode = watchPostalCode.replace(/\D/g, '')

    if (postalCode.length !== 8) return

    addPostalCodeToCart(postalCode).catch((error) => console.error(error))
  }, [addPostalCodeToCart, watchPostalCode])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormWrapper title={t('group.shippingData')}>
      <FormAddress name="shippingAddress">
        <Checkbox pt="4" {...register('sameBillingAddress')}>
          {t('form.sameBillingAddress.label')}
        </Checkbox>
      </FormAddress>
    </FormWrapper>
  )
}
