// Vendors
import { useTranslation } from 'react-i18next'
import { Button, Stack } from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'
import React, { useCallback, useEffect, useState } from 'react'

// Hooks
import { useQuery } from 'hooks/useQuery'
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { Form } from '../FormAddress/Form'
import { FieldRadioButtonCustom } from 'components/FieldRadioButtonCustom'

// Types
import { AddressType } from '../ShippingData/types'

type FormAddressProps = {
  name: string
  children?: React.ReactNode
}

export const FormAddress = (props: FormAddressProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { name, children } = props

  const query = useQuery()
  const { t } = useTranslation('checkout')

  const { control, register, setValue, unregister } = useFormContext()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [showFormAddress, setShowFormAddress] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const formattedLabel = useCallback((item: AddressType) => {
    if (!item) return 'Nenhum endereço encontrado.'

    const postalCodeFormatted = item.postalCode.replace(
      /(\d{5})(\d{3})/,
      '$1-$2'
    )

    return `${item.street} ${item.number}, ${item.neighborhood}, ${
      item.city.name
    }, ${item.state.fullName}, ${postalCodeFormatted} ${
      item.complement ? `, ${item.complement}` : ''
    }`
  }, [])

  const selectAddress = useSelectParser<AddressType>({
    endpoint: query.get('personId')
      ? `/app/person/${query.get('personId')}/address`
      : undefined,
    accessor: { label: formattedLabel, value: 'id' },
    requestWithoutMeta: true,
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const toogleFormAddress = useCallback(() => {
    if (showFormAddress) {
      unregister(`${name}`)
      register(`${name}Id`)
      setValue(`${name}Id`, selectAddress.options[0].value)
    } else {
      unregister(`${name}Id`)
      register(`${name}`)
    }

    setShowFormAddress(!showFormAddress)
  }, [
    name,
    register,
    selectAddress.options,
    setValue,
    showFormAddress,
    unregister,
  ])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (selectAddress.options.length === 0) {
      setShowFormAddress(true)
      unregister(`${name}Id`)
      register(`${name}`)
    }

    if (selectAddress.options.length > 0) {
      unregister(`${name}`)
      register(`${name}Id`)
      setShowFormAddress(false)
      setValue(`${name}Id`, selectAddress.options[0].value)
    }
  }, [name, register, selectAddress.options, setValue, unregister])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Stack spacing="4">
      {!showFormAddress ? (
        <Controller
          name={`${name}Id`}
          control={control}
          render={({ field }) => (
            <FieldRadioButtonCustom
              options={selectAddress.options}
              {...field}
            />
          )}
        />
      ) : (
        <Form name={`${name}`} />
      )}

      {selectAddress.options.length > 0 && (
        <Button
          variant="link"
          alignSelf="flex-start"
          onClick={toogleFormAddress}
        >
          {t('button.showFormAddress', {
            isShow: !showFormAddress ? t('button.show') : t('button.hide'),
          })}
        </Button>
      )}

      {children}
    </Stack>
  )
}
