export enum AccentColorEnum {
  'Polo' = '#94BDDA',
  'Blush' = '#EC6A96',
  'Lilac' = '#CCA3CC',
  'Viola' = '#B781AD',
  'Mango' = '#FCB43F',
  'Willow' = '#AACF7C',
  'Sienna' = '#EF8261',
  'Orchid' = '#ED8C95',
  'Cranberry' = '#DC5D77',
  'Fountain' = '#44A4B2',
  'Mauvelous' = '#F18CB9',
}
