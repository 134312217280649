// Vendors
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import React, { useCallback, useEffect, useMemo } from 'react'

// Hooks
import { useEditRecords } from 'hooks/useEditRecord'

// Components
import { FieldText } from 'components/FieldText'
import { FormFooter } from 'components/FormFooter'

// Schemas
import { schema } from './schema'

// Styles
import { Flex, Grid, GridItem, useToast } from '@chakra-ui/react'

// Types
import { SalesCategoryShowAPI, SalesCategoryShowForm } from './types'
import { FieldStatus } from 'components/FieldStatus'
import { apiPut } from 'services/put'
import { useFeedback } from 'contexts/feedback'

type SalesCategoryFormShowProps = {
  id: string
  endpoint: string
}

export const SalesCategoryFormShow = (
  props: SalesCategoryFormShowProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id, endpoint } = props

  const { t } = useTranslation('salesCategory')

  const methods = useForm<SalesCategoryShowForm>({
    resolver: yupResolver(schema(t)),
  })
  const toast = useToast({ position: 'top-right' })
  const { feedback } = useFeedback()

  const { record } = useEditRecords<
    SalesCategoryShowAPI,
    SalesCategoryShowForm
  >({
    endpoint,
    currentId: id,
  })
  const recordCurrent = get(record, 'current')

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sanitize = useCallback((data: SalesCategoryShowAPI) => {
    if (Object.keys(data).length === 0) return {}

    return {
      name: get(data, 'name'),
      status: get(data, 'status'),
    }
  }, [])

  useEffect(() => {
    methods.reset(sanitize(recordCurrent))
  }, [recordCurrent, sanitize, methods])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDisabled = useCallback(async () => {
    await apiPut(`${endpoint}/${id}`, {
      status: 'DISABLED',
    })
  }, [endpoint, id])

  const handleEnabled = useCallback(async () => {
    await apiPut(`${endpoint}/${id}`, {
      status: 'ENABLED',
    })
  }, [endpoint, id])

  const onSubmit: SubmitHandler<SalesCategoryShowForm> = React.useCallback(
    async (data) => {
      try {
        await record.update(data)

        toast({
          title: t('toast.success.title'),
          description: t('toast.success.update'),
          status: 'success',
        })
      } catch (error: any) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        }

        const errorMessage =
          error?.response?.data.message ||
          error?.message ||
          t('toast.error.default')

        feedback({
          title: 'Atenção',
          description: errorMessage,
          status: 'error',
        })
      }
    },
    [feedback, methods, record, t, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      as="form"
      h="full"
      position="relative"
      flexDirection="column"
      justifyContent="space-between"
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <FieldStatus
        disable={handleDisabled}
        enable={handleEnabled}
        methods={methods}
      />

      <Grid
        my="4"
        gap="10"
        templateColumns={{ lg: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
      >
        <GridItem colSpan={2}>
          <FieldText
            label={t('form.name.label')}
            error={methods.formState.errors.name}
            isRequired
            {...methods.register('name')}
          />
        </GridItem>
      </Grid>

      <FormFooter
        recordDelete={record.delete}
        isSubmitting={methods.formState.isSubmitting}
      />
    </Flex>
  )
}
