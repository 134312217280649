import React, { useMemo } from 'react'
import { Divider, Stack, Text } from '@chakra-ui/react'
import { NotificationCard } from '../NotificationCard'
import { useNotification } from 'contexts/notification'
import { NotificationStatus } from 'contexts/notification/types'

export const TabMessage = () => {
  const { notifications } = useNotification()

  const notificationsUnread = useMemo(() => {
    if (!notifications) {
      return null
    }

    const draft = notifications[NotificationStatus.UNREAD]

    if (!draft) {
      return null
    }

    return draft.original
  }, [notifications])

  const notificationsRead = useMemo(() => {
    if (!notifications) {
      return null
    }

    const draft = notifications[NotificationStatus.READ]

    if (!draft) {
      return null
    }

    return draft.original
  }, [notifications])

  const isBlank = useMemo(() => {
    if (!notificationsUnread && !notificationsRead) {
      return true
    }

    if (notificationsUnread?.length === 0 && notificationsRead?.length === 0) {
      return true
    }

    return false
  }, [notificationsRead, notificationsUnread])

  return (
    <Stack w="full" spacing="0" divider={<Divider />}>
      {isBlank ? (
        <Text
          pt="6"
          pb="3"
          display="flex"
          fontSize="xs"
          fontWeight="thin"
          fontStyle="italic"
          alignItems="center"
          justifyContent="center"
        >
          Você não tem nenhuma mensagem
        </Text>
      ) : (
        <>
          <Stack boxShadow="md" mb="2.5" divider={<Divider />}>
            {notificationsUnread &&
              notificationsUnread.map((notification) => (
                <NotificationCard key={notification.id} data={notification} />
              ))}
          </Stack>

          {notificationsRead && (
            <Stack divider={<Divider />}>
              {notificationsRead.map((notification) => (
                <NotificationCard key={notification.id} data={notification} />
              ))}
            </Stack>
          )}
        </>
      )}
    </Stack>
  )
}
