import { UserRolesNames } from 'types/User'

export const RULES = [
  {
    name: 'discountPercentage50',
    allowedRoles: [
      UserRolesNames.BACKOFFICE,
      UserRolesNames.ADMIN,
      UserRolesNames.SUPERADMIN,
    ],
  },
]
