// Vendors
import React, { useMemo, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'

// Functions
import { useTranslation } from 'react-i18next'
import { useListRecords } from 'hooks/useListRecords'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListActions } from 'components/ListActions'
import { ListPagination } from 'components/ListPagination'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'
import { Button, Stack } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'

// Types
import { SalesCategory } from './types'

import { PageMetaSetting } from './meta'

export const PageSalesCategoryListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('salesCategory')
  const history = useHistory()
  const { endpoint, title } = PageMetaSetting
  const { records, query, pagination } = useListRecords<SalesCategory>({
    endpoint,
  })

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const listTableHeaders = useMemo(
    () => [
      {
        label: t('table.headers.name'),
        accessor: 'name',
      },
      {
        label: t('table.headers.status'),
        accessor: 'status',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
    ],
    [t]
  )

  const listTableRowAction = useCallback(
    (record: SalesCategory) => {
      if (!record) return
      history.push(`/sales-category/${record.id}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={title}>
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ListFilters query={query} />

        <ListActions>
          <Button as={Link} to={'/sales-category/new'} leftIcon={<FiPlus />}>
            Adicionar
          </Button>
        </ListActions>
      </Stack>

      <ListTable
        records={records}
        headers={listTableHeaders}
        rowAction={listTableRowAction}
      />

      <ListPagination pagination={pagination} query={query} />
    </LayoutLoggedPageList>
  )
}
