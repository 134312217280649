import { PageMeta } from 'types/Pages'

export const PageMetaSettings: PageMeta[] = [
  {
    location: 'notifications',
    title: 'Notificações',
    endpoint: '',
    breadcrumbs: [],
  },
  {
    location: 'recollects',
    title: 'Recollect',
    endpoint: '/app/purchase/request_recollect',
    breadcrumbs: [],
  },
  {
    location: 'clinics',
    title: 'Clinic',
    endpoint: '/app/company?type=clinic',
    breadcrumbs: [],
  },
  {
    location: 'invoices',
    title: 'Financeiros',
    endpoint: '/app/expedition/order',
    breadcrumbs: [],
  },
  {
    location: 'audits',
    title: 'Logs',
    endpoint: '/',
    breadcrumbs: [],
  },

  {
    location: 'sellers',
    title: 'Vendedores',
    endpoint: '/app/person?type=seller',
    breadcrumbs: [],
  },
  {
    location: 'motoboy',
    title: 'Motoboy',
    endpoint: '/app/expedition/order',
    breadcrumbs: [],
  },
  {
    location: '/',
    title: '',
    endpoint: '/',
    breadcrumbs: [],
  },
  {
    location: 'login',
    title: '',
    endpoint: '/',
    breadcrumbs: [],
  },
  {
    location: 'sales-category',
    title: 'Categoria de vendas',
    endpoint: '/app/purchase_category',
    breadcrumbs: [],
  },
  {
    location: 'dashboard',
    title: 'Dashboard',
    endpoint: '/',
    breadcrumbs: [],
  },
  {
    location: 'checkout',
    title: 'Checkout',
    endpoint: '/',
    breadcrumbs: [],
  },
  {
    location: 'correios',
    title: 'Dashboard',
    endpoint: '/',
    breadcrumbs: [],
  },
  {
    location: 'orders',
    title: 'Pedidos',
    endpoint: '/',
    breadcrumbs: [],
  },
  {
    location: 'genericrecordslist',
    title: 'Generic Page',
    endpoint: '/app/store_product_category',
    breadcrumbs: [],
  },
  {
    location: 'users',
    title: 'Usuários',
    endpoint: '/app/person',
    breadcrumbs: [],
  },
  {
    location: 'companies',
    title: 'Empresas',
    endpoint: '/app/company',
    breadcrumbs: [],
  },
  {
    location: 'company',
    title: 'Empresas',
    endpoint: '/app/company',
    breadcrumbs: [],
  },
  {
    location: 'laboratory',
    title: 'Laboratory',
    endpoint: '/app/company',
    breadcrumbs: [],
  },
  {
    location: 'products',
    title: 'Produtos',
    endpoint: '/public/store_product',
    breadcrumbs: [],
  },
  {
    location: 'clients',
    title: 'Clientes',
    endpoint: '/app/person',
    breadcrumbs: [],
  },
  {
    location: 'prospects',
    title: 'Prospects',
    endpoint: '/app/person',
    breadcrumbs: [],
  },
  {
    location: 'contracts',
    title: 'Contracts',
    endpoint: '/app/person',
    breadcrumbs: [],
  },
  {
    location: 'laboratorial_analysis',
    title: 'Análises Laboratoriais',
    endpoint: '/app/laboratory_analysis',
    breadcrumbs: [],
  },
  {
    location: 'kits',
    title: 'Kits',
    endpoint: '/app/sample_transport_kit',
    breadcrumbs: [],
  },
  {
    location: 'sample_transport_kit',
    title: 'Kits',
    endpoint: '/app/sample_transport_kit',
    breadcrumbs: [],
  },
  {
    location: 'category',
    title: 'Categorias',
    endpoint: '/app/store_product_category',
    breadcrumbs: [],
  },
  {
    location: 'coupons',
    title: 'Cupons',
    endpoint: '/app/coupon',
    breadcrumbs: [],
  },
  {
    location: 'influencers',
    title: 'Influencers',
    endpoint: '/app/influencer',
    breadcrumbs: [],
  },
  {
    location: 'influencer',
    title: 'Influencer',
    endpoint: '/app/person',
    breadcrumbs: [],
  },
  {
    location: 'professionals',
    title: 'Professionals',
    endpoint: '/app/professional',
    breadcrumbs: [],
  },
  {
    location: 'professional',
    title: 'Professional',
    endpoint: '/app/person',
    breadcrumbs: [],
  },
  {
    location: 'reports',
    title: 'Relatórios',
    endpoint: '/app/reports',
    breadcrumbs: [],
  },
  {
    location: 'genericrecord',
    title: 'Relatórios',
    endpoint: '/app/reports',
    breadcrumbs: [],
  },
  {
    location: 'sysconfig',
    title: 'Configurações',
    endpoint: '/app/reports',
    breadcrumbs: [],
  },
  {
    location: '/users/new',
    title: 'Cadastro de Usuários',
    endpoint: '/person',
    breadcrumbs: [],
  },
  {
    location: 'blog',
    title: 'Blog',
    endpoint: '/app/blog',
    breadcrumbs: [],
  },
]
