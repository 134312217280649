// Vendors
import React, { ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Styles
import {
  Box,
  Flex,
  Button,
  Spacer,
  Divider,
  BoxProps,
  ButtonGroup,
  useDisclosure,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { FiTrash2 } from 'react-icons/fi'
import { AlertDialogDelete } from 'components/AlertDialogDelete'

// Interfaces
export type FormFooterProps = BoxProps & {
  isSubmitting: boolean
  children?: ReactNode
  onCancel?: () => void
  recordDelete?: () => Promise<void>
  isDisabled?: boolean
  titleDialog?: string
  isDisabledDialog?: boolean
  bodyDialog?: React.ReactNode
  hasAlert?: boolean
  alertTitle?: string
  alertDescription?: string
  saveButtonText?: string
  confirmLabel?: string
}

export const FormFooter = (props: FormFooterProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('common')
  const {
    isSubmitting,
    recordDelete,
    onCancel,
    children,
    isDisabled,
    titleDialog,
    bodyDialog,
    isDisabledDialog,
    alertTitle,
    alertDescription,
    hasAlert = false,
    saveButtonText,
    confirmLabel,
    ...rest
  } = props

  const { isOpen, onClose, onOpen } = useDisclosure()
  const history = useHistory()

  const message = (
    <>
      <Text>
        <Text as="span" fontWeight="bold">
          Atenção:{' '}
        </Text>
        Após salvar este registro, os campos indicados acima não poderão ser
        modificados.
      </Text>
      <Text>
        Em caso de mudanças, será necessário excluir ou desabilitar o item
        cadastrado e adicionar um novo.
      </Text>
    </>
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box {...rest} pb="2rem !important">
      {recordDelete && (
        <AlertDialogDelete
          isOpen={isOpen}
          onCancel={onClose}
          titleDialog={titleDialog}
          bodyDialog={bodyDialog}
          isDisabled={isDisabledDialog}
          confirmLabel={confirmLabel}
          onConfirm={async () => {
            await recordDelete()
            onClose()
          }}
        />
      )}

      <Divider borderColor={'gray.500'} mt="24" mb="8" />
      <Flex justifyContent="space-between">
        {children}

        {hasAlert && (
          <Alert status="warning" borderRadius="md">
            <AlertIcon />
            <AlertTitle mr={2}>{alertTitle}</AlertTitle>

            <AlertDescription isTruncated>
              {alertDescription || message}
            </AlertDescription>
          </Alert>
        )}

        {recordDelete && (
          <Button
            onClick={onOpen}
            colorScheme="red"
            variant="outline"
            leftIcon={<FiTrash2 />}
          >
            {confirmLabel || t('button.delete')}
          </Button>
        )}
        <Spacer />

        <ButtonGroup spacing="4" ml="4">
          <Button onClick={onCancel || history.goBack}>
            {t('button.cancel')}
          </Button>
          <Button
            colorScheme="blue"
            type="submit"
            isLoading={isSubmitting}
            isDisabled={isDisabled}
          >
            {saveButtonText || t('button.save')}
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  )
}
