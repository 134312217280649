// Vendors
import React, { useState } from 'react'

// Components
import { FieldText } from 'components/FieldText'
import { FormFieldEditerFieldType } from 'components/FormFieldEditerFieldType'
import { FormFieldEditerFieldPreview } from 'components/FormFieldEditerFieldPreview'
import {
  FormFieldEditer,
  FormFieldEditerProps,
} from 'components/FormFieldEditer'

// Styles

// Interfaces
export type FormFieldEditerTextProps = {
  index: number
  id: string
}

export const FormFieldEditerText = (
  props: FormFieldEditerProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [fieldName, setFieldName] = useState(props.question.label)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormFieldEditer {...props}>
      <FormFieldEditerFieldType
        index={props.index}
        handleQuestion={props.handleQuestion}
        fieldTypeLabel="TEXTO"
        fieldName={{ get: fieldName, set: setFieldName }}
      />

      <FormFieldEditerFieldPreview>
        <FieldText
          name={`text-${props.index}`}
          label={fieldName !== '' ? fieldName : 'Nome do campo'}
        />
      </FormFieldEditerFieldPreview>
    </FormFieldEditer>
  )
}
