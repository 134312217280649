import { useAuth } from 'contexts/auth'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import api from 'services/api'

import {
  GlobalConfigContextValues,
  GlobalConfigContextProviderProps,
  GlobalConfigActivationCode,
} from './types'

export const GlobalConfigContext = createContext<GlobalConfigContextValues>(
  {} as GlobalConfigContextValues
)

export const GlobalConfigProvider = ({
  children,
}: GlobalConfigContextProviderProps) => {
  const { isLoggedIn } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [activationCodes, setActivationCodes] = useState<
    GlobalConfigActivationCode[]
  >([])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const formatActivationCode = useCallback(
    (value: string) => {
      const valueCharacters = value.replace(/[^\w\s]/gi, '')

      const globalConfigActivationCodeRegexFormat = activationCodes.find(
        (activationCode) => valueCharacters.match(activationCode.regexFormat)
      )

      if (!globalConfigActivationCodeRegexFormat) return valueCharacters

      const regexFormat = new RegExp(
        globalConfigActivationCodeRegexFormat.regexFormat,
        'g'
      )

      return valueCharacters.replace(
        regexFormat,
        globalConfigActivationCodeRegexFormat.format
      )
    },
    [activationCodes]
  )

  const validateActivationCode = useCallback(
    (value: string) => {
      if (activationCodes.length === 0) return false

      const valueCharacters = formatActivationCode(value)

      const globalConfigActivationCodeValidation = activationCodes.find(
        (activationCode) => valueCharacters.match(activationCode.validation)
      )

      if (globalConfigActivationCodeValidation) {
        return true
      }

      return false
    },
    [activationCodes, formatActivationCode]
  )

  const fetchGlobalConfigActivationCode = useCallback(async () => {
    const response = await api.get<GlobalConfigActivationCode[]>(
      '/public/global_configuration/activation_code'
    )

    setActivationCodes(response.data)
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!isLoggedIn) return
    fetchGlobalConfigActivationCode()
  }, [fetchGlobalConfigActivationCode, isLoggedIn])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <GlobalConfigContext.Provider
      value={{ validateActivationCode, formatActivationCode }}
    >
      {children}
    </GlobalConfigContext.Provider>
  )
}

export const useGlobalConfig = () => {
  const context = useContext(GlobalConfigContext)

  return context
}
