import { TFunction } from 'react-i18next'
import { yupSchema } from 'utils/yupSchema'

import * as yup from 'yup'

export const schema = (t: TFunction<'common'>) => {
  yupSchema(t)

  return yup.object().shape({
    name: yup.string().required().label(t('form.company.name.label')),

    documentNumber: yup
      .string()
      .getCharacters()
      .length(14)
      .required()
      .label(t('form.company.documentNumber.label')),

    street: yup.string().required().label(t('form.company.street.label')),

    number: yup.string().required().label(t('form.company.number.label')),

    cityId: yup
      .string()
      .required()
      .selectValue()
      .label(t('form.company.cityId.label')),

    stateId: yup
      .string()
      .selectValue()
      .required()
      .label(t('form.company.stateId.label')),

    postalCode: yup
      .string()
      .getCharacters()
      .length(8)
      .required()
      .label(t('form.company.postalCode.label')),

    complement: yup
      .string()
      .optional()
      .label(t('form.company.complement.label')),

    neighborhood: yup
      .string()
      .required()
      .label(t('form.company.neighborhood.label')),

    referencePoint: yup
      .string()
      .optional()
      .label(t('form.company.referencePoint.label')),

    // Others informations
    states: yup
      .array()
      .min(1)
      .selectValues()
      .required()
      .label(t('form.company.states.label')),

    cities: yup
      .array()
      .min(1)
      .selectValues()
      .required()
      .label(t('form.company.cities.label')),

    logisticManager: yup
      .array()
      .min(1)
      .selectValues()
      .required()
      .label(t('form.company.logisticManager.label')),

    logisticAgent: yup
      .array()
      .min(1)
      .selectValues()
      .required()
      .label(t('form.company.logisticAgent.label')),

    deliveryPrice: yup
      .number()
      .required()
      .positive()
      .label(t('form.company.priceDelivery.label')),

    maxWidthInCentimeter: yup
      .number()
      .required()
      .positive()
      .max(999.99)
      .label(t('form.company.maxWidthInCentimeter.label')),

    maxHeightInCentimeter: yup
      .number()
      .required()
      .positive()
      .max(999.99)
      .label(t('form.company.maxHeightInCentimeter.label')),

    maxLengthInCentimeter: yup
      .number()
      .required()
      .positive()
      .max(999.99)
      .label(t('form.company.maxLengthInCentimeter.label')),

    maxWeigthInGrams: yup
      .number()
      .required()
      .positive()
      .max(999.99)
      .label(t('form.company.maxWeigthInGrams.label')),
  })
}
