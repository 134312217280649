import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

export const InfectionsOne = (
  props: HTMLChakraProps<'svg'> & {
    iconColor?: string
    colorStopInit: string
    colorStopEnd: string
  }
) => {
  const { iconColor, colorStopInit, colorStopEnd, ...rest } = props
  const color = iconColor || 'white'
  return (
    <chakra.svg
      id="Componente_58_1"
      data-name="Componente 58 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="53.73"
      height="53.73"
      viewBox="0 0 53.73 53.73"
      {...rest}
    >
      <svg>
        <path
          id="Caminho_22"
          data-name="Caminho 22"
          d="M26.865,53.73A26.865,26.865,0,1,0,0,26.865,26.865,26.865,0,0,0,26.865,53.73Z"
          fill={colorStopInit}
        />
        <path
          id="Caminho_183"
          data-name="Caminho 183"
          d="M114.343,238.468h2.713a4.885,4.885,0,0,0,.672,1.614l-1.928,1.927a1.017,1.017,0,0,0,0,1.442,1.006,1.006,0,0,0,.714.3.982.982,0,0,0,.714-.3l1.956-1.956a4.447,4.447,0,0,0,1.328.528v2.656a1.014,1.014,0,0,0,2.028,0v-2.656a4.529,4.529,0,0,0,1.285-.5l1.8,1.8a1.015,1.015,0,1,0,1.428-1.442l-1.742-1.757a4.594,4.594,0,0,0,.672-1.5h2.413a1.014,1.014,0,0,0,0-2.027h-2.371a4.424,4.424,0,0,0-.557-1.4l1.7-1.7a1.02,1.02,0,0,0-1.442-1.442l-1.656,1.656a4.5,4.5,0,0,0-1.542-.657v-2.484a1.014,1.014,0,1,0-2.028,0v2.456a4.471,4.471,0,0,0-1.442.6l-1.842-1.841a1.019,1.019,0,0,0-1.442,1.442l1.841,1.857a4.489,4.489,0,0,0-.571,1.342H114.3a1.018,1.018,0,0,0-1.014,1.014A1.087,1.087,0,0,0,114.343,238.468Z"
          transform="translate(-98.944 -221.05)"
          fill={color}
        />
        <path
          id="Caminho_184"
          data-name="Caminho 184"
          d="M151.137,253.108h-2.371a4.414,4.414,0,0,0-.557-1.4l1.7-1.7a1.02,1.02,0,1,0-1.442-1.442l-1.657,1.656a4.491,4.491,0,0,0-1.542-.657v-2.457a1.014,1.014,0,1,0-2.027,0v2.457a4.468,4.468,0,0,0-1.442.6l-1.842-1.842a1.02,1.02,0,1,0-1.442,1.442l1.842,1.856a4.474,4.474,0,0,0-.571,1.342h-2.741a1.014,1.014,0,1,0,0,2.028h2.712a4.891,4.891,0,0,0,.672,1.614l-1.928,1.927a1.017,1.017,0,0,0,0,1.442,1.006,1.006,0,0,0,.714.3.98.98,0,0,0,.714-.3l1.956-1.956a4.445,4.445,0,0,0,1.328.528V261.2a1.014,1.014,0,0,0,2.028,0v-2.656a4.529,4.529,0,0,0,1.285-.5l1.8,1.8a1.015,1.015,0,0,0,1.428-1.442l-1.742-1.757a4.607,4.607,0,0,0,.671-1.5h2.413a1.018,1.018,0,0,0,1.014-1.014A.952.952,0,0,0,151.137,253.108Z"
          transform="translate(-106.036 -226.208)"
          fill={color}
        />
        <path
          id="Caminho_185"
          data-name="Caminho 185"
          d="M126.63,266.507h-2.37a4.419,4.419,0,0,0-.557-1.4l1.7-1.7a1.02,1.02,0,0,0-1.442-1.442l-1.656,1.657a4.5,4.5,0,0,0-1.542-.657V260.5a1.014,1.014,0,0,0-2.028,0v2.456a4.481,4.481,0,0,0-1.442.6l-1.841-1.842a1.02,1.02,0,1,0-1.442,1.442l1.842,1.856a4.482,4.482,0,0,0-.571,1.343h-2.742a1.014,1.014,0,0,0,0,2.028h2.713a4.9,4.9,0,0,0,.671,1.614l-1.927,1.928a1.021,1.021,0,0,0,.714,1.742.985.985,0,0,0,.714-.3l1.956-1.956a4.444,4.444,0,0,0,1.328.528v2.656a1.014,1.014,0,0,0,2.028,0v-2.656a4.527,4.527,0,0,0,1.285-.5l1.8,1.8a1,1,0,0,0,1.428,0,1.017,1.017,0,0,0,0-1.442l-1.742-1.756a4.592,4.592,0,0,0,.672-1.5h2.413a1.018,1.018,0,0,0,1.014-1.014A.94.94,0,0,0,126.63,266.507Z"
          transform="translate(-98.393 -230.383)"
          fill={color}
        />
      </svg>
    </chakra.svg>
  )
}
