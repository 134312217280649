// Vendors
import React, { useCallback } from 'react'

// Hooks
import { useError } from 'hooks/useError'
import { useDownload } from 'hooks/useDownload'

// Styles
import { FiChevronDown } from 'react-icons/fi'
import { Menu, Button, MenuItem, MenuList, MenuButton } from '@chakra-ui/react'

// Interfaces
import { Query } from 'types/Requests'

export type MenuExportsProps = {
  endpoint: string
  query?: Query
}

export const MenuExports = (props: MenuExportsProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint, query } = props
  const { onDownload } = useDownload()
  const { handleError } = useError()

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleClick = useCallback(
    async (type: 'csv' | 'pdf') => {
      try {
        await onDownload(`${endpoint}/${type}`, `report.${type}`, query)
      } catch (error: any) {
        handleError(error)
      }
    },
    [onDownload, endpoint, query, handleError]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<FiChevronDown />}>
        Exportar
      </MenuButton>

      <MenuList>
        <MenuItem onClick={() => handleClick('csv')}>CSV</MenuItem>
        <MenuItem onClick={() => handleClick('pdf')}>PDF</MenuItem>
      </MenuList>
    </Menu>
  )
}
