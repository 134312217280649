import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

export const Swab = (
  props: HTMLChakraProps<'svg'> & {
    iconColor?: string
    colorStopInit: string
    colorStopEnd: string
  }
) => {
  const { iconColor, colorStopInit, colorStopEnd, ...rest } = props
  const color = iconColor || 'white'
  return (
    <chakra.svg
      id="Componente_52_1"
      data-name="Componente 52 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="53.73"
      height="53.73"
      viewBox="0 0 53.73 53.73"
      {...rest}
    >
      <path
        id="Caminho_1"
        data-name="Caminho 1"
        d="M26.865,53.73A26.865,26.865,0,1,0,0,26.865,26.865,26.865,0,0,0,26.865,53.73Z"
        fill={colorStopInit}
      />
      <g id="Grupo_33" data-name="Grupo 33" transform="translate(12.665 6.717)">
        <path
          id="Caminho_43353"
          data-name="Caminho 43353"
          d="M18.1,1.234,8.055,16.619a1.062,1.062,0,0,0-.671-.406L17.433.772,16.037,0,5.173,16.677A17.5,17.5,0,0,0,.558,20.813a3.542,3.542,0,0,0,1.11,4.913,3.655,3.655,0,0,0,4.946-1.081,16.715,16.715,0,0,0,1.894-5.809L19.46,2.066ZM5.279,23.791a2.017,2.017,0,0,1-2.773.567,1.969,1.969,0,0,1-.612-2.689,15.438,15.438,0,0,1,4-3.636.82.82,0,0,0,.345-.178.967.967,0,0,1,.613-.141,1.046,1.046,0,0,1,.134.647l-.026.176A13.966,13.966,0,0,1,5.279,23.791Z"
          transform="translate(0 14.138) rotate(-7)"
          fill={color}
        />
        <path
          id="Caminho_43354"
          data-name="Caminho 43354"
          d="M5.064,13.726l-4.584-3A1.041,1.041,0,0,1,.193,9.244L6.054.644a1.468,1.468,0,0,1,2.021-.4l4,2.611a1.41,1.41,0,0,1,.4,1.987l-5.862,8.6A1.15,1.15,0,0,1,5.064,13.726Z"
          transform="translate(13.225 1.551) rotate(-7)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
      </g>
    </chakra.svg>
  )
}
