// Vendors
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'

// Hooks
import { useError } from 'hooks/useError'

// Components
import { FieldText } from 'components/FieldText'
import { FieldTextArea } from 'components/FieldTextArea'
import { DrawerListContent } from 'components/DrawerListContent'

// Styles
import {
  Stack,
  Button,
  Divider,
  useToast,
  DrawerBody,
  ButtonGroup,
  DrawerFooter,
} from '@chakra-ui/react'

import { schema } from './schema'
import { apiPatch } from 'services/patch'

import { useGlobalConfig } from 'contexts/globalConfig'

// Interfaces
export type DrawerFormActivateCodeProps = {
  kitId: string
  isOpen: boolean
  onClose: () => void
  recordReload: () => void
}

export const DrawerFormActivateCode = (
  props: DrawerFormActivateCodeProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('common')
  const { isOpen, onClose, kitId, recordReload } = props
  const { handleError } = useError()
  const toast = useToast({ position: 'top-right' })

  const { formatActivationCode, validateActivationCode } = useGlobalConfig()

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onCloseDrawer = useCallback(() => {
    reset({})
    onClose()
  }, [onClose, reset])

  const onSubmit = useCallback(
    async (data: { activationCode: string; comments?: string }) => {
      try {
        const isValidActivationCode = validateActivationCode(
          data.activationCode
        )

        if (!isValidActivationCode) {
          throw new Error('Código de ativação inválido')
        }

        await apiPatch('/app/purchase_product/activation_code', {
          activationCodes: [
            {
              purchaseHasProductId: kitId,
              activationCode: data.activationCode.toUpperCase(),
            },
          ],
          comments: data.comments,
        })

        toast({
          title: t('drawer.changeCode.toast.success.title'),
          description: t('drawer.changeCode.toast.success.description'),
          status: 'success',
        })
        onCloseDrawer()
        recordReload()
      } catch (error) {
        handleError(error)
      }
    },
    [
      t,
      toast,
      kitId,
      handleError,
      recordReload,
      onCloseDrawer,
      validateActivationCode,
    ]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <DrawerListContent
      title={t('drawer.changeCode.title')}
      isOpen={isOpen}
      onClose={onCloseDrawer}
    >
      <Divider />

      <DrawerBody>
        <Stack>
          <Controller
            name="activationCode"
            control={control}
            rules={{
              required: 'Código de ativação é obrigatório',
            }}
            render={({ field }) => (
              <FieldText
                label={t('drawer.changeCode.form.activateCode.label')}
                error={errors.activationCode}
                maxLength={19}
                {...field}
                onChange={(e) =>
                  field.onChange(formatActivationCode(e.target.value))
                }
                isRequired
              />
            )}
          />

          <FieldTextArea
            label={t('drawer.changeCode.form.comments.label')}
            error={errors.comments}
            {...register('comments')}
          />
        </Stack>
      </DrawerBody>

      <Divider />

      <DrawerFooter>
        <ButtonGroup>
          <Button onClick={onCloseDrawer}>Cancelar</Button>

          <Button
            colorScheme="blue"
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            Salvar
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </DrawerListContent>
  )
}
