import { AccentColorEnum } from 'pages/private/Category/form/new/types'
import { TFunction } from 'react-i18next'
import { yupSchema } from 'utils/yupSchema'
import { FILE_SIZE, FILE_SIZE_BYTES } from '../../../../../constants/constants'

import * as yup from 'yup'
import { testIsRequired } from 'utils/testIsRequired'

export const schema = (t: TFunction<'products'>) => {
  yupSchema(t)

  let isAvailableInStore = false

  return yup.object().shape({
    // General
    name: yup.string().required().label(t('form.name.label')),

    storeProductCode: yup
      .string()
      .required()
      .label(t('form.storeProductCode.label')),

    testfyBaseIds: yup
      .array()
      .selectValues()
      .min(1)
      .required()
      .label(t('form.testfyBaseIds.label')),

    sampleTransportKitId: yup
      .string()
      .selectValue()
      .required()
      .label(t('form.sampleTransportKitId.label')),

    storeProductCategoryIds: yup
      .array()
      .selectValues()
      .required()
      .label(t('form.storeProductCategoryId.label')),

    // Sellers
    isAvailableInStore: yup
      .boolean()
      .transform((value) => {
        isAvailableInStore = value
        return value
      })
      .label(t('form.isAvailableInStore.label')),

    priceB2b: yup.number().min(0).required().label(t('form.priceB2b.label')),

    priceB2c: yup.number().min(0).required().label(t('form.priceB2c.label')),

    status: yup
      .string()
      .required()
      .label(t('form.status.label'))
      .default('DRAFT'),

    isEligibleB2bDiscount: yup
      .boolean()
      .label(t('form.isEligibleB2bDiscount.label')),

    isB2cAvailable: yup.boolean().label(t('form.isB2cAvailable.label')),

    isB2bAvailable: yup.boolean().label(t('form.isB2bAvailable.label')),

    isServiceIncluded: yup
      .boolean()
      .label(t('form.isLaboratoryAnalysisIncluded.label')),

    isSellerAvailable: yup.boolean().label(t('form.isSellerAvailable.label')),

    storeProductHasLaboratoryAnalysisIds: yup
      .array()
      .selectValues()
      .min(1)
      .required()
      .label(t('form.storeProductAttendLaboratoryAnalysisIds.label')),

    progressiveDiscount: yup
      .array()
      .min(1)
      .required()
      .label(t('form.progressiveDiscount.label')),

    // Promotional
    mktTitle: yup
      .string()
      .when('isAvailableInStore', (isAvailableInStore, field) => {
        return isAvailableInStore ? field.required() : field.notRequired()
      })
      .label(t('form.title.label')),

    mktDescription: yup
      .string()
      .when('isAvailableInStore', (isAvailableInStore, field) => {
        return isAvailableInStore ? field.required() : field.notRequired()
      })
      .label(t('form.description.label')),

    mktAbstract: yup
      .string()
      .when('isAvailableInStore', (isAvailableInStore, field) => {
        return isAvailableInStore ? field.required() : field.notRequired()
      })
      .label(t('form.abstract.label')),

    attributes: yup
      .array(
        yup.object().shape({
          icon: yup
            .string()
            .test({
              name: 'isRequired',
              exclusive: true,
              message: 'O ícone é obrigatório',
              test: function (value) {
                return testIsRequired(value, isAvailableInStore)
              },
            })
            .label('Ícone'),
          label: yup
            .string()
            .test({
              name: 'isRequired',
              exclusive: true,
              message: 'O título é obrigatório',
              test: function (value) {
                return testIsRequired(value, isAvailableInStore)
              },
            })
            .label('Título'),
          url: yup.string(),
        })
      )
      .transform((value) =>
        value.map((v: any) => ({ ...v, icon: v.icon.value }))
      )
      .min(1)
      .when('isAvailableInStore', (isAvailableInStore, field) => {
        return isAvailableInStore ? field.required() : field.notRequired()
      })
      .label(t('form.attributes.label')),

    faq: yup
      .array(
        yup.object().shape({
          label: yup
            .string()
            .test({
              name: 'isRequired',
              exclusive: true,
              message: 'O título na lateral é obrigatório',
              test: function (value) {
                return testIsRequired(value, isAvailableInStore)
              },
            })
            .label('Título na lateral'),
          title: yup
            .string()
            .test({
              name: 'isRequired',
              exclusive: true,
              message: 'O título no texto é obrigatório',
              test: function (value) {
                return testIsRequired(value, isAvailableInStore)
              },
            })
            .label('Título no texto'),
          description: yup
            .string()
            .test({
              name: 'isRequired',
              exclusive: true,
              message: 'O descrição é obrigatório',
              test: function (value) {
                return testIsRequired(value, isAvailableInStore)
              },
            })
            .label('Descrição'),
        })
      )
      .min(1)
      .when('isAvailableInStore', (isAvailableInStore, field) => {
        return isAvailableInStore ? field.required() : field.notRequired()
      })
      .label(t('form.faq.label')),

    reportOverviewTitle: yup
      .string()
      .when('isAvailableInStore', (isAvailableInStore, field) => {
        return isAvailableInStore ? field.required() : field.notRequired()
      })
      .label(t('form.reportOverviewTitle.label')),

    reportOverviewAbstract: yup
      .string()
      .when('isAvailableInStore', (isAvailableInStore, field) => {
        return isAvailableInStore ? field.required() : field.notRequired()
      })
      .label(t('form.reportOverviewAbstract.label')),

    reportOverviewPDF: yup
      .mixed()
      .label(t('form.reportOverviewPDF.label'))
      .test(
        'fileSize',
        `O arquivo deve ser menor que ${FILE_SIZE_BYTES}MB`,
        (value) => {
          if (!value || !value[0]) return true
          return value[0].size <= FILE_SIZE
        }
      )
      .test({
        name: 'isRequired',
        exclusive: true,
        message: 'O arquivo é obrigatório',
        test: function (value) {
          return testIsRequired(value, isAvailableInStore)
        },
      }),

    accentColor: yup
      .string()
      .selectValue()
      .when('isAvailableInStore', (isAvailableInStore, field) => {
        return isAvailableInStore ? field.required() : field.notRequired()
      })
      .oneOf(Object.keys(AccentColorEnum))
      .label(t('form.accentColor.label')),

    // SEO
    seoTitle: yup
      .string()
      .required()
      .label(t('common:form.tabSeo.seoTitle.label')),

    seoDescription: yup
      .string()
      .required()
      .label(t('common:form.tabSeo.seoDescription.label')),

    slug: yup
      .string()
      .kebabCase()
      .required()
      .label(t('common:form.tabSeo.slug.label')),

    seoKeywords: yup
      .array()
      .required()
      .selectValues()
      .label(t('common:form.tabSeo.seoKeywords.label')),

    seoAdditionalKeywords: yup
      .string()
      .nullable()
      .label(t('common:form.tabSeo.seoAdditionalKeywords.label')),
  })
}
