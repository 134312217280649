import { Query } from 'types/Requests'
import api from './api'

const handleDeleteFailure = (error: unknown): void => {
  console.log(
    `Um erro ocorreu ao deletar o item, por favor tente novamente.`,
    error
  )
}

export async function apiDelete<T>(
  endpoint: string,
  data?: T,
  query?: Query
): Promise<T | undefined> {
  let payload
  try {
    const { data: response } = await api.delete(endpoint, {
      data,
      params: { ...query },
    })
    payload = response
  } catch (error: unknown) {
    handleDeleteFailure(error)
    payload = undefined
    throw error
  }
  return payload
}
