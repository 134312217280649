// Vendors
import React, { ChangeEvent, useCallback } from 'react'
import { debounce, find, get } from 'lodash'
import { Control, Controller, UseFormRegister, useWatch } from 'react-hook-form'

// Functions
import formatCurrency from 'utils/formatCurrency'

// Components
import { FieldText } from 'components/FieldText'
import { FieldNumber } from 'components/FieldNumber'
import { FieldSelect } from 'components/FieldSelect'

// Styles
import { FiMinusCircle } from 'react-icons/fi'
import {
  Tr,
  Td,
  HStack,
  Checkbox,
  IconButton,
  useToken,
  Skeleton,
} from '@chakra-ui/react'
import { SelectType } from 'hooks/useSelectParser'
import { FormProduct, ProductList, ProductType } from './types'
import { useCart } from 'contexts/cart'
import { StoreProductType } from 'contexts/cart/interface'

type TableDataProps = {
  field: ProductType & { id: string }
  index: number
  control: Control<FormProduct>
  register: UseFormRegister<FormProduct>
  onSetValue: (index: number, product: ProductType) => void
  options: {
    products: {
      options: SelectType<ProductList>[]
      isLoading: boolean
    }
  }
  onRemove: (index: number) => void
}

export const TableData = (props: TableDataProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { field, index, onRemove, control, options, onSetValue } = props

  const {
    addProductToCart,
    updateProductPrice,
    updateProductQuantity,
    removeProductFromCart,
    toggleApplyCoupon,
  } = useCart()

  const red500 = useToken('colors', 'red.500')
  const product = useWatch({
    control,
    name: `purchaseProducts.${index}`,
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sanitizeProduct = useCallback(
    (storeProduct: StoreProductType) => {
      const {
        quantity,
        unitPrice,
        unitPriceB2c,
        subTotalPrice,
        storeProductId,
        discountApplied,
        storeProductMktTitle,
        subTotalPriceWithoutDiscount,
      } = storeProduct

      return {
        storeProductId: {
          value: storeProductId,
          label: storeProductMktTitle,
        },
        unitPrice,
        quantity,
        subtotal: subTotalPriceWithoutDiscount,
        totalDiscount: discountApplied,
        totalPrice: subTotalPrice,
        originalPrice: unitPriceB2c,
        isCustomPrice: product.isCustomPrice,
      }
    },
    [product.isCustomPrice]
  )

  const handleRemoveProduct = useCallback(async () => {
    await removeProductFromCart(index)
    onRemove(index)
  }, [index, onRemove, removeProductFromCart])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdateProductQuantity = useCallback(
    debounce((quantity: number) => {
      updateProductQuantity(index, quantity).then((response) => {
        if (!response) return

        const storeProduct = response.data?.storeProductsList.find(
          (_, index) => index === response.position
        )

        if (!storeProduct) return

        onSetValue(index, sanitizeProduct(storeProduct))
      })
    }, 500),
    [index, updateProductQuantity]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdateProductPrice = useCallback(
    debounce((productId: string, unitPrice: number) => {
      updateProductPrice(index, unitPrice).then((response) => {
        if (!response) return

        const storeProduct = response.data?.storeProductsList.find(
          (_, index) => index === response.position
        )

        if (!storeProduct) return

        onSetValue(index, sanitizeProduct(storeProduct))
      })
    }, 500),
    [updateProductPrice, onSetValue, index, sanitizeProduct]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleIsCustomPrice = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target

      if (checked) return

      const { originalPrice, unitPrice } = product

      if (!originalPrice) return
      if (originalPrice === unitPrice) return

      updateProductPrice(index, originalPrice).then((response) => {
        if (!response) return

        const storeProduct = response.data?.storeProductsList.find(
          (_, index) => index === response.position
        )

        if (!storeProduct) return

        const sanitizedProduct = sanitizeProduct(storeProduct)

        onSetValue(index, {
          ...sanitizedProduct,
          isCustomPrice: false,
        })
      })
    }, 500),
    [index, onSetValue, product, sanitizeProduct, updateProductPrice]
  )

  const handleApplyCoupon = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target

      try {
        const response = await toggleApplyCoupon(index, checked)

        if (!response) return

        const storeProduct = response.data?.storeProductsList.find(
          (_, index) => index === response.position
        )

        if (!storeProduct) return

        const sanitizedProduct = sanitizeProduct(storeProduct)

        onSetValue(index, {
          ...sanitizedProduct,
        })
      } catch (error) {
        console.error(error)
      }
    },
    [index, onSetValue, sanitizeProduct, toggleApplyCoupon]
  )

  const handleChangeProduct = useCallback(
    (e: { value: string }) => {
      const productId = get(e, 'value')

      addProductToCart(index, productId).then((response) => {
        if (!response) return

        const storeProduct = response.data?.storeProductsList.find(
          (_, index) => index === response.position
        )

        if (!storeProduct) return

        onSetValue(index, sanitizeProduct(storeProduct))
      })
    },
    [addProductToCart, index, onSetValue, sanitizeProduct]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Tr key={field.id} color="gray.700" h="20">
      <Td w="96" maxW="96">
        <Controller
          name={`purchaseProducts.${index}.storeProductId`}
          control={control}
          render={({ field }) => (
            <FieldSelect
              options={options.products.options}
              isLoading={options.products.isLoading}
              {...field}
              onChange={(e) => {
                handleChangeProduct(e as { value: string })
                field.onChange(e)
              }}
            />
          )}
        />
      </Td>

      <Td>
        <HStack alignItems="center" justifyContent="center">
          <FieldNumber
            min={0}
            minW="24"
            control={control}
            showNumberStepper={false}
            customOnChange={(e) =>
              handleUpdateProductPrice(product.storeProductId.value, Number(e))
            }
            name={`purchaseProducts.${index}.unitPrice`}
            isDisabled={!get(product, `isCustomPrice`)}
          />

          <Controller
            name={`purchaseProducts.${index}.isCustomPrice`}
            control={control}
            render={({ field }) => (
              <Checkbox
                pt="2"
                name={field.name}
                isChecked={field.value}
                onChange={(e) => {
                  handleIsCustomPrice(e)
                  field.onChange(e)
                }}
              />
            )}
          />
        </HStack>
      </Td>

      <Td>
        <FieldNumber
          min={1}
          minW="24"
          control={control}
          customOnChange={(e) => handleUpdateProductQuantity(Number(e))}
          name={`purchaseProducts.${index}.quantity`}
        />
      </Td>

      <Td>
        <Controller
          name={`purchaseProducts.${index}.subtotal`}
          control={control}
          render={({ field }) => (
            <FieldText
              minW="32"
              isDisabled
              {...field}
              value={formatCurrency(field.value)}
            />
          )}
        />
      </Td>

      <Td>
        <HStack>
          <Controller
            name={`purchaseProducts.${index}.totalDiscount`}
            control={control}
            render={({ field }) => (
              <FieldText
                minW="32"
                isDisabled
                {...field}
                value={formatCurrency(field.value)}
              />
            )}
          />

          <Controller
            name={`purchaseProducts.${index}.applyCoupon`}
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <Checkbox
                pt="2"
                name={field.name}
                isChecked={field.value}
                onChange={(e) => {
                  handleApplyCoupon(e)
                  field.onChange(e)
                }}
              />
            )}
          />
        </HStack>
      </Td>

      <Td>
        <Controller
          name={`purchaseProducts.${index}.totalPrice`}
          control={control}
          render={({ field }) => (
            <FieldText
              minW="32"
              isDisabled
              {...field}
              value={formatCurrency(field.value)}
            />
          )}
        />
      </Td>

      <Td>
        <IconButton
          size="sm"
          variant="ghost"
          aria-label="remove product"
          icon={<FiMinusCircle size="20" color={red500} />}
          onClick={async () => await handleRemoveProduct()}
        />
      </Td>
    </Tr>
  )
}
