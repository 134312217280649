// Vendors
import React from 'react'
import { useFormContext } from 'react-hook-form'

// Components
import { FieldNumber } from 'components/FieldNumber'

// Styles
import { Box } from '@chakra-ui/react'
import { useFormFieldEditor } from '../useFormFieldEditor'
import { FormFieldEditorProps } from '../MedicalRecord'
import { get } from 'lodash'

// Hooks

// Types

export const FormFieldEditorNumber = (props: FormFieldEditorProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { question } = props
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const { rules, label, name } = useFormFieldEditor({ question })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <FieldNumber
        name={name}
        label={label}
        error={get(errors, name)}
        control={control}
        rules={rules}
      />
    </Box>
  )
}
