// Vendors
import { find, get } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { Controller, FieldError, useFormContext } from 'react-hook-form'

// Hooks
import { useAddress } from 'hooks/useAddress'
import { useCepPromise } from 'hooks/useCepPromise'
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FieldMask } from 'components/FieldMask'
import { FieldSelect } from 'components/FieldSelect'
import { FieldText } from 'components/FieldText'

// Styles
import { Grid, GridItem } from '@chakra-ui/react'

type FormProps = {
  name: string
}

export const Form = (props: FormProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Contants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { name } = props

  const { fetchCEP } = useCepPromise()
  const { getState, getCity } = useAddress()

  const {
    watch,
    control,
    register,
    setValue,
    setFocus,
    setError,
    getValues,
    formState: { errors },
  } = useFormContext()

  const watchState = watch(`${name}.stateId`)

  const cityId = getValues(`${name}.cityId`)
  const stateId = getValues(`${name}.stateId`)

  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectStates = useSelectParser({
    endpoint: '/app/state',
    accessor: { label: 'abbreviatedName', value: 'id' },
    requestWithoutMeta: true,
  })

  const selectCities = useSelectParser({
    endpoint: watchState?.label ? `/app/city?state=${watchState.label}` : '',
    accessor: { label: 'name', value: 'id' },
    requestWithoutMeta: true,
  })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!stateId) return
    const state = find(selectStates.options, { value: stateId })

    if (!state) return
    setValue(`${name}.stateId`, state)
  }, [
    cityId,
    stateId,
    setValue,
    selectStates.options,
    selectCities.options,
    name,
  ])

  useEffect(() => {
    if (!cityId) return
    const city = find(selectCities.options, { value: cityId })

    if (!city) return
    setValue(`${name}.cityId`, city)
  }, [cityId, name, selectCities.options, setValue])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleAddPostalCode = useCallback(async () => {
    let postalCode = watch(`${name}.postalCode`)

    if (!postalCode) return

    postalCode = postalCode.replace(/\D/g, '')
    if (postalCode.length !== 8) return

    try {
      const response = await fetchCEP(postalCode)

      if (response) {
        const stateOption = getState(response.state)
        const cityOption = await getCity(response.city)

        setValue(`${name}.street`, response.street)
        setValue(`${name}.neighborhood`, response.neighborhood)
        setValue(`${name}.stateId`, stateOption)
        setValue(`${name}.cityId`, cityOption)

        setFocus(`${name}.number`)
      }
    } catch (error) {
      console.error(error)
      setError(`${name}.postalCode`, { message: 'CEP inválido' })
    }
  }, [watch, name, fetchCEP, getState, getCity, setValue, setFocus, setError])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Grid
      mb="4"
      gap="10"
      w={{ lg: 'container.md' }}
      templateColumns={{ lg: 'repeat(3, 1fr)' }}
    >
      <GridItem>
        <Controller
          name={`${name}.postalCode`}
          control={control}
          render={({ field: { ref, name, onChange, value } }) => (
            <FieldMask
              ref={ref}
              isRequired
              name={name}
              label="CEP"
              value={value || ''}
              mask="99999-999"
              onChange={onChange}
              onBlur={handleAddPostalCode}
              error={errors[name]?.postalCode}
            />
          )}
        />
      </GridItem>

      <GridItem colSpan={{ lg: 2 }}>
        <FieldText
          maxLength={120}
          currentLength={get(watch(`${name}.street`), 'length')}
          label="Logradouro"
          error={errors[name]?.street}
          isRequired
          {...register(`${name}.street`)}
        />
      </GridItem>

      <GridItem>
        <FieldText
          maxLength={8}
          currentLength={get(watch(`${name}.number`), 'length')}
          label="Número"
          error={errors[name]?.number}
          isRequired
          {...register(`${name}.number`)}
        />
      </GridItem>

      <GridItem>
        <FieldText
          maxLength={50}
          currentLength={get(watch(`${name}.complement`), 'length')}
          label="Complemento"
          error={errors[name]?.complement}
          {...register(`${name}.complement`)}
        />
      </GridItem>

      <GridItem>
        <FieldText
          maxLength={50}
          currentLength={get(watch(`${name}.referencePoint`), 'length')}
          label="Ponto de referência"
          error={errors[name]?.referencePoint}
          {...register(`${name}.referencePoint`)}
        />
      </GridItem>

      <GridItem>
        <FieldText
          maxLength={120}
          currentLength={get(watch(`${name}.neighborhood`), 'length')}
          label="Bairro"
          error={errors[name]?.neighborhood}
          isRequired
          {...register(`${name}.neighborhood`)}
        />
      </GridItem>

      <GridItem>
        <Controller
          name={`${name}.stateId`}
          control={control}
          render={({ field }) => (
            <FieldSelect
              label="UF"
              error={errors[name]?.stateId as FieldError}
              isLoading={false}
              options={selectStates.options}
              isRequired
              {...field}
            />
          )}
        />
      </GridItem>

      <GridItem>
        <Controller
          name={`${name}.cityId`}
          control={control}
          render={({ field }) => (
            <FieldSelect
              label="Cidade"
              error={errors[name]?.cityId as FieldError}
              isLoading={false}
              options={selectCities.options}
              isRequired
              {...field}
            />
          )}
        />
      </GridItem>
    </Grid>
  )
}
