// Vendors
import React from 'react'

// Components
import { CompanyLogisticsFormNew } from '../form/new'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

import { PageMetaSetting } from './meta'

export const PageCompanyLogisticNewRecord = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint, title } = PageMetaSetting

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={title}>
      <CompanyLogisticsFormNew endpoint={endpoint} />
    </LayoutLoggedPageList>
  )
}
