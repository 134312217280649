import { Grid, GridItem } from '@chakra-ui/react'
import { FieldNoImage } from 'components/FieldNoImage'
import { FieldSet } from 'components/FieldSet'
import { FieldText } from 'components/FieldText'
// import { TextEditor } from 'components/TextEditor'
import React, { Dispatch, SetStateAction } from 'react'
import { useFormContext } from 'react-hook-form'

const TextEditor = React.lazy(() => import('components/TextEditor'))
type TabMarketingProps = {
  setTextEditorData: Dispatch<SetStateAction<string>>
  setComplementaryImages: Dispatch<SetStateAction<File[]>>
}

export const TabMarketing = (props: TabMarketingProps) => {
  const { setTextEditorData, setComplementaryImages } = props

  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <FieldSet title="Descrição de conteúdo do kit">
      <Grid gap="10" my="4" templateColumns={{ lg: 'repeat(2, 1fr)' }}>
        <GridItem colSpan={{ base: 1, lg: 2 }}>
          <TextEditor
            setTextEditorData={setTextEditorData}
            label="Descrição para o conteúdo do kit:"
            helperText="Instruções de uso do kit."
          />
        </GridItem>

        <GridItem>
          <FieldText
            label="Link do vídeo de instrução de uso do kit"
            error={errors.kitVideoInstructions}
            helperText="Este vídeo aparece na home."
            isRequired
            {...register('kitVideoInstructions')}
          />
        </GridItem>

        <GridItem colSpan={{ lg: 2 }}>
          <FieldNoImage label="Imagem de destaque" />
        </GridItem>
      </Grid>
    </FieldSet>
  )
}
