import { UserRolesNames } from 'types/User'
import { ProductShowForm } from './types'

type RulesType = {
  name: keyof ProductShowForm
  allowedRoles: UserRolesNames[]
}

export const RULES: RulesType[] = [
  {
    name: 'priceB2c',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.SELLER,
      UserRolesNames.SUPERADMIN,
    ],
  },
  {
    name: 'priceB2b',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.SELLER,
      UserRolesNames.SUPERADMIN,
    ],
  },
  {
    name: 'isEligibleB2bDiscount',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.SELLER,
      UserRolesNames.SUPERADMIN,
    ],
  },
  {
    name: 'progressiveDiscount',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.SELLER,
      UserRolesNames.SUPERADMIN,
    ],
  },
  {
    name: 'isB2bAvailable',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.SELLER,
      UserRolesNames.SUPERADMIN,
    ],
  },
  {
    name: 'isB2cAvailable',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.SELLER,
      UserRolesNames.SUPERADMIN,
    ],
  },
  {
    name: 'isSellerAvailable',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.SELLER,
      UserRolesNames.SUPERADMIN,
    ],
  },
]
