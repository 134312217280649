// Vendors
import React, { ReactNode, useCallback } from 'react'

// Styles
import {
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialog as AlertDialogChakra,
  Button,
  ButtonProps,
} from '@chakra-ui/react'

// Interfaces
export type AlertDialogProps = {
  title: string
  isOpen: boolean
  children: ReactNode
  confirmProps?: ButtonProps
  confirmLabel?: string
  cancelLabel?: string
  onConfirm: () => Promise<void>
  onCancel: () => void
  cancelRef?: React.RefObject<HTMLButtonElement>
}

export const AlertDialog = (props: AlertDialogProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    title,
    isOpen,
    children,
    confirmLabel,
    confirmProps,
    cancelLabel,
    onConfirm,
    onCancel,
    cancelRef,
  } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isLoading, setIsLoading] = React.useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleConfirm = useCallback(() => {
    setIsLoading(true)
    onConfirm().finally(() => setIsLoading(false))
  }, [onConfirm])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <AlertDialogChakra
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          {children}
          {/* <AlertDialogBody>
            Tem certeza de que deseja deletar este item?{' '}
            <Text as="strong">Esta ação não pode ser desfeita.</Text>
          </AlertDialogBody> */}

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCancel}>
              {cancelLabel || 'Cancelar'}
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleConfirm}
              ml={3}
              className="btn-confirm"
              {...confirmProps}
              isLoading={isLoading}
            >
              {confirmLabel || 'Confirmar'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialogChakra>
  )
}
