import React, { useCallback, useEffect, useState } from 'react'
import { get, map } from 'lodash'

import { BsPatchCheck } from 'react-icons/bs'
import { useDisclosure } from '@chakra-ui/react'

import { ActionProps } from './types'
import { WrapperAction } from './WrapperAction'
import { ModalNewActiveKit } from 'components/ModalNewActiveKit'
import { apiShow } from 'services/get'
import { PurchaseHasProductHasAnalysisType } from 'pages/private/Laboratorial/show/tabs/TabReport'
import { LaboratorialAnalysis } from 'types/LaboratorialAnalysis'
import { v4 } from 'uuid'

type KitDetails = {
  purchaseHasProductHasAnalysis: PurchaseHasProductHasAnalysisType[]
}

export const NewActiveKitAction = <T extends unknown>({
  record,
  options,
}: ActionProps<T>) => {
  const [laboratoryAnalysis, setLaboratoryAnalysis] = useState<
    LaboratorialAnalysis[] | null
  >(null)

  const modalNewActiveKitActionDisclosure = useDisclosure()

  const activationCode = get(record, 'activationCode')
  const { callback, actionType = 'menu', reload, ...optionsRest } = options

  if (!reload) {
    throw new Error('reload is required')
  }

  const cta = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, data: T) => {
      e.stopPropagation()
      modalNewActiveKitActionDisclosure.onOpen()

      if (callback) {
        callback(data)
      }
    },
    [modalNewActiveKitActionDisclosure, callback]
  )

  useEffect(() => {
    if (!activationCode || !modalNewActiveKitActionDisclosure.isOpen) return

    apiShow<KitDetails>(`/app/kit/${activationCode}`).then((response) => {
      if (!response) return

      const purchaseHasProductHasAnalysis = get(
        response,
        'purchaseHasProductHasAnalysis'
      )

      if (!purchaseHasProductHasAnalysis) return

      const laboratoryAnalysis = map(
        purchaseHasProductHasAnalysis,
        'laboratoryAnalysis'
      ) as LaboratorialAnalysis[]

      const formattedLaboratoryAnalysis = map(
        laboratoryAnalysis,
        (analysis) => {
          const clinicalRecord = get(analysis, 'clinicalRecord')

          if (!clinicalRecord) return analysis

          const clinicalRecordForm = get(clinicalRecord, 'clinicalRecordForm')

          if (!clinicalRecordForm) return analysis

          const questions = get(clinicalRecordForm, 'questions')

          if (!questions) return analysis

          const formattedQuestions = map(questions, (question) => ({
            ...question,
            id: v4(),
          }))

          return {
            ...analysis,
            clinicalRecord: {
              ...clinicalRecord,
              clinicalRecordForm: {
                ...clinicalRecordForm,
                questions: formattedQuestions,
              },
            },
          }
        }
      )

      setLaboratoryAnalysis(formattedLaboratoryAnalysis)
    })
  }, [activationCode, modalNewActiveKitActionDisclosure.isOpen])

  return (
    <>
      <ModalNewActiveKit
        reload={reload}
        activationCode={activationCode}
        laboratoryAnalysis={laboratoryAnalysis}
        isOpen={modalNewActiveKitActionDisclosure.isOpen}
        onCancel={modalNewActiveKitActionDisclosure.onClose}
      />

      <WrapperAction
        cta={cta}
        record={record}
        icon={BsPatchCheck}
        options={optionsRest}
        actionType={actionType}
        label="Ativar kit"
        tooltip="Para ativar o kit, é necessário que o pedido não seja do tipo 'Legado'."
        tooltipProps={{
          shouldWrapChildren: true,
          isDisabled: !optionsRest.isDisabled,
        }}
      />
    </>
  )
}
