// Vendors
import React, { useCallback, useEffect } from 'react'
import { get } from 'lodash'
import { SubmitHandler, useForm } from 'react-hook-form'

// Components
import { FieldText } from 'components/FieldText'
import { FieldFile } from 'components/FieldFile'
import { DrawerListContent } from 'components/DrawerListContent'

// Styles
import {
  Button,
  ButtonGroup,
  DrawerBody,
  DrawerFooter,
  Stack,
  useToast,
} from '@chakra-ui/react'

// Functions
import { apiPost } from 'services/post'
import { apiPatch } from 'services/patch'
import { FILE_SIZE_1MB, FILE_SIZE_BYTES } from 'constants/constants'
import { useFeedback } from 'contexts/feedback'
import { useError } from 'hooks/useError'

// Interfaces
type MessageParams = {
  success: string
  error: string
}

export type DrawerFormImageProps = {
  title: string
  type: 'image' | 'document'
  messages: {
    [key: string]: {
      post: MessageParams
      patch: MessageParams
      delete: MessageParams
    }
  }
  isOpen: boolean
  onClose: () => void
  defaultValues?: any
  labelFieldFile?: string
  reloadRecord: () => void
  group: string
  endpoint: {
    patch?: string
    post: string
    delete?: string
    isMain?: boolean
  }
  hasTitle?: boolean
  hasDescription?: boolean
}

const IMAGE_FILE_SIZE = FILE_SIZE_1MB * 2
const IMAGE_FILE_SIZE_BYTES = (FILE_SIZE_1MB * 2) / 1000 / 1000

export const DrawerFormImage = (props: DrawerFormImageProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    group,
    title,
    type,
    messages,
    isOpen,
    onClose,
    endpoint,
    reloadRecord,
    defaultValues,
    labelFieldFile,
    hasTitle = true,
    hasDescription = true,
  } = props

  const {
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()

  const toast = useToast({ position: 'top-right' })
  const { feedback } = useFeedback()
  const { handleError } = useError()

  const imageWatch = watch('image')

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!defaultValues) return

    reset({
      title: defaultValues.title,
      description: defaultValues.description,
    })
  }, [defaultValues, reset])

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onCloseDrawer = useCallback(() => {
    reset({
      title: '',
      description: '',
    })
    onClose()
  }, [onClose, reset])

  const onSubmit: SubmitHandler<any> = useCallback(
    async (data) => {
      try {
        const formData = new FormData()

        formData.append('title', get(data, 'title'))
        formData.append('description', get(data, 'description'))
        formData.append('group', group)

        if (!defaultValues) {
          formData.append('file', get(data, 'image')[0])
          const response = await apiPost<FormData, { id: string }>(
            endpoint.post,
            formData
          )

          if (!response) {
            throw new Error()
          }

          if (endpoint.isMain) {
            await apiPatch(`${endpoint.patch}/${response.id}/main`)
          }

          toast({
            title: 'Sucesso',
            description: messages[type].post.success,
            status: 'success',
          })
        }

        if (defaultValues) {
          await apiPatch(`${endpoint.patch}/${defaultValues.id}`, formData)

          toast({
            title: 'Sucesso',
            description: messages[type].patch.success,
            status: 'success',
          })
        }

        reloadRecord()

        onClose()
      } catch (error) {
        handleError(error)
      }
    },
    [
      defaultValues,
      endpoint.isMain,
      endpoint.patch,
      endpoint.post,
      group,
      handleError,
      messages,
      onClose,
      reloadRecord,
      toast,
      type,
    ]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <DrawerListContent isOpen={isOpen} onClose={onCloseDrawer} title={title}>
      <DrawerBody>
        <Stack as="form">
          {hasTitle && (
            <FieldText
              label="Título"
              error={errors.title}
              {...register('title', { required: 'Título é obrigátorio' })}
            />
          )}

          {hasDescription && (
            <FieldText
              label="Descrição"
              error={errors.description}
              {...register('description', {
                required: 'Descrição é obrigátorio',
              })}
            />
          )}

          <FieldFile
            error={errors.image}
            buttonLabel="Selecionar"
            isDisabled={!!defaultValues}
            label={labelFieldFile || 'Imagem'}
            fileName={
              defaultValues?.originalFileName || get(imageWatch, '[0].name')
            }
            {...register('image', {
              required: 'Imagem é obrigátorio',
              validate: {
                lessThan1MB: (files) =>
                  files[0]?.size < IMAGE_FILE_SIZE ||
                  `Tamanho máximo ${IMAGE_FILE_SIZE_BYTES}MB`,
              },
            })}
          />
        </Stack>
      </DrawerBody>

      <DrawerFooter>
        <ButtonGroup>
          <Button onClick={onCloseDrawer}>Cancelar</Button>

          <Button
            colorScheme="blue"
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            Salvar
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </DrawerListContent>
  )
}
