import React, { useEffect, useState } from 'react'
import {
  SimpleGrid,
  Skeleton,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react'

import Layout from 'layout/Private'

import { arrayOfIntegers } from 'utils/array-of-integers'
import { useTranslation } from 'react-i18next'

import { GraphPie } from 'components/GraphPie'
import { CardStat } from 'components/CardStat'
import { GraphLines } from 'components/GraphLines'
import { GraphStacks } from 'components/GraphStacks'

const Dashboard: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const gridColumns = useBreakpointValue({ sm: 1, md: 1, lg: 2 })
  const cardGridColumns = useBreakpointValue({ sm: 1, md: 2, lg: 4 })
  const [isLoading, setIsLoading] = useState(true)

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('common')

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout title={t('pages_private.dashboard_title')}>
      {isLoading && (
        <SimpleGrid
          columns={gridColumns}
          spacing={4}
          spacingY={4}
          mt={4}
          mb={4}
        >
          {[
            Array.from({ length: 6 }, () => (
              <Stack>
                <Skeleton height="30px" />
                <Skeleton height="330px" />
              </Stack>
            )),
          ]}
        </SimpleGrid>
      )}

      {!isLoading && (
        <>
          <SimpleGrid
            columns={cardGridColumns}
            spacing={4}
            spacingY={4}
            mt={4}
            mb={4}
          >
            <CardStat
              unit="R$"
              label="Volume de vendas no mês"
              value={260000}
              series={[
                {
                  name: 'Volume financeiro',
                  data: arrayOfIntegers(12, 100),
                },
              ]}
            />
            <CardStat
              unit="#"
              label="Pedidos no mês"
              value={2430}
              series={[
                {
                  name: 'Quantidade',
                  data: arrayOfIntegers(12, 80),
                },
              ]}
            />
            <CardStat
              unit="#"
              label="Novos Clientes"
              value={1200}
              series={[
                {
                  name: 'Quantidade',
                  data: arrayOfIntegers(12, 80),
                },
              ]}
            />
            <CardStat
              unit="#"
              label="Clientes Recorrentes"
              value={256}
              series={[
                {
                  name: 'Quantidade',
                  data: arrayOfIntegers(12, 80),
                },
              ]}
            />
            <CardStat
              unit="#"
              label="Produtos ativos"
              value={23}
              series={[
                {
                  name: 'Volume financeiro',
                  data: arrayOfIntegers(12, 100),
                },
              ]}
            />
            <CardStat
              unit="#"
              label="Produtos inativos"
              value={4}
              series={[
                {
                  name: 'Quantidade',
                  data: arrayOfIntegers(12, 80),
                },
              ]}
            />
            <CardStat
              unit="#"
              label="Usuários ativos"
              value={78}
              series={[
                {
                  name: 'Quantidade',
                  data: arrayOfIntegers(12, 80),
                },
              ]}
            />
            <CardStat
              unit="#"
              label="Dias corridos com vendas"
              value={256}
              series={[
                {
                  name: 'Quantidade',
                  data: arrayOfIntegers(12, 80),
                },
              ]}
            />
          </SimpleGrid>
          <SimpleGrid
            columns={gridColumns}
            spacing={4}
            spacingY={4}
            mt={4}
            mb={4}
          >
            <GraphLines
              title="Histórico de pedidos"
              subtitle="Últimos 12 meses"
              series={[
                {
                  type: 'line',
                  name: 'Alta complexidade',
                  data: arrayOfIntegers(12),
                },
                {
                  type: 'line',
                  name: 'Baixa complexidade',
                  data: arrayOfIntegers(12),
                },
              ]}
            />
            <GraphLines
              title="Histórico de acessos"
              subtitle="Últimos 12 meses"
              series={[
                {
                  type: 'line',
                  name: 'Interno',
                  data: arrayOfIntegers(12),
                },
                {
                  type: 'line',
                  name: 'Externo',
                  data: arrayOfIntegers(12),
                },
              ]}
            />
            <GraphPie
              title="Tipo de entrega"
              subtitle="Correios vs Motoboy"
              series={[73, 27]}
            />
            <GraphPie
              title="Método de Pagamento"
              subtitle="Transações aprovadas"
              series={[70, 2, 28]}
              options={{
                labels: ['PIX', 'Boleto', 'Cartão de Crédito'],
              }}
            />
          </SimpleGrid>
          <GraphStacks
            title="Histórico de Aprovação de Pedidos"
            subtitle="Transações aprovadas vs reprovadas"
            series={[
              {
                name: 'Reprovadas',
                data: [44, 55, 10, 11, 25, 35, 70, 80, 40, 30, 18, 42],
              },
              {
                name: 'Aprovadas',
                data: [70, 80, 40, 30, 18, 44, 55, 10, 11, 25, 35, 47],
              },
            ]}
          />
        </>
      )}
    </Layout>
  )
}

export default Dashboard
