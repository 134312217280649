import { Query } from 'types/Requests'
import api from './api'

const handlePatchFailure = (error: unknown): void => {
  console.log(
    `Um erro ocorreu na atualização do item, por favor tente novamente.`,
    error
  )
}

export async function apiPatch<T>(
  endpoint: string,
  data?: unknown,
  query?: Query
): Promise<T | undefined> {
  let payload
  try {
    const { data: response } = await api.patch(endpoint, data, {
      params: { ...query },
    })
    payload = response
  } catch (error: unknown) {
    payload = undefined
    throw error
  }
  return payload
}
