// Vendors
import { get } from 'lodash'
import React, { useMemo } from 'react'

// Styles
import { Grid, GridItem } from '@chakra-ui/react'
import {
  FormQuestionType,
  LaboratorialAnalysis,
} from 'types/LaboratorialAnalysis'
import { FormFieldEditor } from '.'
import { v4 } from 'uuid'

// Types

type FormFieldEditorListProps = {
  laboratoryAnalysis: Partial<LaboratorialAnalysis>
}

export const FormFieldEditorList = (props: FormFieldEditorListProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { laboratoryAnalysis } = props

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const questions = useMemo(() => {
    return get(
      laboratoryAnalysis,
      'clinicalRecord.clinicalRecordForm.questions',
      []
    ) as FormQuestionType[]
  }, [laboratoryAnalysis])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Grid
      px="5"
      py="3"
      gap="4"
      w="full"
      templateColumns={{ base: 'repeat(1, 1fr)' }}
    >
      {questions.map((question) => {
        if (question.fieldType === 'title') return null

        return (
          <GridItem key={question.label}>
            <FormFieldEditor question={question} />
          </GridItem>
        )
      })}
    </Grid>
  )
}
