import * as dateFns from 'date-fns'
import { toDate } from 'date-fns-tz'

export function formatDate(rawDate: string | Date, format: string) {
  if (rawDate === null || rawDate === undefined) {
    return '-'
  }

  const date = toDate(rawDate)

  return dateFns.format(date, format)
}
