// Vendors
import React from 'react'

// Components
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

// Styles
import { Box, Text } from '@chakra-ui/react'

// Interfaces
export interface ISeries {
  type: string
  name: string
  data: number[]
}

export interface IGraphLines {
  title: string
  subtitle: string
  options?: ApexOptions
  series?: ISeries[]
}

const DEFAULT_OPTIONS: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  stroke: {
    curve: 'smooth',
  },
  markers: {
    size: 0,
    shape: 'circle',
  },
  xaxis: {
    categories: [
      'JAN',
      'FEV',
      'MAR',
      'ABR',
      'MAI',
      'JUN',
      'JUL',
      'AGO',
      'SET',
      'OUT',
      'NOV',
      'DEZ',
    ],
    labels: {
      show: true,
      style: {
        fontSize: '10',
      },
    },
  },
  yaxis: {
    title: {
      text: undefined,
    },
  },
  fill: {
    opacity: 1,
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center',
  },
}

export const GraphLines: React.FC<IGraphLines> = (props) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { title, subtitle, options, series } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Box
      bg={'white'}
      shadow="base"
      rounded="lg"
      px={4}
      py={6}
      position="relative"
    >
      <Text as={'h2'} fontSize="lg" fontWeight="bold" color={'blue.500'}>
        {title}
      </Text>
      <Text as={'p'} fontSize="md" color={'gray.500'} pt={2}>
        {subtitle}
      </Text>
      <ReactApexChart
        series={series}
        type="line"
        height={300}
        options={{ ...DEFAULT_OPTIONS, ...options }}
      />
    </Box>
  )
}
