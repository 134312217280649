// Vendors
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

// Hooks
import { useEditRecords } from 'hooks/useEditRecord'

// Functions

// Components
import { FieldText } from 'components/FieldText'
import { FormFooter } from 'components/FormFooter'

// Schemas
import { schema } from './schema'

// Styles
import { Flex, Grid, GridItem, useToast } from '@chakra-ui/react'

// Types
import { RecollectShowAPI, RecollectShowForm } from './types'
import { useFeedback } from 'contexts/feedback'

type RecollectFormShowProps = {
  id: string
  endpoint: string
}

export const RecollectFormShow = (
  props: RecollectFormShowProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id, endpoint } = props

  const { t } = useTranslation('recollect')

  const {
    reset,
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<RecollectShowForm>({
    resolver: yupResolver(schema(t)),
  })
  const toast = useToast({ position: 'top-right' })
  const { feedback } = useFeedback()

  const { record } = useEditRecords<RecollectShowAPI, RecollectShowForm>({
    endpoint,
    currentId: id,
  })
  const recordCurrent = get(record, 'current')

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sanitize = useCallback((data: RecollectShowAPI) => {
    if (Object.keys(data).length === 0) return {}

    return {
      name: get(data, 'name'),
      email: get(data, 'email'),
      gender: get(data, 'gender'),
      birthdate: get(data, 'birthdate'),
      socialName: get(data, 'socialName'),
      phoneNumber: get(data, 'phoneNumber')?.slice(3),
      documentNumber: get(data, 'documentNumber'),
    }
  }, [])

  useEffect(() => {
    reset(sanitize(recordCurrent))
  }, [recordCurrent, reset, sanitize])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<RecollectShowForm> = async (data) => {
    try {
      await record.update(data)

      toast({
        title: t('toast.success.title'),
        description: t('toast.success.update'),
        status: 'success',
      })
    } catch (error: any) {
      if (error && error instanceof Array) {
        error.forEach((fieldError) => {
          setError(fieldError.field, { message: fieldError.message })
        })
      }

      const errorMessage =
        error?.response?.data.message ||
        error?.message ||
        t('toast.error.default')

      feedback({
        title: 'Atenção',
        description: errorMessage,
        status: 'error',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      as="form"
      h="full"
      position="relative"
      flexDirection="column"
      justifyContent="space-between"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid
        my="4"
        gap="10"
        templateColumns={{ lg: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
      >
        <GridItem colSpan={2}>
          <FieldText
            label={t('form.name.label')}
            error={errors.name}
            {...register('name')}
          />
        </GridItem>
      </Grid>

      <FormFooter isSubmitting={isSubmitting} />
    </Flex>
  )
}
