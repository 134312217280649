// Vendors
import React from 'react'
import { useLocation } from 'react-router'

// Functions
import { usePageMeta } from 'hooks/usePageMeta'

// Styles
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbProps,
} from '@chakra-ui/react'
import { HiChevronRight } from 'react-icons/hi'

export const Breadcrumbs = (props: BreadcrumbProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const location = useLocation()
  const { title, breadcrumbs } = usePageMeta(location)

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Breadcrumb
      fontSize="sm"
      {...props}
      separator={
        <Box
          as={HiChevronRight}
          color="gray.400"
          fontSize="md"
          top="2px"
          pos="relative"
        />
      }
    >
      {title !== 'Dashboard' && (
        <BreadcrumbItem color="inherit">
          <BreadcrumbLink
            href="/dashboard"
            color={'blue.500'}
            fontWeight="bold"
            transition="all 150ms ease-in-out"
            _hover={{ textDecoration: 'none', color: 'blue.600' }}
          >
            Dashboard
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      {breadcrumbs.map((item, index) => {
        return (
          <BreadcrumbItem key={`breadcrumb-${index}`} color="inherit">
            <BreadcrumbLink
              href={item.location}
              color={'blue.500'}
              fontWeight="bold"
              transition="all 150ms ease-in-out"
              _hover={{ textDecoration: 'none', color: 'blue.600' }}
            >
              {item.label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )
      })}
      <BreadcrumbItem color="inherit" isCurrentPage>
        <BreadcrumbLink
          isCurrentPage
          color={'gray.500'}
          fontWeight="bold"
          transition="all 150ms ease-in-out"
          cursor="default"
          _hover={{ textDecoration: 'none' }}
        >
          {title}
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  )
}
