// Vendors
import React from 'react'

// Styles
import { HStack, Text } from '@chakra-ui/react'

// Interfaces
export type CardDetailsValueProps = {
  label: string
  value?: string
}

export const CardDetailsValue = (props: CardDetailsValueProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { label, value = '-' } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <HStack>
      <Text w={'8rem'}>{label}</Text>
      <Text as="strong">{value}</Text>
    </HStack>
  )
}
