import * as React from 'react'
import { Breadcrumbs } from 'components/Breadcrumbs'
import {
  HStack,
  Box,
  Button,
  useColorModeValue as mode,
  IconButton,
} from '@chakra-ui/react'
import { FiArrowLeft } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

export const NavSecondary: React.FC = () => {
  const history = useHistory()

  return (
    <Box
      as="header"
      bg={mode('white', 'gray.800')}
      position="relative"
      zIndex="10"
      p="6"
      borderBottom={'1px'}
      borderColor={'gray.100'}
    >
      <HStack>
        <IconButton
          aria-label="back"
          icon={<FiArrowLeft />}
          colorScheme="blue"
          fontSize="xl"
          border="0"
          variant="ghost"
          onClick={() => {
            history.goBack()
          }}
        />
        <Box as="nav" aria-label="Main navigation" mx="auto">
          <Breadcrumbs />
        </Box>
      </HStack>
    </Box>
  )
}
