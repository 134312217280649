// Vendors
import { get } from 'lodash'
import React, { ChangeEvent, useCallback, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

// Components
import { FieldText } from 'components/FieldText'
import { ModalBarCodeScanner } from 'components/ModalBarCodeScanner'

// Styles
import {
  Button,
  Modal,
  Stack,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  useDisclosure,
  Heading,
  Box,
  StackDivider,
} from '@chakra-ui/react'
import { ImBarcode } from 'react-icons/im'
import { useGlobalConfig } from 'contexts/globalConfig'

type ModalAssingIdProps = {
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
}

export const ModalAssingId = (props: ModalAssingIdProps) => {
  const { isOpen, onCancel, onConfirm } = props

  const [position, setPosition] = useState(-1)
  const disclosureBarCodeScanner = useDisclosure()

  const { formatActivationCode, validateActivationCode } = useGlobalConfig()

  const {
    reset,
    control,
    setValue,
    formState: { errors, isDirty, isSubmitting },
    getValues,
  } = useFormContext<{
    activationCodes: Array<{
      storeProduct: { mktTitle: string }
      activationCode: string
    }>
  }>()

  const { fields } = useFieldArray({
    control,
    name: 'activationCodes',
  })

  const onClose = () => {
    reset()
    onCancel()
  }

  const handleScan = (activationCode: string) => {
    if (position === -1) return

    setValue(`activationCodes.${position}.activationCode`, activationCode)

    disclosureBarCodeScanner.onClose()
  }

  const validate = useCallback(() => {
    return {
      isValidCode: (value: string) => {
        const isValid = validateActivationCode(value)
        if (!isValid) {
          return 'Código de ativação inválido'
        }
      },
      repeatedCodes: (value: string) => {
        const hasRepeatedCode = getValues('activationCodes').filter(
          (activationCode) => activationCode.activationCode === value
        )

        if (hasRepeatedCode.length > 1) {
          return 'Código de ativação repetido'
        }
      },
    }
  }, [getValues, validateActivationCode])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBarCodeScanner
        onScan={(data) => handleScan(data)}
        isOpen={disclosureBarCodeScanner.isOpen}
        onClose={disclosureBarCodeScanner.onClose}
      />

      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Atribuir códigos de ativação</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Stack spacing="6" divider={<StackDivider />}>
            {fields.map((field, index) => (
              <Box key={field.id}>
                <Heading fontSize="md">{field?.storeProduct?.mktTitle}</Heading>

                <Stack alignItems="flex-end" direction={{ lg: 'row' }}>
                  <Controller
                    control={control}
                    name={`activationCodes.${index}.activationCode`}
                    rules={{
                      required: 'Código de ativação é obrigatório',
                      validate: validate(),
                    }}
                    render={({ field }) => (
                      <FieldText
                        label="Novo ID"
                        error={get(
                          errors,
                          `activationCodes.${index}.activationCode`
                        )}
                        {...field}
                        onChange={(e) => {
                          field.onChange(formatActivationCode(e.target.value))
                        }}
                      />
                    )}
                  />

                  <Button
                    px="8"
                    colorScheme="blue"
                    rightIcon={<ImBarcode />}
                    onClick={() => {
                      setPosition(index)
                      disclosureBarCodeScanner.onOpen()
                    }}
                  >
                    Escanear
                  </Button>
                </Stack>
              </Box>
            ))}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose}>Cancelar</Button>
            <Button
              colorScheme="blue"
              isDisabled={!isDirty}
              isLoading={isSubmitting}
              onClick={onConfirm}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
