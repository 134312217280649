import { FILE_SIZE } from 'constants/constants'
import { yupSchema } from 'utils/yupSchema'
import * as yup from 'yup'
import { ClinicEnum, CouncilEnum } from '../show/types'

export const schema = (t: any) => {
  yupSchema(t)

  return yup.object().shape({
    name: yup.string().required().label(t('form.name.label')),

    email: yup.string().email().required().label(t('form.email.label')),

    phoneNumber: yup
      .string()
      .transform((value) => `+55${value.replace(/\D/g, '')}`)
      .required()
      .label(t('form.phoneNumber.label')),

    documentNumber: yup
      .string()
      .getCharacters()
      .required()
      .length(11)
      .label(t('form.documentNumber.label')),

    birthdate: yup.date().required().label(t('form.birthdate.label')),

    gender: yup.string().required().selectValue().label(t('form.gender.label')),

    // Professional Data
    'council-type': yup.string().required().label(t('form.council-type.label')),

    'council-value': yup
      .string()
      .required()
      .label(t('form.council-value.label')),

    'council-state-value': yup
      .string()
      .required()
      .selectValue()
      .label(t('form.council-state-value.label')),

    sellerUserId: yup
      .string()
      .required()
      .selectValue()
      .label(t('form.sellerUserId.label')),

    [CouncilEnum.councilFileFront]: yup
      .mixed()
      .maxFileSize(FILE_SIZE)
      .fileIsRequired(t('form.council-file-front.label')),

    [CouncilEnum.councilFileBack]: yup
      .mixed()
      .maxFileSize(FILE_SIZE)
      .fileIsRequired(t('form.council-file-back.label')),

    [CouncilEnum.councilFileSelfie]: yup
      .mixed()
      .maxFileSize(FILE_SIZE)
      .fileIsRequired(t('form.council-file-selfie.label')),

    // Clinic
    [ClinicEnum.recordClinicId]: yup
      .array()
      .selectValues()
      .label(t('form.clinic.label')),
  })
}
