// Vendors
import React, { useMemo } from 'react'
import { FieldError } from 'react-hook-form'

// Styles
import {
  Switch,
  FormLabel,
  StyleProps,
  FormControl,
  SwitchProps,
  FormErrorMessage,
  Flex,
} from '@chakra-ui/react'
import { useFormHelperText } from 'hooks/useFormHelperText'
import { Tooltip, TooltipProps } from 'components/Tooltip'

// Interfaces
enum LabelDirectionEnum {
  top = 'top',
  left = 'left',
  right = 'right',
  'top-right' = 'top-right',
}

type LabelDirectionStyle = Record<LabelDirectionEnum, StyleProps>

export interface IFieldToggle extends SwitchProps {
  name: string
  label?: string
  error?: FieldError
  labelDirection?: keyof typeof LabelDirectionEnum
  canModify?: boolean
  tooltipProps?: TooltipProps
  helperText?:
    | string
    | {
        text: string | React.ReactNode
        color?: string
      }
}

const FieldToggleBase: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IFieldToggle
> = (props, ref) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    name,
    label,
    error,
    labelDirection = 'top',
    canModify = true,
    tooltipProps,
    helperText,
    ...rest
  } = props
  const componentHelperText = useFormHelperText({ helperText, canModify })

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const labelDirections: LabelDirectionStyle = useMemo(
    () => ({
      top: {},
      'top-right': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        sx: {
          label: {
            marginInlineEnd: '0',
          },
        },
      },
      left: {
        display: 'flex',
        alignItems: 'end',
      },
      right: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'flex-end',
        flexDirection: 'row-reverse',
      },
    }),
    []
  )

  const labelDirectionStyle = useMemo(
    () => labelDirections[labelDirection],
    [labelDirections, labelDirection]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormControl {...labelDirectionStyle}>
      <Flex alignItems="center">
        {!!label && (
          <FormLabel
            htmlFor={name}
            mb="0"
            ml={labelDirection === 'top' ? '0' : '3'}
          >
            {label}
          </FormLabel>
        )}

        {tooltipProps && Object.keys(tooltipProps).length && (
          <Tooltip {...tooltipProps} />
        )}
      </Flex>

      <Switch id={name} name={name} size="lg" pt={2} ref={ref} {...rest} />

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      {(helperText || !canModify) && !error && componentHelperText}
    </FormControl>
  )
}

export const FieldToggle = React.forwardRef(FieldToggleBase)
