// Vendors
import { useHistory, Link } from 'react-router-dom'
import React, { useMemo, useCallback, useEffect } from 'react'

// Functions
import { useTranslation } from 'react-i18next'
import { useListRecords } from 'hooks/useListRecords'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListActions } from 'components/ListActions'
import { ListPagination } from 'components/ListPagination'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles
import { FiPlus, FiStar } from 'react-icons/fi'
import { Stack, Button, Select } from '@chakra-ui/react'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Types
import { Blog } from 'types/Blog'

import { PageMetaSetting } from './meta'
import { format } from 'date-fns'

export const PageBlogListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const history = useHistory()
  const { t } = useTranslation('common')
  const { endpoint, title } = PageMetaSetting
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<Blog>({ endpoint })

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    Records.list.forEach((record) => {
      record.publishingDate = format(
        new Date(record.publishingDate),
        'dd/MM/yyyy'
      )
      record.formattedCategory = record.category.title
      record.formattedTags = record.tags.map((tag) => tag.name).join(', ')
    })
  }, [Records.list])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const listTableHeaders = useMemo(
    () => [
      {
        label: 'Favorito',
        accessor: 'featured',
        render: { as: ListTableRenderAs.ICON, options: { icon: FiStar } },
      },
      {
        label: 'Titulo',
        accessor: 'title',
        render: { as: ListTableRenderAs.MULTILINE_TEXT },
      },
      {
        label: 'Categorias',
        accessor: 'formattedCategory',
      },
      {
        label: 'Tags',
        accessor: 'formattedTags',
      },
      {
        label: 'Data de publicação',
        accessor: 'publishingDate',
      },
      {
        label: 'Status',
        accessor: 'status',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const listTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/blog/${Record.id}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutLoggedPageList title={title}>
      <Stack
        spacing="4"
        justify="space-between"
        direction={{ base: 'column', md: 'row' }}
      >
        <ListFilters query={Query}>
          <Select
            size="sm"
            placeholder="Status..."
            onChange={(e) => {
              Query.set({
                ...Query.current,
                status: e.target.value,
              })
            }}
          >
            <option value="DRAFT">Rascunho</option>
            <option value="REVISION">Revisão</option>
            <option value="PUBLISHED">Publicado</option>
            <option value="TRASH">Lixeira</option>
          </Select>
        </ListFilters>

        <ListActions>
          <Button as={Link} to={'/blog/new'} leftIcon={<FiPlus />}>
            Adicionar
          </Button>
        </ListActions>
      </Stack>

      <ListTable
        headers={listTableHeaders}
        records={Records}
        defaultActions="edit"
        rowAction={listTableRowAction}
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
