// Vendors
import React from 'react'

// Styles
import { IconType } from 'react-icons'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'

// Interfaces
export type CardStatSimpleProps = {
  label: string
  value?: string
  icon: IconType
  customValue?: React.ReactNode
}

export const CardStatSimple = (props: CardStatSimpleProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { label, value, icon: Icon, customValue } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      w="full"
      p="6"
      bg="blackAlpha.50"
      borderRadius="2xl"
      justifyContent="space-between"
    >
      <Box>
        {customValue && <Box h="3.5rem">{customValue}</Box>}

        <Heading color="blue.500" fontWeight="extrabold" fontSize="5xl" mb="7">
          {value}
        </Heading>

        <Text color="gray.900" fontSize="md">
          {label}
        </Text>
      </Box>

      <Icon size={48} color="var(--chakra-colors-gray-300)" />
    </Flex>
  )
}
