import React from 'react'
import { Link } from 'react-router-dom'

import { SearchModule } from 'contexts/search/types'
import { GlobalSearchBlank } from './GlobalSearchBlank'
import { GlobalSearchSkeleton } from './GlobalSearchSkeleton'

import {
  Box,
  Text,
  Stack,
  HStack,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Flex,
} from '@chakra-ui/react'
import { ListTableRowComponents } from 'components/ListTableRowComponent'

type GlobalSearchItemProps = {
  searchModule: SearchModule
}

export const GlobalSearchItem = ({ searchModule }: GlobalSearchItemProps) => {
  const Icon = searchModule?.icon

  return (
    <AccordionItem borderColor="gray.600" py="2">
      <AccordionButton>
        <Flex
          flex="1"
          alignItems="center"
          textAlign="left"
          fontWeight="bold"
          fontSize="sm"
          color="gray.400"
        >
          {Icon && <Icon />}
          <Text ml="2">{searchModule.name.toUpperCase()}</Text>
        </Flex>

        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel px="2">
        {searchModule.isLoading && <GlobalSearchSkeleton />}

        {!searchModule.isLoading && !searchModule.data?.length && (
          <GlobalSearchBlank />
        )}

        {searchModule.data && (
          <Stack spacing="0">
            {searchModule.data.map((item) => (
              <Link
                key={item.id}
                to={`${searchModule.endpointFront}/${item.href || item.id}`}
              >
                <HStack rounded="4" p="2" _hover={{ bg: 'gray.600' }}>
                  <Text isTruncated>{item.name}</Text>
                  {item.status && (
                    <ListTableRowComponents.BADGE fontSize="10px" height="4">
                      {item.status.toUpperCase()}
                    </ListTableRowComponents.BADGE>
                  )}
                </HStack>
              </Link>
            ))}
          </Stack>
        )}
      </AccordionPanel>
    </AccordionItem>
  )
}
