// Vendors
import { v4 } from 'uuid'
import React, {
  MouseEvent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

// Components

// Styles
import {
  Td,
  Text,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  TableCellProps,
  ButtonProps,
  IconButton,
  ButtonGroup,
  IconButtonProps,
  MenuItemProps,
  TooltipProps,
} from '@chakra-ui/react'
import {
  FiEdit,
  FiMinusCircle,
  FiPrinter,
  FiTruck,
  FiExternalLink,
  FiCheckCircle,
  FiRepeat,
  FiShoppingBag,
  FiMoreVertical,
} from 'react-icons/fi'
import { FaMoneyCheckAlt } from 'react-icons/fa'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useActions } from './useActions'

// Interfaces
type NotDisableWhenType<T> =
  | Record<keyof T, string>
  | ((record: T) => boolean)
  | undefined
type ShowWhenType<T> =
  | Partial<Record<keyof T, T[keyof T]>>
  | ((record: T) => boolean)
  | undefined

enum TypeEnum {
  'print',
  'edit',
  'delete',
  'delivery',
  'report',
  'medicalRecord',
  'link',
  'delivered',
  'inTransit',
  'activateCode',
  'pagarme',
  'order',
  'assignId',
  'legacyActiveKit',
  'alterKitStatus',
  'authorizeShipment',
  'alterCobrandingStatus',
  'activeKit',
  'alterShippingMethod',
  'generateNewPaymentLink',
  'alterPurchaseStatus',
  /* Plop actions enum here */
  'resetActivationCode',
  'requestCollection',
  'alterActivationCode',
  'resetAccountPassword',
  'cancelOrder',
  'cancelIssuedInvoice',
  'confirmIssuanceNote',
  'newActiveKit',
  'recollectProcessing',
}

type ActionMap = {
  [key in keyof typeof TypeEnum]: (options: any) => JSX.Element
}

export type ActionType<T> = {
  type: keyof typeof TypeEnum
  options?: (IconButtonProps | MenuItemProps) & {
    tooltipProps?: Omit<TooltipProps, 'children'>
    callback?: ((record: T) => void) | ((e: any, record: T) => void)
    notDisableWhen?: NotDisableWhenType<T>
    showWhen?: ShowWhenType<T>
    reload?: () => void
    isWorkspace?: boolean
  }
}

export type ListTableActionProps<T> = {
  children?: ReactNode
  record: T
  actions?: ActionType<T>[]
  tableActionProps?: TableCellProps | ((record: T) => TableCellProps)
  menuButtonProps?: ButtonProps
}

const OUTSIDE_MENU = [
  'edit',
  'delete',
  'delivered',
  'delivery',
  'assignId',
  'recollectProcessing',
]

export const ListTableAction = <T extends any>(
  props: ListTableActionProps<T>
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { children, record, actions, tableActionProps, menuButtonProps } = props

  const [isLoading, setIsLoading] = useState(false)

  const {
    ActiveKitAction,
    AlterKitStatusAction,
    LegacyActiveKitAction,
    AuthorizeShipmentAction,
    AlterShippingMethodAction,
    AlterPurchaseStatusAction,
    AlterCobrandingStatusAction,
    GenerateNewPaymentLinkAction,
    /* Plop actions hook here */
    ResetActivationCodeAction,
    RequestCollectionAction,
    AlterActivationCodeAction,
    ResetAccountPasswordAction,
    CancelOrderAction,
    CancelIssuedInvoiceAction,
    ConfirmIssuanceNoteAction,
    NewActiveKitAction,
    RecollectProcessingAction,
  } = useActions(record)

  const history = useHistory()
  const DEFAULT_STYLE_ICON = useMemo(
    () => ({
      size: 14,
    }),
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tableActionPropsMemo = useMemo(() => {
    if (typeof tableActionProps === 'function') {
      return tableActionProps(record)
    }

    return tableActionProps
  }, [record, tableActionProps])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const EditAction = useCallback(
    (options: any) => {
      const { callback, ...rest } = options

      return (
        <IconButton
          size="sm"
          variant={'ghost'}
          icon={<FiEdit {...DEFAULT_STYLE_ICON} size="18" />}
          aria-label="Edit item"
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            callback(record)
          }}
          {...rest}
        >
          Editar
        </IconButton>
      )
    },
    [DEFAULT_STYLE_ICON, record]
  )

  const DeleteAction = useCallback(
    (options: any) => {
      const { callback, tooltipProps, ...rest } = options

      return (
        <Tooltip {...tooltipProps}>
          <IconButton
            size="sm"
            variant={'ghost'}
            icon={<FiMinusCircle {...DEFAULT_STYLE_ICON} size="18" />}
            color="red.500"
            aria-label="Delete item"
            onClick={async (e: MouseEvent<HTMLButtonElement>) => {
              setIsLoading(true)
              await callback(e, record)
              setIsLoading(false)
            }}
            isLoading={isLoading}
            {...rest}
          >
            Deletar
          </IconButton>
        </Tooltip>
      )
    },
    [DEFAULT_STYLE_ICON, record, isLoading]
  )

  const PrintAction = useCallback(
    (options: any) => {
      const { callback, ...rest } = options
      return (
        <MenuItem
          icon={<FiPrinter {...DEFAULT_STYLE_ICON} />}
          aria-label="Print item"
          onClick={(e: MouseEvent<HTMLButtonElement>) => callback(e, record)}
          {...rest}
        >
          Imprimir etiqueta
        </MenuItem>
      )
    },
    [DEFAULT_STYLE_ICON, record]
  )

  const DeliveryAction = useCallback(
    (options: any) => {
      const { callback, ...rest } = options

      return (
        <Tooltip label="Confirmar envio">
          <IconButton
            size="sm"
            variant="ghost"
            icon={<FiTruck {...DEFAULT_STYLE_ICON} />}
            aria-label="Delivery item"
            onClick={(e: MouseEvent<HTMLButtonElement>) => callback(e, record)}
            {...rest}
          >
            Enviado
          </IconButton>
        </Tooltip>
      )
    },
    [DEFAULT_STYLE_ICON, record]
  )

  const InTransitAction = useCallback(
    (options: any) => {
      const { callback, ...rest } = options
      return (
        <MenuItem
          icon={<FiTruck {...DEFAULT_STYLE_ICON} />}
          aria-label="Delivery item"
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            callback(e, record)
          }}
          {...rest}
        >
          Em transito
        </MenuItem>
      )
    },
    [DEFAULT_STYLE_ICON, record]
  )

  const DeliveredAction = useCallback(
    (options: any) => {
      const { callback, ...rest } = options
      return (
        <Tooltip label="Marcar como entregue">
          <IconButton
            size="sm"
            variant="ghost"
            icon={<FiCheckCircle {...DEFAULT_STYLE_ICON} />}
            aria-label="Delivered item"
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              callback(e, record)
            }}
            {...rest}
          >
            Entregue
          </IconButton>
        </Tooltip>
      )
    },
    [DEFAULT_STYLE_ICON, record]
  )

  const ReportAction = useCallback(
    (options: any) => {
      const { callback, ...rest } = options
      return (
        <Tooltip
          shouldWrapChildren
          placement="left"
          label="Sem permissão para acessar o relatório"
          isDisabled={!rest.isDisabled}
        >
          <MenuItem
            fontWeight={600}
            colorScheme="blue"
            onClick={(e: MouseEvent<HTMLButtonElement>) => callback(e, record)}
            {...rest}
          >
            Download do Laudo
          </MenuItem>
        </Tooltip>
      )
    },
    [record]
  )

  const MedicalRecordAction = useCallback(
    (options: any) => {
      const { callback, ...rest } = options
      return (
        <MenuItem
          colorScheme="blue"
          fontWeight="semibold"
          onClick={(e: MouseEvent<HTMLButtonElement>) => callback(e, record)}
          {...rest}
        >
          Ficha clínica
        </MenuItem>
      )
    },
    [record]
  )

  const ActivateCodeAction = useCallback(
    (options: any) => {
      const { callback, ...rest } = options
      return (
        <Tooltip
          shouldWrapChildren
          placement="left"
          label={
            <Text>
              Esse ID so pode ser alterado caso o status esteja{' '}
              <Text as="strong">Aguardando Ativação</Text>
            </Text>
          }
        >
          <MenuItem
            icon={<FiRepeat {...DEFAULT_STYLE_ICON} />}
            size="sm"
            aria-label="change activate code"
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              callback(e, record)
            }}
            {...rest}
          >
            Trocar ID do kit
          </MenuItem>
        </Tooltip>
      )
    },
    [DEFAULT_STYLE_ICON, record]
  )

  const PagarmeAction = useCallback(
    (options: any) => {
      const { callback, ...rest } = options
      return (
        <MenuItem
          icon={<FaMoneyCheckAlt {...DEFAULT_STYLE_ICON} />}
          size="sm"
          aria-label="pagarme"
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            callback(e, record)
          }}
          {...rest}
        >
          Visualizar dados da transação
        </MenuItem>
      )
    },
    [DEFAULT_STYLE_ICON, record]
  )

  const LinkAction = useCallback(
    (options: any) => {
      const { callback, ...rest } = options
      return (
        <MenuItem
          aria-label="Print item"
          icon={<FiExternalLink size={20} />}
          onClick={(e: MouseEvent<HTMLButtonElement>) => callback(e, record)}
          {...rest}
        >
          Link
        </MenuItem>
      )
    },
    [record]
  )

  const OrderAction = useCallback(
    (options: any) => {
      const { callback, ...rest } = options
      return (
        <MenuItem
          aria-label="new order"
          icon={<FiShoppingBag size={20} />}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()

            if (callback) {
              callback(e, record)
              return
            }

            history.push(`/checkout?personId=${(record as any)?.id}`)
          }}
          {...rest}
        >
          Novo pedido
        </MenuItem>
      )
    },
    [history, record]
  )

  const AssignIdAction = useCallback(
    (options: any) => {
      const { callback, ...rest } = options
      return (
        <Tooltip label="Atribuir IDs">
          <IconButton
            size="sm"
            variant="ghost"
            aria-label="open modal to assign id"
            icon={<FiRepeat {...DEFAULT_STYLE_ICON} />}
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()

              if (callback) {
                callback(e, record)
              }
            }}
            {...rest}
          />
        </Tooltip>
      )
    },
    [DEFAULT_STYLE_ICON, record]
  )

  const fnShowWhen = useCallback(
    (optShowWhen: ShowWhenType<T>) => {
      if (!optShowWhen) return true

      if (typeof optShowWhen === 'function') {
        return optShowWhen(record)
      }

      const show = Object.entries(optShowWhen).map(([key, value]) => {
        if (typeof value === 'function') {
          return value(record)
        }

        const recordValue = get(record, key)

        if (recordValue && recordValue === value) {
          return true
        }

        return false
      })

      return (
        show.length === Object.keys(optShowWhen).length && show.includes(true)
      )
    },
    [record]
  )

  const fnNotDisableWhen = useCallback(
    (optNotDisableWhen: NotDisableWhenType<T>) => {
      if (!optNotDisableWhen) return false

      if (typeof optNotDisableWhen === 'function') {
        return optNotDisableWhen(record)
      }
      const show = Object.entries(optNotDisableWhen).map(([key, value]) => {
        const recordValue = get(record, key)

        if (recordValue && recordValue === value) {
          return false
        }

        return true
      })

      return (
        show.length === Object.keys(optNotDisableWhen).length &&
        show.includes(true)
      )
    },
    [record]
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const actionsMap: ActionMap = useMemo(
    () => ({
      edit: EditAction,
      link: LinkAction,
      print: PrintAction,
      delete: DeleteAction,
      report: ReportAction,
      delivery: DeliveryAction,
      delivered: DeliveredAction,
      inTransit: InTransitAction,
      medicalRecord: MedicalRecordAction,
      activateCode: ActivateCodeAction,
      pagarme: PagarmeAction,
      order: OrderAction,
      assignId: AssignIdAction,
      legacyActiveKit: LegacyActiveKitAction,
      alterKitStatus: AlterKitStatusAction,
      authorizeShipment: AuthorizeShipmentAction,
      alterCobrandingStatus: AlterCobrandingStatusAction,
      activeKit: ActiveKitAction,
      alterShippingMethod: AlterShippingMethodAction,
      generateNewPaymentLink: GenerateNewPaymentLinkAction,
      alterPurchaseStatus: AlterPurchaseStatusAction,
      /* Plop actions map here */
      resetActivationCode: ResetActivationCodeAction,
      requestCollection: RequestCollectionAction,
      alterActivationCode: AlterActivationCodeAction,
      resetAccountPassword: ResetAccountPasswordAction,
      cancelOrder: CancelOrderAction,
      cancelIssuedInvoice: CancelIssuedInvoiceAction,
      confirmIssuanceNote: ConfirmIssuanceNoteAction,
      newActiveKit: NewActiveKitAction,
      recollectProcessing: RecollectProcessingAction,
    }),
    [
      EditAction,
      LinkAction,
      PrintAction,
      DeleteAction,
      ReportAction,
      DeliveryAction,
      DeliveredAction,
      InTransitAction,
      MedicalRecordAction,
      ActivateCodeAction,
      PagarmeAction,
      OrderAction,
      AssignIdAction,
      LegacyActiveKitAction,
      AlterKitStatusAction,
      AuthorizeShipmentAction,
      AlterCobrandingStatusAction,
      ActiveKitAction,
      AlterShippingMethodAction,
      GenerateNewPaymentLinkAction,
      AlterPurchaseStatusAction,
      /* Plop actions deps here */
      ResetActivationCodeAction,
      RequestCollectionAction,
      AlterActivationCodeAction,
      ResetAccountPasswordAction,
      CancelOrderAction,
      CancelIssuedInvoiceAction,
      ConfirmIssuanceNoteAction,
      NewActiveKitAction,
      RecollectProcessingAction,
    ]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  const renderActionsDefault = useCallback(
    (actions: ActionType<T>[]) => {
      const actionsFormatted: (JSX.Element | null)[] = actions.map((action) => {
        const { type, options } = action
        let restOptions

        if (options) {
          const { notDisableWhen, showWhen, ...rest } = options
          restOptions = rest
          const disabledAction = fnNotDisableWhen(notDisableWhen)
          const showAction = fnShowWhen(showWhen)

          if (!showAction) {
            return null
          }

          if (disabledAction) {
            restOptions.isDisabled = disabledAction
          }
        }

        if (OUTSIDE_MENU.includes(type)) {
          const Component = actionsMap[type]

          return Component && <Component key={v4()} {...restOptions} />
        }

        return null
      })

      return actionsFormatted.filter((action) => action)
    },
    [actionsMap, fnNotDisableWhen, fnShowWhen]
  )

  const renderActions = useCallback(
    (actions: ActionType<T>[]) => {
      const actionsFormatted: (JSX.Element | null)[] = actions.map((action) => {
        const { type, options } = action
        let restOptions

        if (options) {
          const { notDisableWhen, showWhen, ...rest } = options
          restOptions = rest

          const disabledAction = fnNotDisableWhen(notDisableWhen)
          const showAction = fnShowWhen(showWhen)

          if (!showAction) {
            return null
          }

          if (disabledAction) {
            restOptions.isDisabled = disabledAction
          }
        }

        if (OUTSIDE_MENU.includes(type)) return null

        const Component = actionsMap[type]

        return Component && <Component key={v4()} {...restOptions} />
      })

      return actionsFormatted.filter((action) => action)
    },
    [actionsMap, fnNotDisableWhen, fnShowWhen]
  )

  return (
    <Td textAlign="right" {...tableActionPropsMemo}>
      {actions && !!actions.length && (
        <ButtonGroup>
          <ButtonGroup>{renderActionsDefault(actions)}</ButtonGroup>
          {renderActions(actions).length > 0 && (
            <Menu autoSelect={false}>
              <MenuButton
                aria-label="actions-menu"
                variant="ghost"
                as={IconButton}
                icon={<FiMoreVertical />}
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                }}
                size="sm"
                {...menuButtonProps}
              />
              <MenuList color="blue.500" variant="ghost" size="sm">
                {children}
                {renderActions(actions)}
              </MenuList>
            </Menu>
          )}
        </ButtonGroup>
      )}
    </Td>
  )
}
