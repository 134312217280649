// Vendors
import { map } from 'lodash'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import React, {
  useMemo,
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
} from 'react'
import {
  useForm,
  Controller,
  FormProvider,
  SubmitHandler,
} from 'react-hook-form'

// Functions
import { apiPost } from 'services/post'
import { useEditRecords } from 'hooks/useEditRecord'
import { useFormTransform } from 'hooks/useFormTransform'

// Components
import { TabGeneral } from './tabs/TabGeneral'
import { TabDetail, Tabs } from 'components/Tabs'
import { TabMarketing } from './tabs/TabMarketing'
import { FormFooter } from 'components/FormFooter'
import { FieldToggle } from 'components/FieldToggle'

// Schemas
import { schema } from './schema'

// Styles
import {
  Box,
  Flex,
  Text,
  useToast,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react'

// Types
import { SampleTransportKitShowRecord } from './types'
import { SampleTransportKit } from 'types/SampleTransportKit'

type KitShowFormProps = {
  endpoint: string
  id: string
}

export const KitShowForm = (props: KitShowFormProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint, id } = props

  const { t } = useTranslation('common')
  const toast = useToast({ position: 'top-right' })
  const { statusParser } = useFormTransform()

  const { record } = useEditRecords<
    SampleTransportKit,
    SampleTransportKitShowRecord
  >({ endpoint, currentId: id })

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  const recordCurrent = record.current

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [textEditorData, setTextEditorData] = useState('')
  const [complementaryImages, setComplementaryImages] = useState<File[]>([])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Dados gerais',
        component: TabGeneral,
        names: [
          'name',
          'sku',
          'description',
          'weightInGrams',
          'widthInCentimeters',
          'heightInCentimeters',
          'lengthInCentimeters',
          'laboratoryAnalysisIds',
        ],
      },
      {
        title: 'Marketing',
        component: TabMarketing,
        options: {
          textEditorData,
          setTextEditorData,
          setComplementaryImages,
          recordReload: record.reload,
          recordCurrentFiles: recordCurrent.sampleTransportKitHasFiles,
        },
        names: ['kitVideoInstructions'],
      },
    ],
    [textEditorData, record.reload, recordCurrent.sampleTransportKitHasFiles]
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    setTextEditorData(recordCurrent.kitItems)
  }, [recordCurrent.kitItems])

  useEffect(() => {
    const laboratoryAnalysisIds = map(
      recordCurrent.sampleTransportKitAttendLaboratoryAnalysis,
      'id'
    )

    const status = statusParser(recordCurrent.status)

    methods.reset({ ...recordCurrent, laboratoryAnalysisIds, status })
  }, [methods, recordCurrent, statusParser])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const deleteDialog = useMemo(() => {
    if (!recordCurrent || !recordCurrent.storeProducts) return

    if (recordCurrent.storeProducts.length > 0) {
      const productsLength = recordCurrent.storeProducts.length
      const bodyDialog = map(recordCurrent.storeProducts, (product) => {
        return (
          <Link to={`/products/${product.id}`} key={product.id}>
            <ListItem
              color="blue.600"
              _hover={{
                filter: 'brightness(0.85)',
                textDecoration: 'underline',
              }}
            >
              {product.mktTitle}
            </ListItem>
          </Link>
        )
      })

      return {
        title: 'Este registro não pode ser excluído',
        body: (
          <>
            <Box>
              Atualmente, {productsLength}{' '}
              {productsLength === 1
                ? 'produto ativo utiliza'
                : 'produtos ativos utilizam'}{' '}
              este registro:{' '}
            </Box>
            <UnorderedList>{bodyDialog}</UnorderedList>
          </>
        ),
        isDisabled: true,
      }
    } else {
      return {
        title: 'Por favor confirme a exclusão.',
        isDisabled: false,
        body: (
          <Text>
            Não existem produtos ativos utilizando este registro. Ao excluir
            este item, é possível que produtos desativados que dependam dele não
            possam mais ser ativados. Você tem certeza que deseja excluir este
            item?
          </Text>
        ),
      }
    }
  }, [recordCurrent])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleToggle = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target

      if (checked) {
        await record.enable()
      } else {
        await record.disable()
      }

      toast({
        title: 'Sucesso',
        description: 'Status atualizado com sucesso',
        status: 'success',
      })
    },
    [record, toast]
  )

  const onSubmit: SubmitHandler<SampleTransportKitShowRecord> =
    React.useCallback(
      async (data) => {
        const formattedData = {
          ...data,
          kitItems: textEditorData,
        }
        try {
          await record.update(formattedData)

          if (complementaryImages.length) {
            const formData = new FormData()

            complementaryImages.forEach((image, index) => {
              formData.append(`files[${index}]`, image)
            })

            await apiPost(`${endpoint}/${id}/upload`, formData)
          }

          toast({
            title: 'Atualizado com sucesso',
            description: 'Kit atualizado com sucesso',
            status: 'success',
          })
        } catch (error) {
          if (error && error instanceof Array) {
            error.forEach((fieldError) => {
              methods.setError(fieldError.field, {
                message: fieldError.message,
              })
            })
          }
        }
      },
      [
        complementaryImages,
        endpoint,
        id,
        methods,
        record,
        textEditorData,
        toast,
      ]
    )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box>
          <Controller
            name="status"
            control={methods.control}
            render={({ field: { value, ref, name, onChange } }) => (
              <FieldToggle
                name={name}
                position="absolute"
                top={-16}
                right={0}
                ref={ref}
                isChecked={value}
                error={methods.formState.errors.status}
                onChange={(e) => {
                  onChange(e)
                  handleToggle(e)
                }}
              />
            )}
          />

          <Tabs tabsDetails={tabsDetails} errors={methods.formState.errors} />
        </Box>

        <FormFooter
          recordDelete={record.delete}
          isSubmitting={methods.formState.isSubmitting}
          titleDialog={deleteDialog?.title}
          bodyDialog={deleteDialog?.body}
          isDisabledDialog={deleteDialog?.isDisabled}
        />
      </Flex>
    </FormProvider>
  )
}
