import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

export const CollectionBrush = (
  props: HTMLChakraProps<'svg'> & {
    iconColor?: string
    colorStopInit: string
    colorStopEnd: string
  }
) => {
  const { iconColor, colorStopInit, colorStopEnd, ...rest } = props
  const color = iconColor || 'white'
  return (
    <chakra.svg
      id="Componente_56_1"
      data-name="Componente 56 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="53.73"
      height="53.73"
      viewBox="0 0 53.73 53.73"
      {...rest}
    >
      <svg>
        <path
          id="Caminho_28"
          data-name="Caminho 28"
          d="M26.865,53.73A26.865,26.865,0,1,0,0,26.865,26.865,26.865,0,0,0,26.865,53.73Z"
          fill={colorStopInit}
        />
        <g
          id="Grupo_43"
          data-name="Grupo 43"
          transform="translate(19.659 5.373) rotate(5)"
        >
          <path
            id="Caminho_43355"
            data-name="Caminho 43355"
            d="M0,0H5.3"
            transform="translate(12.242 2.616) rotate(21)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Caminho_43358"
            data-name="Caminho 43358"
            d="M0,0H3.348"
            transform="translate(14.263 0.176) rotate(21)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Caminho_43356"
            data-name="Caminho 43356"
            d="M0,0H6.583"
            transform="translate(10.47 5.443) rotate(21)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Caminho_43357"
            data-name="Caminho 43357"
            d="M0,0H8.148"
            transform="translate(8.739 7.994) rotate(21)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Caminho_43359"
            data-name="Caminho 43359"
            d="M0,0H11.012"
            transform="translate(6.409 10.315) rotate(21)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Caminho_43360"
            data-name="Caminho 43360"
            d="M0,0"
            transform="translate(17.08 1.258) rotate(21)"
            fill="none"
            stroke={color}
            strokeWidth="1"
          />
          <g
            id="Retângulo_4502"
            data-name="Retângulo 4502"
            transform="translate(15.404 0) rotate(21)"
            fill={color}
            stroke={color}
            strokeWidth="1"
          >
            <rect width="1.343" height="42.984" rx="0.672" stroke="none" />
            <rect
              x="0.5"
              y="0.5"
              width="0.343"
              height="41.984"
              rx="0.172"
              fill="none"
            />
          </g>
        </g>
      </svg>
    </chakra.svg>
  )
}
