import {
  Text,
  Badge,
  HStack,
  Divider,
  Heading,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react'
import { Audit } from 'pages/private/Audit/list/types'
import React from 'react'
import { formatDate } from 'utils/formatDate'

type ListCardProps = {
  record: Audit
}

export const ListCard = (props: ListCardProps) => {
  const { record } = props

  const httpMethodStyle = {
    GET: '#61affe',
    POST: '#49cc90',
    PUT: '#f4c242',
    DELETE: '#f44242',
    PATCH: '#50e3c2',
  }

  return (
    <AccordionItem my="4">
      <AccordionButton textAlign="start" boxShadow="md" p="4" borderRadius="4">
        <HStack width="inherit">
          <Text as="span" minW="44" whiteSpace="nowrap">
            {formatDate(record.createdAt, "dd-MM-yyyy HH'h'mm")}
          </Text>

          <Badge bg={httpMethodStyle[record.httpMethod]} color="white">
            {record.httpMethod}
          </Badge>

          <Text as="span" color="gray.600">
            {record.responseStatus}
          </Text>

          <Text as="span" isTruncated maxW="7xl">
            {record.route}
          </Text>
        </HStack>
      </AccordionButton>

      <AccordionPanel>
        <HStack>
          {record.userEmail && <Text>{record.userEmail}</Text>}

          <Text>
            <strong>IP:</strong> {record.ip}
          </Text>
        </HStack>

        <Text isTruncated w="5xl">
          {record.browserUserAgent}
        </Text>

        {record.requestPayload &&
          Object.keys(record.requestPayload).length > 0 && (
            <>
              <Divider my="4" />

              <Heading fontSize="md">Payload</Heading>

              <Text
                as="pre"
                p="4"
                my="2"
                bg="#f6f6f6"
                borderRadius="md"
                fontFamily="monospace"
              >
                {JSON.stringify(record.requestPayload, null, 2)}
              </Text>
            </>
          )}

        {record.isException && (
          <>
            <Divider my="4" />

            <Heading fontSize="md">Exceção</Heading>

            <HStack my="2">
              <Badge
                bg={record.isException ? '#f44242' : '#49cc90'}
                color="white"
              >
                É exceção
              </Badge>
            </HStack>

            {record.responseStackError && (
              <Text
                as="pre"
                p="4"
                my="2"
                bg="#f6f6f6"
                borderRadius="md"
                fontFamily="monospace"
              >
                {record.responseStackError}
              </Text>
            )}
          </>
        )}
      </AccordionPanel>
    </AccordionItem>
  )
}
