import { ptBR } from 'date-fns/locale'
import { formatDistance } from 'date-fns'
import React, { useCallback, useState } from 'react'

import {
  NotificationItem,
  NotificationStatus,
} from 'contexts/notification/types'
import { useNotification } from 'contexts/notification'

import { PinSolid } from 'assets/icons/PinSolid'
import { ArchiveIn } from 'assets/icons/ArchiveIn'
import { PinOutline } from 'assets/icons/PinOutline'

import {
  Box,
  Text,
  Flex,
  Avatar,
  HStack,
  Heading,
  IconButton,
  ButtonGroup,
} from '@chakra-ui/react'

type NotificationCardProps = {
  data: NotificationItem
}

export const NotificationCard = ({ data }: NotificationCardProps) => {
  const {
    markAsArchive,
    markAsFavorite,
    markAsUnArchive,
    markAsUnFavorite,
    fetchNotifications,
  } = useNotification()

  const [isArchiving, setIsArchiving] = useState(false)
  const [isFavoriting, setIsFavoriting] = useState(false)

  const handleArchive = useCallback(async () => {
    try {
      setIsArchiving(true)
      if (data.status === NotificationStatus.ARCHIVED) {
        await markAsUnArchive(data.id)
      } else {
        await markAsArchive(data.id)
      }

      await fetchNotifications()
    } catch (error) {
      console.error(error)
    } finally {
      setIsArchiving(false)
    }
  }, [data.id, data.status, fetchNotifications, markAsArchive, markAsUnArchive])

  const handleFavorite = useCallback(async () => {
    try {
      setIsFavoriting(true)

      if (data.starred) {
        await markAsUnFavorite(data.id)
      } else {
        await markAsFavorite(data.id)
      }

      await fetchNotifications()
    } catch (error) {
      console.error(error)
    } finally {
      setIsFavoriting(false)
    }
  }, [
    data.id,
    data.starred,
    markAsFavorite,
    markAsUnFavorite,
    fetchNotifications,
  ])

  return (
    <Flex mt="2.5" py="4" pl="3" pr="3.5" w="full" bg="white">
      <Avatar
        w="10"
        h="10"
        mr="3"
        name={data?.createdByUser?.person?.name}
        _before={{
          ...(data.status === 'UNREAD'
            ? {
                content: '""',
                left: '-3',
                width: '3px',
                height: '60px',
                bg: '#13A7A7',
                position: 'absolute',
                borderRightRadius: '6px',
              }
            : {}),
        }}
      />

      <Flex flex="1" justifyContent="space-between">
        <Box pb="4">
          <HStack>
            <Heading color="black" fontSize="sm" lineHeight="4">
              {data?.createdByUser?.person?.name}
            </Heading>

            {data.status === 'UNREAD' && (
              <Text as="sup" color="#13A7A7">
                novo
              </Text>
            )}
          </HStack>

          <Text as="span" color="gray.900" fontSize="xs">
            {data.title}
          </Text>

          <Text
            mt="2"
            fontSize="xs"
            color="gray.800"
            fontWeight="thin"
            lineHeight="15px"
          >
            {data.description}
          </Text>

          {data.message && (
            <Box position="relative">
              <Text
                mt="2"
                pl="1.5"
                color="gray.800"
                fontWeight="thin"
                fontSize="0.625rem"
                _before={{
                  content: '""',
                  left: '0',
                  width: '1px',
                  height: '100%',
                  bg: '#A1A1A1',
                  position: 'absolute',
                }}
              >
                {data.message}
              </Text>
            </Box>
          )}
        </Box>
        <Flex
          flexDir="column"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <ButtonGroup variant="ghost" size="xs" spacing="0">
            <IconButton
              icon={<ArchiveIn />}
              aria-label="arquivar"
              isLoading={isArchiving}
              onClick={handleArchive}
            />

            <IconButton
              aria-label="fixar"
              onClick={handleFavorite}
              isLoading={isFavoriting}
              icon={data.starred ? <PinSolid /> : <PinOutline />}
            />
          </ButtonGroup>

          <Text color="gray.800" fontSize="0.625rem">
            {formatDistance(new Date(data.createdAt), Date.now(), {
              addSuffix: true,
              locale: ptBR,
            })}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
