// Vendors
import { get, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react'

// Functions
import { useEditRecords } from 'hooks/useEditRecord'
import { useFormTransform } from 'hooks/useFormTransform'
import { useFormFieldEditer } from 'hooks/useFormFieldEditer'

// Components
import { TabGeneral } from '../tabs/TabGeneral'
import { TabDetail, Tabs } from 'components/Tabs'
import { FieldToggle } from 'components/FieldToggle'
import { TabMedicalRecord } from '../tabs/TabMedicalRecord'

// Schemas
import { schema } from './schema'

// Styles
import {
  Flex,
  Box,
  useToast,
  UnorderedList,
  Text,
  ListItem,
} from '@chakra-ui/react'

// Types
import {
  LaboratorialAnalysisShowForm,
  LaboratorialAnalysisShowRecord,
} from './types'
import { FormFooter } from 'components/FormFooter'
import { Link } from 'react-router-dom'

type LaboratorialAnalysisFormShowProps = {
  endpoint: string
  id: string
}

export const LaboratorialAnalysisFormShow = (
  props: LaboratorialAnalysisFormShowProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id, endpoint } = props
  const { t } = useTranslation('common')
  const { statusParser } = useFormTransform()
  const { questions, handleQuestion } = useFormFieldEditer()
  const toast = useToast({ position: 'top-right' })

  const { record } = useEditRecords<
    LaboratorialAnalysisShowRecord,
    LaboratorialAnalysisShowForm
  >({ endpoint, currentId: id })

  const recordCurrent = record.current
  const formFieldEditerDefaultValues = handleQuestion.defaultValues

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    const transformData = {
      status: statusParser(get(recordCurrent, 'status')),
      name: get(recordCurrent, 'name'),
      description: get(recordCurrent, 'description'),
      medicalReportDeliveryEstimateInDays: get(
        recordCurrent,
        'medicalReportDeliveryEstimateInDays'
      ),
    }

    methods.reset(transformData)

    const formQuestions = get(
      recordCurrent,
      'clinicalRecord.clinicalRecordForm.questions',
      undefined
    )

    if (!formQuestions) return
    formFieldEditerDefaultValues(formQuestions)
  }, [methods, recordCurrent, formFieldEditerDefaultValues, statusParser])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Geral',
        component: TabGeneral,
        options: { methods },
      },
      {
        title: 'Ficha clínica',
        component: TabMedicalRecord,
        options: {
          questions,
          handleQuestion,
        },
      },
    ],
    [questions, handleQuestion, methods]
  )

  const deleteDialog = useMemo(() => {
    if (
      !recordCurrent ||
      !recordCurrent.storeProducts ||
      !recordCurrent.sampleTransportKits
    )
      return
    const productsLength = recordCurrent.storeProducts.length
    const kitsLength = recordCurrent.sampleTransportKits.length

    if (productsLength > 0 || kitsLength > 0) {
      const totalItems = productsLength + kitsLength

      const products = map(recordCurrent.storeProducts, (product) => {
        return (
          <Link to={`/products/${product.id}`} key={product.id}>
            <ListItem
              color="blue.600"
              _hover={{
                filter: 'brightness(0.85)',
                textDecoration: 'underline',
              }}
            >
              {product.mktTitle}
            </ListItem>
          </Link>
        )
      })
      const sampleTransportKits = map(
        recordCurrent.sampleTransportKits,
        (sampleTransportKit) => {
          return (
            <Link
              to={`/products/${sampleTransportKit.id}`}
              key={sampleTransportKit.id}
            >
              <ListItem
                color="blue.600"
                _hover={{
                  filter: 'brightness(0.85)',
                  textDecoration: 'underline',
                }}
              >
                {sampleTransportKit.name}
              </ListItem>
            </Link>
          )
        }
      )

      return {
        title: 'Este registro não pode ser excluído',
        body: (
          <>
            <Box>
              Atualmente, {totalItems}{' '}
              {totalItems === 1
                ? 'item ativo utiliza'
                : 'itens ativos utilizam'}{' '}
              este registro:{' '}
            </Box>

            {!!productsLength && (
              <Box mt="5">
                <Text>Produtos</Text>
                <UnorderedList>{products}</UnorderedList>
              </Box>
            )}

            {!!kitsLength && (
              <Box mt="5">
                <Text>Kits</Text>
                <UnorderedList>{sampleTransportKits}</UnorderedList>
              </Box>
            )}
          </>
        ),
        isDisabled: true,
      }
    } else {
      return {
        title: 'Por favor confirme a exclusão.',
        isDisabled: false,
        body: (
          <Text>
            Não existem produtos ativos utilizando este registro. Ao excluir
            este item, é possível que produtos desativados que dependam dele não
            possam mais ser ativados. Você tem certeza que deseja excluir este
            item?
          </Text>
        ),
      }
    }
  }, [recordCurrent])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleToggle = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target

      if (checked) {
        await record.enable()
      } else {
        await record.disable()
      }

      toast({
        title: 'Sucesso',
        description: 'Status alterado com sucesso',
        status: 'success',
      })
    },
    [record, toast]
  )

  const onSubmit: SubmitHandler<LaboratorialAnalysisShowForm> =
    React.useCallback(
      async (data) => {
        const formData: LaboratorialAnalysisShowRecord = {
          ...data,
          status: data.status ? 'ENABLED' : 'DISABLED',
          clinicalRecordForm: {
            questions: handleQuestion.parser(),
          },
        }

        try {
          await record.update(formData)
          toast({
            title: t('toast.success.default', {
              type: t('common:toast.success.update'),
            }),
            status: 'success',
          })
        } catch (error) {
          if (error && error instanceof Array) {
            error.forEach((fieldError) => {
              methods.setError(fieldError.field, {
                message: fieldError.message,
              })
            })
          }
        }
      },
      [handleQuestion, methods, record, t, toast]
    )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex
      as="form"
      h="full"
      flexDirection="column"
      justifyContent="space-between"
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Box>
        <Controller
          name="status"
          control={methods.control}
          render={({ field: { onChange, value, ref, name } }) => (
            <FieldToggle
              name={name}
              position="absolute"
              top={-16}
              right={0}
              ref={ref}
              isChecked={value === 'ENABLED' || !!value}
              onChange={(e) => {
                onChange(e)
                handleToggle(e)
              }}
              error={methods.formState.errors.status}
            />
          )}
        />

        <Tabs tabsDetails={tabsDetails} />
      </Box>

      <FormFooter
        isSubmitting={methods.formState.isSubmitting}
        recordDelete={record.delete}
        titleDialog={deleteDialog?.title}
        bodyDialog={deleteDialog?.body}
        isDisabledDialog={deleteDialog?.isDisabled}
      />
    </Flex>
  )
}
