import React from 'react'
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react'

type FormModalProps = {
  title: string
  isOpen: boolean
  onClose: () => void
}

export const FormModal: React.FC<FormModalProps> = ({
  title,
  isOpen,
  onClose,
  children,
}) => (
  <Modal
    size="xl"
    isCentered
    isOpen={isOpen}
    onClose={onClose}
    scrollBehavior="inside"
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>{children}</ModalBody>
    </ModalContent>
  </Modal>
)
