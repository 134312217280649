import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

export const Gluten = (
  props: HTMLChakraProps<'svg'> & {
    iconColor?: string
    colorStopInit: string
    colorStopEnd: string
  }
) => {
  const { iconColor, colorStopInit, colorStopEnd, ...rest } = props
  const color = iconColor || 'white'
  return (
    <chakra.svg
      id="Componente_60_1"
      data-name="Componente 60 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="53.73"
      height="53.73"
      viewBox="0 0 53.73 53.73"
      {...rest}
    >
      <svg>
        <path
          id="Caminho_4"
          data-name="Caminho 4"
          d="M26.865,53.73A26.865,26.865,0,1,0,0,26.865,26.865,26.865,0,0,0,26.865,53.73Z"
          fill={colorStopInit}
        />
        <g
          id="Grupo_31"
          data-name="Grupo 31"
          transform="translate(14.159 6.716)"
        >
          <path
            id="Caminho_6953"
            data-name="Caminho 6953"
            d="M615.038,545.624a2.933,2.933,0,0,1-2.1-.866,2.963,2.963,0,0,1-.863-2.1,9.516,9.516,0,0,1,9.438-9.44l.049,0a3.064,3.064,0,0,1,2.053.863,2.961,2.961,0,0,1,.865,2.1A9.516,9.516,0,0,1,615.038,545.624Zm6.46-10.841a7.948,7.948,0,0,0-7.861,7.884,1.4,1.4,0,0,0,.406.986,1.372,1.372,0,0,0,.98.406,7.95,7.95,0,0,0,7.89-7.884,1.4,1.4,0,0,0-.407-.987A1.555,1.555,0,0,0,621.5,534.783Z"
            transform="translate(-599.065 -507.874)"
            fill={color}
            stroke={color}
            strokeWidth="0.5"
          />
          <path
            id="Caminho_6954"
            data-name="Caminho 6954"
            d="M615.038,538a2.928,2.928,0,0,1-2.1-.864,2.96,2.96,0,0,1-.864-2.1,9.517,9.517,0,0,1,9.438-9.44l.049,0a3.064,3.064,0,0,1,2.053.863,2.964,2.964,0,0,1,.865,2.1A9.515,9.515,0,0,1,615.038,538Zm6.46-10.84a7.949,7.949,0,0,0-7.861,7.884,1.4,1.4,0,0,0,.406.988,1.378,1.378,0,0,0,.98.4,7.949,7.949,0,0,0,7.89-7.884,1.4,1.4,0,0,0-.407-.986A1.57,1.57,0,0,0,621.5,527.155Z"
            transform="translate(-599.065 -512.921)"
            fill={color}
            stroke={color}
            strokeWidth="0.5"
          />
          <path
            id="Caminho_6955"
            data-name="Caminho 6955"
            d="M613.207,545.624a9.519,9.519,0,0,1-9.454-9.44,2.952,2.952,0,0,1,2.936-2.964h.028a9.516,9.516,0,0,1,9.44,9.44,2.949,2.949,0,0,1-2.95,2.964Zm-6.484-10.841a1.425,1.425,0,0,0-1,.405,1.4,1.4,0,0,0-.406.988,7.947,7.947,0,0,0,7.884,7.884,1.385,1.385,0,0,0,1.393-1.394A7.95,7.95,0,0,0,606.723,534.783Z"
            transform="translate(-603.753 -507.874)"
            fill={color}
            stroke={color}
            strokeWidth="0.5"
          />
          <path
            id="Caminho_6956"
            data-name="Caminho 6956"
            d="M613.207,538a9.518,9.518,0,0,1-9.454-9.44,2.95,2.95,0,0,1,2.936-2.962l.028,0a9.518,9.518,0,0,1,9.44,9.44,2.946,2.946,0,0,1-2.95,2.964Zm-6.484-10.84a1.421,1.421,0,0,0-1,.406,1.394,1.394,0,0,0-.406.986,7.946,7.946,0,0,0,7.884,7.884,1.385,1.385,0,0,0,1.393-1.395A7.949,7.949,0,0,0,606.723,527.155Z"
            transform="translate(-603.753 -512.921)"
            fill={color}
            stroke={color}
            strokeWidth="0.5"
          />
          <path
            id="Caminho_6957"
            data-name="Caminho 6957"
            d="M613.726,533.62a2.935,2.935,0,0,1-2.013-.794,9.516,9.516,0,0,1,0-14.066,2.955,2.955,0,0,1,4.029,0,9.52,9.52,0,0,1,0,14.066A2.938,2.938,0,0,1,613.726,533.62Zm0-14.091a1.383,1.383,0,0,0-.947.372,7.955,7.955,0,0,0,0,11.782,1.419,1.419,0,0,0,1.893,0,7.957,7.957,0,0,0,0-11.782A1.378,1.378,0,0,0,613.726,519.529Z"
            transform="translate(-601.019 -517.966)"
            fill={color}
            stroke={color}
            strokeWidth="0.5"
          />
        </g>
      </svg>
    </chakra.svg>
  )
}
