// Vendors
import { get } from 'lodash'
import React, { useCallback, useMemo } from 'react'

// Components

// Styles
import { Box } from '@chakra-ui/react'
import { FormQuestionType } from 'types/LaboratorialAnalysis'
import { FormFieldEditorDate } from './FormFieldEditorDate'
import { FormFieldEditorText } from './FormFieldEditorText'
import { FormFieldEditorNumber } from './FormFieldEditorNumber'
import { FormFieldEditorTextArea } from './FormFieldEditorTextArea'
import { FormFieldEditorMultipleChoice } from './FormFieldEditorMultipleChoice'
import { FormFieldEditorSingleChoice } from './FormFieldEditorSingleChoice'
import { FormFieldEditorSelect } from './FormFieldEditorSelect'

// Types

export type FormFieldEditorProps = {
  question: FormQuestionType
}

export const FormFieldEditor = (props: FormFieldEditorProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { question } = props

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const component = useMemo(
    () => ({
      date: FormFieldEditorDate,
      text: FormFieldEditorText,
      number: FormFieldEditorNumber,
      select: FormFieldEditorSelect,
      textarea: FormFieldEditorTextArea,
      checkbox: FormFieldEditorMultipleChoice,
      radiobutton: FormFieldEditorSingleChoice,
    }),
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const renderComponent = useCallback(() => {
    const Component = get(component, question.fieldType)

    if (!Component) return null

    return <Component question={question} />
  }, [component, question])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return <Box>{renderComponent()}</Box>
}
