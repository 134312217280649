// Vendors
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

// Contexts
import { useCart } from 'contexts/cart'

// Functions
import formatCurrency from 'utils/formatCurrency'

// Components
import { FormWrapper } from 'components/FormWrapper'

// Styles
import { Td, Th, Tr, Table, Tbody, Thead } from '@chakra-ui/react'
import { FormCheckout } from '../../types'

export const FinalValues = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('checkout')
  const { checkout } = useCart()

  const { watch } = useFormContext<FormCheckout>()

  const watchShippingMethod = watch('shippingMethodId')

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormWrapper title={t('group.finalValues')} titlePosition="top">
      <Table mt="6">
        <Thead bg="gray.50">
          <Tr>
            <Th w="96">{t('table.headers.freight')}</Th>
            <Th>{t('table.headers.value')}</Th>
            <Th>{t('table.headers.discount')}</Th>
            <Th>{t('table.headers.totalWithDiscount')}</Th>
          </Tr>
        </Thead>

        <Tbody>
          <Tr h="20">
            <Td>{watchShippingMethod?.label}</Td>
            <Td>{formatCurrency(checkout?.totalPriceWithoutDiscount || 0)}</Td>
            <Td>{formatCurrency(checkout?.totalDiscount || 0)}</Td>
            <Td fontWeight="semibold">
              {formatCurrency(checkout?.totalPrice || 0)}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </FormWrapper>
  )
}
