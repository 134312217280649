import React from 'react'

import { chakra, HTMLChakraProps } from '@chakra-ui/react'

export type IconProps = HTMLChakraProps<'svg'>

export const DoubleCheck = (props: IconProps) => {
  return (
    <svg
      width="14px"
      height="14px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.97 3.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L1.326 7.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L7.95 3.992a.25.25 0 0 1 .02-.022Zm-.92 5.14.92.92a.749.749 0 0 0 1.08-.02l3.992-4.99a.749.749 0 0 0-.563-1.283.75.75 0 0 0-.528.255L8.478 8.417l-.485-.486L7.05 9.11Z"
        fill="#474747"
      />
    </svg>
  )
}
