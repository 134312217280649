// Vendors
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { useError } from 'hooks/useError'
import { apiPatch } from 'services/patch'

// Components
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import {
  Text,
  Modal,
  Stack,
  Button,
  ListItem,
  ModalBody,
  OrderedList,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react'

type ModalResetAccountPasswordProps = {
  email: string
  isOpen: boolean
  reload: () => void
  onCancel: () => void
  isWorkspace?: boolean
  personalDataId: string
}

type FormResetAccountPassword = {
  comments: string
}

export const ModalResetAccountPassword = (
  props: ModalResetAccountPasswordProps
) => {
  const {
    isOpen,
    email,
    reload,
    onCancel,
    personalDataId,
    isWorkspace = true,
  } = props

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormResetAccountPassword>()

  const { handleError } = useError()

  const onClose = useCallback(() => {
    reset()
    onCancel()
  }, [onCancel, reset])

  const onSubmit = useCallback(
    async (data: FormResetAccountPassword) => {
      try {
        const endpoint = isWorkspace
          ? `/app/backoffice/forget_password/${personalDataId}`
          : `/app/forget_password/${personalDataId}`

        await apiPatch(endpoint, {
          email,
          comments: data.comments,
        })

        reload()
        onClose()
      } catch (error) {
        handleError(error)
      }
    },
    [email, handleError, isWorkspace, onClose, personalDataId, reload]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Resetar senha da conta</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text color="gray.500" mb="4">
            Ao resetar a senha da conta de um cliente, vamos:
          </Text>

          <OrderedList color="gray.500">
            <ListItem>Redefinir a senha para um valor aleatório.</ListItem>

            <ListItem>
              Invalidar os logins e pedidos de reset de senha do cliente.
            </ListItem>

            <ListItem>
              Notificar o cliente por e-mail dizendo que a senha foi resetada
              pelo admin.
            </ListItem>
          </OrderedList>

          <Text color="gray.500" my="4">
            Para continuar, por favor preencha os campos abaixo:
          </Text>

          <Stack>
            <FieldTextArea
              label="Justificativa"
              error={errors.comments}
              placeholder="Digite uma justificativa"
              {...register('comments', {
                required: 'Por favor, preencha o campo de justificativa',
              })}
            />
          </Stack>

          <Text fontSize="sm" color="orange.500" mt="4" fontStyle="italic">
            Esta operação não pode ser desfeita.
          </Text>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose}>Cancelar</Button>
            <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
