import { SelectType } from 'hooks/useSelectParser'
import { Record } from 'types/Requests'

export enum DISCOUNT_TYPES {
  PRICE = 'PREÇO',
  PERCENTAGE = 'PORCENTAGEM',
}

export enum STATUS_TYPES {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

type DiscountType = keyof typeof DISCOUNT_TYPES
export type StatusTypes = keyof typeof STATUS_TYPES

export type CouponShowForm = Record & {
  name: string
  status: StatusTypes
  validFrom: Date
  validUntil: Date
  description: string
  maximumUsage: number
  minimumPrice: number
  maximumPrice: number
  discountPrice: number
  generatedByUserId: string
  discountType: SelectType<DiscountType>
  discountPercentage: number
  maximumClientUsage: number
  generatedByUsername: string
  storeProductIds: string[]
  assignedUserId: string
}

export type CouponShowEditRecord = Record & {
  name: string
  status: StatusTypes
  validFrom: Date
  validUntil: Date
  description: string
  maximumUsage: number
  minimumPrice: number
  maximumPrice: number
  discountPrice: number
  generatedByUserId: string
  discountType: DiscountType
  discountPercentage: number
  maximumClientUsage: number
  generatedByUsername: string
  storeProductIds: string[]
  assignedUserId: string
}
