import React from 'react'
import { FormHelperText, Text } from '@chakra-ui/react'

interface IUseFormHelperTextProps {
  canModify?: boolean
  helperText?: string | { text: string | React.ReactNode; color?: string }
}

export const useFormHelperText = ({
  helperText,
  canModify = true,
}: IUseFormHelperTextProps) => {
  if (!helperText && canModify) {
    return null
  }

  if (typeof helperText === 'string') {
    return <FormHelperText lineHeight="6">{helperText}</FormHelperText>
  }

  if (typeof helperText === 'object') {
    return (
      <FormHelperText color={helperText.color}>
        {helperText.text}
      </FormHelperText>
    )
  }

  if (!canModify) {
    return (
      <FormHelperText color="orange.500">
        <Text fontWeight="bold" d="inline">
          Atenção:
        </Text>{' '}
        Após salvar este registro, esta informação não poderá ser modificada.
      </FormHelperText>
    )
  }

  return null
}
