// Vendors
import React from 'react'
import { useFormContext } from 'react-hook-form'

// Components
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import { Box } from '@chakra-ui/react'
import { FormFieldEditorProps } from '../MedicalRecord'
import { useFormFieldEditor } from '../useFormFieldEditor'
import { get } from 'lodash'

// Hooks

// Types

export const FormFieldEditorTextArea = (props: FormFieldEditorProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { question } = props
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const { rules, label, name } = useFormFieldEditor({ question })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <FieldTextArea
        label={label}
        error={get(errors, name)}
        {...register(name, {
          ...rules,
          required: rules.required ? `${label} é obrigatorio` : undefined,
        })}
      />
    </Box>
  )
}
