import { kebabCase } from 'lodash'
import { useMemo } from 'react'
import { FormQuestionType } from 'types/LaboratorialAnalysis'

type UseFormFieldEditorProps = {
  question: FormQuestionType
}

export const useFormFieldEditor = ({ question }: UseFormFieldEditorProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const rules = useMemo(() => {
    const constraints = question.constraints.reduce((acc, constraint) => {
      return {
        ...acc,
        [constraint.name]: {
          value: constraint.value,
          message: `${constraint.name} é invalido`,
        },
      }
    }, {})

    return {
      ...(question.constraints.length && constraints),
      ...(question.required &&
        question.fieldType !== 'checkbox' && { required: 'Campo obrigatório' }),
    }
  }, [question])

  const label = useMemo(() => {
    return question.label
  }, [question])

  const name = useMemo(() => {
    return `${question?.id}.${kebabCase(question.label)}`
  }, [question])

  const selectOptions = useMemo(() => {
    return question.options.map((option) => ({
      value: `${question?.id}.${option.value}`,
      label: option.label,
    }))
  }, [question])

  const radioOptions = useMemo(() => {
    return question.options.map((option) => ({
      value: `${question?.id}.${option.value}`,
      label: option.label,
    }))
  }, [question])

  const checkboxOptions = useMemo(() => {
    return question.options.map((option) => ({
      name: `${question?.id}.${kebabCase(question.label)}.${kebabCase(
        option.value
      )}`,
      label: option.label,
    }))
  }, [question])

  /*
  |-----------------------------------------------------------------------------
  | Return
  |-----------------------------------------------------------------------------
  |
  |
  */

  return {
    name,
    rules,
    label,
    options: {
      radioOptions,
      selectOptions,
      checkboxOptions,
    },
  }
}
