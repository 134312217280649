import { Record } from 'types/Requests'

export enum BlogStatusEnum {
  DRAFT = 'Rascunho',
  PUBLISHED = 'Publicado',
  REVISION = 'Revisão',
  TRASH = 'Lixeira',
}

export enum AccentColorEnum {
  Mauvelous = 'Mauvelous',
  Polo = 'Polo',
  Blush = 'Blush',
  Lilac = 'Lilac',
  Cranberry = 'Cranberry',
  Fountain = 'Fountain',
  Willow = 'Willow',
  Sienna = 'Sienna',
  Orchid = 'Orchid',
  Mango = 'Mango',
  Viola = 'Viola',
}

export type Blog = Record & {
  title: string
  tags: {
    id: string
    name: string
  }[]
  formattedTags?: string
  formattedCategory?: string
  createdBy: Date
  featured?: boolean
  category: {
    id: string
    title: string
  }
  publishingDate: string
  articleAuthor?: string
  status: keyof typeof BlogStatusEnum
}
