export function testIsRequired(value: any, isRequired: boolean) {
  if (isRequired) {
    if (!value?.length) {
      return false
    } else {
      return true
    }
  }

  return true
}
