// Vendors
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

// Functions
import { apiShow } from 'services/get'
import { useQuery } from 'hooks/useQuery'
import { useCreateRecord } from 'hooks/useCreateRecord'
import { useFormFieldEditer } from 'hooks/useFormFieldEditer'

// Components
import { TabGeneral } from '../tabs/TabGeneral'
import { TabDetail, Tabs } from 'components/Tabs'
import { FormFooter } from 'components/FormFooter'
import { FieldToggle } from 'components/FieldToggle'
import { TabMedicalRecord } from '../tabs/TabMedicalRecord'

// Schemas
import { schema } from './schema'

// Styles
import { Flex, Box, useToast } from '@chakra-ui/react'

// Types
import {
  LaboratorialAnalysisNewForm,
  LaboratorialAnalysisNewRecord,
} from './types'

type LaboratorialAnalysisFormNewProps = {
  endpoint: string
}

export const LaboratorialAnalysisFormNew = (
  props: LaboratorialAnalysisFormNewProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props

  const { t } = useTranslation('common')
  const history = useHistory()

  const { questions, handleQuestion } = useFormFieldEditer()

  const { create } = useCreateRecord({ endpoint })

  const query = useQuery()
  const methods = useForm({ resolver: yupResolver(schema(t)) })
  const toast = useToast({ position: 'top-right' })

  const formFieldEditerDefaultValues = handleQuestion.defaultValues

  /*
  |-----------------------------------------------------------------------------
  | SetValues
  |-----------------------------------------------------------------------------
  |
  |
  */

  const setDefaultValues = useCallback(
    (response) => {
      const formQuestions = get(
        response,
        'clinicalRecord.clinicalRecordForm.questions'
      )

      if (!formQuestions) return
      formFieldEditerDefaultValues(formQuestions)
    },
    [formFieldEditerDefaultValues]
  )

  useEffect(() => {
    const duplicateFrom = query.get('duplicate_from')

    if (!duplicateFrom) return

    apiShow(`${endpoint}/${duplicateFrom}`).then((response) => {
      if (!response) return

      setDefaultValues(response)
    })
  }, [endpoint, query, setDefaultValues])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Geral',
        component: TabGeneral,
        options: { methods },
      },
      {
        title: 'Ficha clínica',
        component: TabMedicalRecord,
        options: {
          questions,
          handleQuestion,
        },
      },
    ],
    [questions, handleQuestion, methods]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<LaboratorialAnalysisNewForm> =
    React.useCallback(
      async (data) => {
        const formData: LaboratorialAnalysisNewRecord = {
          ...data,
          status: data.status ? 'ENABLED' : 'DISABLED',
          clinicalRecordForm: {
            questions: handleQuestion.parser(),
          },
        }

        try {
          await create(formData)
          toast({
            title: 'Sucesso',
            description: 'Análise laboratorial criada com sucesso',
            status: 'success',
          })

          history.push('/laboratorial_analysis')
        } catch (error) {
          if (error && error instanceof Array) {
            error.forEach((fieldError) => {
              methods.setError(fieldError.field, {
                message: fieldError.message,
              })
            })
          }
        }
      },
      [create, handleQuestion, history, methods, toast]
    )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex
      as="form"
      h="full"
      flexDirection="column"
      justifyContent="space-between"
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Box>
        <Controller
          name="status"
          control={methods.control}
          defaultValue={true}
          render={({ field: { onChange, value, ref, name } }) => (
            <FieldToggle
              name={name}
              position="absolute"
              top={-16}
              right={0}
              ref={ref}
              isChecked={value}
              onChange={onChange}
              error={methods.formState.errors.status}
            />
          )}
        />

        <Tabs tabsDetails={tabsDetails} />
      </Box>

      <FormFooter isSubmitting={methods.formState.isSubmitting} />
    </Flex>
  )
}
