// Vendors
import { filter, get, kebabCase } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FieldError, useForm } from 'react-hook-form'

// Contexts
import { apiPost } from 'services/post'
import { useError } from 'hooks/useError'
import { CheckoutType } from 'contexts/cart/interface'
import { useSelectParser } from 'hooks/useSelectParser'
import { useFormTransform } from 'hooks/useFormTransform'

// Components
import { FieldNumber } from 'components/FieldNumber'
import { FieldSelect } from 'components/FieldSelect'
import { FieldTextArea } from 'components/FieldTextArea'
import { OptionsType } from 'components/FormFieldEditerOptions'
import { PurchaseDetailsType } from 'components/ListTableAction/RecollectProcessingAction'

// Styles
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Stack,
  Text,
  Box,
} from '@chakra-ui/react'

import {
  PaymentMethodType,
  ShippingMethodType,
  PAYMENT_METHODS_NAMES,
  SHIPPING_METHODS_SERVICE_NAME,
} from 'pages/private/Checkout/components/PaymentAndShipping'
import { SuccessSubsidized } from './components/SuccessSubsidized'
import { SuccessLittleMachine } from './components/SuccessLittleMachine'
import { SuccessPaymentLink } from './components/SuccessPaymentLink'

type ModalRecollectProcessingProps = {
  isOpen: boolean
  reload: () => void
  onCancel: () => void
  purchaseDetails?: PurchaseDetailsType
  buyerName?: string
}

type FormRecollectProcessing = {
  comment: string
  unitPrice: number
  paymentMethod: {
    value: string
    label: string
  }
  shippingMethod: {
    value: string
    label: string
  }
  seller: OptionsType
}

export const ModalRecollectProcessing = (
  props: ModalRecollectProcessingProps
) => {
  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectSellers = useSelectParser({
    modalIsOpen: props.isOpen,
    accessor: { label: 'name', value: 'userId' },
    endpoint: '/app/person?type=seller',
  })

  const selectShippingMethods = useSelectParser<ShippingMethodType>({
    modalIsOpen: props.isOpen,
    accessor: { label: 'providerName', value: 'id' },
    endpoint: '/public/purchase/shipping_method',
    callback: (data) => {
      return filter(data, (item) =>
        SHIPPING_METHODS_SERVICE_NAME.includes(kebabCase(item.serviceName))
      )
    },
  })

  const selectPaymentMethods = useSelectParser<PaymentMethodType>({
    modalIsOpen: props.isOpen,
    accessor: { label: 'name', value: 'id' },
    endpoint: '/public/purchase/payment_method',
    callback: (data) => {
      return filter(data, (item) =>
        PAYMENT_METHODS_NAMES.includes(kebabCase(item.name))
      )
    },
  })

  /*
  |-----------------------------------------------------------------------------
  | Contants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, reload, onCancel, purchaseDetails, buyerName } = props

  const { selectParser } = useFormTransform()

  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormRecollectProcessing>()

  const { handleError } = useError()

  const [paymentLink, setPaymentLink] = useState<string>('')
  const [showSuccess, setShowSuccess] = useState({
    bonificado: false,
    'link-de-pagamento': false,
    'maquina-de-cartao': false,
  })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!purchaseDetails) return

    const sellerId = get(purchaseDetails, 'sellerId')

    if (!sellerId) return

    const seller = selectParser(sellerId, selectSellers.options)

    if (!seller) return

    setValue('seller', seller as OptionsType)
  }, [purchaseDetails, reset, selectParser, selectSellers.options, setValue])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const formattedShippingAddress = useMemo(() => {
    if (!purchaseDetails) return ''

    const shippingAddress = get(purchaseDetails, 'shippingAddress')

    if (!shippingAddress) return ''

    const {
      street,
      number,
      complement,
      postalCode,
      neighborhood,
      referencePoint,
      city: { name: cityName, stateAbbreviatedName },
    } = shippingAddress

    return ` ${street} Nº ${number}, ${neighborhood}, ${
      complement ? ` ${complement}, ` : ''
    } ${
      referencePoint ? ` ${referencePoint}, ` : ''
    } ${cityName} ${stateAbbreviatedName} - CEP ${postalCode}`
  }, [purchaseDetails])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleClose = useCallback(() => {
    reset()
    reload()
    onCancel()
  }, [onCancel, reload, reset])

  const onClose = useCallback(() => {
    reset()
    onCancel()
  }, [onCancel, reset])

  const onSubmit = useCallback(
    async (data: FormRecollectProcessing) => {
      try {
        if (!purchaseDetails) {
          throw new Error('Purchase details not found')
        }

        const { unitPrice, seller, comment, paymentMethod, shippingMethod } =
          data

        const {
          clientId,
          isCobranding,
          storeProduct,
          recollectCode,
          documentNumber,
          shippingAddress,
          companyClinicId,
          purchaseCategoriesIds,
        } = purchaseDetails

        const responseShoppingCart = await apiPost<any, CheckoutType>(
          '/app/purchase/backoffice/shopping_cart',
          {
            clientUserId: clientId,
            paymentMethodId: data.paymentMethod.value,
            shippingMethodId: data.shippingMethod.value,
            shippingAddressId: get(shippingAddress, 'id'),
            postalCode: get(shippingAddress, 'postalCode'),
            purchaseProducts: [
              {
                unitPrice,
                quantity: 1,
                storeProductId: get(storeProduct, 'id'),
              },
            ],
          }
        )

        const response = await apiPost(
          '/app/purchase/backoffice/one_step_checkout',
          {
            isCobranding,
            recollectCode,
            documentNumber,
            comments: comment,
            sellerId: seller.value,
            purchaseType: 'RECOLLECT',
            paymentMethodId: paymentMethod.value,
            clientId: companyClinicId || clientId,
            shippingMethodId: shippingMethod.value,
            purchaseCategoryIds: purchaseCategoriesIds,
            totalPrice: responseShoppingCart?.totalPrice,
            totalDiscount: responseShoppingCart?.totalDiscount,
            shippingAddressId: get(shippingAddress, 'id'),
            deliveryTimeInDays: responseShoppingCart?.deliveryTimeInDays,
            purchaseProducts: [
              {
                unitPrice,
                quantity: 1,
                storeProductId: get(storeProduct, 'id'),
              },
            ],
            shoppingCartData: {
              totalPrice: responseShoppingCart?.totalPrice,
              totalDiscount: responseShoppingCart?.totalDiscount,
              totalPriceB2c: responseShoppingCart?.totalPriceB2c,
              totalPriceB2b: responseShoppingCart?.totalPriceB2b,
              storeProductsList: responseShoppingCart?.storeProductsList,
              deliveryTimeInDays: responseShoppingCart?.deliveryTimeInDays,
              totalPriceWithoutDiscount:
                responseShoppingCart?.totalPriceWithoutDiscount,
            },
          }
        )

        setPaymentLink(get(response, 'paymentLink', ''))
        setShowSuccess((oldState) => ({
          ...oldState,
          [kebabCase(paymentMethod.label)]: true,
        }))
      } catch (error) {
        handleError(error)
      }
    },
    [handleError, purchaseDetails]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Novo pedido de recoleta</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          {showSuccess.bonificado && <SuccessSubsidized />}

          {showSuccess['link-de-pagamento'] && (
            <SuccessPaymentLink paymentLink={paymentLink} />
          )}

          {showSuccess['maquina-de-cartao'] && <SuccessLittleMachine />}

          {!Object.values(showSuccess).includes(true) && (
            <>
              {get(purchaseDetails, 'historyUpdate.comments') && (
                <>
                  <Text>Solicitação de recoleta:</Text>
                  <Box mb="6" bg="#f6f6f6" p="6">
                    <Text textAlign="center">
                      {get(purchaseDetails, 'historyUpdate.comments')}
                    </Text>
                  </Box>
                </>
              )}

              <Text color="gray.500" mb="4">
                Para realizar uma nova recoleta, criaremos um novo pedido para:
              </Text>

              <Text color="gray.500" mb="4">
                1 unidade do {get(purchaseDetails, 'storeProduct.mktTitle')}{' '}
                para {buyerName || 'sem dados do comprador'} com envio para
                {formattedShippingAddress}
              </Text>

              <Stack>
                <FieldNumber
                  control={control}
                  name="unitPrice"
                  label="Campo aberto do valor"
                  error={errors.unitPrice}
                  rules={{
                    required: 'Por favor, selecione um valor',
                  }}
                  isRequired
                />

                <FieldTextArea
                  isRequired
                  label="Comentário"
                  error={errors.comment}
                  placeholder="Digite um comentário..."
                  {...register('comment', {
                    required: 'Por favor, preencha o campo de comentário',
                  })}
                />

                <Controller
                  name="paymentMethod"
                  control={control}
                  rules={{
                    required: 'Por favor, selecione um método de pagamento',
                  }}
                  render={({ field }) => (
                    <FieldSelect
                      label="Forma de pagamento"
                      error={errors.paymentMethod as FieldError}
                      isLoading={selectPaymentMethods.isLoading}
                      options={selectPaymentMethods.options}
                      isRequired
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="shippingMethod"
                  control={control}
                  rules={{
                    required: 'Por favor, selecione um método de envio',
                  }}
                  render={({ field }) => (
                    <FieldSelect
                      label="Forma de envio"
                      error={errors.shippingMethod as FieldError}
                      isLoading={selectShippingMethods.isLoading}
                      options={selectShippingMethods.options}
                      isRequired
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="seller"
                  control={control}
                  rules={{
                    required: 'Por favor, selecione um vendedor',
                  }}
                  render={({ field }) => (
                    <FieldSelect
                      label="Vendedor"
                      error={errors.seller as FieldError}
                      isLoading={selectSellers.isLoading}
                      options={selectSellers.options}
                      isRequired
                      {...field}
                    />
                  )}
                />
              </Stack>

              <Text fontSize="sm" color="orange.500" mt="4" fontStyle="italic">
                Um pedido de recoleta não poderá ser cancelado. Este kit será
                marcado como recoleta, e o cliente final poderá ver que esta
                operação foi realizada.
              </Text>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            {Object.values(showSuccess).includes(true) ? (
              <Button colorScheme="blue" onClick={handleClose}>
                OK
              </Button>
            ) : (
              <>
                <Button onClick={onClose}>Cancelar</Button>
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                >
                  Confirmar
                </Button>
              </>
            )}
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
