import React, { useCallback, useMemo } from 'react'

import { NotificationStatus } from 'contexts/notification/types'

import { NotificationCard } from 'components/Notification/NotificationCard'

import { Box, Divider, Stack, Text } from '@chakra-ui/react'
import { useNotification } from 'contexts/notification'
import { Footer } from '../Footer'

export const TabArchive = () => {
  const { notifications, nextPage, prevPage } = useNotification()

  const notificationsArchive = useMemo(() => {
    if (!notifications) {
      return null
    }

    const draft = notifications[NotificationStatus.ARCHIVED]

    if (!draft) {
      return null
    }

    return draft.data
  }, [notifications])

  const meta = useMemo(() => {
    if (!notifications) {
      return []
    }

    const draft = notifications[NotificationStatus.ARCHIVED]

    if (!draft) {
      return []
    }

    return [draft.meta]
  }, [notifications])

  const handleNextPage = useCallback(() => {
    nextPage(NotificationStatus.ARCHIVED)
  }, [nextPage])

  const handlePrevPage = useCallback(() => {
    prevPage(NotificationStatus.ARCHIVED)
  }, [prevPage])

  return (
    <Box>
      <Stack divider={<Divider />}>
        {!notificationsArchive ? (
          <Text
            pt="6"
            pb="3"
            display="flex"
            fontSize="xs"
            fontWeight="thin"
            fontStyle="italic"
            alignItems="center"
            justifyContent="center"
          >
            Nenhuma mensagem arquivada
          </Text>
        ) : (
          notificationsArchive.map((notification) => (
            <NotificationCard key={notification.id} data={notification} />
          ))
        )}
      </Stack>

      <Footer
        meta={meta}
        onNextPage={handleNextPage}
        onPrevPage={handlePrevPage}
      />
    </Box>
  )
}
