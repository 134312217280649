import axios from 'axios'
import { useError } from 'hooks/useError'
import { find } from 'lodash'
import { FormCheckoutSubmit } from 'pages/private/Checkout/types'
import React, { createContext, useContext, useState } from 'react'
import { apiPost } from 'services/post'
import { CartContextValues, CheckoutType, ShoppingCart } from './interface'
import { useFeedback } from 'contexts/feedback'

const CartContext = createContext<CartContextValues>({} as CartContextValues)

type CartContextProviderProps = {
  children: React.ReactNode
}

export const CartContextProvider = ({ children }: CartContextProviderProps) => {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { handleError } = useError()
  const { feedback } = useFeedback()

  const [isFetching, setIsFetching] = useState(false)
  const [cart, setCart] = useState<ShoppingCart>({} as ShoppingCart)
  const [checkout, setCheckout] = useState<CheckoutType | undefined>(undefined)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  // shopping cart
  const getShoppingCart = async (
    shoppingCart: ShoppingCart,
    makeCartCalculation?: boolean
  ) => {
    try {
      setIsFetching(true)

      if (!shoppingCart?.purchaseProducts?.length) {
        throw new Error(
          'Ops... Parece que você não tem nenhum produto no carrinho. Por favor, adicione algum produto.'
        )
      }

      if (!makeCartCalculation) {
        shoppingCart.postalCode = undefined
      }
      const response = await apiPost<ShoppingCart, CheckoutType>(
        '/app/purchase/backoffice/shopping_cart',
        {
          ...shoppingCart,
        }
      )

      setCheckout(response)

      return response
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      setIsFetching(false)
    }
  }

  // products
  const addProductToCart = async (position: number, productId: string) => {
    try {
      const newCart = { ...cart }
      const newPurchaseProducts = [...(newCart.purchaseProducts || [])]

      const product = find(newPurchaseProducts, { position })

      if (product) {
        newPurchaseProducts[position].storeProductId = productId
        newPurchaseProducts[position].unitPrice = undefined
      } else {
        newPurchaseProducts.push({
          position,
          storeProductId: productId,
          quantity: 1,
        })
      }

      newCart.purchaseProducts = newPurchaseProducts

      let response
      // const response = await getShoppingCart(newCart)

      setCart(newCart)

      return { position, data: response }
    } catch (error: any) {
      handleError(error)
    }
  }

  const removeProductFromCart = async (position: number) => {
    try {
      const newCart = { ...cart }
      const newPurchaseProducts = [...(newCart.purchaseProducts || [])]
      const productToRemove = find(newPurchaseProducts, {
        position,
      })

      if (!productToRemove) return

      newPurchaseProducts.splice(
        newPurchaseProducts.indexOf(productToRemove),
        1
      )

      newCart.purchaseProducts = newPurchaseProducts

      if (newPurchaseProducts.length === 0) {
        setCheckout(undefined)
      } else {
        // await getShoppingCart(newCart)
      }

      setCart(newCart)
    } catch (error: any) {
      handleError(error)
    }
  }

  const updateProductPrice = async (position: number, price: number) => {
    try {
      const newCart = { ...cart }
      const newPurchaseProducts = [...(newCart.purchaseProducts || [])]
      const product = find(newPurchaseProducts, { position })

      if (!product) throw new Error('Produto não encontrado')

      product.unitPrice = price

      let response

      // const response = await getShoppingCart(newCart)

      setCart(newCart)

      return { position, data: response }
    } catch (error: any) {
      handleError(error)
    }
  }

  const updateProductQuantity = async (position: number, quantity: number) => {
    try {
      const newCart = { ...cart }
      const newPurchaseProducts = [...(newCart.purchaseProducts || [])]
      const product = find(newPurchaseProducts, { position })

      if (!product) throw new Error('Produto não encontrado')

      product.quantity = quantity

      let response

      // const response = await getShoppingCart(newCart)

      setCart(newCart)

      return { position, data: response }
    } catch (error: any) {
      handleError(error)
    }
  }

  const toggleApplyCoupon = async (
    position: number,
    hasApplyCoupon: boolean
  ) => {
    try {
      const newCart = { ...cart }
      const newPurchaseProducts = [...(newCart.purchaseProducts || [])]
      const product = find(newPurchaseProducts, { position })

      if (!product) throw new Error('Produto não encontrado')

      product.applyCoupon = hasApplyCoupon

      const response = await getShoppingCart(newCart)

      setCart(newCart)

      return { position, data: response }
    } catch (error: any) {
      handleError(error)
    }
  }

  // postalcode
  const addPostalCodeToCart = async (postalCode: string) => {
    try {
      const newCart = { ...cart }
      const newPostalCode = newCart.postalCode

      if (newPostalCode === postalCode) return

      newCart.postalCode = postalCode

      let response

      // if (newCart.shippingMethodId) {
      //   response = await getShoppingCart(newCart)
      // }

      setCart(newCart)

      return response
    } catch (error: any) {
      handleError(error)
    }
  }

  // coupon
  const addCouponToCart = async (voucherCode: string) => {
    try {
      const newCart = { ...cart }
      const newVoucherCodes = [...(newCart.voucherCodes || [])]

      newVoucherCodes.push(voucherCode)

      newCart.voucherCodes = newVoucherCodes

      const response = await getShoppingCart(newCart)

      setCart(newCart)

      return response
    } catch (error: any) {
      handleError(error)
    }
  }

  const removeCouponFromCart = async (voucherCode: string) => {
    try {
      const newCart = { ...cart }
      const newVoucherCodes = [...(newCart.voucherCodes || [])]
      const voucherToRemove = newVoucherCodes.findIndex(
        (newVoucherCode) => newVoucherCode === voucherCode
      )

      if (voucherToRemove === -1) return

      newVoucherCodes.splice(voucherToRemove, 1)

      newCart.voucherCodes = newVoucherCodes

      const response = await getShoppingCart(newCart)

      setCart(newCart)

      return response
    } catch (error: any) {
      handleError(error)
    }
  }

  // payment method
  const addPaymentMethodToCart = async (paymentMethodId: string) => {
    try {
      const newCart = { ...cart }
      const newPaymentMethodId = newCart.paymentMethodId

      if (newPaymentMethodId === paymentMethodId) return

      newCart.paymentMethodId = paymentMethodId

      const response = await getShoppingCart(newCart)

      setCart(newCart)

      return response
    } catch (error: any) {
      handleError(error)
    }
  }

  // shipping method
  const addShippingMethodToCart = async (shippingMethodId: string) => {
    try {
      const newCart = { ...cart }
      const newShippingMethodId = newCart.shippingMethodId

      if (newShippingMethodId === shippingMethodId) return

      newCart.shippingMethodId = shippingMethodId

      let response

      if (newCart.postalCode) {
        response = await getShoppingCart(newCart)
      }

      setCart(newCart)

      return response
    } catch (error: any) {
      handleError(error)
    }
  }

  //
  const makeOrder = async (data: FormCheckoutSubmit) => {
    const response = await apiPost(
      '/app/purchase/backoffice/one_step_checkout',
      {
        ...data,
        clientId: data.professionalId || data.clientId,
        clientUserId: data?.companyClinicId ? undefined : data.clientId,
        totalPrice: checkout?.totalPrice,
        companyClinicId: data?.companyClinicId,
        totalDiscount: checkout?.totalDiscount,
        deliveryTimeInDays: checkout?.deliveryTimeInDays,
        shoppingCartData: {
          totalPrice: checkout?.totalPrice,
          totalDiscount: checkout?.totalDiscount,
          totalPriceB2c: checkout?.totalPriceB2c,
          totalPriceB2b: checkout?.totalPriceB2b,
          storeProductsList: checkout?.storeProductsList,
          deliveryTimeInDays: checkout?.deliveryTimeInDays,
          totalPriceWithoutDiscount: checkout?.totalPriceWithoutDiscount,
        },
      }
    )

    return response
  }

  const makeCartCalculation = async () => {
    try {
      return await getShoppingCart(cart, true)
    } catch (error: any) {
      handleError(error)
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <CartContext.Provider
      value={{
        checkout,
        addCouponToCart,
        addProductToCart,
        addPostalCodeToCart,
        addPaymentMethodToCart,
        addShippingMethodToCart,
        removeCouponFromCart,
        removeProductFromCart,
        updateProductPrice,
        updateProductQuantity,
        makeOrder,
        makeCartCalculation,
        toggleApplyCoupon,
        isFetching,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export function useCart() {
  const context = useContext(CartContext)
  return context
}
