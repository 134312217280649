// Vendors
import { VStack } from '@chakra-ui/react'
import React from 'react'
import { v4 } from 'uuid'

// Components
import { FormFeedbackItem, IFormFeedbackItem } from '../FormFeedbackItem'

// Styles

// Interfaces
export interface IFormFeedback {
  data: IFormFeedbackItem[]
  setData: any
}

export const FormFeedback: React.FC<IFormFeedback> = (props) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { data, setData } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <VStack>
      {data.map((item) => (
        <FormFeedbackItem
          key={`${v4()}`}
          title={item.title}
          content={item.content}
          type={item.type}
          layout={item?.layout}
          setData={setData}
        />
      ))}
    </VStack>
  )
}
