// Vendors
import { get } from 'lodash'
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { Controller, FieldError, useFormContext } from 'react-hook-form'

// Functions
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FieldDate } from 'components/FieldDate'
import { FieldText } from 'components/FieldText'
// import { TextEditor } from 'components/TextEditor'
import { FieldSelect } from 'components/FieldSelect'
import { FieldToggle } from 'components/FieldToggle'
import { FieldNoImage } from 'components/FieldNoImage'
import { FieldTextArea } from 'components/FieldTextArea'
import { FieldSelectColor } from 'components/FieldSelectColor'
// Styles
import { Box, Grid, Text, Image, GridItem } from '@chakra-ui/react'
import BlogBannerExample from 'assets/images/blog_banner.png'

// Types
import { AccentColorEnum } from 'types/Blog'
import { BlogNewForm } from '../new/types'

const TextEditor = React.lazy(() => import('components/TextEditor'))

type TabNewPostProps = {
  setTextEditorData: Dispatch<SetStateAction<string>>
}

export const TabNewPost = (props: TabNewPostProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { options: optionsCategories, isLoading: isLoadingCategories } =
    useSelectParser({
      accessor: { label: 'title', value: 'id' },
      endpoint: '/public/store_product_category',
    })

  const { options: optionsTags, isLoading: isLoadingTags } = useSelectParser({
    accessor: { label: 'name', value: 'id' },
    endpoint: '/app/store_product_tag',
  })

  const accentColorOptions = useMemo(() => {
    return Object.entries(AccentColorEnum).map(([key, value]) => ({
      label: value,
      value: key,
    }))
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { setTextEditorData } = props

  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext<BlogNewForm>()

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tooltipFeatured = useCallback(() => {
    return (
      <Box>
        <Image src={BlogBannerExample} alt="blog banner example" />

        <Text padding="4">
          Use este campo para dar destaque a esse post no header do blog. Apenas
          o post mais recente marcado como favorito aparecerá no destaque.
        </Text>
      </Box>
    )
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Grid
      my="4"
      gap="10"
      templateColumns={{ base: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
    >
      <GridItem colSpan={2}>
        <Controller
          name="featured"
          control={control}
          defaultValue={true}
          render={({ field: { onChange, value, ref, name } }) => (
            <FieldToggle
              label="Favorito"
              name={name}
              ref={ref}
              tooltipProps={{
                label: tooltipFeatured(),
                padding: 0,
              }}
              isChecked={value}
              onChange={onChange}
              error={errors.status}
            />
          )}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <FieldText
          maxLength={255}
          currentLength={get(watch('title'), 'length')}
          label="Título"
          error={errors.title}
          isRequired
          {...register('title')}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <FieldText
          maxLength={255}
          currentLength={get(watch('subtitle'), 'length')}
          label="Linha fina"
          error={errors.subtitle}
          {...register('subtitle')}
          isRequired
        />
      </GridItem>

      <GridItem colSpan={2}>
        <FieldText
          maxLength={120}
          currentLength={get(watch('articleAuthor'), 'length')}
          label="Autor"
          error={errors.articleAuthor}
          {...register('articleAuthor')}
          isRequired
        />
      </GridItem>

      <GridItem colSpan={2}>
        <FieldTextArea
          maxLength={280}
          currentLength={get(watch('articleLead'), 'length')}
          label="Resumo do post"
          error={errors.articleLead}
          isRequired
          {...register('articleLead')}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <Controller
          name="accentColor"
          control={control}
          render={({ field }) => (
            <FieldSelectColor
              label="Cores"
              error={errors.accentColor as FieldError}
              options={accentColorOptions}
              isRequired
              {...field}
            />
          )}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <Controller
          name="tagIds"
          control={control}
          render={({ field }) => (
            <FieldSelect
              isMulti
              label="Tags vinculadas"
              error={errors.tagIds as FieldError}
              isLoading={isLoadingTags}
              options={optionsTags}
              isRequired
              {...field}
            />
          )}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <Controller
          name="categoryId"
          control={control}
          render={({ field }) => (
            <FieldSelect
              label="Categoria"
              error={errors.categoryId as FieldError}
              isLoading={isLoadingCategories}
              options={optionsCategories}
              isRequired
              {...field}
            />
          )}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <Controller
          name="publishingDate"
          control={control}
          render={({ field }) => (
            <FieldDate
              dateFormat="dd/MM/yyyy"
              label="Data de publicação"
              minDate={new Date()}
              name={field.name}
              dropdownMode="select"
              selected={field.value}
              onChange={field.onChange}
              error={errors.publishingDate as FieldError}
              isRequired
            />
          )}
        />
      </GridItem>

      <GridItem colSpan={{ base: 2, xl: 4 }}>
        <TextEditor setTextEditorData={setTextEditorData} />
      </GridItem>

      <GridItem colSpan={{ base: 2, lg: 4 }}>
        <FieldNoImage label="Imagem de destaque" />
      </GridItem>

      <GridItem colSpan={{ base: 2, lg: 4 }}>
        <FieldNoImage label="Imagem complementar" />
      </GridItem>
    </Grid>
  )
}
