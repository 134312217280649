import React from 'react'

import { FiCheck } from 'react-icons/fi'
import { Flex, Text, Stack, Circle, Heading } from '@chakra-ui/react'

export const SuccessSubsidized = () => {
  return (
    <Flex justifyContent="center" flexDirection="column" pb="4">
      <Stack alignItems="center">
        <Circle size="12" bg="blue.100" mb="1.5rem !important">
          <FiCheck size="24" />
        </Circle>

        <Heading fontWeight="medium" fontSize="lg" color="gray.900">
          Pedido realizado com sucesso!
        </Heading>

        <Text fontSize="sm" color="gray.500" textAlign="center" w="80">
          Os pedidos bonificados não exigem pagamento, nenhuma ação necessária
        </Text>
      </Stack>
    </Flex>
  )
}
