import { useCallback } from 'react'
import { apiList } from 'services/get'
import { useSelectParser } from 'hooks/useSelectParser'

export const useAddress = () => {
  const selectStates = useSelectParser({
    endpoint: '/app/state',
    accessor: { label: 'abbreviatedName', value: 'id' },
    requestWithoutMeta: true,
  })

  const getState = useCallback(
    (state: string) => {
      const options = selectStates.options

      if (!options || !options.length) return

      const stateFilter = options.find((option) => option.label === state)

      return stateFilter
    },
    [selectStates.options]
  )

  const getCity = useCallback(async (city: string) => {
    const cities = await apiList<{ id: string; name: string }[]>(
      `/app/city?q=${city}`
    )

    if (!cities || !cities.length) return

    const cityFilter = cities.find((option) => option.name === city)

    if (!cityFilter) return

    return {
      value: cityFilter.id,
      label: cityFilter.name,
    }
  }, [])

  return {
    getState,
    getCity,
  }
}
