// Vendors
import React from 'react'
import { Controller, FieldError, useForm } from 'react-hook-form'

// Hooks
import { useError } from 'hooks/useError'

// Components
import { FieldSelect } from 'components/FieldSelect'
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import {
  Stack,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react'
import { apiPatch } from 'services/patch'

export enum KitStatus {
  KIT_ACTIVATED = 'Kit ativado',
  KIT_RECEIVED = 'Kit recebido',
  IN_ANALYSIS = 'Em análise',
  REPORT_GENERATED = 'Laudo gerado',
  KIT_ACCEPTED = 'Kit aceito',
  SAMPLE_DENIED = 'Amostra negada',
  DIVERGENT_DATA = 'Dados divergentes',
  PENDING_REVERSE_CODE = 'Código de reverso pendente',
}

type ModalAlterKitStatusProps = {
  isOpen: boolean
  reload: () => void
  onCancel: () => void
  activationCode: string
}

type FormAlterKitStatus = {
  status: {
    label: string
    value: string
  }
  comment: string
}

export const ModalAlterKitStatus = (props: ModalAlterKitStatusProps) => {
  const selectKitStatus = Object.entries(KitStatus).map(([value, label]) => ({
    label,
    value,
  }))

  const { isOpen, reload, onCancel, activationCode } = props

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormAlterKitStatus>()

  const { handleError } = useError()

  const onClose = React.useCallback(() => {
    reset()
    onCancel()
  }, [onCancel, reset])

  const onSubmit = React.useCallback(
    async (data: FormAlterKitStatus) => {
      try {
        const {
          comment,
          status: { value: statusValue },
        } = data

        await apiPatch(`/app/kit/${activationCode}/status`, {
          status: statusValue,
          comment,
        })

        reload()
        onClose()
      } catch (error) {
        handleError(error)
      }
    },
    [activationCode, handleError, onClose, reload]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Alterar status do kit</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Stack>
            <Controller
              control={control}
              name="status"
              rules={{
                required: 'Por favor, selecione um status',
              }}
              render={({ field }) => (
                <FieldSelect
                  label="Escolha um status"
                  error={errors.status as FieldError}
                  options={selectKitStatus}
                  placeholder="Selecione um status..."
                  {...field}
                />
              )}
            />

            <FieldTextArea
              label="Comentários"
              error={errors.comment}
              placeholder="Digite algum comentário"
              {...register('comment', {
                required: 'Por favor, preencha o campo comentários',
              })}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose}>Cancelar</Button>
            <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
