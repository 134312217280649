export enum UserRolesNames {
  'SUPERADMIN' = 'SUPERADMIN',
  'ADMIN' = 'ADMIN',
  'FINANCIAL' = 'FINANCIAL',
  'BACKOFFICE' = 'BACKOFFICE',
  'SELLER' = 'SELLER',
  'PROFESSIONAL' = 'PROFESSIONAL',
  'CLIENT' = 'CLIENT',
  'EXPEDITION' = 'EXPEDITION',
  'LOGISTIC_MANAGER' = 'LOGISTIC_MANAGER',
  'MOTORCYCLE_COURIER' = 'MOTORCYCLE_COURIER',
  'LABORATORY' = 'LABORATORY',
  'INFLUENCER' = 'INFLUENCER',
  'LABORATORY_MANAGER' = 'LABORATORY_MANAGER',
  'LABORATORY_AGENT' = 'LABORATORY_AGENT',
  'CONTENT_MANAGER' = 'CONTENT_MANAGER',
}
