// Vendors
import React from 'react'

// Components
import { CardStatSimple, CardStatSimpleProps } from 'components/CardStatSimple'

// Styles
import { Stack } from '@chakra-ui/react'

// Interfaces
export type CardStatSimpleListProps = {
  data: CardStatSimpleProps[]
}

export const CardStatSimpleList = (
  props: CardStatSimpleListProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { data } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Stack
      my="4"
      w="full"
      spacing="6"
      direction={{ base: 'column', md: 'row' }}
    >
      {data.map((item, index) => (
        <CardStatSimple key={index} {...item} />
      ))}
    </Stack>
  )
}
