import { FILE_SIZE, FILE_SIZE_BYTES } from 'constants/constants'
import { TFunction } from 'react-i18next'
import { yupSchema } from 'utils/yupSchema'

import * as yup from 'yup'

export const schema = (t: TFunction<'common'>) => {
  yupSchema(t)

  return yup.object().shape({
    name: yup.string().required().label(t('form.company.name.label')),

    documentNumber: yup
      .string()
      .getCharacters()
      .length(14)
      .required()
      .label(t('form.company.documentNumber.label')),

    street: yup.string().required().label(t('form.company.street.label')),

    number: yup.string().required().label(t('form.company.number.label')),

    cityId: yup
      .string()
      .required()
      .selectValue()
      .label(t('form.company.cityId.label')),

    stateId: yup
      .string()
      .selectValue()
      .required()
      .label(t('form.company.stateId.label')),

    postalCode: yup
      .string()
      .getCharacters()
      .length(8)
      .required()
      .label(t('form.company.postalCode.label')),

    complement: yup
      .string()
      .optional()
      .nullable()
      .label(t('form.company.complement.label')),

    neighborhood: yup
      .string()
      .required()
      .label(t('form.company.neighborhood.label')),

    referencePoint: yup
      .string()
      .optional()
      .nullable()
      .label(t('form.company.referencePoint.label')),

    employees: yup
      .array()
      .selectValues()
      .label(t('form.company.employees.label')),

    cardCnpj: yup
      .mixed()
      .test(
        'fileSize',
        `O arquivo deve ser menor que ${FILE_SIZE_BYTES}MB`,
        (value) => {
          if (!value || !value[0]) return true
          return value[0].size <= FILE_SIZE
        }
      ),

    socialContract: yup
      .mixed()
      .test(
        'fileSize',
        `O arquivo deve ser menor que ${FILE_SIZE_BYTES}MB`,
        (value) => {
          if (!value || !value[0]) return true
          return value[0].size <= FILE_SIZE
        }
      ),

    clinicalSpecialty: yup
      .string()
      .required()
      .label(t('form.company.clinicalSpecialty.label')),

    clinicType: yup
      .string()
      .required()
      .selectValue()
      .label(t('form.company.clinicType.label')),
  })
}
