// Vendors
import React from 'react'
import { FieldError } from 'react-hook-form'

// Components

// Styles
import {
  Text,
  FormControl,
  FormLabel,
  Textarea,
  TextareaProps,
  FormErrorMessage,
  Flex,
} from '@chakra-ui/react'
import { useFormHelperText } from 'hooks/useFormHelperText'
import { Tooltip, TooltipProps } from 'components/Tooltip'

// Interfaces
export interface IFieldTextArea extends TextareaProps {
  name: string
  label?: string
  error?: FieldError
  canModify?: boolean
  currentLength?: number
  helperText?:
    | string
    | {
        text: string
        color?: string
      }
  tooltipProps?: TooltipProps
  isRequired?: boolean
}

const FieldTextAreaBase: React.ForwardRefRenderFunction<
  HTMLTextAreaElement,
  IFieldTextArea
> = (props, ref) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const {
    name,
    label,
    error,
    helperText,
    canModify = true,
    currentLength = 0,
    tooltipProps,
    isRequired,
    ...rest
  } = props
  const componentHelperText = useFormHelperText({ helperText, canModify })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormControl id="text" isInvalid={!!error}>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        {!!label && (
          <FormLabel htmlFor={name} m={0}>
            {label}
            {isRequired && (
              <Text
                as="span"
                color="red.500"
                ml={1}
                fontWeight="bold"
                userSelect="none"
              >
                *
              </Text>
            )}
          </FormLabel>
        )}

        {tooltipProps && Object.keys(tooltipProps).length && (
          <Tooltip {...tooltipProps} verticalAlign="middle" />
        )}
      </Flex>

      {rest.maxLength !== undefined && !!currentLength && (
        <Text
          as="span"
          top="1"
          right="0"
          fontSize="sm"
          color="gray.500"
          position="absolute"
          letterSpacing="wide"
        >
          {String(currentLength).padStart(2, '0')}/{rest.maxLength}
        </Text>
      )}

      <Textarea id={name} name={name} ref={ref} {...rest} />

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      {(helperText || !canModify) && !error && componentHelperText}
    </FormControl>
  )
}

export const FieldTextArea = React.forwardRef(FieldTextAreaBase)
