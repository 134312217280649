// Vendors
import { v4 } from 'uuid'
import React, { useCallback } from 'react'
import { Control, Controller, RegisterOptions } from 'react-hook-form'

// Styles
import {
  Stack,
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  Text,
} from '@chakra-ui/react'
import { useFormHelperText } from 'hooks/useFormHelperText'

// Interfaces
type RadioButtonOption = {
  label: string
  value?: string | number
}

export interface IFieldRadioButton {
  name?: string
  label?: string
  defaultValue?: string
  control?: Control<any>
  options: Array<RadioButtonOption>
  radioButtonDirection?: 'row' | 'column'
  isDisabled?: boolean
  canModify?: boolean
  helperText?:
    | string
    | {
        text: string
        color?: string
      }
  rules?: Omit<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >
  isRequired?: boolean
}

export const FieldRadioButton: React.FC<IFieldRadioButton> = (props) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const {
    name,
    label,
    rules,
    options,
    control,
    isDisabled,
    helperText,
    defaultValue,
    canModify = true,
    radioButtonDirection = 'column',
    isRequired,
  } = props

  const componentHelperText = useFormHelperText({ helperText, canModify })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const renderControllerRadioButton = useCallback(() => {
    if (!control || !name) return

    return (
      <Controller
        name={name}
        rules={rules}
        control={control}
        defaultValue={defaultValue || options[0].value}
        render={({ field }) => (
          <RadioGroup value={field.value}>
            <Stack direction={radioButtonDirection}>
              {options.map(({ label, value }) => {
                return (
                  <Radio
                    isDisabled={isDisabled}
                    key={v4()}
                    value={value}
                    ref={field.ref}
                    onChange={field.onChange}
                  >
                    {label}
                  </Radio>
                )
              })}
            </Stack>
          </RadioGroup>
        )}
      />
    )
  }, [
    control,
    name,
    rules,
    defaultValue,
    options,
    radioButtonDirection,
    isDisabled,
  ])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormControl>
      {!!label && (
        <FormLabel>
          {label}
          {isRequired && (
            <Text
              as="span"
              color="red.500"
              ml={1}
              fontWeight="bold"
              userSelect="none"
            >
              *
            </Text>
          )}
        </FormLabel>
      )}

      {control ? (
        renderControllerRadioButton()
      ) : (
        <RadioGroup defaultValue={options[0]?.value}>
          <Stack direction={radioButtonDirection}>
            {options.map((option) => (
              <Radio key={v4()} isDisabled={isDisabled} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      )}
      {(helperText || !canModify) && componentHelperText}
    </FormControl>
  )
}
