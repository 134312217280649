import React from 'react'

import { chakra, HTMLChakraProps } from '@chakra-ui/react'

export type IconProps = HTMLChakraProps<'svg'>

export const PinOutline = (props: IconProps) => {
  return (
    <chakra.svg
      width="10px"
      height="10px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m7.016.427 2.557 2.557a1.457 1.457 0 0 1-.378 2.333l-2.58 1.29a.397.397 0 0 0-.199.226L5.653 9.04a.662.662 0 0 1-1.094.253L2.914 7.648.562 10H0v-.563l2.353-2.351L.707 5.44A.662.662 0 0 1 .96 4.347l2.207-.763a.397.397 0 0 0 .226-.198l1.29-2.58A1.457 1.457 0 0 1 7.017.427Zm1.996 3.119L6.455.989a.662.662 0 0 0-1.061.172l-1.29 2.58c-.14.28-.383.492-.677.594l-2.008.694 3.553 3.552.693-2.007c.101-.295.314-.538.593-.677l2.582-1.29a.662.662 0 0 0 .172-1.061Z"
        fill="#5D5D5D"
      />
    </chakra.svg>
  )
}
