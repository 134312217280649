import { TFunction } from 'react-i18next'
import { yupSchema } from 'utils/yupSchema'

import * as yup from 'yup'

export const schema = (t: TFunction<'seller'>) => {
  yupSchema(t)

  return yup.object().shape({
    name: yup.string().required().label(t('form.name.label')),

    email: yup.string().email().required().label(t('form.email.label')),

    phoneNumber: yup
      .string()
      .transform((value) => `+55${value.replace(/\D/g, '')}`)
      .required()
      .label(t('form.phoneNumber.label')),

    documentNumber: yup
      .string()
      .getCharacters()
      .length(11)
      .required()
      .label(t('form.documentNumber.label')),

    birthdate: yup.date().required().label(t('form.birthdate.label')),

    gender: yup.string().required().label(t('form.genre.label')),

    companyId: yup
      .string()
      .selectValue()
      .required()
      .label(t('form.companyId.label')),

    status: yup.string().getStatus().label(t('form.genre.label')),

    roleName: yup.string().default('SELLER'),
  })
}
