// Vendors
import React from 'react'

// Components
import { BarCodeScanner } from 'components/BarCodeScanner'

// Styles
import {
  Flex,
  Text,
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  CircularProgress,
} from '@chakra-ui/react'

// Interfaces
export type ModalBarCodeScannerProps = {
  isOpen: boolean
  isLoading?: boolean
  onClose: () => void
  onScan: (data: string) => void
}

export const ModalBarCodeScanner = (
  props: ModalBarCodeScannerProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { onScan, isOpen, onClose, isLoading = false } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Escaneie o código</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex h="520" justifyContent="center" alignItems="center">
            {isLoading ? (
              <>
                <CircularProgress
                  mr="2"
                  size="20px"
                  isIndeterminate
                  color="blue.500"
                />
                <Text>Validando ID...</Text>
              </>
            ) : (
              <BarCodeScanner onScan={onScan} />
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
