import { useRadioGroup, UseRadioGroupProps, VStack } from '@chakra-ui/react'
import { SelectType } from 'hooks/useSelectParser'
import React, { forwardRef, ForwardRefRenderFunction } from 'react'
import { RadioCard } from './RadioCard'

type RadioButtonCardGroupProps = UseRadioGroupProps & {
  options:
    | SelectType<any>[]
    | {
        label: string
        value: string
      }[]
}

const RadioButtonCardGroupBase: ForwardRefRenderFunction<
  any,
  RadioButtonCardGroupProps
> = (props, ref) => {
  const { options, ...rest } = props

  const { getRootProps, getRadioProps } = useRadioGroup({ ...rest })

  const group = getRootProps()

  return (
    <VStack w={{ lg: 'container.md' }} {...group}>
      {options.map((item) => {
        const radio = getRadioProps({ value: item.value })

        return (
          <RadioCard key={item.value} {...radio} ref={ref}>
            {item.label}
          </RadioCard>
        )
      })}
    </VStack>
  )
}

export const FieldRadioButtonCustom = forwardRef(RadioButtonCardGroupBase)
