import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import { kebabCase } from 'lodash'

export function yupSchema(t: TFunction) {
  yup.setLocale({
    array: {
      min: ({ label, min }) => ` ${label} ${t('common:form.array.min')} ${min}`,
    },
    mixed: {
      notType: t('common:form.mixed.notType'),
      required: ({ label }) => `${label} ${t('common:form.mixed.required')}`,
    },
    string: {
      email: ({ label }) => `${label} ${t('common:form.string.email')}`,
      max: ({ label, max }) =>
        `${label} ${t('common:form.string.max', { max })}`,
      length: ({ label, length }) =>
        `${label} ${t('common:form.string.length')} ${length}`,
    },
    number: {
      positive: ({ label }) => `${label} ${t('common:form.number.positive')}`,
      max: ({ label, max }) => `${label} ${t('common:form.number.max')} ${max}`,
    },
  })

  yup.addMethod(yup.string, 'kebabCase', function () {
    return this.transform((item) => kebabCase(item))
  })

  yup.addMethod(yup.mixed, 'selectValue', function () {
    return this.transform(
      (item: { label: string; value: string | number }) => item.value
    )
  })

  yup.addMethod(yup.mixed, 'selectValues', function () {
    return this.transform(
      (items: { label: string; value: string | number }[]) => {
        if (!items || items.length === 0) return

        return items?.map((item) => item?.value)
      }
    )
  })

  yup.addMethod(yup.string, 'getStatus', function () {
    return this.transform((item: string) =>
      item === 'true' ? 'ENABLED' : 'DISABLED'
    )
  })

  yup.addMethod(yup.string, 'getCharacters', function () {
    return this.transform((item: string) => String(item).replace(/\D/g, ''))
  })

  yup.addMethod(yup.mixed, 'maxFileSize', function (maxFileSize: number) {
    return this.test(
      'maxFileSize',
      t('common:form.mixed.maxFileSize', {
        maxFileSize: maxFileSize / 1000000,
      }),
      (value) => {
        if (!value || !value[0]) return true
        return value[0].size <= maxFileSize
      }
    )
  })

  yup.addMethod(yup.mixed, 'fileIsRequired', function (label: string) {
    return this.test(
      'fileIsRequired',
      label + ' ' + t('common:form.mixed.required'),
      (value) => {
        return Object.keys(value).length > 0
      }
    )
  })

  yup.addMethod(yup.string, 'isStrongPassword', function () {
    return this.test(
      'Password is strong',
      'Por favor, insira uma senha forte (contendo ao mínimo 8 caracteres, entre letras e números)',
      (item: string | undefined) => {
        if (!item) return false

        const numbers = item.replace(/\D/g, '')
        const letters = item.replace(/\d/g, '')

        const isStrong =
          !!numbers.length &&
          !!letters.length &&
          (numbers + letters).length >= 8

        return isStrong
      }
    )
  })
}
