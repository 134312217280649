// Vendors
import React from 'react'
import { useFormContext } from 'react-hook-form'

// Components
import { FieldRadioButton } from 'components/FieldRadioButton'

// Styles
import { Box } from '@chakra-ui/react'
import { useFormFieldEditor } from '../useFormFieldEditor'
import { FormFieldEditorProps } from '../MedicalRecord'

// Hooks

// Types

export const FormFieldEditorSingleChoice = (props: FormFieldEditorProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { question } = props
  const { control } = useFormContext()

  const {
    rules,
    label,
    name,
    options: { radioOptions },
  } = useFormFieldEditor({ question })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <FieldRadioButton
        name={name}
        label={label}
        rules={rules}
        control={control}
        options={radioOptions}
      />
    </Box>
  )
}
