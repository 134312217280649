import { filter, get, groupBy, slice } from 'lodash'
import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
  useRef,
} from 'react'

import { apiList } from 'services/get'
import { useAuth } from 'contexts/auth'

import {
  NotificationItem,
  NotificationState,
  NotificationStatus,
  NotificationContextValues,
  NotificationContextProviderProps,
} from './types'
import { apiPatch } from 'services/patch'
import { useDisclosure, useOutsideClick } from '@chakra-ui/react'
import { NotificationModal } from 'components/Notification/NotificationModal'

export const NotificationContext = createContext<NotificationContextValues>(
  {} as NotificationContextValues
)

export const NotificationContextProvider = ({
  children,
}: NotificationContextProviderProps) => {
  /* Constants
   *
   *
   *
   *
   * */
  const ref = useRef<HTMLDivElement>(null)

  const { isLoggedIn } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()

  /* States
   *
   *
   *
   *
   * */
  const [notifications, setNotifications] = useState<
    NotificationState | undefined
  >(undefined)
  const [favoriteNotifications, setFavoriteNotifications] = useState<
    NotificationState | undefined
  >(undefined)

  /* Functions
   *
   *
   *
   *
   * */
  const nextPage = useCallback(
    (status: NotificationStatus) => {
      if (!notifications) return

      const draft = get(notifications, status)

      if (!draft) return

      const { meta } = draft

      if (!meta) return

      const { currentPage, perPage, total } = meta

      if (currentPage * perPage >= total) return

      const nextPageNumber = currentPage + 1

      draft.meta.currentPage = nextPageNumber
      draft.meta.hasNextPage = nextPageNumber * perPage < total
      draft.meta.hasPrevPage = nextPageNumber > 1

      draft.data = slice(
        draft.original,
        (nextPageNumber - 1) * perPage,
        nextPageNumber * perPage
      )

      setNotifications({
        ...notifications,
        [status]: draft,
      })
    },
    [notifications]
  )

  const prevPage = useCallback(
    (status: NotificationStatus) => {
      if (!notifications) return

      const draft = notifications[status]

      if (!draft) return

      const { meta } = draft

      if (!meta) return

      const { currentPage, perPage, total } = meta

      if (currentPage === 1) return

      const prevPageNumber = currentPage - 1

      draft.meta.currentPage = prevPageNumber
      draft.meta.hasNextPage = prevPageNumber * perPage < total
      draft.meta.hasPrevPage = prevPageNumber > 1

      draft.data = slice(
        draft.original,
        (prevPageNumber - 1) * perPage,
        prevPageNumber * perPage
      )

      setNotifications({
        ...notifications,
        [status]: draft,
      })
    },
    [notifications]
  )

  const addMeta = useCallback(
    (
      notificationsUnformatted: Record<
        keyof typeof NotificationStatus,
        NotificationItem[]
      >
    ) => {
      if (!notificationsUnformatted) return

      const newNotifications = { ...notificationsUnformatted }

      const notificationsFormatted = Object.entries(newNotifications).reduce(
        (acc, [key, value]) => {
          return {
            ...acc,
            [key]: {
              data: slice(value, 0, 10),
              original: value,
              meta: {
                total: value.length,
                perPage: 10,
                currentPage: 1,
                hasPrevPage: false,
                hasNextPage: value.length > 10,
              },
            },
          }
        },
        {} as NotificationState
      )

      return notificationsFormatted
    },
    []
  )

  const fetchNotifications = useCallback(async () => {
    const response = await apiList<{ data: NotificationItem[] }>(
      '/app/notification',
      { limit: 99999 }
    )

    if (!response || response.data.length === 0) return

    const favorites = filter(response.data, { starred: true })

    const grouped = groupBy(response.data, 'status') as any
    const newGrouped = addMeta(grouped)

    const groupedFavorites = groupBy(favorites, 'status') as any
    const newGroupedFavorites = addMeta(groupedFavorites)

    setNotifications(newGrouped)
    setFavoriteNotifications(newGroupedFavorites)
  }, [addMeta])

  const markAsRead = useCallback(async () => {
    const notificationsUnread = get(notifications, NotificationStatus.UNREAD)

    if (!notificationsUnread) return

    try {
      await Promise.all(
        notificationsUnread.data.map(async (notification) => {
          await apiPatch(`/app/notification/${notification.id}/status`, {
            status: NotificationStatus.READ,
          })
        })
      )
    } catch (error) {
      console.error(error)
    }
  }, [notifications])

  const markAsFavorite = useCallback(async (notificationId: string) => {
    try {
      await apiPatch(`/app/notification/${notificationId}/starred`, {
        starred: true,
      })
    } catch (error) {
      console.error(error)
    }
  }, [])

  const markAsUnFavorite = useCallback(async (notificationId: string) => {
    try {
      await apiPatch(`/app/notification/${notificationId}/starred`, {
        starred: false,
      })
    } catch (error) {
      console.error(error)
    }
  }, [])

  const markAsArchive = useCallback(async (notificationId: string) => {
    try {
      await apiPatch(`/app/notification/${notificationId}/status`, {
        status: NotificationStatus.ARCHIVED,
      })
    } catch (error) {
      console.log(error)
    }
  }, [])

  const markAsUnArchive = useCallback(async (notificationId: string) => {
    try {
      await apiPatch(`/app/notification/${notificationId}/status`, {
        status: NotificationStatus.READ,
      })
    } catch (error) {
      console.log(error)
    }
  }, [])

  const markAllArchive = useCallback(async () => {
    const notificationsUnread = get(notifications, NotificationStatus.UNREAD)
    const notificationsRead = get(notifications, NotificationStatus.READ)

    const notificationsToArchive = [
      ...(notificationsUnread?.data || []),
      ...(notificationsRead?.data || []),
    ]

    if (!notificationsToArchive.length) return

    try {
      await Promise.all(
        notificationsToArchive.map(async (notification) => {
          await apiPatch(`/app/notification/${notification.id}/status`, {
            status: NotificationStatus.ARCHIVED,
          })
        })
      )
    } catch (error) {
      console.log(error)
    }
  }, [notifications])

  const handleOpen = useCallback(async () => {
    onOpen()
    await markAsRead()
  }, [markAsRead, onOpen])

  const handleClose = useCallback(
    async (e?: Event) => {
      const target = get(e, 'target') as HTMLElement
      const classList = get(target, 'classList')

      if (classList && !classList.contains('notification-button')) {
        onClose()
        return
      }

      await fetchNotifications()
    },
    [fetchNotifications, onClose]
  )

  const handleToggle = useCallback(async () => {
    if (isOpen) {
      await handleClose()
    } else {
      await handleOpen()
    }
  }, [handleClose, handleOpen, isOpen])

  /* Memos
   *
   *
   *
   *
   * */
  const amountNotificationsUnread = useMemo(() => {
    if (!notifications) {
      return 0
    }

    return get(notifications, NotificationStatus.UNREAD)?.data?.length || 0
  }, [notifications])

  /* Effects
   *
   *
   *
   *
   * */
  useEffect(() => {
    if (!isLoggedIn) return

    fetchNotifications()
  }, [fetchNotifications, isLoggedIn])

  useOutsideClick({
    ref: ref,
    handler: (e) => handleClose(e),
  })

  return (
    <NotificationContext.Provider
      value={{
        ref,
        markAsRead,
        markAsArchive,
        markAsFavorite,
        markAllArchive,
        markAsUnArchive,
        markAsUnFavorite,
        fetchNotifications,
        handleToggle,
        nextPage,
        prevPage,
        isOpen,
        notifications,
        favoriteNotifications,
        amountNotificationsUnread,
      }}
    >
      <NotificationModal />

      {children}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  const context = useContext(NotificationContext)

  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationContextProvider'
    )
  }

  return context
}
