// Vendors
import React, { useMemo } from 'react'

// Components
import { ButtonWithMenu } from 'components/ButtonWithMenu'
import { FormFieldEditerList } from 'components/FormFieldEditerList'

// Functions
import { HandleQuestionType, QuestionType } from 'hooks/useFormFieldEditer'

// Styles
import { Flex } from '@chakra-ui/react'
import { FiPlusCircle } from 'react-icons/fi'

// Types
type TabMedicalRecordProps = {
  questions: QuestionType[]
  handleQuestion: HandleQuestionType
}

export const TabMedicalRecord = (props: TabMedicalRecordProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { questions, handleQuestion } = props

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const buttonMenuActions = useMemo(
    () => [
      {
        label: 'Título',
        onClick: () => handleQuestion.add('title'),
      },
      {
        label: 'Data',
        onClick: () => handleQuestion.add('date'),
      },
      {
        label: 'Seleção única',
        onClick: () => handleQuestion.add('radiobutton'),
      },
      {
        label: 'Área de texto',
        onClick: () => handleQuestion.add('textarea'),
      },
      {
        label: 'Texto',
        onClick: () => handleQuestion.add('text'),
      },
      {
        label: 'Numérico',
        onClick: () => handleQuestion.add('number'),
      },
      {
        label: 'Seleção',
        onClick: () => handleQuestion.add('select'),
      },
      {
        label: 'Múltipla escolha',
        onClick: () => handleQuestion.add('checkbox'),
      },
    ],
    [handleQuestion]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <>
      <FormFieldEditerList
        questions={questions}
        handleQuestion={handleQuestion}
      />

      <Flex>
        <ButtonWithMenu
          icon={<FiPlusCircle />}
          label="Adicionar pergunta"
          actions={buttonMenuActions}
        />
      </Flex>
    </>
  )
}
