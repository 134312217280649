import { TFunction } from 'react-i18next'
import { yupSchema } from 'utils/yupSchema'

import * as yup from 'yup'

export const schema = (t: TFunction<'salesCategory'>) => {
  yupSchema(t as any)

  return yup.object().shape({
    name: yup.string().required().label(t('form.name.label')),
  })
}
