// Vendors
import React, { useMemo, useCallback, ChangeEvent } from 'react'
import { Link, useHistory } from 'react-router-dom'

// Functions
import { useTranslation } from 'react-i18next'
import { useListRecords } from 'hooks/useListRecords'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListActions } from 'components/ListActions'
import { ListPagination } from 'components/ListPagination'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'
import { Button, Select, Stack } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'

// Types
import { Recollect } from './types'

import { PageMetaSetting } from './meta'

export const PageRecollectListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('recollect')
  const history = useHistory()
  const { endpoint, title } = PageMetaSetting
  const { records, query, pagination } = useListRecords<Recollect>({
    endpoint,
  })

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const listTableHeaders = useMemo(
    () => [
      {
        label: t('table.headers.recollect_code'),
        accessor: 'recollectCode',
      },
      {
        label: t('table.headers.activation_code'),
        accessor: 'activationCode',
      },
      {
        label: t('table.headers.buyer_name'),
        accessor: 'buyerName',
      },
      {
        label: t('table.headers.mkt_title'),
        accessor: 'purchaseHasProduct.storeProduct.mktTitle',
      },
      {
        label: t('table.headers.status'),
        accessor: 'status',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
    ],
    [t]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={title}>
      <Stack
        spacing="4"
        justify="space-between"
        direction={{ base: 'column', md: 'row' }}
      >
        <ListFilters query={query}>
          <Select
            size="sm"
            placeholder={t('filters.status.default')}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              query.set({
                ...query.current,
                status: e.target.value,
              })
            }}
          >
            <option value="REQUESTED_RECOLLECT">
              {t('filters.status.requested_recollect')}
            </option>
            <option value="ATTENDED_RECOLLECT">
              {t('filters.status.attended_recollect')}
            </option>
          </Select>
        </ListFilters>
      </Stack>

      <ListTable
        records={records}
        headers={listTableHeaders}
        defaultActions="none"
        actions={[
          {
            type: 'recollectProcessing',
            options: {
              reload: records.reload,
              showWhen(record) {
                return record.status === 'REQUESTED_RECOLLECT'
              },
            },
          },
        ]}
      />

      <ListPagination pagination={pagination} query={query} />
    </LayoutLoggedPageList>
  )
}
