// Vendors
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

// Functions
import { apiPut } from 'services/put'
import { apiPost } from 'services/post'
import { useError } from 'hooks/useError'
import { useAddress } from 'hooks/useAddress'
import { useCepPromise } from 'hooks/useCepPromise'
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FieldText } from 'components/FieldText'
import { FieldMask } from 'components/FieldMask'
import { FieldSelect } from 'components/FieldSelect'
import { FieldCheckbox } from 'components/FieldCheckbox'
import { AddressData } from 'components/ListAddress/ItemAddress'
import { DrawerListContent } from 'components/DrawerListContent'

// Styles
import {
  Box,
  Stack,
  Button,
  HStack,
  Divider,
  useToast,
  DrawerBody,
  IconButton,
  ButtonGroup,
  DrawerFooter,
} from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'

import { schema } from './schema'
import { useFeedback } from 'contexts/feedback'

// Interfaces
type FormAddressData = {
  street: string
  number: string
  neighborhood: string
  complement: string | null
  referencePoint: string | null
  postalCode: string
  cityId: string
  stateId: string
}

export type DrawerFormAddressProps = {
  personId?: string
  isOpen: boolean
  reload: () => void
  onClose: () => void
  submitEndpoint?: string
  defaultValues?: AddressData
}

export const DrawerFormAddress = (
  props: DrawerFormAddressProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { personId, isOpen, onClose, defaultValues, reload } = props

  const { handleError } = useError()
  const toast = useToast()
  const { fetchCEP } = useCepPromise()
  const { t } = useTranslation('common')
  const { getState, getCity } = useAddress()

  const submitEndpoint = personId
    ? `/app/person/${personId}/address`
    : `/app/person/address`

  const {
    watch,
    reset,
    control,
    register,
    setValue,
    setError,
    setFocus,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema(t)),
  })

  const watchState = watch('stateId')

  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectStates = useSelectParser({
    endpoint: '/app/state',
    accessor: { label: 'abbreviatedName', value: 'id' },
    requestWithoutMeta: true,
  })

  const selectCities = useSelectParser({
    endpoint: watchState && `/app/city?state=${watchState.label}`,
    accessor: { label: 'name', value: 'id' },
    requestWithoutMeta: true,
  })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!defaultValues) return

    const stateOption = {
      value: defaultValues.state.id,
      label: defaultValues.state.abbreviatedName,
    }
    const cityOption = {
      value: defaultValues.city.id,
      label: defaultValues.city.name,
    }

    const defaultValue = {
      stateId: stateOption,
      cityId: cityOption,
      street: defaultValues.street,
      number: defaultValues.number,
      neighborhood: defaultValues.neighborhood,
      complement: defaultValues.complement,
      postalCode: defaultValues.postalCode,
    }

    reset(defaultValue)
  }, [defaultValues, reset])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isEditing = useMemo(() => {
    return !!defaultValues
  }, [defaultValues])

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isSearchingPostalCode, setIsSearchingPostalCode] =
    React.useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onCloseDrawer = useCallback(() => {
    reset({})
    onClose()
  }, [onClose, reset])

  const handleAddPostalCode = useCallback(async () => {
    let postalCode = watch('postalCode')

    if (!postalCode) return

    postalCode = postalCode.replace(/\D/g, '')
    if (postalCode.length !== 8) return

    try {
      setIsSearchingPostalCode(true)
      const response = await fetchCEP(postalCode)

      if (response) {
        const stateOption = getState(response.state)
        const cityOption = await getCity(response.city)

        setValue(`street`, response.street)
        setValue(`neighborhood`, response.neighborhood)
        setValue(`stateId`, stateOption)
        setValue(`cityId`, cityOption)

        setFocus(`number`)
      }
    } catch (error) {
      console.error(error)
      setError(`postalCode`, { message: 'CEP inválido' })
    } finally {
      setIsSearchingPostalCode(false)
    }
  }, [watch, fetchCEP, getState, getCity, setValue, setFocus, setError])

  const onSubmit: SubmitHandler<FormAddressData> = useCallback(
    async (data) => {
      try {
        if (isEditing) {
          if (!defaultValues || !defaultValues.id) {
            throw new Error('Id não informado')
          }

          await apiPut(`${submitEndpoint}/${defaultValues.id}`, data)
        } else {
          await apiPost(`${submitEndpoint}`, data)
        }

        toast({
          title: t('drawer.address.toast.success.title'),
          description: t('drawer.address.toast.success.description', {
            type: t(
              `drawer.address.toast.success.${isEditing ? 'update' : 'add'}`
            ),
          }),
          status: 'success',
        })

        reload()
        onCloseDrawer()
      } catch (error: any) {
        handleError(error)
      }
    },
    [
      defaultValues,
      handleError,
      isEditing,
      onCloseDrawer,
      reload,
      submitEndpoint,
      t,
      toast,
    ]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <DrawerListContent
      title={t('drawer.address.title')}
      isOpen={isOpen}
      onClose={onCloseDrawer}
    >
      <Divider />

      <DrawerBody>
        <Stack as="form" spacing="5">
          <Controller
            name="postalCode"
            control={control}
            render={({ field }) => {
              return (
                <FieldMask
                  mask="99999-999"
                  label={t('drawer.address.form.postalCode.label')}
                  error={errors.postalCode}
                  inputRightElement={
                    <IconButton
                      size="sm"
                      variant="ghost"
                      transition="all 0.2s"
                      aria-label="Buscar CEP"
                      isLoading={isSearchingPostalCode}
                      icon={<FiSearch />}
                      onClick={handleAddPostalCode}
                    />
                  }
                  {...field}
                  isRequired
                />
              )
            }}
          />

          <FieldText
            label={t('drawer.address.form.street.label')}
            error={errors.street}
            {...register('street')}
            isRequired
          />

          <HStack>
            <FieldText
              label={t('drawer.address.form.number.label')}
              error={errors.number}
              {...register('number')}
              isRequired
            />

            <FieldText
              label={t('drawer.address.form.complement.label')}
              error={errors.complement}
              {...register('complement')}
              isRequired
            />
          </HStack>

          <FieldText
            label={t('drawer.address.form.neighborhood.label')}
            error={errors.neighborhood}
            isRequired
            {...register('neighborhood')}
          />

          <HStack>
            <Controller
              name="stateId"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  label={t('drawer.address.form.stateId.label')}
                  error={errors.stateId}
                  isLoading={false}
                  options={selectStates.options}
                  {...field}
                  isRequired
                />
              )}
            />

            <Controller
              name="cityId"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  label={t('drawer.address.form.cityId.label')}
                  error={errors.cityId}
                  isLoading={false}
                  options={selectCities.options}
                  {...field}
                  isRequired
                />
              )}
            />
          </HStack>

          <Box mt="2rem !important">
            <FieldCheckbox
              control={control}
              options={[
                {
                  label: t('drawer.address.form.isMain.option.yes'),
                  name: 'isMain',
                },
              ]}
            />
          </Box>
        </Stack>
      </DrawerBody>

      <Divider />

      <DrawerFooter>
        <ButtonGroup>
          <Button onClick={onCloseDrawer}>Cancelar</Button>

          <Button
            colorScheme="blue"
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            Salvar
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </DrawerListContent>
  )
}
