// Vendors
import React, { ReactNode } from 'react'

// Styles
import {
  Box,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerProps,
} from '@chakra-ui/react'
import { DrawerList } from 'components/DrawerList'

// Interfaces
export type DrawerListContentProps = DrawerProps & {
  title: string
  children: ReactNode
}

export const DrawerListContent = (
  props: DrawerListContentProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { title, children, ...rest } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <DrawerList {...rest}>
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerHeader>{title}</DrawerHeader>

        {children}
      </DrawerContent>
    </DrawerList>
  )
}
