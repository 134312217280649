import { TFunction } from 'react-i18next'
import { yupSchema } from 'utils/yupSchema'

import * as yup from 'yup'
import { AccentColorEnum } from './types'

export const schema = (t: TFunction<'categories'>) => {
  yupSchema(t as any)

  return yup.object().shape({
    title: yup.string().required().label(t('form.category.title.label')),

    description: yup
      .string()
      .max(280)
      .required()
      .label(t('form.category.description.label')),

    // initial information
    accentColor: yup
      .string()
      .selectValue()
      .oneOf(Object.keys(AccentColorEnum))
      .when('isPage', (isPage, field) => {
        return isPage ? field.required() : field.notRequired()
      })
      .label(t('form.category.accentColor.label')),

    isPage: yup.boolean().label(t('form.category.isPage.label')),

    mktTitle: yup
      .string()
      .max(45)
      .when('isPage', (isPage, field) => {
        return isPage ? field.required() : field.notRequired()
      })
      .label(t('form.category.mktTitle.label')),

    mktDescription: yup
      .string()
      .max(50)
      .when('isPage', (isPage, field) => {
        return isPage ? field.required() : field.notRequired()
      })
      .label(t('form.category.mktDescription.label')),

    mktAbstract: yup
      .string()
      .when('isPage', (isPage, field) => {
        return isPage ? field.required() : field.notRequired()
      })
      .label(t('form.category.mktAbstract.label')),

    // Introduction
    mktTitleIntrodutoryText: yup
      .string()
      .max(280)
      .when('isPage', (isPage, field) => {
        return isPage ? field.required() : field.notRequired()
      })
      .label(t('form.category.mktTitleIntrodutoryText.label')),

    // Features
    mktFeatures: yup.object().shape({
      features: yup
        .array(
          yup.object().shape({
            title: yup
              .string()
              .required()
              .label(t('form.category.mktFeatures.features.title.label')),
            description: yup
              .string()
              .required()
              .label(t('form.category.mktFeatures.features.description.label')),
          })
        )
        .min(1)
        .label(t('form.category.mktFeatures.features.label')),
    }),
    // Understand the report
    mktTitleReport: yup
      .string()
      .max(45)
      .when('isPage', (isPage, field) => {
        return isPage ? field.required() : field.notRequired()
      })
      .label(t('form.category.mktTitleReport.label')),

    mktDescriptionReport: yup
      .string()
      .max(280)
      .when('isPage', (isPage, field) => {
        return isPage ? field.required() : field.notRequired()
      })
      .label(t('form.category.mktDescriptionReport.label')),

    // SEO
    seoTitle: yup
      .string()
      .required()
      .label(t('common:form.tabSeo.seoTitle.label')),

    seoDescription: yup
      .string()
      .required()
      .label(t('common:form.tabSeo.seoDescription.label')),

    slug: yup
      .string()
      .kebabCase()
      .required()
      .label(t('common:form.tabSeo.slug.label')),

    seoKeywords: yup
      .array()
      .required()
      .selectValues()
      .label(t('common:form.tabSeo.seoKeywords.label')),

    seoAdditionalKeywords: yup
      .string()
      .label(t('common:form.tabSeo.seoAdditionalKeywords.label')),
  })
}
