// Vendors
import { sumBy } from 'lodash'
import { useParams } from 'react-router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

// Functions
import { apiShow } from 'services/get'

// Components
import { TabOrder } from './tabs/TabOrder'
import { TabDelivery } from './tabs/TabDelivery'
import { TabHistoric } from './tabs/TabHistoric'
import { TabDetail, Tabs } from 'components/Tabs'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'
import { CircularProgress, Flex, Text } from '@chakra-ui/react'

// Types
import { PageMetaSetting } from './meta'
import { PurchaseShow } from './types'

export const PageCorreiosShowRecord = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id } = useParams<{ id: string }>()
  const { title } = PageMetaSetting

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [order, setOrder] = useState<PurchaseShow | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const getOrder = useCallback(async () => {
    const response = await apiShow<PurchaseShow>(`/app/purchase/${id}`)

    if (!response) return
    setOrder(response)
    setLoading(false)
  }, [id])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    setLoading(true)
    async function fetchOrder() {
      await getOrder()
    }

    fetchOrder()
  }, [getOrder, id])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const orderDetails = useMemo(() => {
    if (!order) return undefined

    const {
      purchaseNumber,
      createdAt,
      price,
      user,
      purchaseHasProducts,
      purchaseDelivery,
      billingData,
    } = order

    const totalQuantity = String(sumBy(billingData?.items, 'quantity'))

    return {
      order: {
        createdAt,
        price,
        status: purchaseDelivery?.status,
        totalQuantity,
        purchaseNumber,
      },
      purchaseHasProducts: {
        list: purchaseHasProducts.map((product) => {
          return {
            id: product.id,
            status: product.status,
            patient: user?.person?.name,
            title: product.storeProduct.mktTitle,
            activationCode: product.activationCode,
          }
        }),
      },
    }
  }, [order])

  const delivery = useMemo(() => {
    if (!order) return null

    const {
      shippingValue,
      shippingMethod,
      deliveryAddress,
      purchaseDelivery,
      estimatedDeliveryDays,
      user,
    } = order

    const deliveryInfo = {
      shippingValue,
      estimatedDeliveryDays,
      serviceName: shippingMethod.serviceName,
      providerName: shippingMethod.providerName,
      deliveryDate: shippingMethod?.deliveryDate,
      deliveryTrackingCode: purchaseDelivery?.deliveryTrackingCode,
    }

    return {
      user,
      deliveryAddress,
      deliveryInfo,
    }
  }, [order])

  const historic = useMemo(() => {
    if (!order) return null

    const { purchaseDelivery, user } = order

    const historicInfo = purchaseDelivery?.purchaseDeliveryStatusUpdate.map(
      (item, index: number) => {
        return {
          id: item.id,
          action: item.status,
          date: item.createdAt,
          description: item.note,
          userName: user?.person?.name,
          isLastItem:
            index === purchaseDelivery?.purchaseDeliveryStatusUpdate.length - 1,
        }
      }
    )

    return {
      purchaseDeliveryStatusUpdate: historicInfo,
    }
  }, [order])

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Pedido',
        component: TabOrder,
        options: {
          recordReload: getOrder,
          orderDetails,
        },
      },
      {
        title: 'Envio',
        component: TabDelivery,
        options: {
          delivery,
        },
      },
      {
        title: 'Histórico',
        component: TabHistoric,
        options: {
          historic,
        },
      },
    ],
    [delivery, getOrder, historic, orderDetails]
  )

  const purchaseNumber = useMemo(() => {
    if (!order) return ''

    return ' - #' + order.purchaseNumber
  }, [order])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={title + purchaseNumber}>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" h="full">
          <CircularProgress
            mr="2"
            size="28px"
            isIndeterminate
            color="blue.500"
          />
          <Text>Carregando...</Text>
        </Flex>
      ) : (
        <Tabs tabsDetails={tabsDetails} />
      )}
    </LayoutLoggedPageList>
  )
}
