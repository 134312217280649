import { TabDetail, Tabs } from 'components/Tabs'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'
import React, { useMemo } from 'react'
import { TabArchive } from './Tabs/TabArchive'
import { TabFavorite } from './Tabs/TabFavorite'
import { TabMessage } from './Tabs/TabMessage'

export const PageNotificationsListRecords = (): JSX.Element => {
  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Mensagens',
        component: TabMessage,
      },
      {
        title: 'Favoritos',
        component: TabFavorite,
      },
      {
        title: 'Arquivados',
        component: TabArchive,
      },
    ],
    []
  )

  return (
    <LayoutLoggedPageList title="Notificações">
      <Tabs tabsDetails={tabsDetails} />
    </LayoutLoggedPageList>
  )
}
