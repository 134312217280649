import { CanEditType } from 'hooks/useCan'
import { isNaN } from 'lodash'
import { TFunction } from 'react-i18next'
import { yupSchema } from 'utils/yupSchema'
import * as yup from 'yup'

export const schema = (
  t: TFunction<'common'>,
  can: CanEditType<{
    [x: string]: {}
  }>
) => {
  yupSchema(t)

  return yup.object().shape({
    name: yup.string().required().label(t('form.coupon.name.label')),

    voucherCode: yup
      .string()
      .required()
      .label(t('form.coupon.voucherCode.label')),

    status: yup.string().required().getStatus().label(t('form.status.label')),

    description: yup
      .string()
      .required()
      .label(t('form.coupon.description.label')),

    validFrom: yup.date().required().label(t('form.coupon.validFrom.label')),

    validUntil: yup.date().optional().label(t('form.coupon.validUntil.label')),

    discountType: yup
      .string()
      .selectValue()
      .optional()
      .label(t('form.coupon.discountType.label')),

    discountPercentage: yup
      .number()
      .optional()
      .transform((value) => (isNaN(value) ? undefined : value))
      .max(!can.discountPercentage50 ? 50 : 15)
      .min(0)
      .label(t('form.coupon.discountPercentage.label')),

    discountPrice: yup
      .number()
      .optional()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(0)
      .label(t('form.coupon.discountPrice.label')),

    maximumClientUsage: yup
      .number()
      .positive()
      .optional()
      .label(t('form.coupon.maximumClientUsage.label')),

    maximumUsage: yup
      .number()
      .positive()
      .optional()
      .label(t('form.coupon.maximumUsage.label')),

    isEligibleAllStoreProducts: yup.boolean(),

    storeProductsIds: yup
      .array()
      .when('isEligibleAllStoreProducts', (isEligible, field) => {
        return isEligible ? field.notRequired() : field.required().min(1)
      })
      .selectValues()
      .label(t('form.coupon.storeProductsIds.label')),

    assignedUserId: yup
      .string()
      .min(1)
      .selectValue()
      .label(t('form.coupon.storeProductsIds.label')),
  })
}
