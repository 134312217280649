// Vendors
import React, { ChangeEvent, useCallback, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

// Components
import { FieldText } from 'components/FieldText'
import { FormWrapper } from 'components/FormWrapper'

// Styles
import { Grid, GridItem } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FormClientData } from './types'
import { useQuery } from 'hooks/useQuery'
import { apiShow } from 'services/get'
import { get } from 'lodash'
import { Person } from 'types/Person'

export const ClientData = () => {
  /*
  |-----------------------------------------------------------------------------
  | Contants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const query = useQuery()
  const { t } = useTranslation('checkout')

  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<FormClientData>()

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    apiShow<Person>(`/app/person/${query.get('personId')}`).then((response) => {
      if (!response) return

      const documentNumber = get(response, 'documentNumber')
      const documentNumberFormatted =
        documentNumber?.length === 11
          ? documentNumber.replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})/g,
              '$1.$2.$3-$4'
            )
          : documentNumber?.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
              '$1.$2.$3/$4-$5'
            )

      setValue('name', get(response, 'name'))
      setValue('email', get(response, 'email'))
      setValue('documentNumber', documentNumberFormatted || '')
      setValue('clientId', get(response, 'userId'))
    })
  }, [query, setValue])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleChangeDocumentNumber = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      const valueCharacters = value.replace(/\D/g, '')
      const valueLength = valueCharacters.length

      if (valueLength === 11) {
        return valueCharacters.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/g,
          '$1.$2.$3-$4'
        )
      }

      if (valueLength === 14) {
        return valueCharacters.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
          '$1.$2.$3/$4-$5'
        )
      }

      return value
    },
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormWrapper title={t('group.clientData')}>
      <Grid
        my="4"
        gap="10"
        w={{ lg: 'container.md' }}
        templateColumns={{ lg: 'repeat(2, 1fr)' }}
      >
        <GridItem colSpan={{ lg: 2 }}>
          <FieldText
            isDisabled
            label={t('form.name.label')}
            error={errors.name}
            isRequired
            {...register('name')}
          />
        </GridItem>

        <GridItem>
          <Controller
            control={control}
            name="documentNumber"
            render={({ field: { ref, name, onChange, value } }) => (
              <FieldText
                label={t('form.documentNumber.label')}
                error={errors.documentNumber}
                maxLength={18}
                ref={ref}
                name={name}
                value={value || ''}
                isRequired
                onChange={(e) => {
                  onChange(handleChangeDocumentNumber(e))
                }}
              />
            )}
          />
        </GridItem>

        <GridItem>
          <FieldText
            label={t('form.email.label')}
            error={errors.email}
            isRequired
            {...register('email')}
          />
        </GridItem>
      </Grid>
    </FormWrapper>
  )
}
