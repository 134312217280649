// Vendors
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { apiPut } from 'services/put'

import { useError } from 'hooks/useError'

// Components
import { FieldText } from 'components/FieldText'
import { FiEye, FiEyeOff } from 'react-icons/fi'

// Styles
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  HStack,
  InputGroup,
  InputRightElement,
  useToast,
} from '@chakra-ui/react'
import { schema } from './schema'

// Interfaces
export type ModalPasswordProps = {
  isOpen: boolean
  onClose: () => void
}

type DataForm = {
  password: string
  confirmPassword: string
}

export const ModalPassword = (props: ModalPasswordProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { isOpen, onClose } = props
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema()),
  })
  const toast = useToast({ position: 'top-right' })
  const { handleError } = useError()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [isShowPassword, setIsShowPassword] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */
  const onSubmit = useCallback(
    async (data: DataForm) => {
      try {
        await apiPut(`/app/client`, {
          password: data.password,
          confirmPassword: data.confirmPassword,
        })

        toast({
          title: 'Senha alterada com sucesso',
          status: 'success',
        })
      } catch (error: unknown) {
        handleError(error)
      } finally {
        onClose()
        reset()
      }
    },
    [handleError, onClose, reset, toast]
  )

  const handleClose = useCallback(() => {
    reset()
    onClose()
  }, [onClose, reset])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Alteração de senha</ModalHeader>
        <ModalCloseButton onClick={handleClose} />
        <ModalBody>
          <VStack>
            <InputGroup>
              <FieldText
                label="Senha"
                type={isShowPassword ? 'text' : 'password'}
                {...register('password')}
                error={errors.password}
                isRequired
              />

              <InputRightElement width="4.5rem" top="8">
                <Button
                  h="7"
                  size="sm"
                  onClick={() => setIsShowPassword((i) => !i)}
                >
                  {isShowPassword ? <FiEyeOff /> : <FiEye />}
                </Button>
              </InputRightElement>
            </InputGroup>

            <FieldText
              label="Confirme sua senha"
              type="password"
              {...register('confirmPassword')}
              error={errors.confirmPassword}
              isRequired
            />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button variant="ghost" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              isLoading={isSubmitting}
              type="submit"
              mr={3}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
