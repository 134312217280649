import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

export const Antibodies = (
  props: HTMLChakraProps<'svg'> & {
    iconColor?: string
    colorStopInit: string
    colorStopEnd: string
  }
) => {
  const { iconColor, colorStopInit, colorStopEnd, ...rest } = props
  const color = iconColor || 'white'
  return (
    <chakra.svg
      id="Componente_59_1"
      data-name="Componente 59 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="53.73"
      height="53.73"
      viewBox="0 0 53.73 53.73"
      {...rest}
    >
      <svg>
        <path
          id="Caminho_25"
          data-name="Caminho 25"
          d="M26.865,53.73A26.865,26.865,0,1,0,0,26.865,26.865,26.865,0,0,0,26.865,53.73Z"
          fill={colorStopInit}
        />
        <g
          id="Grupo_30"
          data-name="Grupo 30"
          transform="translate(10.023 8.645)"
        >
          <path
            id="Caminho_42530"
            data-name="Caminho 42530"
            d="M140.836,262.6a.516.516,0,0,1-.42-.14.692.692,0,0,1-.14-.981l5.323-6.863.561-8.544a.7.7,0,1,1,1.4,0l-.7,8.824a.516.516,0,0,1-.14.42l-5.463,7.144C141.256,262.6,140.976,262.6,140.836,262.6Z"
            transform="translate(-119.453 -233.707)"
            fill={color}
          />
          <path
            id="Caminho_42531"
            data-name="Caminho 42531"
            d="M148.762,257.924c-.28,0-.561-.141-.561-.421a.929.929,0,0,1,.28-.98l7.7-3.922a.721.721,0,1,1,.7,1.261l-7.7,3.922A.514.514,0,0,1,148.762,257.924Z"
            transform="translate(-122.056 -236.035)"
            fill={color}
          />
          <path
            id="Caminho_42532"
            data-name="Caminho 42532"
            d="M152.494,260.541c-.28,0-.561-.14-.561-.42a.929.929,0,0,1,.28-.98l6.163-3.222a.721.721,0,1,1,.7,1.261l-6.3,3.361Z"
            transform="translate(-123.268 -237.112)"
            fill={color}
          />
          <path
            id="Caminho_42533"
            data-name="Caminho 42533"
            d="M144.989,253.981a.661.661,0,0,1-.7-.7l.28-7a.7.7,0,0,1,1.4,0l-.28,7A.75.75,0,0,1,144.989,253.981Z"
            transform="translate(-120.805 -233.774)"
            fill={color}
          />
          <path
            id="Caminho_42534"
            data-name="Caminho 42534"
            d="M124.656,247.052h-.14a.768.768,0,0,1-.56-.84l2.381-8.825-2.8-8.4a.664.664,0,0,1,1.261-.42l2.941,8.685v.42l-2.381,8.825A.714.714,0,0,1,124.656,247.052Z"
            transform="translate(-114.059 -228.105)"
            fill={color}
          />
          <path
            id="Caminho_42535"
            data-name="Caminho 42535"
            d="M128.425,238.886a.514.514,0,0,1-.42-.14.864.864,0,0,1-.141-.981l5.883-6.863c.14-.42.56-.42.98-.14a.862.862,0,0,1,.141.98l-5.743,6.863A2.352,2.352,0,0,1,128.425,238.886Z"
            transform="translate(-115.446 -228.904)"
            fill={color}
          />
          <path
            id="Caminho_42536"
            data-name="Caminho 42536"
            d="M132.779,240.438a.514.514,0,0,1-.42-.14.864.864,0,0,1-.141-.981l4.762-5.462a.7.7,0,0,1,1.121.84l-4.762,5.463A.728.728,0,0,1,132.779,240.438Z"
            transform="translate(-116.858 -229.895)"
            fill={color}
          />
          <path
            id="Caminho_42537"
            data-name="Caminho 42537"
            d="M122.4,238.193a.77.77,0,0,1-.7-.421l-2.522-6.723a.664.664,0,1,1,1.261-.42l2.522,6.723a.588.588,0,0,1-.42.841Z"
            transform="translate(-112.639 -228.771)"
            fill={color}
          />
          <path
            id="Caminho_42538"
            data-name="Caminho 42538"
            d="M124.5,272.714a.772.772,0,0,1-.7-.421l-2.941-8.684v-.42l2.381-8.965a.714.714,0,0,1,1.4.28l-2.381,8.825,2.8,8.4a.681.681,0,0,1-.42.84A.138.138,0,0,1,124.5,272.714Z"
            transform="translate(-113.202 -236.398)"
            fill={color}
          />
          <path
            id="Caminho_42539"
            data-name="Caminho 42539"
            d="M113.081,275.222a.514.514,0,0,1-.42-.14.863.863,0,0,1-.141-.981l5.743-6.863a.7.7,0,0,1,1.121.84l-5.743,6.863A1.058,1.058,0,0,1,113.081,275.222Z"
            transform="translate(-110.467 -240.727)"
            fill={color}
          />
          <path
            id="Caminho_42540"
            data-name="Caminho 42540"
            d="M110.178,272.992a.514.514,0,0,1-.42-.14.863.863,0,0,1-.141-.981l4.762-5.462a.7.7,0,1,1,1.121.84l-4.762,5.462C110.6,272.992,110.457,272.992,110.178,272.992Z"
            transform="translate(-109.525 -240.458)"
            fill={color}
          />
          <path
            id="Caminho_42541"
            data-name="Caminho 42541"
            d="M128.823,275.93a.769.769,0,0,1-.7-.42l-2.522-6.724a.664.664,0,0,1,1.261-.42l2.522,6.724a.587.587,0,0,1-.42.84Z"
            transform="translate(-114.724 -241.015)"
            fill={color}
          />
        </g>
      </svg>
    </chakra.svg>
  )
}
