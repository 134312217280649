// Vendors
import { map } from 'lodash'
import React, { useMemo, useCallback, ChangeEvent } from 'react'
import { Link, useHistory } from 'react-router-dom'

// Functions
import { apiShow } from 'services/get'
import { apiPatch } from 'services/patch'
import { useTranslation } from 'react-i18next'
import { useListRecords } from 'hooks/useListRecords'
import { useDialogDelete } from 'hooks/useDialogDelete'
import { useConfirmationDialog } from 'contexts/confirmationDialog'

// Components
import { ListTable } from 'components/ListTable'
import { ListActions } from 'components/ListActions'
import { ListFilters } from 'components/ListFilters'
import { ListPagination } from 'components/ListPagination'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'
import { Button, Select, Stack } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'

// Types
import { Professional } from './types'

import { PageMetaSetting } from './meta'
import { PersonPurchaseAndKit } from 'types/PersonPurchaseAndKit'

export const PageProfessionalListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('common')
  const history = useHistory()
  const { endpoint, title } = PageMetaSetting
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<Professional>({
    endpoint,
  })

  const { dialog } = useDialogDelete()
  const { getConfirmation } = useConfirmationDialog()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDelete = useCallback(
    async (e, record: Professional) => {
      e.stopPropagation()

      try {
        const response = await apiShow<PersonPurchaseAndKit>(
          `/app/person/${record.id}/purchase_kits`
        )

        const responseForDialogKits = map(response?.kits, (kit) => {
          return {
            href: `/kits/${kit.activationCode}`,
            label: kit.activationCode,
          }
        })

        const responseForDialogPurchases = map(
          response?.purchases,
          (purchase) => {
            return {
              href: `/orders/${purchase.id}`,
              label: `#${purchase.purchaseNumber}`,
            }
          }
        )

        const { title, body, isDisabled } = dialog(
          {
            title: 'Kits',
            items: responseForDialogKits,
          },
          {
            title: 'Pedidos',
            items: responseForDialogPurchases,
          }
        )

        await getConfirmation({
          title: title,
          message: body,
          confirmText: isDisabled ? 'Desabilitar' : 'Deletar',
          onConfirm: async () => {
            try {
              if (isDisabled) {
                await apiPatch(`/app/person/${record.id}/disable`)
                Records.reload()
              } else {
                Records.delete(record.id)
              }
            } catch (error) {
              console.log(error)
            }
          },
        })
      } catch (error) {
        console.log(error)
      }
    },
    [Records, dialog, getConfirmation]
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const ListTableHeaders = useMemo(
    () => [
      {
        label: t('list_table.headers.name'),
        accessor: 'name',
      },
      {
        label: t('list_table.headers.phone'),
        accessor: 'phoneNumber',
      },
      {
        label: t('list_table.headers.email'),
        accessor: 'email',
      },
      {
        label: t('list_table.headers.counciltype'),
        accessor: 'councilType',
      },
      {
        label: t('list_table.headers.councilnumber'),
        accessor: 'councilNumber',
      },
      {
        label: 'UF',
        accessor: 'state',
      },
      {
        label: t('list_table.headers.seller'),
        accessor: 'seller.name',
      },
      {
        label: t('list_table.headers.accountStatus'),
        accessor: 'status',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
      {
        label: t('list_table.headers.roleStatus'),
        accessor: 'roleStatus',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
    ],
    [t]
  )

  const ListTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/professionals/${Record.id}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleFilterStatus = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      Query.set({
        ...Query.current,
        status: event.target.value,
      })
    },
    [Query]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={title}>
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ListFilters query={Query}>
          <Select size="sm" onChange={handleFilterStatus}>
            <option value="">Todos os status</option>
            <option value="ENABLED">Ativos</option>
            <option value="DISABLED">Inativos</option>
            <option value="PENDING_DOCUMENTS">Aguardando documentos</option>
            <option value="PENDING_APPROVAL">Aguardando aprovação</option>
          </Select>
        </ListFilters>

        <ListActions>
          <Button as={Link} to={'/professionals/new'} leftIcon={<FiPlus />}>
            Adicionar
          </Button>
        </ListActions>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={ListTableRowAction}
        defaultActions="edit"
        actions={[
          {
            type: 'order',
          },
          {
            type: 'resetAccountPassword',
            options: {
              reload: Records.reload,
              isWorkspace: false,
            },
          },
          {
            type: 'delete',
            options: {
              callback: handleDelete,
            },
          },
        ]}
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
