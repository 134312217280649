// Vendors
import React from 'react'
import { kebabCase } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'

// Hooks
import { useCreateRecord } from 'hooks/useCreateRecord'

// Components
import { FieldText } from 'components/FieldText'

import { FormFooter } from 'components/FormFooter'

// Schemas
import { schema } from './schema'

// Styles
import { Box, Flex, Grid, GridItem, useToast } from '@chakra-ui/react'

// Types
import { SalesCategoryNewForm } from './types'

type SalesCategoryFormNewProps = {
  endpoint: string
}

export const SalesCategoryFormNew = (
  props: SalesCategoryFormNewProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props

  const { t } = useTranslation('salesCategory')
  const history = useHistory()

  const { create } = useCreateRecord<SalesCategoryNewForm>({ endpoint })

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SalesCategoryNewForm>({
    resolver: yupResolver(schema(t)),
  })
  const toast = useToast({ position: 'top-right' })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<SalesCategoryNewForm> = React.useCallback(
    async (data) => {
      try {
        const newData = {
          ...data,
          slug: kebabCase(data.name),
        }

        await create(newData)

        toast({
          title: t('toast.success.title'),
          description: t('toast.success.create'),
          status: 'success',
        })

        history.push('/sales-category')
      } catch (error: any) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [create, history, setError, t, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      as="form"
      h="full"
      flexDirection="column"
      justifyContent="space-between"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box>
        <Grid
          my="4"
          gap="10"
          templateColumns={{ lg: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
        >
          <GridItem colSpan={2}>
            <FieldText
              label={t('form.name.label')}
              error={errors.name}
              isRequired
              {...register('name')}
            />
          </GridItem>
        </Grid>
      </Box>

      <FormFooter isSubmitting={isSubmitting} />
    </Flex>
  )
}
