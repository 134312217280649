import { find, get, kebabCase, map, reduce } from 'lodash'
import { LaboratoryAnalysisType } from '.'

export function formatClinicalRecordFormResponse(
  laboratoryAnalysis: LaboratoryAnalysisType[]
) {
  if (!laboratoryAnalysis || !laboratoryAnalysis.length) {
    return []
  }

  const formattedLaboratoryAnalysis = map(laboratoryAnalysis, (analysis) => {
    const { clinicalRecordFormResponse, clinicalRecord } = analysis

    if (!clinicalRecordFormResponse) {
      return
    }

    const {
      clinicalRecordForm: { questions: clinicalRecordFormQuestions },
    } = clinicalRecord
    const { questions } = clinicalRecordFormResponse

    if (!questions || !questions.length) {
      return
    }

    // REVIEW: Foi necessário, desculpa por isso.
    const formattedQuestions = map(questions, (question, index, arr) => {
      let position = index
      // const clinicalRecordFormQuestion = get(clinicalRecordFormQuestions, index)
      const clinicalRecordFormQuestion = clinicalRecordFormQuestions.find(
        (q, i) => {
          if (
            position === i &&
            (q.fieldType !== 'title' || question.fieldType === q.fieldType)
          ) {
            return true
          }

          if (
            q.fieldType === 'title' &&
            !arr.some((q2) => q2.fieldType === 'title')
          ) {
            position++
          }

          return false
        }
      )

      if (!clinicalRecordFormQuestion) {
        return question
      }

      return {
        ...question,
        id: clinicalRecordFormQuestion.id,
      }
    })

    return reduce(
      formattedQuestions,
      (acc, question) => {
        let response = question.response
        const key = kebabCase(question.label)

        if (question.fieldType === 'checkbox') {
          const responseOptions = question.options
            .filter((option) => option.response)
            .map((option) => option.label)

          response = question.options.reduce((acc, option) => ({
            ...acc,
            [kebabCase(option.label)]: responseOptions.includes(option.label),
          }))
        }

        if (question.fieldType === 'radiobutton') {
          const responseOption = find(question.options, { value: response })
          response = `${question.id}.${responseOption?.label}`
        }

        if (question.fieldType === 'select') {
          const responseOption = find(question.options, { value: response })

          response = {
            name: `${question?.id}.${kebabCase(question.label)}.${kebabCase(
              responseOption?.value
            )}`,
            label: responseOption?.label,
          }
        }

        if (
          question.fieldType === 'date' &&
          question.response &&
          !question.response.includes('/')
        ) {
          response = new Date(question.response)
        }

        return {
          ...acc,
          [question.id]: {
            [key]: response,
          },
        }
      },
      {}
    )
  })

  return Object.assign({}, ...formattedLaboratoryAnalysis)
}
