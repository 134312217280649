// Vendors
import React, { useMemo } from 'react'
import { FieldError } from 'react-hook-form'

// Styles
import {
  Text,
  Input,
  HStack,
  FormLabel,
  InputProps,
  InputGroup,
  FormControl,
  InputLeftAddon,
  FormHelperText,
  FormErrorMessage,
  Flex,
} from '@chakra-ui/react'
import { Tooltip, TooltipProps } from 'components/Tooltip'

// Interfaces
import { IconType } from 'react-icons'
import { useFormHelperText } from 'hooks/useFormHelperText'

export type FieldFileProps = InputProps & {
  name: string
  label?: string
  icon?: IconType
  fileName?: string
  error?: FieldError
  canModify?: boolean
  buttonLabel?: string
  tooltipProps?: TooltipProps
  allowedExtensions?: string[]
  helperText?:
    | string
    | {
        text: string
        color?: string
      }
  isRequired?: boolean
}

const FieldFileBase: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FieldFileProps
> = (props: FieldFileProps, ref): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const {
    name,
    label,
    error,
    fileName,
    icon: Icon,
    helperText,
    buttonLabel,
    tooltipProps,
    canModify = true,
    allowedExtensions,
    isRequired,
    ...rest
  } = props

  const componentHelperText = useFormHelperText({ helperText, canModify })

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const allowedExtensionsMemo = useMemo(() => {
    if (!allowedExtensions) return { caption: '', accept: '' }

    const acceptedExtensions = allowedExtensions.join(', .')

    return {
      caption: allowedExtensions.join(', '),
      accept: `.${acceptedExtensions}`,
    }
  }, [allowedExtensions])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormControl isInvalid={!!error}>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        {!!label && (
          <FormLabel htmlFor={name} mb={0}>
            {label}
            {isRequired && (
              <Text
                as="span"
                color="red.500"
                ml={1}
                fontWeight="bold"
                userSelect="none"
              >
                *
              </Text>
            )}
          </FormLabel>
        )}

        {tooltipProps && Object.keys(tooltipProps).length && (
          <Tooltip {...tooltipProps} />
        )}
      </Flex>

      <HStack>
        <InputGroup w="75%">
          <InputLeftAddon
            borderWidth={error ? '2px' : '1px'}
            borderRightWidth="1px"
            borderColor={error ? 'red.500' : 'gray.200'}
          >
            selecione
          </InputLeftAddon>

          <Input
            id={name}
            ref={ref}
            name={name}
            type="file"
            display="none"
            accept={allowedExtensionsMemo.accept}
            {...rest}
          />

          <Text
            htmlFor={name}
            as="label"
            pl={2}
            pt="2"
            w="full"
            borderWidth={error ? '2px' : '1px'}
            borderColor={error ? 'red.500' : 'gray.200'}
            display="block"
            borderRightRadius="md"
            _hover={{ bg: 'gray.50' }}
            bg={rest.isDisabled ? 'gray.50' : 'white'}
            cursor={rest.isDisabled ? 'not-allowed' : 'pointer'}
            isTruncated
          >
            {fileName || (
              <Text color="blackAlpha.500" isTruncated>
                nenhum arquivo selecionado
              </Text>
            )}
          </Text>
        </InputGroup>

        <Text
          w="25%"
          htmlFor={name}
          as="label"
          h={10}
          px={4}
          color="white"
          display="flex"
          borderRadius="md"
          alignItems="center"
          justifyContent="center"
          _hover={{ bg: 'blue.600' }}
          bg={rest.isDisabled ? 'blue.600' : 'blue.500'}
          cursor={rest.isDisabled ? 'not-allowed' : 'pointer'}
        >
          {Icon && <Icon style={{ marginRight: '0.5rem' }} />}
          {buttonLabel || 'upload'}
        </Text>
      </HStack>

      {allowedExtensions && !error && (
        <FormHelperText color="gray.500" lineHeight="3" mt="2" fontSize="sm">
          Extensões permitidas: {allowedExtensionsMemo.caption}
        </FormHelperText>
      )}

      {(helperText || !canModify) && !error && componentHelperText}
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const FieldFile = React.forwardRef(FieldFileBase)
