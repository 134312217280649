import React from 'react'

import { FormModal } from 'components/FormModal'

import { FiCheck } from 'react-icons/fi'
import { Flex, Text, Stack, Button, Circle, Heading } from '@chakra-ui/react'

type ModalSuccessSubsidizedProps = {
  onClose: () => void
  isOpen: boolean
}

export const ModalSuccessSubsidized = (props: ModalSuccessSubsidizedProps) => {
  const { isOpen, onClose } = props

  return (
    <FormModal title="" isOpen={isOpen} onClose={onClose}>
      <Flex justifyContent="center" flexDirection="column" px="16" pb="12">
        <Stack alignItems="center">
          <Circle size="12" bg="blue.100" mb="1.5rem !important">
            <FiCheck size="24" />
          </Circle>

          <Heading fontWeight="medium" fontSize="lg" color="gray.900">
            Pedido realizado com sucesso!
          </Heading>

          <Text fontSize="sm" color="gray.500" textAlign="center" w="80">
            Os pedidos bonificados não exigem pagamento, nenhuma ação necessária
          </Text>
        </Stack>

        <Button colorScheme="blue" mt="14" onClick={onClose}>
          OK
        </Button>
      </Flex>
    </FormModal>
  )
}
