import React from 'react'
import { Box, Flex, Heading } from '@chakra-ui/react'

export const AccordionBlank = () => {
  return (
    <Flex w="full" py={5} px={10} align="center">
      <Box position="relative">
        <Flex
          w={8}
          h={8}
          color="white"
          align="center"
          justify="center"
          bgColor="red.500"
          fontWeight="bold"
          borderRadius="full"
        >
          X
        </Flex>
      </Box>

      <Box ml={4}>
        <Heading as="h3" fontSize={16} fontWeight={700}>
          Não conseguimos recuperar informacões do histórico do kit.
        </Heading>
      </Box>
    </Flex>
  )
}
