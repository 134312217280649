// Vendors
import { v4 } from 'uuid'
import * as React from 'react'
import { Link } from 'react-router-dom'

// Styles
import {
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
} from '@chakra-ui/react'
import { BsCaretRightFill } from 'react-icons/bs'
import { FiMoreHorizontal } from 'react-icons/fi'
import { IconBaseProps } from 'react-icons'

// Types
export interface NavItemProps {
  path?: string
  label: string
  subtle?: boolean
  active?: boolean
  external?: boolean
  icon: React.ComponentType<IconBaseProps>
  iconActions?: React.ReactElement
  endElement?: React.ReactElement
  children?: React.ReactNode
  actions?: Array<{
    path: string
    label: string
    icon: React.ComponentType<IconBaseProps>
  }>
}

export const NavItem = (props: NavItemProps) => {
  const {
    active,
    actions,
    iconActions,
    external,
    path,
    subtle,
    icon: Icon,
    children,
    label,
    endElement,
  } = props
  return (
    <HStack
      w="full"
      cursor="pointer"
      userSelect="none"
      rounded="md"
      transition="all 0.2s"
      bg={active ? 'gray.700' : undefined}
      _hover={{ bg: 'gray.700' }}
      _active={{ bg: 'gray.600' }}
    >
      <Link
        to={{ pathname: `${path}` }}
        target={external ? '_blank' : undefined}
        style={{ width: '100%' }}
      >
        <HStack w="full" px="3" py="2">
          <Box fontSize="lg" color={active ? 'currentcolor' : 'gray.400'}>
            <Icon />
          </Box>
          <Box
            flex="1"
            fontWeight="inherit"
            color={subtle ? 'gray.400' : undefined}
          >
            {label}
          </Box>
        </HStack>
      </Link>
      <Spacer></Spacer>
      {actions && (
        <Box>
          <Menu placement="bottom-end">
            <MenuButton
              as={HStack}
              px="3"
              py="3"
              cursor="pointer"
              userSelect="none"
              rounded="md"
              transition="all 0.2s"
              _hover={{ bg: 'gray.500' }}
              _active={{ bg: 'gray.500' }}
            >
              <Box flex="1" fontWeight="inherit" color="gray.400">
                {iconActions || <FiMoreHorizontal />}
              </Box>
            </MenuButton>

            <MenuList color="gray.700">
              {actions.map((action) => (
                <Link to={`${action.path}`} key={v4()}>
                  <MenuItem>{action.label}</MenuItem>
                </Link>
              ))}
            </MenuList>
          </Menu>
        </Box>
      )}

      {endElement && !children && <Box>{endElement}</Box>}
      {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
    </HStack>
  )
}
