// Vendors
import { map } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, FieldError, useFormContext } from 'react-hook-form'

// Hooks
import { useAuth } from 'contexts/auth'
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FormWrapper } from 'components/FormWrapper'
import { FieldSelect } from 'components/FieldSelect'
import { FieldToggle } from 'components/FieldToggle'
import { FieldTextArea } from 'components/FieldTextArea'
import { FieldRadioButton } from 'components/FieldRadioButton'

// Styles
import { Grid, GridItem } from '@chakra-ui/react'

// Types
import { FormPurchaseData } from './types'

export const PurchaseData = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { user } = useAuth()
  const { t } = useTranslation('checkout')

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<FormPurchaseData>()

  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectSellers = useSelectParser({
    accessor: { label: 'name', value: 'userId' },
    endpoint: '/app/person?type=seller',
  })

  const selectAccessOrderModule = useSelectParser({
    accessor: { label: 'name', value: 'userId' },
    endpoint: '/app/person?type=access-order-module',
  })

  const selectSalesCategory = useSelectParser({
    accessor: { label: 'name', value: 'id' },
    endpoint: '/app/purchase_category?status=ENABLED',
  })

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sellerDefaultValue = useMemo(() => {
    if (!user) return

    const roles = map(user.roleUser, 'role')

    if (!roles || !roles.length) return
    const hasAccessOrderModule = roles.some((role) =>
      role.name.includes('ACCESS_ORDER_MODULE')
    )

    if (!hasAccessOrderModule) return

    return {
      label: user.person.name,
      value: user.person.userId,
    }
  }, [user])

  const purchaseTypeOptions = useMemo(
    () => [
      {
        label: t('form.purchaseType.options.b2b'),
        value: 'B2B',
      },
      {
        label: t('form.purchaseType.options.b2c'),
        value: 'B2C',
      },
      {
        label: t('form.purchaseType.options.legacy'),
        value: 'LEGACY',
      },
    ],
    [t]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormWrapper title={t('group.purchaseData')}>
      <Grid
        my="4"
        gap="10"
        w={{ lg: 'container.md' }}
        templateColumns={{ lg: 'repeat(2, 1fr)' }}
      >
        <GridItem>
          <Controller
            name="sellerId"
            control={control}
            defaultValue={sellerDefaultValue}
            render={({ field }) => (
              <FieldSelect
                label={t('form.sellerId.label')}
                options={[
                  ...selectSellers.options,
                  ...selectAccessOrderModule.options,
                ]}
                isLoading={
                  selectSellers.isLoading || selectAccessOrderModule.isLoading
                }
                isRequired
                placeholder={t('form.sellerId.placeholder')}
                error={errors.sellerId as FieldError}
                {...field}
              />
            )}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="purchaseCategoryIds"
            control={control}
            render={({ field }) => (
              <FieldSelect
                isMulti
                label={t('form.purchaseCategoryIds.label')}
                options={selectSalesCategory.options}
                isLoading={selectSalesCategory.isLoading}
                placeholder={t('form.purchaseCategoryIds.placeholder')}
                error={errors.purchaseCategoryIds as FieldError}
                isRequired
                {...field}
              />
            )}
          />
        </GridItem>

        <GridItem>
          <FieldRadioButton
            label={t('form.purchaseType.label')}
            name="purchaseType"
            control={control}
            options={purchaseTypeOptions}
            radioButtonDirection="row"
          />
        </GridItem>

        <GridItem>
          <Controller
            name="isCobranding"
            control={control}
            defaultValue={false}
            render={({ field: { value, ref, name, onChange } }) => (
              <FieldToggle
                label={t('form.isCobranding.label')}
                ref={ref}
                name={name}
                isChecked={value}
                onChange={onChange}
                error={errors.isCobranding}
              />
            )}
          />
        </GridItem>

        <GridItem>
          <FieldTextArea
            label={t('form.comments.label')}
            placeholder={t('form.comments.placeholder')}
            error={errors.comments}
            helperText={
              'Os comentários serão salvos na aba Histórico e não serão públicos para o cliente final'
            }
            {...register('comments')}
          />
        </GridItem>
      </Grid>
    </FormWrapper>
  )
}
