// Vendors
import React from 'react'

// Styles and Icons
import { Box, Flex, Heading, Text, Image } from '@chakra-ui/react'
import LayoutError from 'layout/Error'
import { Button } from 'components/Button'

// Components

// Hooks
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

// Services

// Types

/*
|-----------------------------------------------------------------------------
| Page
|-----------------------------------------------------------------------------
|
|
*/

const Page500 = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('common')
  const { push } = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutError title={t('pages_private.page_404')}>
      <Flex
        w="100%"
        height={{ base: 'calc(100vh - 48px)', lg: '100%' }}
        alignItems="center"
        justifyContent="center"
        px={{ base: 8, lg: 24 }}
      >
        <Flex
          flexDirection={{ base: 'column', xl: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          w="100%"
          maxWidth="1440px"
          mx="auto"
        >
          <Box maxWidth="417px">
            <Heading as="h1" textColor="#7C7C7C" fontSize={64} fontWeight={700}>
              500
            </Heading>
            <Text fontSize={{ base: 32, lg: 40 }} textColor="#7C7C7C" mt={4}>
              Ops! Ocorreu um erro!
            </Text>
            <Text fontSize={{ base: 16, lg: 24 }} textColor="#7C7C7C" mt={3}>
              Fique tranquilo, estamos cientes e trabalhando na correção.
            </Text>
            <Button
              text="IR PARA A HOME"
              onClick={() => push('/dashboard')}
              mt={{ base: 5, lg: 10 }}
              bg="blue.500"
              color="white"
              _hover={{
                bg: 'blue.600',
              }}
              _active={{
                bg: 'blue.600',
              }}
            />
          </Box>
          <Image
            src="/500.svg"
            mt={{ base: 20, lg: '' }}
            w={{ base: '85%', lg: 'auto' }}
          />
        </Flex>
      </Flex>
    </LayoutError>
  )
}

export default Page500
