import { ChangeEvent } from 'react'

export function handleChangeActivateCode(e: ChangeEvent<HTMLInputElement>) {
  const { value } = e.target

  const valueCharacters = value.replace(/[^\w\s]/gi, '')
  const valueLength = valueCharacters.length

  if (valueLength === 16) {
    return valueCharacters.replace(
      /([a-zA-Z0-9]{4})([a-zA-Z0-9]{4})([a-zA-Z0-9]{4})([a-zA-Z0-9]{4})/g,
      '$1-$2-$3-$4'
    )
  } else {
    return valueCharacters.replace(/[^\w\s]/gi, '')
  }
}
