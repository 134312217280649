// Vendors
import React from 'react'
import { useTranslation } from 'react-i18next'

// Components
import { InfluencerFormNew } from '../form/new'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

import { PageMetaSetting } from './meta'

export const PageInfluencerNewRecord = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('influencer')
  const { endpoint, title } = PageMetaSetting

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={t('common:register', { title: t(title) })}>
      <InfluencerFormNew endpoint={endpoint} />
    </LayoutLoggedPageList>
  )
}
