/* eslint-disable react/jsx-key */
// @ts-nocheck

import React from 'react'
import { useTable, usePagination } from 'react-table'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Box,
  Flex,
  Button,
  Select,
  useColorModeValue as mode,
  ButtonGroup,
  Text,
  HStack,
} from '@chakra-ui/react'
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi'
import Todo from 'tools/Todo'

interface TableWithPaginationProps {
  columns: Array<{ Header: string; accessor: string }>
  data: any
  RowAction?: () => void
}

export const TableWithPagination = (props: TableWithPaginationProps) => {
  // Use the state and functions returned from useTable to build your UI
  const { columns, data, RowAction } = props
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  )

  // Render the UI for your table
  return (
    <Box>
      <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/10" />

      <Table {...getTableProps()} my="8" borderWidth="1px" fontSize="sm">
        <Thead bg={mode('gray.50', 'gray.800')}>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps()}
                  whiteSpace="nowrap"
                  scope="col"
                >
                  {column.render('Header')}
                </Th>
              ))}
              <Th />
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <Tr
                {...row.getRowProps()}
                transition="all 0.1s"
                _hover={RowAction ? { bg: 'gray.100' } : { bg: 'unset' }}
                cursor={RowAction ? 'pointer' : 'unset'}
                onClick={() => {
                  if (!RowAction) return
                  RowAction(row.original.id)
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <Td {...cell.getCellProps()} whiteSpace="nowrap">
                      {cell.render('Cell')}
                    </Td>
                  )
                })}

                <Td textAlign="right">
                  <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/12" />
                  <Button variant="link" colorScheme="blue">
                    Editar
                  </Button>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      <Flex align="center" justify="space-between">
        <HStack cursor="pointer" userSelect="none">
          <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
            Mostrando
          </Text>
          <Select
            w="70px"
            size="sm"
            rounded="md"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 25, 50, 75, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>
          <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
            de {data.length} registros
          </Text>
        </HStack>

        <ButtonGroup variant="outline" size="sm">
          <Button
            as="a"
            cursor="pointer"
            rel="first"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FiChevronsLeft />
          </Button>
          <Button
            as="a"
            cursor="pointer"
            rel="prev"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <FiChevronLeft />
          </Button>
          {/* TODO: Update input page number when page is updated */}
          {/* <Input
            size="sm"
            borderRadius="6"
            w="12"
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
          /> */}
          <Box
            px="3"
            py="1"
            fontSize="sm"
            border="1px"
            rounded="md"
            borderColor="gray.200"
          >
            {pageIndex + 1}
          </Box>
          <Button
            as="a"
            cursor="pointer"
            rel="next"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <FiChevronRight />
          </Button>
          <Button
            as="a"
            cursor="pointer"
            rel="last"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <FiChevronsRight />
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  )
}
