import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useTheme,
  VStack,
} from '@chakra-ui/react'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'
import { BiError } from 'react-icons/bi'
import { FaCheck } from 'react-icons/fa'
import { RiErrorWarningLine } from 'react-icons/ri'

import {
  FeedbackContextProviderProps,
  FeedbackContextValues,
  FeedbackOptions,
  FeedbackStatus,
} from './types'

export const FeedbackContext = createContext<FeedbackContextValues>(
  {} as FeedbackContextValues
)

export const FeedbackContextProvider = (
  props: FeedbackContextProviderProps
) => {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { children } = props
  const [feedbackOptions, setFeedbackOptions] = useState<FeedbackOptions>(
    {} as FeedbackOptions
  )

  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    colors: { green, red, orange },
  } = useTheme()

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */
  const feedback = useCallback(
    async (options: FeedbackOptions) => {
      const { title, description, status, duration } = options

      setFeedbackOptions({
        title,
        description,
        status,
        duration,
      })

      onOpen()
    },

    [onOpen]
  )

  const icon = useCallback(
    (status: FeedbackStatus) => {
      const options: Record<FeedbackStatus, ReactNode> = {
        success: <FaCheck size={20} color={green[600]} />,
        error: <BiError size={20} color={red[600]} />,
        warning: <RiErrorWarningLine size={20} color={orange[600]} />,
      }

      return options[status]
    },
    [green, red, orange]
  )

  const color = useCallback(
    (status: FeedbackStatus) => {
      const options: Record<FeedbackStatus, string> = {
        success: green[100],
        error: red[100],
        warning: orange[100],
      }

      return options[status]
    },
    [green, red, orange]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FeedbackContext.Provider value={{ feedback }}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton size="sm" />
          <ModalBody p={4}>
            <HStack alignItems="flex-start" spacing={3}>
              <Flex
                backgroundColor={color(feedbackOptions.status)}
                alignItems="center"
                borderRadius="full"
                p={2}
              >
                {icon(feedbackOptions.status)}
              </Flex>

              <VStack alignItems="flex-start" spacing={0}>
                <Heading as="h2" fontSize="md">
                  {feedbackOptions.title}
                </Heading>
                <Text color="gray.700" fontSize="md">
                  {feedbackOptions.description}
                </Text>
              </VStack>
            </HStack>
          </ModalBody>

          <ModalFooter
            borderTopWidth="1px"
            borderTopStyle="solid"
            borderTopColor="gray.200"
            py={4}
            px={2}
          >
            <Button colorScheme="gray" mr={3} onClick={onClose} size="sm">
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {children}
    </FeedbackContext.Provider>
  )
}

export const useFeedback = () => {
  const context = useContext(FeedbackContext)

  return context
}
