// Vendors
import { v4 } from 'uuid'
import React, { useCallback } from 'react'
import { Control, Controller, RegisterOptions } from 'react-hook-form'

// Styles
import {
  Checkbox,
  CheckboxGroup,
  CheckboxProps,
  FormControl,
  FormLabel,
  Stack,
} from '@chakra-ui/react'
import { useFormHelperText } from 'hooks/useFormHelperText'

// Interfaces
type CheckboxOption = {
  name: string
  label: string | React.ReactNode
  value?: boolean
  props?: CheckboxProps
}

export interface IFieldCheckbox {
  label?: string
  control?: Control<any>
  options: Array<CheckboxOption>
  canModify?: boolean
  isDisabled?: boolean
  rules?: Omit<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >
  helperText?:
    | string
    | {
        text: string | React.ReactNode
        color?: string
      }
}

export const FieldCheckbox: React.FC<IFieldCheckbox> = (props) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { label, rules, options, control, isDisabled, helperText, canModify } =
    props
  const componentHelperText = useFormHelperText({ helperText, canModify })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const renderControllerCheckbox = useCallback(
    (option: CheckboxOption) => {
      return (
        <Controller
          key={v4()}
          name={option.name}
          control={control}
          defaultValue={false}
          rules={rules}
          render={({ field: { onChange, value, ref } }) => {
            return (
              <Checkbox
                isDisabled={isDisabled}
                key={v4()}
                ref={ref}
                isChecked={value}
                onChange={onChange}
                {...option.props}
              >
                {option.label}
              </Checkbox>
            )
          }}
        />
      )
    },
    [control, isDisabled, rules]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormControl>
      {!!label && <FormLabel>{label}</FormLabel>}

      <CheckboxGroup colorScheme="blue">
        <Stack>
          {options.map((option) => {
            return control ? (
              renderControllerCheckbox(option)
            ) : (
              <Checkbox isDisabled={isDisabled} key={v4()} {...option.props}>
                {option.label}
              </Checkbox>
            )
          })}
        </Stack>
      </CheckboxGroup>

      {(helperText || !canModify) && componentHelperText}
    </FormControl>
  )
}
