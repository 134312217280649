// Vendors
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'

// Components
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useError } from 'hooks/useError'
import { apiPatch } from 'services/patch'

type ModalResetActivationCodeProps = {
  isOpen: boolean
  reload: () => void
  onCancel: () => void
  activationCode: string
}

type FormResetActivationCode = {
  comments: string
}

export const ModalResetActivationCode = (
  props: ModalResetActivationCodeProps
) => {
  const { isOpen, reload, onCancel, activationCode } = props
  const [isLoading, setIsLoading] = useState(false)

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormResetActivationCode>()
  const { handleError } = useError()

  const onClose = useCallback(() => {
    reset()
    onCancel()
  }, [onCancel, reset])

  const onSubmit = useCallback(
    async (data: FormResetActivationCode) => {
      try {
        // TODO: Add your logic here
        setIsLoading(true)
        await apiPatch(`/app/kit/${activationCode}/reset`, {
          comments: data.comments,
        })

        reload()
        onClose()
      } catch (error) {
        handleError(error)
      } finally {
        setIsLoading(false)
      }
    },
    [handleError, onClose, activationCode, reload]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Cancelar ativação do kit</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text color="gray.500" mb="4">
            Para cancelar a ativação do kit, por favor preencha o campo abaixo:
          </Text>

          <Stack>
            <FieldTextArea
              label="Justificativa"
              error={errors.comments}
              placeholder="Digite uma justificativa"
              {...register('comments', {
                required: 'Por favor, preencha o campo de justificativa',
              })}
            />
          </Stack>

          <Text fontSize="sm" color="orange.500" mt="4" fontStyle="italic">
            Resetar o kit implica na mudança do status do kit para o estado
            anterior ao da ativação. Essa operação é irreversível.
          </Text>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose}>Cancelar</Button>
            <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
              Confirmar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
