// Vendors
import React, { useCallback } from 'react'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useError } from 'hooks/useError'

// Components
import { FieldSelect } from 'components/FieldSelect'
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import {
  Box,
  Flex,
  Text,
  Stack,
  Button,
  Divider,
  Heading,
  useToast,
} from '@chakra-ui/react'
import { FiEdit3, FiUserCheck, FiUserX } from 'react-icons/fi'
import { apiPatch } from 'services/patch'
import { useSelectParser } from 'hooks/useSelectParser'

// Interfaces
type RefErrorType = {
  reasonRefusal?: FieldError
  reasonRevision?: FieldError
}

type NecessaryActionProps = {
  personId: string
  reload: () => void
}

export const NecessaryAction = ({
  personId,
  reload,
}: NecessaryActionProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectSeller = useSelectParser<any>({
    accessor: { value: 'userId', label: 'name' },
    endpoint: '/app/person?type=seller',
  })

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('professional')
  const toast = useToast({ position: 'top-right' })
  const { handleError } = useError()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isSubmitting, setIsSubmitting] = React.useState<{
    approve?: boolean
    revision?: boolean
    disapprove?: boolean
  }>({})
  const [refErrors, setRefErrors] = React.useState<RefErrorType>(
    {} as RefErrorType
  )

  /*
  |-----------------------------------------------------------------------------
  | Refs
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sellerRef = React.useRef<any>(null)
  const reasonApproveRef = React.useRef<HTMLTextAreaElement>(null)
  const reasonRefusalRef = React.useRef<HTMLTextAreaElement>(null)
  const reasonRevisionRef = React.useRef<HTMLTextAreaElement>(null)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleApprove = useCallback(async () => {
    if (!sellerRef.current || !reasonApproveRef.current) return

    try {
      setIsSubmitting({ approve: true })
      const sellerUserId = sellerRef.current.getValue().value
      const comments = reasonApproveRef.current.value

      await apiPatch(`/app/professional/${personId}/approve`, {
        sellerUserId,
        comments,
      })

      reload()
      toast({
        title: t('toast.success.title'),
        description: t('toast.success.description', {
          type: t('toast.success.approve'),
        }),
        status: 'success',
      })
    } catch (error: any) {
      handleError(error)
    } finally {
      setIsSubmitting({ approve: false })
    }
  }, [handleError, personId, reload, t, toast])

  const handleRevision = useCallback(async () => {
    if (!reasonRevisionRef.current) return

    const draftError = {} as RefErrorType
    setRefErrors(draftError)

    if (reasonRevisionRef.current.value === '') {
      draftError.reasonRevision = {
        type: 'required',
        message: t('form.errors.reasonRevision'),
      }
    }

    if (Object.keys(draftError).length) {
      setRefErrors(draftError)
      return
    }

    try {
      setIsSubmitting({ revision: true })
      const rejectionReason = reasonRevisionRef.current.value

      await apiPatch(`/app/professional/${personId}/revision`, {
        rejectionReason,
      })

      reload()
      toast({
        title: t('toast.success.title'),
        description: t('toast.success.description', {
          type: t('toast.success.revision'),
        }),
        status: 'success',
      })
    } catch (error: any) {
      handleError(error)
    } finally {
      setIsSubmitting({ revision: false })
    }
  }, [handleError, personId, reload, t, toast])

  const handleDisapprove = useCallback(async () => {
    if (!reasonRefusalRef.current) return

    const draftError = {} as RefErrorType
    setRefErrors(draftError)

    if (reasonRefusalRef.current.value === '') {
      draftError.reasonRefusal = {
        type: 'required',
        message: t('form.errors.reasonRefusal'),
      }
    }

    if (Object.keys(draftError).length) {
      setRefErrors(draftError)
      return
    }

    try {
      setIsSubmitting({ disapprove: true })
      const rejectionReason = reasonRefusalRef.current.value
      await apiPatch(`/app/professional/${personId}/disapprove`, {
        rejectionReason,
      })

      reload()
      toast({
        title: t('toast.success.title'),
        description: t('toast.success.description', {
          type: t('toast.success.disapprove'),
        }),
        status: 'success',
      })
    } catch (error: any) {
      handleError(error)
    } finally {
      setIsSubmitting({ disapprove: false })
    }
  }, [handleError, personId, reload, t, toast])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box boxShadow="md" borderRadius="md" w={{ xl: 'container.md' }}>
      <Box as="header" p="1rem 1.5rem 0.75rem" borderBottom="1px solid #EDF2F7">
        <Heading fontSize="lg" color="gray.900">
          {t('footer.header.title')}
        </Heading>

        <Text fontSize="sm" color="gray.600">
          {t('footer.header.description')}
        </Text>
      </Box>

      <Box as="section" p="1.5rem 2rem">
        <Stack
          flexDirection={{ base: 'column', xl: 'row' }}
          justifyContent="space-between"
        >
          <Stack w={{ xl: '75%' }}>
            <Flex>
              <FiUserCheck color="#1DB6BC" size="1.5rem" />

              <Box ml="4">
                <Heading as="h2" fontSize="md" color="gray.900">
                  {t('footer.approve.title')}
                </Heading>

                <Text fontSize="sm" color="gray.600">
                  {t('footer.approve.description')}
                </Text>
              </Box>
            </Flex>

            <Stack>
              <FieldSelect
                ref={sellerRef}
                label={t('footer.approve.seller')}
                name="seller"
                options={selectSeller.options}
                isLoading={selectSeller.isLoading}
              />

              <FieldTextArea
                ref={reasonApproveRef}
                label={t('footer.approve.reasonApprove')}
                name="reasonApprove"
              />
            </Stack>
          </Stack>

          <Button
            colorScheme="blue"
            onClick={handleApprove}
            isLoading={isSubmitting.approve}
          >
            {t('footer.approve.buttonTitle')}
          </Button>
        </Stack>
      </Box>

      <Box px="8">
        <Divider />
      </Box>

      <Box as="section" p="1.5rem 2rem">
        <Stack
          flexDirection={{ base: 'column', xl: 'row' }}
          justifyContent="space-between"
        >
          <Stack w={{ xl: '75%' }}>
            <Flex>
              <FiUserX color="#DB3D23" size="1.5rem" />

              <Box ml="4">
                <Heading as="h2" fontSize="md" color="gray.900">
                  {t('footer.disapprove.title')}
                </Heading>

                <Text fontSize="sm" color="gray.600">
                  {t('footer.disapprove.description')}
                </Text>
              </Box>
            </Flex>

            <Stack>
              <FieldTextArea
                label={t('footer.disapprove.reasonRefusal')}
                name="reasonRefusal"
                ref={reasonRefusalRef}
                error={refErrors.reasonRefusal}
              />
            </Stack>
          </Stack>

          <Button
            variant="outline"
            colorScheme="red"
            onClick={handleDisapprove}
            isLoading={isSubmitting.disapprove}
          >
            {t('footer.disapprove.buttonTitle')}
          </Button>
        </Stack>
      </Box>

      <Box px="8">
        <Divider />
      </Box>

      <Box as="section" p="1.5rem 2rem">
        <Stack
          flexDirection={{ base: 'column', xl: 'row' }}
          justifyContent="space-between"
        >
          <Stack w={{ xl: '75%' }}>
            <Flex>
              <FiEdit3 color="#DD6B20" size="1.5rem" />

              <Box ml="4">
                <Heading as="h2" fontSize="md" color="gray.900">
                  {t('footer.revision.title')}
                </Heading>

                <Text fontSize="sm" color="gray.600">
                  {t('footer.revision.description')}
                </Text>
              </Box>
            </Flex>

            <Stack>
              <FieldTextArea
                label={t('footer.revision.reasonRevision')}
                name="reasonRevision"
                ref={reasonRevisionRef}
                error={refErrors.reasonRevision}
              />
            </Stack>
          </Stack>

          <Button
            variant="outline"
            borderColor="#DD6B20"
            color="#DD6B20"
            colorScheme="red"
            onClick={handleRevision}
            isLoading={isSubmitting.revision}
          >
            {t('footer.revision.buttonTitle')}
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}
