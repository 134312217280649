import {
  Box,
  Radio,
  HStack,
  useRadio,
  useBreakpointValue,
  UseRadioProps,
} from '@chakra-ui/react'
import React, { ForwardRefRenderFunction } from 'react'

export type RadioCardProps = UseRadioProps & {
  children: React.ReactNode
}

const RadioCardBase: ForwardRefRenderFunction<any, RadioCardProps> = (
  props,
  ref
) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <Box as="label" w="full">
      <input ref={ref} {...input} />

      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          borderColor: 'teal.600',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        _hover={{
          bg: 'gray.50',
        }}
        px={5}
        py={3}
      >
        <HStack>
          <Radio size={isMobile ? 'sm' : 'md'} isChecked={props.isChecked} />

          <Box w="full">{props.children}</Box>
        </HStack>
      </Box>
    </Box>
  )
}

export const RadioCard = React.forwardRef(RadioCardBase)
