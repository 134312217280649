// Vendors
import { flatten, get, intersection, map } from 'lodash'
import React, { useCallback, useMemo, MouseEvent } from 'react'

// Contexts
import { useAuth } from 'contexts/auth'

// Components
import { ListTable } from 'components/ListTable'

// Styles
import { Box } from '@chakra-ui/react'

// Hooks
import { useError } from 'hooks/useError'
import { useDownload } from 'hooks/useDownload'
import { REPORT_DOWNLOAD_PERMISSION_ROLES } from 'constants/constants'

// Types
type AnalysisReportFilesType = {
  id: string
  fileName: string
  originalFileName: string
  laboratoryAnalysis?: LaboratoryAnalysisType
}

type LaboratoryAnalysisType = {
  id: string
  name: string
}

export type PurchaseHasProductHasAnalysisType = {
  analysisReportFiles: AnalysisReportFilesType[]
  laboratoryAnalysis: LaboratoryAnalysisType
}

type TabReportProps = {
  activationCode?: string
  purchaseHasProductHasAnalysis?: PurchaseHasProductHasAnalysisType[]
}

export const TabReport = (props: TabReportProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { activationCode, purchaseHasProductHasAnalysis } = props

  const { user } = useAuth()
  const { handleError } = useError()
  const { onDownload } = useDownload()

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDownloadReport = useCallback(
    async (e: MouseEvent<HTMLButtonElement>, item: AnalysisReportFilesType) => {
      e.stopPropagation()

      try {
        if (!activationCode) {
          throw new Error('Código de ativação não encontrado')
        }

        const { id, laboratoryAnalysis, originalFileName } = item

        if (!laboratoryAnalysis) {
          throw new Error('Análise laboratorial não encontrada')
        }

        const endpointDownloadReport = `${process.env.REACT_APP_API_URL}app/reverse_logistic/${activationCode}/analysis/${laboratoryAnalysis.id}/report/${id}/download_report`

        await onDownload(endpointDownloadReport, originalFileName)
      } catch (error: any) {
        console.error(error)

        handleError(error)
      }
    },
    [activationCode, handleError, onDownload]
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const memoRecords = useMemo(() => {
    const analysisReportFiles = flatten(
      map(purchaseHasProductHasAnalysis, (item) => {
        return map(item.analysisReportFiles, (file) => {
          return {
            ...file,
            laboratoryAnalysis: item.laboratoryAnalysis,
          }
        })
      })
    )

    return {
      list: analysisReportFiles || [],
    }
  }, [purchaseHasProductHasAnalysis])

  const listTableHeaders = useMemo(
    () => [
      {
        label: 'Nome',
        accessor: 'title',
      },
      {
        label: 'Análise Laboratorial',
        accessor: 'laboratoryAnalysis.name',
      },
      {
        label: 'Comentário interno',
        accessor: 'comment',
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <ListTable
        headers={listTableHeaders}
        records={memoRecords}
        defaultActions="none"
        actions={[
          {
            type: 'report',
            options: {
              callback: handleDownloadReport,
              notDisableWhen: () => {
                const roles = map(get(user, 'roleUser'), 'role.name')

                return (
                  intersection(roles, REPORT_DOWNLOAD_PERMISSION_ROLES)
                    .length === 0
                )
              },
            },
          },
        ]}
      />
    </Box>
  )
}
