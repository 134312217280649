import React, { FC, useCallback, useMemo } from 'react'
import { Redirect, Route as RouterRoute, RouteProps } from 'react-router-dom'

import { useAuth } from 'contexts/auth'

import Loading from 'pages/public/Loading'

import { UserRolesNames } from 'types/User'
import { intersection, map } from 'lodash'

interface CustomRouteProps extends RouteProps {
  isPrivate?: boolean
  component: React.ComponentType
  provider?: ({ children }: any) => JSX.Element
  allowedRoles?: Array<keyof typeof UserRolesNames>
}

export const Route: FC<CustomRouteProps> = ({
  isPrivate = true,
  component: Component,
  provider: Provider,
  path,
  allowedRoles,
  ...routeProps
}) => {
  const { isLoggedIn, isLoading, user } = useAuth()

  const isAllowed = useMemo(() => {
    if (!allowedRoles) return true

    if (allowedRoles.length === 0) return true

    const userRoles = map(user?.roleUser, 'role.name')
    if (userRoles.includes(UserRolesNames.SUPERADMIN)) return true

    return intersection(allowedRoles, userRoles).length > 0
  }, [allowedRoles, user?.roleUser])

  const renderRule = useCallback(() => {
    if (isLoading) return <Loading />

    if (isLoggedIn === isPrivate && isAllowed) {
      if (Provider)
        return (
          <Provider>
            <Component />
          </Provider>
        )

      return <Component />
    }

    return <Redirect to={{ pathname: isPrivate ? '/login' : '/dashboard' }} />
  }, [isLoading, isLoggedIn, isPrivate, isAllowed, Provider, Component])

  return <RouterRoute render={renderRule} {...routeProps} />
}
