// Vendors
import React, { Dispatch, SetStateAction } from 'react'
import { Controller, useFormContext, FieldError } from 'react-hook-form'
import _, { get } from 'lodash'

// Components
import { FieldText } from 'components/FieldText'
import { FieldRepeater } from 'components/FieldRepeater'
import { FieldTextArea } from 'components/FieldTextArea'
import { FieldSelectColor } from 'components/FieldSelectColor'

// Styles
import { Heading, Grid, GridItem, Box } from '@chakra-ui/react'

// Types
import { AccentColorEnum } from '../../show/types'
// import { TextEditor } from 'components/TextEditor'
import { FieldToggle } from 'components/FieldToggle'
import { FieldNoImage } from 'components/FieldNoImage'

const TextEditor = React.lazy(() => import('components/TextEditor'))
type TabMarketingProps = {
  endpoint: string
  setTextEditorData: Dispatch<SetStateAction<string>>
  textEditorData: string
  accentColorOptions: Array<{
    value: AccentColorEnum
    label: string
  }>
}

export const TabMarketing = (props: TabMarketingProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { setTextEditorData, accentColorOptions, textEditorData } = props

  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext()

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <Box my={{ base: 2, lg: 4 }}>
        <Heading as="h2" fontSize={20} textColor="#000000">
          Informações iniciais
        </Heading>
        <Grid
          gap="10"
          my="4"
          templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
        >
          <GridItem colSpan={{ base: 2, lg: 1 }}>
            <Controller
              name="accentColor"
              control={control}
              render={({ field }) => (
                <FieldSelectColor
                  label="Cor de destaque"
                  error={errors.accentColor as FieldError}
                  options={accentColorOptions}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2, lg: 1 }}>
            <Controller
              name="isPage"
              control={control}
              render={({ field: { value, ref, name, onChange } }) => (
                <FieldToggle
                  name={name}
                  label="Apresentar como página "
                  ref={ref}
                  isChecked={value}
                  error={errors.isPage}
                  onChange={onChange}
                />
              )}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2 }} />

          <GridItem colSpan={{ base: 2 }}>
            <FieldText
              maxLength={45}
              currentLength={get(watch('mktTitle'), 'length')}
              label="Título da página"
              error={errors.mktTitle}
              isRequired={!!watch('isPage')}
              {...register('mktTitle')}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2 }} />

          <GridItem colSpan={{ base: 2 }}>
            <FieldTextArea
              maxLength={50}
              currentLength={get(watch('mktDescription'), 'length')}
              label="Descrição da página"
              error={errors.mktDescription}
              isRequired={!!watch('isPage')}
              {...register('mktDescription')}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2 }}>
            <FieldTextArea
              label="Resumo da página"
              error={errors.mktAbstract}
              isRequired={!!watch('isPage')}
              {...register('mktAbstract')}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2, lg: 4 }}>
            <FieldNoImage label="Imagem de destaque" />
          </GridItem>

          <GridItem colSpan={{ base: 2, lg: 4 }}>
            <FieldNoImage label="Ícone" />
          </GridItem>
        </Grid>
      </Box>

      <Box my={{ base: 2, lg: 4 }}>
        <Heading as="h2" fontSize={20} textColor="#000000">
          Introdução
        </Heading>
        <Grid
          gap="10"
          my="4"
          templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
        >
          <GridItem colSpan={{ base: 2, lg: 2 }}>
            <FieldText
              maxLength={120}
              currentLength={get(watch('mktTitleIntrodutoryText'), 'length')}
              label="Título do texto introdutório"
              error={errors.mktTitleIntrodutoryText}
              isRequired={!!watch('isPage')}
              {...register('mktTitleIntrodutoryText')}
            />
          </GridItem>
          <GridItem colSpan={{ base: 2, lg: 4 }}>
            <TextEditor
              setTextEditorData={setTextEditorData}
              defaultValue={textEditorData}
            />
          </GridItem>
        </Grid>
      </Box>

      <Box my={{ base: 2, lg: 4 }}>
        <FieldRepeater
          name="mktFeatures.features"
          error={errors.mktFeatures?.features}
          label="Features"
          control={control}
          register={register}
          startWith={1}
          gridProps={{ columns: 2, gap: 4 }}
          defaultValue={{ icon: '', label: '', url: '' }}
          fieldComponents={[
            {
              component: FieldText,
              type: 'register',
              props: {
                name: 'title',
                placeholder: 'Digite...',
                label: 'Título',
                isRequired: true,
              },
            },
            {
              component: FieldText,
              type: 'register',
              props: {
                name: 'description',
                placeholder: 'Digite...',
                label: 'Descrição',
                isRequired: true,
              },
            },
          ]}
        />
      </Box>

      <GridItem colSpan={{ base: 2, lg: 4 }}>
        <FieldNoImage label="Imagem de destaque feature" />
      </GridItem>

      <Box my={{ base: 2, lg: 4 }}>
        <Heading as="h2" fontSize={20} textColor="#000000">
          Entenda o laudo
        </Heading>
        <Grid
          gap="10"
          my="4"
          templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
        >
          <GridItem colSpan={{ base: 2, lg: 2 }}>
            <FieldText
              maxLength={45}
              currentLength={get(watch('mktTitleReport'), 'length')}
              label="Título"
              error={errors.mktTitleReport}
              isRequired={!!watch('isPage')}
              {...register('mktTitleReport')}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2, lg: 2 }}>
            <FieldTextArea
              label="Descrição"
              maxLength={1024}
              currentLength={get(watch('mktDescriptionReport'), 'length')}
              error={errors.mktDescriptionReport}
              isRequired={!!watch('isPage')}
              {...register('mktDescriptionReport')}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2, lg: 4 }}>
            <FieldNoImage label="Imagem de destaque do laudo" />
          </GridItem>

          <GridItem colSpan={{ base: 2, lg: 4 }}>
            <FieldNoImage label="PDF do laudo" />
          </GridItem>
        </Grid>
      </Box>
    </Box>
  )
}
