// Vendors
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import { TabDetail, Tabs } from 'components/Tabs'
import { TabPerformance } from '../tabs/TabPerformace'
import { TabInfluencerShow } from './tabs/TabInfluencerShow'

// Styles
import { Flex } from '@chakra-ui/react'

// Types
type InfluencerFormShowProps = {
  id: string
  endpoint: string
}

export const InfluencerFormShow = (
  props: InfluencerFormShowProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('influencer')
  const { id, endpoint } = props

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: t('tabs.personalData'),
        component: TabInfluencerShow,
        options: { id, endpoint },
      },
      // {
      //   title: t('tabs.performance'),
      //   component: TabPerformance,
      // },
    ],
    [t, id, endpoint]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex h="full" flexDirection="column" justifyContent="space-between">
      <Tabs tabsDetails={tabsDetails} />
    </Flex>
  )
}
