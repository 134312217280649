// Vendors
import React, { Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router'

// Components
import { FieldText } from 'components/FieldText'
// import { TextEditor } from 'components/TextEditor'
import { ListImages } from 'components/ListImages'

// Styles
import { Grid, GridItem } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { SampleTransportKitHasFile } from 'types/SampleTransportKit'
import { FieldSet } from 'components/FieldSet'

const TextEditor = React.lazy(() => import('components/TextEditor'))

type TabMarketingProps = {
  textEditorData: string
  setTextEditorData: Dispatch<SetStateAction<string>>
  setComplementaryImages: Dispatch<SetStateAction<File[]>>
  recordReload: () => void
  recordCurrentFiles: SampleTransportKitHasFile[]
}

export const TabMarketing = (props: TabMarketingProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    textEditorData,
    setTextEditorData,
    recordReload,
    recordCurrentFiles,
  } = props

  const {
    register,
    formState: { errors },
  } = useFormContext()

  const params: { id: string } = useParams()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FieldSet title="Descrição de conteúdo do kit">
      <Grid
        my={4}
        gap={4}
        templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }}
      >
        <GridItem colSpan={2}>
          <ListImages
            title="imagem"
            records={{
              get: { list: recordCurrentFiles || [] },
              set: () => {},
            }}
            endpoint={{
              post: `/app/sample_transport_kit/${params.id}/upload`,
              delete: `/app/sample_transport_kit/${params.id}/image`,
              patch: `/app/sample_transport_kit/${params.id}/image`,
            }}
            reloadRecord={recordReload}
            group="FEATURED_IMAGE"
            label="Imagem de exemplo genérica do kit aberto:"
          />
        </GridItem>

        <GridItem colSpan={2}>
          <TextEditor
            setTextEditorData={setTextEditorData}
            defaultValue={textEditorData}
            label="Descrição para o conteúdo do kit:"
            helperText="Instruções de uso do kit."
          />
        </GridItem>

        <GridItem colSpan={{ base: 2, lg: 1 }}>
          <FieldText
            label="Link do vídeo de instrução de uso do kit:"
            error={errors.kitVideoInstructions}
            helperText="Este vídeo aparece na home."
            isRequired
            {...register('kitVideoInstructions')}
          />
        </GridItem>
      </Grid>
    </FieldSet>
  )
}
