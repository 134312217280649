import { TFunction } from 'react-i18next'
import { BlogStatusEnum } from 'types/Blog'
import { yupSchema } from 'utils/yupSchema'

import * as yup from 'yup'

export const schema = (t: TFunction<'common'>) => {
  yupSchema(t)

  return yup.object().shape({
    title: yup.string().required().label(t('form.blog.title.label')),

    subtitle: yup.string().required().label(t('form.blog.subtitle.label')),

    featured: yup.boolean().label(t('form.blog.featured.label')),

    articleLead: yup.string().max(280).label(t('form.blog.articleLead.label')),

    articleAuthor: yup.string().label(t('form.blog.articleAuthor.label')),

    accentColor: yup
      .string()
      .selectValue()
      .required()
      .min(1)
      .label(t('form.blog.accentColor.label')),

    tagIds: yup
      .array()
      .selectValues()
      .required()
      .min(1)
      .label(t('form.blog.tags.label')),

    categoryId: yup
      .string()
      .selectValue()
      .required()
      .label(t('form.blog.categories.label')),

    publishingDate: yup
      .date()
      .required()
      .label(t('form.blog.publishingDate.label')),

    status: yup
      .string()
      .selectValue()
      .required()
      .oneOf(Object.keys(BlogStatusEnum))
      .label(t('form.blog.status.label')),

    // SEO
    seoTitle: yup
      .string()
      .required()
      .label(t('common:form.tabSeo.seoTitle.label')),

    seoDescription: yup
      .string()
      .required()
      .label(t('common:form.tabSeo.seoDescription.label')),

    slug: yup
      .string()
      .kebabCase()
      .required()
      .label(t('common:form.tabSeo.slug.label')),

    seoKeywords: yup
      .array()
      .required()
      .selectValues()
      .label(t('common:form.tabSeo.seoKeywords.label')),

    seoAdditionalKeywords: yup
      .string()
      .label(t('common:form.tabSeo.seoAdditionalKeywords.label')),
  })
}
