// Vendors
import { map, orderBy, uniq } from 'lodash'
import axios, { AxiosError } from 'axios'
import { useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import React, {
  useMemo,
  useState,
  useEffect,
  MouseEvent,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'

// Functions
import { apiPatch } from 'services/patch'
import { useError } from 'hooks/useError'
import { useListRecords } from 'hooks/useListRecords'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListPagination } from 'components/ListPagination'
import { AlertDialogPrinter } from 'components/AlertDialogPrinter'
import {
  ListTableRenderAs,
  StatusExpedition,
} from 'components/ListTableRowComponent'
import { ModalAssingId } from 'components/ModalAssignId'
import { AlertDialogDelivery } from 'components/AlertDialogDelivery'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'
import {
  Stack,
  HStack,
  Select,
  useToast,
  useDisclosure,
} from '@chakra-ui/react'

// Types
import { PageMetaSetting } from './meta'
import { Record } from 'types/Requests'
import { PurchaseHasProductType } from '../show/types'

type ListRecords = Record & {
  price: string
  status: string
  purchaseNumber: string
  purchaseDeliveryId: string
  deliveryTrackingCode: string
  paymentMethodName: string
  shippingMethodName: string
  shippingValue: string
  purchaseHasProducts: PurchaseHasProductType[]
  kitsAmount: string
  kitsName: string
  isCobranding: boolean
  isCobrandingFormatted: string
  buyerName: string | null
  clinicName: string | null
}

export const PageCorreiosListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { handleError } = useError()
  const methods = useForm()
  const methodsAssignId = useForm()

  const history = useHistory()
  const { t } = useTranslation('common')

  const disclosurePrinter = useDisclosure()
  const disclosureAssignId = useDisclosure()
  const disclosureDelivery = useDisclosure()

  const toast = useToast({ position: 'top-right' })

  const { endpoint, title } = PageMetaSetting
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<ListRecords>({
    endpoint,
    query: {
      status: 'IN_EXPEDITION',
      isCourier: true,
      sort: '-purchaseNumber',
    },
  })

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [record, setRecord] = useState<ListRecords>({} as ListRecords)
  const [purchaseHasProducts, setPurchaseHasProducts] = useState<
    PurchaseHasProductType[]
  >([])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    Records.list.forEach((record) => {
      const kitsNameUnformatted = record.purchaseHasProducts.map(
        (product) => product.storeProduct.mktTitle
      )

      const kitsNameFiltered = uniq(kitsNameUnformatted)

      record.kitsAmount = String(record.purchaseHasProducts.length)
      record.kitsName = kitsNameFiltered.join(', ')
      record.buyerName = record.clinicName || record.buyerName

      record.isCobrandingFormatted = record.isCobranding ? 'COBRANDING' : '-'
    })
  }, [Records.list])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const ListTableHeaders = useMemo(
    () => [
      {
        label: 'Cód. pedido',
        accessor: 'purchaseNumber',
      },
      {
        label: 'Nome do destinatário',
        accessor: 'buyerName',
      },
      {
        label: 'Qtd. kits',
        accessor: 'kitsAmount',
      },
      {
        label: 'Produtos solicitados',
        accessor: 'kitsName',
        render: { as: ListTableRenderAs.MULTILINE_TEXT },
      },
      {
        label: 'Forma de entrega',
        accessor: 'shippingMethodName',
      },
      {
        label: t('list_table.headers.status'),
        accessor: 'status',
        render: { as: ListTableRenderAs.BADGE },
      },
      {
        label: 'Origem',
        accessor: 'purchaseOrigin',
        render: {
          as: ListTableRenderAs.TEXT,
          options: {
            textTransform: 'uppercase',
          },
        },
      },
      {
        label: 'Cobranding',
        accessor: 'isCobrandingFormatted',
      },
    ],
    [t]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const fnTableActionProps = useCallback((record: any) => {
    return {
      bg: record.isCobranding
        ? 'linear-gradient(90deg, rgba(253, 201, 11, 0.144) 0.01%, rgba(254, 223, 110, 0.207217) 0.06%, rgba(255, 255, 255, 0.3) 5%);'
        : '',
    }
  }, [])

  const ListTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/correios/${Record.id}`)
    },
    [history]
  )

  const handleOpenAssignId = useCallback(
    (_: MouseEvent<HTMLButtonElement>, item: ListRecords) => {
      const purchaseHasProducts = orderBy(
        item.purchaseHasProducts.map((purchaseHasProduct) => ({
          ...purchaseHasProduct,
          activationCode: '',
        })),
        ['storeProduct.mktTitle'],
        ['asc']
      )

      methodsAssignId.setValue('activationCodes', purchaseHasProducts)
      disclosureAssignId.onOpen()
    },
    [disclosureAssignId, methodsAssignId]
  )

  const handlePrint = useCallback(async () => {
    console.log(record)
  }, [record])

  const handleOpenDelivery = useCallback(
    (e: MouseEvent<HTMLButtonElement>, item) => {
      e.stopPropagation()
      setRecord(item)
      disclosureDelivery.onOpen()
    },
    [disclosureDelivery]
  )

  const handleDelivery = useCallback(async () => {
    try {
      const { note, deliveryTrackingCode } = methods.getValues()

      await apiPatch(`/app/expedition/${record.purchaseDeliveryId}/status`, {
        note,
        deliveryTrackingCode,
        status: 'SENT',
      })

      toast({
        title: 'Sucesso',
        description: 'Status atualizado com sucesso!',
        status: 'success',
      })

      Records.reload()
      disclosureDelivery.onClose()
    } catch (error: any) {
      if (!axios.isAxiosError(error)) {
        console.trace(error)
      }

      if (!(error as AxiosError).response?.data) {
        console.error(error)
      }

      handleError(error)
    }
  }, [
    Records,
    disclosureDelivery,
    handleError,
    methods,
    record.purchaseDeliveryId,
    toast,
  ])

  const handleChangeStatus = useCallback(
    async (e, item, status: keyof typeof StatusExpedition) => {
      try {
        await apiPatch(`/app/expedition/${item.purchaseDeliveryId}/status`, {
          status,
        })

        toast({
          title: 'Sucesso',
          description: 'Status atualizado com sucesso!',
          status: 'success',
        })

        Records.reload()
      } catch (error: any) {
        handleError(error)
      }
    },
    [Records, handleError, toast]
  )

  const handleAssignId = useCallback(
    async (data: { activationCodes: PurchaseHasProductType[] }) => {
      const { activationCodes } = data

      const activationCodesFormatted = map(activationCodes, (item) => ({
        purchaseHasProductId: item.id,
        activationCode: item.activationCode,
      }))

      try {
        await apiPatch('/app/purchase_product/activation_code', {
          activationCodes: activationCodesFormatted,
        })

        toast({
          title: 'Sucesso',
          description: 'Códigos de ativação alterado com sucesso!',
          status: 'success',
        })

        disclosureAssignId.onClose()
      } catch (error) {
        handleError(error)
      }
    },
    [disclosureAssignId, handleError, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutLoggedPageList title={title}>
      <FormProvider {...methodsAssignId}>
        <ModalAssingId
          isOpen={disclosureAssignId.isOpen}
          onCancel={disclosureAssignId.onClose}
          onConfirm={methodsAssignId.handleSubmit(handleAssignId)}
        />
      </FormProvider>

      <FormProvider {...methods}>
        <AlertDialogDelivery
          isOpen={disclosureDelivery.isOpen}
          onCancel={disclosureDelivery.onClose}
          onConfirm={methods.handleSubmit(handleDelivery)}
        />
      </FormProvider>

      <AlertDialogPrinter
        kitCode={map(purchaseHasProducts, 'activationCode')}
        isOpen={disclosurePrinter.isOpen}
        onCancel={disclosurePrinter.onClose}
        onConfirm={handlePrint}
      />

      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ListFilters query={Query}>
          <HStack>
            <Select
              size="sm"
              placeholder="Status..."
              onChange={(e) => {
                Query.set({
                  ...Query.current,
                  status: e.target.value,
                })
              }}
            >
              <option selected value="IN_EXPEDITION">
                Em expedição
              </option>
              <option value="SENT">Enviado</option>
            </Select>

            <Select
              size="sm"
              onChange={(e) =>
                Query.set({ ...Query.current, sort: e.target.value })
              }
            >
              <option value="-purchaseNumber">Número do pedido</option>
              <option value="-updatedAt">Mais recentes</option>
            </Select>
          </HStack>
        </ListFilters>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={ListTableRowAction}
        defaultActions="none"
        trProps={fnTableActionProps}
        actions={[
          {
            type: 'alterShippingMethod',
            options: {
              reload: Records.reload,
              showWhen: { status: 'IN_EXPEDITION' },
            },
          },
          {
            type: 'assignId',
            options: {
              callback: handleOpenAssignId,
              showWhen: { status: 'IN_EXPEDITION' },
            },
          },
          {
            type: 'delivery',
            options: {
              callback: handleOpenDelivery,
              showWhen: { status: 'IN_EXPEDITION' },
            },
          },
          {
            type: 'inTransit',
            options: {
              callback: (e: MouseEvent<HTMLButtonElement>, item: any) =>
                handleChangeStatus(e, item, 'IN_TRANSIT'),
              showWhen: { status: 'SENT' },
            },
          },
          {
            type: 'delivered',
            options: {
              callback: (e: MouseEvent<HTMLButtonElement>, item: any) =>
                handleChangeStatus(e, item, 'DELIVERED'),
              showWhen: { status: 'IN_TRANSIT' },
            },
          },
        ]}
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
