// Vendors
import React from 'react'

// Components
import Todo from 'tools/Todo'

// Styles
import { ButtonGroup } from '@chakra-ui/react'

// Interfaces
export type ListActionsProps = {
  children?: React.ReactNode
}

export const ListActions = (props: ListActionsProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { children } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <ButtonGroup size="sm" variant="outline">
      {children}
    </ButtonGroup>
  )
}
