// Vendors
import Lottie from 'react-lottie'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'

// Hooks
import { useError } from 'hooks/useError'

// Components
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import {
  Text,
  Stack,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ScaleFade,
  Flex,
  Circle,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react'
import { apiPost } from 'services/post'
import { FiCheck } from 'react-icons/fi'
import animationData from 'assets/animations/check-animation.json'

type ModalGenerateNewPaymentLinkProps = {
  isOpen: boolean
  purchaseId: string
  onCancel: () => void
}

type FormAlterKitStatus = {
  comments: string
}

export const ModalGenerateNewPaymentLink = (
  props: ModalGenerateNewPaymentLinkProps
) => {
  const { isOpen, onCancel, purchaseId } = props

  const scaleFade = useDisclosure()
  const [paymentLink, setPaymentLink] = useState<string | undefined>(undefined)
  const { onCopy, hasCopied } = useClipboard(paymentLink || '')

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormAlterKitStatus>()

  const { handleError } = useError()

  const onClose = useCallback(() => {
    reset()
    onCancel()
    setPaymentLink(undefined)
    scaleFade.onClose()
  }, [onCancel, reset, scaleFade])

  const onSubmit = useCallback(
    async (data: FormAlterKitStatus) => {
      try {
        const response = await apiPost<any>(
          `/app/purchase/${purchaseId}/payment_link`,
          {
            comments: data.comments,
          }
        )

        if (!response) return

        setPaymentLink(response.url)
        scaleFade.onOpen()
      } catch (error) {
        handleError(error)
      }
    },
    [handleError, purchaseId, scaleFade]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Atenção</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          {paymentLink && (
            <ScaleFade initialScale={0.9} in={scaleFade.isOpen}>
              <Flex justifyContent="center" flexDirection="column" pb="2">
                <Stack alignItems="center">
                  <Circle size="12" bg="blue.100" mb="1.5rem !important">
                    <FiCheck size="24" />
                  </Circle>

                  <Text
                    fontSize="sm"
                    color="gray.500"
                    textAlign="center"
                    w="80"
                  >
                    Copie o o link abaixo e encaminhe ao cliente para efetuar o
                    pagamento e concluir o pedido.
                  </Text>
                </Stack>

                <Flex
                  justifyContent="center"
                  borderStyle="groove"
                  borderWidth="1px"
                  w="full"
                  p="4"
                  mt="8"
                  mb="10"
                >
                  <Text fontWeight="bold" fontSize="xl" color="#6A6A6A">
                    {paymentLink}
                  </Text>
                </Flex>

                <Button colorScheme="blue" onClick={onCopy}>
                  {hasCopied ? (
                    <Lottie
                      height={32}
                      width={32}
                      options={{
                        animationData,
                        autoplay: true,
                        loop: true,
                      }}
                    />
                  ) : (
                    'COPIAR LINK'
                  )}
                </Button>
              </Flex>
            </ScaleFade>
          )}

          {!paymentLink && (
            <>
              <Text color="gray.500" mb="4">
                Ao realizar esta operação você criará um novo link de pagamento
                para este pedido. Por favor, certifique-se de que não existam
                outros links de pagamento ativos antes de criar um novo.
              </Text>
              <Stack>
                <FieldTextArea
                  label="Justificativa"
                  error={errors.comments}
                  placeholder="Digite uma justificativa"
                  {...register('comments', {
                    required: 'Por favor, preencha o campo com a justificativa',
                  })}
                />
              </Stack>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            {paymentLink ? (
              <Button colorScheme="blue" onClick={onClose}>
                Fechar
              </Button>
            ) : (
              <>
                <Button onClick={onClose}>Cancelar</Button>
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                >
                  Confirmar
                </Button>
              </>
            )}
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
