// Vendors
import React from 'react'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

// Components
import { ProductFormShow } from '../form/show'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Types
import { PageMetaSetting } from './meta'

export const PageProductShowRecord = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('products')
  const { id } = useParams<{ id: string }>()
  const { endpoint, title } = PageMetaSetting

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutLoggedPageList title={t('common:edit', { title: t(title) })}>
      <ProductFormShow id={id} endpoint={endpoint} />
    </LayoutLoggedPageList>
  )
}
