// Vendors
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, FormProvider, useForm } from 'react-hook-form'

// Functions
import { useCreateRecord } from 'hooks/useCreateRecord'

// Components
import { TabGeneral } from './tabs/TabGeneral'
import { TabDetail, Tabs } from 'components/Tabs'
import { FormFooter } from 'components/FormFooter'
import { TabMarketing } from './tabs/TabMarketing'
import { FieldToggle } from 'components/FieldToggle'

// Schemas
import { schema } from './schema'

// Styles
import { Flex, useToast, Box } from '@chakra-ui/react'

// Types
import { SampleTransportKitNewForm } from './types'
import { apiPost } from 'services/post'

type KitNewFormProps = {
  endpoint: string
}

export const KitNewForm = (props: KitNewFormProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props

  const { t } = useTranslation('common')
  const history = useHistory()

  const { create } = useCreateRecord({ endpoint })
  const toast = useToast({ position: 'top-right' })

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [textEditorData, setTextEditorData] = useState('')
  const [complementaryImages, setComplementaryImages] = useState<File[]>([])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Dados gerais',
        component: TabGeneral,
        names: [
          'name',
          'sku',
          'description',
          'weightInGrams',
          'widthInCentimeters',
          'heightInCentimeters',
          'lengthInCentimeters',
          'laboratoryAnalysisIds',
        ],
      },
      {
        title: 'Marketing',
        component: TabMarketing,
        options: {
          setTextEditorData,
          setComplementaryImages,
        },
        names: ['kitVideoInstructions'],
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = useCallback(
    async (data: SampleTransportKitNewForm) => {
      const formattedData = {
        ...data,
        kitItems: textEditorData,
      }

      try {
        const response = await create(formattedData)

        if (!response) return

        if (complementaryImages.length) {
          const formData = new FormData()

          complementaryImages.forEach((image, index) => {
            formData.append(`files[${index}]`, image)
          })

          await apiPost(`${endpoint}/${response.id}/upload`, formData)
        }

        toast({
          title: 'Sucesso',
          description: 'Kit criado com sucesso',
          status: 'success',
        })

        history.push('/sample_transport_kit')
      } catch (error) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [
      complementaryImages,
      create,
      endpoint,
      history,
      methods,
      textEditorData,
      toast,
    ]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box>
          <Controller
            name="status"
            control={methods.control}
            defaultValue={true}
            render={({ field: { onChange, value, ref, name } }) => (
              <FieldToggle
                name={name}
                position="absolute"
                top={-16}
                right={0}
                ref={ref}
                isChecked={value}
                onChange={onChange}
                error={methods.formState.errors.status}
              />
            )}
          />

          <Tabs tabsDetails={tabsDetails} errors={methods.formState.errors} />
        </Box>

        <FormFooter isSubmitting={methods.formState.isSubmitting} />
      </Flex>
    </FormProvider>
  )
}
