// Vendors
import React, { useState } from 'react'

// Components
import {
  FormFieldEditer,
  FormFieldEditerProps,
} from 'components/FormFieldEditer'
import { FormFieldEditerFieldType } from 'components/FormFieldEditerFieldType'
import { FormFieldEditerFieldPreview } from 'components/FormFieldEditerFieldPreview'
import { FieldDate } from 'components/FieldDate'

// Styles

// Interfaces
export type FormFieldEditerDateProps = {
  index: number
  id: string
}

export const FormFieldEditerDate = (
  props: FormFieldEditerProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [fieldName, setFieldName] = useState(props.question.label)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormFieldEditer {...props}>
      <FormFieldEditerFieldType
        index={props.index}
        fieldTypeLabel="DATA"
        fieldName={{ get: fieldName, set: setFieldName }}
        handleQuestion={props.handleQuestion}
      />

      <FormFieldEditerFieldPreview>
        <FieldDate
          name={`date-${props.index}`}
          label={fieldName !== '' ? fieldName : 'Nome do campo'}
        />
      </FormFieldEditerFieldPreview>
    </FormFieldEditer>
  )
}
