// Vendors
import { Controller, useForm } from 'react-hook-form'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

// Functions
import { apiPost } from 'services/post'
import { useError } from 'hooks/useError'

// Components
import { FieldSelect } from 'components/FieldSelect'
import { FieldTextArea } from 'components/FieldTextArea'
import { DrawerListContent } from 'components/DrawerListContent'

// Styles
import {
  Button,
  Divider,
  useToast,
  DrawerBody,
  ButtonGroup,
  DrawerFooter,
  Stack,
} from '@chakra-ui/react'
import { FiAlertCircle } from 'react-icons/fi'

// Types
type DrawerRefusalProps = {
  isOpen: boolean
  onClose: () => void
}

export const DrawerRefusal = (props: DrawerRefusalProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose } = props

  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation('laboratory')

  const toast = useToast({ position: 'top-right' })
  const { handleError } = useError()

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectStatus = useMemo(
    () => [
      {
        value: 'DIVERGENT_DATA',
        label: 'Dados Divergentes',
      },
      {
        value: 'SAMPLE_DENIED',
        label: 'Amostra Negada',
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleRefusal = useCallback(
    async (data) => {
      try {
        await apiPost(`/app/laboratory/${id}/deny`, {
          ...data,
          status: data.status.value,
        })

        onClose()
        history.push('/laboratory')
        toast({
          title: t('toast.success.refusalDetails.title'),
          status: 'success',
        })
      } catch (error) {
        handleError(error)
      }
    },
    [id, onClose, history, toast, t, handleError]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <DrawerListContent
      title={t('drawerRefusal.title')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <DrawerBody>
        <Stack>
          <Controller
            name="status"
            control={control}
            rules={{ required: `${t('refusalKindProblem.required')}` }}
            render={({ field }) => (
              <FieldSelect
                label={t('refusalKindProblem.label')}
                options={selectStatus}
                {...field}
                isRequired
              />
            )}
          />

          <FieldTextArea
            label={t('refusalDetails.label')}
            error={errors.denyMessage}
            {...register('denyMessage', {
              required: `${t('refusalDetails.required')}`,
            })}
            isRequired
          />
        </Stack>
      </DrawerBody>

      <Divider my={4} />

      <DrawerFooter>
        <ButtonGroup justifyContent="space-between">
          <Button onClick={onClose}>{t('drawerRefusal.button.cancel')}</Button>
          <Button
            variant="outline"
            colorScheme="orange"
            onClick={handleSubmit(handleRefusal)}
            leftIcon={<FiAlertCircle fill="orange" color="white" size="20" />}
          >
            {t('drawerRefusal.button.confirmRefusal')}
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </DrawerListContent>
  )
}
