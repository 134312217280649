import React from 'react'

import { ActiveKitAction } from './ActiveKitAction'
import { AlterKitStatusAction } from './AlterKitStatusAction'
import { AuthorizeShipmentAction } from './AuthorizeShipmentAction'
import { AlterCobrandingStatusAction } from './AlterCobrandingStatusAction'
import { AlterShippingMethodAction } from './AlterShippingMethodAction'
import { GenerateNewPaymentLinkAction } from './GenerateNewPaymentLinkAction'

import { AlterPurchaseStatusAction } from './AlterPurchaseStatusAction'
import { NewActiveKitAction } from './NewActiveKitAction'

/* Plop actions imports here */
import { ResetActivationCodeAction } from './ResetActivationCodeAction'
import { RequestCollectionAction } from './RequestCollectionAction'
import { AlterActivationCodeAction } from './AlterActivationCodeAction'
import { ResetAccountPasswordAction } from './ResetAccountPasswordAction'
import { RecollectProcessingAction } from './RecollectProcessingAction'
import { CancelOrderAction } from './CancelOrderAction'
import { CancelIssuedInvoiceAction } from './CancelIssuedInvoiceAction'
import { ConfirmIssuanceNoteAction } from './ConfirmIssuanceNoteAction'
import { LegacyActiveKitAction } from './LegacyActiveKitAction'
export const useActions = <T extends unknown>(record: T) => {
  /* Plop actions fn here */

  const fnResetActivationCodeAction = (options: any) => (
    <ResetActivationCodeAction record={record} options={options} />
  )

  const fnRequestCollectionAction = (options: any) => (
    <RequestCollectionAction record={record} options={options} />
  )

  const fnAlterActivationCodeAction = (options: any) => (
    <AlterActivationCodeAction record={record} options={options} />
  )

  const fnResetAccountPasswordAction = (options: any) => (
    <ResetAccountPasswordAction record={record} options={options} />
  )

  const fnRecollectProcessingAction = (options: any) => (
    <RecollectProcessingAction record={record} options={options} />
  )

  const fnActiveKitAction = (options: any) => (
    <ActiveKitAction record={record} options={options} />
  )

  const fnCancelOrderAction = (options: any) => (
    <CancelOrderAction record={record} options={options} />
  )

  const fnCancelIssuedInvoiceAction = (options: any) => (
    <CancelIssuedInvoiceAction record={record} options={options} />
  )

  const fnConfirmIssuanceNoteAction = (options: any) => (
    <ConfirmIssuanceNoteAction record={record} options={options} />
  )

  const fnLegacyActiveKitAction = (options: any) => (
    <LegacyActiveKitAction record={record} options={options} />
  )

  const fnNewActiveKitAction = (options: any) => (
    <NewActiveKitAction record={record} options={options} />
  )

  const fnAlterKitStatusAction = (options: any) => (
    <AlterKitStatusAction record={record} options={options} />
  )

  const fnAuthorizeShipmentAction = (options: any) => (
    <AuthorizeShipmentAction record={record} options={options} />
  )

  const fnAlterCobrandingStatusAction = (options: any) => (
    <AlterCobrandingStatusAction record={record} options={options} />
  )

  const fnAlterShippingMethodAction = (options: any) => (
    <AlterShippingMethodAction record={record} options={options} />
  )

  const fnGenerateNewPaymentLinkAction = (options: any) => (
    <GenerateNewPaymentLinkAction record={record} options={options} />
  )

  const fnAlterPurchaseStatusAction = (options: any) => (
    <AlterPurchaseStatusAction record={record} options={options} />
  )

  return {
    /* Plop actions map here */
    ResetActivationCodeAction: fnResetActivationCodeAction,
    RequestCollectionAction: fnRequestCollectionAction,
    AlterActivationCodeAction: fnAlterActivationCodeAction,
    ResetAccountPasswordAction: fnResetAccountPasswordAction,
    RecollectProcessingAction: fnRecollectProcessingAction,
    CancelOrderAction: fnCancelOrderAction,
    CancelIssuedInvoiceAction: fnCancelIssuedInvoiceAction,
    ConfirmIssuanceNoteAction: fnConfirmIssuanceNoteAction,
    ActiveKitAction: fnActiveKitAction,
    GenerateNewPaymentLinkAction: fnGenerateNewPaymentLinkAction,
    NewActiveKitAction: fnNewActiveKitAction,
    AlterKitStatusAction: fnAlterKitStatusAction,
    AlterShippingMethodAction: fnAlterShippingMethodAction,
    AuthorizeShipmentAction: fnAuthorizeShipmentAction,
    AlterCobrandingStatusAction: fnAlterCobrandingStatusAction,
    LegacyActiveKitAction: fnLegacyActiveKitAction,
    AlterPurchaseStatusAction: fnAlterPurchaseStatusAction,
  }
}
