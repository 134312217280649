// Vendors
import React, { useMemo, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// Functions
import { useListRecords } from 'hooks/useListRecords'
import { usePageMeta } from 'hooks/usePageMeta'

// Components
import { ButtonWithMenu } from 'components/ButtonWithMenu'
import { ListPagination } from 'components/ListPagination'
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'
import { Stack } from '@chakra-ui/react'
import { FiCircle } from 'react-icons/fi'

// Types
import { Tag } from './types'
import { ListActions } from 'components/ListActions'

export const PageListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const history = useHistory()
  const { endpoint, title } = usePageMeta(useLocation())
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<Tag>({ endpoint })

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const ListTableHeaders = useMemo(
    () => [
      {
        label: 'Nome',
        accessor: 'name',
      },
      {
        label: 'ID',
        accessor: 'id',
      },
    ],
    []
  )

  const ListTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/kits/${Record.id}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutLoggedPageList title={title}>
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ListFilters query={Query}></ListFilters>

        <ListActions>
          <ButtonWithMenu
            label="Adicionar"
            actions={[
              {
                path: '/users/admin?tab=1',
                icon: <FiCircle />,
                label: 'Administrador',
              },
            ]}
          />
        </ListActions>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={ListTableRowAction}
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
