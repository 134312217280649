// Vendors
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

// Hooks
import { useEditRecords } from 'hooks/useEditRecord'

// Functions

// Components

import { FormFooter } from 'components/FormFooter'

// Schemas
import { schema } from './schema'

// Styles
import { Flex, useToast } from '@chakra-ui/react'

// Types
import { SellerShowAPI, SellerShowForm } from './types'
import { Tabs } from 'components/Tabs'
import { TabPersonalData } from './tabs/TabPersonalData'
import { TabPerformance } from './tabs/TabPerformance'
import { useFeedback } from 'contexts/feedback'

type SellerFormShowProps = {
  id: string
  endpoint: string
}

export const SellerFormShow = (props: SellerFormShowProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id, endpoint } = props

  const { t } = useTranslation('seller')

  const methods = useForm<SellerShowForm>({
    resolver: yupResolver(schema(t)),
  })
  const toast = useToast({ position: 'top-right' })
  const { feedback } = useFeedback()

  const { record } = useEditRecords<SellerShowAPI, SellerShowForm>({
    endpoint,
    currentId: id,
  })
  const recordCurrent = get(record, 'current')

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sanitize = useCallback((data: SellerShowAPI) => {
    if (Object.keys(data).length === 0) return {}

    return {
      name: get(data, 'name'),
      email: get(data, 'email'),
      gender: get(data, 'gender'),
      birthdate: get(data, 'birthdate'),
      phoneNumber: get(data, 'phoneNumber')?.slice(3),
      documentNumber: get(data, 'documentNumber'),
    }
  }, [])

  useEffect(() => {
    methods.reset(sanitize(recordCurrent))
  }, [methods, recordCurrent, sanitize])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails = useMemo(
    () => [
      {
        title: t('tabs.personalData'),
        component: TabPersonalData,
      },
      {
        title: t('tabs.performance'),
        component: TabPerformance,
        options: {
          sellerId: recordCurrent.userId,
        },
      },
    ],
    [recordCurrent.userId, t]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<SellerShowForm> = React.useCallback(
    async (data) => {
      try {
        await record.update(data)

        toast({
          title: t('toast.success.title'),
          description: t('toast.success.update'),
          status: 'success',
        })
      } catch (error: any) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        }

        const errorMessage =
          error?.response?.data.message ||
          error?.message ||
          t('toast.error.default')

        feedback({
          title: 'Atenção',
          description: errorMessage,
          status: 'error',
        })
      }
    },
    [feedback, methods, record, t, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Tabs tabsDetails={tabsDetails} />

        <FormFooter isSubmitting={methods.formState.isSubmitting} />
      </Flex>
    </FormProvider>
  )
}
