import React, { useCallback } from 'react'
import { get } from 'lodash'

import { ModalCancelOrder } from 'components/ModalCancelOrder'

import { useDisclosure } from '@chakra-ui/react'

import { ActionProps } from './types'
import { WrapperAction } from './WrapperAction'
import { FiXCircle } from 'react-icons/fi'

export const CancelOrderAction = <T extends unknown>({
  record,
  options,
}: ActionProps<T>) => {
  const modalCancelOrderDisclosure = useDisclosure()

  const { callback, actionType = 'menu', reload, ...optionsRest } = options

  if (!reload) {
    throw new Error('reload is required')
  }

  const cta = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, data: T) => {
      modalCancelOrderDisclosure.onOpen()

      if (callback) {
        callback(e, data)
      }
    },
    [modalCancelOrderDisclosure, callback]
  )

  return (
    <>
      <ModalCancelOrder
        reload={reload}
        isOpen={modalCancelOrderDisclosure.isOpen}
        purchaseId={get(record, 'id')}
        onCancel={modalCancelOrderDisclosure.onClose}
      />

      <WrapperAction
        cta={cta}
        record={record}
        icon={FiXCircle}
        options={optionsRest}
        actionType={actionType}
        label={'Cancelar pedido'}
        tooltip="Para cancelar um pedido, é necessário ter uma nota emitida."
        tooltipProps={{
          shouldWrapChildren: true,
          isDisabled: !optionsRest.isDisabled,
        }}
      />
    </>
  )
}
