// Vendors
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

// Components
import { TabDetail, Tabs } from 'components/Tabs'

import { TabGeneralShow } from '../tabs/TabGeneralShow'

// Styles
import { Box, Flex, useToast } from '@chakra-ui/react'

// Schemas
import { schema } from './schema'
import { FormFooter } from 'components/FormFooter'
import { useEditRecords } from 'hooks/useEditRecord'

// Types
import {
  CompanyLogisticsShow,
  CompanyLogisticsShowRecord,
  LogisticSettings,
} from './types'
import { get, map } from 'lodash'

import { FieldStatus } from 'components/FieldStatus'
import { useFormTransform } from 'hooks/useFormTransform'

type CompanyLogisticsFormShowProps = {
  id: string
  endpoint: string
}

export const CompanyLogisticsFormShow = (
  props: CompanyLogisticsFormShowProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint, id } = props

  const { record } = useEditRecords<CompanyLogisticsShow, CompanyLogisticsShow>(
    {
      endpoint,
      currentId: id,
    }
  )

  const recordCurrent = record.current

  const { t } = useTranslation('common')
  const { statusParser } = useFormTransform()
  const toast = useToast({ position: 'top-right' })

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!recordCurrent) return

    const cities = map(recordCurrent.cities, (city) => ({
      value: city.id,
      label: city.name,
    }))

    const states = map(recordCurrent.states, (state) => ({
      value: state.id,
      label: state.abbreviatedName,
    }))

    const {
      deliveryPrice,
      maxWeigthInGrams,
      maxWidthInCentimeter,
      maxHeightInCentimeter,
      maxLengthInCentimeter,
    } = (get(recordCurrent, 'logisticSettings', {}) as LogisticSettings) || {}

    const logisticAgents = map(recordCurrent?.logisticAgents, (agent) => ({
      value: agent?.id,
      label: agent?.person?.name,
    }))

    const logisticManagers = map(
      recordCurrent?.logisticManagers,
      (manager) => ({
        value: manager.id,
        label: manager.person.name,
      })
    )

    const transformData: CompanyLogisticsShowRecord = {
      cities,
      states,
      maxWidthInCentimeter,
      maxHeightInCentimeter,
      maxLengthInCentimeter,
      maxWeigthInGrams,
      deliveryPrice,
      logisticAgents,
      logisticManagers,
      name: recordCurrent.companyData?.name,
      number: recordCurrent.companyData?.address.number,
      street: recordCurrent.companyData?.address.street,
      cityId: recordCurrent.companyData?.address.cityId,
      stateId: recordCurrent.companyData?.address.stateId,
      complement: recordCurrent.companyData?.address.complement,
      postalCode: recordCurrent.companyData?.address.postalCode,
      neighborhood: recordCurrent.companyData?.address.neighborhood,
      referencePoint: recordCurrent.companyData?.address.referencePoint,
      documentNumber:
        recordCurrent.companyData?.companyHasDocuments[0].documentNumber,
      status: statusParser(recordCurrent.status),
    }

    methods.reset(transformData)
  }, [methods, recordCurrent, statusParser])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Geral',
        component: TabGeneralShow,
        options: { record },
      },
      // {
      //   title: 'Performance',
      //   component: TabPerformance,
      // },
      // {
      //   title: 'Entregas',
      //   component: TabDelivery,
      // },
      // {
      //   title: 'Coletas',
      //   component: TabCollect,
      // },
    ],
    [record]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<CompanyLogisticsShowRecord> = React.useCallback(
    async (data) => {
      try {
        const formattedData = {
          ...data,
          companyType: 'LOGISTICS',
          employees: [...data.logisticAgents, ...data.logisticManagers],
        }

        // TODO: Add a type for this
        await record.update(formattedData as any)

        toast({
          title: 'Editado com sucesso',
          description: 'Logística editada com sucesso',
          status: 'success',
        })
      } catch (error) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            if (fieldError.field === 'documentNumber') {
              fieldError.message = 'Verifique se o CNPJ está correto'
            }
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [methods, record, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box position="absolute" top={-6} right={0}>
          <FieldStatus
            methods={methods}
            error={methods.formState.errors.status}
            disable={record.disable}
            enable={record.enable}
          />
        </Box>

        <Tabs tabsDetails={tabsDetails} />

        <FormFooter
          recordDelete={record.delete}
          isSubmitting={methods.formState.isSubmitting}
        />
      </Flex>
    </FormProvider>
  )
}
