// Vendors
import { sub } from 'date-fns'
import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'

// Functions
import { apiShow } from 'services/get'
import { formatDate } from 'utils/formatDate'
import { arrayOfIntegers } from 'utils/array-of-integers'

// Components
import { CardStat } from 'components/CardStat'
import { FieldDateRangePicker } from 'components/FieldDateRangePicker'

// Styles
import Layout from 'layout/Private'
import { Box, SimpleGrid, Stack } from '@chakra-ui/react'

// Types
import { IndicatorType } from 'types/Indicator'
import { useAuth } from 'contexts/auth'
import { intersection } from 'lodash'
import { UserRolesNames } from 'types/User'

export const DashboardExpedition = () => {
  const { t } = useTranslation('common')
  const { userRolesNames } = useAuth()

  const [expeditionIndicators, setExpeditionIndicators] = useState<
    IndicatorType | undefined
  >(undefined)

  const [period, setPeriod] = useState({
    start: sub(new Date(), { days: 7 }),
    end: new Date(),
  })

  useEffect(() => {
    async function getIndicators() {
      if (
        intersection(userRolesNames, [
          UserRolesNames.ADMIN,
          UserRolesNames.EXPEDITION,
          UserRolesNames.SUPERADMIN,
        ]).length === 0
      ) {
        return
      }

      try {
        const startDate = formatDate(new Date(period.start), 'yyyy-MM-dd')
        const endDate = formatDate(new Date(period.end), 'yyyy-MM-dd')

        const response = await apiShow<IndicatorType>(
          `/app/expedition/indicators?startDate=${startDate}&endDate=${endDate}`
        )

        if (!response) return
        setExpeditionIndicators(response)
      } catch (error) {
        console.error(error)
      }
    }

    getIndicators()
  }, [period, userRolesNames])

  const handlePeriodChange = async (dates: any) => {
    const [start, end] = dates

    setPeriod({
      start,
      end,
    })

    if (!end) return

    try {
      const startDate = formatDate(new Date(start), 'yyyy-MM-dd')
      const endDate = formatDate(new Date(end), 'yyyy-MM-dd')

      const response = await apiShow<IndicatorType>(
        `/app/expedition/indicators?startDate=${startDate}&endDate=${endDate}`
      )

      if (!response) return

      setExpeditionIndicators(response)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Layout title={t('pages_private.dashboard_title')}>
      <Stack direction="row" my="4" spacing="6">
        <Box w="64" zIndex="dropdown">
          <FieldDateRangePicker
            name="filterDate"
            handlePeriodChange={handlePeriodChange}
            onChange={handlePeriodChange}
            placeholderText="Filtrar por data"
            containerProps={{
              marginBottom: '0',
            }}
            showMonthDropdown
            dropdownMode="select"
            period={{ get: period, set: setPeriod }}
          />
        </Box>
      </Stack>

      <SimpleGrid columns={4} gap="4">
        <CardStat
          label="Em expedição"
          value={expeditionIndicators?.indicators.inExpedition || 0}
          series={[
            {
              name: 'Volume financeiro',
              data: arrayOfIntegers(12, 100),
            },
          ]}
        />
        <CardStat
          label="Em Transito"
          value={expeditionIndicators?.indicators.inTransit || 0}
          series={[
            {
              name: 'Volume financeiro',
              data: arrayOfIntegers(12, 100),
            },
          ]}
        />
        <CardStat
          label="Pendentes"
          value={expeditionIndicators?.indicators.pendingReverseCode || 0}
          series={[
            {
              name: 'Volume financeiro',
              data: arrayOfIntegers(12, 100),
            },
          ]}
        />
      </SimpleGrid>
    </Layout>
  )
}
