// Vendors
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory, Link } from 'react-router-dom'
import React, { useMemo, useEffect, useCallback, useState } from 'react'

// Functions
import { useError } from 'hooks/useError'
import { apiDelete } from 'services/delete'
import { useListRecords } from 'hooks/useListRecords'

// Components
import { ListTable } from 'components/ListTable'
import { ListActions } from 'components/ListActions'
import { ListFilters } from 'components/ListFilters'
import { ListPagination } from 'components/ListPagination'
import { AlertDialogDelete } from 'components/AlertDialogDelete'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'
import {
  Box,
  Text,
  Stack,
  Button,
  ListItem,
  useToast,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'

// Types
import { Kits } from './types'
import { PageMetaSetting } from './meta'

export const PageSampleTransportKitListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const toast = useToast({ position: 'top-right' })
  const { handleError } = useError()
  const deleteDisclosure = useDisclosure()
  const { t } = useTranslation('common')
  const history = useHistory()
  const { endpoint, title } = PageMetaSetting
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<Kits>({ endpoint })

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [selectedKit, setSelectedKit] = useState<
    | {
        kit: Kits
        dialog:
          | { title: string; body: JSX.Element; isDisabled: boolean }
          | undefined
      }
    | undefined
  >(undefined)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const deleteDialog = useCallback((selectedKit: Kits) => {
    if (!selectedKit || !selectedKit.storeProducts) return
    if (selectedKit.storeProducts.length > 0) {
      const productsLength = selectedKit.storeProducts.length
      const bodyDialog = map(selectedKit.storeProducts, (product) => {
        return (
          <Link to={`/products/${product.id}`} key={product.id}>
            <ListItem
              color="blue.600"
              _hover={{
                filter: 'brightness(0.85)',
                textDecoration: 'underline',
              }}
            >
              {product.mktTitle}
            </ListItem>
          </Link>
        )
      })

      return {
        title: 'Este registro não pode ser excluído',
        body: (
          <>
            <Box>
              Atualmente, {productsLength}{' '}
              {productsLength === 1
                ? 'produto ativo utiliza'
                : 'produtos ativos utilizam'}{' '}
              este registro:{' '}
            </Box>
            <UnorderedList>{bodyDialog}</UnorderedList>
          </>
        ),
        isDisabled: true,
      }
    } else {
      return {
        title: 'Por favor confirme a exclusão.',
        isDisabled: false,
        body: (
          <Text>
            Não existem produtos ativos utilizando este registro. Ao excluir
            este item, é possível que produtos desativados que dependam dele não
            possam mais ser ativados. Você tem certeza que deseja excluir este
            item?
          </Text>
        ),
      }
    }
  }, [])

  const handleDelete = useCallback(
    async (e, record: Kits) => {
      e.stopPropagation()

      setSelectedKit({ kit: record, dialog: deleteDialog(record) })
      deleteDisclosure.onOpen()
    },
    [deleteDialog, deleteDisclosure]
  )

  const handleDeleteConfirm = useCallback(async () => {
    try {
      await apiDelete(`${endpoint}/${selectedKit?.kit.id}`)
      Records.reload()

      toast({
        title: 'Sucesso',
        description: 'Kit excluído com sucesso.',
        status: 'success',
      })
    } catch (error: any) {
      console.error(error)

      handleError(error)
    }
  }, [Records, endpoint, handleError, selectedKit?.kit.id, toast])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    Records.list.forEach((item) => {
      item.dimensions = `${item.height} x ${item.width} x ${item.length} cm`
    })
  }, [Records])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const ListTableHeaders = useMemo(
    () => [
      {
        label: t('list_table.headers.name'),
        accessor: 'name',
      },
      {
        label: t('list_table.headers.description'),
        accessor: 'description',
        render: { as: ListTableRenderAs.MULTILINE_TEXT },
      },
      {
        label: t('list_table.headers.dimensions'),
        accessor: 'dimensions',
      },
      {
        label: t('list_table.headers.status'),
        accessor: 'status',
        render: { as: ListTableRenderAs.BADGE },
      },
    ],
    [t]
  )

  const ListTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/sample_transport_kit/${Record.id}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutLoggedPageList title={title}>
      <AlertDialogDelete
        isOpen={deleteDisclosure.isOpen}
        onCancel={deleteDisclosure.onClose}
        titleDialog={selectedKit?.dialog?.title}
        bodyDialog={selectedKit?.dialog?.body}
        isDisabled={selectedKit?.dialog?.isDisabled}
        onConfirm={() => {
          deleteDisclosure.onClose()
          handleDeleteConfirm()
        }}
      />

      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ListFilters query={Query}></ListFilters>

        <ListActions>
          <Button
            as={Link}
            to={'/sample_transport_kit/new'}
            leftIcon={<FiPlus />}
          >
            Adicionar
          </Button>
        </ListActions>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={ListTableRowAction}
        defaultActions="edit"
        actions={[{ type: 'delete', options: { callback: handleDelete } }]}
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
