// Vendors
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation, useParams } from 'react-router'
import { useForm } from 'react-hook-form'
// import useSWR from 'swr'
import qs from 'qs'

// Hooks
// import { localApi } from 'services/api'

// Components

// Styles
import Layout from 'layout/Private'
import {
  Box,
  Flex,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Switch,
  Link,
  useColorModeValue as mode,
  Divider,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Spacer,
  Circle,
  TabIndicator,
  Text,
  SimpleGrid,
  Stack,
  Select,
  InputGroup,
  InputLeftElement,
  Grid,
  IconButton,
  Img,
} from '@chakra-ui/react'
import { HiChatAlt, HiPencilAlt, HiPhone } from 'react-icons/hi'
import { FiCircle, FiSearch, FiTrash2 } from 'react-icons/fi'
import { v4 } from 'uuid'
import { TableWithPagination } from 'components/TableWithPagination'
import { ButtonWithMenu } from 'components/ButtonWithMenu'
import { FieldText } from 'components/FieldText'
import Todo from 'tools/Todo'
import { FormSection } from 'components/FormSection'

// Interfaces
interface IFormInputs {
  example: string
  exampleRequired: string
}

const PageRecord: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>()
  const { search } = useLocation()
  const { id } = useParams<{ id: string }>()
  const { tab } = qs.parse(search, { ignoreQueryPrefix: true })
  // const { data: Record } = useSWR('/users', localApi)

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isNewRegister, setIsNewRegister] = useState<boolean>(true)
  const [showRegisterActions, setShowRegisterActions] = useState<boolean>(true)
  const [activeTab, setActiveTab] = useState<number>(() => {
    if (!id) return 1
    return tab ? Number(tab) : 0
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = useCallback((data) => {
    console.log(data)
    setIsNewRegister((current) => !current)
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!id) return
    setIsNewRegister(false)
  }, [id])

  /*
  |-----------------------------------------------------------------------------
  | Tabs
  |-----------------------------------------------------------------------------
  |
  |
  */

  interface ITabsOptions {
    showActions: boolean
  }

  const TabsOptions: Array<ITabsOptions> = useMemo(() => {
    return [
      { showActions: false },
      { showActions: true },
      { showActions: true },
    ]
  }, [])

  const handleTabsChange = useCallback(
    (index: number, options: Array<ITabsOptions>) => {
      const { showActions } = options[index]
      setActiveTab(index)

      setShowRegisterActions(showActions)
    },
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */
  const PerformanceTableColumns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Telefone',
        accessor: 'phone',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'Perfil',
        accessor: 'profile',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )

  const PerformanceTableRecords = useMemo(() => {
    const tempData = []
    for (let i = 0; i < 76; i++) {
      tempData.push({
        name: 'Name',
        phone: 'Phone',
        email: 'email@temp.cb',
        profile: 'Perfil',
        status: 'Ativo',
      })
    }
    return tempData
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Tabs
        index={activeTab}
        onChange={(index) => {
          handleTabsChange(index, TabsOptions)
        }}
      >
        <Flex direction="column" align="stretch">
          <Box bg={mode('gray.50', 'gray.800')} px="6" pt="8">
            <Box>
              {/*
              |-----------------------------------------------------------------------------
              | Header: New register
              |-----------------------------------------------------------------------------
              |
              |
              */}
              {isNewRegister && (
                <Flex
                  direction={{ base: 'column', md: 'row' }}
                  justify="space-between"
                  align="flex-start"
                  mb="10"
                >
                  <HStack mb={{ base: '4', md: '0' }}>
                    <Heading size="lg">Usuários: Novo Admin </Heading>
                  </HStack>

                  <HStack spacing={{ base: '2', md: '4' }}>
                    {/* Actions */}
                  </HStack>
                </Flex>
              )}

              {/*
              |-----------------------------------------------------------------------------
              | Header: Existing register
              |-----------------------------------------------------------------------------
              |
              |
              */}
              {!isNewRegister && (
                <Stack
                  spacing="10"
                  direction={{ base: 'column', md: 'row' }}
                  align="flex-start"
                >
                  <Img
                    src="https://healthfulradiance.files.wordpress.com/2020/08/cropped-d691a3db-909a-46d2-a36a-5599f54c1505.jpeg?w=200"
                    alt="Angie"
                    w="32"
                    h="32"
                    rounded="lg"
                    objectFit="cover"
                  />
                  <Box w={{ base: 'full', md: 'auto' }}>
                    <Heading size="md" mb="8" mt={{ lg: '3' }}>
                      Angie Radiance
                    </Heading>
                    <Grid
                      templateColumns={{
                        base: '1fr',
                        sm: 'repeat(2, 1fr)',
                        lg: 'repeat(4, fit-content(320px))',
                      }}
                      columnGap="20"
                      rowGap={{ base: '8', lg: '14' }}
                    >
                      <Stack as="dl" spacing="1">
                        <Text
                          as="dt"
                          fontWeight="bold"
                          fontSize="xs"
                          casing="uppercase"
                          color="gray.500"
                          whiteSpace="nowrap"
                        >
                          Mídia
                        </Text>
                        <Text fontSize="sm" fontWeight="medium">
                          <Link href="https://healthfulradiance.com">
                            healthfulradiance.com
                          </Link>
                        </Text>
                      </Stack>
                      <Stack as="dl" spacing="1">
                        <Text
                          as="dt"
                          fontWeight="bold"
                          fontSize="xs"
                          casing="uppercase"
                          color="gray.500"
                          whiteSpace="nowrap"
                        >
                          Instagram
                        </Text>
                        <Text fontSize="sm" fontWeight="medium">
                          <Link href="https://www.instagram.com/healthfulradiance/">
                            @healthfulradiance
                          </Link>
                        </Text>
                      </Stack>
                      <Stack as="dl" spacing="1">
                        <Text
                          as="dt"
                          fontWeight="bold"
                          fontSize="xs"
                          casing="uppercase"
                          color="gray.500"
                          whiteSpace="nowrap"
                        >
                          Cupons associados
                        </Text>
                        <Text fontSize="sm" fontWeight="medium">
                          32
                        </Text>
                      </Stack>
                      <Stack as="dl" spacing="1">
                        <Text
                          as="dt"
                          fontWeight="bold"
                          fontSize="xs"
                          casing="uppercase"
                          color="gray.500"
                          whiteSpace="nowrap"
                        >
                          Receita total
                        </Text>
                        <Text fontSize="sm" fontWeight="medium">
                          R$ 45.900,00
                        </Text>
                      </Stack>
                    </Grid>
                  </Box>
                  <Spacer />
                  <ButtonGroup
                    variant="outline"
                    textAlign="right"

                    // pos={{ md: 'absolute' }}
                    // top="0"
                    // right="0"
                  >
                    <IconButton aria-label="Call" icon={<HiPhone />} />
                    <IconButton aria-label="Edit" icon={<HiPencilAlt />} />
                    <Button
                      variant="solid"
                      colorScheme="blue"
                      leftIcon={<HiChatAlt />}
                    >
                      Send Message
                    </Button>
                  </ButtonGroup>
                </Stack>
              )}

              {/*
              |-----------------------------------------------------------------------------
              | Tab handles
              |-----------------------------------------------------------------------------
              |
              |
              */}
              <Flex
                justify="space-between"
                align="center"
                pl={isNewRegister ? '0' : '165'}
              >
                <TabList
                  border="0"
                  position="relative"
                  zIndex={1}
                  w={{ base: '100%', md: 'auto' }}
                >
                  <Tab
                    fontWeight="semibold"
                    isDisabled={isNewRegister}
                    display={isNewRegister ? 'none' : 'unset'}
                  >
                    Performance
                  </Tab>
                  <Tab fontWeight="semibold">Dados pessoais</Tab>
                  <Tab fontWeight="semibold">Histórico</Tab>
                </TabList>
                {!isNewRegister && (
                  <Box>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel htmlFor="email-alerts" mb="0">
                        Status
                      </FormLabel>
                      <Switch
                        id="status"
                        size="md"
                        colorScheme="green"
                        defaultChecked
                      />
                    </FormControl>
                  </Box>
                )}
              </Flex>
            </Box>
          </Box>
          <Box pos="relative" zIndex={0}>
            <Divider
              borderBottomWidth="2px"
              opacity={1}
              borderColor={mode('gray.100', 'gray.700')}
            />
          </Box>
          <form
            onSubmit={() => {
              onSubmit(handleSubmit)
            }}
          >
            <Flex>
              <Box flex="1">
                <TabPanels h="full" py="2" px="2">
                  {/* Tab: Performance |----------------------------------------------------------------------------- */}
                  <TabPanel>
                    {!isNewRegister && (
                      <Fragment>
                        <SimpleGrid
                          columns={{ base: 1, md: 2, lg: 4 }}
                          spacing="6"
                          width="full"
                        >
                          {[
                            {
                              label: 'Cupons Associados',
                              value: '32',
                            },
                            {
                              label: 'Vendas totais',
                              value: '67',
                            },
                            {
                              label: 'Clientes totais',
                              value: '42',
                            },
                            {
                              label: 'Receita total',
                              value: 'R$ 45.900,00',
                            },
                          ].map((options) => (
                            <Box
                              key={v4()}
                              bg={mode('white', 'gray.700')}
                              px="6"
                              py="4"
                              shadow="base"
                              rounded="lg"
                            >
                              <HStack>
                                <Circle
                                  bg="gray.500"
                                  color="white"
                                  rounded="full"
                                  size="6"
                                >
                                  <FiCircle />
                                </Circle>
                                <Text
                                  fontWeight="medium"
                                  color={mode('gray.500', 'gray.400')}
                                >
                                  {options.label}
                                </Text>
                              </HStack>

                              <Heading
                                as="h4"
                                size="lg"
                                my="3"
                                fontWeight="extrabold"
                              >
                                {options.value}
                              </Heading>
                              <Flex
                                justify="space-between"
                                align="center"
                                fontWeight="medium"
                                fontSize="sm"
                              >
                                <HStack
                                  spacing="0"
                                  color={mode('gray.500', 'gray.400')}
                                >
                                  <TabIndicator />
                                  {/* <Text>Stat with icon</Text> */}
                                </HStack>
                                {/* <Text color="gray.400">CUR</Text> */}
                              </Flex>
                            </Box>
                          ))}
                        </SimpleGrid>

                        {PerformanceTableRecords && (
                          <Fragment>
                            <Stack
                              spacing="4"
                              mt="10"
                              direction={{ base: 'column', md: 'row' }}
                              justify="space-between"
                            >
                              <HStack>
                                <FormControl minW={{ md: '320px' }} id="search">
                                  <InputGroup size="sm">
                                    <FormLabel srOnly>
                                      Filtrar por nome ou e-mail
                                    </FormLabel>
                                    <InputLeftElement
                                      pointerEvents="none"
                                      color="gray.400"
                                    >
                                      <FiSearch />
                                    </InputLeftElement>
                                    <Input
                                      rounded="base"
                                      type="search"
                                      placeholder="Filtrar por nome ou e-mail"
                                    />
                                  </InputGroup>
                                </FormControl>
                                <Select
                                  w={{ base: '300px', md: 'unset' }}
                                  rounded="base"
                                  size="sm"
                                  placeholder="Perfís"
                                >
                                  <option>Superadmin</option>
                                  <option>Admin</option>
                                  <option>Financeiro</option>
                                  <option>Backoffice</option>
                                  <option>Vendedor</option>
                                  <option>Profissional</option>
                                  <option>Cliente</option>
                                  <option>Expedição</option>
                                  <option>Gestor Motoboy</option>
                                  <option>Motoboy</option>
                                  <option>Laboratório</option>
                                </Select>
                              </HStack>
                              <ButtonGroup size="sm" variant="outline">
                                <Box>
                                  <ButtonWithMenu
                                    label="Adicionar"
                                    actions={[
                                      {
                                        path: '/users/admin?tab=1',
                                        icon: <FiCircle />,
                                        label: 'Administrador',
                                      },
                                    ]}
                                  />
                                </Box>
                              </ButtonGroup>
                            </Stack>
                            <TableWithPagination
                              columns={PerformanceTableColumns}
                              data={PerformanceTableRecords}
                              // RowAction={() => {}}
                            />
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </TabPanel>

                  {/* Tab: General |----------------------------------------------------------------------------- */}
                  <TabPanel>
                    <VStack spacing="6" width="full">
                      <FormSection label="Seção do formulário" />
                      <FieldText
                        label="FieldText"
                        defaultValue={1}
                        placeholder="Teste"
                      />
                      <FormControl id="select">
                        <FormLabel>
                          <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/18" />
                          FieldSelect
                        </FormLabel>
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                      </FormControl>
                      <FormControl id="selectmulti">
                        <FormLabel>
                          <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/19" />
                          FieldSelectMulti
                        </FormLabel>
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                      </FormControl>
                      <FormControl id="selectmulti">
                        <FormLabel>
                          <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/20" />
                          FieldSelectMulti (one per line)
                        </FormLabel>
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                      </FormControl>
                      <FormControl id="toggle">
                        <FormLabel>
                          <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/21" />
                          FieldToggle
                        </FormLabel>
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                      </FormControl>
                      <FormControl id="datepicker">
                        <FormLabel>
                          <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/22" />
                          FieldDatepicker
                        </FormLabel>
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                      </FormControl>
                      <FormControl id="radio">
                        <FormLabel>
                          <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/23" />
                          FieldRadiobutton
                        </FormLabel>
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                      </FormControl>
                      <FormControl id="checkbox">
                        <FormLabel>
                          <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/24" />
                          FieldCheckbox
                        </FormLabel>
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                      </FormControl>
                      <FormControl id="mask">
                        <FormLabel>
                          <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/25" />
                          FieldMaskedText
                        </FormLabel>
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                      </FormControl>
                      <FormControl id="number">
                        <FormLabel>Todo: FieldNumber</FormLabel>
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                      </FormControl>
                      <FormControl id="textarea">
                        <FormLabel>
                          <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/28" />
                          FieldTextArea
                        </FormLabel>
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                      </FormControl>

                      <FormSection label="FieldAddress" />
                      <FormControl id="cep">
                        <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/29" />
                        <FormLabel>CEP</FormLabel>
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                      </FormControl>

                      <FormSection label="Other Components" />
                      <FormControl id="file">
                        <FormLabel>
                          <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/32" />
                          FileUploader
                        </FormLabel>
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                      </FormControl>

                      <FormControl id="lightbox">
                        <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/31" />
                        <FormLabel>Todo: Lightbox</FormLabel>
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                      </FormControl>
                      <FormControl id="progressiveDiscount">
                        <FormLabel>
                          <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/33" />
                          FieldProgressiveDiscount
                        </FormLabel>
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                      </FormControl>
                      <FormControl id="dimensions">
                        <FormLabel>
                          <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/34" />
                          FieldGroup (eg. package dimensions)
                        </FormLabel>
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                        <Input
                          type="text"
                          {...register('exampleRequired', { required: true })}
                        />
                      </FormControl>
                    </VStack>
                  </TabPanel>

                  {/* Tab: Other tab |----------------------------------------------------------------------------- */}
                  <TabPanel>
                    <Box>
                      <Todo link="https://github.com/CM2-Tech/testfy-backoffice/issues/30" />
                      Timeline
                    </Box>
                  </TabPanel>
                </TabPanels>
                <Box pos="relative" zIndex={0}>
                  <Divider
                    borderBottomWidth="2px"
                    opacity={1}
                    borderColor={mode('gray.100', 'gray.700')}
                  />
                </Box>
                <Spacer />

                {showRegisterActions && (
                  <Fragment>
                    {/* Errors |----------------------------------------------------------------------------- */}
                    {errors.exampleRequired && (
                      <Fragment>
                        <Box p="6">
                          <span>Mensagem de erro</span>
                        </Box>
                        <Box pos="relative" zIndex={0}>
                          <Divider
                            borderBottomWidth="2px"
                            opacity={1}
                            borderColor={mode('gray.100', 'gray.700')}
                          />
                        </Box>
                      </Fragment>
                    )}

                    {/* Actions |----------------------------------------------------------------------------- */}
                    <Flex p="6">
                      <ButtonGroup size="md">
                        {!isNewRegister && (
                          <Button
                            bg={mode('white', 'inherit')}
                            variant="outline"
                            leftIcon={<FiTrash2 />}
                            colorScheme="red"
                          >
                            Deletar
                          </Button>
                        )}
                      </ButtonGroup>
                      <Spacer />
                      <ButtonGroup size="md">
                        <Button
                          colorScheme="blue"
                          onClick={() => {
                            onSubmit(handleSubmit)
                          }}
                        >
                          Salvar
                        </Button>
                      </ButtonGroup>
                    </Flex>
                  </Fragment>
                )}
              </Box>
            </Flex>
          </form>
        </Flex>
      </Tabs>
    </Layout>
  )
}

export default PageRecord
