// Vendors
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

// Hooks
import { apiPatch } from 'services/patch'
import { useEditRecords } from 'hooks/useEditRecord'

// Components
import { TabSeo } from 'components/TabSeo'
import { TabShowPost } from '../tabs/TabShowPost'
import { TabDetail, Tabs } from 'components/Tabs'

// Styles
import { Button, Flex, useDisclosure, useToast } from '@chakra-ui/react'
import { schema } from './schema'
import { FormFooter } from 'components/FormFooter'
import { AlertDialogDelete } from 'components/AlertDialogDelete'
import { useFeedback } from 'contexts/feedback'
import { useError } from 'hooks/useError'

// Types
type BlogFormShowProps = {
  id: string
  endpoint: string
}

export const BlogFormShow = (props: BlogFormShowProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint, id } = props
  const { handleError } = useError()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation('common')
  const history = useHistory()
  const toast = useToast({ position: 'top-right' })
  const { feedback } = useFeedback()

  const { record } = useEditRecords<any, any>({
    endpoint: { public: endpoint, private: '/app/blog' },
    currentId: id,
  })

  const recordCurrent = record.current

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [textEditorData, setTextEditorData] = useState('')

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const memoDefaultImagesSEO = useMemo(() => {
    const seo = recordCurrent.seoImages

    return {
      list: seo || [],
    }
  }, [recordCurrent.seoImages])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sanitize = useCallback((data: any) => {
    if (Object.keys(data).length === 0) return {}

    const seoKeywords = data.keywords?.map((keyword: any) => ({
      value: keyword.id,
      label: keyword.name,
    }))

    return {
      title: data.title,
      tagIds: data.tagIds,
      status: data.status,
      subtitle: data.subtitle,
      featured: !!data.featured,
      categoryId: data.categoryId,
      articleLead: data.articleLead,
      accentColor: data.accentColor,
      articleAuthor: data.articleAuthor,
      publishingDate: data.publishingDate,

      // SEO
      seoKeywords,
      slug: data.slug,
      seoTitle: data.seoTitle,
      seoDescription: data.seoDescription,
      seoAdditionalKeywords: data.seoAdditionalKeywords,
    }
  }, [])

  useEffect(() => {
    setTextEditorData(recordCurrent.articleBody)

    methods.reset(sanitize(recordCurrent))
  }, [methods, recordCurrent, sanitize])

  /*
  |-----------------------------------------------------------------------------
  | Funcions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleTrash = useCallback(async () => {
    try {
      await apiPatch(`/app/blog/${id}/status`, {
        status: 'TRASH',
      })

      toast({
        title: 'Postagem excluída com sucesso',
        status: 'success',
      })

      history.push('/blog')
    } catch (error) {
      handleError(error)
    }
  }, [handleError, history, id, toast])

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        await record.update({ ...data, articleBody: textEditorData })

        toast({
          title: 'Editado com sucesso',
          description: 'Postagem editada com sucesso',
          status: 'success',
        })
      } catch (error) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        } else {
          handleError(error)
        }
      }
    },
    [handleError, methods, record, textEditorData, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Postagem',
        component: TabShowPost,
        options: {
          id,
          record,
          recordCurrent,
          textEditorData,
          setTextEditorData,
        },
        names: [
          'featured',
          'title',
          'subtitle',
          'articleAuthor',
          'articleLead',
          'accentColor',
          'tagIds',
          'categoryId',
          'publishingDate',
        ],
      },
      {
        title: 'SEO',
        component: TabSeo,
        names: ['slug', 'seoTitle', 'seoKeywords', 'seoDescription'],
        options: {
          id,
          recordReload: record.reload,
          memoDefaultImagesSEO,
          endpointUploadImage: {
            post: `/app/blog/${id}/seo_upload`,
            delete: `/app/blog/${id}/seo_image`,
          },
        },
      },
    ],
    [id, memoDefaultImagesSEO, record, recordCurrent, textEditorData]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <AlertDialogDelete
          isOpen={isOpen}
          onConfirm={handleTrash}
          onCancel={onClose}
        />

        <Tabs tabsDetails={tabsDetails} errors={methods.formState.errors} />

        <FormFooter isSubmitting={methods.formState.isSubmitting}>
          <Button onClick={onOpen} colorScheme="red" variant="outline">
            Mover para a lixeira
          </Button>
        </FormFooter>
      </Flex>
    </FormProvider>
  )
}
