// Vendors
import React from 'react'

// Functions
import { useTranslation } from 'react-i18next'

// Components
import { RecollectFormNew } from '../form/new'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

import { PageMetaSetting } from './meta'

export const PageRecollectNewRecord = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('recollect')
  const { endpoint, title } = PageMetaSetting

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={t(title)}>
      <RecollectFormNew endpoint={endpoint} />
    </LayoutLoggedPageList>
  )
}
