import { BsReceipt } from 'react-icons/bs'
import {
  FiTag,
  FiBox,
  FiGrid,
  FiUser,
  FiGlobe,
  FiSmile,
  FiLayers,
  FiCircle,
  FiDroplet,
  FiThumbsUp,
  FiBriefcase,
  FiBarChart2,
  FiShoppingCart,
  FiTruck,
  FiExternalLink,
  FiShoppingBag,
  FiFolder,
  FiSearch,
  FiFileText,
} from 'react-icons/fi'
import { MdOutlineMedicalServices, MdRecycling } from 'react-icons/md'
import {
  RiMicroscopeLine,
  RiMotorbikeLine,
  RiArticleLine,
} from 'react-icons/ri'
import { UserRolesNames } from 'types/User'
import { NavItemProps } from './NavItem'

type Role = {
  title: string
  allowedRoles: Array<keyof typeof UserRolesNames>
  children: Array<{
    item: NavItemProps
    allowedRoles: Array<keyof typeof UserRolesNames>
  }>
}

type NavItemType =
  | 'recollects'
  | 'clinics'
  | 'audits'
  | 'sellers'
  | 'salesCategories'
  | 'dashboard'
  | 'coupons'
  | 'influencers'
  | 'professionals'
  | 'products'
  | 'laboratorial_analysis'
  | 'kits'
  | 'sample_transport_kit'
  | 'users'
  | 'companies'
  | 'clients'
  | 'prospects'
  | 'contracts'
  | 'reports'
  | 'category'
  | 'salesCategory'
  | 'laboratory'
  | 'correios'
  | 'motoboy'
  | 'vipp'
  | 'pagarMe'
  | 'omie'
  | 'orders'
  | 'blog'
  | 'invoice'
  | 'recollects'

const NAV_ITEMS: Record<NavItemType, NavItemProps> = {
  clinics: {
    label: 'menu.clinics',
    path: '/clinics',
    icon: MdOutlineMedicalServices,
  },
  audits: {
    label: 'menu.audit',
    path: '/audits',
    icon: FiGrid,
  },
  sellers: {
    label: 'menu.seller',
    path: '/sellers',
    icon: FiGrid,
  },
  salesCategories: {
    label: 'SalesCategory',
    path: '/salesCategories',
    icon: FiGrid,
  },
  dashboard: {
    label: 'Dashboard',
    path: '/dashboard',
    icon: FiGrid,
  },

  coupons: {
    label: 'menu.coupons',
    path: '/coupons',
    icon: FiTag,
  },

  influencers: {
    label: 'menu.influencers',
    path: '/influencers',
    icon: FiThumbsUp,
  },

  professionals: {
    label: 'menu.professionals',
    path: '/professionals',
    icon: FiBriefcase,
  },

  products: {
    label: 'menu.products',
    path: '/products',
    icon: FiShoppingCart,
  },

  laboratorial_analysis: {
    label: 'menu.laboratorial_analysis',
    path: '/laboratorial_analysis',
    icon: FiDroplet,
  },

  kits: {
    label: 'menu.kits',
    path: '/kits',
    icon: FiBox,
  },
  sample_transport_kit: {
    label: 'menu.sample_transport_kit',
    path: '/sample_transport_kit',
    icon: FiBox,
  },

  users: {
    label: 'menu.users',
    path: '/users',
    icon: FiUser,
  },

  companies: {
    label: 'menu.companies',
    path: '/companies',
    icon: FiGlobe,
  },

  clients: {
    label: 'menu.clients',
    path: '/clients',
    icon: FiSmile,
  },
  prospects: {
    label: 'menu.prospects',
    path: '/prospects',
    icon: FiSearch,
  },
  contracts: {
    label: 'menu.contracts',
    path: '/contracts',
    icon: FiFileText,
  },
  reports: {
    label: 'menu.reports',
    path: '/reports',
    icon: FiBarChart2,
    actions: [
      {
        label: 'menu.reports',
        path: '/reports',
        icon: FiCircle,
      },
    ],
  },
  category: {
    label: 'menu.category',
    path: '/category',
    icon: FiLayers,
  },

  correios: {
    label: 'menu.correios',
    path: '/correios',
    icon: FiTruck,
  },

  orders: {
    label: 'menu.orders',
    path: '/orders',
    icon: FiShoppingBag,
  },

  motoboy: {
    label: 'menu.motoboy',
    path: '/motoboy',
    icon: RiMotorbikeLine,
  },

  vipp: {
    label: 'VIPP',
    path: 'https://vipp.visualset.com.br/vipp/inicio/index.php',
    icon: FiExternalLink,
    external: true,
  },

  pagarMe: {
    label: 'Pagar.ME',
    path: 'https://id.pagar.me/signin',
    icon: FiExternalLink,
    external: true,
  },

  omie: {
    label: 'Omnie',
    path: 'https://app.omie.com.br/login/',
    icon: FiExternalLink,
    external: true,
  },
  laboratory: {
    label: 'menu.laboratory',
    path: '/laboratory',
    icon: RiMicroscopeLine,
  },
  blog: {
    label: 'menu.blog',
    path: '/blog',
    icon: RiArticleLine,
  },
  salesCategory: {
    label: 'menu.salesCategory',
    path: '/sales-category',
    icon: FiFolder,
  },
  invoice: {
    label: 'menu.invoice',
    path: '/invoices',
    icon: BsReceipt,
  },
  recollects: {
    label: 'menu.recollects',
    path: '/recollects',
    icon: MdRecycling,
  },
}

export const SIDEBAR_RULES: Role[] = [
  {
    title: 'menu.groups.sales',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.PROFESSIONAL,
      UserRolesNames.FINANCIAL,
      UserRolesNames.BACKOFFICE,
      UserRolesNames.SELLER,
    ],
    children: [
      {
        item: NAV_ITEMS.coupons,
        allowedRoles: [
          UserRolesNames.ADMIN,
          UserRolesNames.PROFESSIONAL,
          UserRolesNames.FINANCIAL,
          UserRolesNames.BACKOFFICE,
          UserRolesNames.SELLER,
        ],
      },
      {
        item: NAV_ITEMS.influencers,
        allowedRoles: [UserRolesNames.ADMIN],
      },
      {
        item: NAV_ITEMS.professionals,
        allowedRoles: [UserRolesNames.ADMIN],
      },
    ],
  },
  {
    title: 'menu.groups.attendance',
    allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.BACKOFFICE],
    children: [
      {
        item: NAV_ITEMS.recollects,
        allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.BACKOFFICE],
      },
    ],
  },
  {
    title: 'menu.groups.products',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.LOGISTIC_MANAGER,
      UserRolesNames.MOTORCYCLE_COURIER,
      UserRolesNames.SELLER,
    ],
    children: [
      {
        item: NAV_ITEMS.products,
        allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.SELLER],
      },
      {
        item: NAV_ITEMS.laboratorial_analysis,
        allowedRoles: [UserRolesNames.ADMIN],
      },
      {
        item: NAV_ITEMS.sample_transport_kit,
        allowedRoles: [
          UserRolesNames.ADMIN,
          UserRolesNames.LOGISTIC_MANAGER,
          UserRolesNames.MOTORCYCLE_COURIER,
        ],
      },
      {
        item: NAV_ITEMS.category,
        allowedRoles: [UserRolesNames.ADMIN],
      },
      {
        item: NAV_ITEMS.salesCategory,
        allowedRoles: [UserRolesNames.ADMIN],
      },
    ],
  },
  {
    title: 'menu.groups.records',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.FINANCIAL,
      UserRolesNames.PROFESSIONAL,
      UserRolesNames.LOGISTIC_MANAGER,
    ],
    children: [
      {
        item: NAV_ITEMS.users,
        allowedRoles: [
          UserRolesNames.ADMIN,
          UserRolesNames.PROFESSIONAL,
          UserRolesNames.LOGISTIC_MANAGER,
        ],
      },
      {
        item: NAV_ITEMS.companies,
        allowedRoles: [UserRolesNames.ADMIN],
      },
      {
        item: NAV_ITEMS.clinics,
        allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.FINANCIAL],
      },
      {
        item: NAV_ITEMS.clients,
        allowedRoles: [UserRolesNames.ADMIN],
      },
      {
        item: NAV_ITEMS.prospects,
        allowedRoles: [UserRolesNames.ADMIN],
      },
      {
        item: NAV_ITEMS.contracts,
        allowedRoles: [UserRolesNames.ADMIN],
      },
      {
        item: NAV_ITEMS.sellers,
        allowedRoles: [UserRolesNames.ADMIN],
      },
    ],
  },
  {
    title: 'menu.groups.financial',
    allowedRoles: [UserRolesNames.FINANCIAL, UserRolesNames.ADMIN],
    children: [
      {
        item: NAV_ITEMS.invoice,
        allowedRoles: [UserRolesNames.FINANCIAL, UserRolesNames.ADMIN],
      },
    ],
  },
  {
    title: 'menu.groups.expedition',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.EXPEDITION,
      UserRolesNames.BACKOFFICE,
      UserRolesNames.FINANCIAL,
    ],
    children: [
      {
        item: NAV_ITEMS.correios,
        allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.EXPEDITION],
      },
      {
        item: NAV_ITEMS.orders,
        allowedRoles: [
          UserRolesNames.ADMIN,
          UserRolesNames.BACKOFFICE,
          UserRolesNames.FINANCIAL,
          UserRolesNames.EXPEDITION,
        ],
      },
      {
        item: NAV_ITEMS.motoboy,
        allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.EXPEDITION],
      },
    ],
  },
  // {
  //   title: 'menu.groups.reports',
  //   allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.BACKOFFICE],
  //   children: [
  //     {
  //       item: NAV_ITEMS.reports,
  //       allowedRoles: [
  //         UserRolesNames.ADMIN,
  //         UserRolesNames.BACKOFFICE,
  //         UserRolesNames.EXPEDITION,
  //       ],
  //     },
  //   ],
  // },
  {
    title: 'menu.groups.external',
    allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.EXPEDITION],
    children: [
      {
        item: NAV_ITEMS.vipp,
        allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.EXPEDITION],
      },
      {
        item: NAV_ITEMS.pagarMe,
        allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.EXPEDITION],
      },
      {
        item: NAV_ITEMS.omie,
        allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.EXPEDITION],
      },
    ],
  },
  {
    title: 'Laboratório',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.LABORATORY_MANAGER,
      UserRolesNames.LABORATORY_AGENT,
      UserRolesNames.BACKOFFICE,
    ],
    children: [
      {
        item: NAV_ITEMS.kits,
        allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.BACKOFFICE],
      },
      {
        item: NAV_ITEMS.laboratory,
        allowedRoles: [
          UserRolesNames.ADMIN,
          UserRolesNames.LABORATORY_MANAGER,
          UserRolesNames.LABORATORY_AGENT,
        ],
      },
    ],
  },
  {
    title: 'menu.groups.blog',
    allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.EXPEDITION],
    children: [
      {
        item: NAV_ITEMS.blog,
        allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.CONTENT_MANAGER],
      },
    ],
  },
  {
    title: 'menu.groups.audits',
    allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.EXPEDITION],
    children: [
      {
        item: NAV_ITEMS.audits,
        allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.CONTENT_MANAGER],
      },
    ],
  },
]
