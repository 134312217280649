// Vendors
import { kebabCase } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, FieldError, useFormContext } from 'react-hook-form'

// Hooks
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FieldText } from 'components/FieldText'
import { ListImages } from 'components/ListImages'
import { FieldSelect } from 'components/FieldSelect'
import { FormWrapper } from 'components/FormWrapper'
import { FieldNoImage } from 'components/FieldNoImage'
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import {
  Grid,
  Stack,
  Divider,
  GridItem,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react'

// Types
import { Image } from 'types/Product'

type FormSEO = {
  slug: string
  seoTitle: string
  seoKeywords: string[]
  seoDescription: string
  seoAdditionalKeywords: string
}

type TabSeoProps = {
  id?: string
  recordReload?: () => void
  memoDefaultImagesSEO?: { list: Image[] }
  endpointUploadImage?: {
    post: string
    delete: string
  }
}

export const TabSeo = (props: TabSeoProps) => {
  const { id, recordReload, memoDefaultImagesSEO, endpointUploadImage } = props

  const selectTags = useSelectParser({
    accessor: { label: 'name', value: 'id' },
    endpoint: '/app/store_product_tag',
  })

  const { t } = useTranslation('common')
  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext<FormSEO>()

  const seoDescription = watch('seoDescription')

  const circularProgressInfo = useMemo(() => {
    const descriptionLength = seoDescription?.length ?? 0

    if (descriptionLength >= 0 && descriptionLength < 100) {
      return {
        color: 'red',
        label: `${descriptionLength}`,
      }
    }

    if (descriptionLength >= 100 && descriptionLength < 140) {
      return {
        color: 'orange',
        label: `${descriptionLength}`,
      }
    }

    if (descriptionLength >= 140 && descriptionLength <= 160) {
      return {
        color: 'green',
        label: `${descriptionLength}`,
      }
    }

    return {
      color: 'red.500',
      label: `${descriptionLength}`,
    }
  }, [seoDescription?.length])

  return (
    <Stack spacing="14">
      <FormWrapper title="Meta tags textuais">
        <Grid
          my="4"
          gap="10"
          w={{ lg: 'container.md' }}
          templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }}
        >
          <GridItem>
            <FieldText
              error={errors.seoTitle}
              label={t('form.tabSeo.seoTitle.label')}
              isRequired
              {...register('seoTitle')}
            />
          </GridItem>

          <GridItem />

          <GridItem position="relative">
            <CircularProgress
              position="absolute"
              right="1"
              max={160}
              size="24px"
              color={circularProgressInfo.color}
              value={watch('seoDescription')?.length}
            >
              <CircularProgressLabel fontSize="8px">
                {circularProgressInfo.label}
              </CircularProgressLabel>
            </CircularProgress>
            <FieldTextArea
              maxLength={160}
              error={errors.seoDescription}
              label={t('form.tabSeo.seoDescription.label')}
              helperText={t('form.tabSeo.seoDescription.helperText')}
              isRequired
              {...register('seoDescription')}
            />
          </GridItem>

          <GridItem />

          <GridItem>
            <FieldText
              label={t('form.tabSeo.slug.label')}
              error={errors.slug}
              isRequired
              helperText={
                t('form.tabSeo.slug.helperText') + kebabCase(watch('slug'))
              }
              {...register('slug')}
            />
          </GridItem>

          <GridItem />

          <GridItem>
            <Controller
              name="seoKeywords"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  error={errors.seoKeywords as FieldError}
                  label={t('form.tabSeo.seoKeywords.label')}
                  options={selectTags.options}
                  isLoading={selectTags.isLoading}
                  helperText={t('form.tabSeo.seoKeywords.helperText')}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <FieldText
              label={t('form.tabSeo.seoAdditionalKeywords.label')}
              error={errors.seoAdditionalKeywords}
              isRequired
              helperText={t('form.tabSeo.seoAdditionalKeywords.helperText')}
              {...register('seoAdditionalKeywords')}
            />
          </GridItem>
        </Grid>
      </FormWrapper>

      <Divider />

      <FormWrapper title="Meta tags imagéticas">
        <Grid my="4" gap="10" w={{ lg: 'container.md' }}>
          {id && recordReload && memoDefaultImagesSEO && endpointUploadImage ? (
            <>
              <GridItem>
                <ListImages
                  title="IMAGEM DE DESTAQUE SEO"
                  group="SEO"
                  reloadRecord={recordReload}
                  endpoint={{
                    delete: endpointUploadImage.delete,
                    post: endpointUploadImage.post,
                  }}
                  records={{ get: memoDefaultImagesSEO, set: () => {} }}
                />
              </GridItem>
            </>
          ) : (
            <>
              <GridItem colSpan={{ base: 2, lg: 4 }}>
                <FieldNoImage label="Imagem de destaque seo" />
              </GridItem>
            </>
          )}
        </Grid>
      </FormWrapper>
    </Stack>
  )
}
