// Vendors
import React from 'react'

// Functions
import { formatDate } from 'utils/formatDate'

// Styles
import {
  Box,
  Text,
  Modal,
  Stack,
  Heading,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Image,
} from '@chakra-ui/react'
import { FormQuestionType } from 'types/LaboratorialAnalysis'

// Interfaces
export type ModalMedicalRecordProps = {
  isOpen: boolean
  onClose: () => void
  purchaseHasProductHasAnalysis?: PurchaseHasProductHasAnalysis[]
}

export type PurchaseHasProductHasAnalysis = {
  id: string
  clinicalRecordFormResponse: {
    questions: FormQuestionType[]
  } | null
  laboratoryAnalysis: {
    name: string
  }
}

export const ModalMedicalRecord = (
  props: ModalMedicalRecordProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, purchaseHasProductHasAnalysis } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal
      size="xl"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ficha clínica</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={8}>
          <Stack>
            {purchaseHasProductHasAnalysis?.length ? (
              purchaseHasProductHasAnalysis.map((item) => {
                const { clinicalRecordFormResponse } = item
                if (!clinicalRecordFormResponse)
                  return (
                    <Box key="test">
                      <Text>
                        Não foi encontrado uma ficha clínica para este kit.
                      </Text>
                    </Box>
                  )

                const { questions } = clinicalRecordFormResponse

                return (
                  <Stack key={item.id} spacing="24px">
                    <Heading as="h2" fontSize="xl" color="#1FB7BC">
                      {item.laboratoryAnalysis.name}
                    </Heading>

                    {questions &&
                      questions.map((question) => {
                        let response = question.response

                        if (question.fieldType === 'checkbox' && response) {
                          response = question.options
                            .filter((option) => option.response)
                            .map((option) => option.label)
                            .join(', ')
                        }

                        if (question.fieldType === 'date' && response) {
                          response = formatDate(
                            new Date(question.response),
                            'dd/MM/yyyy'
                          )
                        }

                        if (question.fieldType === 'title') {
                          return (
                            <Box key={question.id} mb="-12px !important">
                              <Heading as="h3" fontSize="lg" fontWeight="bold">
                                {question.label}
                              </Heading>
                            </Box>
                          )
                        }

                        return (
                          <Box key={question.id}>
                            <Text as="strong" fontSize={16}>
                              {question.label}
                            </Text>
                            <Text fontStyle="italic" mt={-1} fontSize={14}>
                              {response || 'Sem resposta'}
                            </Text>
                          </Box>
                        )
                      })}
                  </Stack>
                )
              })
            ) : (
              <Stack
                p="5"
                spacing="4"
                alignItems="center"
                justifyContent="center"
              >
                <Text fontSize="2xl" color="gray.500">
                  Ops! Está vazio!
                </Text>

                <Text fontSize="lg" color="gray.500">
                  Não há registros nessa categoria no momento.
                </Text>

                <Image src="/taken.svg" mt={{ lg: '3rem !important' }} />
              </Stack>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
