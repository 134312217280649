import { useAuth } from 'contexts/auth'
import { get, intersection, map } from 'lodash'
import { useEffect, useState } from 'react'
import { UserRolesNames } from 'types/User'

type RulesType<T> = {
  name: keyof T
  allowedRoles: UserRolesNames[]
}

type UseCanProps<T> = {
  rules: RulesType<T>[]
}

export type CanEditType<T> = {
  [key in keyof T]: boolean
}

export const useCan = <T extends any>(props: UseCanProps<T>) => {
  const { rules } = props

  const { user } = useAuth()

  const [canEdit, setCanEdit] = useState<CanEditType<T>>({} as CanEditType<T>)

  useEffect(() => {
    if (!user) return

    const userRoles = get(user, 'roleUser')

    if (!userRoles.length) return

    const userRolesNames = map(userRoles, 'role.name')

    const canEditFormatted = rules.reduce((acc, rule) => {
      const allowedRoles = rule.allowedRoles
      const name = rule.name

      if (intersection(allowedRoles, userRolesNames).length) {
        return {
          ...acc,
          [name]: false,
        }
      }

      return {
        ...acc,
        [name]: true,
      }
    }, {} as CanEditType<T>)

    setCanEdit(canEditFormatted)
  }, [rules, user])

  return { can: canEdit }
}
