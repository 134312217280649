// Vendors
import { get, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { toDate } from 'date-fns-tz'

// Functions
import { apiShow } from 'services/get'
import { apiPost } from 'services/post'
import { useAuth } from 'contexts/auth'
import { useError } from 'hooks/useError'
import { useEditRecords } from 'hooks/useEditRecord'
import { useDialogDelete } from 'hooks/useDialogDelete'
import { useFormTransform } from 'hooks/useFormTransform'

// Components
import { FieldText } from 'components/FieldText'
import { FieldMask } from 'components/FieldMask'
import { FieldDate } from 'components/FieldDate'
import { FormFooter } from 'components/FormFooter'
import { FieldStatus } from 'components/FieldStatus'
import { ModalPassword } from 'components/ModalPassword'
import { FieldRadioButton } from 'components/FieldRadioButton'

// Schemas
import { schema } from './schema'

// Styles
import {
  Box,
  Flex,
  Grid,
  Button,
  Divider,
  GridItem,
  useToast,
  Badge,
  useDisclosure,
} from '@chakra-ui/react'

// Types
import { Person } from 'types/Person'
import { PersonFormShowForm } from './types'
import { PersonPurchaseAndKit } from 'types/PersonPurchaseAndKit'
import { UserPermissions } from './UserPermissions'
import { ResponseForDialog } from 'types/ResponseForDialog'

type UserFormShowProps = {
  id: string
  endpoint: string
}

enum Status {
  ENABLED = 'Ativo',
  DISABLED = 'Inativo',
  PENDING_ACTIVATION = 'Aguardando ativação',
  PENDING_RESET_PASSWORD = 'Aguardando redefinição de senha',
}

export const UserFormShow = (props: UserFormShowProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id, endpoint } = props
  const { user } = useAuth()

  const toast = useToast({ position: 'top-right' })
  const { handleError } = useError()
  const {
    isOpen: isOpenModalPassword,
    onOpen: onOpenModalPassword,
    onClose: onCloseModalPassword,
  } = useDisclosure()

  const { statusParser } = useFormTransform()
  const { record } = useEditRecords<Person, Person>({ endpoint, currentId: id })

  const recordCurrent = get(record, 'current')
  const { t } = useTranslation('common')

  const methods = useForm<PersonFormShowForm>({
    resolver: yupResolver(schema(t)),
  })
  const { dialog } = useDialogDelete()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [responseForDialog, setResponseForDialog] = useState<
    ResponseForDialog | undefined
  >(undefined)

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    const formattedCurrent = Object.entries(recordCurrent).reduce(
      (acc, [key, value]) => {
        if (key === 'phoneNumber') {
          return { ...acc, [key]: String(value).replace('+55', '') }
        }

        return { ...acc, [key]: value }
      },
      {}
    )

    const status = statusParser(recordCurrent?.user?.status)

    let birthdate
    if (get(recordCurrent, 'birthdate')) {
      birthdate = toDate(recordCurrent?.birthdate)
    }

    methods.reset({ ...formattedCurrent, birthdate, status })
  }, [methods, recordCurrent, statusParser])

  useEffect(() => {
    const fetchData = async () => {
      const response = await apiShow<PersonPurchaseAndKit>(
        `/app/person/${id}/purchase_kits`
      )

      const responseForDialogKits = map(response?.kits, (kit) => {
        return {
          href: `/kits/${kit.activationCode}`,
          label: kit.activationCode,
        }
      })

      const responseForDialogPurchases = map(
        response?.purchases,
        (purchase) => {
          return {
            href: `/orders/${purchase.id}`,
            label: `#${purchase.purchaseNumber}`,
          }
        }
      )

      setResponseForDialog({
        kits: responseForDialogKits,
        purchases: responseForDialogPurchases,
      })
    }

    fetchData()
  }, [id])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const deleteDialog = useMemo(() => {
    if (!responseForDialog) return

    return dialog(
      {
        title: 'Kits',
        items: responseForDialog.kits,
      },
      {
        title: 'Pedidos',
        items: responseForDialog.purchases,
      }
    )
  }, [dialog, responseForDialog])

  const isPendingActivation = useMemo(() => {
    return recordCurrent?.user?.status === 'PENDING_ACTIVATION'
  }, [recordCurrent])

  const isPendingResetPassword = useMemo(() => {
    return recordCurrent?.user?.status === 'PENDING_RESET_PASSWORD'
  }, [recordCurrent])

  const genresOptions = useMemo(
    () => [
      {
        label: 'Masculino',
        value: 'M',
      },
      {
        label: 'Feminino',
        value: 'F',
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDisabled = useCallback(async () => {
    try {
      await record.disable()
      record.reload()
    } catch (error) {
      handleError(error)
    }
  }, [handleError, record])

  const handleResentEmail = useCallback(async () => {
    try {
      await apiPost(`/app/person/${id}/backoffice/activation_mail`)

      toast({
        title: 'Sucesso',
        description: 'Email reenviado com sucesso',
        status: 'success',
      })
    } catch (error: any) {
      handleError(error)
    }
  }, [handleError, id, toast])

  const onSubmit: SubmitHandler<PersonFormShowForm> = React.useCallback(
    async (data) => {
      try {
        await record.update(data as any)

        toast({
          title: 'Usuario alterado',
          description: 'Usuario alterado com sucesso',
          status: 'success',
        })
      } catch (error) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [methods, record, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <>
      <Flex
        as="form"
        h="full"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box position="relative">
          {isPendingActivation || isPendingResetPassword ? (
            <Badge
              position="absolute"
              top="-10"
              right="0"
              bg="#FEFBCB"
              color="#786400"
              borderRadius={2}
            >
              {Status[recordCurrent?.user?.status as keyof typeof Status]}
            </Badge>
          ) : (
            <FieldStatus
              methods={methods}
              disable={record.disable}
              enable={record.enable}
            />
          )}

          {user && user.person.id === id && (
            <Button onClick={onOpenModalPassword}>Alterar senha</Button>
          )}

          <Grid
            my="4"
            gap="10"
            templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(10, 1fr)' }}
          >
            <GridItem colSpan={{ lg: 5 }}>
              <FieldText
                label="Nome"
                error={methods.formState.errors.name}
                isRequired
                {...methods.register('name')}
              />
            </GridItem>

            <GridItem colSpan={{ lg: isPendingActivation ? 4 : 5 }}>
              <FieldText
                isDisabled
                label="E-mail"
                error={methods.formState.errors.email}
                isRequired
                {...methods.register('email')}
              />
            </GridItem>

            {isPendingActivation && (
              <GridItem alignSelf="flex-end" colSpan={1} w="full">
                <Button colorScheme="blue" w="full" onClick={handleResentEmail}>
                  Reenviar email
                </Button>
              </GridItem>
            )}

            <GridItem colSpan={{ lg: 5 }}>
              <Controller
                name="phoneNumber"
                control={methods.control}
                render={({ field }) => (
                  <FieldMask
                    label="Telefone"
                    mask="(99) 9 9999-9999"
                    error={methods.formState.errors.phoneNumber}
                    isRequired
                    {...field}
                  />
                )}
              />
            </GridItem>

            <GridItem colSpan={{ lg: 5 }}>
              <Controller
                name="documentNumber"
                control={methods.control}
                render={({ field }) => (
                  <FieldMask
                    label="CPF"
                    mask="999.999.999-99"
                    error={methods.formState.errors.documentNumber}
                    isRequired
                    {...field}
                  />
                )}
              />
            </GridItem>

            <GridItem colSpan={{ lg: 5 }}>
              <Controller
                name="birthdate"
                control={methods.control}
                render={({ field }) => (
                  <FieldDate
                    label="Data de nascimento"
                    name={field.name}
                    selected={field.value}
                    onChange={field.onChange}
                    error={methods.formState.errors.birthdate}
                    isRequired
                  />
                )}
              />
            </GridItem>

            <GridItem colSpan={{ lg: 5 }}>
              <FieldRadioButton
                label="Gênero"
                name="gender"
                control={methods.control}
                defaultValue={recordCurrent.gender}
                radioButtonDirection="row"
                options={genresOptions}
                isRequired
              />
            </GridItem>
          </Grid>

          <Divider my="4" />

          <UserPermissions id={id} userRoles={recordCurrent.user?.roles} />
        </Box>

        <FormFooter
          recordDelete={
            deleteDialog?.isDisabled ? handleDisabled : record.delete
          }
          isSubmitting={methods.formState.isSubmitting}
          titleDialog={deleteDialog?.title}
          bodyDialog={deleteDialog?.body}
          confirmLabel={deleteDialog?.isDisabled ? 'Desativar' : 'Deletar'}
        />
      </Flex>

      <ModalPassword
        isOpen={isOpenModalPassword}
        onClose={onCloseModalPassword}
      />
    </>
  )
}
