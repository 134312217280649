// Vendors
import React from 'react'
import { useFormContext } from 'react-hook-form'

// Components
import { FieldText } from 'components/FieldText'
import { AlertDialog } from 'components/AlertDialog'
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import { AlertDialogBody, Stack, Text } from '@chakra-ui/react'

// Interfaces
export type AlertDialogDeliveryProps = {
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
}

export const AlertDialogDelivery = (
  props: AlertDialogDeliveryProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onCancel, onConfirm } = props
  const cancelRef = React.useRef<HTMLButtonElement>(null)
  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext()

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <AlertDialog
      title="Inserção do código de entrega"
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelRef={cancelRef}
      confirmProps={{ isLoading: isSubmitting }}
    >
      <AlertDialogBody>
        <Stack spacing="4">
          <FieldText
            label="Insira o codigo"
            error={errors.deliveryTrackingCode}
            {...register('deliveryTrackingCode', {
              required: 'Código é obrigatório',
              pattern: {
                value: /^[A-Z]{2}[0-9]{9}[A-Z]{2}$/,
                message: 'Código é inválido exem.: SL123456789BR',
              },
            })}
          />

          <FieldTextArea label="Observações" {...register('note')} />
        </Stack>

        <Text fontSize="sm" color="orange.500" mt="4" fontStyle="italic">
          Para envios do tipo motoboy, por favor utilize a ferramenta{' '}
          <Text as="strong">Mudar tipo de envio.</Text>
        </Text>
      </AlertDialogBody>
    </AlertDialog>
  )
}
