// Vendors
import { get, kebabCase } from 'lodash'
import React, { ReactElement, ReactNode } from 'react'

// Styles
import {
  Tab,
  Badge,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as ChakraTabs,
  TabPanelProps,
  TabProps,
  TabListProps,
} from '@chakra-ui/react'

// Interfaces
export type TabDetail = {
  title: string
  component: (props: any) => ReactElement
  options?: unknown
  names?: string[]
}

export type TabsProps = {
  tabsDetails: TabDetail[]
  errors?: any
  tabProps?: TabProps
  tabPanelProps?: TabPanelProps
  tabListProps?: TabListProps
}

export const Tabs = (props: TabsProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { tabsDetails, errors, tabProps, tabPanelProps, tabListProps } = props

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const getAmountError = (tab: TabDetail): number | undefined => {
    const { names } = tab

    if (!names) return

    if (!errors) return

    let amountError = 0

    Object.keys(errors).forEach((key) => {
      if (names.includes(key)) {
        amountError++
      }
    })

    if (!amountError) return

    return amountError
  }

  const getTabList = (): ReactNode => {
    return tabsDetails.map((tab: any) => {
      const amountError = getAmountError(tab)

      return (
        <Tab
          key={get(tab, 'title')}
          className={kebabCase(get(tab, 'title'))}
          color={amountError ? 'red.500' : 'black'}
          {...tabProps}
        >
          {get(tab, 'title')}{' '}
          {amountError && (
            <Badge ml="2" borderRadius="full" colorScheme="red">
              {amountError}
            </Badge>
          )}
        </Tab>
      )
    })
  }

  const getTabPanels = (): ReactNode[] => {
    return tabsDetails.map((tab: any) => {
      const Component: any = get(tab, 'component')
      const options = get(tab, 'options')

      return (
        <TabPanel {...tabPanelProps} key={tab.title}>
          <Component {...options} />
        </TabPanel>
      )
    })
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <ChakraTabs>
      <TabList {...tabListProps}>{getTabList()}</TabList>

      <TabPanels>{getTabPanels()}</TabPanels>
    </ChakraTabs>
  )
}
