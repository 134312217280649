import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const Languages = ['pt-BR', 'en-US', 'es', 'pt', 'ko', 'zh-Hans']

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'pt-BR',
    debug: false, // change to true to see logs on the console
    supportedLngs: Languages,
    lng: 'pt-BR',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
