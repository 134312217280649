import { kebabCase } from 'lodash'
import { FieldTypeEnum, FormQuestionType } from 'types/LaboratorialAnalysis'

export function formatResponseMedicalRecord(
  question: FormQuestionType,
  value: any
) {
  const mapFieldTypeFn: Record<keyof typeof FieldTypeEnum, () => void> = {
    checkbox() {
      question.options.forEach((option: any) => {
        Object.entries(value as Record<string, boolean>).forEach(
          ([optionKey, optionValue]) => {
            if (kebabCase(option.value) === optionKey) {
              option.response = optionValue
            }
          }
        )
      })
    },
    date() {
      question.response = value
    },
    number() {
      question.response = value
    },
    radiobutton() {
      question.response = value?.split('.')[1]
    },
    select() {
      question.response = value?.value?.split('.')[1]
    },
    text() {
      question.response = value
    },
    textarea() {
      question.response = value
    },
    range() {
      question.response = value
    },
    title() {
      question.response = value
    },
  }

  const mapFieldType = mapFieldTypeFn[question.fieldType]

  if (mapFieldType) {
    mapFieldType()
  }

  return question
}
