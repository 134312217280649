// Vendors
import React from 'react'
import { useParams } from 'react-router'

// Components
import { CompanyBaseTestfyFormShow } from '../form/show'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

import { PageMetaSetting } from './meta'

export const PageCompanyBaseTestfyShowRecord = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id } = useParams<{ id: string }>()
  const { endpoint, title } = PageMetaSetting

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={title}>
      <CompanyBaseTestfyFormShow endpoint={endpoint} id={id} />
    </LayoutLoggedPageList>
  )
}
