import React from 'react'
import { Box, Text } from '@chakra-ui/react'

export const GlobalSearchBlank = () => {
  return (
    <Box px="2">
      <Text>Ops... não encontramos nada para sua busca.</Text>
    </Box>
  )
}
