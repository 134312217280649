// Vendors
import React from 'react'

// Components
import { FormCompanyInfo } from 'pages/private/Company/form/FormCompanyInfo'
import { FormCompanyAddress } from 'pages/private/Company/form/FormCompanyAddress'

// Styles
import { Box } from '@chakra-ui/react'

export const TabGeneral = () => {
  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <FormCompanyInfo />
      <FormCompanyAddress />
    </Box>
  )
}
