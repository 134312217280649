// Vendors
import React, { useMemo, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

// Functions
import { useTranslation } from 'react-i18next'
import { useListRecords } from 'hooks/useListRecords'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListActions } from 'components/ListActions'
import { ListPagination } from 'components/ListPagination'
import { ButtonWithMenu } from 'components/ButtonWithMenu'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles
import { Select, Stack } from '@chakra-ui/react'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Types
import { Company } from './types'

import { PageMetaSetting } from './meta'

export const PageCompanyListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('common')
  const history = useHistory()
  const { endpoint, title } = PageMetaSetting
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<Company>({ endpoint })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    Records.list.forEach((record) => {
      record.documentNumber = record.documentNumber.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      )
    })
  }, [Records.list])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const ListTableHeaders = useMemo(
    () => [
      {
        label: t('list_table.headers.company_name'),
        accessor: 'internalIdentification',
      },
      {
        label: 'CNPJ',
        accessor: 'documentNumber',
      },
      // REVIEW: Dont work
      // {
      //   label: t('list_table.headers.company_employees'),
      //   accessor: 'companyEmployees',
      // },
      {
        label: t('list_table.headers.company_type'),
        accessor: 'companyType',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
      {
        label: t('list_table.headers.status'),
        accessor: 'status',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
    ],
    [t]
  )

  const buttonMenuActions = useMemo(
    () => [
      {
        label: 'Clínica',
        onClick: () => history.push('/company/clinic/new'),
      },
      {
        label: 'Laboratório',
        onClick: () => history.push('/company/laboratory/new'),
      },
      {
        label: 'Logísticas',
        onClick: () => history.push('/company/logistics/new'),
      },
      {
        label: 'Testfy Base',
        onClick: () => history.push('/company/testfy-base/new'),
      },
    ],
    [history]
  )

  const ListTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/company/${Record.companyType}/${Record.id}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutLoggedPageList title={title}>
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ListFilters query={Query}>
          <Select
            size="sm"
            placeholder="Filtrar"
            onChange={(e) => {
              Query.set({
                ...Query.current,
                type: e.target.value,
              })
            }}
          >
            <option value="laboratory">
              {t('companies.types.laboratory')}
            </option>
            <option value="logistics">{t('companies.types.logistics')}</option>
            <option value="clinic">{t('companies.types.clinic')}</option>
            <option value="testfy-base">
              {t('companies.types.testfy_base')}
            </option>
          </Select>
        </ListFilters>

        <ListActions>
          <ButtonWithMenu label="Adicionar" actions={buttonMenuActions} />
        </ListActions>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={ListTableRowAction}
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
