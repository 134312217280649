import api from './api'
import { Query, QueryDate } from 'types/Requests'

/*
|-----------------------------------------------------------------------------
| Request handling
|-----------------------------------------------------------------------------
|
|
*/

const handleListFailure = (error: any, endpoint: string): void => {
  console.log('[GET ERROR]', {
    endpoint,
    errorRaw: error,
    status: error.status,
    message: error.message,
  })
}

/*
|-----------------------------------------------------------------------------
| Functions
|-----------------------------------------------------------------------------
|
|
*/

export async function apiList<T>(
  endpoint: string,
  query?: Query
): Promise<T | undefined> {
  let payload
  try {
    const { data: response } = await api.get<T>(endpoint, {
      params: { ...query },
    })
    payload = response
  } catch (error: unknown) {
    handleListFailure(error, endpoint)
    payload = undefined
  }
  return payload
}

export async function apiShow<T>(
  endpoint: string,
  query?: Query | QueryDate
): Promise<T | undefined> {
  let payload
  try {
    const { data: response } = await api.get<T>(endpoint, {
      params: { ...query },
    })
    payload = response
  } catch (error: unknown) {
    handleListFailure(error, endpoint)
    payload = undefined
    throw error
  }
  return payload
}
