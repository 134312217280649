// Vendors
import React, { useCallback } from 'react'
import { Controller, FieldError, useForm } from 'react-hook-form'

// Hooks
import { useError } from 'hooks/useError'

// Components
import { FieldSelect } from 'components/FieldSelect'
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import {
  Text,
  Stack,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react'
import { apiPatch } from 'services/patch'

export enum CobrandingStatus {
  true = 'Este pedido é cobranding',
  false = 'Este pedido não é cobranding',
}

type ModalAlterCobrandingStatusProps = {
  isOpen: boolean
  purchaseId: string
  reload: () => void
  onCancel: () => void
}

type FormAlterCobrandingStatus = {
  isCobranding: {
    label: string
    value: string
  }
  comment: string
}

export const ModalAlterCobrandingStatus = (
  props: ModalAlterCobrandingStatusProps
) => {
  const selectKitStatus = Object.entries(CobrandingStatus).map(
    ([value, label]) => ({
      label,
      value,
    })
  )

  const { isOpen, onCancel, reload, purchaseId } = props

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormAlterCobrandingStatus>()

  const { handleError } = useError()

  const onClose = useCallback(() => {
    reset()
    onCancel()
  }, [onCancel, reset])

  const onSubmit = useCallback(
    async (data: FormAlterCobrandingStatus) => {
      try {
        const {
          comment,
          isCobranding: { value },
        } = data

        await apiPatch(`/app/purchase/${purchaseId}`, {
          isCobranding: value === 'true',
          comment,
        })

        reload()
        onClose()
      } catch (error) {
        handleError(error)
      }
    },
    [handleError, onClose, purchaseId, reload]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Atenção</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text color="gray.500">
            Modificar o status de cobranding pode causar impacto nos processos
            de expedição e emissão de laudos, por favor inclua uma
            justificativa:
          </Text>

          <Stack mt="4">
            <Controller
              control={control}
              name="isCobranding"
              rules={{
                required: 'Por favor, selecione se o pedido é cobranding',
              }}
              render={({ field }) => (
                <FieldSelect
                  label="Escolha um status"
                  error={errors.isCobranding as FieldError}
                  options={selectKitStatus}
                  placeholder="Selecione se o pedido é cobranding..."
                  {...field}
                />
              )}
            />

            <FieldTextArea
              label="Justificativa"
              error={errors.comment}
              placeholder="Digite uma justificativa..."
              {...register('comment', {
                required: 'Por favor, preencha o campo de justificativa',
              })}
            />
          </Stack>

          <Text fontSize="sm" color="orange.500" mt="4" fontStyle="italic">
            Os pedidos com cobranding ativado mostram a identidade visual da
            clínica do médico e não enviam nenhum tipo de email transacional
            para o paciente
          </Text>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose}>Cancelar</Button>
            <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
              Confirmar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
