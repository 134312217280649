// Vendors
import { get, kebabCase } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useMemo, useCallback, useEffect } from 'react'

// Functions
import { formatDate } from 'utils/formatDate'
import formatCurrency from 'utils/formatCurrency'
import { useListRecords } from 'hooks/useListRecords'
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListActions } from 'components/ListActions'
import { MenuExports } from 'components/MenuExports'
import { ListPagination } from 'components/ListPagination'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles & Icons
import { Select, Stack } from '@chakra-ui/react'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Types
import { PageMetaSetting } from './meta'
import { Record } from 'types/Requests'
import { FieldDateRangePicker } from 'components/FieldDateRangePicker'
import { PurchaseHasProductType } from 'pages/private/Orders/show/types'

enum PurchaseTypeEnum {
  B2C = 'B2C',
  B2B = 'B2B',
  LEGACY = 'LEGADO',
  RECOLLECT = 'RECOLETA',
}

type ListRecords = Record & {
  price: string
  status: string | null
  purchaseNumber: string
  purchaseDeliveryId: string
  deliveryTrackingCode: string
  paymentMethodName: string
  shippingMethodName: string
  shippingValue: string
  purchaseHasProducts: PurchaseHasProductType[]
  kitsAmount: string
  kitsName: string
  createdAtFormatted: string
  priceFormatted: string
  isCobranding: boolean
  purchaseType: keyof typeof PurchaseTypeEnum | null
  purchaseTypeFormatted: PurchaseTypeEnum | null
  purchaseOrigin: 'pdv' | 'store' | 'testfy' | null
  purchaseStatus: string
  isCobrandingFormatted: string
  invoiceUniqueNumber: string | null
  buyerName: string | null
  clinicName: string | null
}

export const PageInvoiceListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectVoucherCode = useSelectParser({
    accessor: { value: 'voucherCode', label: 'voucherCode' },
    endpoint: '/app/coupon',
  })

  const selectSeller = useSelectParser({
    accessor: { value: 'userId', label: 'name' },
    endpoint: '/app/person?type=access-order-module',
  })

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const history = useHistory()
  const { t } = useTranslation('common')

  const { endpoint, title } = PageMetaSetting
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<ListRecords>({
    endpoint: endpoint,
    query: {
      startDate: undefined,
      endDate: undefined,
      sort: '-createdAt',
      isBillable: true,
    },
  })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    Records.list.forEach((record) => {
      if (!record.status) {
        record.status = record.purchaseStatus.toUpperCase()
      }

      if (kebabCase(record.paymentMethodName) === 'credit-card') {
        record.paymentMethodName = 'Cartão de crédito'
      }

      record.isCobrandingFormatted = record.isCobranding ? 'COBRANDING' : '-'

      record.kitsAmount = String(record.purchaseHasProducts.length)

      const purchaseType = get(record, 'purchaseType')
      if (purchaseType) {
        record.purchaseTypeFormatted = get(PurchaseTypeEnum, purchaseType)
      }

      record.createdAtFormatted = formatDate(record.createdAt, 'dd/MM/yyyy')
      record.priceFormatted = formatCurrency(+record.price)
      record.buyerName = record.clinicName || record.buyerName
    })
  }, [Records.list])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const ListTableHeaders = useMemo(
    () => [
      {
        label: 'Cód. pedido',
        accessor: 'purchaseNumber',
      },
      {
        label: 'N° nota fiscal',
        accessor: 'invoiceUniqueNumber',
      },
      {
        label: 'Comprador',
        accessor: 'buyerName',
      },
      {
        label: 'Data da compra',
        accessor: 'createdAtFormatted',
      },
      {
        label: 'QTD',
        accessor: 'kitsAmount',
        render: { as: ListTableRenderAs.MULTILINE_TEXT },
      },
      {
        label: 'Total R$',
        accessor: 'priceFormatted',
      },
      {
        label: t('list_table.headers.status'),
        accessor: 'status',
        render: { as: ListTableRenderAs.BADGE },
      },
      {
        label: 'Forma de envio',
        accessor: 'shippingMethodName',
      },
      {
        label: 'Forma de pagamento',
        accessor: 'paymentMethodName',
      },
      {
        label: 'Origem',
        accessor: 'purchaseOrigin',
        render: {
          as: ListTableRenderAs.TEXT,
          options: {
            textTransform: 'uppercase',
          },
        },
      },
      {
        label: 'Tipo de pedido',
        accessor: 'purchaseTypeFormatted',
        render: {
          as: ListTableRenderAs.TEXT,
          options: {
            textTransform: 'uppercase',
          },
        },
      },
      {
        label: '',
        accessor: 'isCobrandingFormatted',
        render: {
          as: ListTableRenderAs.TEXT,
          options: {
            textTransform: 'uppercase',
          },
        },
      },
    ],
    [t]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const transactionDetails = useCallback((_, record) => {
    const { paymentProviderTransactionCode } = record
    window
      .open(
        `https://beta.dashboard.sandbox.pagar.me/#/transactions/${paymentProviderTransactionCode}`,
        'blank'
      )
      ?.focus()
  }, [])

  const listTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/orders/${Record.id}`)
    },
    [history]
  )

  const fnTableActionProps = useCallback((record: any) => {
    return {
      bg: record.isCobranding
        ? 'linear-gradient(270deg, rgba(253, 201, 11, 0.144) 17.46%, rgba(254, 223, 110, 0.207217) 20.29%, rgba(255, 255, 255, 0.3) 115%);'
        : '',
    }
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutLoggedPageList title={title}>
      <Stack>
        <Stack
          spacing="4"
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
        >
          <ListFilters query={Query}>
            <FieldDateRangePicker
              isSmall
              name="filterDate"
              placeholderText="Filtrar por data"
              containerProps={{
                marginBottom: '0',
              }}
              showMonthDropdown
              dropdownMode="select"
              query={Query}
            />
          </ListFilters>

          <ListActions>
            <MenuExports endpoint={endpoint} query={Query.current} />
          </ListActions>
        </Stack>

        <Stack
          overflowX="auto"
          direction="row"
          sx={{ select: { minWidth: '60' } }}
          spacing={{ base: '1', lg: '4' }}
        >
          <Select
            size="sm"
            placeholder="Todos os status"
            onChange={(e) => {
              Query.set({
                ...Query.current,
                status: e.target.value,
              })
            }}
          >
            <option value="SENT">Enviado</option>
            <option value="SHIPPING_ALERT">Alerta de envio</option>
            <option value="IN_TRANSIT">Em trânsito</option>
            <option value="DELIVERED">Entregue</option>
          </Select>

          <Select
            size="sm"
            placeholder="Todos os tipos de compra"
            onChange={(e) => {
              Query.set({
                ...Query.current,
                purchaseType: e.target.value,
              })
            }}
          >
            <option value="B2C">B2C</option>
            <option value="B2B">B2B</option>
            <option value="LEGACY">LEGADO</option>
          </Select>

          <Select
            size="sm"
            onChange={(e) => {
              Query.set({
                ...Query.current,
                isBillable: e.target.value === 'true',
              })
            }}
          >
            <option value="true">Faturáveis</option>
            <option value="false">Não faturáveis</option>
          </Select>

          <Select
            size="sm"
            placeholder="Faturados e não faturados"
            onChange={(e) => {
              Query.set({
                ...Query.current,
                isBilled: e.target.value === 'true',
              })
            }}
          >
            <option value="true">Já faturados</option>
            <option value="false">Não faturados</option>
          </Select>

          <Select
            size="sm"
            placeholder="Cobranding e não cobranding"
            onChange={(e) => {
              Query.set({
                ...Query.current,
                isCobranding: e.target.value === 'isCobranding',
              })
            }}
          >
            <option value="isCobranding">É cobranding</option>
            <option value="notIsCobranding">Não é cobranding</option>
          </Select>

          <Select
            size="sm"
            placeholder="Todos os coupons"
            onChange={(e) =>
              Query.set({ ...Query.current, voucherCode: e.target.value })
            }
          >
            {selectVoucherCode.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>

          <Select
            size="sm"
            placeholder="Todos os vendedores"
            onChange={(e) =>
              Query.set({ ...Query.current, sellerId: e.target.value })
            }
          >
            {selectSeller.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </Stack>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={listTableRowAction}
        menuButtonProps={{ variant: 'ghost' }}
        defaultActions="none"
        tableActionProps={fnTableActionProps}
        actions={[
          {
            type: 'confirmIssuanceNote',
            options: {
              showWhen: ({ invoiceUniqueNumber }) => !invoiceUniqueNumber,
              reload: Records.reload,
            },
          },
          {
            type: 'cancelIssuedInvoice',
            options: {
              showWhen: ({ invoiceUniqueNumber }) => !!invoiceUniqueNumber,
              reload: Records.reload,
            },
          },
        ]}
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
