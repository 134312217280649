import { useToast } from '@chakra-ui/toast'
import { isString } from 'lodash'
import {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react'
import { apiDelete } from 'services/delete'
import { apiList } from 'services/get'
import { Pagination, Query } from 'types/Requests'
import { useError } from './useError'

type ListRecord = {
  endpoint: string | { public: string; private: string }
  query?: Query
}

export function useListRecords<T>(props: ListRecord): {
  records: {
    list: T[]
    delete: (id: string) => void
    update: (newData: T[]) => void
    filtered: () => T[]
    reload: () => void
    clear: () => void
    isLoading?: boolean
  }
  query: {
    current: Query
    set: Dispatch<SetStateAction<Query>>
  }
  pagination: {
    current: Pagination
    set: Dispatch<SetStateAction<Pagination>>
  }
} {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { endpoint, query } = props
  const toast = useToast({ position: 'top-right' })
  const { handleError } = useError()

  const endpointPublic = useMemo(() => {
    return isString(endpoint) ? endpoint : endpoint.public
  }, [endpoint])

  const endpointPrivate = useMemo(() => {
    return isString(endpoint) ? endpoint : endpoint.private
  }, [endpoint])

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isLoading, setIsLoading] = useState(false)
  const [recordsList, setRecordsList] = useState<T[]>([] as T[])
  const [currentQuery, setCurrentQuery] = useState<Query>({
    limit: 10,
    // sort: '-updatedAt',
    ...query,
  } as Query)
  const [currentPagination, setCurrentPagination] = useState<Pagination>(
    {} as Pagination
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */
  const fetchRecords = useCallback(() => {
    setIsLoading(true)
    apiList<{ meta: Pagination; data: T[] }>(endpointPublic, currentQuery)
      .then((payload) => {
        if (!payload) return
        setRecordsList(payload.data)
        setCurrentPagination({
          ...payload.meta,
          currentPageCount: payload.data.length,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [endpointPublic, currentQuery])

  const reloadRecords = useCallback(() => {
    fetchRecords()
  }, [fetchRecords])

  const updateRecords = useCallback(
    (newData: T[]) => {
      if (!newData) return
      setRecordsList(newData)
    },
    [setRecordsList]
  )

  const getFilteredRecords = useCallback<() => T[]>(() => {
    if (!recordsList) return []
    return recordsList
  }, [recordsList])

  const clear = useCallback<() => void>(() => {
    setRecordsList([])
  }, [setRecordsList])

  const deleteRecord = useCallback(
    (id: string) => {
      apiDelete(`${endpointPrivate}/${id}`)
        .then(() => {
          reloadRecords()
          toast({
            title: 'Deletado com sucesso',
            status: 'success',
          })
        })
        .catch((error) => {
          handleError(error)
        })
    },
    [endpointPrivate, reloadRecords, toast, handleError]
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    fetchRecords()
  }, [fetchRecords, currentQuery])

  /*
  |-----------------------------------------------------------------------------
  | Return
  |-----------------------------------------------------------------------------
  |
  |
  */
  return {
    records: {
      delete: deleteRecord,
      list: recordsList,
      filtered: getFilteredRecords,
      update: updateRecords,
      reload: reloadRecords,
      clear: clear,
      isLoading,
    },
    query: {
      current: currentQuery,
      set: setCurrentQuery,
    },
    pagination: {
      current: currentPagination,
      set: setCurrentPagination,
    },
  }
}
