// Vendors
import { map } from 'lodash'
import { Link, useHistory } from 'react-router-dom'
import React, { useMemo, useCallback, useState } from 'react'

// Functions
import { useError } from 'hooks/useError'
import { apiDelete } from 'services/delete'
import { useListRecords } from 'hooks/useListRecords'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListActions } from 'components/ListActions'
import { ListPagination } from 'components/ListPagination'
import { AlertDialogDelete } from 'components/AlertDialogDelete'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles
import { FiPlus } from 'react-icons/fi'
import {
  Box,
  Text,
  Stack,
  Button,
  ListItem,
  useToast,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Types
import { Category } from './types'
import { PageMetaSetting } from './meta'

export const PageCategoryListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const toast = useToast({ position: 'top-right' })
  const { handleError } = useError()

  const deleteDisclosure = useDisclosure()
  const history = useHistory()
  const { endpoint, title } = PageMetaSetting
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<Category>({
    endpoint: { public: endpoint, private: '/app/store_product_category' },
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [selectedCategory, setSelectedCategory] = useState<
    | {
        category: Category
        dialog:
          | { title: string; body: JSX.Element; isDisabled: boolean }
          | undefined
      }
    | undefined
  >(undefined)

  const deleteDialog = useCallback((record: Category) => {
    if (!record || !record.storeProducts) return

    if (record.storeProducts.length > 0) {
      const productsLength = record.storeProducts.length
      const bodyDialog = map(record.storeProducts, (product) => {
        return (
          <Link to={`/products/${product.id}`} key={product.id}>
            <ListItem
              color="blue.600"
              _hover={{
                filter: 'brightness(0.85)',
                textDecoration: 'underline',
              }}
            >
              {product.mktTitle}
            </ListItem>
          </Link>
        )
      })

      return {
        title: 'Este registro não pode ser excluído',
        body: (
          <>
            <Box>
              Atualmente, {productsLength}{' '}
              {productsLength === 1
                ? 'produto ativo utiliza'
                : 'produtos ativos utilizam'}{' '}
              este registro:{' '}
            </Box>
            <UnorderedList>{bodyDialog}</UnorderedList>
          </>
        ),
        isDisabled: true,
      }
    } else {
      return {
        title: 'Por favor confirme a exclusão.',
        isDisabled: false,
        body: (
          <Text>
            Não existem produtos ativos utilizando este registro. Ao excluir
            este item, é possível que produtos desativados que dependam dele não
            possam mais ser ativados. Você tem certeza que deseja excluir este
            item?
          </Text>
        ),
      }
    }
  }, [])

  const handleDelete = useCallback(
    async (e, record: Category) => {
      e.stopPropagation()

      setSelectedCategory({ category: record, dialog: deleteDialog(record) })
      deleteDisclosure.onOpen()
    },
    [deleteDialog, deleteDisclosure]
  )

  const handleDeleteConfirm = useCallback(async () => {
    try {
      await apiDelete(
        `/app/store_product_category/${selectedCategory?.category.id}`
      )
      Records.reload()

      toast({
        title: 'Sucesso',
        description: 'Categoria excluída com sucesso.',
        status: 'success',
      })
    } catch (error: any) {
      handleError(error)
    }
  }, [Records, handleError, selectedCategory?.category.id, toast])

  /*
  |-----------------------------------------------------------------------------
  |-----------------------------------------------------------------------------
  |
  |
  */
  const ListTableHeaders = useMemo(
    () => [
      {
        label: 'Titulo',
        accessor: 'title',
      },
      {
        label: 'Cor de destaque',
        accessor: 'accentColor',
      },
      {
        label: 'Descrição',
        accessor: 'description',
        render: { as: ListTableRenderAs.MULTILINE_TEXT },
      },
      {
        label: 'Criado em',
        accessor: 'createdAt',
        render: { as: ListTableRenderAs.DATE },
      },
    ],
    []
  )

  const ListTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/category/${Record.id}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutLoggedPageList title={title}>
      <AlertDialogDelete
        isOpen={deleteDisclosure.isOpen}
        onCancel={deleteDisclosure.onClose}
        titleDialog={selectedCategory?.dialog?.title}
        bodyDialog={selectedCategory?.dialog?.body}
        isDisabled={selectedCategory?.dialog?.isDisabled}
        onConfirm={() => {
          deleteDisclosure.onClose()
          handleDeleteConfirm()
        }}
      />

      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ListFilters query={Query}></ListFilters>

        <ListActions>
          <Button as={Link} to={'/category/new'} leftIcon={<FiPlus />}>
            Adicionar
          </Button>
        </ListActions>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={ListTableRowAction}
        defaultActions="edit"
        actions={[{ type: 'delete', options: { callback: handleDelete } }]}
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
