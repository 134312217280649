import { Record } from './Requests'

export type LaboratorialAnalysis = Record & {
  name: string
  description: string
  status: string
  laboratoryAnalysisFileId: string
  clinicalRecord: ClinicalRecordType
  historyUpdates: HistoryUpdatesType[]
  medicalReportDeliveryEstimateInDays: number
}

export type ClinicalRecordType = {
  id: string
  title: string
  laboratoryAnalysisId: string
  clinicalRecordForm: FormStructureType
}

export type FormStructureType = {
  questions: FormQuestionType[]
}

export type FormQuestionType = {
  id: string
  label: string
  value?: string
  required: boolean
  fieldType: keyof typeof FieldTypeEnum
  options: FormOptionType[]
  constraints: FormConstraintType[]
  response?: any
  order?: number
}

type FormOptionType = {
  label: string
  value: string
  response?: any
}

export type FormConstraintType = {
  value: string
  name: keyof typeof FormConstraintEnum
}

type HistoryUpdatesType = {
  id: string
  updatedAt: Date
  createdAt: Date
  modelName: string
  alteration: string
  modelIdentifier: string
  updatedByUserId: string
  comments: string | undefined
  updatedByPersonalDataName: string
}

export enum FormConstraintEnum {
  min = 'min',
  max = 'max',
  step = 'step',
  pattern = 'pattern',
  required = 'required',
  maxLength = 'maxLength',
  minLength = 'minLength',
}

export enum FieldTypeEnum {
  text = 'text',
  date = 'date',
  range = 'range',
  title = 'title',
  select = 'select',
  number = 'number',
  checkbox = 'checkbox',
  textarea = 'textarea',
  radiobutton = 'radiobutton',
}
