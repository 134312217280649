import React from 'react'
import { Box, Flex, useColorModeValue } from '@chakra-ui/react'
import Sidebar from 'components/Sidebar'
import PageHeader from 'components/PageHeader'
import { MobileTopBar } from 'components/Sidebar/MobileTopBar'
import { NavSecondary } from 'components/NavSecondary'
import { GlobalSearch } from 'components/GlobalSearch'

type LayoutLoggedPageListProps = {
  children: React.ReactNode
  title: string
}

export const LayoutLoggedPageList: React.FC<LayoutLoggedPageListProps> = (
  props: LayoutLoggedPageListProps
) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { children, title } = props
  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Box height="100vh" overflow="hidden" position="relative">
      <Flex h="full" flexDirection="column">
        <MobileTopBar />
        <Flex flex="1" overflow="hidden">
          <Sidebar display={{ base: 'none', md: 'flex' }} />

          <Flex flex="1" p="0" overflow="auto" direction="column" h="full">
            <NavSecondary />
            <Flex
              bg={useColorModeValue('white', 'gray.800')}
              w="full"
              h="full"
              p="6"
              direction="column"
              id="LayoutContent"
            >
              <PageHeader title={title} />
              {children}
            </Flex>
          </Flex>

          <GlobalSearch />
        </Flex>
      </Flex>
    </Box>
  )
}
