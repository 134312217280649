// Vendors
import { get, intersection } from 'lodash'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import React, { useMemo, useCallback, MouseEvent, useState } from 'react'

// Functions
import { apiShow } from 'services/get'
import { useError } from 'hooks/useError'
import { useFeedback } from 'contexts/feedback'
import { useDownload } from 'hooks/useDownload'
import formatCurrency from 'utils/formatCurrency'

// Components
import { ListTable } from 'components/ListTable'
import { CardDetails } from 'components/CardDetails'
import { DetailsBuyer } from '../components/DetailsBuyer'
import { KitStatus } from 'components/ModalAlterKitStatus'
import { OrderTimelineList } from 'components/OrderTimelineList'
import { ModalMedicalRecord } from 'components/ModalMedicalRecord'
import { ListTableRenderAs } from 'components/ListTableRowComponent'
import { DrawerFormActivateCode } from 'components/DrawerFormActivateCode'

// Styles
import { AiOutlineCreditCard } from 'react-icons/ai'
import { FiDollarSign, FiTruck } from 'react-icons/fi'
import { BsBag, BsCheckCircle, BsXCircle } from 'react-icons/bs'
import {
  Box,
  Flex,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Text,
} from '@chakra-ui/react'
import { User } from '../types'
import { formatDate } from 'utils/formatDate'
import { FormQuestionType } from 'types/LaboratorialAnalysis'
import { formatDocumentNumber } from 'utils/formatDocumentNumber'
import { GenderEnum } from 'pages/private/Laboratorial/show/tabs/formattedCardDetails'
import { CompanyShow } from 'types/Company'
import { useAuth } from 'contexts/auth'
import { UserRolesNames } from 'types/User'

// Types
enum OrderStatus {
  WAITING_PAYMENT,
  PAID,
  REFUSED,
  IN_EXPEDITION,
  SENT,
  IN_TRANSIT,
  SHIPPING_ALERT,
  DELIVERED,
}

export enum PurchaseType {
  B2B = 'B2B',
  B2C = 'B2C',
  LEGACY = 'LEGADO',
  RECOLLECT = 'RECOLETA',
}

type OrderType = {
  price: string
  status: OrderStatus
  createdAt: string
  isCobranding: boolean
  totalQuantity: string
  purchaseNumber: string
  documentNumber: string | null
  purchaseType: keyof typeof PurchaseType
}

type PurchaseHasProductHasAnalysisType = {
  id: string
  laboratoryAnalysisId: string
  analysisReportFiles: Array<{
    id: string
    originalFileName: string
  }>
  clinicalRecordFormResponse: { questions: FormQuestionType[] } | null
  laboratoryAnalysis: {
    name: string
  }
}

type PurchaseHasProductType = {
  id: string
  purchaseHasProductHasAnalysis: PurchaseHasProductHasAnalysisType[]
  hasReportFile: boolean
  status: keyof typeof KitStatus
  patient: string
  title: string
  activationCode: string
  sampleTransportKit: string
  hasActivatedAt: boolean
}

type TabOrderProps = {
  recordReload: () => void
  orderDetails: {
    user: User
    order: OrderType
    companyClinic?: CompanyShow
    purchaseHasProducts: {
      list: PurchaseHasProductType[]
    }
  }
}

type KitDetails = {
  purchaseHasProductHasAnalysis: PurchaseHasProductHasAnalysisType[]
}

export const TabOrder = (props: TabOrderProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { orderDetails, recordReload } = props
  const { userRolesNames } = useAuth()
  const history = useHistory()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const formActivateCodeDisclosure = useDisclosure()
  const { onDownload } = useDownload()
  const { feedback } = useFeedback()
  const { handleError } = useError()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [kitId, setKitId] = useState('')
  const [purchaseHasProductHasAnalysis, setPurchaseHasProductHasAnalysis] =
    useState<PurchaseHasProductHasAnalysisType[] | undefined>(undefined)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDownloadReport = useCallback(
    async (e: MouseEvent<HTMLButtonElement>, item) => {
      e.stopPropagation()
      const { activationCode } = item

      try {
        const response = await apiShow<KitDetails>(`/app/kit/${activationCode}`)

        if (!response) {
          throw new Error('Não foi possível encontrar o kit informado')
        }

        // REVIEW: Verificar como vai funcionar quando tiver mais de um arquivo
        const firstAnalysis = get(
          response,
          'purchaseHasProductHasAnalysis[0]',
          undefined
        )

        if (!firstAnalysis) {
          throw new Error('Não foi possível encontrar a análise informada')
        }

        const firstReport = get(
          firstAnalysis,
          'analysisReportFiles[0]',
          undefined
        )

        if (!firstReport) {
          throw new Error('Não foi possível encontrar o relatório')
        }

        const endpointDownloadReport = `${process.env.REACT_APP_API_URL}/app/reverse_logistic/${activationCode}/analysis/${firstAnalysis.laboratoryAnalysisId}/report/${firstReport.id}/download_report`

        await onDownload(endpointDownloadReport, firstReport.originalFileName)
      } catch (error: any) {
        if (error.response.status === 403) {
          feedback({
            title: 'Aviso',
            description: 'Você não tem permissão para baixar o relatório.',
            status: 'warning',
          })

          return
        }

        handleError(error)
      }
    },
    [feedback, onDownload, handleError]
  )

  const handleShowMedicalRecord = useCallback(
    (e: MouseEvent<HTMLButtonElement>, item: PurchaseHasProductType) => {
      e.stopPropagation()
      setPurchaseHasProductHasAnalysis(item.purchaseHasProductHasAnalysis)
      onOpen()
    },
    [onOpen]
  )

  const listTableRowAction = useCallback(
    (record: PurchaseHasProductType) => {
      if (!record.activationCode) return
      history.push(`/laboratory/${record.activationCode}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const documentNumber = useMemo(() => {
    if (!orderDetails?.order?.documentNumber) return

    if (orderDetails.order.documentNumber.length === 11) {
      return {
        label: 'CPF',
        value: formatDocumentNumber(orderDetails.order.documentNumber),
      }
    }

    if (orderDetails.order.documentNumber.length === 14) {
      return {
        label: 'CNPJ',
        value: formatDocumentNumber(orderDetails.order.documentNumber),
      }
    }
  }, [orderDetails?.order?.documentNumber])

  const listTableHeaders = useMemo(
    () => [
      {
        label: 'Id Kit',
        accessor: 'activationCode',
      },
      {
        label: 'Tipo de kit',
        accessor: 'sampleTransportKit',
      },
      {
        label: 'Serviço',
        accessor: 'laboratoryAnalysisNamesString',
      },
      {
        label: 'Valor unitário',
        accessor: 'unitPrice',
      },
      {
        label: 'Desconto concedido',
        accessor: 'discountApplied',
      },
      {
        label: 'Paciente',
        accessor: 'patient',
      },
      {
        label: 'Status',
        accessor: 'status',
        render: { as: ListTableRenderAs.BADGE },
      },
    ],
    []
  )

  const orderTimeline = useMemo(() => {
    const status = get(orderDetails, 'order.status') as OrderStatus
    const statusPosition = Number(OrderStatus[status])

    const isRefused = statusPosition === OrderStatus.REFUSED

    let paymentStatus = {
      icon: FiDollarSign,
      title: 'Aguardando pagamento',
      isActive: statusPosition >= OrderStatus.WAITING_PAYMENT,
    }

    const refusedPaymentStatus = {
      icon: BsXCircle,
      title: 'Pagamento recusado',
      isError: true,
    }

    if (statusPosition >= OrderStatus.PAID) {
      paymentStatus = {
        icon: AiOutlineCreditCard,
        title: 'Pagamento confirmado',
        isActive: statusPosition >= OrderStatus.PAID,
      }
    }

    let shippingAlert: any = {
      icon: BsCheckCircle,
      title: 'Pedido entregue',
      isActive: statusPosition >= OrderStatus.DELIVERED,
    }

    if (statusPosition === OrderStatus.SHIPPING_ALERT) {
      shippingAlert = {
        icon: BsXCircle,
        title: 'Alerta de envio',
        isError: statusPosition >= OrderStatus.SHIPPING_ALERT,
      }
    }

    return [
      {
        icon: BsBag,
        title: 'Pedido realizado',
        isActive: true,
      },
      ...(isRefused ? [refusedPaymentStatus] : [paymentStatus]),
      // { ...paymentStatus },
      {
        icon: FiTruck,
        title: 'Em transporte',
        isActive: statusPosition >= 3,
      },
      {
        ...shippingAlert,
      },
    ]
  }, [orderDetails])

  const purchaseOwner = useMemo(() => {
    const user = get(orderDetails, 'user', undefined)

    if (!user) return

    const person = get(user, 'person', undefined)

    if (!person) return

    return {
      name: get(person, 'name', '-'),
      email: get(person, 'email', '-'),
      gender: get(GenderEnum, get(person, 'gender'), '-'),
      createdAt: formatDate(get(person, 'createdAt'), 'dd/MM/yyyy'),
      birthdate: formatDate(get(person, 'birthdate'), 'dd/MM/yyyy'),
      documentNumber: formatDocumentNumber(get(person, 'documentNumber')),
    }
  }, [orderDetails])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  const baseHeaders = useMemo(
    () => ['ID KIT', 'TIPO DE KIT', 'SERVIÇO', 'PACIENTE', 'STATUS'],
    []
  )

  if (!orderDetails) {
    return (
      <Table my="4" borderWidth="1px" fontSize="sm">
        <Thead bg="gray.50">
          <Tr>
            {baseHeaders.map((header, index) => (
              <Th key={`header + ${index}`}>{header}</Th>
            ))}
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {baseHeaders.map((header, index) => {
            return (
              <Tr key={`${index}${header}`}>
                <Td colSpan={baseHeaders.length + 1}>
                  <Skeleton height="40px" />
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    )
  }

  return (
    <Box>
      <DrawerFormActivateCode
        isOpen={formActivateCodeDisclosure.isOpen}
        onClose={formActivateCodeDisclosure.onClose}
        kitId={kitId}
        recordReload={recordReload}
      />

      <ModalMedicalRecord
        isOpen={isOpen}
        onClose={onClose}
        purchaseHasProductHasAnalysis={purchaseHasProductHasAnalysis}
      />

      <OrderTimelineList my="8" data={orderTimeline} />

      <Stack direction={{ lg: 'row' }} spacing="4">
        <CardDetails
          title="Detalhes do pedido"
          data={[
            {
              label: 'Cód pedido',
              value: orderDetails.order.purchaseNumber,
            },
            {
              label: 'Data compra',
              value: format(
                new Date(orderDetails.order.createdAt),
                'dd/MM/yyyy'
              ),
            },
            {
              label: 'Total do pedido',
              value: formatCurrency(+orderDetails.order.price),
            },
            {
              label: 'Quantidade',
              value: orderDetails.order.totalQuantity,
            },
            {
              label: 'Tipo de pedido',
              value: (
                <Flex
                  p="8"
                  mt="-4"
                  mb="-10"
                  mr="-6"
                  ml="-8"
                  h="100%"
                  alignItems="center"
                  justifyContent="space-between"
                  bg={
                    get(orderDetails, 'order.isCobranding', false)
                      ? 'linear-gradient(270deg, rgba(253, 201, 11, 0.144) 10%, rgba(254, 223, 110, 0.207217) 14%, rgba(255, 255, 255, 0.3) 25%);'
                      : ''
                  }
                >
                  <Text>
                    {get(
                      PurchaseType,
                      get(orderDetails, 'order.purchaseType'),
                      '-'
                    )}
                  </Text>
                  {get(orderDetails, 'order.isCobranding', false) && (
                    <Text>Cobranding</Text>
                  )}
                </Flex>
              ),
            },
            {
              label: 'Origem do pedido',
              value: get(orderDetails, 'order.purchaseOrigin', '-'),
            },
          ]}
        />

        <DetailsBuyer
          purchaseOwner={purchaseOwner}
          documentNumber={documentNumber}
          id={get(orderDetails, 'user.id')}
          email={get(orderDetails, 'user.username')}
          companyClinic={get(orderDetails, 'companyClinic')}
        />
      </Stack>

      <ListTable
        headers={listTableHeaders}
        records={orderDetails.purchaseHasProducts}
        rowAction={listTableRowAction}
        defaultActions="none"
        actions={[
          {
            type: 'resetActivationCode',
            options: {
              reload: recordReload,
              showWhen: ({ status }: { status: string }) => {
                return status === 'KIT_ACTIVATED'
              },
              notDisableWhen: () => {
                const disabled = !(userRolesNames as UserRolesNames[]).includes(
                  UserRolesNames.SUPERADMIN
                )
                return disabled
              },
            },
          },
          {
            type: 'newActiveKit',
            options: {
              reload: recordReload,
              showWhen: ({ status }: { status: string }) => {
                const hasPermission =
                  intersection(userRolesNames, [
                    UserRolesNames.ADMIN,
                    UserRolesNames.SUPERADMIN,
                  ]).length > 0

                return status === 'PENDING_ACTIVATION' && hasPermission
              },
              notDisableWhen: () => {
                const purchaseType = get(
                  orderDetails,
                  'order.purchaseType'
                ) as keyof typeof PurchaseType

                return purchaseType === 'LEGACY'
              },
            },
          },
          {
            type: 'activeKit',
            options: {
              reload: recordReload,
              showWhen: () => {
                const hasPermission =
                  intersection(userRolesNames, [
                    UserRolesNames.ADMIN,
                    UserRolesNames.SUPERADMIN,
                  ]).length > 0

                return hasPermission
              },
              notDisableWhen: (record) => {
                return !record.hasActivatedAt
              },
            },
          },
          {
            type: 'legacyActiveKit',
            options: {
              reload: recordReload,
              showWhen: ({ status }: { status: string }) => {
                const hasPermission =
                  intersection(userRolesNames, [
                    UserRolesNames.ADMIN,
                    UserRolesNames.SUPERADMIN,
                  ]).length > 0

                return status === 'PENDING_ACTIVATION' && hasPermission
              },
              notDisableWhen: ({ status }: { status: string }) => {
                const purchaseType = get(
                  orderDetails,
                  'order.purchaseType'
                ) as keyof typeof PurchaseType
                return (
                  status !== 'PENDING_ACTIVATION' || purchaseType !== 'LEGACY'
                )
              },
            },
          },
          {
            type: 'alterKitStatus',
            options: {
              reload: recordReload,
              showWhen: () => {
                const hasPermission =
                  intersection(userRolesNames, [
                    UserRolesNames.ADMIN,
                    UserRolesNames.SUPERADMIN,
                  ]).length > 0

                return hasPermission
              },
              notDisableWhen: ({ status }: { status: string }) =>
                status === 'PENDING_ACTIVATION',
            },
          },
          {
            type: 'activateCode',
            options: {
              notDisableWhen: ({ status }: { status: string }) => {
                return status !== 'PENDING_ACTIVATION'
              },
              callback: (_, record) => {
                setKitId(record.id)
                formActivateCodeDisclosure.onOpen()
              },
            },
          },
          {
            type: 'report',
            options: {
              callback: handleDownloadReport,
              showWhen: { hasReportFile: true },
            },
          },
          {
            type: 'medicalRecord',
            options: {
              callback: handleShowMedicalRecord,
              showWhen: (item) => {
                return !item.purchaseHasProductHasAnalysis.some(
                  (i) => !i.clinicalRecordFormResponse
                )
              },
            },
          },
        ]}
      />
    </Box>
  )
}
