// Vendors
import { get } from 'lodash'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo } from 'react'

// Functions
import { apiShow } from 'services/get'

// Components
import { TabReport } from './tabs/TabReport'
import { TabDetail, Tabs } from 'components/Tabs'
import { TabGeneralData } from './tabs/TabGeneralData'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Types
import { PageMetaSetting } from './meta'
import { KitCheckInShow } from '../KitCheckIn/show/types'
import { TabHistory } from './tabs/TabHistory'
import { Text } from '@chakra-ui/react'
import { formatDate } from 'utils/formatDate'

export const PageLaboratoryShowRecord = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const history = useHistory()
  const { t } = useTranslation('laboratory')
  const { id } = useParams<{ id: string }>()
  const { endpoint, title } = PageMetaSetting

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [record, setRecord] = React.useState<KitCheckInShow | undefined>(
    undefined
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const getKitShow = useCallback(async () => {
    try {
      const response = await apiShow<KitCheckInShow>(`${endpoint}/${id}`)

      setRecord(response)
    } catch (error) {
      console.error(error)
    }
  }, [endpoint, id])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    async function getData() {
      await getKitShow()
    }

    getData()
  }, [endpoint, getKitShow, history, id, t])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: t('tabs.generalData'),
        component: TabGeneralData,
        options: {
          record,
          reload: getKitShow,
          activationCode: get(record, 'activationCode'),
        },
      },
      {
        title: 'Histórico',
        component: TabHistory,
        options: { record, historyUpdates: get(record, 'historyUpdates') },
      },
      {
        title: t('tabs.report'),
        component: TabReport,
        options: {
          recordReload: getKitShow,
          activationCode: get(record, 'activationCode'),
          purchaseHasProductHasAnalysis: get(
            record,
            'purchaseHasProductHasAnalysis'
          ),
        },
      },
    ],
    [t, record, getKitShow]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={`${title} ${id}`}>
      {get(record, 'activatedAt') && (
        <Text mt="-6" mb="4">
          Ativado em {formatDate(get(record, 'activatedAt'), 'dd/MM/yyyy')}
        </Text>
      )}
      <Tabs tabsDetails={tabsDetails} />
    </LayoutLoggedPageList>
  )
}
