import { TFunction } from 'react-i18next'
import { yupSchema } from 'utils/yupSchema'

import * as yup from 'yup'

export const schema = (t: TFunction<'common'>) => {
  yupSchema(t)

  return yup.object().shape({
    sku: yup.string().required().label(t('form.kit.sku.label')),

    name: yup.string().required().label(t('form.kit.name.label')),

    description: yup.string().required().label(t('form.kit.description.label')),

    status: yup
      .string()
      .getStatus()
      .required()
      .label(t('form.kit.status.label')),

    heightInCentimeters: yup
      .number()
      .positive()
      .max(999.99)
      .required()
      .label(t('form.kit.heightInCentimeters.label')),

    widthInCentimeters: yup
      .number()
      .positive()
      .max(999.99)
      .required()
      .label(t('form.kit.widthInCentimeters.label')),

    lengthInCentimeters: yup
      .number()
      .positive()
      .max(999.99)
      .required()
      .label(t('form.kit.lengthInCentimeters.label')),

    weightInGrams: yup
      .number()
      .positive()
      .max(999.99)
      .required()
      .label(t('form.kit.weightInGrams.label')),

    laboratoryAnalysisIds: yup
      .array()
      .min(1)
      .required()
      .selectValues()
      .label(t('form.kit.laboratoryAnalysisIds.label')),

    kitVideoInstructions: yup
      .string()
      .required()
      .label(t('form.kit.kitVideoInstructions.label')),
  })
}
