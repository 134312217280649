// Vendors
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'

// Hooks
import { useFeedback } from 'contexts/feedback'
import { useCreateRecord } from 'hooks/useCreateRecord'

// Components
import { FieldText } from 'components/FieldText'
import { FormFooter } from 'components/FormFooter'

// Schemas
import { schema } from './schema'

// Styles
import { Box, Flex, Grid, GridItem, useToast } from '@chakra-ui/react'

// Types
import { RecollectNewForm } from './types'

type RecollectFormNewProps = {
  endpoint: string
}

export const RecollectFormNew = (props: RecollectFormNewProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props

  const { t } = useTranslation('recollect')
  const history = useHistory()

  const { create } = useCreateRecord<RecollectNewForm>({ endpoint })

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<RecollectNewForm>({
    resolver: yupResolver(schema(t)),
  })

  const toast = useToast({ position: 'top-right' })
  const { feedback } = useFeedback()

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<RecollectNewForm> = async (data) => {
    try {
      await create(data)

      toast({
        title: t('toast.success.title'),
        description: t('toast.success.create'),
        status: 'success',
      })

      history.push('/recollects')
    } catch (error: any) {
      if (error && error instanceof Array) {
        error.forEach((fieldError) => {
          setError(fieldError.field, { message: fieldError.message })
        })
      }

      const errorMessage =
        error?.response?.data.message ||
        error?.message ||
        t('toast.error.default')

      feedback({
        title: 'Atenção',
        description: errorMessage,
        status: 'error',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      as="form"
      h="full"
      flexDirection="column"
      justifyContent="space-between"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box>
        <Grid
          my="4"
          gap="10"
          templateColumns={{ lg: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
        >
          <GridItem colSpan={2}>
            <FieldText
              label={t('form.name.label')}
              error={errors.name}
              {...register('name')}
            />
          </GridItem>
        </Grid>
      </Box>

      <FormFooter isSubmitting={isSubmitting} />
    </Flex>
  )
}
