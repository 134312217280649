import { find, map } from 'lodash'
import { useCallback } from 'react'

type OptionsType = {
  value: unknown
  label: unknown
}

type SelectParserReturn<T extends string | string[]> = T extends string
  ? OptionsType | undefined
  : (OptionsType | undefined)[]

export function useFormTransform() {
  const selectParser = useCallback(
    <T extends string | string[]>(
      value: T,
      options: OptionsType[]
    ): SelectParserReturn<T> => {
      let defaultValue

      if (Array.isArray(value)) {
        defaultValue = map(value, (val) => find(options, ['value', val]))
      } else {
        defaultValue = find(options, ['value', value])
      }

      return defaultValue as SelectParserReturn<T>
    },
    []
  )

  const statusParser = useCallback((value: string) => {
    return value === 'ENABLED'
  }, [])

  return {
    selectParser,
    statusParser,
  }
}
