import React, { useCallback } from 'react'
import { get } from 'lodash'

import { ModalLegacyActiveKit } from 'components/ModalLegacyActiveKit'

import { BsPatchCheck } from 'react-icons/bs'
import { useDisclosure } from '@chakra-ui/react'

import { ActionProps } from './types'
import { WrapperAction } from './WrapperAction'

export const LegacyActiveKitAction = <T extends unknown>({
  record,
  options,
}: ActionProps<T>) => {
  const modalLegacyActiveKitDisclosure = useDisclosure()

  const activationCode = get(record, 'activationCode')
  const { callback, actionType = 'menu', reload, ...optionsRest } = options

  if (!reload) {
    throw new Error('reload is required')
  }

  const cta = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, data: T) => {
      e.stopPropagation()
      modalLegacyActiveKitDisclosure.onOpen()

      if (callback) {
        callback(data)
      }
    },
    [modalLegacyActiveKitDisclosure, callback]
  )

  return (
    <>
      <ModalLegacyActiveKit
        reload={reload}
        activationCode={activationCode}
        isOpen={modalLegacyActiveKitDisclosure.isOpen}
        onCancel={modalLegacyActiveKitDisclosure.onClose}
      />

      <WrapperAction
        cta={cta}
        record={record}
        options={optionsRest}
        actionType={actionType}
        label="Legado: Ativar kit sem ficha clínica"
        tooltip="Para ativar o kit sem ficha clínica, é necessário que o pedido seja do tipo 'Legado'."
        tooltipProps={{
          shouldWrapChildren: true,
          isDisabled: !optionsRest.isDisabled,
        }}
      />
    </>
  )
}
