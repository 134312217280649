// Vendors
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory, Link } from 'react-router-dom'
import React, { useMemo, useCallback, useState } from 'react'

// Functions
import { useError } from 'hooks/useError'
import { apiDelete } from 'services/delete'
import { useListRecords } from 'hooks/useListRecords'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListActions } from 'components/ListActions'
import { DuplicateFrom } from 'components/DuplicateFrom'
import { ListPagination } from 'components/ListPagination'
import { AlertDialogDelete } from 'components/AlertDialogDelete'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles

import {
  Box,
  Text,
  Stack,
  ListItem,
  useToast,
  useDisclosure,
  UnorderedList,
} from '@chakra-ui/react'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Types
import { LaboratorialAnalysis } from './types'
import { PageMetaSetting } from './meta'

export const PageLaboratorialAnalysisListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const toast = useToast({ position: 'top-right' })
  const { handleError } = useError()

  const deleteDisclosure = useDisclosure()
  const { t } = useTranslation('common')
  const history = useHistory()
  const { endpoint, title } = PageMetaSetting
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<LaboratorialAnalysis>({ endpoint })

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [selectedLaboratorialAnalysis, setSelectedLaboratorialAnalysis] =
    useState<
      | {
          laboratorialAnalysis: LaboratorialAnalysis
          dialog:
            | { title: string; body: JSX.Element; isDisabled: boolean }
            | undefined
        }
      | undefined
    >(undefined)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const deleteDialog = useCallback((record: LaboratorialAnalysis) => {
    if (!record || !record.storeProducts || !record.sampleTransportKits) return
    const productsLength = record.storeProducts.length
    const kitsLength = record.sampleTransportKits.length

    if (productsLength > 0 || kitsLength > 0) {
      const totalItems = productsLength + kitsLength

      const products = map(record.storeProducts, (product) => {
        return (
          <Link to={`/products/${product.id}`} key={product.id}>
            <ListItem
              color="blue.600"
              _hover={{
                filter: 'brightness(0.85)',
                textDecoration: 'underline',
              }}
            >
              {product.mktTitle}
            </ListItem>
          </Link>
        )
      })
      const sampleTransportKits = map(
        record.sampleTransportKits,
        (sampleTransportKit) => {
          return (
            <Link
              to={`/products/${sampleTransportKit.id}`}
              key={sampleTransportKit.id}
            >
              <ListItem
                color="blue.600"
                _hover={{
                  filter: 'brightness(0.85)',
                  textDecoration: 'underline',
                }}
              >
                {sampleTransportKit.name}
              </ListItem>
            </Link>
          )
        }
      )

      return {
        title: 'Este registro não pode ser excluído',
        body: (
          <>
            <Box>
              Atualmente, {totalItems}{' '}
              {totalItems === 1
                ? 'item ativo utiliza'
                : 'itens ativos utilizam'}{' '}
              este registro:{' '}
            </Box>

            {!!productsLength && (
              <Box mt="5">
                <Text>Produtos</Text>
                <UnorderedList>{products}</UnorderedList>
              </Box>
            )}

            {!!kitsLength && (
              <Box mt="5">
                <Text>Kits</Text>
                <UnorderedList>{sampleTransportKits}</UnorderedList>
              </Box>
            )}
          </>
        ),
        isDisabled: true,
      }
    } else {
      return {
        title: 'Por favor confirme a exclusão.',
        isDisabled: false,
        body: (
          <Text>
            Não existem produtos ativos utilizando este registro. Ao excluir
            este item, é possível que produtos desativados que dependam dele não
            possam mais ser ativados. Você tem certeza que deseja excluir este
            item?
          </Text>
        ),
      }
    }
  }, [])

  const handleDelete = useCallback(
    async (e, record: LaboratorialAnalysis) => {
      e.stopPropagation()

      setSelectedLaboratorialAnalysis({
        laboratorialAnalysis: record,
        dialog: deleteDialog(record),
      })
      deleteDisclosure.onOpen()
    },
    [deleteDialog, deleteDisclosure]
  )

  const handleDeleteConfirm = useCallback(async () => {
    try {
      await apiDelete(
        `${endpoint}/${selectedLaboratorialAnalysis?.laboratorialAnalysis.id}`
      )
      Records.reload()

      toast({
        title: 'Sucesso',
        description: 'Análise laboratorial excluída com sucesso.',
        status: 'success',
      })
    } catch (error: any) {
      handleError(error)
    }
  }, [
    endpoint,
    selectedLaboratorialAnalysis?.laboratorialAnalysis.id,
    Records,
    toast,
    handleError,
  ])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const ListTableHeaders = useMemo(
    () => [
      {
        label: t('list_table.headers.name'),
        accessor: 'name',
      },
      {
        label: t('list_table.headers.description'),
        accessor: 'description',
        render: { as: ListTableRenderAs.MULTILINE_TEXT },
      },
      {
        label: t('list_table.headers.status'),
        accessor: 'status',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
    ],
    [t]
  )

  const ListTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/laboratorial_analysis/${Record.id}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutLoggedPageList title={title}>
      <AlertDialogDelete
        isOpen={deleteDisclosure.isOpen}
        onCancel={deleteDisclosure.onClose}
        titleDialog={selectedLaboratorialAnalysis?.dialog?.title}
        bodyDialog={selectedLaboratorialAnalysis?.dialog?.body}
        isDisabled={selectedLaboratorialAnalysis?.dialog?.isDisabled}
        onConfirm={async () => {
          deleteDisclosure.onClose()
          handleDeleteConfirm()
        }}
      />

      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ListFilters query={Query}></ListFilters>

        <ListActions>
          <DuplicateFrom
            href="/laboratorial_analysis/new"
            endpoint={endpoint}
            accessor={{ label: 'name', value: 'id' }}
          />
        </ListActions>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={ListTableRowAction}
        defaultActions="edit"
        actions={[{ type: 'delete', options: { callback: handleDelete } }]}
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
