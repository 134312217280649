import React from 'react'
import { Box, Flex, useColorModeValue } from '@chakra-ui/react'
import Sidebar from 'components/Sidebar'
import { MobileTopBar } from 'components/Sidebar/MobileTopBar'

import { useAuth } from 'contexts/auth'

type LayoutErrorProps = {
  children: React.ReactNode
  title: string
}

const LayoutError = ({ children, title }: LayoutErrorProps): JSX.Element => {
  const { user } = useAuth()
  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Box height="100vh" overflow="hidden" position="relative">
      <Flex h="full" flexDirection="column">
        <MobileTopBar />
        <Flex flex="1" overflow="hidden">
          {user && <Sidebar display={{ base: 'none', md: 'flex' }} />}

          <Flex flex="1" p="0" overflow="auto">
            <Box bg={useColorModeValue('white', 'gray.800')} h="full" w="full">
              <Box h="100%">{children}</Box>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

export default LayoutError
