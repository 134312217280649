import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, FieldError, useFormContext } from 'react-hook-form'

// Components
import { FieldSet } from 'components/FieldSet'
import { FieldText } from 'components/FieldText'
import { FieldDate } from 'components/FieldDate'
import { FieldMask } from 'components/FieldMask'
import { ListImages } from 'components/ListImages'
import { FieldSelect } from 'components/FieldSelect'
import { ListAddress } from 'components/ListAddress'
import { NecessaryAction } from 'components/NecessaryAction'

// Styles
import { Box, Divider, Grid, GridItem } from '@chakra-ui/react'
import { useSelectParser } from 'hooks/useSelectParser'
import { useFormTransform } from 'hooks/useFormTransform'
import {
  ClinicEnum,
  CouncilEnum,
  GenderEnum,
  ProfessionalShowForm,
} from '../types'
import { filter, find, map } from 'lodash'
import api from 'services/api'

type TabPersonalDataProps = {
  id: string
  reload: () => void
}

export const TabPersonalData = ({ id, reload }: TabPersonalDataProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('professional')

  const selectGender = {
    options: Object.entries(GenderEnum).map(([key, value]) => ({
      label: t(`form.gender.${String(key).toLowerCase()}`),
      value: value,
    })),
  }

  const selectSeller = useSelectParser<any>({
    accessor: { value: 'userId', label: 'name' },
    endpoint: '/app/person?type=seller',
  })

  const selectState = useSelectParser<any>({
    accessor: { value: 'abbreviatedName', label: 'abbreviatedName' },
    endpoint: '/app/state',
    requestWithoutMeta: true,
  })

  const selectClinic = useSelectParser<any>({
    accessor: { value: 'id', label: 'internalIdentification' },
    endpoint: '/app/company?type=clinic',
  })

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { selectParser } = useFormTransform()

  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<ProfessionalShowForm>()

  const sellerUserId = getValues('sellerUserId')
  const genderKey = getValues('gender')
  const statusRoleProfessional = getValues('statusRoleProfessional')
  const councilStateValue = getValues(CouncilEnum.councilStateValue)
  const recordClinicId = getValues(ClinicEnum.recordClinicId)
  const councilFiles = getValues([
    CouncilEnum.councilFileFront,
    CouncilEnum.councilFileBack,
    CouncilEnum.councilFileSelfie,
  ])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */
  async function getBase64(url: string) {
    return api
      .get(url, {
        responseType: 'arraybuffer',
      })
      .then((response) =>
        Buffer.from(response.data, 'binary').toString('base64')
      )
  }
  const [councilFilesMemo, setCouncilFilesMemo] = React.useState<any[]>([])

  useEffect(() => {
    if (councilFiles.includes(undefined) || councilFiles.includes('')) return
    if (!councilFiles || councilFilesMemo.length) return

    async function councilFilesFetch() {
      const council = await Promise.all(
        map(councilFiles, async (file: any) => {
          if (file) {
            const response = await getBase64(
              `${process.env.REACT_APP_API_URL}/app/person/${id}/document_download/${file.id}`
            )

            return {
              fileUri: `data:image/png;base64,${response}`,
              title: file.originalFileName,
            }
          }
        })
      )
      setCouncilFilesMemo(filter(council, (file: any) => file))
    }

    councilFilesFetch()
  }, [councilFiles, councilFilesMemo.length, id])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (typeof sellerUserId === 'object') return
    const clinic = selectParser(sellerUserId, selectSeller.options)

    if (!clinic) return
    setValue('sellerUserId', clinic)
  }, [sellerUserId, selectSeller.options, selectParser, setValue])

  useEffect(() => {
    if (typeof genderKey === 'object') return
    const clinic = selectParser(genderKey, selectGender.options)

    if (!clinic) return
    setValue('gender', clinic)
  }, [genderKey, selectGender.options, selectParser, setValue])

  useEffect(() => {
    if (typeof recordClinicId === 'object') return
    const clinic = selectParser(recordClinicId, selectClinic.options)

    if (!clinic) return
    setValue(ClinicEnum.recordClinicId, clinic)
  }, [recordClinicId, selectClinic.options, selectParser, setValue])

  useEffect(() => {
    if (typeof councilStateValue === 'object') return
    const state = find(selectState.options, { value: councilStateValue })

    if (!state) return
    setValue(CouncilEnum.councilStateValue, state)
  }, [councilStateValue, selectParser, selectState, setValue])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <FieldSet title={t('tabs.tabPersonalData.personalData.title')}>
        <Grid gap="10" my="4" templateColumns={{ lg: 'repeat(3, 1fr)' }}>
          <GridItem>
            <FieldText
              label={t('form.name.label')}
              error={errors.name}
              isRequired
              {...register('name')}
            />
          </GridItem>

          <GridItem>
            <FieldText
              label={t('form.email.label')}
              error={errors.email}
              isRequired
              {...register('email')}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <FieldMask
                  label={t('form.phoneNumber.label')}
                  mask="(99) 99999-9999"
                  isRequired
                  error={errors.phoneNumber}
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="documentNumber"
              control={control}
              render={({ field }) => (
                <FieldMask
                  label={t('form.documentNumber.label')}
                  mask="999.999.999-99"
                  error={errors.documentNumber}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="birthdate"
              control={control}
              render={({ field }) => (
                <FieldDate
                  dateFormat="dd/MM/yyyy"
                  label={t('form.birthdate.label')}
                  peekNextMonth
                  showYearDropdown
                  showMonthDropdown
                  name={field.name}
                  dropdownMode="select"
                  selected={field.value}
                  onChange={field.onChange}
                  error={errors.birthdate as FieldError}
                  isRequired
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  label={t('form.gender.label')}
                  error={errors.gender}
                  options={selectGender.options}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>
        </Grid>
      </FieldSet>

      <Box
        as="fieldset"
        mb="10"
        minW={{ base: 'lg' }}
        w={{ xl: 'container.md' }}
      >
        <ListAddress personId={id} />
      </Box>

      <FieldSet title={t('tabs.tabPersonalData.professionalData.title')}>
        <Grid gap="10" my="4" templateColumns={{ lg: 'repeat(3, 1fr)' }}>
          <GridItem>
            <FieldText
              label={t('form.council-type.label')}
              error={errors[CouncilEnum.councilType]}
              isRequired
              {...register(CouncilEnum.councilType)}
            />
          </GridItem>

          <GridItem>
            <FieldText
              label={t('form.council-value.label')}
              error={errors['council-value']}
              isRequired
              {...register('council-value')}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="council-state-value"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  label={t('form.council-state-value.label')}
                  error={errors['council-state-value']}
                  isLoading={selectState.isLoading}
                  options={selectState.options}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          {statusRoleProfessional !== 'PENDING_DOCUMENTS' && (
            <GridItem>
              <Controller
                name="sellerUserId"
                control={control}
                render={({ field }) => (
                  <FieldSelect
                    label={t('form.sellerUserId.label')}
                    error={errors.sellerUserId}
                    isLoading={selectSeller.isLoading}
                    options={selectSeller.options}
                    isRequired
                    {...field}
                  />
                )}
              />
            </GridItem>
          )}

          <GridItem colSpan={{ lg: 3 }}>
            <ListImages
              isDisabled
              group="FEATURED_IMAGE_FEATURE"
              title={t('form.boardPortfolio.label')}
              hasDescription={false}
              records={{
                get: { list: councilFilesMemo },
                set: () => {},
              }}
              endpoint={{
                post: ``,
                delete: ``,
                patch: ``,
              }}
              reloadRecord={() => {}}
            />
          </GridItem>
        </Grid>
      </FieldSet>

      <FieldSet title={t('tabs.tabPersonalData.clinic.title')}>
        <Grid gap="10" my="4" templateColumns={{ lg: 'repeat(3, 1fr)' }}>
          <GridItem>
            <Controller
              name={ClinicEnum.recordClinicId}
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label={t('form.clinic.label')}
                  error={errors[ClinicEnum.recordClinicId] as FieldError}
                  isLoading={selectClinic.isLoading}
                  options={selectClinic.options}
                  {...field}
                />
              )}
            />
          </GridItem>
        </Grid>
      </FieldSet>

      <Divider mb={4} />

      {statusRoleProfessional === 'PENDING_DOCUMENTS' && (
        <NecessaryAction personId={id} reload={reload} />
      )}
    </Box>
  )
}
