import { Text } from '@chakra-ui/react'
import axios, { AxiosError } from 'axios'
import { useFeedback } from 'contexts/feedback'
import React, { useCallback } from 'react'

type MessagesType = {
  400?: string
  403?: string
  404?: string
  500?: string
}

type ReturnStatusType = {
  [key: number]: boolean
}

type ErrorsType = {
  rule: string
  field: string
  message: string
}

export const useError = () => {
  const { feedback } = useFeedback()

  const axiosError = useCallback(
    (
      error: AxiosError,
      messages?: MessagesType,
      returnStatus?: ReturnStatusType
    ) => {
      const { response } = error

      if (!response) {
        feedback({
          title: 'Algo deu errado',
          description: 'Ops, algo deu errado',
          status: 'error',
          duration: 5000,
        })

        return
      }

      if (response.status === 500) {
        feedback({
          title: 'Algo deu errado',
          description:
            messages?.[500] ||
            'Ops tivemos um problema interno, tente novamente mais tarde',
          status: 'error',
          duration: 5000,
        })

        return
      }

      if (response.status === 403) {
        feedback({
          title: 'Algo deu errado',
          description:
            messages?.[403] || 'Você não tem permissão para executar esta ação',
          status: 'error',
          duration: 5000,
        })

        return
      }

      const { data } = response

      if (data.message) {
        let { message } = data

        const [_, ...newMessage] = message?.split(':')

        if (newMessage) {
          message = newMessage
        }

        feedback({
          title: 'Algo deu errado',
          description: message,
          status: 'error',
          duration: 5000,
        })
        return
      }

      if (data.errors && response.status === 422) {
        const { errors } = data as { errors: ErrorsType[] }

        if (returnStatus && returnStatus[422]) {
          return errors
        }

        let message: string | React.ReactNode[] = ''

        const errorsMessage = errors.map(({ field, message }) => {
          return (
            <Text key={`${field}-${message}`}>
              <Text as="strong">{field}: </Text>
              {message}
            </Text>
          )
        })

        message = errorsMessage

        feedback({
          title: 'Algo deu errado',
          description: message,
          status: 'error',
          duration: 5000,
        })
      }

      if (response.status === 404) {
        feedback({
          title: 'Algo deu errado',
          description:
            messages?.[404] ||
            'Ops, não encontramos o que você está procurando',
          status: 'error',
        })
      }

      if (response.status === 400) {
        const message = data || data.message || 'Ops, algo deu errado'

        feedback({
          title: 'Algo deu errado',
          description: messages?.[400] || message,
          status: 'error',
        })
      }
    },
    [feedback]
  )

  const handleError = useCallback(
    (
      error: unknown,
      messages?: MessagesType,
      returnStatus?: ReturnStatusType
    ) => {
      if (axios.isAxiosError(error)) {
        return axiosError(error, messages, returnStatus)
      }

      if (error instanceof Error) {
        feedback({
          title: 'Algo deu errado',
          description: error.message,
          status: 'error',
        })

        return
      }

      feedback({
        title: 'Algo deu errado',
        description: 'Ops, algo deu errado',
        status: 'error',
      })
    },
    [axiosError, feedback]
  )

  return {
    handleError,
  }
}
