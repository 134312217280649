import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

export const GeneticsOne = (
  props: HTMLChakraProps<'svg'> & {
    iconColor?: string
    colorStopInit: string
    colorStopEnd: string
  }
) => {
  const { iconColor, colorStopInit, colorStopEnd, ...rest } = props
  const color = iconColor || 'white'
  return (
    <chakra.svg
      id="Componente_51_1"
      data-name="Componente 51 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="53.73"
      height="53.73"
      viewBox="0 0 53.73 53.73"
      {...rest}
    >
      <svg>
        <path
          id="Caminho_3"
          data-name="Caminho 3"
          d="M26.865,53.73A26.865,26.865,0,1,0,0,26.865,26.865,26.865,0,0,0,26.865,53.73Z"
          fill={colorStopInit}
        />
        <g id="Grupo_1" data-name="Grupo 1" transform="translate(14.177 8.051)">
          <g id="Grupo_2725" data-name="Grupo 2725">
            <path
              id="Caminho_7265"
              data-name="Caminho 7265"
              d="M292.169,67.485a1,1,0,0,0,1,1h19.7c.025-.342.047-.684.047-1.039,0-.333-.022-.65-.044-.964H293.17A1,1,0,0,0,292.169,67.485Z"
              transform="translate(-288.999 -30.949)"
              fill={color}
              stroke={color}
              strokeMiterlimit="10"
              strokeWidth="0.25"
            />
            <path
              id="Caminho_7266"
              data-name="Caminho 7266"
              d="M292.734,57.169h19.712a1,1,0,0,0,0-2.006H292.464a3.184,3.184,0,0,1,.232,1.186C292.7,56.632,292.718,56.9,292.734,57.169Z"
              transform="translate(-288.368 -55.163)"
              fill={color}
              stroke={color}
              strokeMiterlimit="10"
              strokeWidth="0.25"
            />
            <path
              id="Caminho_7267"
              data-name="Caminho 7267"
              d="M308.737,58.053a1,1,0,0,0-1-1H292.823a12.163,12.163,0,0,0,1.051,2h13.861A1,1,0,0,0,308.737,58.053Z"
              transform="translate(-287.6 -51.123)"
              fill={color}
              stroke={color}
              strokeMiterlimit="10"
              strokeWidth="0.25"
            />
            <path
              id="Caminho_7268"
              data-name="Caminho 7268"
              d="M294.3,58.94c.813.687,1.682,1.35,2.6,2h4.058a1,1,0,1,0,0-2Z"
              transform="translate(-284.439 -47.085)"
              fill={color}
              stroke={color}
              strokeMiterlimit="10"
              strokeWidth="0.25"
            />
            <path
              id="Caminho_7272"
              data-name="Caminho 7272"
              d="M293.325,65.6a1,1,0,0,0,1,1h15.2a12.314,12.314,0,0,0-1.061-2H294.326A1,1,0,0,0,293.325,65.6Z"
              transform="translate(-286.526 -34.989)"
              fill={color}
              stroke={color}
              strokeMiterlimit="10"
              strokeWidth="0.25"
            />
            <path
              id="Caminho_7273"
              data-name="Caminho 7273"
              d="M302.258,64.71c-.813-.684-1.682-1.35-2.6-2h-4.112a1,1,0,0,0,0,2Z"
              transform="translate(-283.915 -39.028)"
              fill={color}
              stroke={color}
              strokeMiterlimit="10"
              strokeWidth="0.25"
            />
            <path
              id="Caminho_7274"
              data-name="Caminho 7274"
              d="M305.679,72.992c-6.438-4.288-12.518-8.334-12.518-16.824a1,1,0,1,0-2,0c0,9.564,6.818,14.1,13.412,18.491,6.378,4.247,12.41,8.26,12.6,16.645,0,.083-.024,1.058.421,1.327a1,1,0,0,0,1.358-.253,2.659,2.659,0,0,0,.2-1.654C318.754,81.692,312.112,77.271,305.679,72.992Z"
              transform="translate(-291.159 -55.163)"
              fill={color}
              stroke={color}
              strokeMiterlimit="10"
              strokeWidth="0.25"
            />
          </g>
        </g>
      </svg>
    </chakra.svg>
  )
}
