// Vendors
import { FieldError } from 'react-hook-form'
import React, { forwardRef, ForwardRefRenderFunction } from 'react'

// Components
import { useFormHelperText } from 'hooks/useFormHelperText'

// Styles
import { colorStyles, colorStylesError } from './styles'
import ReactSelect, { Props as ReactSelectProps } from 'react-select'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  Text,
} from '@chakra-ui/react'
import { Tooltip, TooltipProps } from 'components/Tooltip'

// Interfaces
export type FieldSelectProps = ReactSelectProps & {
  name: string
  label?: string
  error?: FieldError
  canModify?: boolean
  helperText?:
    | string
    | {
        text: string
        color?: string
      }
  tooltipProps?: TooltipProps
  isRequired?: boolean
}

const FieldSelectBase: ForwardRefRenderFunction<any, FieldSelectProps> = (
  props,
  ref
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    name,
    label,
    error,
    canModify = true,
    helperText,
    tooltipProps,
    isRequired,
    ...rest
  } = props
  const componentHelperText = useFormHelperText({ helperText, canModify })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormControl isInvalid={!!error}>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        {!!label && (
          <FormLabel htmlFor={name} m={0}>
            {label}

            {isRequired && (
              <Text
                as="span"
                color="red.500"
                ml={1}
                fontWeight="bold"
                userSelect="none"
              >
                *
              </Text>
            )}
          </FormLabel>
        )}

        {tooltipProps && Object.keys(tooltipProps).length && (
          <Tooltip {...tooltipProps} verticalAlign="middle" />
        )}
      </Flex>

      <ReactSelect
        id={name}
        name={name}
        ref={ref}
        menuPlacement={'bottom'}
        styles={{ ...colorStyles, ...(error ? colorStylesError : {}) }}
        {...rest}
      />

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      {(helperText || !canModify) && !error && componentHelperText}
    </FormControl>
  )
}

export const FieldSelect = forwardRef(FieldSelectBase)
