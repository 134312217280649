// Vendors
import { find, get, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

// Functions
import { apiShow } from 'services/get'
import { useError } from 'hooks/useError'
import { useEditRecords } from 'hooks/useEditRecord'
import { useDialogDelete } from 'hooks/useDialogDelete'
import { useFormTransform } from 'hooks/useFormTransform'

// Components
import { TabDetail, Tabs } from 'components/Tabs'
import { FormFooter } from 'components/FormFooter'
import { FieldStatus } from 'components/FieldStatus'
import { TabGeneralShow } from '../tabs/TabGeneralShow'
import { ButtonNewOrder } from 'components/ButtonNewOrder'

// Styles
import { Box, Flex, useToast } from '@chakra-ui/react'

// Schemas
import { schema } from './schema'

// Types
import { CompanyClinicShow, CompanyClinicShowRecord } from './types'
import { ClinicPurchase } from 'types/ClinicPurchase'
import { ResponseForDialog } from 'types/ResponseForDialog'
import { useCan } from 'hooks/useCan'
import { RULES } from './rules'

type CompanyClinicFormShowProps = {
  id: string
  endpoint: string
}

export const CompanyClinicFormShow = (
  props: CompanyClinicFormShowProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint, id } = props

  const { record } = useEditRecords<CompanyClinicShow, CompanyClinicShow>({
    endpoint,
    currentId: id,
  })

  const recordCurrent = record.current

  const { statusParser } = useFormTransform()
  const { handleError } = useError()

  const { t } = useTranslation('common')

  const toast = useToast({ position: 'top-right' })

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  const { dialog } = useDialogDelete()

  const { can } = useCan({ rules: RULES })

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [responseForDialog, setResponseForDialog] = useState<
    ResponseForDialog | undefined
  >(undefined)

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const cardCnpjFileName = useMemo(() => {
    const companyHasDocuments = recordCurrent.companyData?.companyHasDocuments

    const cardCnpj = find(companyHasDocuments, [
      'companyDataFile.group',
      'CARD_CNPJ',
    ])

    return cardCnpj?.companyDataFile.originalFileName
  }, [recordCurrent.companyData?.companyHasDocuments])

  const socialContractFileName = useMemo(() => {
    const companyHasDocuments = recordCurrent.companyData?.companyHasDocuments

    return find(companyHasDocuments, [
      'companyDataFile.group',
      'SOCIAL_CONTRACT',
    ])?.companyDataFile.originalFileName
  }, [recordCurrent.companyData?.companyHasDocuments])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    const fetchData = async () => {
      const response = await apiShow<ClinicPurchase[]>(
        `/app/company/${id}/purchase`
      )

      const responseForDialogPurchases = map(response, (purchase) => {
        return {
          href: `/orders/${purchase.id}`,
          label: `#${purchase.purchaseNumber}`,
        }
      })

      setResponseForDialog({
        purchases: responseForDialogPurchases,
      })
    }

    fetchData()
  }, [id])

  useEffect(() => {
    const employees = map(recordCurrent.professionals, (professional) => ({
      value: professional.id,
      label: professional?.person?.name,
    }))

    const clinicType = {
      value: recordCurrent.companyData?.clinicType,
      label: recordCurrent.companyData?.clinicType,
    }

    const companyHasDocuments = get(
      recordCurrent,
      'companyData.companyHasDocuments',
      []
    )

    const companyHasDocument = companyHasDocuments.find(
      (companyHasDocument: any) => companyHasDocument.documentNumber
    )

    const transformData: CompanyClinicShowRecord = {
      employees,
      clinicType,
      name: recordCurrent.companyData?.name,
      number: recordCurrent.companyData?.address.number,
      street: recordCurrent.companyData?.address.street,
      cityId: recordCurrent.companyData?.address.cityId,
      stateId: recordCurrent.companyData?.address.stateId,
      complement: recordCurrent.companyData?.address.complement,
      postalCode: recordCurrent.companyData?.address.postalCode,
      neighborhood: recordCurrent.companyData?.address.neighborhood,
      documentNumber: get(companyHasDocument, 'documentNumber', ''),
      referencePoint: recordCurrent.companyData?.address.referencePoint,
      clinicalSpecialty: recordCurrent.companyData?.clinicalSpecialty,
      status: statusParser(recordCurrent.status),
    }

    methods.reset(transformData)
  }, [methods, recordCurrent, statusParser])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const deleteDialog = useMemo(() => {
    if (!responseForDialog) return

    return dialog({
      title: 'Pedidos',
      items: responseForDialog?.purchases || [],
    })
  }, [dialog, responseForDialog])

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Geral',
        component: TabGeneralShow,
        options: {
          cardCnpjFileName,
          socialContractFileName,
        },
      },
      // {
      //   title: 'Performance',
      //   component: TabPerformance,
      // },
      // {
      //   title: 'Pedidos',
      //   component: TabOrders,
      // },
    ],
    [cardCnpjFileName, socialContractFileName]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDisabled = useCallback(async () => {
    try {
      await record.disable()
      record.reload()
    } catch (error) {
      handleError(error)
    }
  }, [handleError, record])

  const onSubmit: SubmitHandler<CompanyClinicShowRecord> = React.useCallback(
    async (data) => {
      try {
        const formattedData = {
          ...data,
          companyType: 'CLINIC',
        }

        await record.update(formattedData)

        if (data.cardCnpj && data.cardCnpj[0]) {
          await record.upload(
            data.cardCnpj[0],
            `/app/company/${id}/upload/cnpj`,
            {
              group: 'CARD_CNPJ',
            }
          )
        }

        if (data.socialContract && data.socialContract[0]) {
          await record.upload(
            data.socialContract[0],
            `/app/company/${id}/upload/social_contract`,
            { group: 'SOCIAL_CONTRACT' }
          )
        }

        toast({
          title: 'Editado com sucesso',
          description: 'Clinica editada com sucesso',
          status: 'success',
        })
      } catch (error: any) {
        if (error && error instanceof Array) {
          console.log(error)
          error.forEach((fieldError) => {
            if (fieldError.field === 'documentNumber') {
              fieldError.message = 'Verifique se o CNPJ está correto'
            }
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [id, methods, record, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box position="absolute" top="-9.25rem" right="0" zIndex="20">
          <ButtonNewOrder companyClinicId={id} />
        </Box>

        <Box position="absolute" top={-6} right={0}>
          <FieldStatus
            methods={methods}
            error={methods.formState.errors.status}
            disable={record.disable}
            enable={record.enable}
          />
        </Box>

        <Tabs tabsDetails={tabsDetails} />

        {!can.hideFooter && (
          <FormFooter
            titleDialog={deleteDialog?.title}
            bodyDialog={deleteDialog?.body}
            isSubmitting={methods.formState.isSubmitting}
            confirmLabel={deleteDialog?.isDisabled ? 'Desativar' : 'Deletar'}
            recordDelete={
              deleteDialog?.isDisabled ? handleDisabled : record.delete
            }
          />
        )}
      </Flex>
    </FormProvider>
  )
}
