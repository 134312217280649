// Vendors
import { map } from 'lodash'
import { Link, useHistory } from 'react-router-dom'
import React, { useMemo, useCallback, useEffect } from 'react'

// Functions
import { apiShow } from 'services/get'
import { apiPatch } from 'services/patch'
import { formatDate } from 'utils/formatDate'
import { useTranslation } from 'react-i18next'
import { useListRecords } from 'hooks/useListRecords'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListActions } from 'components/ListActions'
import { ListPagination } from 'components/ListPagination'
import { ListTableRenderAs } from 'components/ListTableRowComponent'
import { FieldDateRangePicker } from 'components/FieldDateRangePicker'

// Styles
import { Button, Stack, HStack, Select } from '@chakra-ui/react'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Types
import { Person } from './types'
import { PersonPurchaseAndKit } from 'types/PersonPurchaseAndKit'

// Hooks & Contexts
import { useDialogDelete } from 'hooks/useDialogDelete'
import { useConfirmationDialog } from 'contexts/confirmationDialog'
import { useDownload } from 'hooks/useDownload'
import { useError } from 'hooks/useError'

import { PageMetaSetting } from './meta'

export const PageProspectListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('common')
  const history = useHistory()
  const { endpoint, title } = PageMetaSetting
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<Person>({
    endpoint: { public: endpoint, private: `/app/person` },
  })

  const { onDownload } = useDownload()
  const { handleError } = useError()
  const { dialog } = useDialogDelete()
  const { getConfirmation } = useConfirmationDialog()

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    Records.list.forEach((record) => {
      record.createdAtFormatted = formatDate(record.createdAt, 'dd/MM/yyyy')
      record.birthdateFormatted = formatDate(record.birthdate, 'dd/MM/yyyy')
    })
  }, [Records.list])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleExport = useCallback(async () => {
    try {
      Query.set({
        ...Query.current,
        limit: 99999,
      })

      await onDownload(
        `/app/client/csv?status=PROSPECT`,
        `report.csv`,
        Query.current,
        { handleError: true }
      )
    } catch (error: any) {
      handleError(error)
    }
  }, [handleError, onDownload, Query])

  const handleDelete = useCallback(
    async (e, record: Person) => {
      e.stopPropagation()

      try {
        const response = await apiShow<PersonPurchaseAndKit>(
          `/app/person/${record.id}/purchase_kits`
        )

        const responseForDialogKits = map(response?.kits, (kit) => {
          return {
            href: `/kits/${kit.activationCode}`,
            label: kit.activationCode,
          }
        })

        const responseForDialogPurchases = map(
          response?.purchases,
          (purchase) => {
            return {
              href: `/orders/${purchase.id}`,
              label: `#${purchase.purchaseNumber}`,
            }
          }
        )

        const { title, body, isDisabled } = dialog(
          {
            title: 'Kits',
            items: responseForDialogKits,
          },
          {
            title: 'Pedidos',
            items: responseForDialogPurchases,
          }
        )

        await getConfirmation({
          title: title,
          message: body,
          confirmText: isDisabled ? 'Desabilitar' : 'Deletar',
          onConfirm: async () => {
            try {
              if (isDisabled) {
                await apiPatch(`/app/person/${record.id}/disable`)
                Records.reload()
              } else {
                Records.delete(record.id)
              }
            } catch (error) {
              console.log(error)
            }
          },
        })
      } catch (error) {
        console.log(error)
      }
    },
    [Records, dialog, getConfirmation]
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const ListTableHeaders = useMemo(
    () => [
      {
        label: t('list_table.headers.name'),
        accessor: 'name',
      },
      {
        label: t('list_table.headers.phone'),
        accessor: 'phoneNumber',
      },
      {
        label: t('list_table.headers.email'),
        accessor: 'email',
      },
      {
        label: t('list_table.headers.created_at'),
        accessor: 'createdAtFormatted',
      },
      {
        label: t('list_table.headers.status'),
        accessor: 'status',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
    ],
    [t]
  )

  const ListTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/clients/${Record.id}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutLoggedPageList title={title}>
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ListFilters query={Query}>
          <HStack>
            <FieldDateRangePicker
              isSmall
              name="filterDate"
              placeholderText="Filtrar por data"
              containerProps={{
                marginBottom: '0',
              }}
              showMonthDropdown
              dropdownMode="select"
              query={Query}
            />
            <Select
              size="sm"
              onChange={(e) =>
                Query.set({ ...Query.current, sort: e.target.value })
              }
            >
              <option value="-createdAt">Mais recentes</option>
              <option value="+createdAt">Mais antigos</option>
            </Select>
          </HStack>
        </ListFilters>

        <ListActions>
          <Button colorScheme="gray" onClick={handleExport}>
            EXPORTAR
          </Button>
        </ListActions>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={ListTableRowAction}
        defaultActions="edit"
        actions={[
          {
            type: 'order',
          },
          {
            type: 'resetAccountPassword',
            options: {
              reload: Records.reload,
              isWorkspace: false,
            },
          },
          {
            type: 'delete',
            options: {
              callback: handleDelete,
            },
          },
        ]}
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
