import { ColorModeScript } from '@chakra-ui/react'
import * as React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import App from './App'
import './translations'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    dsn: 'https://86a5d2a020c14c75becd6927f688d925@o1055602.ingest.sentry.io/6156279',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
