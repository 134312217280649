import { useDisclosure } from '@chakra-ui/react'
import { ModalAuthorizeShipment } from 'components/ModalAuthorizeShipment'
import { get } from 'lodash'
import React, { useCallback } from 'react'
import { BsPatchCheck } from 'react-icons/bs'
import { ActionProps } from './types'
import { WrapperAction } from './WrapperAction'

export const AuthorizeShipmentAction = <T extends unknown>(
  props: ActionProps<T>
) => {
  const { options, record } = props
  const authorizeShipmentDisclosure = useDisclosure()

  const { callback, actionType = 'menu', reload, ...optionsRest } = options

  if (!reload) {
    throw new Error('reload is required')
  }

  const cta = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, data: T) => {
      authorizeShipmentDisclosure.onOpen()

      if (callback) {
        callback(e, data)
      }
    },
    [authorizeShipmentDisclosure, callback]
  )

  return (
    <>
      <ModalAuthorizeShipment
        reload={reload}
        isOpen={authorizeShipmentDisclosure.isOpen}
        onCancel={authorizeShipmentDisclosure.onClose}
        purchaseId={get(record, 'id')}
      />

      <WrapperAction
        cta={cta}
        record={record}
        icon={BsPatchCheck}
        options={optionsRest}
        actionType={actionType}
        label="Autorizar expedição"
      />
    </>
  )
}
