import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

export const InfectionsTwo = (
  props: HTMLChakraProps<'svg'> & {
    iconColor?: string
    colorStopInit: string
    colorStopEnd: string
  }
) => {
  const { iconColor, colorStopInit, colorStopEnd, ...rest } = props
  const color = iconColor || 'white'
  return (
    <chakra.svg
      id="Componente_57_1"
      data-name="Componente 57 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="53.73"
      height="53.73"
      viewBox="0 0 53.73 53.73"
      {...rest}
    >
      <svg>
        <path
          id="Caminho_14"
          data-name="Caminho 14"
          d="M26.865,53.73A26.865,26.865,0,1,0,0,26.865,26.865,26.865,0,0,0,26.865,53.73Z"
          fill={colorStopInit}
        />
        <g
          id="Grupo_28"
          data-name="Grupo 28"
          transform="translate(7.046 10.381)"
        >
          <path
            id="Caminho_42521"
            data-name="Caminho 42521"
            d="M161.811,317.415a3.344,3.344,0,1,0-3.344-3.343A3.343,3.343,0,0,0,161.811,317.415Z"
            transform="translate(-158.467 -297.581)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Caminho_42519"
            data-name="Caminho 42519"
            d="M183.724,317.415a3.344,3.344,0,1,0-3.344-3.343A3.343,3.343,0,0,0,183.724,317.415Z"
            transform="translate(-163.905 -297.581)"
            fill={color}
          />
          <path
            id="Caminho_42524"
            data-name="Caminho 42524"
            d="M205.637,317.415a3.344,3.344,0,1,0-3.344-3.343A3.343,3.343,0,0,0,205.637,317.415Z"
            transform="translate(-169.343 -297.581)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Caminho_42520"
            data-name="Caminho 42520"
            d="M172.182,299.928a3.343,3.343,0,1,0-3.344-3.343A3.343,3.343,0,0,0,172.182,299.928Z"
            transform="translate(-161.041 -293.241)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Caminho_42525"
            data-name="Caminho 42525"
            d="M194.1,299.928a3.343,3.343,0,1,0-3.344-3.343A3.343,3.343,0,0,0,194.1,299.928Z"
            transform="translate(-166.476 -293.241)"
            fill={color}
          />
          <path
            id="Caminho_42522"
            data-name="Caminho 42522"
            d="M172.182,334.885a3.344,3.344,0,1,0-3.344-3.343A3.344,3.344,0,0,0,172.182,334.885Z"
            transform="translate(-161.041 -301.916)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Caminho_42523"
            data-name="Caminho 42523"
            d="M194.1,334.885a3.344,3.344,0,1,0-3.344-3.343A3.344,3.344,0,0,0,194.1,334.885Z"
            transform="translate(-166.476 -301.916)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </svg>
    </chakra.svg>
  )
}
