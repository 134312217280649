import { useDisclosure } from '@chakra-ui/react'
import { ModalGenerateNewPaymentLink } from 'components/ModalGenerateNewPaymentLink'
import { get } from 'lodash'
import React, { useCallback } from 'react'
import { VscTag } from 'react-icons/vsc'
import { ActionProps } from './types'
import { WrapperAction } from './WrapperAction'

export const GenerateNewPaymentLinkAction = <T extends unknown>(
  props: ActionProps<T>
) => {
  const { options, record } = props
  const generateNewPaymentLinkDisclosure = useDisclosure()

  const { callback, actionType = 'menu', ...optionsRest } = options

  const cta = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, data: T) => {
      generateNewPaymentLinkDisclosure.onOpen()

      if (callback) {
        callback(e, data)
      }
    },
    [generateNewPaymentLinkDisclosure, callback]
  )

  return (
    <>
      <ModalGenerateNewPaymentLink
        isOpen={generateNewPaymentLinkDisclosure.isOpen}
        onCancel={generateNewPaymentLinkDisclosure.onClose}
        purchaseId={get(record, 'id')}
      />

      <WrapperAction
        cta={cta}
        record={record}
        icon={VscTag}
        options={optionsRest}
        actionType={actionType}
        label="Gerar novo link de pagamento"
      />
    </>
  )
}
