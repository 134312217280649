import { debounce, difference, get } from 'lodash'
import React, { createContext, useCallback, useContext, useState } from 'react'

import api from 'services/api'

import { useDisclosure } from '@chakra-ui/react'

import {
  SearchModule,
  SearchContextValues,
  SearchContextProviderProps,
} from './types'
import {
  FiTag,
  FiBox,
  FiUser,
  FiSmile,
  FiLayers,
  FiBriefcase,
  FiShoppingBag,
  FiShoppingCart,
  FiSearch,
} from 'react-icons/fi'
import { MdOutlineMedicalServices } from 'react-icons/md'
import { RiArticleLine } from 'react-icons/ri'
import { UserRolesNames } from 'types/User'

export const SearchContext = createContext<SearchContextValues>(
  {} as SearchContextValues
)

export const SearchContextProvider = ({
  children,
}: SearchContextProviderProps) => {
  const searchDisclosure = useDisclosure()
  const [expandedIndex, setExpandedIndex] = useState<number[]>([])

  const [searchModules, setSearchModules] = useState<SearchModule[]>([
    {
      name: 'Pedidos',
      icon: FiShoppingBag,
      endpointFront: '/orders',
      endpoint: '/app/expedition/order?sort=-createdAt',
      data: undefined,
      isError: undefined,
      isLoading: undefined,
      allowedRoles: [
        UserRolesNames.ADMIN,
        UserRolesNames.SELLER,
        UserRolesNames.FINANCIAL,
        UserRolesNames.BACKOFFICE,
        UserRolesNames.EXPEDITION,
        UserRolesNames.SUPERADMIN,
        UserRolesNames.LABORATORY_AGENT,
        UserRolesNames.LABORATORY_MANAGER,
      ],
      format(data) {
        return data.map((item: any) => {
          return {
            id: item.id,
            name: '#' + item.purchaseNumber,
            status: item.status || item.purchaseStatus,
          }
        })
      },
    },
    {
      name: 'Kits',
      icon: FiBox,
      endpointFront: '/kits',
      endpoint: '/app/laboratory/kit_list',
      data: undefined,
      isError: undefined,
      isLoading: undefined,
      allowedRoles: [
        UserRolesNames.ADMIN,
        UserRolesNames.BACKOFFICE,
        UserRolesNames.SUPERADMIN,
        UserRolesNames.CONTENT_MANAGER,
      ],
      format(data) {
        return data.map((item: any) => ({
          id: item.id,
          name: get(item, 'activationCode'),
          status: item.status,
          href: get(item, 'activationCode'),
        }))
      },
    },
    {
      name: 'Profissionais',
      icon: FiBriefcase,
      endpointFront: '/professionals',
      endpoint: '/app/professional',
      data: undefined,
      isError: undefined,
      isLoading: undefined,
      allowedRoles: [
        UserRolesNames.ADMIN,
        UserRolesNames.SUPERADMIN,
        UserRolesNames.BACKOFFICE,
      ],
      format(data) {
        return data.map((item: any) => ({
          id: item.id,
          name: item.name,
          status: item.status,
        }))
      },
    },
    {
      name: 'Clínicas',
      icon: MdOutlineMedicalServices,
      endpointFront: '/clinics',
      endpoint: '/app/company?type=clinic',
      data: undefined,
      isError: undefined,
      isLoading: undefined,
      allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.SUPERADMIN],
      format(data) {
        return data.map((item: any) => ({
          id: item.id,
          name: item.internalIdentification,
          status: item.status,
        }))
      },
    },
    {
      name: 'Clientes',
      icon: FiSmile,
      endpointFront: '/clients',
      endpoint: '/app/client',
      data: undefined,
      isError: undefined,
      isLoading: undefined,
      allowedRoles: [
        UserRolesNames.ADMIN,
        UserRolesNames.SUPERADMIN,
        UserRolesNames.BACKOFFICE,
      ],
      format(data) {
        return data.map((item: any) => ({
          id: item.id,
          name: item.name,
          status: item.status,
        }))
      },
    },
    {
      name: 'Prospects',
      icon: FiSearch,
      endpointFront: '/prospects',
      endpoint: '/app/client',
      data: undefined,
      isError: undefined,
      isLoading: undefined,
      allowedRoles: [
        UserRolesNames.ADMIN,
        UserRolesNames.SUPERADMIN,
        UserRolesNames.BACKOFFICE,
      ],
      format(data) {
        return data.map((item: any) => ({
          id: item.id,
          name: item.name,
          status: item.status,
        }))
      },
    },
    {
      name: 'Usuários',
      icon: FiUser,
      endpointFront: '/users',
      endpoint: '/app/person',
      data: undefined,
      isError: undefined,
      isLoading: undefined,
      allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.SUPERADMIN],
      format(data) {
        return data.map((item: any) => ({
          id: item.id,
          name: item.name,
          status: item.status,
        }))
      },
    },
    {
      name: 'Categorias',
      icon: FiLayers,
      endpointFront: '/category',
      endpoint: '/public/store_product_category',
      data: undefined,
      isError: undefined,
      isLoading: undefined,
      allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.SUPERADMIN],
      format(data) {
        return data.map((item: any) => ({
          id: item.id,
          name: item.title,
          status: item.status,
        }))
      },
    },
    {
      name: 'Produtos',
      icon: FiShoppingCart,
      endpointFront: '/products',
      endpoint: '/public/store_product',
      data: undefined,
      isError: undefined,
      isLoading: undefined,
      allowedRoles: [
        UserRolesNames.ADMIN,
        UserRolesNames.SELLER,
        UserRolesNames.SUPERADMIN,
      ],
      format(data) {
        return data.map((item: any) => ({
          id: item.id,
          name: item.name,
          status: item.status,
        }))
      },
    },
    {
      name: 'Coupons',
      icon: FiTag,
      endpointFront: '/coupons',
      endpoint: '/app/coupon',
      data: undefined,
      isError: undefined,
      isLoading: undefined,
      allowedRoles: [
        UserRolesNames.ADMIN,
        UserRolesNames.SELLER,
        UserRolesNames.FINANCIAL,
        UserRolesNames.BACKOFFICE,
        UserRolesNames.SUPERADMIN,
      ],
      format(data) {
        return data.map((item: any) => ({
          id: item.id,
          name: item.name,
          status: item.status,
        }))
      },
    },
    {
      name: 'Blog',
      icon: RiArticleLine,
      endpointFront: '/blog',
      endpoint: '/public/blog',
      data: undefined,
      isError: undefined,
      isLoading: undefined,
      allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.SUPERADMIN],
      format(data) {
        return data.map((item: any) => ({
          id: item.id,
          name: item.title,
          status: item.status,
        }))
      },
    },
  ])

  const [content, setContent] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleContent = useCallback(
    debounce((value: string) => {
      setContent(value)
      setExpandedIndex([])
    }, 500),
    [content]
  )

  const handleExpand = useCallback(
    async (expandIndex: number[]) => {
      const filterExpandIndex = difference(expandIndex, expandedIndex)[0]
      setExpandedIndex(expandIndex)

      const searchModule = searchModules[filterExpandIndex]

      if (!searchModule) return

      if (searchModule.isLoading) return

      const isLoadingSearchModules = searchModules.map(
        (searchModule, index) => {
          if (index === filterExpandIndex) {
            return {
              ...searchModule,
              data: undefined,
              isLoading: true,
            }
          }

          return searchModule
        }
      )

      setSearchModules(isLoadingSearchModules)

      try {
        const response = await api.get(searchModule.endpoint, {
          params: {
            q: content,
            limit: 9999,
          },
        })
        const newSearchModules = searchModules.map((searchModule, index) => {
          if (index === filterExpandIndex) {
            return {
              ...searchModule,
              data: searchModule.format(response.data.data),
              isLoading: false,
            }
          }

          return searchModule
        })

        setSearchModules(newSearchModules)
      } catch (error) {
        const newSearchModules = searchModules.map((searchModule, index) => {
          if (index === filterExpandIndex) {
            return {
              ...searchModule,
              isError: true,
              isLoading: false,
            }
          }

          return searchModule
        })

        setSearchModules(newSearchModules)
      }
    },
    [content, expandedIndex, searchModules]
  )

  const handleOpenSearch = useCallback(() => {
    searchDisclosure.onOpen()
  }, [searchDisclosure])

  return (
    <SearchContext.Provider
      value={{
        content,
        handleExpand,
        expandedIndex,
        handleContent,
        searchModules,
        searchDisclosure,
        handleOpenSearch,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export const useSearch = () => {
  const context = useContext(SearchContext)

  if (!context) {
    throw new Error('useSearch must be used within a SearchContextProvider')
  }

  return context
}
