import React from 'react'
import { Box, Divider, Flex, useColorModeValue } from '@chakra-ui/react'
import Sidebar from 'components/Sidebar'
import { MobileTopBar } from 'components/Sidebar/MobileTopBar'
import { NavSecondary } from 'components/NavSecondary'
import PageHeader from 'components/PageHeader'
import { GlobalSearch } from 'components/GlobalSearch'

type LayoutProps = {
  children: React.ReactNode
  title: string
}

const Layout = ({ children, title }: LayoutProps): JSX.Element => {
  const ref = React.useRef<HTMLDivElement>(null)
  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Box ref={ref} height="100vh" overflow="hidden" position="relative">
      <Flex h="full" flexDirection="column">
        <MobileTopBar />

        <Flex flex="1" overflow="hidden">
          <Sidebar display={{ base: 'none', md: 'flex' }} />

          <Flex flex="1" p="0" overflow="auto">
            <Box
              transition="all 1s ease-in-out"
              bg={useColorModeValue('white', 'gray.800')}
              h="full"
              w="full"
            >
              <NavSecondary />

              <Box p="6">
                <PageHeader title={title} />
                <Divider mb="4" />
                {children}
              </Box>
            </Box>
          </Flex>

          <GlobalSearch />
        </Flex>
      </Flex>
    </Box>
  )
}

export default Layout
