import React from 'react'

import { FormModal } from 'components/FormModal'

import { FiX } from 'react-icons/fi'
import { Flex, Text, Stack, Button, Circle, Heading } from '@chakra-ui/react'

type ModalErrorPurchaseProps = {
  onClose: () => void
  isOpen: boolean
  reason?: string
}

export const ModalErrorPurchase = (props: ModalErrorPurchaseProps) => {
  const { isOpen, onClose, reason } = props

  return (
    <FormModal title="" isOpen={isOpen} onClose={onClose}>
      <Flex justifyContent="center" flexDirection="column" px="16" pb="12">
        <Stack alignItems="center">
          <Circle size="12" bg="red.300" mb="1.5rem !important">
            <FiX size="24" color="white" />
          </Circle>

          <Heading fontWeight="medium" fontSize="lg" color="gray.900">
            O pedido não foi realizado!
          </Heading>

          <Text fontSize="sm" color="gray.500" textAlign="center" w="80">
            {reason || 'MOTIVO DA FALHA NÃO INFORMADO'}
          </Text>
        </Stack>

        <Button colorScheme="blue" mt="14" onClick={onClose}>
          OK
        </Button>
      </Flex>
    </FormModal>
  )
}
