// Vendors
import { filter, find, get } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { Controller, FieldError, useFormContext } from 'react-hook-form'

// Functions
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FieldSet } from 'components/FieldSet'
import { FieldSelect } from 'components/FieldSelect'
import { FieldTextArea } from 'components/FieldTextArea'
import { FormCompanyInfo } from 'pages/private/Company/form/FormCompanyInfo'
import { FormCompanyAddress } from 'pages/private/Company/form/FormCompanyAddress'

// Styles
import { Box, Grid, GridItem } from '@chakra-ui/react'

// Types
import { FormOtherInfoType } from '../show/types'
import { State } from 'types/State'

export const TabGeneralShow = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    watch,
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<FormOtherInfoType>()

  const valueStates = getValues('states')
  const valueEmployees = getValues('employees')
  const valueLaboratoryAnalyses = getValues('laboratoryAnalyses')

  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectStates = useSelectParser<State>({
    endpoint: '/app/state',
    accessor: { label: 'abbreviatedName', value: 'id' },
    requestWithoutMeta: true,
  })

  const selectUsersAgent = useSelectParser({
    endpoint: '/app/person?type=laboratory-agent',
    accessor: { label: 'name', value: 'userId' },
  })

  const selectUsersManager = useSelectParser({
    endpoint: '/app/person?type=laboratory-manager',
    accessor: { label: 'name', value: 'userId' },
  })

  const selectLaboratoryAnalyses = useSelectParser({
    endpoint: '/app/laboratory_analysis',
    accessor: { label: 'name', value: 'id' },
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const setMultiSelect = useCallback(
    (key, value, options) => {
      if (!value) return

      const selectFilter = filter(options, (item) =>
        find(value, { id: item.value })
      )

      if (!selectFilter.length) return
      setValue(key, selectFilter)
    },
    [setValue]
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    setMultiSelect('states', valueStates, selectStates.options)
  }, [setMultiSelect, selectStates.options, valueStates])
  useEffect(() => {
    setMultiSelect('employees', valueEmployees, selectUsersAgent.options)
    setMultiSelect('employees', valueEmployees, selectUsersManager.options)
  }, [
    setMultiSelect,
    valueEmployees,
    selectUsersAgent.options,
    selectUsersManager.options,
  ])
  useEffect(() => {
    setMultiSelect(
      'laboratoryAnalyses',
      valueLaboratoryAnalyses,
      selectLaboratoryAnalyses.options
    )
  }, [
    selectLaboratoryAnalyses.options,
    valueLaboratoryAnalyses,
    setMultiSelect,
  ])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <FormCompanyInfo />
      <FormCompanyAddress />

      <FieldSet title="Outras informações">
        <Grid my="4" gap="10" templateColumns={{ lg: 'repeat(3, 1fr)' }}>
          <GridItem>
            <Controller
              name="states"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label="Estados atendidos"
                  error={errors.states as FieldError}
                  isLoading={selectStates.isLoading}
                  options={selectStates.options}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="employees"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label="Usuários vinculados"
                  error={errors.employees as FieldError}
                  isLoading={
                    selectUsersAgent.isLoading || selectUsersManager.isLoading
                  }
                  options={[
                    ...selectUsersAgent.options,
                    ...selectUsersManager.options,
                  ]}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="laboratoryAnalyses"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label="Análises laboratoriais"
                  error={errors.laboratoryAnalyses as FieldError}
                  isLoading={selectLaboratoryAnalyses.isLoading}
                  options={selectLaboratoryAnalyses.options}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldTextArea
              maxLength={255}
              currentLength={get(watch('additionalInformations'), 'length')}
              label="Informações complementares"
              error={errors.additionalInformations}
              {...register('additionalInformations')}
            />
          </GridItem>
        </Grid>
      </FieldSet>
    </Box>
  )
}
