// Vendors
import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'

// Functions
import { apiPost } from 'services/post'
import { useQuery } from 'hooks/useQuery'
import { useCreateRecord } from 'hooks/useCreateRecord'

// Components
import { TabSeo } from 'components/TabSeo'
import { TabSales } from './tabs/TabSales'
import { TabGeneral } from './tabs/TabGeneral'
import { TabDetail, Tabs } from 'components/Tabs'
import { FormFooter } from 'components/FormFooter'
import { TabPromotional } from './tabs/TabPromotional'
import { TabLaboratorialAnalysis } from './tabs/TabLaboratorialAnalysis'

// Schemas
import { schema } from './schema'

// Styles
import { Flex, Box, useToast } from '@chakra-ui/react'

// Types
import { ProductNewForm, ProductNewRecord } from './types'
import { apiShow } from 'services/get'
import { filter, get, map } from 'lodash'

type ProductFormNewProps = {
  endpoint: string
}

export const ProductFormNew = (props: ProductFormNewProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props

  const { t } = useTranslation('products')

  const history = useHistory()

  const toast = useToast({ position: 'top-right' })

  const methods = useForm<ProductNewForm>({ resolver: yupResolver(schema(t)) })
  const { create } = useCreateRecord({ endpoint })

  const query = useQuery()

  /*
  |-----------------------------------------------------------------------------
  | Set Default Values
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sanitize = useCallback((response: any) => {
    const storeProductHasLaboratoryAnalysisIds = map(
      response.storeProductHasLaboratoryAnalysis,
      'id'
    )

    const faq = response.faq || []

    const attributes = response.attributes || []

    const testfyBaseIds = map(
      filter(
        response.testfyBases,
        (testfyBase) => testfyBase.status === 'ENABLED'
      ),
      'id'
    )

    const storeProductCategoryIds = map(response.categories, 'id')

    const isServiceIncluded = String(response.isServiceIncluded)

    const seoKeywords = response.keywords?.map(
      (keyword: { id: string; name: string }) => ({
        value: keyword.id,
        label: keyword.name,
      })
    )

    return {
      // General
      testfyBaseIds,
      storeProductCategoryIds,
      name: response.name,
      storeProductCode: response.storeProductCode,
      sampleTransportKitId: response.sampleTransportKitId,
      // Sales
      priceB2c: response.priceB2c,
      priceB2b: response.priceB2b,
      isB2bAvailable: response.isB2bAvailable,
      isB2cAvailable: response.isB2cAvailable,
      isSellerAvailable: response.isSellerAvailable,
      progressiveDiscount: response.progressiveDiscounts,
      isEligibleB2bDiscount: response.isEligibleB2bDiscount,
      // Laboratorial Analysis
      isServiceIncluded,
      storeProductHasLaboratoryAnalysisIds,
      // Marketing
      faq,
      attributes,
      mktTitle: response.mktTitle,
      accentColor: response.accentColor,
      mktAbstract: response.mktAbstract,
      mktDescription: response.mktDescription,
      reportOverviewFile: response.reportOverviewFile,
      reportOverviewTitle: response.reportOverviewTitle,
      mktCompactDescription: response.mktCompactDescription || '',
      reportOverviewAbstract: response.reportOverviewAbstract,
      // SEO
      seoKeywords,
      slug: response.slug,
      seoTitle: response.seoTitle,
      seoDescription: response.seoDescription,
      seoAdditionalKeywords: response.seoAdditionalKeywords,
    }
  }, [])

  useEffect(() => {
    const duplicateFrom = query.get('duplicate_from')

    if (!duplicateFrom) return

    apiShow(`${endpoint}/${duplicateFrom}`).then((response) => {
      if (!response) return

      const sanitized = sanitize(response)

      methods.reset(sanitized)
    })
  }, [endpoint, methods, query, sanitize])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: t('tabs.tabGeneral'),
        component: TabGeneral,
        names: [
          'name',
          'testfyBaseIds',
          'storeProductCode',
          'sampleTransportKitId',
          'storeProductCategoryId',
        ],
      },
      {
        title: t('tabs.sales'),
        component: TabSales,
        names: [
          'priceB2c',
          'priceB2b',
          'progressiveDiscount',
          'isEligibleB2bDiscount',
        ],
      },
      {
        title: t('tabs.laboratorialAnalysis'),
        component: TabLaboratorialAnalysis,
        names: ['isServiceIncluded', 'storeProductHasLaboratoryAnalysisIds'],
      },
      {
        title: t('tabs.marketing'),
        component: TabPromotional,
        names: [
          'faq',
          'mktTitle',
          'attributes',
          'mktAbstract',
          'accentColor',
          'mktDescription',
          'reportOverviewPDF',
          'reportOverviewTitle',
          'reportOverviewAbstract',
        ],
      },
      {
        title: 'SEO',
        component: TabSeo,
        names: [
          'slug',
          'seoTitle',
          'seoKeywords',
          'seoDescription',
          'seoAdditionalKeywords',
        ],
      },
    ],
    [t]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<ProductNewRecord> = React.useCallback(
    async (data) => {
      try {
        const response = await create(data)

        if (!response) return

        const reportOverviewPDF = get(data, 'reportOverviewFile')

        if (!reportOverviewPDF || !(reportOverviewPDF instanceof File)) {
          toast({
            title: t('toast.success.title', {
              type: t('common:toast.success.create'),
            }),
            status: 'success',
          })
          history.push(`/products/${response.id}`)
          return
        }

        const reportOverviewFormData = new FormData()
        reportOverviewFormData.append('file', reportOverviewPDF)

        apiPost(
          `/app/store_product/${response.id}/upload_report`,
          reportOverviewFormData
        )
          .then(() => {
            toast({
              title: t('toast.success.title', {
                type: t('common:toast.success.create'),
              }),
              status: 'success',
            })

            history.push(`/products/${response.id}`)
          })
          .catch(() => {
            throw new Error('Erro ao enviar arquivo')
          })
      } catch (error) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [create, history, methods, t, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box>
          <Tabs tabsDetails={tabsDetails} errors={methods.formState.errors} />
        </Box>

        <FormFooter isSubmitting={methods.formState.isSubmitting} hasAlert />
      </Flex>
    </FormProvider>
  )
}
