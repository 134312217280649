// Vendors
import React from 'react'
import { useFormContext } from 'react-hook-form'

// Components
import { FieldCheckbox } from 'components/FieldCheckbox'

// Styles
import { Box } from '@chakra-ui/react'

// Hooks
import { FormFieldEditorProps } from '../MedicalRecord'
import { useFormFieldEditor } from '../useFormFieldEditor'

// Types

export const FormFieldEditorMultipleChoice = (props: FormFieldEditorProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { question } = props
  const { control } = useFormContext()

  const {
    rules,
    label,
    options: { checkboxOptions },
  } = useFormFieldEditor({ question })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <FieldCheckbox
        options={checkboxOptions}
        control={control}
        label={label}
        rules={rules}
      />
    </Box>
  )
}
