import { useCallback } from 'react'
import api from 'services/api'
import { Query } from 'types/Requests'

export const useDownload = () => {
  const onDownload = useCallback(
    async (
      url: string,
      filename: string,
      query?: Query,
      config?: {
        handleError: boolean
      }
    ) => {
      try {
        const response = await api({
          url,
          method: 'GET',
          responseType: 'blob',
          params: { ...query },
        })
        const urlFile = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = urlFile

        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        link.parentNode.removeChild(link)
      } catch (error: any) {
        if (!config?.handleError) {
          throw error
        }

        console.error(error)
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          'Erro ao baixar arquivo'

        throw new Error(errorMessage)
      }
    },
    []
  )

  return {
    onDownload,
  }
}
