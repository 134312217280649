import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import { yupSchema } from 'utils/yupSchema'
import { FILE_SIZE } from 'constants/constants'

export const schema = (t: TFunction<'laboratory'>) => {
  yupSchema(t)

  return yup.object().shape({
    laboratoryAnalysisIds: yup
      .array()
      .selectValues()
      .min(1)
      .required()
      .label(t('drawerUpload.form.analysisLaboratorialIds.label')),

    title: yup.string().required().label(t('drawerUpload.form.fileName.label')),

    comment: yup
      .string()
      .required()
      .label(t('drawerUpload.form.internalComment.label')),

    file: yup
      .mixed()
      .maxFileSize(FILE_SIZE)
      .fileIsRequired(t('drawerUpload.form.uploadReport.label')),
  })
}
