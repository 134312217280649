import React from 'react'
import { Skeleton, Stack } from '@chakra-ui/react'

export const GlobalSearchSkeleton = () => {
  return (
    <Stack flex="1">
      <Skeleton h="40px" />
      <Skeleton h="40px" />
    </Stack>
  )
}
