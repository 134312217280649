// Vendors
import React from 'react'
import { useFormContext } from 'react-hook-form'

// Components
import { Grid, GridItem } from '@chakra-ui/react'
import { FieldTextArea } from 'components/FieldTextArea'
import { FieldText } from 'components/FieldText'
import { get } from 'lodash'

// Types

type TabGeneralProps = {
  endpoint: string
}

// Styles

export const TabGeneral = (props: TabGeneralProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    watch,
    register,
    formState: { errors, isSubmitting },
  } = useFormContext()

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Grid
      gap="10"
      my="4"
      templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
    >
      <GridItem colSpan={2}>
        <FieldText
          maxLength={45}
          currentLength={get(watch('title'), 'length')}
          label="Título"
          error={errors.title}
          isRequired
          {...register('title')}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <FieldTextArea
          label="Descrição"
          maxLength={280}
          currentLength={get(watch('description'), 'length')}
          error={errors.description}
          isRequired
          {...register('description')}
        />
      </GridItem>
    </Grid>
  )
}
