import React from 'react'

import { chakra, HTMLChakraProps } from '@chakra-ui/react'

export type IconProps = HTMLChakraProps<'svg'>

export const Archive = (props: IconProps) => {
  return (
    <chakra.svg
      width="14px"
      height="14px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.9 7.2a.3.3 0 0 0 0 .6h1.8a.3.3 0 0 0 0-.6H5.9ZM2 4.05C2 3.47 2.47 3 3.05 3h7.5c.58 0 1.05.47 1.05 1.05v.9c0 .419-.245.78-.6.95v3.7a1.8 1.8 0 0 1-1.8 1.8H4.4a1.8 1.8 0 0 1-1.8-1.8V5.9a1.05 1.05 0 0 1-.6-.95v-.9Zm1.05-.45a.45.45 0 0 0-.45.45v.9c0 .248.202.45.45.45h7.5a.45.45 0 0 0 .45-.45v-.9a.45.45 0 0 0-.45-.45h-7.5ZM3.2 6v3.6a1.2 1.2 0 0 0 1.2 1.2h4.8a1.2 1.2 0 0 0 1.2-1.2V6H3.2Z"
        fill="#474747"
      />
    </chakra.svg>
  )
}
