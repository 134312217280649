import { get, map } from 'lodash'
import React, { useCallback, useMemo } from 'react'

import { ModalRecollectProcessing } from 'components/ModalRecollectProcessing'

import { useDisclosure } from '@chakra-ui/react'

import { ActionProps } from './types'
import { WrapperAction } from './WrapperAction'
import { MdOutlinePlayCircleOutline } from 'react-icons/md'

type ShippingAddressType = {
  id: string
  street: string
  number: string
  cityId: string
  stateId: string
  createdAt: string
  updatedAt: string
  complement: string
  postalCode: string
  neighborhood: string
  referencePoint: string
  city: {
    id: string
    name: string
    stateAbbreviatedName: string
  }
}

export type PurchaseDetailsType = {
  clientId: string
  sellerId: string
  isCobranding: boolean
  recollectCode: string
  documentNumber: string
  companyClinicId: string
  purchaseCategoriesIds: string[]
  shippingAddress: ShippingAddressType
  storeProduct: {
    id: string
    name: string
    mktTitle: string
    mktDescription: string
  }
}

export const RecollectProcessingAction = <T extends unknown>({
  record,
  options,
}: ActionProps<T>) => {
  const modalRecollectProcessingDisclosure = useDisclosure()
  const { callback, actionType = 'icon', reload, ...optionsRest } = options

  if (!reload) {
    throw new Error('reload is required')
  }

  const purchaseDetails: PurchaseDetailsType | undefined = useMemo(() => {
    if (!record) return

    const documentNumber =
      get(record, 'purchaseDocumentNumber') ||
      get(record, 'buyerDocumentNumber')

    return {
      documentNumber,
      recollectCode: get(record, 'recollectCode'),
      clientId: get(record, 'originPurchase.userId'),
      sellerId: get(record, 'originPurchase.sellerId'),
      isCobranding: get(record, 'originPurchase.isCobranding'),
      storeProduct: get(record, 'purchaseHasProduct.storeProduct'),
      shippingAddress: get(record, 'originPurchase.shippingAddress'),
      companyClinicId: get(record, 'originPurchase.companyClinicId'),
      historyUpdate: get(record, 'purchaseHasProduct.historyUpdates.0'),
      purchaseCategoriesIds: map(
        get(record, 'originPurchase.purchaseCategories'),
        'id'
      ),
    }
  }, [record])

  const cta = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, data: T) => {
      modalRecollectProcessingDisclosure.onOpen()

      if (callback) {
        callback(e, data)
      }
    },
    [modalRecollectProcessingDisclosure, callback]
  )

  return (
    <>
      <ModalRecollectProcessing
        reload={reload}
        purchaseDetails={purchaseDetails}
        buyerName={get(record, 'buyerName')}
        isOpen={modalRecollectProcessingDisclosure.isOpen}
        onCancel={modalRecollectProcessingDisclosure.onClose}
      />

      <WrapperAction
        cta={cta}
        record={record}
        options={optionsRest}
        actionType={actionType}
        label="Processar recoleta"
        icon={MdOutlinePlayCircleOutline}
      />
    </>
  )
}
