// Vendors
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'

// Components
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useError } from 'hooks/useError'
import { apiPatch } from 'services/patch'

type ModalAssingIdProps = {
  isOpen: boolean
  reload: () => void
  onCancel: () => void
  activationCode: string
}

type FormActiveKit = {
  comment: string
}

export const ModalActiveKit = (props: ModalAssingIdProps) => {
  const { isOpen, reload, onCancel, activationCode } = props

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormActiveKit>()

  const { handleError } = useError()

  const onClose = useCallback(() => {
    reset()
    onCancel()
  }, [onCancel, reset])

  const onSubmit = useCallback(
    async (data: FormActiveKit) => {
      try {
        const { comment } = data

        await apiPatch(`/app/kit/${activationCode}/status_kit`, {
          comment,
          status: 'ACTIVE',
        })

        reload()
        onClose()
      } catch (error) {
        handleError(error)
      }
    },
    [activationCode, handleError, onClose, reload]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Atenção</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text color="gray.500" mb="4">
            Use esta ferramenta apenas para kits que já foram ativados
            anteriormente, caso contrário a ficha clínica ficará incompleta. Ao
            completar a operação, o kit ficará com o status ATIVO.
          </Text>

          <Stack>
            <FieldTextArea
              label="Justificativa"
              error={errors.comment}
              placeholder="Digite uma justificativa"
              {...register('comment', {
                required: 'Por favor, preencha o campo de justificativa',
              })}
            />
          </Stack>

          <Text fontSize="sm" color="orange.500" mt="4" fontStyle="italic">
            Ativar kits manualmente por esta ferramenta pode causar erros de
            processo, use com cautela.
          </Text>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose}>Cancelar</Button>
            <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
