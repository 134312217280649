import React from 'react'

import { chakra, HTMLChakraProps } from '@chakra-ui/react'

export type IconProps = HTMLChakraProps<'svg'>

export const ThreeDotsVertical = (props: IconProps) => {
  return (
    <chakra.svg
      width="14px"
      height="15px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.415 2.833a.583.583 0 1 0 1.167 0 .583.583 0 0 0-1.167 0ZM6.415 7.5a.583.583 0 1 0 1.167 0 .583.583 0 0 0-1.167 0ZM6.415 12.167a.583.583 0 1 0 1.167 0 .583.583 0 0 0-1.167 0Z"
        fill="#1E1F1F"
        stroke="#1E1F1F"
        strokeWidth={2}
      />
    </chakra.svg>
  )
}
