// Vendors
import React from 'react'
import QrReader from 'react-qr-reader'

// Styles
import { Box } from '@chakra-ui/react'

// Interfaces
export type QrCodeScannerProps = {
  onScan: (data: string) => Promise<void>
  height?: number
  width?: number
  delay?: number
}

export const QrCodeScanner = (props: QrCodeScannerProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { onScan, height, width, delay } = props

  const previewStyle = {
    height: height || 240,
    width: width || 320,
  }

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleScan = async (data: string | null) => {
    if (!data) return

    await onScan(data)
  }

  const handleError = (err: any) => {
    console.log(err)
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Box>
      <QrReader
        delay={delay || 1000}
        style={previewStyle}
        onError={handleError}
        onScan={handleScan}
      />
    </Box>
  )
}
