// Vendors
import React from 'react'

// Components
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

// Styles
import {
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'

// Enums
enum EAction {
  ACTION1,
  ACTION2,
  ACTION3,
}

// Interfaces

export interface ISeries {
  name: string
  data: number[]
}
export interface ICardStat {
  action?: EAction
  label: string
  value: number
  icon?: string
  unit?: string
  options?: ApexOptions
  series?: ISeries[]
}

const DEFAULT_OPTIONS: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      opacityFrom: 0.4,
      opacityTo: 0.1,
    },
  },
  tooltip: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 0,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    crosshairs: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    labels: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  grid: {
    show: false,
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
}

export const CardStat: React.FC<ICardStat> = (props) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { action, value, label, options, series, icon, unit } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Box
      bg={'white'}
      shadow="base"
      rounded="lg"
      // maxW={360}
      // width={360}
      maxH={160}
      height={160}
      position="relative"
    >
      <Box px="6" py="4" zIndex={10}>
        <Flex justify="space-between" align="center">
          <Heading
            as="h4"
            size="lg"
            my="3"
            fontWeight="extrabold"
            color={'blue.500'}
          >
            {value}
          </Heading>
          <Box color={'gray.400'}>{action}</Box>
        </Flex>

        <Flex justify="space-between" align="center">
          <Text fontWeight="medium" color={'gray.600'}>
            {label}
          </Text>
          <Text fontWeight="medium" color="gray.600">
            {unit}
          </Text>
        </Flex>
      </Box>

      <Box
        position="absolute"
        maxH={160}
        height={160}
        bottom={0}
        left={0}
        right={0}
      >
        <Box zIndex={1} position="relative" bottom={'-15px'}>
          <ReactApexChart
            series={series}
            height="160"
            type="area"
            options={{ ...DEFAULT_OPTIONS, ...options }}
          />
        </Box>
      </Box>
    </Box>
  )
}
