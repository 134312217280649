// Vendors
import React, { useEffect, useState } from 'react'
import { Controller, FieldError, useFormContext } from 'react-hook-form'

// Functions
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FieldSet } from 'components/FieldSet'
import { FieldSelect } from 'components/FieldSelect'
import { FieldTextArea } from 'components/FieldTextArea'
import { FormCompanyInfo } from 'pages/private/Company/form/FormCompanyInfo'
import { FormCompanyAddress } from 'pages/private/Company/form/FormCompanyAddress'

// Styles
import { Box, Grid, GridItem } from '@chakra-ui/react'

// Types
import { FormOtherInfoType } from '../new/types'
import { get } from 'lodash'

export const TabGeneralNew = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext<FormOtherInfoType>()

  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectStates = useSelectParser({
    endpoint: '/app/state',
    accessor: { label: 'abbreviatedName', value: 'id' },
    requestWithoutMeta: true,
  })

  const selectUsersAgent = useSelectParser({
    endpoint: '/app/person?type=laboratory-agent',
    accessor: { label: 'name', value: 'userId' },
  })

  const selectUsersManager = useSelectParser({
    endpoint: '/app/person?type=laboratory-manager',
    accessor: { label: 'name', value: 'userId' },
  })

  const selectLaboratoryAnalyses = useSelectParser({
    endpoint: '/app/laboratory_analysis',
    accessor: { label: 'name', value: 'id' },
  })

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [selectUsers, setSelectUsers] = useState({
    options: [{}],
    isLoading: false,
  })
  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    setSelectUsers({
      options: selectUsersAgent.options.concat(selectUsersManager.options),
      isLoading: selectUsersAgent.isLoading && selectUsersManager.isLoading,
    })
  }, [
    selectUsersAgent.isLoading,
    selectUsersManager.isLoading,
    selectUsersManager.options,
    selectUsersAgent.options,
  ])
  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <FormCompanyInfo />
      <FormCompanyAddress />

      <FieldSet title="Outras informações">
        <Grid my="4" gap="10" templateColumns={{ lg: 'repeat(3, 1fr)' }}>
          <GridItem>
            <Controller
              name="states"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label="Estados atendidos"
                  error={errors.states as FieldError}
                  isLoading={selectStates.isLoading}
                  options={selectStates.options}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="employees"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label="Usuários vinculados"
                  error={errors.employees as FieldError}
                  isLoading={selectUsers.isLoading}
                  options={selectUsers.options}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="laboratoryAnalyses"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label="Análises laboratoriais"
                  error={errors.laboratoryAnalyses as FieldError}
                  isLoading={selectLaboratoryAnalyses.isLoading}
                  options={selectLaboratoryAnalyses.options}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldTextArea
              maxLength={255}
              currentLength={get(watch('additionalInformations'), 'length')}
              label="Informações complementares"
              error={errors.additionalInformations}
              {...register('additionalInformations')}
            />
          </GridItem>
        </Grid>
      </FieldSet>
    </Box>
  )
}
