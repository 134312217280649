// Vendors
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

// Contexts
import { useCart } from 'contexts/cart'

// Components
import { Coupon } from './components/Coupon'
import { Products } from './components/Products'
import { FormFooter } from 'components/FormFooter'
import { ClientData } from './components/ClientData'
import { ClinicData } from './components/ClinicData'
import { BillingData } from './components/BillingData'
import { ReviewOrder } from './components/ReviewOrder'
import { FinalValues } from './components/FinalValues'
import { PurchaseData } from './components/PurchaseData'
import { ShippingData } from './components/ShippingData'
import { PaymentAndShipping } from './components/PaymentAndShipping'
import { ModalErrorPurchase } from './components/Modals/ModalErrorPurchase'
import { ModalSuccessSubsidized } from './components/Modals/ModalSuccessSubsidized'
import { ModalSuccessPaymentLink } from './components/Modals/ModalSuccessPaymentLink'
import { ModalSuccessLittleMachine } from './components/Modals/ModalSuccessLittleMachine'

// Styles
import { Divider, Stack, useDisclosure } from '@chakra-ui/react'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Schema
import { schema } from './schema'
import { FormCheckout, FormCheckoutSubmit } from './types'
import { useHistory } from 'react-router-dom'
import { useError } from 'hooks/useError'
import { useQuery } from 'hooks/useQuery'
import { apiShow } from 'services/get'
import { CompanyShow } from 'types/Company'

// Types
enum PAYMENT_METHODS_NAMES {
  'Máquina de Cartão',
  'Link de Pagamento',
  'Bonificado',
}

export const PageCheckout = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('checkout')

  const { handleError } = useError()
  const { makeOrder } = useCart()

  const query = useQuery()
  const history = useHistory()

  const disclosureSubsidized = useDisclosure({
    onClose(): void {
      history.push('/clients')
    },
  })
  const disclosurePaymentLink = useDisclosure({
    onClose(): void {
      history.push('/clients')
    },
  })
  const disclosureLittleMachine = useDisclosure({
    onClose(): void {
      history.push('/clients')
    },
  })
  const disclosureErrorPurchase = useDisclosure()

  const methods = useForm<FormCheckout>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      purchaseProducts: [
        {
          unitPrice: 0,
          isCustomPrice: false,
          quantity: 1,
          subtotal: 0,
          totalDiscount: 0,
          totalPrice: 0,
        },
      ],
    },
  })

  const watchSameBillingAddress = methods.watch('sameBillingAddress')

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [paymentLink, setPaymentLink] = useState('')
  const [errors, setErrors] = useState<string | undefined>(undefined)

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const modals = useMemo(() => {
    return {
      Bonificado: disclosureSubsidized.onOpen,
      'Máquina de Cartão': disclosureLittleMachine.onOpen,
      'Link de Pagamento': disclosurePaymentLink.onOpen,
    }
  }, [
    disclosureLittleMachine.onOpen,
    disclosurePaymentLink.onOpen,
    disclosureSubsidized.onOpen,
  ])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    const companyClinicId = query.get('companyClinicId')

    if (!companyClinicId) return

    apiShow<CompanyShow>(`/app/company/${companyClinicId}`).then((response) => {
      if (!response) return

      methods.setValue('name', response.internalIdentification)
      methods.setValue(
        'documentNumber',
        response.companyData?.companyHasDocuments[0].documentNumber
      )

      const clinicType = response.companyData.clinicType
      if (clinicType === 'Cobranding') {
        methods.setValue('isCobranding', true)
        methods.setValue('purchaseType', 'B2B')
      }

      if (clinicType === 'B2B' || clinicType === 'B2C') {
        methods.setValue('purchaseType', clinicType)
      }

      const address = response.companyData.address

      methods.setValue('shippingAddress.street', address.street)
      methods.setValue('shippingAddress.number', address.number)
      methods.setValue('shippingAddress.cityId', address.cityId)
      methods.setValue('shippingAddress.stateId', address.stateId)
      methods.setValue('shippingAddress.postalCode', address.postalCode)
      methods.setValue('shippingAddress.complement', address.complement)
      methods.setValue('shippingAddress.neighborhood', address.neighborhood)
      methods.setValue('shippingAddress.referencePoint', address.referencePoint)

      methods.setValue('companyClinicId', companyClinicId)
    })
  }, [methods, query])

  useEffect(() => {
    if (!watchSameBillingAddress) {
      methods.unregister('billingAddress')
      methods.unregister('billingAddressId')
    }
  }, [methods, watchSameBillingAddress])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<FormCheckoutSubmit> = React.useCallback(
    async (data) => {
      const paymentMethod = methods.getValues(
        'paymentMethodId.label'
      ) as keyof typeof PAYMENT_METHODS_NAMES

      try {
        const response = await makeOrder(data)

        if (response.paymentLink) {
          setPaymentLink(response.paymentLink)
        }
        const onOpen = modals[paymentMethod]

        if (onOpen) {
          onOpen()
        }
      } catch (error: any) {
        const errorHandled = handleError(error, undefined, { 422: true })

        if (errorHandled) {
          errorHandled.forEach((errorItem) => {
            const { field, message } = errorItem as {
              field: keyof any
              message: string
            }

            methods.setError(field, { message })
          })
        }

        disclosureErrorPurchase.onOpen()
      }
    },
    [disclosureErrorPurchase, handleError, makeOrder, methods, modals]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={t('title')}>
      <ModalSuccessPaymentLink
        isOpen={disclosurePaymentLink.isOpen}
        onClose={disclosurePaymentLink.onClose}
        paymentLink={paymentLink}
      />

      <ModalSuccessLittleMachine
        isOpen={disclosureLittleMachine.isOpen}
        onClose={disclosureLittleMachine.onClose}
      />

      <ModalSuccessSubsidized
        isOpen={disclosureSubsidized.isOpen}
        onClose={disclosureSubsidized.onClose}
      />

      <ModalErrorPurchase
        isOpen={disclosureErrorPurchase.isOpen}
        onClose={disclosureErrorPurchase.onClose}
        reason={errors}
      />

      <FormProvider {...methods}>
        <Stack
          as="form"
          spacing="16"
          divider={<Divider />}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {query.get('personId') ? <ClientData /> : <ClinicData />}

          <Products />

          <Coupon />

          <PurchaseData />

          <ShippingData />

          {methods.watch('sameBillingAddress') && <BillingData />}

          <PaymentAndShipping />

          <ReviewOrder />

          <FinalValues />

          <FormFooter
            saveButtonText="Finalizar pedido"
            isSubmitting={methods.formState.isSubmitting}
          />
        </Stack>
      </FormProvider>
    </LayoutLoggedPageList>
  )
}
