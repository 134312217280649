// Vendors
import React, { useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'

// Components
import { FieldText } from 'components/FieldText'
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import {
  Modal,
  Stack,
  Button,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useError } from 'hooks/useError'
import { apiPatch } from 'services/patch'
import { handleChangeActivateCode } from 'utils/changeActivateCode'

type ModalAlterActivationCodeProps = {
  isOpen: boolean
  reload: () => void
  onCancel: () => void
  purchaseHasProductId: string
}

type FormAlterActivationCode = {
  comments: string
  activationCode: string
}

const VALIDATE = {
  isValidCode: (value: string) => {
    if (value.length > 8 && value.length < 19) {
      throw new Error('Código de ativação inválido')
    }
  },
  isValidMatrixCode: (value: string) => {
    if (value.length <= 8) {
      const numberPattern = /\D+/g
      const match = value.match(numberPattern)

      if (match) {
        throw new Error('O código do matrix deve conter apenas números')
      }
    }
  },
}

export const ModalAlterActivationCode = (
  props: ModalAlterActivationCodeProps
) => {
  const { isOpen, reload, onCancel, purchaseHasProductId } = props

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormAlterActivationCode>()

  const { handleError } = useError()

  const onClose = useCallback(() => {
    reset()
    onCancel()
  }, [onCancel, reset])

  const onSubmit = useCallback(
    async (data: FormAlterActivationCode) => {
      try {
        VALIDATE.isValidCode(data.activationCode)
        VALIDATE.isValidMatrixCode(data.activationCode)

        await apiPatch('/app/purchase_product/activation_code', {
          activationCodes: [
            {
              purchaseHasProductId,
              activationCode: data.activationCode.toUpperCase(),
            },
          ],
          comments: data.comments,
        })

        reload()
        onClose()
      } catch (error) {
        handleError(error)
      }
    },
    [handleError, onClose, purchaseHasProductId, reload]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Alterar código de ativação</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Stack>
            <Controller
              name="activationCode"
              control={control}
              rules={{
                required: 'Código de ativação é obrigatório',
              }}
              render={({ field }) => (
                <FieldText
                  label="Código de ativação"
                  error={errors.activationCode}
                  maxLength={19}
                  {...field}
                  onChange={(e) => field.onChange(handleChangeActivateCode(e))}
                  isRequired
                />
              )}
            />

            <FieldTextArea
              label="Comentários"
              error={errors.comments}
              {...register('comments')}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose}>Cancelar</Button>
            <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
