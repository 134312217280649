// Vendors
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from 'react'
import { FieldError } from 'react-hook-form'

// Components
import { FieldText } from 'components/FieldText'

// Styles
import { Flex, IconButton, Stack, Text } from '@chakra-ui/react'
import { FiMinus, FiPlus } from 'react-icons/fi'

// Interfaces
import { HandleQuestionType } from 'hooks/useFormFieldEditer'
export type OptionsType = {
  label: string
  value: string
}

export type FormFieldEditerOptionsProps = {
  index: number
  options: OptionsType[]
  setOptions: Dispatch<SetStateAction<OptionsType[]>>
  handleQuestion: HandleQuestionType
}

export const FormFieldEditerOptions = (
  props: FormFieldEditerOptionsProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { index, options, setOptions, handleQuestion } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [optionLabel, setOptionLabel] = useState('')
  const [error, setError] = useState<{ message: string } | undefined>(undefined)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleAddOption = useCallback(
    (
      event:
        | React.KeyboardEvent<HTMLInputElement>
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.preventDefault()

      const checkIfAlreadyExists = options.find(
        (option) => option.value === optionLabel
      )

      if (optionLabel === '') {
        setError({ message: 'A opção está vazia' })
        return
      }

      if (checkIfAlreadyExists) {
        setError({ message: 'A opção já existe' })
        return
      }

      const newOptions = [
        ...options,
        { label: optionLabel, value: optionLabel },
      ]

      setOptions(newOptions)
      handleQuestion.update(index, 'options', newOptions)

      setOptionLabel('')
    },
    [optionLabel, options, setOptions, index, handleQuestion]
  )

  const handleRemoveOption = useCallback(
    (optionValue: string) => {
      const newOptions = options.filter(
        (option) => option.value !== optionValue
      )
      setOptions(newOptions)
      handleQuestion.update(index, 'options', newOptions)
    },
    [handleQuestion, index, options, setOptions]
  )

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setOptionLabel(value)
    setError(undefined)
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Stack bg="blackAlpha.50" px="3.5" pt="2.5" pb="5" borderRadius="md" mt="2">
      <Text color="blue.500" fontWeight="medium">
        Adicione pelo menos 2 opções
      </Text>

      <Stack>
        {options.map((option) => (
          <Flex
            pl="2"
            align="center"
            key={option.value}
            justifyContent="space-between"
          >
            <Text fontWeight="medium">{option.label}</Text>

            <IconButton
              size="xs"
              variant="outline"
              colorScheme="red"
              borderWidth="2px"
              borderRadius="full"
              aria-label="remove option"
              onClick={() => handleRemoveOption(option.value)}
              icon={<FiMinus color="red" strokeWidth="4px" />}
            />
          </Flex>
        ))}
      </Stack>

      <Stack direction="row" align="center">
        <FieldText
          size="sm"
          bg="whiteAlpha"
          variant="filled"
          value={optionLabel}
          onChange={onChange}
          error={error as FieldError}
          name={`option-${props.index}`}
          placeholder="Digite a opção aqui..."
          onKeyPress={(e) => e.key === 'Enter' && handleAddOption(e)}
          _focus={{ bg: 'whiteAlpha' }}
          _hover={{ bg: 'whiteAlpha.700' }}
        />

        <IconButton
          size="xs"
          aria-label="add"
          variant="outline"
          borderWidth="2px"
          colorScheme="blue"
          borderRadius="full"
          onClick={handleAddOption}
          icon={<FiPlus color="blue.500" strokeWidth="4px" />}
        />
      </Stack>
    </Stack>
  )
}
