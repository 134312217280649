import React from 'react'

import { AccordionBlank } from '../AccordionBlank'
import { AccordionContent } from '../AccordionContent'

import {
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
  AccordionItem as ChakraAccordionItem,
  Box,
} from '@chakra-ui/react'
import { HistoryType } from '../AccordionContentItem/iconsMap'

export interface HistoryUpdate {
  id: string
  modelIdentifier: string
  modelName: string
  updatedByUserId: string
  updatedByPersonalDataName: string
  comments: string | null
  description: string | null
  createdAt: Date
  updatedAt: Date
  origin: string | null
  type: HistoryType | null
  title: string | null
  attachmentHistoryFile: Array<{
    id: string
    originalFileName: string
    fileName: string
    filePath: string
    fileUri: string
    group: string | null
    mimeType: string
    size: string
    title: string | null
    description: string | null
    historyUpdateId: string
    createdAt: Date
    updatedAt: Date
  }>
}

export enum TypeHistoryUpdate {
  PURCHASE_PRODUCT = 'PURCHASE_PRODUCT',
  PURCHASE = 'PURCHASE',
}

type AccordionItemProps = {
  label: string
  productId: string
  reload: () => void
  type: TypeHistoryUpdate
  historyUpdates?: HistoryUpdate[]
}

export const AccordionItem = (props: AccordionItemProps) => {
  const { type, label, reload, productId, historyUpdates } = props

  return (
    <ChakraAccordionItem
      mb="6"
      border="none"
      borderRadius="10px"
      boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1)"
    >
      <AccordionButton>
        <Box flex="1" textAlign="left" py="3" fontSize={18}>
          {label}
        </Box>

        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel>
        {historyUpdates && historyUpdates.length ? (
          <AccordionContent
            type={type}
            reload={reload}
            productId={productId}
            historyUpdates={historyUpdates}
          />
        ) : (
          <AccordionBlank />
        )}
      </AccordionPanel>
    </ChakraAccordionItem>
  )
}
