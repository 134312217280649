// Vendors
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

// Components
import { FieldSelect } from 'components/FieldSelect'

// Styles
import { Box } from '@chakra-ui/react'
import { useFormFieldEditor } from '../useFormFieldEditor'
import { FormFieldEditorProps } from '../MedicalRecord'
import { get } from 'lodash'

// Hooks

// Types

export const FormFieldEditorSelect = (props: FormFieldEditorProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { question } = props
  const { control } = useFormContext()
  const {
    rules,
    label,
    name,
    options: { selectOptions },
  } = useFormFieldEditor({ question })

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <Controller
        name={name}
        rules={{
          ...rules,
          required: rules.required ? `${label} é obrigatorio` : undefined,
        }}
        control={control}
        render={({ field, formState }) => (
          <FieldSelect
            label={label}
            options={selectOptions}
            error={get(formState.errors, name)}
            {...field}
          />
        )}
      />
    </Box>
  )
}
