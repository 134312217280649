import { motion } from 'framer-motion'
import React, { ChangeEvent, useState } from 'react'

import { useSearch } from 'contexts/search'

import { GlobalSearchList } from './GlobalSearchList'

import { FiSearch } from 'react-icons/fi'
import { VscClose } from 'react-icons/vsc'
import {
  Box,
  Flex,
  Text,
  Input,
  Badge,
  Heading,
  IconButton,
} from '@chakra-ui/react'

export const GlobalSearch = () => {
  const { content, handleContent, searchDisclosure } = useSearch()

  const [hidden, setHidden] = useState(!searchDisclosure.isOpen)

  return (
    <motion.div
      {...searchDisclosure.getDisclosureProps()}
      hidden={hidden}
      initial={false}
      onAnimationStart={() => setHidden(false)}
      animate={{ width: searchDisclosure.isOpen ? '24rem' : 0 }}
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      <Box p="4" w="96" overflowY="auto" h="full" bg="gray.700" color="white">
        <Flex justifyContent="space-between" alignItems="center">
          <Heading as="header" fontSize="xl">
            Busca global
            <Badge ml="2">Beta</Badge>
          </Heading>

          <IconButton
            variant="ghost"
            icon={<VscClose />}
            aria-label="fechar busca"
            onClick={searchDisclosure.onClose}
            _hover={{
              bg: 'gray.600',
            }}
          />
        </Flex>

        <Input
          mt="4"
          borderColor="gray.600"
          defaultValue={content}
          placeholder="Buscar..."
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleContent(e.target.value)
          }
        />

        <Box>
          {content ? (
            <GlobalSearchList />
          ) : (
            <Text textAlign="center" mt="8">
              Por favor, digite algo para buscar.
            </Text>
          )}
        </Box>
      </Box>
    </motion.div>
  )
}
