// Vendors
import React, { useMemo } from 'react'

// Components
import { Tabs } from 'components/Tabs'
import { TabPersonalData } from '../tabs/TabPersonalData'

// Styles
import { Flex } from '@chakra-ui/react'

// Types
type LaboratoryAgentFormNewProps = {
  endpoint: string
}

export const LaboratoryAgentFormNew = (
  props: LaboratoryAgentFormNewProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails = useMemo(
    () => [
      {
        title: 'Dados pessoais',
        component: TabPersonalData,
        options: { endpoint },
      },
    ],
    [endpoint]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex h="full" flexDirection="column" justifyContent="space-between">
      <Tabs tabsDetails={tabsDetails} />
    </Flex>
  )
}
