// Vendors
import React, { useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

// Functions

// Components
import { FieldText } from 'components/FieldText'

// Schemas
import { schema } from './schema'

// Styles
import { Box, Flex, Grid, GridItem, useToast } from '@chakra-ui/react'
import { FieldDate } from 'components/FieldDate'
import { FieldRadioButton } from 'components/FieldRadioButton'
import { FormFooter } from 'components/FormFooter'
import { FieldMask } from 'components/FieldMask'
import { useHistory } from 'react-router-dom'
import { useCreateRecord } from 'hooks/useCreateRecord'
import { sub } from 'date-fns'
import { ClientNewForm } from './types'

// Types
type ClientFormNewProps = {
  endpoint: string
}

export const ClientFormNew = (props: ClientFormNewProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props

  const { t } = useTranslation('common')
  const history = useHistory()

  const { create } = useCreateRecord<ClientNewForm>({ endpoint })

  const {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ClientNewForm>({
    resolver: yupResolver(schema(t)),
  })
  const toast = useToast({ position: 'top-right' })

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const genresOptions = useMemo(
    () => [
      {
        label: 'Masculino',
        value: 'M',
      },
      {
        label: 'Feminino',
        value: 'F',
      },
      {
        label: 'Não informar',
        value: 'NOT_INFORMED',
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<ClientNewForm> = React.useCallback(
    async (data) => {
      try {
        await create(data)

        toast({
          title: 'Sucesso',
          description: 'Cliente criado com sucesso',
          status: 'success',
        })

        history.push('/clients')
      } catch (error: any) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [create, history, setError, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      as="form"
      h="full"
      flexDirection="column"
      justifyContent="space-between"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box>
        <Grid
          my="4"
          gap="10"
          templateColumns={{ lg: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
        >
          <GridItem colSpan={2}>
            <FieldText
              label="Nome"
              error={errors.name}
              {...register('name')}
              isRequired
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldText
              label="Nome social"
              error={errors.socialName}
              {...register('socialName')}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldText
              label="E-mail"
              error={errors.email}
              isRequired
              {...register('email')}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldMask
              label="Telefone"
              mask="(99) 9 9999-9999"
              error={errors.phoneNumber}
              isRequired
              {...register('phoneNumber')}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldMask
              label="CPF"
              mask="999.999.999-99"
              error={errors.documentNumber}
              isRequired
              {...register('documentNumber')}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <Controller
              name="birthdate"
              control={control}
              render={({ field }) => (
                <FieldDate
                  label="Data de nascimento"
                  name={field.name}
                  selected={field.value}
                  maxDate={sub(new Date(), { days: 1 })}
                  onChange={field.onChange}
                  error={errors.birthdate}
                  isRequired
                />
              )}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldRadioButton
              label="Gênero"
              name="gender"
              control={control}
              radioButtonDirection="row"
              options={genresOptions}
            />
          </GridItem>
        </Grid>
      </Box>

      <FormFooter isSubmitting={isSubmitting} hasAlert />
    </Flex>
  )
}
