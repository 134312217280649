// Vendors
import React, { ChangeEvent, useCallback } from 'react'
import { Controller, FieldError } from 'react-hook-form'

// Components
import { FieldToggle } from 'components/FieldToggle'

// Styles
import { Box, useToast } from '@chakra-ui/react'
import { useFeedback } from 'contexts/feedback'
import { useError } from 'hooks/useError'

// Interfaces
export type FieldStatusProps = {
  methods: any
  error?: FieldError
  enable: () => Promise<void>
  disable: () => Promise<void>
}

export const FieldStatus = (props: FieldStatusProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { error, enable, disable, methods } = props

  const { handleError } = useError()
  const toast = useToast({ position: 'top-right' })
  const { feedback } = useFeedback()

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleToggle = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      try {
        if (e.target.checked) {
          await enable()
        } else {
          await disable()
        }

        toast({
          title: 'Sucesso',
          description: 'Status atualizado com sucesso',
          status: 'success',
        })
      } catch (error: any) {
        methods.setValue('status', !e.target.checked)

        handleError(error)
      }
    },
    [disable, enable, handleError, methods, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box position="absolute" top={-16} right={0}>
      <Controller
        name="status"
        control={methods.control}
        render={({ field: { value, ref, name, onChange } }) => (
          <FieldToggle
            name={name}
            ref={ref}
            label="Habilitar"
            isChecked={value}
            error={error}
            onChange={(e) => {
              onChange(e)
              handleToggle(e)
            }}
          />
        )}
      />
    </Box>
  )
}
