// Vendors
import { v4 } from 'uuid'
import { format } from 'date-fns'
import { find, get, map } from 'lodash'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

// Functions
import { useCan } from 'hooks/useCan'
import { useError } from 'hooks/useError'
import { useDownload } from 'hooks/useDownload'
import { useFeedback } from 'contexts/feedback'
import { formattedCardDetails } from './formattedCardDetails'
import { instanceOfLaboratorialAnalysis } from 'utils/instanceOfLaboratorialAnalysis'

// Components
import { FieldSet } from 'components/FieldSet'
import { FieldDate } from 'components/FieldDate'
import { FieldText } from 'components/FieldText'
import { CardDetails } from 'components/CardDetails'
import { CardDetailsValue } from 'components/CardDetailsValue'
import { OrderTimelineList } from 'components/OrderTimelineList'
import { ModalShowActiveKit } from 'components/ModalShowActiveKit'
import { OrderTimelineItemProps } from 'components/OrderTimelineItem'

// Services
import { apiPatch } from 'services/patch'

// Styles
import {
  Box,
  Text,
  Flex,
  Stack,
  Button,
  HStack,
  Divider,
  Heading,
  Collapse,
  useDisclosure,
} from '@chakra-ui/react'
import {
  FiAlertTriangle,
  FiCheckSquare,
  FiFileText,
  FiInbox,
} from 'react-icons/fi'
import { BiDna } from 'react-icons/bi'

// Types

import {
  KitCheckInForm,
  KitCheckInShow,
  ProductReverseLogisticStatus,
} from 'pages/private/Laboratorial/KitCheckIn/show/types'
import { RULES } from 'pages/private/Laboratorial/rules'
import {
  FormQuestionType,
  LaboratorialAnalysis,
} from 'types/LaboratorialAnalysis'

type TabGeneralDataProps = {
  record: KitCheckInShow | undefined
  activationCode: string
  reload: () => void
}

export const TabGeneralData = (props: TabGeneralDataProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { record, reload, activationCode } = props

  const { onDownload } = useDownload()
  const { can } = useCan({ rules: RULES })

  const { handleError } = useError()
  const { t } = useTranslation('laboratory')
  const { feedback } = useFeedback()
  const disclosureShowActiveKit = useDisclosure()

  const {
    reset,
    watch,
    control,
    register,
    formState: { errors },
  } = useForm<KitCheckInForm>({
    mode: 'onChange',
  })

  const reportFileDateLimit = watch('dateLimit')

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [saveDeadlineForRelease, setSaveDeadlineForRelease] = useState(false)
  const [show, setShow] = React.useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    const productReverseLogistic = get(record, 'productReverseLogistic')
    const productFisrtAnalysis = get(
      record,
      'purchaseHasProductHasAnalysis[0]',
      {}
    )
    reset({
      internalLaboratoryId: get(productReverseLogistic, 'internalLaboratoryId'),
      dateLimit: get(productFisrtAnalysis, 'reportFileEstimatedIssueDate'),
    })
  }, [record, reset])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const orderTimeline = useMemo(() => {
    if (!record || !record.productReverseLogistic) return []
    const extraStatus = ['KIT_ACCEPTED', 'DIVERGENT_DATA', 'SAMPLE_DENIED']
    const secordStatusMap = {
      KIT_ACCEPTED: {
        icon: FiInbox,
        title: 'Amostra recebida',
        isActive:
          Number(
            ProductReverseLogisticStatus[record.productReverseLogistic.status]
          ) >= 1,
      },
      DIVERGENT_DATA: {
        icon: FiAlertTriangle,
        title: 'Dados divergente',
        isError:
          Number(
            ProductReverseLogisticStatus[record.productReverseLogistic.status]
          ) >= 2,
      },
      SAMPLE_DENIED: {
        icon: FiAlertTriangle,
        title: 'Amostra negada',
        isError:
          Number(
            ProductReverseLogisticStatus[record.productReverseLogistic.status]
          ) >= 3,
      },
    }

    let customStatus: OrderTimelineItemProps = secordStatusMap.KIT_ACCEPTED

    if (extraStatus.includes(record.productReverseLogistic.status)) {
      customStatus =
        secordStatusMap[
          record.productReverseLogistic.status as
            | 'KIT_ACCEPTED'
            | 'DIVERGENT_DATA'
            | 'SAMPLE_DENIED'
        ]
    }
    return [
      {
        icon: FiCheckSquare,
        title: 'Kit ativado',
        isActive:
          Number(
            ProductReverseLogisticStatus[record.productReverseLogistic.status]
          ) >= 0,
      },
      {
        ...customStatus,
      },
      {
        icon: BiDna,
        title: 'Em análise',
        isActive:
          Number(
            ProductReverseLogisticStatus[record.productReverseLogistic.status]
          ) >= 4,
      },
      {
        icon: FiFileText,
        title: 'Laudo',
        isActive:
          Number(
            ProductReverseLogisticStatus[record.productReverseLogistic.status]
          ) >= 5,
      },
    ]
  }, [record])

  const memoCardDetails = useMemo(() => {
    if (!record) return []

    const sanitazedRecord = formattedCardDetails(record)

    if (!sanitazedRecord) return []

    const {
      purchase,
      activationByPersonalData,
      activationForPersonalData,
      purchaseHasProductHasAnalysis,
    } = sanitazedRecord

    return [
      {
        label: t('card.order.label'),
        ...(purchase?.id && !can.order && { link: `/orders/${purchase.id}` }),
        value: (
          <Flex
            p="8"
            mt="-4"
            mb="-10"
            mx="-6"
            h="100%"
            alignItems="center"
            justifyContent="space-between"
            bg={
              purchase?.isCobranding
                ? 'linear-gradient(270deg, rgba(253, 201, 11, 0.144) 10%, rgba(254, 223, 110, 0.207217) 14%, rgba(255, 255, 255, 0.3) 25%);'
                : ''
            }
          >
            <Text fontWeight="bold">#{purchase?.purchaseNumber}</Text>
            {purchase?.isCobranding && <Text>Cobranding</Text>}
          </Flex>
        ),
      },
      {
        label: t('card.customerDataActivation.label'),
        ...(activationForPersonalData?.id &&
          !can.patient && {
            link: `/clients/${activationForPersonalData.id}`,
          }),
        value: (
          <>
            <CardDetailsValue
              label={t('card.customerDataActivation.name')}
              value={activationForPersonalData?.name}
            />
            <CardDetailsValue
              label={t('card.customerDataActivation.birthDate')}
              value={activationForPersonalData?.birthdate}
            />
            <CardDetailsValue
              label={t('card.customerDataActivation.documentNumber')}
              value={activationForPersonalData?.documentNumber}
            />
            <CardDetailsValue
              label={t('card.customerDataActivation.email')}
              value={activationForPersonalData?.email}
            />
            <CardDetailsValue
              label={t('card.customerDataActivation.gender')}
              value={activationForPersonalData?.gender}
            />
          </>
        ),
      },
      {
        label: t('card.activeService.label'),
        value: (
          <>
            <Stack
              direction="row"
              spacing="3"
              divider={
                <Divider
                  height="20px"
                  colorScheme="blue"
                  orientation="vertical"
                />
              }
            >
              {purchaseHasProductHasAnalysis?.services?.map((service) =>
                !can.services ? (
                  <Link
                    key={service.id}
                    to={`/laboratorial_analysis/${service.id}`}
                  >
                    <Text
                      transition="all 0.2s"
                      textDecoration="underline"
                      _hover={{ color: 'blue.500' }}
                    >
                      {service.name}
                    </Text>
                  </Link>
                ) : (
                  <Text>{service.name}</Text>
                )
              )}
            </Stack>
          </>
        ),
      },
      ...(((purchase?.purchaseType === 'B2B' ||
        purchase?.purchaseType === 'LEGACY' ||
        get(record, 'originActivation') === 'PROFESSIONAL') && [
        {
          label: t('card.professional.label'),
          ...(activationByPersonalData?.id &&
            !can.professional && {
              link: `/professionals/${activationByPersonalData.id}`,
            }),
          value: (
            <>
              <CardDetailsValue
                label={t('card.customerDataActivation.name')}
                value={activationByPersonalData?.name}
              />
              <CardDetailsValue
                label={t('card.customerDataActivation.birthDate')}
                value={activationByPersonalData?.birthdate}
              />
              <CardDetailsValue
                label={t('card.customerDataActivation.documentNumber')}
                value={activationByPersonalData?.documentNumber}
              />
              <CardDetailsValue
                label={t('card.customerDataActivation.email')}
                value={activationByPersonalData?.email}
              />
            </>
          ),
        },
      ]) || [null]),
    ]
  }, [can.order, can.patient, can.professional, can.services, record, t])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleExport = useCallback(async () => {
    try {
      await onDownload(
        `/app/laboratory/export_kit/${activationCode}`,
        `report.json`,
        undefined,
        { handleError: true }
      )
    } catch (error: any) {
      handleError(error)
    }
  }, [activationCode, handleError, onDownload])

  const laboratoryAnalysis = useMemo(() => {
    if (!record) return []

    const purchaseHasProductHasAnalysis = get(
      record,
      'purchaseHasProductHasAnalysis'
    )

    const clinicalRecordFormResponse = map(
      purchaseHasProductHasAnalysis,
      'clinicalRecordFormResponse'
    ) as { laboratoryAnalysisId: string; questions: FormQuestionType[] }[]

    const laboratoryAnalysis = map(
      purchaseHasProductHasAnalysis,
      'laboratoryAnalysis'
    ) as LaboratorialAnalysis[]

    const formattedLaboratoryAnalysis = map(laboratoryAnalysis, (analysis) => {
      const clinicalRecord = get(analysis, 'clinicalRecord')
      if (!clinicalRecord) return analysis

      const clinicalRecordForm = get(clinicalRecord, 'clinicalRecordForm')

      if (!clinicalRecordForm) return analysis

      const questions = get(clinicalRecordForm, 'questions')

      if (!questions) return analysis

      const formattedQuestions = map(questions, (question) => ({
        ...question,
        id: v4(),
      }))

      const formattedClinicalRecordFormResponse = find(
        clinicalRecordFormResponse,
        { laboratoryAnalysisId: analysis.id }
      )

      return {
        ...analysis,
        clinicalRecordFormResponse: formattedClinicalRecordFormResponse,
        clinicalRecord: {
          ...clinicalRecord,
          clinicalRecordForm: {
            ...clinicalRecordForm,
            questions: formattedQuestions,
          },
        },
      } as LaboratorialAnalysis
    })

    return formattedLaboratoryAnalysis
  }, [record])

  const isAbleEditDateLimit = useMemo(() => {
    const status = get(record, 'status')

    if (!status) return false

    return ['PENDING_ACTIVATION', 'PENDING_REVERSE_CODE'].includes(status)
  }, [record])

  const handleSaveDeadlineForRelease = useCallback(async () => {
    try {
      let endpoint =
        '/app/laboratory/kit/{activationCode}/report_file_estimated_issue_date'
      endpoint = endpoint.replace('{activationCode}', activationCode)

      await apiPatch(endpoint, {
        reportFileEstimatedIssueDate: reportFileDateLimit,
      })

      feedback({
        title: 'Sucesso',
        description: 'Data limite para liberação alterada com sucesso',
        status: 'success',
      })
      setSaveDeadlineForRelease(false)
    } catch (error) {
      handleError(error)
    }
  }, [activationCode, feedback, handleError, reportFileDateLimit])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isProfessional = useMemo(() => {
    const purchase = get(record, 'purchase')
    if (!purchase) return false

    const purchaseType = get(purchase, 'purchaseType')
    const originActivation = get(record, 'originActivation')

    return (
      purchaseType === 'B2B' ||
      purchaseType === 'LEGACY' ||
      originActivation === 'PROFESSIONAL'
    )
  }, [record])

  return (
    <Box>
      <ModalShowActiveKit
        reload={reload}
        isOpen={disclosureShowActiveKit.isOpen}
        onCancel={disclosureShowActiveKit.onClose}
        activationCode={activationCode}
        laboratoryAnalysis={laboratoryAnalysis}
        activationForPersonalData={{
          id: get(record, 'activationForPersonalData.id'),
          name: get(record, 'activationForPersonalData.name'),
        }}
        activationByPersonalData={
          isProfessional
            ? {
                id: get(record, 'activationByPersonalData.id'),
                name: get(record, 'activationByPersonalData.name'),
              }
            : undefined
        }
      />

      <OrderTimelineList my="8" data={orderTimeline} />

      <Stack direction={{ base: 'column', lg: 'row' }} spacing={12}>
        <CardDetails data={memoCardDetails} />

        <Stack w="full">
          <Collapse startingHeight={240} in={show}>
            <HStack>
              <Heading fontSize="xl" color="blue.500">
                {t('medicalRecord.title')}
              </Heading>

              <Button
                size="xs"
                variant="outline"
                colorScheme="blue"
                onClick={disclosureShowActiveKit.onOpen}
              >
                EDITAR
              </Button>

              <Button
                size="xs"
                variant="outline"
                colorScheme="blue"
                onClick={handleExport}
              >
                EXPORTAR
              </Button>
            </HStack>

            {get(record, 'isLegacy', false) && (
              <Box mt="5">
                <Text>Sem ficha clínica</Text>
              </Box>
            )}

            {record &&
              !instanceOfLaboratorialAnalysis(
                record.purchaseHasProductHasAnalysis
              ) &&
              record.purchaseHasProductHasAnalysis.map((product) => {
                const { clinicalRecordFormResponse } = product

                if (!clinicalRecordFormResponse) {
                  return (
                    <Box mt="5">
                      <Text>Sem respostas para este exame</Text>
                    </Box>
                  )
                }

                const { questions } = clinicalRecordFormResponse

                if (questions.length === 0) {
                  return (
                    <Box mt="5">
                      <Text>Sem respostas para este exame</Text>
                    </Box>
                  )
                }

                return (
                  <>
                    <Heading as="h2" fontSize="xl" mt="5">
                      {t('medicalRecord.description')}{' '}
                      {product.laboratoryAnalysis.name}
                    </Heading>

                    {questions &&
                      questions.map((question) => {
                        let response = question.response

                        if (question.fieldType === 'checkbox') {
                          response = question.options
                            .filter((option) => option.response)
                            .map((option) => option.label)
                            .join(', ')
                        }

                        if (
                          question.fieldType === 'date' &&
                          question.response &&
                          !question.response.includes('/')
                        ) {
                          response = format(
                            new Date(question.response),
                            'dd/MM/yyyy'
                          )
                        }

                        if (question.fieldType === 'title') {
                          return (
                            <Box key={question.id} mt="6" mb="2">
                              <Heading as="h3" fontSize="lg" fontWeight="bold">
                                {question.label}
                              </Heading>
                            </Box>
                          )
                        }

                        return (
                          <Box key={question.label}>
                            <Text as="strong">{question.label}</Text>
                            <Text>{response || 'Sem resposta'}</Text>
                          </Box>
                        )
                      })}
                  </>
                )
              })}
          </Collapse>

          <Stack isInline alignItems="center" spacing="3">
            <Divider />
            <Button
              size="sm"
              minW="24"
              variant="ghost"
              colorScheme="blue"
              onClick={() => setShow((oldState) => !oldState)}
            >
              Mostrar {show ? 'menos' : 'mais'}
            </Button>
            <Divider />
          </Stack>
        </Stack>
      </Stack>

      <FieldSet title={t('reportData.title')} maxW="container.md" mt={12}>
        <Stack
          mt={2}
          alignItems="flex-start"
          direction={{ base: 'column', md: 'row' }}
        >
          <Box w="full">
            <Controller
              name="dateLimit"
              control={control}
              rules={{
                required: `${t('reportData.deadlineForRelease.required')}`,
              }}
              render={({ field }) => (
                <FieldDate
                  disabled={isAbleEditDateLimit}
                  minDate={new Date()}
                  label={t('reportData.deadlineForRelease.label')}
                  name={field.name}
                  selected={field.value}
                  onChange={(e) => {
                    setSaveDeadlineForRelease(true)
                    field.onChange(e)
                  }}
                  error={errors.dateLimit}
                />
              )}
            />

            {!isAbleEditDateLimit && saveDeadlineForRelease && (
              <Button
                size="sm"
                variant="link"
                onClick={handleSaveDeadlineForRelease}
              >
                Salvar
              </Button>
            )}
          </Box>

          <FieldText
            isDisabled
            label={t('reportData.laboratoryId.label')}
            helperText={
              errors.internalLaboratoryId
                ? ''
                : t('reportData.laboratoryId.helperText')
            }
            error={errors.internalLaboratoryId}
            {...register('internalLaboratoryId')}
          />
        </Stack>
      </FieldSet>
    </Box>
  )
}
