import Print from 'print-js'
import React, { useCallback, useState } from 'react'

export const useGenerateQrCode = () => {
  const printerTagHeight = 104 // 300
  const printerTagWidth = 186 // 500

  const [kitsCode, setKitsCode] = useState<string[]>([])

  const canvasDrawTag = useCallback(
    (
      code: string,
      canvasWidth: number,
      canvasHeight: number,
      canvasCtxRef: any,
      canvasRef: any
    ) => {
      const margin = canvasHeight * 0.13
      const QRSize = canvasHeight - margin * 3.5

      if (canvasRef) {
        // Get canvas from ref
        canvasCtxRef = canvasRef.getContext('2d')
        const ctx = canvasCtxRef
        if (!ctx) return
        // Set canvas size
        ctx.canvas.width = canvasWidth
        ctx.canvas.height = canvasHeight

        // Clear canvas
        ctx.clearRect(0, 0, canvasRef.width, canvasRef.height)

        // Fill Background
        // ctx.fillRect(0, 0, canvasWidth, canvasHeight)

        // Get image from qr code
        if (document.getElementById(`QRCode-${code}`)) {
          const QRImage = document.getElementById(
            `QRCode-${code}`
          ) as HTMLCanvasElement
          ctx.drawImage(QRImage, margin, margin, QRSize, QRSize)
        }

        // border
        ctx.strokeStyle = '#000'
        ctx.lineWidth = 1 * 2
        ctx.rect(0, 0, canvasWidth, canvasHeight)
        ctx.stroke()

        // Draw code
        ctx.font = `${canvasHeight * 0.127}px JetBrains Mono`
        ctx.fillText(code, margin, canvasHeight - margin)
      }
    },
    []
  )

  // TODO: Refactor this function
  const printTag = useCallback(() => {
    const tags = kitsCode.map((_, index) => {
      return `no-print-${index}`
    })

    Print({
      printable: 'tag',
      type: 'html',
      ignoreElements: [...tags],
      style: `.no-print: { display: none !important; }`,
    })
  }, [kitsCode])

  return {
    printTag,
    kitsCode: {
      current: kitsCode,
      set: setKitsCode,
    },
    printerTagHeight,
    printerTagWidth,
    canvasDrawTag,
  }
}
