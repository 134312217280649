import { TFunction } from 'react-i18next'
import { yupSchema } from 'utils/yupSchema'

import * as yup from 'yup'

export const schema = (t: TFunction<'common'>) => {
  yupSchema(t)

  return yup.object().shape({
    street: yup
      .string()
      .required()
      .label(t('drawer.address.form.street.label')),

    number: yup
      .string()
      .required()
      .label(t('drawer.address.form.number.label')),

    cityId: yup
      .string()
      .required()
      .selectValue()
      .label(t('drawer.address.form.cityId.label')),

    stateId: yup
      .string()
      .selectValue()
      .required()
      .label(t('drawer.address.form.stateId.label')),

    postalCode: yup
      .string()
      .getCharacters()
      .length(8)
      .required()
      .label(t('drawer.address.form.postalCode.label')),

    complement: yup
      .string()
      .optional()
      .nullable()
      .label(t('drawer.address.form.complement.label')),

    neighborhood: yup
      .string()
      .required()
      .label(t('drawer.address.form.neighborhood.label')),
  })
}
