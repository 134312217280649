import { chakra, HTMLChakraProps, useToken } from '@chakra-ui/react'
import * as React from 'react'

export const IggReaction = (
  props: HTMLChakraProps<'svg'> & {
    iconColor?: string
    colorStopInit: string
    colorStopEnd: string
  }
) => {
  const { iconColor, colorStopInit, colorStopEnd, ...rest } = props
  const color = iconColor || 'white'
  return (
    <chakra.svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="url(#paint0_linear_397:843)"
      />
      <path
        d="M19.1251 5.84696C19.2219 5.71604 19.3574 5.61888 19.5124 5.56922C19.6674 5.51955 19.8342 5.51991 19.989 5.57022C20.1438 5.62054 20.2789 5.71828 20.3751 5.8496C20.4713 5.98091 20.5238 6.13917 20.5251 6.30196L20.5471 21.121C20.5471 21.2731 20.5026 21.4218 20.4191 21.549L12.1191 34.126C12.063 34.2113 11.9907 34.2848 11.9062 34.3422C11.8218 34.3996 11.7268 34.4398 11.6268 34.4605C11.5268 34.4812 11.4237 34.482 11.3233 34.4629C11.223 34.4437 11.1275 34.405 11.0421 34.349C10.9567 34.2929 10.8833 34.2205 10.8259 34.1361C10.7685 34.0516 10.7283 33.9567 10.7076 33.8566C10.6869 33.7566 10.686 33.6535 10.7052 33.5532C10.7243 33.4529 10.763 33.3573 10.8191 33.272L18.9921 20.89L18.9711 6.30496C18.9719 6.13971 19.0259 5.97911 19.1251 5.84696Z"
        fill={color}
      />
      <path
        d="M19.148 20.664C19.2079 20.5811 19.2837 20.511 19.371 20.458L31.836 12.858C31.923 12.7997 32.0209 12.7596 32.1238 12.7401C32.2267 12.7206 32.3325 12.7222 32.4348 12.7447C32.537 12.7672 32.6337 12.8101 32.7189 12.871C32.8042 12.9318 32.8762 13.0093 32.9307 13.0987C32.9852 13.1881 33.0211 13.2876 33.0361 13.3913C33.0511 13.4949 33.045 13.6005 33.0181 13.7017C32.9912 13.803 32.9441 13.8977 32.8797 13.9802C32.8152 14.0627 32.7347 14.1314 32.643 14.182L20.178 21.782C20.0249 21.8751 19.8442 21.9123 19.6667 21.8872C19.4892 21.8622 19.3258 21.7765 19.2043 21.6447C19.0829 21.5128 19.0108 21.343 19.0003 21.164C18.9899 20.9851 19.0417 20.808 19.147 20.663L19.148 20.664Z"
        fill={color}
      />
      <path
        d="M23.4957 23.034C23.5573 22.9501 23.6348 22.8791 23.7236 22.825L33.7736 16.799C33.95 16.6929 34.1613 16.6612 34.361 16.7109C34.5607 16.7606 34.7326 16.8876 34.8386 17.064C34.9447 17.2403 34.9764 17.4516 34.9267 17.6513C34.877 17.8511 34.75 18.0229 34.5736 18.129L24.5216 24.158C24.3682 24.2496 24.1878 24.2855 24.0109 24.2596C23.8341 24.2336 23.6716 24.1474 23.551 24.0155C23.4304 23.8835 23.359 23.714 23.3489 23.5356C23.3388 23.3571 23.3906 23.1806 23.4957 23.036V23.034Z"
        fill={color}
      />
      <path
        d="M14.2518 6.47897C14.3475 6.34927 14.4812 6.25258 14.6344 6.20234C14.7875 6.15211 14.9525 6.15082 15.1065 6.19864C15.2604 6.24647 15.3956 6.34105 15.4933 6.46924C15.5911 6.59743 15.6464 6.75287 15.6518 6.91397L15.9578 18.628C15.963 18.7314 15.9475 18.8348 15.9122 18.9321C15.8769 19.0294 15.8224 19.1187 15.7521 19.1947C15.6817 19.2706 15.5969 19.3317 15.5025 19.3744C15.4082 19.4171 15.3063 19.4404 15.2028 19.4431C15.0993 19.4458 14.9963 19.4277 14.8999 19.3899C14.8034 19.3522 14.7156 19.2955 14.6414 19.2233C14.5672 19.1511 14.5082 19.0647 14.4679 18.9694C14.4276 18.874 14.4068 18.7715 14.4068 18.668L14.1018 6.95497C14.0979 6.78411 14.1506 6.61676 14.2518 6.47897Z"
        fill={color}
      />
      <defs>
        <linearGradient
          id="paint0_linear_397:843"
          x1="34.68"
          y1="-1.09564e-06"
          x2="5.48"
          y2="36.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colorStopInit} />
          <stop offset="1" stopColor={colorStopEnd} />
        </linearGradient>
      </defs>
    </chakra.svg>
  )
}
