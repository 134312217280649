// Vendors
import React, { useCallback, useState, useMemo } from 'react'
import {
  sub,
  endOfWeek,
  subMonths,
  getDaysInMonth,
  previousSaturday,
  add,
  format,
} from 'date-fns'
import { FieldError } from 'react-hook-form'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'

import { formatDate } from 'utils/formatDate'

// Styles
import {
  Flex,
  Menu,
  Input,
  Tooltip,
  MenuList,
  MenuItem,
  FormLabel,
  MenuButton,
  InputProps,
  IconButton,
  InputGroup,
  FormControl,
  TooltipProps,
  MenuItemProps,
  FormControlProps,
  FormErrorMessage,
  InputRightElement,
} from '@chakra-ui/react'
import { FiCalendar, FiCheck, FiX } from 'react-icons/fi'
import fieldDateRangePickerStyles from './styles'

// Interfaces
import { Query } from 'types/Requests'
enum PeriodSelected {
  CURRENT_WEEK = 'CURRENT_WEEK',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
}

type CustomMenuItemProps = MenuItemProps & {
  children: React.ReactNode
  isSelected: boolean
}

export type FieldDateRangePickerProps = Omit<
  ReactDatePickerProps,
  'onChange'
> & {
  name: string
  period?: {
    get: { start?: Date; end?: Date }
    set: React.Dispatch<
      React.SetStateAction<{
        start?: Date
        end?: Date
      }>
    >
  }
  query?: {
    current: Query
    set: React.Dispatch<React.SetStateAction<Query>>
  }
  label?: string
  error?: FieldError
  isSmall?: boolean
  canModify?: boolean
  inputProps?: InputProps
  tooltipProps?: TooltipProps
  containerProps?: FormControlProps
  onChange?: (dates: [Date, Date]) => void
  handlePeriodChange?: (dates: [Date, Date]) => void
  helperText?:
    | string
    | {
        text: string
        color?: string
      }
}

export const FieldDateRangePicker = (
  props: FieldDateRangePickerProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    name,
    label,
    error,
    query,
    period,
    isSmall,
    helperText,
    inputProps,
    tooltipProps,
    containerProps,
    placeholderText,
    canModify = true,
    handlePeriodChange,
    onChange,
    ...rest
  } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [periodSelected, setPeriodSelected] = useState<
    keyof typeof PeriodSelected | undefined
  >(undefined)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleQuery = useCallback(
    (dates: [Date, Date]) => {
      const [start, end] = dates
      const startDate = format(new Date(start), 'yyyy-MM-dd')
      let endDate = ''

      if (end) {
        endDate = format(new Date(end), 'yyyy-MM-dd')
      }
      query?.set((oldQuery) => ({
        ...oldQuery,
        startDate,
        endDate,
      }))
    },
    [query]
  )

  const CustomMenuItem = useCallback(
    ({ isSelected, children, ...rest }: CustomMenuItemProps) => {
      return (
        <MenuItem d="flex" justifyContent="space-between" {...rest}>
          {children}
          {isSelected && <FiCheck />}
        </MenuItem>
      )
    },
    []
  )

  const handleSetCustomPeriod = useCallback(
    (days: number) => {
      const currentDate = new Date()
      const end = sub(currentDate, { days: 1 })
      const start = sub(end, { days })

      if (days === 30 || days === 7) {
        setPeriodSelected(`LAST_${days}_DAYS`)
      }

      if (query) {
        handleQuery([start, end])
        return
      }

      if (period && handlePeriodChange) {
        period.set({ start, end })
        handlePeriodChange([start, end])
      }
    },
    [handlePeriodChange, handleQuery, period, query]
  )

  const handleLastMonth = useCallback(() => {
    const currentDate = new Date()
    const previousMonth = subMonths(currentDate, 1)
    const daysInMonth = getDaysInMonth(previousMonth)
    const start = new Date(
      previousMonth.getFullYear(),
      previousMonth.getMonth(),
      1
    )
    const end = new Date(
      previousMonth.getFullYear(),
      previousMonth.getMonth(),
      daysInMonth
    )

    setPeriodSelected(PeriodSelected.LAST_MONTH)

    if (query) {
      handleQuery([start, end])
      return
    }

    if (period && handlePeriodChange) {
      period.set({ start, end })
      handlePeriodChange([start, end])
    }
  }, [handlePeriodChange, handleQuery, period, query])

  const handleLastWeek = useCallback(() => {
    const currentDate = new Date()
    const end = previousSaturday(currentDate)
    const start = sub(end, { days: 6 })

    setPeriodSelected(PeriodSelected.LAST_WEEK)

    if (query) {
      handleQuery([start, end])
      return
    }

    if (period && handlePeriodChange) {
      period.set({ start, end })
      handlePeriodChange([start, end])
    }
  }, [handlePeriodChange, handleQuery, period, query])

  const handleCurrentWeek = useCallback(() => {
    const currentDate = new Date()
    const end = endOfWeek(currentDate)
    const start = sub(end, { days: 6 })

    setPeriodSelected(PeriodSelected.CURRENT_WEEK)

    if (query) {
      handleQuery([start, end])
      return
    }

    if (period && handlePeriodChange) {
      period.set({ start, end })
      handlePeriodChange([start, end])
    }
  }, [handlePeriodChange, handleQuery, period, query])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const endDate = useMemo(() => {
    if (query) {
      if (query.current.endDate) {
        return add(new Date(query.current.endDate), { days: 1 })
      }

      return
    }

    if (period) {
      return period.get.end
    }

    return undefined
  }, [period, query])

  const startDate = useMemo(() => {
    if (query) {
      if (query.current.startDate) {
        return add(new Date(query.current.startDate), { days: 1 })
      }

      return
    }

    if (period) {
      return period.get.start
    }

    return undefined
  }, [period, query])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormControl isInvalid={!!error} sx={fieldDateRangePickerStyles}>
      <Flex
        mb={isSmall ? 0 : 2}
        alignItems="center"
        justifyContent="space-between"
        {...containerProps}
      >
        {!!label && (
          <FormLabel htmlFor={name} m={0}>
            {label}
          </FormLabel>
        )}

        {tooltipProps && Object.keys(tooltipProps).length && (
          <Tooltip {...tooltipProps} verticalAlign="middle" />
        )}
      </Flex>

      <InputGroup size={isSmall ? 'sm' : 'md'} minW="300px">
        <DatePicker
          onChange={onChange || handleQuery}
          id={name}
          name={name}
          selectsRange
          dateFormat="dd/MM/yyyy"
          endDate={endDate}
          startDate={startDate}
          locale="pt-BR"
          customInput={<Input {...inputProps} size={isSmall ? 'sm' : 'md'} />}
          selected={startDate && new Date(startDate)}
          placeholderText={placeholderText || 'Selecione a data...'}
          {...rest}
        />

        <IconButton
          variant="ghost"
          position="absolute"
          top={isSmall ? '14%' : '20%'}
          right="10"
          size="xs"
          aria-label="Remover período"
          icon={<FiX />}
          onClick={() => {
            period?.set({ start: undefined, end: undefined })
            setPeriodSelected(undefined)
            query?.set((oldQuery) => ({
              ...oldQuery,
              startDate: undefined,
              endDate: undefined,
            }))
          }}
        />

        <InputRightElement>
          <Menu>
            <MenuButton
              size="sm"
              as={IconButton}
              icon={<FiCalendar />}
              borderRadius={isSmall ? 'sm' : 'md'}
            >
              Periodo
            </MenuButton>
            <MenuList>
              <CustomMenuItem
                onClick={() => handleCurrentWeek()}
                isSelected={periodSelected === 'CURRENT_WEEK'}
              >
                Essa semana
              </CustomMenuItem>
              <CustomMenuItem
                onClick={() => handleLastWeek()}
                isSelected={periodSelected === 'LAST_WEEK'}
              >
                Última semana
              </CustomMenuItem>
              <CustomMenuItem
                onClick={() => handleLastMonth()}
                isSelected={periodSelected === 'LAST_MONTH'}
              >
                Último mês
              </CustomMenuItem>
              <CustomMenuItem
                onClick={() => handleSetCustomPeriod(7)}
                isSelected={periodSelected === 'LAST_7_DAYS'}
              >
                Últimos 7 dias
              </CustomMenuItem>
              <CustomMenuItem
                onClick={() => handleSetCustomPeriod(30)}
                isSelected={periodSelected === 'LAST_30_DAYS'}
              >
                Últimos 30 dias
              </CustomMenuItem>
            </MenuList>
          </Menu>
        </InputRightElement>
      </InputGroup>

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
