import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

export const Spatula = (
  props: HTMLChakraProps<'svg'> & {
    iconColor?: string
    colorStopInit: string
    colorStopEnd: string
  }
) => {
  const { iconColor, colorStopInit, colorStopEnd, ...rest } = props
  const color = iconColor || 'white'
  return (
    <chakra.svg
      id="Componente_53_1"
      data-name="Componente 53 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="53.73"
      height="53.73"
      viewBox="0 0 53.73 53.73"
      {...rest}
    >
      <svg>
        <path
          id="Caminho_44"
          data-name="Caminho 44"
          d="M26.865,53.73A26.865,26.865,0,1,0,0,26.865,26.865,26.865,0,0,0,26.865,53.73Z"
          fill={colorStopInit}
        />
        <g
          id="Grupo_32"
          data-name="Grupo 32"
          transform="translate(14.776 6.717)"
        >
          <path
            id="União_7"
            data-name="União 7"
            d="M.5,41.557c-.825-.447-.374-1.546-.374-1.546l2.152-5.943V14.252H2.248A1.452,1.452,0,0,1,1.66,14c-.227-.188-1.5-1.4-1.61-1.989s0-8.917,0-8.917A3.364,3.364,0,0,1,3.693,0,3.355,3.355,0,0,1,7.334,3.1s.128,8.156,0,8.841S5.908,13.815,5.729,14a1,1,0,0,1-.44.249H5.107V34.273l1.878,3.208A1.654,1.654,0,0,1,6.8,38.956a2.159,2.159,0,0,1-1.56.408,2.837,2.837,0,0,0-1.58.37A7.539,7.539,0,0,0,2.25,40.979a2.143,2.143,0,0,1-1.306.692A.925.925,0,0,1,.5,41.557Z"
            transform="translate(17.611 0) rotate(25)"
            fill="none"
            stroke={color}
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Caminho_43352"
            data-name="Caminho 43352"
            d="M1.986,1.877A1.851,1.851,0,0,0,1.366.466,1.491,1.491,0,0,0,0,.03"
            transform="translate(20.238 3.113) rotate(25)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
        </g>
      </svg>
    </chakra.svg>
  )
}
