// Vendors
import { v4 } from 'uuid'
import { get } from 'lodash'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { Control, Controller, useFieldArray } from 'react-hook-form'

// Styles
import {
  Text,
  IconButton,
  Stack,
  SimpleGrid,
  Box,
  StackProps,
} from '@chakra-ui/react'
import { FiMinus, FiPlus } from 'react-icons/fi'

const TextEditor = React.lazy(() => import('components/TextEditor'))

// Interfaces
export type FieldRepeaterProps = {
  name: string
  control: Control
  error?: any
  register: any
  gridProps: any
  defaultValue: any
  label?: string
  fieldComponents: {
    component: ReactNode
    type: 'register' | 'control' | 'ckEditor'
    props: any
  }[]
  startWith?: number
  containerProps?: StackProps
}

export const FieldRepeater = (props: FieldRepeaterProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    name,
    error,
    control,
    label,
    register,
    fieldComponents,
    defaultValue,
    gridProps,
    startWith = 0,
    containerProps,
  } = props
  const { fields, append, remove } = useFieldArray({ name, control })

  const [defaultStartWith, setDefaultStartWith] = useState(startWith)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const renderRegisterComponent = useCallback(
    (Component, props, index) => {
      const componentError = get(error, `${index}.${props.name}`)

      return (
        <Box key={v4()} {...props.box}>
          <Component
            {...props}
            error={componentError}
            {...register(`${name}.${index}.${props.name}`)}
          />
        </Box>
      )
    },
    [error, register, name]
  )

  const renderControlComponent = useCallback(
    (Component, props, index) => {
      const componentError = get(error, `${index}.${props.name}`)

      return (
        <Box key={v4()} {...props.box}>
          <Controller
            name={`${name}.${index}.${props.name}`}
            control={control}
            render={({ field }) => (
              <Component error={componentError} {...field} {...props} />
            )}
          />
        </Box>
      )
    },
    [control, error, name]
  )

  const handleRemove = (index: number) => {
    remove(index)
    setDefaultStartWith(defaultStartWith - 1)
  }

  const generateFieldsArray = (startWith: number) => {
    return Array.from(Array(startWith).keys()).map((_, index) => {
      return index + 1
    })
  }

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (defaultStartWith > 0) {
      if (fields.length < defaultStartWith) {
        generateFieldsArray(defaultStartWith)
          .splice(0, 1)
          .forEach((_) => {
            append(defaultValue)
          })
      }
    }
  }, [append, defaultValue, fields, defaultStartWith])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Stack>
      <Stack direction="row" align="center">
        <Text fontWeight="bold" fontSize={20} mr={4}>
          {label}
        </Text>
        <IconButton
          size="xs"
          variant="outline"
          colorScheme="blue"
          borderWidth="2px"
          borderRadius="full"
          icon={<FiPlus color="blue" />}
          aria-label="Adicionar desconto progressivo"
          onClick={() => append(defaultValue)}
        />
      </Stack>

      {!!error && (
        <Text color="red.500" fontSize="sm">
          {error.message}
        </Text>
      )}

      <Stack {...containerProps}>
        {fields?.map((field, index) => (
          <SimpleGrid key={field.id} position="relative" pr="10" {...gridProps}>
            {fieldComponents.map((fieldComponent) => {
              if (fieldComponent.type === 'register') {
                return renderRegisterComponent(
                  fieldComponent.component,
                  fieldComponent.props,
                  index
                )
              }

              if (fieldComponent.type === 'control') {
                return renderControlComponent(
                  fieldComponent.component,
                  fieldComponent.props,
                  index
                )
              }

              if (fieldComponent.type === 'ckEditor') {
                return (
                  <Box key={v4()} mb="4" gridColumn={'1 / 3'}>
                    <Controller
                      name={`${name}.${index}.${fieldComponent.props.name}`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextEditor
                            {...field}
                            label={fieldComponent.props.label}
                            data={field.value}
                            onChange={(
                              _: any,
                              editor: { getData: () => string }
                            ) => field.onChange(editor.getData())}
                            {...fieldComponent.props}
                          />
                        )
                      }}
                    />
                  </Box>
                )
              }

              return null
            })}
            <IconButton
              size="xs"
              position="absolute"
              right="0"
              top="50%"
              transform="translateY(-50%)"
              variant="outline"
              colorScheme="red"
              borderWidth="2px"
              borderRadius="full"
              onClick={() => handleRemove(index)}
              icon={<FiMinus color="red" />}
              aria-label="Remove desconto progressivo"
            />
          </SimpleGrid>
        ))}
      </Stack>
    </Stack>
  )
}
