// Vendors
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form'

// Functions
import { useEditRecords } from 'hooks/useEditRecord'
import { useFormTransform } from 'hooks/useFormTransform'

// Components
import { FieldText } from 'components/FieldText'
import { FieldMask } from 'components/FieldMask'
import { FieldDate } from 'components/FieldDate'
import { FormFooter } from 'components/FormFooter'
import { FieldStatus } from 'components/FieldStatus'
import { FieldRadioButton } from 'components/FieldRadioButton'

// Schemas
import { schema } from '../schema'

// Styles
import { Box, Flex, Grid, GridItem, useToast } from '@chakra-ui/react'

// Types
import { InfluencerShowForm, InfluencerShowRecord } from '../types'
import { User } from 'types/Person'

type TabShowInfluencerProps = {
  id: string
  endpoint: string
}

export const TabInfluencerShow = (props: TabShowInfluencerProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id, endpoint } = props

  const { t } = useTranslation('influencer')

  const { record } = useEditRecords<User, InfluencerShowRecord>({
    endpoint,
    currentId: id,
  })
  const { statusParser } = useFormTransform()
  const recordCurrent = record.current

  const toast = useToast({ position: 'top-right' })

  const methods = useForm<InfluencerShowForm>({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    methods.reset({ ...recordCurrent })
  }, [methods, recordCurrent])

  useEffect(() => {
    const status = statusParser(recordCurrent.user?.roles[0]?.status)

    methods.reset({
      ...recordCurrent,
      status,
    })
  }, [methods, recordCurrent, statusParser])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const genresOptions = useMemo(
    () => [
      {
        label: t('form.gender.options.male'),
        value: 'M',
      },
      {
        label: t('form.gender.options.female'),
        value: 'F',
      },
    ],
    [t]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<InfluencerShowRecord> = React.useCallback(
    async (data) => {
      try {
        await record.update(data)

        toast({
          title: t('toast.success.title', {
            type: t('common:toast.success.update'),
          }),
          status: 'success',
        })
      } catch (error) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [methods, record, t, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      as="form"
      h="full"
      position="relative"
      flexDirection="column"
      justifyContent="space-between"
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Box position="absolute" top="-5" right="0">
        <FieldStatus
          methods={methods}
          disable={record.disable}
          enable={record.enable}
        />
      </Box>

      <Grid
        my="4"
        gap="10"
        templateColumns={{ base: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
      >
        <GridItem colSpan={2}>
          <FieldText
            label={t('form.name.label')}
            error={methods.formState.errors.name}
            isRequired
            {...methods.register('name')}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <FieldText
            label={t('form.email.label')}
            error={methods.formState.errors.email}
            isRequired
            {...methods.register('email')}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Controller
            name="phoneNumber"
            control={methods.control}
            render={({ field }) => (
              <FieldMask
                mask="+99 (99) 9 9999-9999"
                label={t('form.phoneNumber.label')}
                error={methods.formState.errors.phoneNumber}
                isRequired
                {...field}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <FieldRadioButton
            label={t('form.gender.label')}
            name="gender"
            control={methods.control}
            options={genresOptions}
            radioButtonDirection="row"
            isRequired
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Controller
            name="birthdate"
            control={methods.control}
            render={({ field }) => (
              <FieldDate
                dateFormat="dd/MM/yyyy"
                label={t('form.birthdate.label')}
                maxDate={new Date()}
                peekNextMonth
                showYearDropdown
                showMonthDropdown
                name={field.name}
                dropdownMode="select"
                selected={field.value}
                onChange={field.onChange}
                error={methods.formState.errors.birthdate as FieldError}
                isRequired
              />
            )}
          />
        </GridItem>
      </Grid>

      <FormFooter
        recordDelete={record.delete}
        isSubmitting={methods.formState.isSubmitting}
      />
    </Flex>
  )
}
