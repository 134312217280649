// Vendors
import { FieldError } from 'react-hook-form'
import React, { forwardRef, ForwardRefRenderFunction } from 'react'

// Components

// Styles
import { colorStyles, colorStylesError } from './styles'
import ReactSelect, { Props as ReactSelectProps } from 'react-select'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react'

// Interfaces
export type FieldSelectColorProps = ReactSelectProps & {
  name: string
  label?: string
  error?: FieldError
  isRequired?: boolean
}

const FieldSelectColorBase: ForwardRefRenderFunction<
  any,
  FieldSelectColorProps
> = (props, ref): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { name, label, error, isRequired, ...rest } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel htmlFor={name}>
          {label}{' '}
          {isRequired && (
            <Text
              as="span"
              color="red.500"
              ml={1}
              fontWeight="bold"
              userSelect="none"
            >
              *
            </Text>
          )}
        </FormLabel>
      )}

      <ReactSelect
        id={name}
        name={name}
        ref={ref}
        menuPlacement={'bottom'}
        styles={{ ...colorStyles, ...(error ? colorStylesError : {}) }}
        {...rest}
      />

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const FieldSelectColor = forwardRef(FieldSelectColorBase)
