import { StylesConfig } from 'react-select'

export const colorStylesError: StylesConfig = {
  control: (styles) => ({
    ...styles,
    borderColor: 'var(--chakra-colors-red-500)',
    borderRadius: '0.375rem',
    outline: '2px solid transparent',
    boxShadow: '0 0 0 1px var(--chakra-colors-red-500)',
  }),
}

export const colorStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    borderRadius: '0.375rem',
    borderColor: 'inherit',
    outline: '2px solid transparent',
    minHeight: '2.5rem',
    '&:hover, &:focus': {
      boxShadow: '0 0 0 1px var(--chakra-colors-blue-500)',
      borderColor: 'var(--chakra-colors-blue-500)',
    },
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: 'var(--chakra-colors-blue-500)',
      borderRadius: '0.25rem',
    }
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'var(--chakra-colors-white)',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'var(--chakra-colors-white)',
    ':hover': {
      filter: 'brightness(0.85)',
      color: 'white',
    },
  }),
}
