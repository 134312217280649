import { get } from 'lodash'
import React, { useCallback } from 'react'

import { SiBrandfolder } from 'react-icons/si'
import { useDisclosure } from '@chakra-ui/react'

import { ActionProps } from './types'
import { WrapperAction } from './WrapperAction'
import { ModalAlterCobrandingStatus } from 'components/ModalAlterCobrandingStatus'

export const AlterCobrandingStatusAction = <T extends unknown>({
  record,
  options,
}: ActionProps<T>) => {
  const modalAlterCobrandingStatusDisclosure = useDisclosure()

  const { callback, actionType = 'menu', reload, ...optionsRest } = options

  if (!reload) {
    throw new Error('reload is required')
  }

  const purchaseId = get(record, 'id')

  const cta = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, data: T) => {
      modalAlterCobrandingStatusDisclosure.onOpen()

      if (callback) {
        callback(e, data)
      }
    },
    [modalAlterCobrandingStatusDisclosure, callback]
  )

  return (
    <>
      <ModalAlterCobrandingStatus
        reload={reload}
        purchaseId={purchaseId}
        isOpen={modalAlterCobrandingStatusDisclosure.isOpen}
        onCancel={modalAlterCobrandingStatusDisclosure.onClose}
      />

      <WrapperAction
        cta={cta}
        record={record}
        icon={SiBrandfolder}
        options={optionsRest}
        actionType={actionType}
        label="Mudar status de cobranding"
      />
    </>
  )
}
