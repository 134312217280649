// Vendors
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

// Functions

// Components
import { TabDetail, Tabs } from 'components/Tabs'
import { TabCouponNew } from './tabs/TabCouponNew'

// Styles
import { Flex } from '@chakra-ui/react'

// Types

type CouponFormNewProps = {
  endpoint: string
}

export const CouponFormNew = (props: CouponFormNewProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('common')
  const { endpoint } = props

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: t('companies.tab_list.general'),
        component: TabCouponNew,
        options: { endpoint },
      },
    ],
    [t, endpoint]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex h="full" flexDirection="column" justifyContent="space-between">
      <Tabs tabsDetails={tabsDetails} />
    </Flex>
  )
}
