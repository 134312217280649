import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

export const GeneticsTwo = (
  props: HTMLChakraProps<'svg'> & {
    iconColor?: string
    colorStopInit: string
    colorStopEnd: string
  }
) => {
  const { iconColor, colorStopInit, colorStopEnd, ...rest } = props
  const color = iconColor || 'white'
  return (
    <chakra.svg
      id="Componente_55_1"
      data-name="Componente 55 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="53.73"
      height="53.73"
      viewBox="0 0 53.73 53.73"
      {...rest}
    >
      <svg>
        <path
          id="Caminho_11"
          data-name="Caminho 11"
          d="M26.865,53.73A26.865,26.865,0,1,0,0,26.865,26.865,26.865,0,0,0,26.865,53.73Z"
          fill={colorStopInit}
        />
        <g
          id="Grupo_2726"
          data-name="Grupo 2726"
          transform="translate(10.709 10.9)"
        >
          <path
            id="Caminho_43088"
            data-name="Caminho 43088"
            d="M-12.3,3545.6s4.439-3.556,9.323-4.226,10.161,1.641,10.161,1.641"
            transform="translate(25.1 -3541.25)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <path
            id="Caminho_43093"
            data-name="Caminho 43093"
            d="M0,4.348S4.439.791,9.323.122s10.161,1.64,10.161,1.64"
            transform="matrix(-0.966, -0.259, 0.259, -0.966, 18.821, 34.778)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <path
            id="Caminho_43089"
            data-name="Caminho 43089"
            d="M-12.3,3545.6s4.439-3.556,9.323-4.226,10.161,1.641,10.161,1.641"
            transform="translate(25.1 -3530.737)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <path
            id="Caminho_43092"
            data-name="Caminho 43092"
            d="M0,4.348S4.439.791,9.323.122s10.161,1.64,10.161,1.64"
            transform="matrix(-0.966, -0.259, 0.259, -0.966, 18.821, 22.524)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <path
            id="Caminho_43090"
            data-name="Caminho 43090"
            d="M-12.3,3545.6s4.439-3.556,9.323-4.226,10.161,1.641,10.161,1.641"
            transform="translate(25.1 -3517.889)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <path
            id="Caminho_43091"
            data-name="Caminho 43091"
            d="M0,4.348S4.439.791,9.323.122s10.161,1.64,10.161,1.64"
            transform="matrix(-0.966, -0.259, 0.259, -0.966, 18.821, 10.113)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
          />
        </g>
      </svg>
    </chakra.svg>
  )
}
