// Vendors
import { capitalize, find, get, join, map } from 'lodash'
import { useParams } from 'react-router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

// Functions
import { apiShow } from 'services/get'
import formatCurrency from 'utils/formatCurrency'

// Components
import { TabHistory } from './tabs/TabHistory'
import { TabPayment } from './tabs/TabPayment'
import { TabDelivery } from './tabs/TabDelivery'
import { TabDetail, Tabs } from 'components/Tabs'
import { TabFinancial } from './tabs/TabFinancial'
import { PurchaseType, TabOrder } from './tabs/TabOrder'
import { ButtonCopyData } from './components/ButtonCopyData'
import { ListTableStatuses } from 'components/ListTableRowComponent'
import { ModalShowEditOrder } from 'components/ModalShowEditOrder'

// Styles
import { Box, Button, useDisclosure } from '@chakra-ui/react'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Types
import { PageMetaSetting } from './meta'
import { PurchaseShow } from './types'

export const PageOrderShowRecord = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id } = useParams<{ id: string }>()
  const { title } = PageMetaSetting

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [order, setOrder] = useState<PurchaseShow | undefined>(undefined)
  const disclosureShowEditOrder = useDisclosure()

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  const getOrder = useCallback(async () => {
    const response = await apiShow<PurchaseShow>(`/app/purchase/${id}`)

    if (!response) return

    setOrder(response)
  }, [id])

  useEffect(() => {
    getOrder()
  }, [getOrder, id])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sanitize = useMemo(() => {
    if (!order) return ''

    const purchaseNumber = get(order, 'purchaseNumber', '[ALERTA]')
    const purchaseType = get(
      PurchaseType,
      get(order, 'purchaseType'),
      '[ALERTA]'
    )
    const isCobranding = get(order, 'isCobranding') ? 'Cobranding' : ''

    const status = get(order, 'status', '')?.toUpperCase() as string
    const statusFormatted = capitalize(
      get(ListTableStatuses, status, '[ALERTA]')
    )

    const deliveryStatus = capitalize(
      get(ListTableStatuses, get(order, 'purchaseDelivery.status'), '[ALERTA]')
    )

    const purchaseOrigin = capitalize(
      get(order, 'purchaseOrigin', '[ALERTA]') as string
    )

    const historyUpdates = get(order, 'purchaseHasProducts.0.historyUpdates')
    const userName = get(
      find(historyUpdates, { type: 'KIT_NEW' }),
      'updatedByPersonalDataName',
      '[ALERTA]'
    )

    const user = get(order, 'user.username', '[ALERTA]')

    const kits = map(order.purchaseHasProducts, (purchaseHasProduct, index) => {
      const position = index + 1
      const originActivation =
        capitalize(get(purchaseHasProduct, 'originActivation', '[ALERTA]')) ||
        '[ALERTA]'

      const activationCode = get(
        purchaseHasProduct,
        'activationCode',
        '[ALERTA]'
      )

      const activationByPersonalDataEmail = get(
        purchaseHasProduct,
        'activationByPersonalData.email',
        '[ALERTA]'
      )

      const kitStatus = capitalize(
        get(ListTableStatuses, get(purchaseHasProduct, 'status'), '[ALERTA]')
      )

      const activationForPersonalDataEmail = get(
        purchaseHasProduct,
        'activationForPersonalData.email',
        '[ALERTA]'
      )

      return `Kit ${position}: ${activationCode}
${
  kitStatus !== ListTableStatuses.PENDING_ACTIVATION
    ? `Ativado via ${originActivation} por ${activationByPersonalDataEmail}
Status ${kitStatus}
${
  purchaseType === 'B2B'
    ? `Médico ${activationByPersonalDataEmail}
Paciente ${activationForPersonalDataEmail}`
    : `Paciente ${activationForPersonalDataEmail}`
}
`
    : `Status ${kitStatus}`
}
`
    }).join('\n')

    return `Pedido #${purchaseNumber} ${purchaseType} ${isCobranding}
${statusFormatted}
Status ${deliveryStatus}
Realizado via ${purchaseOrigin} por ${userName}
Para o cliente ${user}

${kits}`
  }, [order])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const orderDetails = useMemo(() => {
    if (!order) return undefined

    const {
      purchaseNumber,
      createdAt,
      price,
      user,
      purchaseHasProducts,
      purchaseDelivery,
      documentNumber,
      companyClinic,
    } = order

    let status = get(purchaseDelivery, 'status') || get(order, 'status')

    if (get(order, 'status') === 'refused') {
      status = get(order, 'status')
    }

    return {
      user,
      companyClinic,
      order: {
        createdAt,
        price,
        isCobranding: order.isCobranding,
        purchaseType: order.purchaseType,
        purchaseOrigin: order.purchaseOrigin?.toUpperCase(),
        totalQuantity: purchaseHasProducts.length,
        documentNumber: documentNumber || user?.person?.documentNumber,
        purchaseNumber,
        status: status?.toUpperCase(),
      },
      purchaseHasProducts: {
        list: purchaseHasProducts.map((product) => {
          const analysisReportFiles = map(
            product.purchaseHasProductHasAnalysis,
            'analysisReportFiles'
          )[0]

          const laboratoryAnalysis = map(
            product.purchaseHasProductHasAnalysis,
            'laboratoryAnalysis'
          )

          const laboratoryAnalysisNames = map(laboratoryAnalysis, 'name')

          const laboratoryAnalysisNamesString = join(
            laboratoryAnalysisNames,
            ', '
          )

          return {
            id: product.id,
            unitPrice: product.unitPrice
              ? formatCurrency(+product.unitPrice)
              : '—',
            discountApplied: product.discountApplied
              ? formatCurrency(+product.discountApplied)
              : '—',
            purchaseHasProductHasAnalysis:
              product?.purchaseHasProductHasAnalysis,
            hasReportFile: analysisReportFiles?.length > 0,
            status: product.status,
            hasActivatedAt: !!product.activatedAt,
            patient:
              product.activationForPersonalData?.socialName ||
              product.activationForPersonalData?.name,
            laboratoryAnalysisNamesString: laboratoryAnalysisNamesString,
            activationCode: product.activationCode,
            sampleTransportKit: product?.storeProduct?.sampleTransportKit?.name,
          }
        }),
      },
    }
  }, [order])

  const delivery = useMemo(() => {
    if (!order) return null

    const {
      shippingValue,
      shippingMethod,
      deliveryAddress,
      purchaseDelivery,
      estimatedDeliveryDays,
      user,
    } = order

    const deliveryInfo = {
      shippingMethodId: shippingMethod.id,
      shippingValue,
      estimatedDeliveryDays,
      serviceName: shippingMethod.serviceName,
      providerName: shippingMethod.providerName,
      deliveryDate: shippingMethod?.deliveryDate,
      deliveryTrackingCode: purchaseDelivery?.deliveryTrackingCode,
    }

    return {
      user,
      deliveryAddress,
      deliveryInfo,
    }
  }, [order])

  const billingData = useMemo(() => {
    if (!order) return null
    const { billingData } = order

    if (!billingData) return null

    billingData.paymentMethod = order.paymentMethod.name
    return { billingData }
  }, [order])

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Pedido',
        component: TabOrder,
        options: {
          recordReload: getOrder,
          orderDetails,
        },
      },
      {
        title: 'Envio',
        component: TabDelivery,
        options: {
          delivery,
        },
      },
      {
        title: 'Financeiro',
        component: TabFinancial,
        options: { order },
      },
      {
        title: 'Pagamento',
        component: TabPayment,
        options: {
          paymentMethod: order?.paymentMethod,
          paymentLink: order?.paymentLink,
          paymentStatus: order?.status,
          billingData: billingData?.billingData,
        },
      },
      {
        title: 'Histórico',
        component: TabHistory,
        options: {
          reload: getOrder,
          purchaseId: order?.id,
          purchaseNumber: order?.purchaseNumber,
          purchaseHistory: order?.historyUpdates,
          products: order?.purchaseHasProducts,
        },
      },
    ],
    [order, getOrder, orderDetails, delivery, billingData?.billingData]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={`${title} #${order && order.purchaseNumber}`}>
      <Box position="relative">
        <ModalShowEditOrder
          reload={getOrder}
          isOpen={disclosureShowEditOrder.isOpen}
          onCancel={disclosureShowEditOrder.onClose}
          purchaseId={id}
          delivery={delivery}
        />
        {order && (
          <Box position="absolute" top="-3.5rem" left="13rem">
            <ButtonCopyData text={sanitize} />
            <Button
              w="32"
              size="sm"
              onClick={disclosureShowEditOrder.onOpen}
              variant="outline"
              colorScheme="blue"
              ml="2"
            >
              EDITAR
            </Button>
          </Box>
        )}
        <Tabs tabsDetails={tabsDetails} />
      </Box>
    </LayoutLoggedPageList>
  )
}
