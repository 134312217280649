import { UserRolesNames } from 'types/User'

export const RULES = [
  {
    name: 'pacient',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.SUPERADMIN,
      UserRolesNames.BACKOFFICE,
    ],
  },
  {
    name: 'services',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.SUPERADMIN,
      UserRolesNames.BACKOFFICE,
    ],
  },
  {
    name: 'professional',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.SUPERADMIN,
      UserRolesNames.BACKOFFICE,
    ],
  },
  {
    name: 'order',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.SUPERADMIN,
      UserRolesNames.BACKOFFICE,
    ],
  },
  {
    name: 'editDeadlineForRelease',
    allowedRoles: [
      UserRolesNames.ADMIN,
      UserRolesNames.SUPERADMIN,
      UserRolesNames.LABORATORY_MANAGER,
    ],
  },
]
