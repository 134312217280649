// Vendors
import { find, get } from 'lodash'
import React, { useEffect, useCallback } from 'react'
import { Controller, FieldError, useFormContext } from 'react-hook-form'

// Hooks
import { useAddress } from 'hooks/useAddress'
import { useCepPromise } from 'hooks/useCepPromise'
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FieldSet } from '../../FieldSet'
import { FieldMask } from 'components/FieldMask'
import { FieldText } from 'components/FieldText'
import { FieldSelect } from 'components/FieldSelect'

// Styles
import { Grid, GridItem, IconButton } from '@chakra-ui/react'

// Types
import { FiSearch } from 'react-icons/fi'
import { DeliveryAddressType } from 'pages/private/Orders/show/types'

export type FormAddressType = {
  street: string
  number: string
  cityId?: { value: string; label: string }
  stateId?: { value: string; label: string }
  postalCode: string
  complement: string
  neighborhood: string
  referencePoint: string
}

interface FormAddressProps {
  deliveryAddress?: DeliveryAddressType
}

export const FormAddress = (props: FormAddressProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { deliveryAddress } = props
  const {
    watch,
    control,
    setValue,
    setError,
    setFocus,
    register,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext<FormAddressType>()

  const watchState = watch('stateId')

  const cityId = getValues('cityId')
  const stateId = getValues('stateId')

  const { fetchCEP } = useCepPromise()
  const { getState, getCity } = useAddress()

  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectStates = useSelectParser({
    endpoint: '/app/state',
    accessor: { label: 'abbreviatedName', value: 'id' },
    requestWithoutMeta: true,
  })

  const selectCities = useSelectParser({
    endpoint: watchState?.label ? `/app/city?state=${watchState.label}` : '',
    accessor: { label: 'name', value: 'id' },
    requestWithoutMeta: true,
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleAddPostalCode = useCallback(async () => {
    let postalCode = watch('postalCode')

    if (!postalCode) return

    postalCode = postalCode.replace(/\D/g, '')
    if (postalCode.length !== 8) return

    try {
      const response = await fetchCEP(postalCode)

      if (response) {
        const stateOption = getState(response.state)
        const cityOption = await getCity(response.city)

        setValue(`street`, response.street)
        setValue(`neighborhood`, response.neighborhood)
        setValue(`stateId`, stateOption)
        setValue(`cityId`, cityOption)

        setFocus(`number`)
      }
    } catch (error) {
      console.error(error)
      setError(`postalCode`, { message: 'CEP inválido' })
    }
  }, [watch, fetchCEP, getState, getCity, setValue, setFocus, setError])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!stateId) return
    const state = find(selectStates.options, { value: stateId })

    if (!state) return
    setValue('stateId', state)
  }, [cityId, stateId, setValue, selectStates.options, selectCities.options])

  useEffect(() => {
    if (!cityId) return
    const city = find(selectCities.options, { value: cityId })

    if (!city) return
    setValue('cityId', city)
  }, [cityId, selectCities.options, setValue])

  useEffect(() => {
    if (!deliveryAddress) return

    const {
      city,
      complement,
      neighborhood,
      postalCode,
      referencePoint,
      stateAbbreviatedName,
      streetName,
      streetNumber,
    } = deliveryAddress

    const stateOption = find(selectStates.options, {
      label: stateAbbreviatedName,
    })
    const cityOption = find(selectCities.options, {
      label: city,
    })

    reset({
      postalCode,
      neighborhood,
      referencePoint: referencePoint || undefined,
      number: streetNumber,
      street: streetName,
      complement: complement || undefined,
      stateId: stateOption,
      cityId: cityOption,
    })
  }, [reset, selectStates.options, selectCities.options, deliveryAddress])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FieldSet title="Endereço">
      <Grid my="4" gap="10" templateColumns={{ xl: 'repeat(3, 1fr)' }}>
        <GridItem>
          <Controller
            name="postalCode"
            control={control}
            render={({ field }) => (
              <FieldMask
                label="CEP"
                mask="99999-999"
                error={errors.postalCode}
                inputRightElement={
                  <IconButton
                    size="sm"
                    variant="ghost"
                    transition="all 0.2s"
                    aria-label="Buscar CEP"
                    icon={<FiSearch />}
                    onClick={handleAddPostalCode}
                  />
                }
                {...field}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={{ lg: 2 }}>
          <FieldText
            maxLength={120}
            currentLength={get(watch('street'), 'length')}
            label="Logradouro"
            error={errors.street}
            {...register('street')}
          />
        </GridItem>

        <GridItem>
          <FieldText
            maxLength={8}
            currentLength={get(watch('number'), 'length')}
            label="Número"
            error={errors.number}
            {...register('number')}
          />
        </GridItem>

        <GridItem>
          <FieldText
            maxLength={50}
            currentLength={get(watch('complement'), 'length')}
            label="Complemento"
            error={errors.complement}
            {...register('complement')}
          />
        </GridItem>

        <GridItem>
          <FieldText
            maxLength={50}
            currentLength={get(watch('referencePoint'), 'length')}
            label="Ponto de referência"
            error={errors.referencePoint}
            {...register('referencePoint')}
          />
        </GridItem>

        <GridItem colSpan={{ lg: 2 }}>
          <FieldText
            maxLength={120}
            currentLength={get(watch('neighborhood'), 'length')}
            label="Bairro"
            error={errors.neighborhood}
            {...register('neighborhood')}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="stateId"
            control={control}
            render={({ field }) => (
              <FieldSelect
                label="UF"
                error={errors.stateId as FieldError}
                isLoading={false}
                options={selectStates.options}
                {...field}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={{ lg: 3 }}>
          <Controller
            name="cityId"
            control={control}
            render={({ field }) => (
              <FieldSelect
                label="Cidade"
                error={errors.cityId as FieldError}
                isLoading={false}
                options={selectCities.options}
                {...field}
              />
            )}
          />
        </GridItem>
      </Grid>
    </FieldSet>
  )
}
