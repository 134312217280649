import { useDisclosure } from '@chakra-ui/react'
import { ModalAlterPurchaseStatus } from 'components/ModalAlterPurchaseStatus'
import { get } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { ActionProps } from './types'
import { WrapperAction } from './WrapperAction'

export const AlterPurchaseStatusAction = <T extends unknown>({
  record,
  options,
}: ActionProps<T>) => {
  const modalAlterPurchaseStatusDisclosure = useDisclosure()

  const { callback, actionType = 'menu', reload, ...optionsRest } = options

  if (!reload) {
    throw new Error('reload is required')
  }

  const purchaseDeliveryId = get(record, 'purchaseDeliveryId')

  const tooltipLabel = useMemo(() => {
    if (purchaseDeliveryId) return

    optionsRest.isDisabled = true

    return 'Enquanto o pagamento não for confirmado, não poderá alterar o status da entrega.'
  }, [optionsRest, purchaseDeliveryId])

  const cta = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, data: T) => {
      modalAlterPurchaseStatusDisclosure.onOpen()

      if (callback) {
        callback(e, data)
      }
    },
    [modalAlterPurchaseStatusDisclosure, callback]
  )

  return (
    <>
      <ModalAlterPurchaseStatus
        reload={reload}
        purchaseDeliveryId={purchaseDeliveryId}
        isOpen={modalAlterPurchaseStatusDisclosure.isOpen}
        onCancel={modalAlterPurchaseStatusDisclosure.onClose}
      />

      <WrapperAction
        cta={cta}
        record={record}
        options={optionsRest}
        tooltip={tooltipLabel}
        actionType={actionType}
        label="Mudar status do pedido"
        tooltipProps={{
          shouldWrapChildren: true,
        }}
      />
    </>
  )
}
