import { TFunction } from 'react-i18next'
import { yupSchema } from 'utils/yupSchema'

import * as yup from 'yup'

export const schema = (t: TFunction<'common'>) => {
  yupSchema(t)

  return yup.object().shape({
    activationCode: yup
      .string()
      .required()
      .label(t('drawer.changeCode.form.activateCode.label')),
  })
}
