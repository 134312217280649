import { chakra, HTMLChakraProps, useToken } from '@chakra-ui/react'
import * as React from 'react'

export const CapillaryBloodCollection = (
  props: HTMLChakraProps<'svg'> & {
    iconColor?: string
    colorStopInit: string
    colorStopEnd: string
  }
) => {
  const { iconColor, colorStopInit, colorStopEnd, ...rest } = props
  const color = iconColor || 'white'
  return (
    <chakra.svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="url(#paint0_linear_194:721)"
      />
      <path
        d="M28.0902 21.873C28.0917 23.8067 27.3877 25.6744 26.1102 27.126C25.348 27.9959 24.4084 28.6925 23.3545 29.1689C22.3005 29.6454 21.1568 29.8905 20.0002 29.888C18.9402 29.9021 17.888 29.705 16.905 29.3081C15.9219 28.9112 15.0278 28.3226 14.2746 27.5765C13.5215 26.8305 12.9245 25.9419 12.5183 24.9626C12.1122 23.9834 11.9052 22.9331 11.9092 21.873C11.9092 17.646 16.0922 12.357 19.1092 9.36401C19.3463 9.13174 19.6649 9.00165 19.9967 9.00165C20.3286 9.00165 20.6472 9.13174 20.8842 9.36401C21.1012 9.57801 21.3232 9.804 21.5482 10.039C21.7732 10.274 22.0162 10.532 22.2532 10.793L22.3952 10.95C22.4952 11.055 22.5852 11.163 22.6812 11.271L22.8252 11.435C22.9686 11.5997 23.1116 11.7663 23.2542 11.935L23.3962 12.106C23.4282 12.143 23.4572 12.179 23.4872 12.216L23.5222 12.257L23.5312 12.269C25.9062 15.157 28.0902 18.74 28.0902 21.873Z"
        stroke={color}
        strokeWidth="1.6"
      />
      <defs>
        <linearGradient
          id="paint0_linear_194:721"
          x1="34.68"
          y1="-1.09564e-06"
          x2="5.48"
          y2="36.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colorStopInit} />
          <stop offset="1" stopColor={colorStopEnd} />
        </linearGradient>
      </defs>
    </chakra.svg>
  )
}
