// Vendors
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

// Functions
import { useAuth } from 'contexts/auth'

// Styles
import {
  Menu,
  MenuItem,
  MenuList,
  useColorModeValue,
  Box,
  Flex,
  HStack,
  MenuButton,
  Spacer,
  Text,
  Avatar,
  IconButton,
  Circle,
  Button,
} from '@chakra-ui/react'

import { IoMdNotificationsOutline } from 'react-icons/io'
import { useNotification } from 'contexts/notification'
import { UserRolesNames } from 'types/User'

// Interfaces
export interface INavUser {
  bar?: string
}

const MAP_ROLES_NAMES = {
  [UserRolesNames.SUPERADMIN]: 'superadmin',
  [UserRolesNames.ADMIN]: 'admin',
  [UserRolesNames.SELLER]: 'vendas',
  [UserRolesNames.BACKOFFICE]: 'helpdesk (backoffice)',
  [UserRolesNames.EXPEDITION]: 'expedição',
  [UserRolesNames.FINANCIAL]: 'financeiro',
  [UserRolesNames.CONTENT_MANAGER]: 'conteúdo',
  [UserRolesNames.LABORATORY_MANAGER]: 'gerente de lab',
  [UserRolesNames.LABORATORY_AGENT]: 'agente de lab',
}

export const NavUser: React.FC<INavUser> = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { logOut, user, userRolesNames } = useAuth()
  const history = useHistory()
  const { amountNotificationsUnread, handleToggle } = useNotification()

  const roleName = useMemo(() => {
    const findRoleName = Object.entries(MAP_ROLES_NAMES).find(([key]) => {
      return userRolesNames.find((role) => role === key)
    })

    if (!findRoleName) {
      return
    }

    return findRoleName[1]
  }, [userRolesNames])

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */
  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */
  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Menu>
      <Box position="relative">
        <MenuButton
          as={Button}
          w="full"
          alignItems="center"
          p="3"
          h="12"
          userSelect="none"
          transition="all 0.2s"
          borderRadius="md"
          bg="gray.700"
          _hover={{ filter: 'brightness(0.8)' }}
          _active={{ filter: 'brightness(1)' }}
        >
          <HStack flex="1" spacing="2" alignItems="center">
            <Avatar
              name={user?.person?.name}
              rounded="md"
              size="sm"
              // TODO: check to see how the user avatar is coming
              // src="https://avatars.githubusercontent.com/u/26548010?v=4"
            />
            <Box textAlign="start" maxWidth="60%">
              <Text
                width="100%"
                fontSize="sm"
                overflow="hidden"
                whiteSpace="nowrap"
                lineHeight="shorter"
                fontWeight="semibold"
                textOverflow="ellipsis"
              >
                {user?.person?.name}
              </Text>

              {roleName && (
                <Text
                  fontSize="xs"
                  color="gray.400"
                  lineHeight="shorter"
                  textTransform="capitalize"
                >
                  {roleName}
                </Text>
              )}
            </Box>
            <Spacer />
          </HStack>
        </MenuButton>

        <Box
          transform="translate(0, -50%)"
          top="50%"
          right="2"
          position="absolute"
        >
          <IconButton
            w="36px"
            h="36px"
            size="sm"
            bg="#233B5C"
            borderRadius="full"
            aria-label="notificação"
            className="notification-button"
            icon={
              <IoMdNotificationsOutline
                className="notification-button"
                color="#1FB7BC"
                size="24"
              />
            }
            _hover={{
              filter: 'brightness(0.9)',
            }}
            _active={{
              filter: 'brightness(1)',
            }}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()

              handleToggle()
            }}
          />

          {amountNotificationsUnread > 0 && (
            <Circle
              h="11px"
              w="11px"
              top="8px"
              right=" 5px"
              bg="#1FB7BC"
              fontSize="8px"
              position="absolute"
              fontWeight="semibold"
            >
              {amountNotificationsUnread}
            </Circle>
          )}
        </Box>
      </Box>

      <MenuList
        shadow="lg"
        py="4"
        color={useColorModeValue('gray.600', 'gray.200')}
        px="3"
      >
        {/* <MenuItem
          rounded="md"
          onClick={() => history.push(`/users/${user?.person.id}`)}
        >
          Conta
        </MenuItem> */}
        <MenuItem rounded="md" onClick={logOut}>
          Sair
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
