// Vendors
import React from 'react'

// Components
import { AlertDialog } from 'components/AlertDialog'

// Styles
import { AlertDialogBody, Text } from '@chakra-ui/react'

// Interfaces
export type AlertDialogDeleteProps = {
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => Promise<void>
  titleDialog?: string
  bodyDialog?: React.ReactNode
  isDisabled?: boolean
  isLoading?: boolean
  confirmLabel?: string
}

export const AlertDialogDelete = (
  props: AlertDialogDeleteProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    isOpen,
    onCancel,
    onConfirm,
    titleDialog,
    bodyDialog,
    isDisabled,
    isLoading,
    confirmLabel = 'Deletar',
  } = props
  const cancelRef = React.useRef<HTMLButtonElement>(null)

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <AlertDialog
      title={titleDialog || 'Deletar item'}
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelRef={cancelRef}
      confirmLabel={confirmLabel}
      confirmProps={{ colorScheme: 'red', isDisabled, isLoading }}
    >
      <AlertDialogBody>
        {bodyDialog || (
          <Text>
            Tem certeza de que deseja deletar este item?{' '}
            <Text as="strong">Esta ação não pode ser desfeita.</Text>
          </Text>
        )}
      </AlertDialogBody>
    </AlertDialog>
  )
}
