import React from 'react'
import { get } from 'lodash'

import { CardDetails } from 'components/CardDetails'

import { Box, Divider, Heading, Stack, Text, useToken } from '@chakra-ui/react'

import { MdPersonOff } from 'react-icons/md'
import { CompanyShow } from 'types/Company'
import { formatDate } from 'utils/formatDate'

type DetailsBuyerProps = {
  id: string
  email: string
  companyClinic?: CompanyShow
  purchaseOwner?: {
    name: string
    email: string
    gender: string
    createdAt: string
    birthdate: string
  }
  documentNumber?: {
    label: string
    value: string
  }
}

export const DetailsBuyer = ({
  id,
  email,
  companyClinic,
  purchaseOwner,
  documentNumber,
}: DetailsBuyerProps) => {
  const gray300 = useToken('colors', 'gray.300')

  return (
    <>
      {!purchaseOwner && (
        <Box
          as="section"
          w="full"
          h="full"
          bg="white"
          shadow="base"
          rounded={{ md: 'lg' }}
        >
          <Box px="6" py="4">
            <Heading fontSize="lg">Detalhes do comprador</Heading>
          </Box>

          <Divider />

          <Stack
            w="full"
            h="full"
            py="10"
            spacing="16"
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Box>
              <MdPersonOff size={84} color={gray300} />
            </Box>

            <Stack spacing="5">
              <Heading color="black" fontSize="md">
                O usuário foi excluído
              </Heading>

              <Text color="gray.600" fontSize="sm">
                Para a resolução de problemas, contate o administrador com as
                informações:
              </Text>

              <Box
                px="5"
                py="2.5"
                fontSize="sm"
                color="gray.600"
                bgColor="gray.50"
                borderRadius="0.625rem"
              >
                <Text>
                  <Text as="strong">ID:</Text> {id}
                </Text>
                <Text>
                  <Text as="strong">E-mail: </Text>
                  {email}
                </Text>
              </Box>
            </Stack>
          </Stack>
        </Box>
      )}

      {companyClinic && (
        <CardDetails
          title="Detalhes do comprador"
          data={[
            {
              label: 'Data registro',
              value: formatDate(companyClinic?.createdAt, 'dd/MM/yyyy'),
            },
            {
              label: 'Nome',
              value: companyClinic?.internalIdentification,
            },
            {
              label: 'CNPJ',
              value: get(
                companyClinic,
                'companyData.companyHasDocuments[0].documentNumber'
              ),
            },
            {
              label: 'Email',
              value: get(purchaseOwner, 'email'),
            },
          ]}
        />
      )}

      {purchaseOwner && !companyClinic && (
        <CardDetails
          title="Detalhes do comprador"
          data={[
            {
              label: 'Data registro',
              value: purchaseOwner?.createdAt,
            },
            {
              label: 'Nome',
              value: purchaseOwner?.name,
            },
            {
              label: 'Nascimento',
              value: purchaseOwner?.birthdate,
            },
            {
              label: documentNumber?.label || 'CPF',
              value: documentNumber?.value,
            },
            {
              label: 'Email',
              value: purchaseOwner?.email,
            },
            {
              label: 'Gênero',
              value: purchaseOwner?.gender,
            },
          ]}
        />
      )}
    </>
  )
}
