import React from 'react'
import { Link } from 'react-router-dom'

import { useSelectParser } from 'hooks/useSelectParser'

import {
  Menu,
  Button,
  Tooltip,
  MenuItem,
  MenuList,
  MenuButton,
  IconButton,
  ButtonGroup,
} from '@chakra-ui/react'
import { FiChevronDown, FiPlus } from 'react-icons/fi'

type DuplicateFromProps = {
  href: string
  endpoint: string
  accessor: { label: string; value: string }
}

export const DuplicateFrom = (props: DuplicateFromProps) => {
  const { href, endpoint, accessor } = props

  const selectDuplicateFrom = useSelectParser({
    endpoint,
    accessor,
  })

  return (
    <ButtonGroup isAttached variant="outline" size="sm">
      <Button
        as={Link}
        to={href}
        borderRightWidth="0"
        borderRightRadius="0"
        leftIcon={<FiPlus />}
      >
        Adicionar
      </Button>

      <Menu isLazy>
        <Tooltip
          label="Adicionar como cópia de..."
          placement="bottom-start"
          hasArrow
        >
          <MenuButton
            as={IconButton}
            borderLeftRadius="0"
            icon={<FiChevronDown />}
            isLoading={selectDuplicateFrom.isLoading}
          />
        </Tooltip>

        <MenuList overflowY="scroll" h="64">
          {selectDuplicateFrom.options?.map((option) => (
            <MenuItem
              key={option.value as string}
              as={Link}
              to={{ pathname: href, search: `?duplicate_from=${option.value}` }}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </ButtonGroup>
  )
}
