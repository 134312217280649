import React from 'react'

import { chakra, HTMLChakraProps } from '@chakra-ui/react'

export type IconProps = HTMLChakraProps<'svg'>

export const PinSolid = (props: IconProps) => {
  return (
    <chakra.svg
      width="14px"
      height="15px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.137 3.424a1.446 1.446 0 0 0-2.33.404L5.67 6.226a.779.779 0 0 1-.424.393l-1.838.707a.779.779 0 0 0-.271 1.277l1.394 1.394L2 12.528V13h.472l2.531-2.532 1.394 1.394a.778.778 0 0 0 1.277-.271l.707-1.838a.78.78 0 0 1 .393-.424l2.399-1.136a1.446 1.446 0 0 0 .403-2.33l-2.44-2.44Z"
        fill="#1E1F1F"
      />
    </chakra.svg>
  )
}
