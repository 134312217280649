import React, { useCallback } from 'react'
import { get } from 'lodash'

import { ModalAlterActivationCode } from 'components/ModalAlterActivationCode'

import { useDisclosure } from '@chakra-ui/react'

import { ActionProps } from './types'
import { WrapperAction } from './WrapperAction'
import { FiRepeat } from 'react-icons/fi'

export const AlterActivationCodeAction = <T extends unknown>({
  record,
  options,
}: ActionProps<T>) => {
  const modalAlterActivationCodeDisclosure = useDisclosure()

  const { callback, actionType = 'menu', reload, ...optionsRest } = options

  if (!reload) {
    throw new Error('reload is required')
  }

  const cta = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, data: T) => {
      modalAlterActivationCodeDisclosure.onOpen()

      if (callback) {
        callback(e, data)
      }
    },
    [modalAlterActivationCodeDisclosure, callback]
  )

  return (
    <>
      <ModalAlterActivationCode
        reload={reload}
        isOpen={modalAlterActivationCodeDisclosure.isOpen}
        onCancel={modalAlterActivationCodeDisclosure.onClose}
        purchaseHasProductId={get(record, 'purchaseHasProductId')}
      />

      <WrapperAction
        cta={cta}
        record={record}
        icon={FiRepeat}
        options={optionsRest}
        actionType={actionType}
        label="Trocar ID do kit"
      />
    </>
  )
}
