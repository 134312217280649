// Vendors
import { Link } from 'react-router-dom'
import React, { useCallback, useMemo } from 'react'

// Functions
import formatCurrency from 'utils/formatCurrency'

// Components
import { CardDetails } from 'components/CardDetails'

// Styles
import { FiBox } from 'react-icons/fi'
import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react'
import { get, replace } from 'lodash'

// Types
type Address = {
  city: string
  state: string
  street: string
  postalCode: string
  complement?: string
  customerName: string
}

type DeliveryInfo = {
  serviceName: string
  providerName: string
  shippingValue: number
  deliveryDate?: string
  deliveryTrackingCode?: string
  estimatedDeliveryDays?: string
}

type TabDeliveryProps = {
  delivery: {
    address: Address
    deliveryInfo: DeliveryInfo
  }
}

export const TabDelivery = (props: TabDeliveryProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { delivery } = props

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sanitizedDeliveryAddress = useMemo(() => {
    const user = get(delivery, 'user')
    const deliveryAddress = get(delivery, 'deliveryAddress')

    if (!deliveryAddress) return

    const postalCodeFormatted = replace(
      get(deliveryAddress, 'postalCode'),
      /(\d{5})(\d{3})/,
      '$1-$2'
    )

    return {
      postalCode: postalCodeFormatted,
      city: get(deliveryAddress, 'city'),
      recipient: get(user, 'person.name'),
      state: get(deliveryAddress, 'state'),
      street: get(deliveryAddress, 'streetName'),
      number: get(deliveryAddress, 'streetNumber'),
      complement: get(deliveryAddress, 'complement', '-'),
      neighborhood: get(deliveryAddress, 'neighborhood'),
      referencePoint: get(deliveryAddress, 'referencePoint', '-'),
      stateAbbreviatedName: get(deliveryAddress, 'stateAbbreviatedName'),
    }
  }, [delivery])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  const renderDeliveryTrackingCode = useCallback(() => {
    if (!delivery.deliveryInfo.deliveryTrackingCode) return

    return (
      <HStack>
        <Text color="blue.500" fontWeight="bold">
          {delivery.deliveryInfo.deliveryTrackingCode}
        </Text>

        <Button
          as={Link}
          target="_blank"
          size="xs"
          colorScheme="blue"
          leftIcon={<FiBox />}
          to={{
            pathname: `https://linketrack.com/track?codigo=${delivery.deliveryInfo.deliveryTrackingCode}`,
          }}
        >
          Rastrear pedido
        </Button>
      </HStack>
    )
  }, [delivery?.deliveryInfo?.deliveryTrackingCode])

  if (!delivery) {
    return (
      <Box>
        <Text>No order details</Text>
      </Box>
    )
  }

  return (
    <Stack spacing="8" direction={{ xl: 'row' }}>
      <CardDetails
        title="Informações de envio"
        data={[
          {
            label: 'Método',
            value: delivery.deliveryInfo.providerName,
          },
          {
            label: 'Tipo de frete',
            value: delivery.deliveryInfo.serviceName,
          },
          {
            label: 'Valor frete',
            value: formatCurrency(delivery.deliveryInfo.shippingValue),
          },
          {
            label: 'Data de envio',
            value: delivery.deliveryInfo.deliveryDate || 'Sem data de envio',
          },
          {
            label: 'Prazo de entrega',
            value: `Até ${
              Number(delivery.deliveryInfo.estimatedDeliveryDays) === 1
                ? '1 dia útil'
                : `${delivery.deliveryInfo.estimatedDeliveryDays} dias úteis`
            }`,
          },
          {
            label: 'Código de rastreio',
            value: renderDeliveryTrackingCode() || 'Não disponível',
          },
        ]}
      />

      <CardDetails
        title="Endereço de entrega"
        data={[
          {
            label: 'Destinatário',
            value: sanitizedDeliveryAddress?.recipient,
          },
          {
            label: 'Logradouro',
            value: sanitizedDeliveryAddress?.street,
          },
          {
            label: 'Numero',
            value: sanitizedDeliveryAddress?.number,
          },
          {
            label: 'Complemento',
            value: sanitizedDeliveryAddress?.complement,
          },
          {
            label: 'Ponto de referência',
            value: sanitizedDeliveryAddress?.referencePoint,
          },
          {
            label: 'CEP',
            value: sanitizedDeliveryAddress?.postalCode,
          },
          {
            label: 'Cidade',
            value:
              sanitizedDeliveryAddress?.city +
              ' - ' +
              sanitizedDeliveryAddress?.stateAbbreviatedName,
          },
          {
            label: 'UF',
            value: sanitizedDeliveryAddress?.state,
          },
        ]}
      />
    </Stack>
  )
}
