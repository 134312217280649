// Vendors
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// Functions
import { useSelectParser } from 'hooks/useSelectParser'
import { useFormTransform } from 'hooks/useFormTransform'

// Components
import { FieldText } from 'components/FieldText'
import { FieldSelect } from 'components/FieldSelect'

// Styles
import { Grid, GridItem } from '@chakra-ui/react'
import { get } from 'lodash'

export const TabGeneral = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectKits = useSelectParser({
    accessor: { label: 'name', value: 'id' },
    endpoint: '/app/sample_transport_kit',
  })
  const selectCategories = useSelectParser({
    accessor: { label: 'title', value: 'id' },
    endpoint: '/public/store_product_category',
  })

  const selectCompanyTestfyBase = useSelectParser({
    accessor: { label: 'internalIdentification', value: 'id' },
    endpoint: '/app/company?type=testfy-base',
  })

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('products')
  const { selectParser } = useFormTransform()

  const {
    watch,
    control,
    setValue,
    register,
    getValues,
    formState: { errors },
  } = useFormContext()

  const valueTestfyBaseIds = getValues('testfyBaseIds')
  const valueSampleTransportKitId = getValues('sampleTransportKitId')
  const valueStoreProductCategoryId = getValues('storeProductCategoryIds')

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!valueTestfyBaseIds || !valueTestfyBaseIds.length) return
    if (selectCompanyTestfyBase.options.length === 0) return
    if (typeof valueTestfyBaseIds[0] === 'object') return

    const testfyBaseIds = selectParser(
      valueTestfyBaseIds,
      selectCompanyTestfyBase.options
    )

    setValue('testfyBaseIds', testfyBaseIds)
  }, [
    setValue,
    selectParser,
    valueTestfyBaseIds,
    selectCompanyTestfyBase.options,
  ])
  useEffect(() => {
    if (!valueSampleTransportKitId || !valueSampleTransportKitId.length) return
    if (typeof valueSampleTransportKitId[0] === 'object') return

    const sampleTransportKitId = selectParser(
      valueSampleTransportKitId,
      selectKits.options
    )

    setValue('sampleTransportKitId', sampleTransportKitId)
  }, [valueSampleTransportKitId, setValue, selectParser, selectKits.options])
  useEffect(() => {
    if (selectCategories.options.length === 0) return
    if (!valueStoreProductCategoryId || !valueStoreProductCategoryId.length)
      return
    if (typeof valueStoreProductCategoryId[0] === 'object') return

    const storeProductCategoryId = selectParser(
      valueStoreProductCategoryId,
      selectCategories.options
    )

    setValue('storeProductCategoryIds', storeProductCategoryId)
  }, [
    setValue,
    selectParser,
    selectCategories.options,
    valueStoreProductCategoryId,
  ])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Grid
      my="4"
      gap="10"
      templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
    >
      <GridItem colSpan={2}>
        <FieldText
          maxLength={45}
          currentLength={get(watch('name'), 'length')}
          label={t('form.name.label')}
          error={errors.name}
          isRequired
          {...register('name')}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <FieldText
          maxLength={5}
          currentLength={get(watch('storeProductCode'), 'length')}
          label={t('form.storeProductCode.label')}
          error={errors.storeProductCode}
          isRequired
          {...register('storeProductCode')}
        />
      </GridItem>

      <GridItem colSpan={1}>
        <Controller
          name="testfyBaseIds"
          control={control}
          render={({ field }) => (
            <FieldSelect
              label={t('form.testfyBaseIds.label')}
              isMulti
              error={errors.testfyBaseIds}
              options={selectCompanyTestfyBase.options}
              isLoading={selectCompanyTestfyBase.isLoading}
              isRequired
              {...field}
            />
          )}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <Controller
          name="sampleTransportKitId"
          control={control}
          render={({ field }) => (
            <FieldSelect
              isDisabled
              label={t('form.sampleTransportKitId.label')}
              error={errors.sampleTransportKitId}
              options={selectKits.options}
              isLoading={selectKits.isLoading}
              isRequired
              {...field}
            />
          )}
        />
      </GridItem>

      <GridItem colSpan={1}>
        <Controller
          name="storeProductCategoryIds"
          control={control}
          render={({ field }) => (
            <FieldSelect
              isMulti
              label={t('form.storeProductCategoryId.label')}
              error={errors.storeProductCategoryIds}
              options={selectCategories.options}
              isLoading={selectCategories.isLoading}
              isRequired
              {...field}
            />
          )}
        />
      </GridItem>
    </Grid>
  )
}
