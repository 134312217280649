import {
  Flex,
  Text,
  Stack,
  Button,
  Circle,
  Heading,
  useClipboard,
} from '@chakra-ui/react'
import React from 'react'
import { FiCheck } from 'react-icons/fi'
import Lottie from 'react-lottie'
import animationData from 'assets/animations/check-animation.json'

type SuccessPaymentLinkProps = {
  paymentLink: string
}

export const SuccessPaymentLink = (props: SuccessPaymentLinkProps) => {
  const { paymentLink } = props

  const { onCopy, hasCopied } = useClipboard(paymentLink)

  return (
    <Flex justifyContent="center" flexDirection="column" pb="4">
      <Stack alignItems="center">
        <Circle size="12" bg="blue.100" mb="1.5rem !important">
          <FiCheck size="24" />
        </Circle>

        <Heading fontWeight="medium" fontSize="lg" color="gray.900">
          Pedido realizado com sucesso!
        </Heading>

        <Text fontSize="sm" color="gray.500" textAlign="center" w="80">
          Copie o o link abaixo e encaminhe ao cliente para efetuar o pagamento
          e concluir o pedido.
        </Text>
      </Stack>

      <Flex
        justifyContent="center"
        borderStyle="groove"
        borderWidth="1px"
        w="full"
        p="4"
        mt="8"
        mb="4"
      >
        <Text fontWeight="bold" fontSize="xl" color="#6A6A6A">
          {paymentLink}
        </Text>
      </Flex>

      <Button colorScheme="blue" onClick={onCopy}>
        {hasCopied ? (
          <Lottie
            height={32}
            width={32}
            options={{
              animationData,
              autoplay: true,
              loop: true,
            }}
          />
        ) : (
          'COPIAR LINK'
        )}
      </Button>
    </Flex>
  )
}
