import {
  Box,
  IconButton,
  Link,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import React from 'react'
import { FaSkull } from 'react-icons/fa'
import { BiBug, BiCodeAlt } from 'react-icons/bi'

interface ITodo {
  text?: string
  link?: string
  large?: boolean
  blocker?: boolean
  bug?: boolean
  issue?: string
}

const Todo: React.FC<ITodo> = (props: ITodo) => {
  const initialFocusRef = React.useRef(null)
  const HideFromProject = false
  const { text, issue, link, bug, large, blocker } = props

  if (HideFromProject) return <></>

  return (
    <Box position="relative" zIndex="500">
      <Box position="absolute" top="-3" left="-3">
        <Popover
          initialFocusRef={initialFocusRef}
          placement="right"
          closeOnBlur={true}
        >
          {!blocker && !bug && (
            <PopoverTrigger>
              <IconButton
                colorScheme="yellow"
                borderRadius="50"
                size={large ? 'lg' : 'xs'}
                fontSize={large ? '24' : '16'}
                border="0"
                aria-label="Todo"
                bg="#FFF629"
                _hover={{ background: '#FFF629', opacity: 0.8 }}
                icon={<BiCodeAlt />}
              />
            </PopoverTrigger>
          )}
          {bug && (
            <PopoverTrigger>
              <IconButton
                colorScheme="orange"
                borderRadius="50"
                size={large ? 'lg' : 'xs'}
                fontSize={large ? '24' : '16'}
                border="0"
                aria-label="Todo"
                bg="#FF6600"
                _hover={{ background: '#FF6600', opacity: 0.8 }}
                icon={<BiBug />}
              />
            </PopoverTrigger>
          )}
          {blocker && (
            <PopoverTrigger>
              <IconButton
                colorScheme="red"
                borderRadius="50"
                size={large ? 'lg' : 'xs'}
                fontSize={large ? '24' : '16'}
                border="0"
                aria-label="Todo"
                bg="#FF0000"
                _hover={{ background: '#FF0000', opacity: 0.8 }}
                icon={<FaSkull />}
              />
            </PopoverTrigger>
          )}

          <Portal>
            <PopoverContent
              borderColor="black"
              bg="black"
              color="white"
              fontSize="16"
              p="3"
              _focus={{ outline: 'none' }}
            >
              <PopoverArrow bg="black" />
              {!link && text}
              {!issue && link && (
                <Link href={link} target="_blank">
                  {text || link}
                </Link>
              )}
              {issue && (
                <Link
                  href={`https://github.com/CM2-Tech/testfy-backoffice/issues/${issue}`}
                  target="_blank"
                >
                  Github: {issue}
                </Link>
              )}
            </PopoverContent>
          </Portal>
        </Popover>
      </Box>
    </Box>
  )
}

export default Todo
