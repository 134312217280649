// Venders
import React from 'react'

// Styles
import {
  Box,
  Flex,
  Text,
  HStack,
  Heading,
  useToken,
  IconButton,
  ButtonGroup,
} from '@chakra-ui/react'
import { FiEdit, FiMapPin, FiMinusCircle } from 'react-icons/fi'

export type AddressData = {
  id: string
  street: string
  number: string
  neighborhood: string
  complement: string | null
  referencePoint: string | null
  postalCode: string
  cityId: string
  stateId: string
  createdAt: Date
  updatedAt: Date
  city: {
    id: string
    ibgeCode: number
    name: string
    latitude: string
    longitude: string
    stateAbbreviatedName: string
    createdAt: Date
    updatedAt: Date
  }
  state: {
    id: string
    abbreviatedName: string
    fullName: string
    createdAt: Date
    updatedAt: Date
  }
}

type ItemAddressProps = {
  address: AddressData
  onEdit: (id: string) => void
  onDelete: (id: string) => void
}

export const ItemAddress = (props: ItemAddressProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { address, onEdit, onDelete } = props
  const {
    street,
    number,
    complement,
    neighborhood,
    postalCode,
    city: { name },
    state: { abbreviatedName },
  } = address
  const [gray500, blue500, red500] = useToken('colors', [
    'gray.500',
    'blue.500',
    'red.500',
  ])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex justifyContent="space-between">
      <Box>
        <Heading fontWeight="semibold" color="gray.900" fontSize="md">
          {street}, {number}
        </Heading>

        <HStack mt="2">
          <FiMapPin color={gray500} />

          <Text fontWeight="medium" color="gray.500" fontSize="sm">
            {`${
              complement ? `${complement},` : ''
            } ${neighborhood}, CEP ${postalCode}, ${name} - ${abbreviatedName}`}
          </Text>
        </HStack>
      </Box>

      <ButtonGroup>
        <IconButton
          aria-label="Edit item"
          icon={<FiEdit size="20px" color={blue500} />}
          variant="ghost"
          size="sm"
          onClick={() => onEdit(address.id)}
        />
        <IconButton
          aria-label="Delete item"
          icon={<FiMinusCircle size="20px" color={red500} />}
          variant="ghost"
          size="sm"
          onClick={() => onDelete(address.id)}
        />
      </ButtonGroup>
    </Flex>
  )
}
