import { useState, useCallback } from 'react'
import cepPromise from 'cep-promise'
import { useFeedback } from 'contexts/feedback'

type CepPromiseResponse = {
  cep: string
  state: string
  city: string
  street: string
  neighborhood: string
}

export const useCepPromise = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isFetchingCep, setIsFetchingCep] = useState(false)
  const [record, setRecord] = useState<CepPromiseResponse | null>(null)
  const { feedback } = useFeedback()

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const fetchCEP = useCallback(
    async (cep: string) => {
      try {
        setIsFetchingCep(true)
        const response: CepPromiseResponse = await cepPromise(cep, {
          timeout: 10000,
        })

        if (!response) return
        setRecord(response)
        return response
      } catch (err: any) {
        let messageError = 'Não foi possível encontrar o CEP informado'

        if (err.message) {
          messageError = err.message
        }

        feedback({
          title: 'Erro ao procurar CEP',
          description: messageError,
          status: 'error',
        })

        throw new Error(messageError)
      } finally {
        setIsFetchingCep(false)
      }
    },
    [feedback]
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Return
  |-----------------------------------------------------------------------------
  |
  |
  */
  return {
    cep: record,
    fetchCEP,
    isFetchingCep,
  }
}
