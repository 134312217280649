export type CategoryNewForm = {
  title: string
  description: string
  accentColor: string
  mktIntrodutoryText: string
  mktDescriptionReport: string
}

export type CategoryNewRecord = CategoryNewForm

export enum AccentColorEnum {
  'Polo' = 'Polo',
  'Blush' = 'Blush',
  'Lilac' = 'Lilac',
  'Viola' = 'Viola',
  'Mango' = 'Mango',
  'Willow' = 'Willow',
  'Sienna' = 'Sienna',
  'Orchid' = 'Orchid',
  'Cranberry' = 'Cranberry',
  'Fountain' = 'Fountain',
  'Mauvelous' = 'Mauvelous',
}
