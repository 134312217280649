// Vendors
import React from 'react'
import { useLocation } from 'react-router-dom'

// Components
import PageHeader from 'components/PageHeader'

// Styles
import Layout from 'layout/Private'
import { Box } from '@chakra-ui/react'

const PageEmpty: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { pathname } = useLocation()

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Box p="6">
        <PageHeader title={`${pathname}`} />
        <Box>Page in development</Box>
      </Box>
    </Layout>
  )
}

export default PageEmpty
