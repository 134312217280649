// Vendors
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

// Components
import { FieldToggle } from 'components/FieldToggle'
import { FieldNumber } from 'components/FieldNumber'
import { FieldCheckbox } from 'components/FieldCheckbox'

// Styles
import { FiMinus, FiPlus } from 'react-icons/fi'
import {
  Box,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useCan } from 'hooks/useCan'
import { RULES } from '../rules'
import { ProductShowForm } from '../types'

export const TabSales = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('products')

  const { can: canEdit } = useCan<ProductShowForm>({ rules: RULES })

  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'progressiveDiscounts',
  })

  const watchIsB2bAvailable = watch('isB2bAvailable')
  const watchIsB2cAvailable = watch('isB2cAvailable')
  const watchIsAvailableInStore = watch('isAvailableInStore')

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const defaultProgressiveDiscount = useMemo(
    () => ({
      percentageDiscount: 0,
      minUnits: 0,
    }),
    []
  )

  const checkboxOptions = useMemo(
    () => [
      {
        label: t('form.isB2bAvailable.label'),
        name: 'isB2bAvailable',
      },
      {
        label: t('form.isB2cAvailable.label'),
        name: 'isB2cAvailable',
      },
      {
        label: t('form.isSellerAvailable.label'),
        name: 'isSellerAvailable',
      },
    ],
    [t]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <Heading as="span" fontWeight="normal" fontSize="sm" color="gray.500">
        Apenas o perfil <Text as="strong">Vendas</Text> pode alterar essas
        informações
      </Heading>

      <Grid
        gap="10"
        my="4"
        templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
      >
        <GridItem colSpan={2}>
          <FieldNumber
            isDisabled={canEdit.priceB2c}
            label={t('form.priceB2c.label')}
            control={control}
            name="priceB2c"
            isRequired
            error={errors.priceB2c}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <FieldNumber
            isDisabled={canEdit.priceB2b}
            control={control}
            name="priceB2b"
            label={t('form.priceB2b.label')}
            isRequired
            error={errors.priceB2b}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Controller
            name="isEligibleB2bDiscount"
            control={control}
            defaultValue={true}
            render={({ field: { onChange, value, ref, name } }) => (
              <FieldToggle
                isDisabled={canEdit.isEligibleB2bDiscount}
                label={t('form.isEligibleB2bDiscount.label')}
                size="md"
                name={name}
                ref={ref}
                isChecked={value}
                onChange={onChange}
                error={errors.status}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Stack mb="3" direction="row" align="center">
            <Text fontSize="md" fontWeight="medium">
              {t('form.progressiveDiscount.label')}
            </Text>
            {fields.length > 0 && !canEdit.progressiveDiscount && (
              <IconButton
                size="xs"
                variant="outline"
                colorScheme="blue"
                borderWidth="2px"
                borderRadius="full"
                icon={<FiPlus color="blue" />}
                aria-label="Adicionar desconto progressivo"
                onClick={() => append(defaultProgressiveDiscount)}
              />
            )}
          </Stack>
          <Stack w="100%">
            {fields.length > 0 ? (
              fields.map((field, index) => (
                <Stack
                  key={field.id}
                  color="gray.500"
                  direction="row"
                  alignItems="center"
                >
                  <Text whiteSpace="nowrap">
                    {t('form.progressiveDiscount.discountFrom')}
                  </Text>

                  <Box>
                    <FieldNumber
                      control={control}
                      isDisabled={canEdit.progressiveDiscount}
                      name={`progressiveDiscounts.${index}.percentageDiscount`}
                    />
                  </Box>

                  <Text whiteSpace="nowrap">
                    % {t('form.progressiveDiscount.from')}
                  </Text>

                  <Box>
                    <FieldNumber
                      control={control}
                      isDisabled={canEdit.progressiveDiscount}
                      name={`progressiveDiscounts.${index}.minUnits`}
                    />
                  </Box>

                  <Text whiteSpace="nowrap">
                    {t('form.progressiveDiscount.units')}
                  </Text>

                  {!canEdit.progressiveDiscount && (
                    <IconButton
                      size="xs"
                      variant="outline"
                      colorScheme="red"
                      borderWidth="2px"
                      borderRadius="full"
                      onClick={() => remove(index)}
                      icon={<FiMinus color="red" />}
                      aria-label="Remove desconto progressivo"
                    />
                  )}
                </Stack>
              ))
            ) : (
              <Stack isInline>
                <Text>{t('form.progressiveDiscount.noDiscountsFound')},</Text>
                <Text
                  color="blue.500"
                  cursor="pointer"
                  fontWeight="bold"
                  textDecoration="underline"
                  onClick={() => append(defaultProgressiveDiscount)}
                >
                  {t('form.progressiveDiscount.addDiscount')}
                </Text>
              </Stack>
            )}
          </Stack>
        </GridItem>

        <GridItem colSpan={{ base: 2, lg: 4 }}>
          <Text>
            As opções abaixo determinam onde o produto poderá ser visto e
            vendido.
          </Text>
          <Text>
            Se a opção &quot;mostrar produto na loja&quot; estiver desmarcada, o
            produto não será mostrado mesmo se estiver disponível para venda B2B
            ou B2C.
          </Text>
        </GridItem>

        <GridItem colSpan={2}>
          <FieldCheckbox
            isDisabled={
              canEdit.isB2bAvailable ||
              canEdit.isB2cAvailable ||
              canEdit.isSellerAvailable
            }
            label={t('form.isAvailable.label')}
            control={control}
            options={checkboxOptions}
            helperText={{
              text: !(watchIsB2bAvailable || watchIsB2cAvailable) &&
                watchIsAvailableInStore && (
                  <Text fontStyle="italic">
                    Atenção: O produto está marcado para mostrar na loja, mas
                    não está selecionado para venda B2C ou B2B. Selecione uma
                    das opções acima para mostrar o produto.
                  </Text>
                ),
              color: 'red.500',
            }}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Controller
            name="isAvailableInStore"
            control={control}
            render={({ field: { value, ref, name, onChange } }) => (
              <FieldToggle
                ref={ref}
                size="md"
                name={name}
                isChecked={value}
                onChange={onChange}
                label={t('form.isAvailableInStore.label')}
                error={errors.isAvailableInStore}
                helperText={{
                  text: (watchIsB2bAvailable || watchIsB2cAvailable) &&
                    !value && (
                      <Text fontStyle="italic">
                        Atenção: O produto está marcado para venda B2B ou B2C,
                        mas não está marcada para mostrar na loja. Marque esta
                        opção para mostrar o produto
                      </Text>
                    ),
                  color: 'red.500',
                }}
              />
            )}
          />
        </GridItem>
      </Grid>
    </Box>
  )
}
