export function formatDocumentNumber(documentNumber: string) {
  if (!documentNumber) return '-'

  if (documentNumber.length === 11) {
    return documentNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }

  if (documentNumber.length === 14) {
    return documentNumber.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    )
  }

  return documentNumber
}
