// Vendors
import React from 'react'
import { v4 } from 'uuid'

// Components
import Todo from 'tools/Todo'
import { TimelineItem, ITimelineItem } from '../TimelineItem'

// Styles
import { Box, Stack, Text } from '@chakra-ui/react'

// Interfaces
export interface ITimeline {
  label: string
  data: ITimelineItem[]
}

export const Timeline: React.FC<ITimeline> = (props) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { label, data } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Box as="section">
      <Box maxW="2xl" p={{ base: '4', md: '8' }}>
        <Text
          fontSize="2xl"
          color="black"
          display="flex"
          fontWeight="bold"
          pb={4}
        >
          {label}
        </Text>
        <Stack as="ul">
          {data &&
            data.map((item, i) => (
              <TimelineItem
                key={`timeline-${i}`}
                {...item}
                isLastItem={i + 1 === data.length}
              />
            ))}
        </Stack>
      </Box>
    </Box>
  )
}
