// Vendors
import React, { useCallback } from 'react'
import { get } from 'lodash'
import { useForm } from 'react-hook-form'

// Components
import { FieldFile } from 'components/FieldFile'
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useError } from 'hooks/useError'

import { apiPost } from 'services/post'
import { FieldNumber } from 'components/FieldNumber'

type ModalConfirmIssuanceNoteProps = {
  isOpen: boolean
  reload: () => void
  purchaseId: string
  onCancel: () => void
}

type FormConfirmIssuanceNote = {
  comments: string
  invoiceUniqueNumber: string
  document: File
}

export const ModalConfirmIssuanceNote = (
  props: ModalConfirmIssuanceNoteProps
) => {
  const { isOpen, reload, onCancel, purchaseId } = props

  const {
    watch,
    reset,
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormConfirmIssuanceNote>()

  const documentWatch = watch('document')

  const { handleError } = useError()

  const onClose = useCallback(() => {
    reset()
    onCancel()
  }, [onCancel, reset])

  const onSubmit = useCallback(
    async (data: FormConfirmIssuanceNote) => {
      try {
        await apiPost(`/app/purchase/${purchaseId}/invoice_note`, {
          comments: data.comments,
          invoiceUniqueNumber: data.invoiceUniqueNumber,
        })

        reload()
        onClose()
      } catch (error) {
        const errorHandled = handleError(error, undefined, { 422: true })

        if (errorHandled) {
          errorHandled.forEach((errorItem) => {
            const { field, message } = errorItem as {
              field: keyof FormConfirmIssuanceNote
              message: string
            }

            setError(field, { message: String(message) })
          })
        }
      }

      try {
        const formData = new FormData()

        if (!documentWatch) throw new Error('Documento é obrigatório')

        formData.append('file', get(documentWatch, '0'))
        formData.append('title', get(documentWatch, '[0].name', 'draft'))

        await apiPost(
          `/app/history_update/purchase/${purchaseId}/upload`,
          formData
        )

        reload()
        onClose()
      } catch (error) {
        handleError(error)
      }
    },
    [documentWatch, handleError, onClose, purchaseId, reload, setError]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Confirmar emissão da nota fiscal</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text color="gray.500" mb="4">
            Para confirmar a emissão da nota, por favor preencha os campos
            abaixo:
          </Text>

          <Stack>
            <FieldNumber
              rules={{
                required: 'Por favor, preencha o número da nota fiscal',
              }}
              control={control}
              showNumberStepper={false}
              name="invoiceUniqueNumber"
              label="Número da nota fiscal"
              error={errors.invoiceUniqueNumber}
              placeholder="Digite o número da nota fiscal"
            />

            <FieldTextArea
              label="Comentário"
              error={errors.comments}
              placeholder="Digite uma comentário"
              {...register('comments', {
                required: 'Por favor, preencha o campo de comentário',
              })}
            />

            <FieldFile
              label="Documento"
              error={errors.document}
              fileName={get(documentWatch, '[0].name')}
              allowedExtensions={['pdf', 'jpg', 'png', 'xml']}
              {...register('document', {
                required: `Documento é obrigatorio`,
              })}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose}>Cancelar</Button>
            <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
              Confirmar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
