import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { get, map } from 'lodash'
import { useToast } from '@chakra-ui/react'

import api from 'services/api'

import {
  AuthContextValues,
  User,
  AuthStorage,
  LogInPayload,
  LogInResponse,
} from './interfaces'
import { apiPost } from 'services/post'
import { useHistory } from 'react-router-dom'

export const STORAGE_KEYS = `@tfy:bo${
  process.env.NODE_ENV === 'production' ? '' : ':test'
}`

const AuthContext = createContext({} as AuthContextValues)

export const AuthContextProvider: React.FC = ({ children }) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const history = useHistory()
  const toast = useToast()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [user, setUser] = useState<User>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const setAuthDataToStorage = useCallback((authData: AuthStorage) => {
    localStorage.setItem(STORAGE_KEYS, btoa(JSON.stringify(authData)))
  }, [])

  const getAuthDataFromStorage = useCallback(() => {
    const authData = localStorage.getItem(STORAGE_KEYS)
    if (!authData) return
    return JSON.parse(atob(authData)) as AuthStorage
  }, [])

  const clearAuthDataFromStorage = useCallback(() => {
    localStorage.removeItem(STORAGE_KEYS)
  }, [])

  const logIn = useCallback(
    async (loginPayload: LogInPayload) => {
      setIsLoading(true)

      try {
        const apiResponse = await api.post<LogInResponse>(
          '/public/session',
          loginPayload
        )

        const {
          data: {
            user,
            token: { token },
          },
        } = apiResponse

        setUser(user)
        api.defaults.headers.Authorization = `Bearer ${token}`
        setAuthDataToStorage({ user, token })

        toast({
          title: 'Sucesso',
          description: 'Login feito com sucesso!',
          status: 'success',
          position: 'top-right',
        })
      } catch (error: any) {
        // TODO: Adicionar apiHandlerError

        let errorMessage =
          'Infelizmente houve uma falha no seu login. Reveja os dados e tente novamente.'

        if (error.response?.data?.errors) {
          errorMessage = error.response.data.errors
            .map((err: { [key: string]: string }) => err.message)
            .join('\n')
        }

        const apiErrorString = get(error, 'response.data')
        if (typeof apiErrorString === 'string') {
          errorMessage = apiErrorString
        }

        toast({
          title: 'Atenção',
          description: errorMessage,
          status: 'info',
        })
      } finally {
        setIsLoading(false)
      }
    },
    [setAuthDataToStorage, toast]
  )

  const logOut = useCallback(async () => {
    try {
      await apiPost('/app/person/logout')
    } catch (error) {
      console.error(error)
    }

    clearAuthDataFromStorage()
    setUser(undefined)
    api.defaults.headers.Authorization = ''
  }, [clearAuthDataFromStorage])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    /**
     * Get auth data from storage. If finds something, set values to state.
     */

    setIsLoading(true)

    const authData = getAuthDataFromStorage()
    if (!authData) {
      setUser(undefined)
      setIsLoading(false)

      return
    }
    const { token, user } = authData

    setUser(user)
    api.defaults.headers.Authorization = `Bearer ${token}`

    setIsLoading(false)
  }, [getAuthDataFromStorage, history])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const userRolesNames = useMemo(() => {
    if (!user) return []

    return map(get(user, 'roleUser'), 'role.name')
  }, [user])

  const authContextValue: AuthContextValues = useMemo(
    () => ({
      user,
      isLoggedIn: !!user,
      userRolesNames,
      logIn,
      logOut,
      isLoading,
    }),
    [isLoading, logIn, logOut, user, userRolesNames]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  return context
}
