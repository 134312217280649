import { get } from 'lodash'
import React, { useCallback, useState } from 'react'

import { FieldText } from 'components/FieldText'
import { FieldIconFile } from 'components/FieldIconFile'

import { FiMessageCircle, FiMinusCircle } from 'react-icons/fi'
import { Box, Text, Button, IconButton, Stack, Tooltip } from '@chakra-ui/react'
import { apiPost } from 'services/post'
import { useError } from 'hooks/useError'
import { TypeHistoryUpdate } from '../AccordionItem'

type CommentProps = {
  productId: string
  reload: () => void
  type: TypeHistoryUpdate
}

export const Comment = (props: CommentProps) => {
  const { type, reload, productId } = props

  const { handleError } = useError()

  const [comment, setComment] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)

  const handleComment = useCallback(async () => {
    try {
      setIsLoading(true)
      if (!productId) throw new Error('ID não informado')

      if (!comment)
        throw new Error('Por favor, é necessário preencher o campo comentário')

      if (type === TypeHistoryUpdate.PURCHASE_PRODUCT) {
        const endpoint = `/app/history_update/purchase_product/${productId}`

        if (file) {
          const formData = new FormData()
          formData.append('file', file)
          formData.append('title', comment)

          await apiPost(`${endpoint}/upload`, formData)
        } else {
          await apiPost(`${endpoint}/note`, {
            note: comment,
          })
        }
      }

      if (type === TypeHistoryUpdate.PURCHASE) {
        const endpoint = `/app/history_update/purchase/${productId}`

        if (file) {
          const formData = new FormData()
          formData.append('file', file)
          formData.append('title', comment)

          await apiPost(`${endpoint}/upload`, formData)
        } else {
          await apiPost(`${endpoint}/note`, {
            note: comment,
          })
        }
      }

      reload()
      setFile(null)
      setComment('')
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }, [comment, file, handleError, productId, reload, type])

  return (
    <Stack
      pl="5"
      pr="9"
      w="full"
      alignItems="flex-end"
      mb="1.5rem !important"
      direction={{ lg: 'row' }}
    >
      <FieldText
        name="comment"
        value={comment}
        placeholder="Digite aqui..."
        onChange={(e) => setComment(e.target.value)}
      />

      <Box>
        {file ? (
          <Tooltip
            openDelay={500}
            label="Remover anexo"
            aria-label="remover anexo"
          >
            <IconButton
              colorScheme="red"
              icon={<FiMinusCircle size="20" />}
              aria-label="remover anexo"
              onClick={() => setFile(null)}
            />
          </Tooltip>
        ) : (
          <FieldIconFile
            name={`upload-${productId}`}
            onChange={(e) => setFile(get(e, 'target.files[0]'))}
          />
        )}
      </Box>

      <Button
        w="40"
        colorScheme="blue"
        isLoading={isLoading}
        onClick={handleComment}
      >
        <FiMessageCircle size="20" />
        <Text ml="2">Publicar</Text>
      </Button>
    </Stack>
  )
}
