import {
  add,
  differenceInSeconds,
  getSeconds,
  intervalToDuration,
  isDate,
} from 'date-fns'
import { useEffect, useMemo, useState } from 'react'

type UseTimerProps = {
  date?: Date
  duration: {
    seconds?: number
    minutes?: number
    hours?: number
  }
}

export const useTimer = (props: UseTimerProps) => {
  const { date, duration } = props
  const [startTimer, setStartTimer] = useState(date || 0)
  const [currentTimer, setCurrentTimer] = useState(0)
  const [endTimer, setEndTimer] = useState<Date | number>(0)
  const [porcentage, setPorcentage] = useState(0)
  const [isRunning, setIsRunning] = useState(false)

  const { seconds, minutes, hours } = duration

  useEffect(() => {
    if (endTimer !== 0) return

    const hoursInSeconds = hours ? hours * 60 * 60 : 0
    const minutesInSeconds = minutes ? minutes * 60 : 0

    const durationInSeconds = hoursInSeconds + minutesInSeconds + (seconds || 0)

    if (date && typeof startTimer !== 'number') {
      setEndTimer(add(new Date(startTimer), { seconds: durationInSeconds }))
      return
    }

    setEndTimer(durationInSeconds)
  }, [date, endTimer, hours, minutes, seconds, startTimer])

  useEffect(() => {
    if (!isRunning) {
      setIsRunning(true)
    }

    if (
      differenceInSeconds(new Date(), new Date(endTimer)) >= 0 ||
      endTimer === currentTimer
    ) {
      setIsRunning(false)
      return
    }

    const interval = setInterval(() => {
      if (isDate(endTimer)) {
        const diffInSec = differenceInSeconds(new Date(), new Date(endTimer))
        setCurrentTimer(diffInSec)
        setPorcentage((diffInSec / getSeconds(endTimer)) * 100)
      }

      if (typeof currentTimer === 'number' && typeof endTimer === 'number') {
        setCurrentTimer(currentTimer + 1)
        setPorcentage((currentTimer / endTimer) * 100)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [isRunning, currentTimer, endTimer])

  const durationConverted = useMemo(() => {
    const end = typeof endTimer === 'number' ? endTimer : getSeconds(endTimer)
    return intervalToDuration({
      start: 0,
      end: (end - currentTimer) * 1000,
    })
  }, [currentTimer, endTimer])

  return {
    currentTimer,
    porcentage,
    isRunning,
    durationConverted,
  }
}
