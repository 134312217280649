// Vendors
import React from 'react'

// Components
import { Timeline } from 'components/Timeline'
import { ITimelineItem } from 'components/TimelineItem'

// Styles
import { Box, Text } from '@chakra-ui/react'

// Types
type TabHistoricProps = {
  historic: {
    purchaseDeliveryStatusUpdate: ITimelineItem[]
  }
}

export const TabHistoric = (props: TabHistoricProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { historic } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (!historic || !historic.purchaseDeliveryStatusUpdate) {
    return (
      <Box>
        <Text>Sem histórico</Text>
      </Box>
    )
  }

  return (
    <Box>
      <Timeline
        label="Histórico"
        data={historic.purchaseDeliveryStatusUpdate}
      />
    </Box>
  )
}
