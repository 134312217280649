// Vendors
import React from 'react'
import { useTranslation } from 'react-i18next'

// Components
import { FormAddress } from '../FormAddress'
import { FormWrapper } from 'components/FormWrapper'

export const BillingData = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('checkout')

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormWrapper title={t('group.billingData')}>
      <FormAddress name="billingAddress" />
    </FormWrapper>
  )
}
