// Vendors
import React, { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { FiEye, FiEyeOff } from 'react-icons/fi'

// Styles and Icons
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  LightMode,
  Button,
  useColorModeValue as mode,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'

// Components
import { Logo } from 'components/Logo'
import { InputText } from 'components/InputText'
import { FormFeedback } from 'components/FormFeedback'
import { IFormFeedbackItem } from 'components/FormFeedbackItem'

// Hooks
import { useAuth } from 'contexts/auth'
import { useTranslation } from 'react-i18next'
import { FieldText } from 'components/FieldText'

type Inputs = {
  username: string
  password: string
}

/*
|-----------------------------------------------------------------------------
| Page
|-----------------------------------------------------------------------------
|
|
*/

const Login = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('common')
  const { logIn } = useAuth()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [formAlerts, setFormAlerts] = useState<IFormFeedbackItem[] | []>([])
  const [isShowPassword, setIsShowPassword] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */
  const onSubmit: SubmitHandler<Inputs> = React.useCallback(
    (data) => {
      logIn(data)
    },
    [logIn]
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex minH="100vh" direction={{ base: 'column', md: 'row' }}>
      <Box
        display={{ base: 'none', md: 'block' }}
        maxW={{ base: '20rem', lg: '40rem' }}
        flex="1"
        backgroundImage="url('/login-bg.jpg')"
        backgroundSize="cover"
        color="white"
        px="10"
        py="8"
      >
        <Box mb="16">
          <Logo w="auto" h="14" iconColor="white" />
        </Box>
      </Box>
      <Flex
        flex="1"
        align="center"
        px={{ base: '10', md: '40' }}
        backgroundImage={{ base: "url('login-bg.jpg')", md: '' }}
        backgroundSize="cover"
      >
        <Box maxW="xl" w={{ base: '100%', md: 'auto' }}>
          <Box textAlign={{ base: 'center', md: 'left' }}>
            <Logo
              display={{ md: 'none' }}
              mb="16"
              w="auto"
              h="14"
              iconColor="white"
              mx="auto"
            />
            <Heading
              color={{ base: 'white', md: 'teal.400' }}
              as="h1"
              size="2xl"
              fontWeight="extrabold"
              letterSpacing="tight"
            >
              {t('authentication.login_welcome')}
            </Heading>
            <Text
              mt="3"
              fontSize={{ base: 'xl', md: '3xl' }}
              fontWeight="bold"
              color={{ base: 'white', md: 'gray.500' }}
            >
              {t('authentication.login_title')}
            </Text>
          </Box>

          <Box mt="6">
            <FormFeedback data={formAlerts} setData={setFormAlerts} />
          </Box>

          <Box
            minW={{ md: '420px' }}
            mt="6"
            rounded="xl"
            bg={{ md: mode('white', 'gray.700') }}
            shadow={{ md: 'lg' }}
            px={{ md: '10' }}
            pt={{ base: '8', md: '12' }}
            pb="8"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing="8">
                <FieldText
                  label={t('authentication.username')}
                  error={errors.username}
                  {...register('username', {
                    required: `${t('authentication.email_required')}`,
                  })}
                />

                <Box>
                  <InputGroup>
                    <FieldText
                      label={t('authentication.password')}
                      type={isShowPassword ? 'text' : 'password'}
                      error={errors.password}
                      {...register('password', {
                        required: `${t('authentication.password_required')}`,
                      })}
                    />

                    <InputRightElement width="4.5rem" top="8">
                      <Button
                        h="7"
                        size="sm"
                        onClick={() => setIsShowPassword((i) => !i)}
                      >
                        {isShowPassword ? <FiEyeOff /> : <FiEye />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </Box>
              </Stack>
              <Flex
                spacing="4"
                direction={{ base: 'column-reverse', md: 'row' }}
                mt="6"
                align="center"
                justify="space-between"
              >
                <Text
                  color={{ base: 'white', md: 'teal.600' }}
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  <Link to="/forgotpassword">
                    {t('authentication.login_forget_password')}
                  </Link>
                </Text>
                <LightMode>
                  <Button
                    mb={{ base: '4', md: '0' }}
                    w={{ base: 'full', md: 'auto' }}
                    type="submit"
                    colorScheme="teal"
                    size="lg"
                    fontSize="md"
                    fontWeight="bold"
                  >
                    {t('authentication.login_send')}
                  </Button>
                </LightMode>
              </Flex>
            </form>
          </Box>
        </Box>
      </Flex>
    </Flex>
  )
}

export default Login
