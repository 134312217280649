import { StylesConfig, CSSObjectWithLabel } from 'react-select'
import { AccentColorEnum } from 'types/themes'

type ReactSelectDataType = {
  label: string
  value: keyof typeof AccentColorEnum
}

const swatch = (primaryColor: string) => {
  return {
    alignItems: 'center',
    display: 'flex',
    ':before': {
      content: '" "',
      display: 'block',
      border: `1px solid ${primaryColor}`,
      background: `linear-gradient(90deg, ${primaryColor}, #fff)`,
      borderRadius: 100,
      marginRight: 8,
      height: 15,
      width: 15,
    },
  }
}

const newSwatch = (styles: CSSObjectWithLabel, data: ReactSelectDataType) => {
  let swatchStyle = {}
  const color = AccentColorEnum[data.value] || '#ddd'

  swatchStyle = swatch(color)

  return { ...styles, ...swatchStyle }
}

export const colorStylesError: StylesConfig = {
  control: (styles) => ({
    ...styles,
    borderColor: 'var(--chakra-colors-red-500)',
    borderRadius: '0.375rem',
    outline: '2px solid transparent',
    boxShadow: '0 0 0 1px var(--chakra-colors-red-500)',
  }),
}

export const colorStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    borderRadius: '0.375rem',
    borderColor: 'inherit',
    outline: '2px solid transparent',
    minHeight: '2.5rem',
    '&:hover, &:focus': {
      boxShadow: '0 0 0 1px var(--chakra-colors-blue-500)',
      borderColor: 'var(--chakra-colors-blue-500)',
    },
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: 'var(--chakra-colors-blue-500)',
      borderRadius: '0.25rem',
    }
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'var(--chakra-colors-white)',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'var(--chakra-colors-white)',
    ':hover': {
      filter: 'brightness(0.85)',
      color: 'white',
    },
  }),
  singleValue: (styles, { data }) =>
    newSwatch(styles, data as ReactSelectDataType),
  option: (styles, { data }) => newSwatch(styles, data as ReactSelectDataType),
}
