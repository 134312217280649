import { UserRolesNames } from 'types/User'

export const RULES = [
  {
    name: 'inExpedition',
    allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.EXPEDITION],
  },
  {
    name: 'inTransit',
    allowedRoles: [UserRolesNames.ADMIN],
  },
  {
    name: 'sent',
    allowedRoles: [UserRolesNames.ADMIN, UserRolesNames.EXPEDITION],
  },
  {
    name: 'delivered',
    allowedRoles: [UserRolesNames.ADMIN],
  },
]
