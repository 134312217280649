import { get, map } from 'lodash'
import {
  FormQuestionType,
  LaboratorialAnalysis,
} from 'types/LaboratorialAnalysis'
import { Record } from 'types/Requests'
import { formatDate } from 'utils/formatDate'
import { formatDocumentNumber } from 'utils/formatDocumentNumber'

type ActivationByPersonalDataType = Record & {
  email: string
  birthdate: string
  documentNumber: string
  name: string
}

type ActivationForPersonalDataType = Record & {
  email: string
  birthdate: string
  documentNumber: string
  name: string
  gender: 'M' | 'F' | 'NOT_INFORMED'
}

type PurchaseType = {
  purchaseType: 'B2B' | 'B2C' | 'LEGACY'
  purchaseNumber: string
  isCobranding: boolean
}

type PurchaseHasProductHasAnalysisType = {
  laboratoryAnalysis: LaboratorialAnalysis
  clinicalRecordFormResponse?: {
    questions: FormQuestionType[]
  } | null
}

type RecordType = {
  isLegacy: boolean
  purchase: PurchaseType
  activationByPersonalData: ActivationByPersonalDataType
  activationForPersonalData: ActivationForPersonalDataType
  purchaseHasProductHasAnalysis:
    | PurchaseHasProductHasAnalysisType[]
    | LaboratorialAnalysis[]
}

export enum GenderEnum {
  M = 'Masculino',
  F = 'Feminino',
  NOT_INFORMED = 'Não informado',
}

function sanitizeActivationByPersonalData(data: ActivationByPersonalDataType) {
  if (!data) return null

  return {
    id: get(data, 'id'),
    name: get(data, 'name', '-'),
    email: get(data, 'email', '-'),
    birthdate: formatDate(get(data, 'birthdate'), 'dd/MM/yyyy'),
    createdAt: formatDate(get(data, 'createdAt'), 'dd/MM/yyyy'),
    documentNumber: formatDocumentNumber(get(data, 'documentNumber')),
  }
}

function sanitizeActivationForPersonalData(
  data: ActivationForPersonalDataType
) {
  if (!data) return null

  return {
    id: get(data, 'id'),
    name: get(data, 'name', '-'),
    email: get(data, 'email', '-'),
    gender: get(GenderEnum, get(data, 'gender')),
    birthdate: formatDate(get(data, 'birthdate'), 'dd/MM/yyyy'),
    createdAt: formatDate(get(data, 'createdAt'), 'dd/MM/yyyy'),
    documentNumber: formatDocumentNumber(get(data, 'documentNumber')),
  }
}

function sanitizePurchase(data: PurchaseType) {
  if (!data) return null

  return {
    id: get(data, 'id'),
    purchaseType: get(data, 'purchaseType'),
    isCobranding: get(data, 'isCobranding', false),
    purchaseNumber: get(data, 'purchaseNumber', '-'),
  }
}

function sanitizePurchaseHasProductHasAnalysis(
  isLegacy: boolean,
  data: PurchaseHasProductHasAnalysisType[] | LaboratorialAnalysis[]
) {
  if (!data) return null

  const services = map(data, (service) => {
    const pathName = isLegacy ? 'name' : 'laboratoryAnalysis.name'
    const pathId = isLegacy ? 'id' : 'laboratoryAnalysis.id'

    const name = get(service, pathName) as string
    const id = get(service, pathId) as string

    return {
      id,
      name,
    }
  })

  return {
    services,
  }
}

export function formattedCardDetails(record: RecordType) {
  if (!record) return null

  const activationByPersonalData = sanitizeActivationByPersonalData(
    get(record, 'activationByPersonalData')
  )

  const activationForPersonalData = sanitizeActivationForPersonalData(
    get(record, 'activationForPersonalData')
  )

  const purchase = sanitizePurchase(get(record, 'purchase'))

  const purchaseHasProductHasAnalysis = sanitizePurchaseHasProductHasAnalysis(
    get(record, 'isLegacy'),
    get(record, 'purchaseHasProductHasAnalysis')
  )

  return {
    purchase,
    activationByPersonalData,
    activationForPersonalData,
    purchaseHasProductHasAnalysis,
  }
}
