// Vendors
import React from 'react'

// Components
import { FieldText } from 'components/FieldText'
import { FieldNumber } from 'components/FieldNumber'
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import { Grid, GridItem } from '@chakra-ui/react'

// Types
import { UseFormReturn } from 'react-hook-form'

type TabGeneralProps = {
  methods: UseFormReturn
}

export const TabGeneral = (props: TabGeneralProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { methods } = props

  const {
    control,
    register,
    formState: { errors },
  } = methods

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Grid
      gap="10"
      my="4"
      templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
    >
      <GridItem colSpan={2}>
        <FieldText
          label="Nome"
          error={errors.name}
          {...register('name')}
          isRequired
        />
      </GridItem>

      <GridItem colSpan={2}>
        <FieldNumber
          control={control}
          label="Prazo p/ processamento de laudo"
          name="medicalReportDeliveryEstimateInDays"
          error={errors.medicalReportDeliveryEstimateInDays}
          isRequired
        />
      </GridItem>

      <GridItem colSpan={2}>
        <FieldTextArea
          label="Descrição"
          error={errors.description}
          isRequired
          {...register('description')}
        />
      </GridItem>
    </Grid>
  )
}
