// Vendors
import { sub } from 'date-fns'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

// Functions
import { formatDate } from 'utils/formatDate'
import { useTranslation } from 'react-i18next'
import { useListRecords } from 'hooks/useListRecords'

// Components
import { ListCard } from 'components/ListCard'
import { ListFilters } from 'components/ListFilters'
import { ListPagination } from 'components/ListPagination'
import { FieldDateRangePicker } from 'components/FieldDateRangePicker'

// Styles
import { Accordion, Box, Checkbox, Stack } from '@chakra-ui/react'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Types
import { Audit } from './types'

import { PageMetaSetting } from './meta'

export const PageAuditListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('audit')
  const history = useHistory()
  const { endpoint, title } = PageMetaSetting
  const { records, query, pagination } = useListRecords<Audit>({
    endpoint,
    query: {
      sort: '-createdAt',
    },
  })

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [period, setPeriod] = useState<{ start?: Date; end?: Date }>({
    start: undefined,
    end: undefined,
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handlePeriodChange = async (dates: [Date, Date]) => {
    const [start, end] = dates

    setPeriod({
      start,
      end,
    })

    if (!end) return

    const startDate = formatDate(new Date(start), 'yyyy-MM-dd')
    const endDate = formatDate(new Date(end), 'yyyy-MM-dd')

    query.set((oldState) => ({
      ...oldState,
      startDate,
      endDate,
    }))
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={title}>
      <Stack
        spacing="4"
        justify="space-between"
        direction={{ base: 'column', md: 'row' }}
      >
        <Stack direction={{ base: 'column', md: 'row' }}>
          <ListFilters query={query} />
          <FieldDateRangePicker
            isSmall
            name="filterDate"
            query={query}
            placeholderText="Filtrar por data"
            containerProps={{
              marginBottom: '0',
              width: '320px',
            }}
            showMonthDropdown
            dropdownMode="select"
          />

          <Checkbox
            width="full"
            onChange={(e) => {
              query.set((oldState) => ({
                ...oldState,
                isException: e.target.checked,
              }))
            }}
          >
            Filtrar por exceções
          </Checkbox>
        </Stack>
      </Stack>

      <Accordion spacing="6" py="4" allowToggle>
        {records.list.map((record) => (
          <ListCard key={record.id} record={record} />
        ))}
      </Accordion>

      <ListPagination pagination={pagination} query={query} />
    </LayoutLoggedPageList>
  )
}
