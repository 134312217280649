// Vendors
import { debounce } from 'lodash'
import React, {
  useState,
  FocusEvent,
  ChangeEvent,
  useCallback,
  useMemo,
} from 'react'

// Components
import { FieldText } from 'components/FieldText'
import { FieldSelect } from 'components/FieldSelect'
import { FieldToggle } from 'components/FieldToggle'

// Styles
import {
  Box,
  Flex,
  Stack,
  Button,
  IconButton,
  SimpleGrid,
} from '@chakra-ui/react'
import { FiMinus, FiPlusCircle } from 'react-icons/fi'

// Interfaces
import { HandleQuestionType, QuestionType } from 'hooks/useFormFieldEditer'
import {
  FormConstraintEnum,
  FormConstraintType,
} from 'types/LaboratorialAnalysis'
import { useTranslation } from 'react-i18next'

type ConstatintsOptionType = {
  label: string
  value: keyof typeof FormConstraintEnum
}

type ConstraintValue = FormConstraintType & {
  option: ConstatintsOptionType | undefined
}

type ConstraintsType = {
  values: ConstraintValue[]
  options: ConstatintsOptionType[]
}

export type FormFieldEditerFooterProps = {
  index: number
  question: QuestionType
  handleQuestion: HandleQuestionType
}

export const FormFieldEditerFooter = (
  props: FormFieldEditerFooterProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { index: indexFormFieldEditer, handleQuestion, question } = props
  const { t } = useTranslation('common')

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const constraintsSelectOptionsDefault = useMemo(() => {
    return Object.keys(FormConstraintEnum).map((key) => {
      return {
        label: t(`constraints.${key}`),
        value: key as keyof typeof FormConstraintEnum,
      }
    })
  }, [t])

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [constraints, setConstraints] = useState<ConstraintsType>(() => {
    if (!question.constraints.length) {
      return { values: [], options: constraintsSelectOptionsDefault }
    }

    const newConstraints = question.constraints.map((constraint) => {
      const option = constraintsSelectOptionsDefault.find(
        (option) => option.value === constraint.name
      )

      return { ...constraint, option }
    })

    const newOptions = constraintsSelectOptionsDefault.filter((option) =>
      newConstraints.find((constraint) => constraint.name !== option.value)
    )

    return { values: newConstraints, options: newOptions }
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleAddConstraints = useCallback(() => {
    setConstraints((oldState) => {
      const newConstraints = [
        ...oldState.values,
        {
          value: '',
          option: oldState.options[0],
          name: oldState.options[0].value,
        },
      ]

      const newOptions = oldState.options.slice(1)

      return { values: newConstraints, options: newOptions }
    })
  }, [])

  const handleRemoveConstraints = useCallback(
    (index: number) => {
      const newConstraints = constraints.values.filter((_, i) => i !== index)
      const newOptions = [
        ...constraints.options,
        constraints.values[index].option!,
      ]

      setConstraints({ values: newConstraints, options: newOptions })
      handleQuestion.update(indexFormFieldEditer, 'constraints', newConstraints)
    },
    [constraints, indexFormFieldEditer, handleQuestion]
  )

  const onChangeNameConstraints = useCallback(
    (index: number, e: ConstatintsOptionType) => {
      let newOptions = [
        ...constraints.options,
        constraints.values[index].option!,
      ]

      newOptions = newOptions.filter((option) => option.value !== e.value)

      const newConstraints = constraints.values.map((constraint, i) => {
        if (i === index) {
          return { ...constraint, name: e.value, option: e }
        }

        return constraint
      })

      setConstraints({ values: newConstraints, options: newOptions })
    },
    [constraints]
  )

  const onChangeValueConstraints = useCallback(
    (index: number, e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      setConstraints((oldState) => {
        const newConstraints = oldState.values.map((constraint, i) => {
          if (i === index) return { ...constraint, value }

          return constraint
        })

        return { ...oldState, values: newConstraints }
      })
    },
    []
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onBlurConstraints = useCallback(
    debounce((e?: FocusEvent<HTMLInputElement>) => {
      if (!e) return

      if (e.target.value === '') return

      handleQuestion.update(
        indexFormFieldEditer,
        'constraints',
        constraints.values
      )
    }, 500),
    [indexFormFieldEditer, handleQuestion, constraints]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <SimpleGrid columns={2} gap="8">
      <Stack>
        {constraints.values.map((constraint, index) => (
          <Flex
            key={`${constraint.name}-${indexFormFieldEditer}`}
            alignItems="center"
          >
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              alignItems="center"
              w="100%"
            >
              <Box flex="2" w="100%">
                <FieldSelect
                  name="field"
                  blurInputOnSelect={true}
                  value={constraint.option}
                  onBlur={() => onBlurConstraints()}
                  options={constraints.options}
                  onChange={(e) =>
                    onChangeNameConstraints(index, e as ConstatintsOptionType)
                  }
                />
              </Box>
              <Box flex="3" w="100%">
                <FieldText
                  name="foo"
                  value={constraint.value}
                  onBlur={onBlurConstraints}
                  placeholder="Digite a restrição aqui..."
                  onChange={(e) => onChangeValueConstraints(index, e)}
                />
              </Box>
            </Stack>
            <IconButton
              ml="2"
              size="xs"
              variant="outline"
              borderWidth="2px"
              colorScheme="red"
              borderRadius="full"
              aria-label="remove constraints"
              icon={<FiMinus strokeWidth="4px" />}
              onClick={() => handleRemoveConstraints(index)}
            />
          </Flex>
        ))}
      </Stack>

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        alignItems={{ lg: 'flex-start' }}
        justifyContent={{ lg: 'flex-end' }}
        flexWrap="wrap"
      >
        <Box>
          <FieldToggle
            size="md"
            label="Obrigatório"
            labelDirection="left"
            name={`required-${indexFormFieldEditer}`}
            isChecked={question.required}
            onChange={(e) =>
              handleQuestion.update(
                indexFormFieldEditer,
                'required',
                e.target.checked
              )
            }
          />
        </Box>

        <Button
          variant="outline"
          colorScheme="blue"
          leftIcon={<FiPlusCircle />}
          aria-label="add constraints"
          onClick={handleAddConstraints}
          isDisabled={constraints.options.length === 0}
        >
          Restrição
        </Button>
      </Stack>
    </SimpleGrid>
  )
}
