// Vendors
import { useCallback, useMemo } from 'react'
import axios, { AxiosError } from 'axios'

// Functions
import { apiPost } from 'services/post'

// Styles

// Types
import { Record } from 'types/Requests'
import { apiPatch } from 'services/patch'
import { useError } from './useError'

type CreateRecord = {
  endpoint: string
}

type CreateRecordResponse<T> = {
  create: (data: T) => Promise<(T & Record) | undefined>
  upload: (
    file: File,
    endpointUpload: string,
    options?: object,
    httpMethod?: 'POST' | 'PATCH'
  ) => Promise<void>
}

export function useCreateRecord<T>(
  props: CreateRecord
): CreateRecordResponse<T> {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props
  const { handleError } = useError()
  const httpMethods = useMemo(
    () => ({
      POST: apiPost,
      PATCH: apiPatch,
    }),
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const uploadFile = useCallback(
    async (
      file: File,
      endpointUpload: string,
      options?: object,
      httpMethod?: 'POST' | 'PATCH'
    ) => {
      const formData = new FormData()
      const httpMethodApi = httpMethods[httpMethod || 'POST'] as any

      Object.entries(options || {}).forEach(([key, value]) => {
        formData.append(key, value)
      })

      formData.append('file', file)

      try {
        await httpMethodApi(endpointUpload, formData)
      } catch (error: any) {
        const errorMessage =
          error.response.data.message || error.message || 'Erro desconhecido'

        throw new Error(errorMessage)
      }
    },
    [httpMethods]
  )

  const createRecord = useCallback(
    async (data: T) => {
      try {
        const response = await apiPost<T>(`${endpoint}`, data)

        return response as T & Record
      } catch (error: unknown | AxiosError) {
        if (!axios.isAxiosError(error)) {
          console.trace(error)
          return
        }

        if (!error.response) {
          console.trace(error)
          return
        }

        switch (error.response.status) {
          case 422: {
            const errorHandled = handleError(error, undefined, { 422: true })

            if (errorHandled && errorHandled instanceof Array) {
              throw errorHandled
            } else {
              handleError(error)
            }

            break
          }
          default: {
            handleError(error)

            console.trace(error)
            throw new Error()
          }
        }
      }
    },
    [endpoint, handleError]
  )

  return {
    create: createRecord,
    upload: uploadFile,
  }
}
