// Vendors
import React from 'react'
import { FieldError } from 'react-hook-form'

// Styles
import {
  Text,
  Input,
  HStack,
  FormLabel,
  InputProps,
  InputGroup,
  FormControl,
  FormErrorMessage,
  Flex,
  IconButton,
} from '@chakra-ui/react'
import { Tooltip, TooltipProps } from 'components/Tooltip'

// Interfaces
import { IconType } from 'react-icons'
import { FiPaperclip } from 'react-icons/fi'

export type FieldIconFileProps = InputProps & {
  name: string
  label?: string
  icon?: IconType
  error?: FieldError
}

const FieldIconFileBase: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FieldIconFileProps
> = (props: FieldIconFileProps, ref): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { name, label, error, icon: Icon, ...rest } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel htmlFor={name} mb={0}>
          {label}
        </FormLabel>
      )}

      <InputGroup>
        <Input
          id={name}
          ref={ref}
          name={name}
          type="file"
          display="none"
          {...rest}
        />
        <IconButton
          htmlFor={name}
          as="label"
          colorScheme="blue"
          aria-label="upload"
          icon={Icon ? <Icon /> : <FiPaperclip />}
          cursor="pointer"
        />
      </InputGroup>

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const FieldIconFile = React.forwardRef(FieldIconFileBase)
