// Vendors
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'

// Hooks
import { apiPost } from 'services/post'
import { useError } from 'hooks/useError'

// Components
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import {
  Text,
  Stack,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react'

type ModalAuthorizeShipmentProps = {
  isOpen: boolean
  purchaseId: string
  reload: () => void
  onCancel: () => void
}

type FormAlterKitStatus = {
  comments: string
}

export const ModalAuthorizeShipment = (props: ModalAuthorizeShipmentProps) => {
  const { isOpen, onCancel, reload, purchaseId } = props

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormAlterKitStatus>()

  const { handleError } = useError()

  const onClose = useCallback(() => {
    reset()
    onCancel()
  }, [onCancel, reset])

  const onSubmit = useCallback(
    async (data: FormAlterKitStatus) => {
      try {
        const { comments } = data

        await apiPost(`/app/purchase/${purchaseId}/confirm_payment`, {
          comments,
        })

        reload()
        onClose()
      } catch (error) {
        handleError(error)
      }
    },
    [handleError, onClose, purchaseId, reload]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Autorizar expedição</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Stack>
            <Text color="gray.500">
              Por favor, inclua um motivo para a operação, você também pode
              anexar documentos na aba Histórico do pedido
            </Text>

            <FieldTextArea
              label="Comentários"
              error={errors.comments}
              placeholder="Digite algum comentário"
              {...register('comments', {
                required: 'Por favor, preencha o campo comentários',
              })}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose}>Cancelar</Button>
            <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
