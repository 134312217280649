import React from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'

type FieldNoImageProps = {
  label: string
}

export const FieldNoImage = (props: FieldNoImageProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { label } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <Heading fontSize="md">{label}</Heading>
      <Text
        mt="2"
        fontSize="sm"
        lineHeight="3"
        color="gray.500"
        fontStyle="italic"
      >
        É necessário salvar este registro antes de incluir imagens
      </Text>
    </Box>
  )
}
