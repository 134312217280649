// Vendors
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

// Functions
import { useCreateRecord } from 'hooks/useCreateRecord'

// Components
import { FieldMask } from 'components/FieldMask'
import { FieldText } from 'components/FieldText'
import { FieldDate } from 'components/FieldDate'
import { FormFooter } from 'components/FormFooter'
import { FieldToggle } from 'components/FieldToggle'
import { FieldRadioButton } from 'components/FieldRadioButton'

// Schemas
import { schema } from './schema'

// Styles
import { Flex, Grid, Box, GridItem, useToast } from '@chakra-ui/react'

// Types
import { UserContentManagerNewForm, UserContentManagerNewRecord } from './types'

type ContentManagerFormNewProps = {
  endpoint: string
}

export const ContentManagerFormNew = (
  props: ContentManagerFormNewProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props

  const { t } = useTranslation('common')
  const history = useHistory()

  const { create } = useCreateRecord({ endpoint })

  const {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserContentManagerNewForm>({
    resolver: yupResolver(schema(t)),
  })
  const toast = useToast({ position: 'top-right' })

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const genresOptions = useMemo(
    () => [
      {
        label: 'Masculino',
        value: 'M',
      },
      {
        label: 'Feminino',
        value: 'F',
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<UserContentManagerNewRecord> =
    React.useCallback(
      async (data) => {
        try {
          const formData = { ...data, roleName: 'CONTENT_MANAGER' }

          await create(formData)

          toast({
            title: 'Sucesso',
            description: 'Gestor de conteúdo criado com sucesso',
            status: 'success',
          })

          history.push('/users')
        } catch (error) {
          if (error && error instanceof Array) {
            error.forEach((fieldError) => {
              setError(fieldError.field, { message: fieldError.message })
            })
          }
        }
      },
      [create, history, setError, toast]
    )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex
      as="form"
      h="full"
      flexDirection="column"
      justifyContent="space-between"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box>
        <Controller
          name="status"
          control={control}
          defaultValue={true}
          render={({ field: { onChange, value, ref, name } }) => (
            <FieldToggle
              name={name}
              position="absolute"
              top={-16}
              right={0}
              ref={ref}
              isChecked={value}
              onChange={onChange}
              error={errors.status}
            />
          )}
        />

        <Grid
          my="4"
          gap="10"
          templateColumns={{ lg: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
        >
          <GridItem colSpan={2}>
            <FieldText
              label="Nome"
              error={errors.name}
              isRequired
              {...register('name')}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldText
              canModify={false}
              label="E-mail"
              error={errors.email}
              isRequired
              {...register('email')}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldMask
              label="Telefone"
              mask="(99) 9 9999-9999"
              error={errors.phoneNumber}
              isRequired
              {...register('phoneNumber')}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldMask
              label="CPF"
              mask="999.999.999-99"
              error={errors.documentNumber}
              isRequired
              {...register('documentNumber')}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <Controller
              name="birthdate"
              control={control}
              render={({ field }) => (
                <FieldDate
                  label="Data de nascimento"
                  name={field.name}
                  selected={field.value}
                  onChange={field.onChange}
                  error={errors.birthdate}
                  isRequired
                />
              )}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldRadioButton
              label="Gênero"
              name="gender"
              control={control}
              radioButtonDirection="row"
              options={genresOptions}
              isRequired
            />
          </GridItem>
        </Grid>
      </Box>

      <FormFooter isSubmitting={isSubmitting} hasAlert />
    </Flex>
  )
}
