import {
  FormQuestionType,
  LaboratorialAnalysis,
} from 'types/LaboratorialAnalysis'
import { Record } from 'types/Requests'

export enum ProductReverseLogisticStatus {
  KIT_ACTIVATED,
  KIT_ACCEPTED,
  DIVERGENT_DATA,
  SAMPLE_DENIED,
  IN_ANALYSIS,
  REPORT_GENERATED,
}

export type KitCheckInShow = {
  isLegacy: boolean
  status: string
  purchase: {
    purchaseType: 'B2B' | 'B2C' | 'LEGACY'
    purchaseNumber: string
    isCobranding: boolean
  }
  productReverseLogistic?: {
    status: keyof typeof ProductReverseLogisticStatus
  }
  activationByPersonalData: Record & {
    email: string
    birthdate: string
    documentNumber: string
    name: string
  }
  activationForPersonalData: Record & {
    email: string
    birthdate: string
    documentNumber: string
    name: string
    gender: 'M' | 'F' | 'NOT_INFORMED'
  }
  purchaseHasProductHasAnalysis:
    | PurchaseHasProductHasAnalysisType[]
    | LaboratorialAnalysis[]
}

type PurchaseHasProductHasAnalysisType = {
  laboratoryAnalysis: LaboratorialAnalysis
  clinicalRecordFormResponse?: {
    questions: FormQuestionType[]
  } | null
}

type BillingDataType = {
  customer: CustomerType
}

type CustomerType = {
  id: string
  type: string
  name: string
  email: string
  object: string
  bornAt: string
  gender: string
  country: string
  birthday: string
  dateCreated: Date
  externalId: string
  documentType: string
  documents: Document[]
  phoneNumbers: string[]
  documentNumber: string
}

type Document = {
  id: string
  type: string
  number: string
  object: string
}

export type KitCheckInForm = {
  internalLaboratoryId?: string
  dateLimit: Date
}
