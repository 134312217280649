import { IconType } from 'react-icons'
import {
  MdOutlineDeleteSweep,
  MdOutlineDoNotDisturb,
  MdPostAdd,
  MdOutlineFileDownload,
  MdWarningAmber,
  MdOutlineContacts,
  MdOutlineCheck,
  MdOutlineClose,
  MdOutlineFiberNew,
  MdOutlineLocalShipping,
  MdOutlineComment,
  MdOutlineUploadFile,
  MdOutlinePayments,
  MdFactCheck,
  MdFeedback,
  MdPersonAddAlt,
  MdStorefront,
  MdPointOfSale,
  MdSecurity,
  MdDriveFileRenameOutline,
  MdCached,
  MdOutlineAccountTree,
  MdUploadFile,
} from 'react-icons/md'

export enum HistoryType {
  CREATE = 'CREATE',
  CANCELLED = 'CANCELLED',
  DELETE = 'DELETE',
  DOWNLOAD = 'DOWNLOAD',
  FORCE_UPDATE_STATUS = 'FORCE_UPDATE_STATUS',
  KIT_ACTIVATE = 'KIT_ACTIVATE',
  KIT_ALLOW = 'KIT_ALLOW',
  KIT_DENY = 'KIT_DENY',
  KIT_NEW = 'KIT_NEW',
  LOGISTIC = 'LOGISTIC',
  MANUAL_UPDATE_STATUS = 'MANUAL_UPDATE_STATUS',
  NOTE = 'NOTE',
  NOTE_UPLOAD = 'NOTE_UPLOAD',
  PAYMENT = 'PAYMENT',
  PROFESSIONAL_ALLOW = 'PROFESSIONAL_ALLOW',
  PROFESSIONAL_DENY = 'PROFESSIONAL_DENY',
  PROFESSIONAL_SUBMIT = 'PROFESSIONAL_SUBMIT',
  PURCHASE_CREATE = 'PURCHASE_CREATE',
  PURCHASE_CREATE_BACK = 'PURCHASE_CREATE_BACK',
  SECURITY = 'SECURITY',
  UPDATE = 'UPDATE',
  UPDATE_ID = 'UPDATE_ID',
  UPDATE_STATUS = 'UPDATE_STATUS',
  UPLOAD = 'UPLOAD',
}

type Map = {
  icon: IconType
}

export const iconsMap = {
  CANCELLED: {
    icon: MdOutlineDoNotDisturb,
  },
  CREATE: {
    icon: MdPostAdd,
  },
  DELETE: {
    icon: MdOutlineDeleteSweep,
  },
  DOWNLOAD: {
    icon: MdOutlineFileDownload,
  },
  FORCE_UPDATE_STATUS: {
    icon: MdWarningAmber,
  },
  KIT_ACTIVATE: {
    icon: MdOutlineContacts,
  },
  KIT_ALLOW: {
    icon: MdOutlineCheck,
  },
  KIT_DENY: {
    icon: MdOutlineClose,
  },
  KIT_NEW: {
    icon: MdOutlineFiberNew,
  },
  LOGISTIC: {
    icon: MdOutlineLocalShipping,
  },
  NOTE: {
    icon: MdOutlineComment,
  },
  NOTE_UPLOAD: {
    icon: MdOutlineUploadFile,
  },
  PAYMENT: {
    icon: MdOutlinePayments,
  },
  PROFESSIONAL_ALLOW: {
    icon: MdFactCheck,
  },
  PROFESSIONAL_DENY: {
    icon: MdFeedback,
  },
  PROFESSIONAL_SUBMIT: {
    icon: MdPersonAddAlt,
  },
  PURCHASE_CREATE: {
    icon: MdStorefront,
  },
  PURCHASE_CREATE_BACK: {
    icon: MdPointOfSale,
  },
  SECURITY: {
    icon: MdSecurity,
  },
  UPDATE: {
    icon: MdDriveFileRenameOutline,
  },
  UPDATE_ID: {
    icon: MdCached,
  },
  UPDATE_STATUS: {
    icon: MdOutlineAccountTree,
  },
  UPLOAD: {
    icon: MdUploadFile,
  },
  MANUAL_UPDATE_STATUS: {
    icon: MdOutlineAccountTree,
  },
  UPDATE_REPORT_EMISSION_DATE: {
    icon: MdWarningAmber,
  },
}
