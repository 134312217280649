import React from 'react'
import Lottie from 'react-lottie'
import animationData from 'assets/animations/loading.json'
import { Box } from '@chakra-ui/react'

export const Loading: React.FC = () => {
  return (
    <Box
      h="100vh"
      w="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Lottie
        height={400}
        width={400}
        options={{
          animationData,
          autoplay: true,
          loop: true,
        }}
      />
    </Box>
  )
}

export default Loading
