// Vendors
import React, { useEffect, Dispatch, SetStateAction, useState } from 'react'
import { Controller, useFormContext, FieldError } from 'react-hook-form'
import _, { filter, get } from 'lodash'

// Functions
import { useEditRecords, RecordResponse } from 'hooks/useEditRecord'

// Components
import { FieldText } from 'components/FieldText'
import { ListImages } from 'components/ListImages'
// import { TextEditor } from 'components/TextEditor'
import { FieldToggle } from 'components/FieldToggle'
import { FieldRepeater } from 'components/FieldRepeater'
import { FieldTextArea } from 'components/FieldTextArea'
import { FieldSelectColor } from 'components/FieldSelectColor'

// Styles
import { Heading, Grid, GridItem, Box } from '@chakra-ui/react'

// Types
import { CategoryShow, CategoryShowRecord, AccentColorEnum } from '../types'

const TextEditor = React.lazy(() => import('components/TextEditor'))
type TabMarketingProps = {
  endpoint: string
  id: string
  record: RecordResponse<CategoryShow, CategoryShowRecord>
  setTextEditorData: Dispatch<SetStateAction<string>>
  textEditorData: string
  accentColorOptions: Array<{
    value: AccentColorEnum
    label: string
  }>
}

type DataReport = {
  fileName: string
  filePath: string
  fileUri: string
}

export const TabMarketing = (props: TabMarketingProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const {
    endpoint,
    id,
    setTextEditorData,
    accentColorOptions,
    textEditorData,
  } = props

  const { record } = useEditRecords<CategoryShow, CategoryShowRecord>({
    endpoint: { public: endpoint, private: '/app/store_product_category' },
    currentId: id,
  })

  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [dataReport, setDataReport] = useState<DataReport>({} as DataReport)

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    if (record.current.mktReportFileName === dataReport.fileName) return

    if (
      record.current.mktReportFileName &&
      record.current.mktReportFilePath &&
      record.current.mktReportFileUri
    ) {
      const dataReport = Object.assign(
        {},
        ...Object.keys(record.current)
          .filter((param) => param.includes('ReportFile'))
          .map((param) => {
            const croppedParam = param.replace('mktReport', '')
            const firstLetter = croppedParam.charAt(0).toLowerCase()
            const paramWithoutFirstLetter = croppedParam.slice(1)

            const newParam = `${firstLetter}${paramWithoutFirstLetter}`

            return {
              [newParam]:
                record.current[
                  param as
                    | 'mktReportFileName'
                    | 'mktReportFilePath'
                    | 'mktReportFileUri'
                ],
            }
          })
      )
      setDataReport({ ...dataReport, originalFileName: `Laudo ${id}` })
    }
  }, [dataReport.fileName, id, record])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <Box my={{ base: 2, lg: 4 }}>
        <Heading as="h2" fontSize={20} textColor="#000000">
          Informações iniciais
        </Heading>
        <Grid
          gap="10"
          my="4"
          templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
        >
          <GridItem colSpan={{ base: 2, lg: 1 }}>
            <Controller
              name="accentColor"
              control={control}
              render={({ field }) => (
                <FieldSelectColor
                  label="Cor de destaque"
                  error={errors.accentColor as FieldError}
                  options={accentColorOptions}
                  isRequired={!!watch('isPage')}
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2, lg: 1 }}>
            <Controller
              name="isPage"
              control={control}
              render={({ field: { value, ref, name, onChange } }) => (
                <FieldToggle
                  name={name}
                  label="Apresentar como página "
                  ref={ref}
                  isChecked={value}
                  error={errors.isPage}
                  onChange={onChange}
                />
              )}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2 }} />

          <GridItem colSpan={{ base: 2 }}>
            <FieldText
              maxLength={45}
              currentLength={get(watch('mktTitle'), 'length')}
              label="Título da página"
              error={errors.mktTitle}
              isRequired={!!watch('isPage')}
              {...register('mktTitle')}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2 }} />

          <GridItem colSpan={{ base: 2 }}>
            <FieldTextArea
              maxLength={50}
              currentLength={get(watch('mktDescription'), 'length')}
              label="Descrição da página"
              error={errors.mktDescription}
              isRequired={!!watch('isPage')}
              {...register('mktDescription')}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2 }}>
            <FieldTextArea
              label="Resumo da página"
              error={errors.mktAbstract}
              isRequired={!!watch('isPage')}
              {...register('mktAbstract')}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2, lg: 4 }}>
            <ListImages
              isSingle
              group="FEATURED_IMAGE"
              title="IMAGEM DESTAQUE"
              records={{
                get: {
                  list: filter(record.current.images, {
                    group: 'FEATURED_IMAGE',
                  }),
                },
                set: () => {},
              }}
              endpoint={{
                post: `/app/store_product_category/${id}/upload`,
                delete: `/app/store_product_category/${id}/image`,
                patch: `/app/store_product_category/${id}/image`,
              }}
              reloadRecord={record.reload}
            />
          </GridItem>
          <GridItem colSpan={{ base: 2, lg: 4 }}>
            <ListImages
              isSingle
              group="ICON"
              title="ÍCONE"
              records={{
                get: {
                  list: filter(record.current.images, {
                    group: 'ICON',
                  }),
                },
                set: () => {},
              }}
              endpoint={{
                post: `/app/store_product_category/${id}/upload`,
                delete: `/app/store_product_category/${id}/image`,
                patch: `/app/store_product_category/${id}/image`,
              }}
              reloadRecord={record.reload}
            />
          </GridItem>
        </Grid>
      </Box>

      <Box my={{ base: 2, lg: 4 }}>
        <Heading as="h2" fontSize={20} textColor="#000000">
          Introdução
        </Heading>
        <Grid
          gap="10"
          my="4"
          templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
        >
          <GridItem colSpan={{ base: 2, lg: 2 }}>
            <FieldText
              maxLength={120}
              currentLength={get(watch('mktTitleIntrodutoryText'), 'length')}
              label="Título do texto introdutório"
              error={errors.mktTitleIntrodutoryText}
              isRequired={!!watch('isPage')}
              {...register('mktTitleIntrodutoryText')}
            />
          </GridItem>
          <GridItem colSpan={{ base: 2, lg: 4 }}>
            <TextEditor
              setTextEditorData={setTextEditorData}
              defaultValue={textEditorData}
            />
          </GridItem>
        </Grid>
      </Box>

      <Box my={{ base: 2, lg: 4 }}>
        <FieldRepeater
          name="mktFeatures.features"
          error={errors.mktFeatures?.features}
          label="Features"
          control={control}
          register={register}
          gridProps={{ columns: 2, gap: 4 }}
          defaultValue={{ icon: '', label: '', url: '' }}
          fieldComponents={[
            {
              component: FieldText,
              type: 'register',
              props: {
                name: 'title',
                placeholder: 'Digite...',
                label: 'Título',
                isRequired: true,
              },
            },
            {
              component: FieldText,
              type: 'register',
              props: {
                name: 'description',
                placeholder: 'Digite...',
                label: 'Descrição',
                isRequired: true,
              },
            },
          ]}
        />

        <GridItem colSpan={{ base: 2, lg: 4 }} mt={4}>
          <ListImages
            isSingle
            group="FEATURED_IMAGE_FEATURE"
            title="IMAGEM DE DESTAQUE FEATURE"
            records={{
              get: {
                list: filter(record.current.images, {
                  group: 'FEATURED_IMAGE_FEATURE',
                }),
              },
              set: () => {},
            }}
            endpoint={{
              post: `/app/store_product_category/${id}/upload`,
              delete: `/app/store_product_category/${id}/image`,
              patch: `/app/store_product_category/${id}/image`,
            }}
            reloadRecord={record.reload}
          />
        </GridItem>
      </Box>

      <Box my={{ base: 2, lg: 4 }}>
        <Heading as="h2" fontSize={20} textColor="#000000">
          Entenda o laudo
        </Heading>
        <Grid
          gap="10"
          my="4"
          templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
        >
          <GridItem colSpan={{ base: 2, lg: 2 }}>
            <FieldText
              maxLength={45}
              currentLength={get(watch('mktTitleReport'), 'length')}
              label="Título"
              error={errors.mktTitleReport}
              isRequired={!!watch('isPage')}
              {...register('mktTitleReport')}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2, lg: 2 }}>
            <FieldTextArea
              label="Descrição"
              maxLength={1024}
              currentLength={get(watch('mktDescriptionReport'), 'length')}
              error={errors.mktDescriptionReport}
              isRequired={!!watch('isPage')}
              {...register('mktDescriptionReport')}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2, lg: 4 }} x>
            <ListImages
              isSingle
              group="FEATURED_IMAGE_REPORT"
              title="IMAGEM DE DESTAQUE DO LAUDO"
              records={{
                get: {
                  list: filter(record.current.images, {
                    group: 'FEATURED_IMAGE_REPORT',
                  }),
                },
                set: () => {},
              }}
              endpoint={{
                post: `/app/store_product_category/${id}/upload`,
                delete: `/app/store_product_category/${id}/image`,
                patch: `/app/store_product_category/${id}/image`,
              }}
              reloadRecord={record.reload}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2, lg: 4 }}>
            <ListImages
              isSingle
              group="PDF_REPORT"
              type="document"
              title="PDF DO LAUDO"
              hasTitle={false}
              hasDescription={false}
              records={{
                get: {
                  list: dataReport.fileName ? [dataReport] : [],
                },
                set: () => {},
              }}
              endpoint={{
                post: `/app/store_product_category/${id}/upload-report-file`,
                delete: `/app/store_product_category/${id}/upload-report-file`,
                patch: `/app/store_product_category/${id}/upload-report-file`,
              }}
              reloadRecord={record.reload}
            />
          </GridItem>
        </Grid>
      </Box>
    </Box>
  )
}
