// Vendors
import React, { ReactElement, useCallback } from 'react'

// Components
import Todo from 'tools/Todo'
import { FiInfo } from 'react-icons/fi'

// Styles
import {
  Box,
  Tag,
  Text,
  Tooltip as ChakraTooltip,
  TooltipProps as ChakraTooltipProps,
  useTheme,
} from '@chakra-ui/react'

// Interfaces
export type TooltipProps = {
  label: ReactElement | string
} & Omit<ChakraTooltipProps, 'children'>

export const Tooltip = (props: TooltipProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { label, ...rest } = props
  const theme = useTheme()

  const labelIsReactNode = Object.keys(label).length > 1

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <ChakraTooltip
      label={labelIsReactNode ? label : <Text>{label}</Text>}
      openDelay={500}
      closeDelay={100}
      {...rest}
    >
      <Box>
        <FiInfo color={theme.colors.gray['500']} />
      </Box>
    </ChakraTooltip>
  )
}
