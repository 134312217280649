// Vendors
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { schema } from './schema'

// Functions
import { useCreateRecord } from 'hooks/useCreateRecord'

// Styles
import { Flex, useToast } from '@chakra-ui/react'

// Types
import { AccentColorEnum, CategoryNewRecord } from './types'
import { Tabs, TabDetail } from 'components/Tabs'
import { FormFooter } from 'components/FormFooter'
import { TabMarketing } from './tabs/TabMarketing'
import { TabGeneral } from './tabs/TabGeneral'
import { TabSeo } from 'components/TabSeo'

type CategoryFormNewProps = {
  endpoint: string
}

export const CategoryFormNew = (props: CategoryFormNewProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props

  const toast = useToast({ position: 'top-right' })
  const { t } = useTranslation('categories')
  const history = useHistory()
  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  const { create } = useCreateRecord<CategoryNewRecord>({
    endpoint,
  })

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [textEditorData, setTextEditorData] = useState('')

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const accentColorOptions = useMemo(() => {
    return Object.entries(AccentColorEnum).map(([key, value]) => ({
      label: value,
      value: key,
    }))
  }, [])

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: t('tabs.tabGeneral'),
        component: TabGeneral,
        names: ['title', 'description'],
        options: {
          endpoint: endpoint,
          options: accentColorOptions,
        },
      },
      {
        title: t('tabs.marketing'),
        component: TabMarketing,
        names: [
          'isPage',
          'accentColor',
          'mktTitle',
          'mktAbstract',
          'mktFeatures',
          'mktTitleReport',
          'mktDescription',
          'mktDescriptionReport',
          'mktTitleIntrodutoryText',
        ],
        options: {
          endpoint: endpoint,
          setTextEditorData: setTextEditorData,
          textEditorData: textEditorData,
          accentColorOptions: accentColorOptions,
        },
      },
      {
        title: t('common:tabs.seo'),
        component: TabSeo,
        names: ['slug', 'seoTitle', 'seoKeywords', 'seoDescription'],
      },
    ],
    [accentColorOptions, endpoint, t, textEditorData]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<CategoryNewRecord> = React.useCallback(
    async (data) => {
      try {
        const newData = {
          ...data,
          mktIntrodutoryText: textEditorData,
        }

        await create(newData)

        toast({
          title: t('common:toast.success.default', {
            type: t('common:toast.success.create'),
          }),
          status: 'success',
        })

        history.push('/category')
      } catch (error: unknown) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [create, history, methods, t, textEditorData, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormProvider {...methods}>
      <Flex
        h="full"
        flexDirection="column"
        justifyContent="space-between"
        as="form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Tabs tabsDetails={tabsDetails} errors={methods.formState.errors} />
        <FormFooter isSubmitting={methods.formState.isSubmitting} />
      </Flex>
    </FormProvider>
  )
}
