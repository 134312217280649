// Vendors
import React, { useMemo, useCallback, useEffect } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'

// Functions
import { useListRecords } from 'hooks/useListRecords'
import { usePageMeta } from 'hooks/usePageMeta'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/formatDate'

// Components
import { ListPagination } from 'components/ListPagination'
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'

// Styles
import { FiPlus } from 'react-icons/fi'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'
import { Stack, Button } from '@chakra-ui/react'

// Types
import { Coupons } from './types'
import { ListActions } from 'components/ListActions'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

export const PageCouponListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('common')
  const history = useHistory()
  const { endpoint, title } = usePageMeta(useLocation())
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<Coupons>({ endpoint })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    Records.list.forEach((record) => {
      record.discountPercentage = record.discountPercentage
        ? `${record.discountPercentage}%`
        : '-'
      record.validFrom = formatDate(record.validFrom, 'dd/MM/yyyy')
      record.validUntil = record.validUntil
        ? formatDate(new Date(record.validUntil), 'dd/MM/yyyy')
        : '-'
      record.assignedUser = record.assignedUser
        ? record.assignedUser
        : 'Não atribuído'
    })
  }, [Records.list])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const ListTableHeaders = useMemo(
    () => [
      {
        label: t('list_table.headers.coupo_name'),
        accessor: 'name',
      },
      {
        label: t('list_table.headers.discount'),
        accessor: 'discountPercentage',
      },
      {
        label: t('list_table.headers.by'),
        accessor: 'generatedByUsername',
      },
      {
        label: t('list_table.headers.assigned'),
        accessor: 'assignedUser',
      },
      {
        label: t('list_table.headers.valid_from'),
        accessor: 'validFrom',
      },
      {
        label: t('list_table.headers.until'),
        accessor: 'validUntil',
      },
      {
        label: t('list_table.headers.status'),
        accessor: 'status',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
    ],
    [t]
  )

  const ListTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/coupons/${Record.id}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutLoggedPageList title={title}>
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ListFilters query={Query}></ListFilters>

        <ListActions>
          <Button as={Link} to={'/coupons/new'} leftIcon={<FiPlus />}>
            Adicionar
          </Button>
        </ListActions>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={ListTableRowAction}
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
