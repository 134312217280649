// Vendors
import React, { useCallback } from 'react'
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form'

// Components

// Styles
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Stack,
} from '@chakra-ui/react'
import { useError } from 'hooks/useError'
import { MedicalRecord } from './MedicalRecord'
import {
  FormQuestionType,
  LaboratorialAnalysis,
} from 'types/LaboratorialAnalysis'
import { kebabCase, map } from 'lodash'
import { formatResponseMedicalRecord } from './formatResponseMedicalRecord'
import { apiPost } from 'services/post'
import { FieldDate } from 'components/FieldDate'
import { FieldSelect } from 'components/FieldSelect'
import { useSelectParser } from 'hooks/useSelectParser'

type Client = {
  id: string
  userId: string
  name: string
  email: string
  phoneNumber: string
  birthdate: Date
  status: 'ENABLED' | 'DISABLED'
  createdAt: Date
  updatedAt: Date
  documentNumber?: string
}

type ModalNewActiveKitProps = {
  isOpen: boolean
  reload: () => void
  onCancel: () => void
  activationCode: string
  laboratoryAnalysis: LaboratorialAnalysis[] | null
}

type FormActiveKit = {
  collectedAt: Date | null
  activateForUserId: { value: string } | null
}

export const ModalNewActiveKit = (props: ModalNewActiveKitProps) => {
  const { isOpen, reload, onCancel, activationCode, laboratoryAnalysis } = props

  const selectPatient = useSelectParser<Client>({
    accessor: {
      label: ({ name, documentNumber }) =>
        `${name} ${
          documentNumber &&
          `(${documentNumber.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})$/,
            '$1.$2.$3-$4'
          )})`
        }`,
      value: 'userId',
    },
    endpoint: isOpen ? '/app/client' : undefined,
  })

  const methods = useForm()

  const { handleError } = useError()

  const onClose = useCallback(() => {
    methods.reset()
    onCancel()
  }, [methods, onCancel])

  const formatResponse = useCallback(
    (data: FormActiveKit) => {
      const analysis = map(laboratoryAnalysis, (analysis) => {
        return {
          laboratoryAnalysisId: analysis.id,
          questions: analysis?.clinicalRecord?.clinicalRecordForm?.questions,
        }
      })

      Object.entries(data).forEach(([key, value]) => {
        analysis.forEach(({ questions }) => {
          const draft = questions.find((q) => q.id === key)
          if (!draft) return

          formatResponseMedicalRecord(
            draft,
            (value as any)[kebabCase(draft.label)]
          )
        })
      })

      return analysis
    },
    [laboratoryAnalysis]
  )

  const formatPayload = useCallback(
    (
      response: Array<{
        laboratoryAnalysisId: string
        questions: FormQuestionType[]
      }>,
      data: FormActiveKit
    ) => {
      return {
        collectedAt: data.collectedAt,
        activateForUserId: data.activateForUserId?.value,
        clinicalRecordFormResponses: response,
      }
    },
    []
  )

  const onSubmit = useCallback(
    async (data: FormActiveKit) => {
      try {
        if (!laboratoryAnalysis) return
        const response = formatResponse(data)

        const payload = formatPayload(response, data)

        await apiPost(`/app/backoffice/activate_kit/${activationCode}`, payload)

        reload()
        onClose()
      } catch (error) {
        handleError(error)
      }
    },
    [
      activationCode,
      formatPayload,
      formatResponse,
      handleError,
      laboratoryAnalysis,
      onClose,
      reload,
    ]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />

      <FormProvider {...methods}>
        <ModalContent as="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalHeader>Ativação do kit</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Stack spacing={4}>
              <Controller
                control={methods.control}
                name="activateForUserId"
                render={({ field }) => (
                  <FieldSelect
                    label="Escolha um paciente"
                    error={
                      methods.formState.errors.activateForUserId as FieldError
                    }
                    options={selectPatient.options}
                    isLoading={selectPatient.isLoading}
                    placeholder="Selecione um paciente..."
                    {...field}
                  />
                )}
              />

              <Controller
                name="collectedAt"
                control={methods.control}
                render={({ field }) => (
                  <FieldDate
                    maxDate={new Date()}
                    dropdownMode="select"
                    dateFormat="dd/MM/yyyy"
                    label="Data da coleta"
                    name={field.name}
                    selected={field.value}
                    onChange={field.onChange}
                    error={methods.formState.errors.collectedAt}
                  />
                )}
              />

              {laboratoryAnalysis && (
                <MedicalRecord laboratoryAnalysis={laboratoryAnalysis} />
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button onClick={onClose}>Cancelar</Button>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={methods.formState.isSubmitting}
              >
                Salvar
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </Modal>
  )
}
