import * as Sentry from '@sentry/react'
import React, { Suspense } from 'react'
import { hotjar } from 'react-hotjar'
import { BrowserRouter, Redirect } from 'react-router-dom'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { AppProvider } from 'contexts'
import { Routes } from 'routes'

import Loading from 'pages/public/Loading'

import './styles/ckeditor.css'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/montserrat/300.css'
import '@fontsource/montserrat/600.css'

const theme = extendTheme({
  fonts: {
    heading: 'Roboto, sans-serif',
    body: 'Roboto, sans-serif',
  },
  // fontWeights: {
  //   hairline: 100,
  //   thin: 200,
  //   light: 300,
  //   normal: 400,
  //   medium: 500,
  //   semibold: 600,
  //   bold: 700,
  //   extrabold: 800,
  //   black: 900,
  // },
  colors: {
    cyan: {
      100: '#D7E4FF',
      200: '#AFC7FF',
      300: '#87A8FF',
      400: '#698FFF',
      500: '#3866FF',
      600: '#284DDB',
      700: '#1C38B7',
      800: '#112693',
      900: '#0A197A',
    },
    red: {
      100: '#FFEAD5',
      200: '#FFD0AC',
      300: '#FFAF82',
      400: '#FF9063',
      500: '#FF5D30',
      600: '#DB3D23',
      700: '#B72318',
      800: '#930F0F',
      900: '#7A0911',
    },
    green: {
      100: '#ECFBD3',
      200: '#D5F7A9',
      300: '#B2E87B',
      400: '#8DD256',
      500: '#5DB527',
      600: '#459B1C',
      700: '#308213',
      800: '#1E680C',
      900: '#125607',
    },
    yellow: {
      100: '#FEFCCF',
      200: '#FEFAA0',
      300: '#FDF570',
      400: '#FBF14D',
      500: '#F9EA13',
      600: '#D6C70D',
      700: '#B3A509',
      800: '#908406',
      900: '#776C03',
    },
    blue: {
      100: '#D1FBEE',
      200: '#A4F8E6',
      300: '#73EADB',
      400: '#4ED6D0',
      500: '#1DB6BC',
      600: '#1590A1',
      700: '#0E6E87',
      800: '#094F6D',
      900: '#053A5A',
    },
  },
})

const route = <Redirect to={'/dashboard'} />

const errorHandle = () => {
  if (
    process.env.NODE_ENV === 'production' ||
    process.env.NODE_ENV === 'test'
  ) {
    return (window.location.href = '/500')
  }

  Sentry.showReportDialog()
}
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_HOTJAR_ID) {
  hotjar.initialize(+process.env.REACT_APP_HOTJAR_ID, 6)
}

const App: React.FC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <ChakraProvider resetCSS theme={theme}>
        <BrowserRouter>
          <AppProvider>
            <Sentry.ErrorBoundary onError={errorHandle}>
              <Routes />
            </Sentry.ErrorBoundary>
          </AppProvider>
        </BrowserRouter>
      </ChakraProvider>
    </Suspense>
  )
}

export default App
