// Vendors
import { get } from 'lodash'
import { components } from 'react-select'
import React, { useEffect, useMemo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

// Components
import { FieldSet } from 'components/FieldSet'
import { FieldText } from 'components/FieldText'
import { FieldFile } from 'components/FieldFile'
// import { TextEditor } from 'components/TextEditor'
import { FieldSelect } from 'components/FieldSelect'
import { FieldNoImage } from 'components/FieldNoImage'
import { FieldTextArea } from 'components/FieldTextArea'
import { FieldRepeater } from 'components/FieldRepeater'
import { FieldSelectColor } from 'components/FieldSelectColor'

// Styles
import { Divider, Grid, GridItem, HStack, Text } from '@chakra-ui/react'

// Icons
import { Swab } from 'assets/icons/Swap'
import { Gluten } from 'assets/icons/Gluten'
import { Spatula } from 'assets/icons/Spatula'
import { Antibodies } from 'assets/icons/Antibodies'
import { IggReaction } from 'assets/icons/IggReaction'
import { ReportModel } from 'assets/icons/ReportModel'
import { GeneticsOne } from 'assets/icons/GeneticsOne'
import { GeneticsTwo } from 'assets/icons/GeneticsTwo'
import { GeneticsThree } from 'assets/icons/GeneticsThree'
import { InfectionsOne } from 'assets/icons/InfectionsOne'
import { InfectionsTwo } from 'assets/icons/InfectionsTwo'
import { CollectionBrush } from 'assets/icons/CollectionBrush'
import { FastAndConvenient } from 'assets/icons/FastAndConvenient'
import { CapillaryBloodCollection } from 'assets/icons/CapillaryBloodCollection'

// Types
import { AccentColorEnum } from 'types/themes'
import selectIcons from '../../SelectIcons'
import { useFormTransform } from 'hooks/useFormTransform'

const TextEditor = React.lazy(() => import('components/TextEditor'))
const { Option, SingleValue } = components
const IconValueContainer = (props: any) => {
  const mapIcons = useMemo(() => {
    return {
      Gluten,
      Spatula,
      CollectionBrush,
      Antibodies,
      GeneticsOne,
      GeneticsTwo,
      GeneticsThree,
      Swab,
      IggReaction,
      ReportModel,
      InfectionsOne,
      InfectionsTwo,
      FastAndConvenient,
      CapillaryBloodCollection,
    }
  }, [])

  if (!props.hasValue) {
    return <SingleValue {...props} />
  }

  const data = props.getValue()[0]
  const Icon = mapIcons[(data.value as keyof typeof mapIcons) || 'Genetic']
  return (
    <SingleValue {...props}>
      <HStack spacing={'2'}>
        <Icon
          h={{ base: '5', lg: '6' }}
          w={{ base: '5', lg: '6' }}
          colorStopInit={data.accentColor || AccentColorEnum.Polo}
          colorStopEnd={data.accentColor || AccentColorEnum.Polo}
        />
        <Text>{data.label}</Text>
      </HStack>
    </SingleValue>
  )
}

const IconOption = (props: any) => {
  const mapIcons = useMemo(() => {
    return {
      Gluten,
      Spatula,
      CollectionBrush,
      Antibodies,
      GeneticsOne,
      GeneticsTwo,
      GeneticsThree,
      Swab,
      IggReaction,
      ReportModel,
      InfectionsOne,
      InfectionsTwo,
      FastAndConvenient,
      CapillaryBloodCollection,
    }
  }, [])

  const Icon =
    mapIcons[(props.data.value as keyof typeof mapIcons) || 'ReportModel']

  return (
    <Option {...props}>
      <HStack spacing={'2'}>
        <Icon
          h={{ base: '5', lg: '6' }}
          w={{ base: '5', lg: '6' }}
          colorStopInit={props.data.accentColor || AccentColorEnum.Polo}
          colorStopEnd={props.data.accentColor || AccentColorEnum.Polo}
        />
        <Text>{props.data.label}</Text>
      </HStack>
    </Option>
  )
}

export const TabPromotional = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { selectParser } = useFormTransform()

  const {
    watch,
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const watchIsAvailableInStore = watch('isAvailableInStore')
  const valueAttributes = getValues('attributes')

  const valueAccentColor = getValues('accentColor')

  const accentColorOptions = useMemo(() => {
    return Object.entries(AccentColorEnum).map(([key, value]) => ({
      label: value,
      value: key,
    }))
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!valueAccentColor || !valueAccentColor.length) return
    if (typeof valueAccentColor[0] === 'object') return

    const accentColor = selectParser(valueAccentColor, accentColorOptions)

    setValue('accentColor', accentColor)
  }, [setValue, valueAccentColor, accentColorOptions, selectParser])

  useEffect(() => {
    if (!valueAttributes || !valueAttributes.length) return
    if (typeof valueAttributes[0].icon === 'object') return

    valueAttributes.forEach(
      (attribute: { icon: string; label: string }, index: number) => {
        const valueAttributesOption = selectParser(attribute.icon, selectIcons)

        if (!valueAttributesOption) return

        setValue(`attributes.${index}.icon`, valueAttributesOption)
      }
    )
  }, [setValue, selectParser, valueAttributes])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <>
      <FieldSet title="Informações iniciais">
        <Grid my={4} gap={10} templateColumns="repeat(2, 1fr)">
          <GridItem colSpan={1}>
            <FieldText
              maxLength={55}
              currentLength={get(watch('mktTitle'), 'length')}
              label="Nome oficial do teste"
              error={errors.mktTitle}
              isRequired={watchIsAvailableInStore}
              {...register('mktTitle')}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <Controller
              name="accentColor"
              control={control}
              render={({ field }) => (
                <FieldSelectColor
                  label="Cor de destaque"
                  error={errors.accentColor}
                  options={accentColorOptions}
                  isRequired={watchIsAvailableInStore}
                  {...field}
                />
              )}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <FieldTextArea
              label="Resumo do teste"
              error={errors.mktAbstract}
              isRequired={watchIsAvailableInStore}
              {...register('mktAbstract')}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <FieldTextArea
              maxLength={200}
              currentLength={get(watch('mktDescription'), 'length')}
              label="Linha fina"
              error={errors.mktDescription}
              isRequired={watchIsAvailableInStore}
              {...register('mktDescription')}
            />
          </GridItem>
        </Grid>
      </FieldSet>

      <Controller
        name="mktCompactDescription"
        control={control}
        render={({ field }) => (
          <TextEditor
            {...field}
            label="Resumo para home page"
            data={field.value}
            name="description"
            minHeight="10rem"
            onChange={(_: any, editor: { getData: () => string }) =>
              field.onChange(editor.getData())
            }
          />
        )}
      />

      <Grid
        my={4}
        gap={10}
        templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
      >
        <GridItem colSpan={2}>
          <FieldRepeater
            startWith={1}
            name="attributes"
            error={errors.attributes}
            label="Atributos do teste"
            control={control}
            register={register}
            gridProps={{ columns: 2, gap: 2 }}
            defaultValue={{ icon: '', label: '', url: '' }}
            fieldComponents={[
              {
                component: FieldSelect,
                type: 'control',
                props: {
                  name: 'icon',
                  placeholder: 'Selecione um ícone...',
                  options: selectIcons.map((icon) => ({
                    ...icon,
                    accentColor: watch('accentColor')?.label,
                  })),
                  control: control,
                  components: {
                    Option: IconOption,
                    SingleValue: IconValueContainer,
                  },
                },
              },
              {
                component: FieldText,
                type: 'register',
                props: {
                  name: 'label',
                  placeholder: 'Texto',
                },
              },
            ]}
          />
        </GridItem>

        <GridItem colSpan={2} />

        <GridItem colSpan={2}>
          <FieldRepeater
            startWith={1}
            label="FAQ"
            control={control}
            error={errors.faq}
            register={register}
            name="faq"
            gridProps={{ columns: 1, gap: 2 }}
            defaultValue={{ label: '', title: '', description: '' }}
            containerProps={{ spacing: 10 }}
            fieldComponents={[
              {
                component: FieldText,
                type: 'register',
                props: {
                  name: 'label',
                  placeholder: 'Título na lateral',
                  label: 'Título na lateral',
                  isRequired: watchIsAvailableInStore,
                },
              },
              {
                component: FieldText,
                type: 'register',
                props: {
                  name: 'title',
                  placeholder: 'Título no texto',
                  label: 'Título no texto',
                  isRequired: watchIsAvailableInStore,
                },
              },
              {
                component: TextEditor,
                type: 'ckEditor',
                props: {
                  name: 'description',
                  label: 'Descrição',
                  minHeight: '10rem',
                },
              },
            ]}
          />
        </GridItem>

        <GridItem colSpan={{ base: 2, lg: 4 }}>
          <FieldNoImage label="Imagem de destaque" />
        </GridItem>

        <GridItem colSpan={{ base: 2, lg: 4 }}>
          <FieldNoImage label="Imagem complementar" />
        </GridItem>

        <GridItem colSpan={{ base: 2, lg: 4 }}>
          <FieldNoImage label="Imagem deste produto aberto mostrando o kit" />
        </GridItem>
      </Grid>

      <Divider my={4} />

      <FieldSet title="Entenda o laudo">
        <Grid
          my={4}
          gap={10}
          templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
        >
          <GridItem colSpan={2}>
            <FieldText
              maxLength={20}
              currentLength={get(watch('reportOverviewTitle'), 'length')}
              label="Título do bloco de laudo"
              error={errors.reportOverviewTitle}
              isRequired={watchIsAvailableInStore}
              {...register('reportOverviewTitle')}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldFile
              label="PDF do laudo"
              fileName={get(watch('reportOverviewPDF'), '[0].name')}
              allowedExtensions={['pdf']}
              error={errors.reportOverviewPDF}
              isRequired={watchIsAvailableInStore}
              {...register('reportOverviewPDF')}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldTextArea
              maxLength={1024}
              currentLength={get(watch('reportOverviewAbstract'), 'length')}
              label="Resumo do bloco de laudo"
              error={errors.reportOverviewAbstract}
              isRequired={watchIsAvailableInStore}
              {...register('reportOverviewAbstract')}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2, lg: 4 }}>
            <FieldNoImage label="Imagem destaque laudo" />
          </GridItem>
        </Grid>
      </FieldSet>
    </>
  )
}
