// Vendors
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useFormContext } from 'react-hook-form'

// Functions
import formatCurrency from 'utils/formatCurrency'

// Hooks
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { TableData } from './TableData'
import { FormWrapper } from 'components/FormWrapper'

// Styles
import { FiPlusCircle } from 'react-icons/fi'
import {
  Tr,
  Td,
  Th,
  Box,
  Table,
  Tbody,
  Thead,
  useToken,
  IconButton,
  Spinner,
  Button,
} from '@chakra-ui/react'

// Types
import { FormProduct, ProductList, ProductType } from './types'
import { useCart } from 'contexts/cart'
import { get } from 'lodash'
import { StoreProductType } from 'contexts/cart/interface'

const DEFAULT_PRODUCTS: ProductType = {
  storeProductId: { value: '', label: '' },
  quantity: 1,
  unitPrice: 0,
  subtotal: 0,
  totalDiscount: 0,
  totalPrice: 0,
}

export const Products = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('checkout')
  const { checkout, isFetching, makeCartCalculation } = useCart()

  const blue500 = useToken('colors', 'blue.500')

  const { control, register, setValue, getValues } =
    useFormContext<FormProduct>()
  const { fields, remove, append } = useFieldArray<FormProduct>({
    control,
    name: 'purchaseProducts',
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sanitizeProduct = useCallback(
    (storeProduct: StoreProductType, index: number) => {
      const {
        quantity,
        unitPrice,
        unitPriceB2c,
        subTotalPrice,
        storeProductId,
        discountApplied,
        storeProductMktTitle,
        subTotalPriceWithoutDiscount,
      } = storeProduct

      return {
        storeProductId: {
          value: storeProductId,
          label: storeProductMktTitle,
        },
        unitPrice,
        quantity,
        subtotal: subTotalPriceWithoutDiscount,
        totalDiscount: discountApplied,
        totalPrice: subTotalPrice,
        originalPrice: unitPriceB2c,
        isCustomPrice: getValues(`purchaseProducts.${index}.isCustomPrice`),
      }
    },
    [getValues]
  )

  const handleSetValue = useCallback(
    (index: number, product: ProductType) => {
      setValue(`purchaseProducts.${index}`, product)
    },
    [setValue]
  )

  const handleCartCalculation = useCallback(async () => {
    const response = await makeCartCalculation()

    if (!response) return

    const storeProductsList = get(response, 'storeProductsList', [])

    storeProductsList.forEach((storeProduct, index) => {
      handleSetValue(index, sanitizeProduct(storeProduct, index))
    })
  }, [handleSetValue, makeCartCalculation, sanitizeProduct])

  const formattedLabel = useCallback((item: ProductList) => {
    return `${item.name} (${formatCurrency(item.priceB2c || 0)})`
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectProducts = useSelectParser<ProductList>({
    accessor: {
      label: formattedLabel,
      value: 'id',
    },
    endpoint: '/public/store_product?status=ENABLED',
  })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormWrapper title={t('group.products')} titlePosition="top">
      <Box position="absolute" top="-0.5" left="52">
        <IconButton
          size="sm"
          variant="ghost"
          aria-label="remove product"
          icon={<FiPlusCircle size="20" color={blue500} />}
          onClick={() => append(DEFAULT_PRODUCTS)}
        />
      </Box>
      <Button
        top="0"
        size="sm"
        right="0"
        colorScheme="blue"
        position="absolute"
        isLoading={isFetching}
        onClick={handleCartCalculation}
      >
        Calcular carrinho de compras
      </Button>

      <Table mt="6" overflow="auto">
        <Thead bg="gray.50">
          <Tr>
            <Th d="flex">
              {t('table.headers.product')}
              {isFetching && <Spinner ml="2" size="sm" color="blue.500" />}
            </Th>
            <Th>{t('table.headers.price')}</Th>
            <Th>{t('table.headers.quantity')}</Th>
            <Th>{t('table.headers.subtotal')}</Th>
            <Th>{t('table.headers.discounts')}</Th>
            <Th>{t('table.headers.subtotalLine')}</Th>
            <Th>{t('table.headers.actions')}</Th>
          </Tr>
        </Thead>

        <Tbody>
          {fields.map((field, index) => (
            <TableData
              control={control}
              key={field.id}
              field={field}
              index={index}
              onRemove={remove}
              register={register}
              onSetValue={handleSetValue}
              options={{
                products: selectProducts,
              }}
            />
          ))}
        </Tbody>
      </Table>

      <Table>
        <Thead bg="gray.50">
          <Tr>
            <Th w="96" />
            <Th>{t('table.headers.value')}</Th>
            <Th>{t('table.headers.discount')}</Th>
            <Th>{t('table.headers.totalWithDiscount')}</Th>
          </Tr>
        </Thead>

        <Tbody>
          <Tr color="gray.700" h="20">
            <Td />
            <Td>{formatCurrency(checkout?.totalPriceWithoutDiscount || 0)}</Td>
            <Td>{formatCurrency(checkout?.totalDiscount || 0)}</Td>
            <Td fontWeight="semibold">
              {formatCurrency(checkout?.totalPrice || 0)}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </FormWrapper>
  )
}
