import { Image } from 'types/Product'
import { Record } from 'types/Requests'

export type CategoryShowForm = {
  title: string
  description: string
  accentColor: { value: string; label: string }
  images: File[]
  mktTitleIntrodutoryText: string
}

export type CategoryShowRecord = {
  title: string
  description: string
  accentColor: string
  images: File[]
  mktTitleIntrodutoryText: string
  mktIntrodutoryText: string
  mktFeatures: {
    features: Array<{
      title: string
      description: string
    }>
  }
  mktReportFileName: string
  mktReportFilePath: string
  mktReportFileUri: string
}

export type CategoryShow = {
  title: string
  description: string
  accentColor: string
  images: File[]
  icon: File[]
  mktTitleIntrodutoryText: string
  mktIntrodutoryText: string
  storeProducts: Array<{
    id: string
    mktTitle: string
    status: 'ENABLED'
  }>
  mktFeatures: {
    features: Array<{
      title: string
      description: string
    }>
  }
  mktReportFileName: string
  mktReportFilePath: string
  mktReportFileUri: string

  slug: string | null
  seoTitle: string | null
  seoImages: Image[] | null
  seoDescription: string | null
  seoAdditionalKeywords: string | null
  keywords: Array<Record & { name: string }> | null
}

export enum AccentColorEnum {
  'Polo' = 'Polo',
  'Blush' = 'Blush',
  'Lilac' = 'Lilac',
  'Viola' = 'Viola',
  'Mango' = 'Mango',
  'Willow' = 'Willow',
  'Sienna' = 'Sienna',
  'Orchid' = 'Orchid',
  'Cranberry' = 'Cranberry',
  'Fountain' = 'Fountain',
  'Mauvelous' = 'Mauvelous',
}

export type File = {
  sampleTransportKitId: string
  originalFileName: string
  fileName: string
  filePath: string
  group: string | null
  mimeType: string
  size: string
  isMainImage: boolean
}
