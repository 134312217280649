import { TFunction } from 'react-i18next'

import * as yup from 'yup'

export const schema = (t: TFunction<'common'>) => {
  yup.setLocale({
    mixed: {
      notType: t('form.mixed.notType'),
      required: ({ label }) => `${label} ${t('form.mixed.required')}`,
    },
    number: {
      positive: ({ label }) => `${label} ${t('form.number.positive')}`,
      max: ({ label, max }) => `${label} ${t('form.number.max')} ${max}`,
    },
  })

  const sch = yup.object().shape({
    name: yup
      .string()
      .required()
      .label(t('form.laboratorial_analysis.name.label')),

    medicalReportDeliveryEstimateInDays: yup
      .string()
      .required()
      .label(
        t(
          'form.laboratorial_analysis.medicalReportDeliveryEstimateInDays.label'
        )
      ),

    description: yup
      .string()
      .required()
      .label(t('form.laboratorial_analysis.description.label')),

    status: yup
      .string()
      .required()
      .label(t('form.laboratorial_analysis.status.label')),
  })

  return sch
}
