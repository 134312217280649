// Vendors
import { get, map } from 'lodash'
import { add, format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import React, { useCallback, useEffect, useMemo } from 'react'

// Functions
import { apiShow } from 'services/get'
import { apiPost } from 'services/post'
import { useError } from 'hooks/useError'
import { formattedCardDetails } from '../../show/tabs/formattedCardDetails'
import { instanceOfLaboratorialAnalysis } from 'utils/instanceOfLaboratorialAnalysis'

// Components
import { FieldSet } from 'components/FieldSet'
import { DrawerRefusal } from './DrawerRefusal'
import { FieldDate } from 'components/FieldDate'
import { FieldText } from 'components/FieldText'
import { CardDetails } from 'components/CardDetails'
import { CardDetailsValue } from 'components/CardDetailsValue'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'
import {
  Box,
  Text,
  Stack,
  Button,
  Divider,
  Heading,
  useToast,
  ButtonGroup,
  useDisclosure,
  Collapse,
  Badge,
  Flex,
} from '@chakra-ui/react'

// Types
import { PageMetaSetting } from './meta'
import { KitCheckInForm, KitCheckInShow } from './types'
import { useCan } from 'hooks/useCan'
import { RULES } from '../../rules'
import { formatDate } from 'utils/formatDate'

export const PageCheckInShowRecord = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { can } = useCan({ rules: RULES })
  const history = useHistory()
  const disclosureRefusal = useDisclosure()
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation('laboratory')
  const toast = useToast({ position: 'top-right' })

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<KitCheckInForm>({
    mode: 'onChange',
  })

  const { endpoint, title } = PageMetaSetting

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { handleError } = useError()

  const [show, setShow] = React.useState(false)
  const [record, setRecord] = React.useState<KitCheckInShow | undefined>(
    undefined
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    async function getData() {
      try {
        const response = await apiShow<KitCheckInShow>(`${endpoint}/${id}`)

        setRecord(response)

        if (!response) return

        const purchaseHasProductHasAnalysis = get(
          response,
          'purchaseHasProductHasAnalysis'
        )

        let laboratoryAnalysis

        if (instanceOfLaboratorialAnalysis(purchaseHasProductHasAnalysis)) {
          laboratoryAnalysis = purchaseHasProductHasAnalysis
        } else {
          laboratoryAnalysis = map(
            purchaseHasProductHasAnalysis,
            'laboratoryAnalysis'
          )
        }

        const medicalReportDeliveryEstimateInDays = map(
          laboratoryAnalysis,
          'medicalReportDeliveryEstimateInDays'
        )

        const medicalReportDeliveryEstimateInDaysMax = Math.max(
          ...medicalReportDeliveryEstimateInDays
        )

        setValue(
          'dateLimit',
          add(new Date(), { days: medicalReportDeliveryEstimateInDaysMax })
        )
      } catch (error: any) {
        history.goBack()

        handleError(error)
      }
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, history, id, setValue, t])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const memoCardDetails = useMemo(() => {
    if (!record) return []

    const sanitazedRecord = formattedCardDetails(record)

    if (!sanitazedRecord) return []

    const {
      purchase,
      activationByPersonalData,
      activationForPersonalData,
      purchaseHasProductHasAnalysis,
    } = sanitazedRecord

    return [
      {
        label: t('card.order.label'),
        ...(purchase?.id && can.order && { link: `/orders/${purchase.id}` }),
        value: (
          <Flex
            p="8"
            mt="-4"
            mb="-10"
            mx="-6"
            h="100%"
            alignItems="center"
            justifyContent="space-between"
            bg={
              purchase?.isCobranding
                ? 'linear-gradient(270deg, rgba(253, 201, 11, 0.144) 10%, rgba(254, 223, 110, 0.207217) 14%, rgba(255, 255, 255, 0.3) 25%);'
                : ''
            }
          >
            <Text fontWeight="bold">#{purchase?.purchaseNumber}</Text>
            {purchase?.isCobranding && <Text>Cobranding</Text>}
          </Flex>
        ),
      },
      {
        label: t('card.customerDataActivation.label'),
        ...(activationForPersonalData?.id &&
          can.patient && {
            link: `/clients/${activationForPersonalData.id}`,
          }),
        value: (
          <>
            <CardDetailsValue
              label={t('card.customerDataActivation.name')}
              value={activationForPersonalData?.name}
            />
            <CardDetailsValue
              label={t('card.customerDataActivation.birthDate')}
              value={activationForPersonalData?.birthdate}
            />
            <CardDetailsValue
              label={t('card.customerDataActivation.documentNumber')}
              value={activationForPersonalData?.documentNumber}
            />
            <CardDetailsValue
              label={t('card.customerDataActivation.email')}
              value={activationForPersonalData?.email}
            />
            <CardDetailsValue
              label={t('card.customerDataActivation.gender')}
              value={activationForPersonalData?.gender}
            />
          </>
        ),
      },
      {
        label: t('card.activeService.label'),
        value: (
          <>
            <Stack direction="row" spacing={3}>
              {purchaseHasProductHasAnalysis?.services?.map((service) =>
                can.services ? (
                  <Link
                    key={service.id}
                    to={`/laboratorial_analysis/${service.id}`}
                  >
                    <Text
                      transition="all 0.2s"
                      textDecoration="underline"
                      _hover={{ color: 'blue.500' }}
                    >
                      {service.name}
                    </Text>
                  </Link>
                ) : (
                  <Text>{service.name}</Text>
                )
              )}
            </Stack>
          </>
        ),
      },
      ...(((purchase?.purchaseType === 'B2B' ||
        purchase?.purchaseType === 'LEGACY') && [
        {
          label: t('card.professional.label'),
          ...(activationByPersonalData?.id &&
            can.professional && {
              link: `/professionals/${activationByPersonalData.id}`,
            }),
          value: (
            <>
              <CardDetailsValue
                label={t('card.customerDataActivation.name')}
                value={activationByPersonalData?.name}
              />
              <CardDetailsValue
                label={t('card.customerDataActivation.birthDate')}
                value={activationByPersonalData?.birthdate}
              />
              <CardDetailsValue
                label={t('card.customerDataActivation.documentNumber')}
                value={activationByPersonalData?.documentNumber}
              />
              <CardDetailsValue
                label={t('card.customerDataActivation.email')}
                value={activationByPersonalData?.email}
              />
            </>
          ),
        },
      ]) || [null]),
    ]
  }, [can.order, can.patient, can.professional, can.services, record, t])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleCheckIn = useCallback(
    async (data: KitCheckInForm) => {
      try {
        await apiPost(`/app/laboratory/${id}/accept`, data)

        history.push('/laboratory')

        toast({
          title: t('toast.success.confirmCheckIn.title'),
          status: 'success',
        })
      } catch (error: any) {
        handleError(error)
      }
    },
    [handleError, history, id, t, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={`${title} ${id}`}>
      {get(record, 'activatedAt') && (
        <Text mt="-6" mb="4">
          Ativado em {formatDate(get(record, 'activatedAt'), 'dd/MM/yyyy')}
        </Text>
      )}

      {memoCardDetails.length > 0 && (
        <>
          <DrawerRefusal
            isOpen={disclosureRefusal.isOpen}
            onClose={disclosureRefusal.onClose}
          />

          {record?.isLegacy && (
            <Badge right="16" position="absolute">
              Kit legado
            </Badge>
          )}

          <Stack direction={{ base: 'column', lg: 'row' }} spacing={12}>
            <CardDetails data={memoCardDetails} />

            <Stack w="full">
              <Collapse startingHeight={250} in={show}>
                <Heading fontSize="xl" color="blue.500">
                  {t('medicalRecord.title')}
                </Heading>

                {instanceOfLaboratorialAnalysis(
                  record?.purchaseHasProductHasAnalysis
                ) && (
                  <Box mt="5">
                    <Text>Sem respostas para este exame</Text>
                  </Box>
                )}

                {record &&
                  !instanceOfLaboratorialAnalysis(
                    record.purchaseHasProductHasAnalysis
                  ) &&
                  record.purchaseHasProductHasAnalysis.map((product) => {
                    const { clinicalRecordFormResponse } = product

                    if (!clinicalRecordFormResponse) return null

                    const { questions } = clinicalRecordFormResponse

                    if (questions.length === 0) {
                      return (
                        <Box>
                          <Text>Sem respostas para este exame</Text>
                        </Box>
                      )
                    }

                    return (
                      <>
                        <Heading as="h2" fontSize="xl" mt="5">
                          {t('medicalRecord.description')}{' '}
                          {product.laboratoryAnalysis.name}
                        </Heading>

                        {questions &&
                          questions.map((question) => {
                            let response = question.response

                            if (question.fieldType === 'checkbox') {
                              response = question.options
                                .filter((option) => option.response)
                                .map((option) => option.label)
                                .join(', ')
                            }

                            if (
                              question.fieldType === 'date' &&
                              question.response &&
                              !question.response.includes('/')
                            ) {
                              response = format(
                                new Date(question.response),
                                'dd/MM/yyyy'
                              )
                            }

                            return (
                              <Box key={question.label}>
                                <Text as="strong">{question.label}</Text>
                                <Text>{response || 'Sem resposta'}</Text>
                              </Box>
                            )
                          })}
                      </>
                    )
                  })}
              </Collapse>

              <Stack isInline alignItems="center" spacing="3">
                <Divider />
                <Button
                  size="sm"
                  minW="28"
                  variant="ghost"
                  colorScheme="blue"
                  onClick={() => setShow((oldState) => !oldState)}
                >
                  Mostrar {show ? 'menos' : 'mais'}
                </Button>
                <Divider />
              </Stack>
            </Stack>
          </Stack>

          <FieldSet title={t('reportData.title')} maxW="container.md" mt={12}>
            <Stack
              mt={2}
              alignItems="flex-start"
              direction={{ base: 'column', md: 'row' }}
            >
              <Controller
                name="dateLimit"
                control={control}
                rules={{
                  required: `${t('reportData.deadlineForRelease.required')}`,
                }}
                render={({ field }) => (
                  <FieldDate
                    minDate={new Date()}
                    label={t('reportData.deadlineForRelease.label')}
                    name={field.name}
                    selected={field.value}
                    onChange={field.onChange}
                    error={errors.dateLimit}
                    isRequired
                  />
                )}
              />

              <FieldText
                label={t('reportData.laboratoryId.label')}
                helperText={
                  errors.internalLaboratoryId
                    ? ''
                    : t('reportData.laboratoryId.helperText')
                }
                error={errors.internalLaboratoryId}
                {...register('internalLaboratoryId')}
              />
            </Stack>
          </FieldSet>

          <Divider my={6} />

          <ButtonGroup
            colorScheme="blue"
            justifyContent="space-between"
            w="full"
          >
            <Button onClick={handleSubmit(handleCheckIn)}>
              {t('action.confirmCheckIn')}
            </Button>
          </ButtonGroup>
        </>
      )}
    </LayoutLoggedPageList>
  )
}
