import { IconButton, MenuItem, Tooltip, TooltipProps } from '@chakra-ui/react'
import React, { MouseEvent } from 'react'
import { IconType } from 'react-icons'
import { ActionProps } from './types'

type WrapperActionProps<T> = ActionProps<T> & {
  label: string
  cta: (e: React.MouseEvent<HTMLButtonElement>, record: T) => void
  icon?: IconType
  actionType: 'icon' | 'menu'
  tooltip?: string
  tooltipProps?: Omit<TooltipProps, 'children'>
}

export const WrapperAction = <T extends unknown>(
  props: WrapperActionProps<T>
) => {
  const {
    cta,
    label,
    record,
    options,
    tooltip,
    actionType,
    icon: Icon,
    tooltipProps,
  } = props

  const IconButtonAction = () => {
    if (!Icon) throw new Error('Icon is required')

    return (
      <Tooltip label={tooltip || label} {...tooltipProps}>
        <IconButton
          size="sm"
          variant="ghost"
          aria-label={label}
          icon={<Icon size="18" />}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            cta(e, record)
          }}
          {...options}
        />
      </Tooltip>
    )
  }

  const MenuItemAction = () => {
    return (
      <Tooltip label={tooltip} placement="left" {...tooltipProps}>
        <MenuItem
          aria-label={label}
          icon={Icon && <Icon size="18" />}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            cta(e, record)
          }}
          {...options}
        >
          {label}
        </MenuItem>
      </Tooltip>
    )
  }

  return actionType === 'menu' ? <MenuItemAction /> : <IconButtonAction />
}
