// Vendors
import React from 'react'

// Styles
import { Drawer, DrawerOverlay, DrawerProps } from '@chakra-ui/react'

// Types
export type IDrawerList = DrawerProps

export const DrawerList: React.FC<IDrawerList> = (props) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { children, ...rest } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Drawer size="md" {...rest}>
      <DrawerOverlay />

      {children}
    </Drawer>
  )
}
