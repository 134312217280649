// Vendors
import React, { useCallback } from 'react'

// Styles
import { Button } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

// Interfaces
export type ButtonNewOrderProps = {
  personId?: string
  companyClinicId?: string
}

export const ButtonNewOrder = (props: ButtonNewOrderProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { personId, companyClinicId } = props

  if (!personId && !companyClinicId) {
    throw new Error('personId or companyClinicId are required')
  }

  const history = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleClick = useCallback(() => {
    if (companyClinicId) {
      history.push(`/checkout?companyClinicId=${companyClinicId}`)
    }

    if (personId) {
      history.push(`/checkout?personId=${personId}`)
    }
  }, [companyClinicId, history, personId])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Button variant="outline" colorScheme="blue" onClick={handleClick}>
      Novo pedido
    </Button>
  )
}
