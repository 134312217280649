// Vendors
import React from 'react'

// Functions
import { useTranslation } from 'react-i18next'

// Components
import { ProductFormNew } from '../form/new'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

import { PageMetaSetting } from './meta'

export const PageProductNewRecord = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('products')
  const { endpoint, title } = PageMetaSetting

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={t('common:register', { title: t(title) })}>
      <ProductFormNew endpoint={endpoint} />
    </LayoutLoggedPageList>
  )
}
