import * as yup from 'yup'

export const schema = () => {
  return yup.object().shape({
    password: yup.string().required('Senha é obrigatória!').isStrongPassword(),

    confirmPassword: yup
      .string()
      .required('Confirmação da senha é obrigatória!')
      .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais'),
  })
}
