// Vendors
import React from 'react'
import { Controller, get, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

// Functions

// Components
import { FieldText } from 'components/FieldText'

// Schemas
import { schema } from './schema'

// Styles
import { Flex, Grid, GridItem, useToast } from '@chakra-ui/react'
import { FieldSet } from 'components/FieldSet'
import { FieldMask } from 'components/FieldMask'
import { FieldDate } from 'components/FieldDate'
import { FieldSelect } from 'components/FieldSelect'
import { ClinicEnum, CouncilEnum, GenderEnum } from '../show/types'
import { useSelectParser } from 'hooks/useSelectParser'
import { FieldFile } from 'components/FieldFile'
import { FormFooter } from 'components/FormFooter'
import { apiPost } from 'services/post'
import { ProfessionalNewForm } from './types'
import { useHistory } from 'react-router-dom'
import { useError } from 'hooks/useError'

// Types

export const ProfessionalFormNew = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('professional')

  const selectGender = {
    options: Object.entries(GenderEnum).map(([key, value]) => ({
      label: t(`form.gender.${String(key).toLowerCase()}`),
      value: value,
    })),
  }

  const selectSeller = useSelectParser<any>({
    accessor: { value: 'userId', label: 'name' },
    endpoint: '/app/person?type=seller',
  })

  const selectState = useSelectParser<any>({
    accessor: { value: 'abbreviatedName', label: 'abbreviatedName' },
    endpoint: '/app/state',
    requestWithoutMeta: true,
  })

  const selectClinic = useSelectParser<any>({
    accessor: { value: 'id', label: 'internalIdentification' },
    endpoint: '/app/company?type=clinic',
  })

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const toast = useToast({ position: 'top-right' })
  const history = useHistory()
  const { handleError } = useError()

  const {
    watch,
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<ProfessionalNewForm> = React.useCallback(
    async (data) => {
      try {
        const formData = new FormData()
        Object.entries(data).forEach(([key, value]) => {
          if (value instanceof Date) {
            formData.append(key, value.toISOString())
          } else if (value instanceof FileList) {
            formData.append(key, value[0])
          } else {
            formData.append(key, value)
          }
        })

        await apiPost('/app/professional', formData)

        toast({
          title: t('toast.success.title'),
          description: t('toast.success.descriptionCreate'),
          status: 'success',
        })

        history.push('/professionals')
      } catch (error: any) {
        const errorHandled = handleError(error, undefined, { 422: true })

        if (errorHandled) {
          errorHandled.forEach((errorItem) => {
            const { field, message } = errorItem as {
              field: keyof ProfessionalNewForm
              message: string
            }

            setError(field, { message })
          })
        }
      }
    },
    [handleError, history, setError, t, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      as="form"
      h="full"
      flexDirection="column"
      justifyContent="space-between"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FieldSet title={t('tabs.tabPersonalData.personalData.title')}>
        <Grid gap="10" my="4" templateColumns={{ lg: 'repeat(2, 1fr)' }}>
          <GridItem>
            <FieldText
              label={t('form.name.label')}
              error={errors.name}
              isRequired
              {...register('name')}
            />
          </GridItem>

          <GridItem>
            <FieldText
              label={t('form.email.label')}
              error={errors.email}
              isRequired
              {...register('email')}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <FieldMask
                  label={t('form.phoneNumber.label')}
                  mask="(99) 99999-9999"
                  error={errors.phoneNumber}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="documentNumber"
              control={control}
              render={({ field }) => (
                <FieldMask
                  label={t('form.documentNumber.label')}
                  mask="999.999.999-99"
                  error={errors.documentNumber}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="birthdate"
              control={control}
              render={({ field }) => (
                <FieldDate
                  dateFormat="dd/MM/yyyy"
                  label={t('form.birthdate.label')}
                  peekNextMonth
                  showYearDropdown
                  showMonthDropdown
                  name={field.name}
                  dropdownMode="select"
                  selected={field.value}
                  onChange={field.onChange}
                  error={errors.birthdate}
                  maxDate={new Date()}
                  isRequired
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  label={t('form.gender.label')}
                  error={errors.gender}
                  options={selectGender.options}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>
        </Grid>
      </FieldSet>

      <FieldSet title={t('tabs.tabPersonalData.professionalData.title')}>
        <Grid gap="10" my="4" templateColumns={{ lg: 'repeat(2, 1fr)' }}>
          <GridItem>
            <FieldText
              label={t('form.council-type.label')}
              error={errors[CouncilEnum.councilType]}
              isRequired
              {...register(CouncilEnum.councilType)}
            />
          </GridItem>

          <GridItem>
            <FieldText
              label={t('form.council-value.label')}
              error={errors['council-value']}
              isRequired
              {...register('council-value')}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="council-state-value"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  label={t('form.council-state-value.label')}
                  error={errors['council-state-value']}
                  isLoading={selectState.isLoading}
                  options={selectState.options}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="sellerUserId"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  label={t('form.sellerUserId.label')}
                  error={errors.sellerUserId}
                  isLoading={selectSeller.isLoading}
                  options={selectSeller.options}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <FieldFile
              label={t(`form.${CouncilEnum.councilFileFront}.label`)}
              fileName={get(watch(CouncilEnum.councilFileFront), '[0].name')}
              error={errors[CouncilEnum.councilFileFront]}
              isRequired
              {...register(CouncilEnum.councilFileFront)}
            />
          </GridItem>

          <GridItem>
            <FieldFile
              label={t(`form.${CouncilEnum.councilFileBack}.label`)}
              fileName={get(watch(CouncilEnum.councilFileBack), '[0].name')}
              error={errors[CouncilEnum.councilFileBack]}
              isRequired
              {...register(CouncilEnum.councilFileBack)}
            />
          </GridItem>

          <GridItem>
            <FieldFile
              label={t(`form.${CouncilEnum.councilFileSelfie}.label`)}
              fileName={get(watch(CouncilEnum.councilFileSelfie), '[0].name')}
              error={errors[CouncilEnum.councilFileSelfie]}
              isRequired
              {...register(CouncilEnum.councilFileSelfie)}
            />
          </GridItem>
        </Grid>
      </FieldSet>

      <FieldSet title={t('tabs.tabPersonalData.clinic.title')}>
        <Grid gap="10" my="4" templateColumns={{ lg: 'repeat(2, 1fr)' }}>
          <GridItem>
            <Controller
              name={ClinicEnum.recordClinicId}
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label={t('form.clinic.label')}
                  error={errors[ClinicEnum.recordClinicId]}
                  isLoading={selectClinic.isLoading}
                  options={selectClinic.options}
                  {...field}
                />
              )}
            />
          </GridItem>
        </Grid>
      </FieldSet>

      <FormFooter isSubmitting={isSubmitting} />
    </Flex>
  )
}
