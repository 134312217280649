// Vendors
import React, { useMemo, Dispatch, SetStateAction, useEffect } from 'react'

// Styles
import { Flex, Spacer, Button, ButtonGroup } from '@chakra-ui/react'
import { Pagination, Query } from 'types/Requests'
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
  FiMoreHorizontal,
} from 'react-icons/fi'

// Interfaces
export type ListPaginationProps = {
  pagination: {
    current: Pagination
    set: Dispatch<SetStateAction<Pagination>>
  }
  query: {
    current: Query
    set: Dispatch<SetStateAction<Query>>
  }
  beforeNavigation?(): void
}

export const ListPagination = (props: ListPaginationProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { pagination, query, beforeNavigation } = props
  const calculated = useMemo(() => {
    if (!pagination)
      return {
        min: 0,
        max: 0,
        of: '',
      }
    return {
      min:
        pagination.current.perPage * (pagination.current.currentPage - 1) + 1,
      max:
        pagination.current.perPage * (pagination.current.currentPage - 1) +
        pagination.current.currentPageCount,
      of: pagination.current.total,
    }
  }, [pagination])

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (pagination.current.currentPage > pagination.current.lastPage) {
      query.set({
        ...query.current,
        page: pagination.current.firstPage,
      })
    }
  }, [pagination, query])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex as="section" pb="2rem !important">
      {pagination.current.total > 0 && (
        <div className="count">
          {`Mostrando ${calculated.min} a ${calculated.max} de `}
          <b>{calculated.of}</b> registros
          {query.current.q === '' || query.current.q === undefined
            ? '.'
            : ' de sua busca.'}
        </div>
      )}

      <Spacer />

      {pagination.current.firstPage !== pagination.current.lastPage && (
        <ButtonGroup spacing="2">
          {pagination.current.firstPage !== pagination.current.currentPage && (
            <Button
              onClick={() => {
                if (beforeNavigation) beforeNavigation()
                query.set({
                  ...query.current,
                  page: pagination.current.firstPage,
                })
              }}
            >
              <FiChevronsLeft />
            </Button>
          )}

          {pagination.current.currentPage > 1 && (
            <Button
              className="prev"
              onClick={() => {
                if (beforeNavigation) beforeNavigation()
                query.set({
                  ...query.current,
                  page: pagination.current.currentPage - 1,
                })
              }}
            >
              <FiChevronLeft />
            </Button>
          )}

          {/* {pagination.current.currentPage - 3 >
            pagination.current.firstPage && (
            <Button
              className="separator"
              pointerEvents="none"
              bg="transparent"
              p="0"
            >
              <FiMoreHorizontal />
            </Button>
          )} */}

          {/* {pagination.current.currentPage - 2 >
            pagination.current.firstPage && (
            <Button
              onClick={() => {
                if (beforeNavigation) beforeNavigation()
                query.set({
                  ...query.current,
                  page: pagination.current.currentPage - 2,
                })
              }}
            >
              {pagination.current.currentPage - 2}
            </Button>
          )} */}

          {pagination.current.currentPage - 1 >
            pagination.current.firstPage && (
            <Button
              onClick={() => {
                if (beforeNavigation) beforeNavigation()
                query.set({
                  ...query.current,
                  page: pagination.current.currentPage - 1,
                })
              }}
            >
              {pagination.current.currentPage - 1}
            </Button>
          )}

          <Button className="current" bg="gray.300" pointerEvents="none">
            {pagination.current.currentPage}
          </Button>

          {pagination.current.currentPage + 1 <=
            pagination.current.lastPage && (
            <Button
              onClick={() => {
                if (beforeNavigation) beforeNavigation()
                query.set({
                  ...query.current,
                  page: pagination.current.currentPage + 1,
                })
              }}
            >
              {pagination.current.currentPage + 1}
            </Button>
          )}

          {/* {pagination.current.currentPage + 2 < pagination.current.lastPage && (
            <Button
              onClick={() => {
                if (beforeNavigation) beforeNavigation()
                query.set({
                  ...query.current,
                  page: pagination.current.currentPage + 2,
                })
              }}
            >
              {pagination.current.currentPage + 2}
            </Button>
          )} */}

          {/* {pagination.current.currentPage + 3 < pagination.current.lastPage && (
            <Button
              className="separator"
              pointerEvents="none"
              bg="transparent"
              p="0"
            >
              <FiMoreHorizontal />
            </Button>
          )} */}

          {pagination.current.lastPage > pagination.current.currentPage && (
            <Button
              className="next"
              onClick={() => {
                if (beforeNavigation) beforeNavigation()
                query.set({
                  ...query.current,
                  page: pagination.current.currentPage + 1,
                })
              }}
            >
              <FiChevronRight />
            </Button>
          )}

          {pagination.current.lastPage !== pagination.current.currentPage && (
            <Button
              onClick={() => {
                if (beforeNavigation) beforeNavigation()
                query.set({
                  ...query.current,
                  page: pagination.current.lastPage,
                })
              }}
            >
              <FiChevronsRight />
            </Button>
          )}
        </ButtonGroup>
      )}
    </Flex>
  )
}
