// Vendors
import React, { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Coupon, Indicators } from './types'

// Functions
import { formatDate } from 'utils/formatDate'
import { apiList, apiShow } from 'services/get'
import formatCurrency from 'utils/formatCurrency'
import { useListRecords } from 'hooks/useListRecords'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { MenuExports } from 'components/MenuExports'
import { ListPagination } from 'components/ListPagination'
import { CardStatSimpleList } from 'components/CardStatSimpleList'
import { ListTableRenderAs } from 'components/ListTableRowComponent'
import { FieldDateRangePicker } from 'components/FieldDateRangePicker'

// Styles
import {
  Box,
  Text,
  ListItem,
  Stack,
  UnorderedList,
  Link,
  Select,
} from '@chakra-ui/react'
import {
  FiCalendar,
  FiDollarSign,
  FiShoppingBag,
  FiShoppingCart,
} from 'react-icons/fi'
import { useSelectParser } from 'hooks/useSelectParser'

export const TabPerformance = () => {
  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectSeller = useSelectParser({
    accessor: { value: 'userId', label: 'name' },
    endpoint: '/app/person?type=seller',
  })

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id } = useParams<{ id: string }>()

  const endpoint = `/app/coupon/${id}/performance/order`

  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<Coupon>({
    endpoint,
  })

  const { t } = useTranslation('influencer')

  const [indicators, setIndicators] = useState<Indicators>({
    dateOfLastPurchase: '00/00/00',
    generatedOrders: 0,
    totalOrdersAmount: 0,
    mostPurchasedProducts: [],
  })

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const cardStats = useMemo(
    () => [
      {
        label: t('cards.performance.requestsGenerated'),
        value: String(indicators.generatedOrders),
        icon: FiShoppingBag,
      },
      {
        label: t('cards.performance.totalOrdersInBRL'),
        value: String(indicators.totalOrdersAmount),
        icon: FiDollarSign,
      },
      {
        label: t('cards.performance.dateOfLastPurchase'),
        value:
          indicators.dateOfLastPurchase === '00/00/00'
            ? indicators.dateOfLastPurchase
            : formatDate(new Date(indicators.dateOfLastPurchase), 'dd/MM/yy'),
        icon: FiCalendar,
      },
      {
        label: t('cards.performance.mostPurchasedProducts'),
        customValue: !indicators.mostPurchasedProducts.length ? (
          <Text>Nenhum produto foi comprado.</Text>
        ) : (
          <UnorderedList listStyleType="number">
            {indicators.mostPurchasedProducts.map((product) => {
              return (
                <ListItem
                  key={product.storeProductId}
                  sx={{
                    '&::marker': {
                      color: 'blue.500',
                      fontWeight: 'bold',
                      fontSize: 'md',
                    },
                  }}
                >
                  <Link
                    href={`/products/${product.storeProductId}`}
                    fontSize={14}
                    color="gray.700"
                    _hover={{
                      textDecoration: 'none',
                    }}
                  >
                    {product.mktTitle}
                  </Link>
                </ListItem>
              )
            })}
          </UnorderedList>
        ),
        icon: FiShoppingCart,
      },
    ],
    [indicators, t]
  )

  const listTableHeaders = useMemo(
    () => [
      {
        label: t('table.performance.headers.purchaseNumber'),
        accessor: 'purchaseNumber',
      },
      {
        label: t('table.performance.headers.purchaseDate'),
        accessor: 'createdAt',
      },
      {
        label: t('table.performance.headers.customer'),
        accessor: 'user.person.name',
      },
      {
        label: t('table.performance.headers.paymentMethod'),
        accessor: 'paymentMethod.name',
      },
      {
        label: t('table.performance.headers.coupon'),
        accessor: 'coupon.voucherCode',
      },
      {
        label: t('table.performance.headers.total'),
        accessor: 'price',
      },
      {
        label: t('table.performance.headers.status'),
        accessor: 'status',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
    ],
    [t]
  )

  useEffect(() => {
    Records.list.forEach((record) => {
      record.createdAt = formatDate(record.createdAt, 'dd/MM/yyyy')
      record.price = formatCurrency(+record.price)
    })

    const fetchIndicators = async () => {
      const data: Indicators | undefined = await apiList(
        `/app/coupon/${id}/performance/indicators`
      )

      if (data && data?.generatedOrders > 0) {
        setIndicators(data)
      }
    }
    fetchIndicators()
  }, [Records.list, id])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <CardStatSimpleList data={cardStats} />

      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <Stack direction={{ base: 'column', md: 'row' }}>
          <ListFilters query={Query} />

          <FieldDateRangePicker
            isSmall
            name="filterDate"
            query={Query}
            placeholderText="Filtrar por data"
            containerProps={{
              marginBottom: '0',
            }}
            showMonthDropdown
            dropdownMode="select"
          />

          <Select
            size="sm"
            placeholder="Todos os vendedores"
            onChange={(e) =>
              Query.set({ ...Query.current, sellerId: e.target.value })
            }
          >
            {selectSeller.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>

          <Select
            size="sm"
            placeholder="Todos os status"
            onChange={(e) =>
              Query.set({ ...Query.current, status: e.target.value })
            }
          >
            <option value="IN_EXPEDITION">Em expedição</option>
            <option value="SENT">Enviado</option>
            <option value="IN_TRANSIT">Em trânsito</option>
            <option value="DELIVERED">Entrega</option>
            <option value="SHIPPING_ALERT">Alerta de envio</option>
          </Select>
        </Stack>

        <MenuExports endpoint={endpoint} query={Query.current} />
      </Stack>

      <ListTable
        headers={listTableHeaders}
        records={Records}
        defaultActions="none"
      />

      <ListPagination pagination={Pagination} query={Query} />
    </Box>
  )
}
