import React, { useCallback, useMemo } from 'react'

import { useNotification } from 'contexts/notification'
import { NotificationStatus } from 'contexts/notification/types'

import { Footer } from '../Footer'
import { NotificationCard } from 'components/Notification/NotificationCard'

import { Box, Text, Stack, Divider } from '@chakra-ui/react'

export const TabMessage = () => {
  const { notifications, nextPage, prevPage } = useNotification()

  const meta = useMemo(() => {
    if (!notifications) {
      return []
    }

    const draftUnread = notifications[NotificationStatus.UNREAD]
    const draftRead = notifications[NotificationStatus.READ]

    const metadata = []
    if (draftUnread) {
      metadata.push(draftUnread.meta)
    }

    if (draftRead) {
      metadata.push(draftRead.meta)
    }

    return metadata
  }, [notifications])

  const notificationsUnread = useMemo(() => {
    if (!notifications) {
      return null
    }

    const draft = notifications[NotificationStatus.UNREAD]

    if (!draft) {
      return null
    }

    return draft.data
  }, [notifications])

  const notificationsRead = useMemo(() => {
    if (!notifications) {
      return null
    }

    const draft = notifications[NotificationStatus.READ]

    if (!draft) {
      return null
    }

    return draft.data
  }, [notifications])

  const isBlank = useMemo(() => {
    if (!notificationsUnread && !notificationsRead) {
      return true
    }

    if (notificationsUnread?.length === 0 && notificationsRead?.length === 0) {
      return true
    }

    return false
  }, [notificationsRead, notificationsUnread])

  const handleNextPage = useCallback(() => {
    nextPage(NotificationStatus.READ)
    nextPage(NotificationStatus.UNREAD)
  }, [nextPage])

  const handlePrevPage = useCallback(() => {
    prevPage(NotificationStatus.READ)
    prevPage(NotificationStatus.UNREAD)
  }, [prevPage])

  return (
    <Box>
      {isBlank ? (
        <Text
          pt="6"
          pb="3"
          display="flex"
          fontSize="xs"
          fontWeight="thin"
          fontStyle="italic"
          alignItems="center"
          justifyContent="center"
        >
          Você não tem nenhuma mensagem
        </Text>
      ) : (
        <>
          {notificationsUnread && (
            <Stack boxShadow="md" mb="2.5" divider={<Divider />}>
              {notificationsUnread.map((notification) => (
                <NotificationCard key={notification.id} data={notification} />
              ))}
            </Stack>
          )}

          {notificationsRead && (
            <Stack divider={<Divider />}>
              {notificationsRead.map((notification) => (
                <NotificationCard key={notification.id} data={notification} />
              ))}
            </Stack>
          )}
        </>
      )}

      <Footer
        meta={meta}
        onNextPage={handleNextPage}
        onPrevPage={handlePrevPage}
      />
    </Box>
  )
}
