// Vendors
import React, { ReactNode } from 'react'

// Styles
import { Box, BoxProps, Heading } from '@chakra-ui/react'

type FieldSetProps = BoxProps & {
  title: string
  children: ReactNode
}

export const FieldSet = (props: FieldSetProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { title, children, ...rest } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box as="fieldset" {...rest}>
      <Heading as="legend" fontSize="xl" lineHeight="6" mb="4">
        {title}
      </Heading>

      {children}
    </Box>
  )
}
