// Vendors
import React, { useCallback } from 'react'
import { forEach, toPairs } from 'lodash'
import { useForm, UseFormReturn } from 'react-hook-form'

// Functions
import { useRole } from 'hooks/useRole'

// Components
import { FormFooter } from 'components/FormFooter'
import { DrawerListContent } from 'components/DrawerListContent'
import {
  FormRequirementDocumentsFields,
  RequirementDocuments,
} from 'components/FormRequirementDocumentsFields'

// Styles
import { DrawerBody, Stack } from '@chakra-ui/react'

// Types
import { Role } from 'types/Role'

type DrawerRoleNewProps = {
  role: Role
  title: string
  userId: string
  methods?: UseFormReturn
  missingDocumentsDefault: RequirementDocuments[]
  onChangeStatusState: (roleId: string, status: string) => void
  disclosure: {
    isOpen: boolean
    onClose: () => void
  }
}

export const DrawerRoleNew = (props: DrawerRoleNewProps) => {
  const {
    role,
    title,
    userId,
    disclosure,
    onChangeStatusState,
    missingDocumentsDefault,
  } = props

  const { addUserRole } = useRole()
  const methods = useForm()

  const onClose = useCallback(() => {
    methods.reset()
    methods.unregister()
    disclosure.onClose()
  }, [disclosure, methods])

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        const formData = new FormData()

        forEach(toPairs(data), ([key, value]) => {
          let valueToSend = value

          if (typeof value === 'object') {
            if (!value) return

            valueToSend = (value as { value: string }).value
          }

          formData.append(key, valueToSend as string | File)
        })

        await addUserRole(userId, role.id, formData)

        onChangeStatusState(role.id, 'ENABLED')
        onClose()
      } catch {
        onChangeStatusState(role.id, 'DISABLED')
      }
    },
    [addUserRole, onChangeStatusState, onClose, role.id, userId]
  )

  return (
    <DrawerListContent
      title={title}
      isOpen={disclosure.isOpen}
      onClose={onClose}
    >
      <DrawerBody>
        <Stack spacing="5" as="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <FormRequirementDocumentsFields
            role={role}
            methods={methods}
            missingDocumentsDefault={missingDocumentsDefault}
          />

          <FormFooter
            onCancel={onClose}
            isSubmitting={methods.formState.isSubmitting}
          />
        </Stack>
      </DrawerBody>
    </DrawerListContent>
  )
}
