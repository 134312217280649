// Vendors
import { debounce, filter, find, get } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, FieldError, useFormContext } from 'react-hook-form'

// Functions
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FieldSet } from 'components/FieldSet'
import { FieldSelect } from 'components/FieldSelect'
import { FieldTextArea } from 'components/FieldTextArea'

// Styles
import { Box, Grid, GridItem } from '@chakra-ui/react'

// Types
import { FormOtherInfoType } from '../types'
import { apiList } from 'services/get'
import { OptionsType } from 'components/FormFieldEditerOptions'

export const TabOperation = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    watch,
    control,
    register,

    getValues,
    formState: { errors },
  } = useFormContext<FormOtherInfoType>()

  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectStates = useSelectParser({
    endpoint: '/app/state',
    accessor: { label: 'abbreviatedName', value: 'id' },
    requestWithoutMeta: true,
  })

  const selectExpedition = useSelectParser({
    accessor: { label: 'name', value: 'userId' },
    endpoint: '/app/person?type=expedition',
  })
  const selectProducts = useSelectParser({
    accessor: { label: 'name', value: 'id' },
    endpoint: '/public/store_product',
  })

  const [selectCities, setSelectCities] = useState<{
    options: OptionsType[]
    isLoading: boolean
  }>({
    options: [],
    isLoading: false,
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onInputChange = useCallback(
    debounce(async (value) => {
      if (value.length < 3) return

      try {
        setSelectCities((oldState) => ({
          options: oldState.options,
          isLoading: true,
        }))

        const response = await apiList<{ name: string; id: string }[]>(
          `/app/city?q=${value}`
        )

        if (!response) return

        const selectCitiesFormatted = response.map((item) => ({
          label: item.name,
          value: item.id,
        }))

        setSelectCities({ options: selectCitiesFormatted, isLoading: false })
      } catch (error) {
        console.error(error)
      } finally {
        setSelectCities((oldState) => ({
          options: oldState.options,
          isLoading: false,
        }))
      }
    }, 500),
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <FieldSet title="Outras informações">
        <Grid
          my="4"
          gap="10"
          maxW="container.md"
          templateColumns={{ lg: 'repeat(2, 1fr)' }}
        >
          <GridItem colSpan={{ base: 1, lg: 2 }}>
            <FieldTextArea
              maxLength={255}
              currentLength={get(watch('additionalInformations'), 'length')}
              label="Informações complementares"
              error={errors.additionalInformations}
              {...register('additionalInformations')}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="employees"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label="Vínculo com usuários expedição"
                  error={errors.employees as FieldError}
                  isLoading={selectExpedition.isLoading}
                  options={selectExpedition.options}
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="storeProductIds"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label="Produtos vinculados à base"
                  error={errors.storeProductIds as FieldError}
                  isLoading={selectProducts.isLoading}
                  options={selectProducts.options}
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="states"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label="Estados vinculados à base"
                  error={errors.states as FieldError}
                  isLoading={selectStates.isLoading}
                  options={selectStates.options}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="cities"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label="Cidades vinculados à base"
                  error={errors.cities as FieldError}
                  isLoading={selectCities.isLoading}
                  options={selectCities.options}
                  onInputChange={(v) => {
                    onInputChange(v)
                  }}
                  placeholder="Digite pelo menos 3 caracteres..."
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>
        </Grid>
      </FieldSet>
    </Box>
  )
}
