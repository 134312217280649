// Vendors
import { v4 } from 'uuid'
import * as React from 'react'

// Styles
import { FiPlus } from 'react-icons/fi'
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'

// Types
interface ButtonWithMenuProps {
  label: string
  icon?: React.ReactElement
  actions: Array<{
    label: string
    onClick: () => void
  }>
}

export const ButtonWithMenu = (props: ButtonWithMenuProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { actions, icon, label } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Menu>
      <MenuButton
        as={Button}
        iconSpacing="1"
        colorScheme="blue"
        leftIcon={icon || <FiPlus />}
      >
        {label}
      </MenuButton>

      <MenuList color="gray.700">
        {actions.map((action) => (
          <MenuItem key={v4()} onClick={action.onClick}>
            {action.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
