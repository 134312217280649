import { TFunction } from 'react-i18next'
import { yupSchema } from 'utils/yupSchema'

import * as yup from 'yup'

export const schema = (t: TFunction<'common'>) => {
  yupSchema(t)

  const sch = yup.object().shape({
    name: yup.string().required().label(t('form.person.name.label')),

    email: yup.string().email().required().label(t('form.person.email.label')),

    phoneNumber: yup
      .string()
      .transform((value) => `+55${value.replace(/\D/g, '')}`)
      .required()
      .label(t('form.person.phoneNumber.label')),

    documentNumber: yup
      .string()
      .getCharacters()
      .length(11)
      .required()
      .label(t('form.person.cpf.label')),

    birthdate: yup.date().required().label(t('form.person.birthdate.label')),

    gender: yup.string().required().label(t('form.person.genre.label')),

    companyId: yup
      .string()
      .selectValue()
      .label(t('form.person.companyId.label')),

    status: yup.string().getStatus().label(t('form.person.genre.label')),
  })

  return sch
}
