// Vendors
import { Controller, UseFormReturn } from 'react-hook-form'
import { get, includes, map, reject, filter } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

// Functions
import { apiList } from 'services/get'
import { useRole } from 'hooks/useRole'

// Components
import { FieldFile } from 'components/FieldFile'
import { FieldText } from 'components/FieldText'
import { FieldSelect } from 'components/FieldSelect'

// Styles
import { Role } from 'types/Role'

// Types
import { Company } from 'types/Company'
import { Pagination } from 'types/Requests'

enum RequirementTypeEnum {
  FILE = 'file',
  VALUE = 'value',
  COMPANY = 'company',
}

export type RequirementDocuments = {
  id: string
  name: string
  slug: string
  requirementType: RequirementTypeEnum
}

export type FormRequirementDocumentsFieldsProps = {
  role: Role
  methods: UseFormReturn
  missingDocumentsDefault?: RequirementDocuments[]
}

export const FormRequirementDocumentsFields = (
  props: FormRequirementDocumentsFieldsProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { missingDocumentsDefault, role, methods } = props

  const { checkDocumentRequirements } = useRole()
  const requiredSelectOptions = useMemo(
    () => ({
      'record-clinic-id': 'clinic',
      'record-testfy-id': 'testfy-base',
      'record-logistic-id': 'logistics',
      'record-laboratory-id': 'laboratory',
    }),
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [options, setOptions] = useState({})
  const [missingDocuments, setMissingDocuments] = useState<
    RequirementDocuments[]
  >(missingDocumentsDefault || [])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const renderFieldFile = useCallback(
    (document: RequirementDocuments) => {
      const documentWatch = methods.watch(document.slug)

      return (
        <FieldFile
          label={document.name}
          error={methods.formState.errors[document.slug]}
          fileName={get(documentWatch, '[0].name')}
          {...methods.register(document.slug, {
            required: `${document.name} é obrigatorio`,
          })}
          isRequired
        />
      )
    },
    [methods]
  )
  const renderFieldText = useCallback(
    (document: RequirementDocuments) => {
      return (
        <FieldText
          label={document.name}
          error={methods.formState.errors[document.slug]}
          {...methods.register(document.slug, {
            required: `${document.name} é obrigatorio`,
          })}
          isRequired
        />
      )
    },
    [methods]
  )
  const renderFieldSelect = useCallback(
    (document: RequirementDocuments) => {
      return (
        <Controller
          name={document.slug}
          control={methods.control}
          render={({ field }) => (
            <FieldSelect
              label={document.name}
              options={get(options, document.slug)}
              {...field}
            />
          )}
        />
      )
    },
    [options, methods]
  )

  const getOptions = useCallback(
    (requiredDocuments: RequirementDocuments[]) => {
      const fieldOptions = filter(requiredDocuments, (document) =>
        includes(Object.keys(requiredSelectOptions), document.slug)
      )

      if (!fieldOptions.length) return

      fieldOptions.forEach((value) => {
        apiList<{ meta: Pagination; data: Company[] }>(
          `/app/company?type=${get(requiredSelectOptions, value.slug)}`
        ).then((response) => {
          if (!response) return

          const optionsData = map(response.data, (data) => ({
            value: data.id,
            label: data.internalIdentification,
          }))

          setOptions((oldState) => ({ ...oldState, [value.slug]: optionsData }))
        })
      })
    },
    [requiredSelectOptions]
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!role.id) return

    async function fetchData() {
      if (missingDocumentsDefault) {
        getOptions(missingDocumentsDefault)
        return
      }

      const response = await checkDocumentRequirements(role.id)

      if (!response) return
      getOptions(response)

      const filterResponse = reject(response, {
        requirementType: 'company',
      }) as RequirementDocuments[]

      setMissingDocuments(filterResponse)
    }

    fetchData()
  }, [role.id, missingDocumentsDefault, getOptions, checkDocumentRequirements])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const documentsFieldsMap = useMemo(
    () => ({
      file: renderFieldFile,
      value: renderFieldText,
      company: renderFieldSelect,
    }),
    [renderFieldFile, renderFieldText, renderFieldSelect]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <>
      {missingDocuments.map((document) => {
        const renderComponent = documentsFieldsMap[document.requirementType]

        if (!renderComponent) return null

        return renderComponent(document)
      })}
    </>
  )
}
