// Vendors
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useState } from 'react'

// Functions
import { apiList } from 'services/get'
import { useError } from 'hooks/useError'
import { apiDelete } from 'services/delete'

// Components
import { AddressData, ItemAddress } from './ItemAddress'
import { AlertDialogDelete } from 'components/AlertDialogDelete'
import { DrawerFormAddress } from 'components/DrawerFormAddress'

// Styles
import {
  Box,
  Flex,
  Text,
  Stack,
  Button,
  Divider,
  Heading,
  useDisclosure,
} from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'

// Interfaces
export type ListAddressProps = {
  children?: React.ReactNode
  personId?: string
}

export const ListAddress = (props: ListAddressProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { personId } = props

  const { t } = useTranslation('common')
  const { handleError } = useError()
  const modalEdit = useDisclosure()
  const alertDelete = useDisclosure()

  const enpoint = personId
    ? `/app/person/${personId}/address`
    : '/app/person/address'

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [address, setAddress] = useState<AddressData | undefined>(undefined)
  const [addresses, setAddresses] = useState<AddressData[]>([])
  const [addressId, setAddressId] = useState<string | undefined>(undefined)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const reloadAddress = useCallback(() => {
    apiList<AddressData[]>(enpoint).then((response) => {
      if (!response) return

      setAddresses(response)
    })
  }, [enpoint])

  const handleClose = useCallback(() => {
    setAddress(undefined)
    modalEdit.onClose()
  }, [modalEdit])

  const handleEdit = useCallback(
    (id: string) => {
      setAddress(addresses.find((address) => address.id === id))
      modalEdit.onOpen()
    },
    [addresses, modalEdit]
  )

  const handleOpenAlertRemove = useCallback(
    (id: string) => {
      setAddressId(id)
      alertDelete.onOpen()
    },
    [alertDelete]
  )

  const handleRemove = useCallback(async () => {
    if (!addressId) return

    try {
      await apiDelete(`${enpoint}/${addressId}`)

      reloadAddress()
    } catch (error: any) {
      handleError(error)
    } finally {
      setAddressId(undefined)
      alertDelete.onClose()
    }
  }, [addressId, alertDelete, enpoint, reloadAddress, handleError])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    reloadAddress()
  }, [reloadAddress])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box boxShadow="md" borderRadius="md">
      <DrawerFormAddress
        personId={personId}
        onClose={handleClose}
        reload={reloadAddress}
        defaultValues={address}
        isOpen={modalEdit.isOpen}
      />

      <AlertDialogDelete
        isOpen={alertDelete.isOpen}
        onConfirm={handleRemove}
        onCancel={alertDelete.onClose}
      />

      <Flex
        as="header"
        alignItems="center"
        justifyContent="space-between"
        pl="8"
        py="4"
        pr="6"
      >
        <Heading fontSize="lg" color="gray.900">
          {t('list.address.title')}
        </Heading>

        <Button
          leftIcon={<FiPlus />}
          colorScheme="blue"
          onClick={modalEdit.onOpen}
        >
          {t('list.address.buttonLabel')}
        </Button>
      </Flex>

      <Divider />

      <Stack divider={<Divider />} pt="5" px="8" pb="8" spacing="6">
        {addresses.length ? (
          addresses.map((address) => (
            <ItemAddress
              key={address.id}
              address={address}
              onEdit={handleEdit}
              onDelete={handleOpenAlertRemove}
            />
          ))
        ) : (
          <Box>
            <Text>{t('list.address.empty')}</Text>
          </Box>
        )}
      </Stack>
    </Box>
  )
}
