// Vendors
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, FieldError, useForm } from 'react-hook-form'
import React, { ChangeEvent, useCallback, useEffect } from 'react'

// Functions
import { optionsDiscountType } from '../../selects'
import { useEditRecords } from 'hooks/useEditRecord'

// Components
import { FieldText } from 'components/FieldText'
import { FieldDate } from 'components/FieldDate'
import { FieldToggle } from 'components/FieldToggle'
import { FieldNumber } from 'components/FieldNumber'
import { FieldTextArea } from 'components/FieldTextArea'
import { FieldSelect } from 'components/FieldSelect'
import { FormFooter } from 'components/FormFooter'

// Schemas
import { schema } from '../schema'

// Styles
import { Flex, useToast, Grid, GridItem, useDisclosure } from '@chakra-ui/react'

// Hooks
import { useFormTransform } from 'hooks/useFormTransform'

// Types
import { CouponShowEditRecord, CouponShowForm } from '../types'
import { useSelectParser } from 'hooks/useSelectParser'
import { Product } from 'types/Product'
import { get } from 'lodash'
import { useFeedback } from 'contexts/feedback'
import { useCan } from 'hooks/useCan'
import { RULES } from '../../new/rules'

type TabCouponShowProps = {
  id: string
  endpoint: string
}

export const TabCouponShow = (props: TabCouponShowProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { options: optionsProducts, isLoading } = useSelectParser<Product>({
    accessor: { value: 'id', label: 'name' },
    endpoint: 'public/store_product',
  })

  const selectAssigned = useSelectParser<{ userId: string; name: string }>({
    accessor: { value: 'userId', label: 'name' },
    endpoint: 'app/influencer',
  })

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { endpoint, id } = props

  const { can } = useCan({ rules: RULES })

  const { record } = useEditRecords<CouponShowEditRecord, CouponShowForm>({
    endpoint,
    currentId: id,
  })

  const toast = useToast({ position: 'top-right' })
  const { feedback } = useFeedback()
  const { t } = useTranslation('common')

  const {
    watch,
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema(t, can)),
  })

  const { selectParser, statusParser } = useFormTransform()
  const disclosureDelete = useDisclosure()

  const recordCurrent = record.current

  const watchValidFrom = watch('validFrom')

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    const discountTypeId = selectParser(
      recordCurrent?.discountType,
      optionsDiscountType
    )
    const storeProductIds = selectParser(
      recordCurrent?.storeProductIds,
      optionsProducts
    )

    const assignedUserId = selectParser(
      recordCurrent?.assignedUserId,
      selectAssigned.options
    )

    const status = statusParser(recordCurrent.status)

    reset({
      ...recordCurrent,
      discountPrice: recordCurrent?.discountPrice || undefined,
      discountPercentage: recordCurrent?.discountPercentage || undefined,
      maximumClientUsage: recordCurrent?.maximumClientUsage || undefined,
      maximumUsage: recordCurrent?.maximumUsage || undefined,
      discountType: discountTypeId,
      storeProductIds,
      assignedUserId,
      status,
    })
  }, [
    reset,
    selectParser,
    statusParser,
    recordCurrent,
    optionsProducts,
    selectAssigned.options,
  ])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleToggle = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target

      if (checked) {
        await record.enable()
      } else {
        await record.disable()
      }

      toast({
        title: 'Sucesso',
        description: 'Status atualizado com sucesso',
        status: 'success',
      })
    },
    [record, toast]
  )

  const onSubmit = useCallback(
    async (data: CouponShowForm) => {
      try {
        await record.update(data)
        toast({
          title: t('toast.success.default', {
            type: t('common:toast.success.update'),
          }),
          status: 'success',
        })
      } catch {
        feedback({
          title: t('toast.error.default', {
            type: 'common:toast.error.update',
          }),
          status: 'error',
        })
      }
    },
    [feedback, record, t, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex
      as="form"
      h="full"
      flexDirection="column"
      justifyContent="space-between"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="status"
        control={control}
        render={({ field: { value, ref, name, onChange } }) => (
          <FieldToggle
            name={name}
            position="absolute"
            top={-16}
            right={0}
            ref={ref}
            isChecked={value}
            error={errors.status}
            onChange={(e) => {
              onChange(e)
              handleToggle(e)
            }}
          />
        )}
      />
      <Grid
        my="4"
        gap="10"
        templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(4, 1fr)' }}
      >
        <GridItem colSpan={{ lg: 2 }}>
          <FieldText
            label="Nome"
            maxLength={120}
            currentLength={get(watch('name'), 'length')}
            error={errors.name}
            {...register('name')}
            isRequired
          />
        </GridItem>

        <GridItem>
          <FieldText
            isDisabled
            maxLength={45}
            currentLength={get(watch('voucherCode'), 'length')}
            label="Código do voucher"
            error={errors.voucherCode}
            {...register('voucherCode')}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="discountType"
            control={control}
            render={({ field }) => (
              <FieldSelect
                isDisabled
                label="Selecione a forma de desconto"
                error={errors.discountType as FieldError}
                options={optionsDiscountType}
                {...field}
              />
            )}
          />
        </GridItem>

        <GridItem>
          <FieldNumber
            isDisabled
            control={control}
            name="discountPercentage"
            max={!can.discountPercentage50 ? 50 : 15}
            label="Porcentagem de desconto"
            error={errors.discountPercentage}
            placeholder="Para valor zero, não preencher."
          />
        </GridItem>

        <GridItem>
          <FieldNumber
            isDisabled
            control={control}
            name="discountPrice"
            label="Preço de desconto"
            error={errors.discountPrice}
            placeholder="Para valor zero, não preencher."
          />
        </GridItem>

        <GridItem colSpan={{ lg: 2 }}>
          <FieldTextArea
            isDisabled
            maxLength={120}
            label="Descrição"
            currentLength={get(watch('description'), 'length')}
            error={errors.description}
            {...register('description')}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="validFrom"
            control={control}
            render={({ field }) => {
              return (
                <FieldDate
                  disabled
                  label="Válido de"
                  name={field.name}
                  selected={field.value}
                  onChange={field.onChange}
                  error={errors.validFrom}
                />
              )
            }}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="validUntil"
            control={control}
            render={({ field }) => (
              <FieldDate
                disabled
                label="Válido até"
                minDate={new Date(watchValidFrom)}
                name={field.name}
                selected={field.value}
                onChange={field.onChange}
                error={errors.validUntil}
              />
            )}
          />
        </GridItem>

        <GridItem>
          <FieldNumber
            isDisabled
            control={control}
            label="Uso por cliente"
            name="maximumClientUsage"
            error={errors.maximumClientUsage}
          />
        </GridItem>

        <GridItem>
          <FieldNumber
            isDisabled
            control={control}
            name="maximumUsage"
            label="Total de uso"
            error={errors.maximumUsage}
          />
        </GridItem>

        <GridItem colSpan={{ lg: 2 }}>
          <Controller
            control={control}
            name="isEligibleAllStoreProducts"
            render={({ field: { name, value, onChange } }) => (
              <FieldToggle
                isDisabled
                label="Aplicável a todos os produtos da loja"
                name={name}
                isChecked={value}
                onChange={onChange}
                error={errors.isEligibleAllStoreProducts}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={{ lg: 2 }}>
          <Controller
            name="storeProductIds"
            control={control}
            render={({ field }) => (
              <FieldSelect
                isDisabled
                label="Produtos vinculados"
                error={errors.storeProductIds}
                isMulti
                options={optionsProducts}
                isLoading={isLoading}
                {...field}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={{ lg: 2 }}>
          <Controller
            name="assignedUserId"
            control={control}
            render={({ field }) => (
              <FieldSelect
                isDisabled
                label="Atribuir cupom ao usuário"
                error={errors.assignedUserId}
                options={selectAssigned.options}
                isLoading={selectAssigned.isLoading}
                {...field}
              />
            )}
          />
        </GridItem>
      </Grid>

      <FormFooter isSubmitting={isSubmitting} recordDelete={record.delete} />
    </Flex>
  )
}
