// Vendors
import React from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'

// Components

// Styles
import { Box, Stack } from '@chakra-ui/react'

// Interfaces
import { HandleQuestionType, QuestionType } from 'hooks/useFormFieldEditer'
export type FormFieldEditerListProps = {
  questions: QuestionType[]
  handleQuestion: HandleQuestionType
}

export const FormFieldEditerList = (
  props: FormFieldEditerListProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { questions, handleQuestion } = props

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result

    if (!destination) return

    if (
      source.index === destination.index &&
      source.droppableId === destination.droppableId
    )
      return

    handleQuestion.move(source.index, destination.index)
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Box my="8">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="DynamicForm">
          {(provided) => (
            <Stack
              ref={provided.innerRef}
              {...provided.droppableProps}
              spacing="6"
            >
              {questions.map((question, index) => {
                const Component = question.component
                return (
                  <Component
                    key={question.id}
                    index={index}
                    question={question}
                    handleQuestion={handleQuestion}
                  />
                )
              })}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  )
}
