export const ROLES = [
  {
    name: 'SUPERADMIN',
    translated: 'Superadmin',
    slug: 'superadmin',
    status: 'ENABLED',
    isCreatableByAdmin: false,
  },
  {
    name: 'ADMIN',
    translated: 'Administrador(a)',
    slug: 'admin',
    status: 'ENABLED',
    isCreatableByAdmin: true,
  },
  {
    name: 'FINANCIAL',
    translated: 'Financeiro(a)',
    slug: 'financial',
    status: 'ENABLED',
    isCreatableByAdmin: true,
  },
  {
    name: 'BACKOFFICE',
    translated: 'Backoffice',
    slug: 'backoffice',
    status: 'ENABLED',
    isCreatableByAdmin: true,
  },
  {
    name: 'SELLER',
    translated: 'Vendedor(a)',
    slug: 'seller',
    status: 'ENABLED',
    isCreatableByAdmin: true,
  },
  {
    name: 'PROFESSIONAL',
    translated: 'Profissional de Saúde',
    slug: 'professional',
    status: 'ENABLED',
    isCreatableByAdmin: false,
  },
  {
    name: 'SECRETARY',
    translated: 'Secretário(a)',
    slug: 'secretary',
    status: 'ENABLED',
    isCreatableByAdmin: false,
  },
  {
    name: 'CLIENT',
    translated: 'Cliente',
    slug: 'client',
    status: 'ENABLED',
    isCreatableByAdmin: false,
  },
  {
    name: 'EXPEDITION',
    translated: 'Expedição',
    slug: 'expedition',
    status: 'ENABLED',
    isCreatableByAdmin: true,
  },
  {
    name: 'LOGISTIC_MANAGER',
    translated: 'Gestor(a) Logístico',
    slug: 'logistic-manager',
    status: 'ENABLED',
    isCreatableByAdmin: true,
  },
  {
    name: 'LOGISTIC_AGENT',
    translated: 'Agente Logístico',
    slug: 'logistic-agent',
    status: 'ENABLED',
    isCreatableByAdmin: true,
  },
  {
    name: 'LABORATORY_MANAGER',
    translated: 'Gestor(a) de Laboratório',
    slug: 'laboratory-manager',
    status: 'ENABLED',
    isCreatableByAdmin: true,
  },
  {
    name: 'LABORATORY_AGENT',
    translated: 'Agente de Laboratório',
    slug: 'laboratory-agent',
    status: 'ENABLED',
    isCreatableByAdmin: true,
  },
  {
    name: 'INFLUENCER',
    translated: 'Influencer',
    slug: 'influencer',
    status: 'ENABLED',
    isCreatableByAdmin: true,
  },
  {
    name: 'PATIENT',
    translated: 'Paciente',
    slug: 'patient',
    status: 'ENABLED',
    isCreatableByAdmin: false,
  },
] as const

export const ROLES_CREATABLE = ROLES.filter(({ isCreatableByAdmin }) =>
  Boolean(isCreatableByAdmin)
)

export type ROLE_TYPE = typeof ROLES[number]

export const ROLES_SLUGS = ROLES.map(({ slug }) => slug)

export const ROLES_NAMES = ROLES.map(({ name }) => name)

// Types
export type ROLE_NAMES_TYPES = typeof ROLES_NAMES[0]

export type ROLE_SLUGS_TYPES = typeof ROLES_SLUGS[0]
