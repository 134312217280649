import Lottie from 'react-lottie'
import React from 'react'

import { Button, useClipboard } from '@chakra-ui/react'

import animationData from 'assets/animations/check-animation.json'

type ButtonCopyDataProps = {
  text: string
}

export const ButtonCopyData = (props: ButtonCopyDataProps) => {
  const { text } = props

  const { hasCopied, onCopy } = useClipboard(text, {
    format: 'text/plain',
  })

  return (
    <Button
      w="32"
      size="sm"
      onClick={onCopy}
      variant="outline"
      colorScheme="blue"
    >
      {hasCopied ? (
        <Lottie
          height={32}
          width={32}
          options={{
            animationData,
            autoplay: true,
            loop: true,
          }}
        />
      ) : (
        'COPIAR DADOS'
      )}
    </Button>
  )
}
