// Vendors
import React from 'react'

// Components

// Styles
import { Accordion, Heading, Stack } from '@chakra-ui/react'
import {
  AccordionItem,
  HistoryUpdate,
} from 'pages/private/Orders/show/components/AccordionItem'
import { AccordionContent } from 'pages/private/Orders/show/components/AccordionContent'
import { AccordionBlank } from 'pages/private/Orders/show/components/AccordionBlank'

type TabHistoryProps = {
  reload: () => void
  historyUpdates?: HistoryUpdate[]
}

export const TabHistory = (props: TabHistoryProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { historyUpdates, reload } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Stack spacing="4" maxW="container.md">
      {historyUpdates && historyUpdates.length && (
        <>
          <Heading fontSize={20}>Histórico do pedido</Heading>
          {historyUpdates && historyUpdates.length ? (
            <AccordionContent reload={reload} historyUpdates={historyUpdates} />
          ) : (
            <AccordionBlank />
          )}
        </>
      )}
    </Stack>
  )
}
