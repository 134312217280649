// Vendors
import React from 'react'
import { Result } from '@zxing/library'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'

// Styles
import { Box } from '@chakra-ui/react'

// Interfaces
export type BarCodeScannerProps = {
  onScan: (data: string) => void
  height?: number
  width?: number
  delay?: number
}

export const BarCodeScanner = (props: BarCodeScannerProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { onScan, height = 520, width = 520, delay = 500 } = props

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleUpdate = (_err: unknown, result: Result | undefined) => {
    if (!result) return

    onScan(result.getText())
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Box>
      <BarcodeScannerComponent
        delay={delay}
        width={width}
        height={height}
        onUpdate={handleUpdate}
      />
    </Box>
  )
}
