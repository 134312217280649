import { PageMeta } from 'types/Pages'
import { Location } from 'history'
import { find } from 'lodash'
import { PageMetaSettings } from 'pages/PageMetaSettings'

// TODO - Remove this file when not used anymore.

export const usePageMeta = ({ pathname }: Location): PageMeta => {
  const pageMeta = find(PageMetaSettings, { location: pathname.split('/')[1] })
  if (!pageMeta)
    throw new Error(
      'Metadados da página não encontrados\r\nVerificar arquivo ./src/pages/index.tsx'
    )

  return pageMeta
}
