import { TFunction } from 'react-i18next'
import { yupSchema } from 'utils/yupSchema'

import * as yup from 'yup'

export const schema = (t: TFunction<'checkout'>) => {
  yupSchema(t as any)

  return yup.object().shape({
    // Client data
    name: yup.string().required().label(t('form.name.label')),

    documentNumber: yup
      .string()
      .required()
      .getCharacters()
      .label(t('form.documentNumber.label')),

    email: yup.string().email().optional().label(t('form.email.label')),

    clientId: yup.string().optional().label(t('form.clientId.label')),

    professionalId: yup
      .string()
      .optional()
      .selectValue()
      .label(t('form.professional.label')),

    // Products
    purchaseProducts: yup.array(
      yup.object().shape({
        storeProductId: yup
          .string()
          .required()
          .selectValue()
          .label(t('form.productId.label')),

        unitPrice: yup.number().min(0).required().label(t('form.price.label')),

        quantity: yup
          .number()
          .min(1)
          .required()
          .label(t('form.quantity.label')),

        subtotal: yup
          .number()
          .min(0)
          .required()
          .label(t('form.subtotal.label')),

        totalDiscount: yup
          .number()
          .min(0)
          .required()
          .label(t('form.discounts.label')),

        totalPrice: yup
          .number()
          .min(0)
          .required()
          .label(t('form.subtotalLine.label')),
      })
    ),

    // Coupon
    voucherCodes: yup.array(yup.string().required().selectValue()),

    // Purchase data
    sellerId: yup
      .string()
      .required()
      .selectValue()
      .label(t('form.sellerId.label')),

    purchaseCategoryIds: yup
      .array()
      .required()
      .selectValues()
      .label(t('form.purchaseCategoryIds.label')),

    purchaseType: yup.string().required().label(t('form.purchaseType.label')),

    comment: yup.string().label(t('form.comment.label')),

    isCobranding: yup
      .boolean()
      .label(t('form.isCobranding.label'))
      .default(false),

    // Shipping data
    shippingAddress: yup
      .object()
      .shape({
        postalCode: yup
          .string()
          .required()
          .getCharacters()
          .label(t('form.postalCode.label')),

        street: yup.string().required().label(t('form.street.label')),

        number: yup.string().required().label(t('form.number.label')),

        complement: yup.string().nullable().label(t('form.complement.label')),

        referencePoint: yup
          .string()
          .nullable()
          .label(t('form.referencePoint.label')),

        neighborhood: yup
          .string()
          .required()
          .label(t('form.neighborhood.label')),

        cityId: yup
          .string()
          .required()
          .selectValue()
          .label(t('form.city.label')),

        stateId: yup
          .string()
          .required()
          .selectValue()
          .label(t('form.state.label')),
      })
      .default(undefined),

    shippingAddressId: yup.string().label(t('form.addressId.label')),

    // Billing data

    billingAddress: yup
      .object()

      .shape({
        postalCode: yup
          .string()
          .getCharacters()
          .when('sameBillingAddress', (isSameBillingAddress, field) => {
            return !isSameBillingAddress
              ? field.required()
              : field.notRequired()
          })
          .label(t('form.postalCode.label')),

        number: yup
          .string()
          .when('sameBillingAddress', (isSameBillingAddress, field) => {
            return !isSameBillingAddress
              ? field.required()
              : field.notRequired()
          })
          .required()
          .label(t('form.number.label')),

        complement: yup.string().label(t('form.complement.label')),

        referencePoint: yup.string().label(t('form.referencePoint.label')),

        neighborhood: yup
          .string()
          .when('sameBillingAddress', (isSameBillingAddress, field) => {
            return !isSameBillingAddress
              ? field.required()
              : field.notRequired()
          })
          .label(t('form.neighborhood.label')),

        cityId: yup
          .string()
          .when('sameBillingAddress', (isSameBillingAddress, field) => {
            return !isSameBillingAddress
              ? field.required()
              : field.notRequired()
          })
          .selectValue()
          .label(t('form.city.label')),

        stateId: yup
          .string()
          .when('sameBillingAddress', (isSameBillingAddress, field) => {
            return !isSameBillingAddress
              ? field.required()
              : field.notRequired()
          })
          .selectValue()
          .label(t('form.state.label')),
      })
      .default(undefined),

    billingAddressId: yup.string().label(t('form.billingId.label')),

    // Payment and shipping
    paymentMethodId: yup
      .string()
      .required()
      .selectValue()
      .label(t('form.paymentMethodId.label')),

    shippingMethodId: yup
      .string()
      .required()
      .selectValue()
      .label(t('form.shippingMethodId.label')),
  })
}
