// Vendors
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

// Components
import { FieldText } from 'components/FieldText'
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListPagination } from 'components/ListPagination'
import { DrawerListContent } from 'components/DrawerListContent'

// Functions
import { useError } from 'hooks/useError'
import { useListRecords } from 'hooks/useListRecords'
import { useCreateRecord } from 'hooks/useCreateRecord'

// Styles
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  DrawerBody,
  DrawerFooter,
  useToast,
} from '@chakra-ui/react'
import { formatDate } from 'utils/formatDate'

// Types
type DrawerListNewProps = {
  title: string
  endpoint: string
  disclosure: {
    isOpen: boolean
    onClose: () => void
  }
}

export const DrawerListNew = (props: DrawerListNewProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { title, endpoint, disclosure } = props
  const toast = useToast()
  const { handleError } = useError()

  const { records, query, pagination } = useListRecords({
    endpoint,
  })
  const { create } = useCreateRecord({
    endpoint,
  })

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [record, setRecord] = useState({ name: '' })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    records.list.forEach((record: any) => {
      record.createdAt = formatDate(record.createdAt, 'dd/MM/yyyy')
    })
  }, [records.list])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const ListTableHeaders = useMemo(
    () => [
      {
        label: 'Nome',
        accessor: 'name',
      },
      {
        label: 'Criado em',
        accessor: 'createdAt',
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setRecord({ name: value })
  }, [])

  const onSubmit = useCallback(async () => {
    try {
      if (record.name === '') {
        throw new Error('Campo obrigatório')
      }

      await create(record)

      toast({
        title: 'Sucesso',
        description: 'Registro criado com sucesso',
        status: 'success',
      })

      records.reload()
    } catch (error: any) {
      handleError(error)
    }
  }, [create, handleError, record, records, toast])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <DrawerListContent
      size="lg"
      title={title}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
    >
      <DrawerBody>
        <ListFilters query={query}></ListFilters>

        <ListTable
          headers={ListTableHeaders}
          records={records}
          defaultActions="delete"
        />

        <ListPagination pagination={pagination} query={query} />

        <Box mt="2">
          <FieldText
            name="name"
            label="Nome"
            placeholder="Digite aqui..."
            onChange={onChange}
          />
        </Box>
      </DrawerBody>

      <Divider my="2" />

      <DrawerFooter>
        <ButtonGroup>
          <Button
            mb={{ base: '4', md: '0' }}
            w={{ base: 'full', md: 'auto' }}
            colorScheme="gray"
            size="lg"
            fontSize="md"
            fontWeight="bold"
            onClick={disclosure.onClose}
          >
            Cancelar
          </Button>

          <Button
            mb={{ base: '4', md: '0' }}
            w={{ base: 'full', md: 'auto' }}
            colorScheme="blue"
            size="lg"
            fontWeight="bold"
            onClick={onSubmit}
          >
            Salvar
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </DrawerListContent>
  )
}
