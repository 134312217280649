// Vendors
import React, { ChangeEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, FieldError, useFormContext } from 'react-hook-form'

// Hooks
import { useQuery } from 'hooks/useQuery'

// Components
import { FieldText } from 'components/FieldText'
import { FormWrapper } from 'components/FormWrapper'

// Styles
import { Grid, GridItem } from '@chakra-ui/react'

// Types
import { FormClinicData } from './types'
import { FieldSelect } from 'components/FieldSelect'
import { useSelectParser } from 'hooks/useSelectParser'

export const ClinicData = () => {
  /*
  |-----------------------------------------------------------------------------
  | Contants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const query = useQuery()
  const { t } = useTranslation('checkout')

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<FormClinicData>()

  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectProfessional = useSelectParser({
    endpoint: `/app/company/${query.get('companyClinicId')}/clinic_employees`,
    accessor: { value: 'id', label: (data: any) => data?.person?.name },
    requestWithoutMeta: true,
  })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormWrapper title={t('group.clinicData')}>
      <Grid
        my="4"
        gap="10"
        w={{ lg: 'container.md' }}
        templateColumns={{ lg: 'repeat(2, 1fr)' }}
      >
        <GridItem colSpan={{ lg: 2 }}>
          <FieldText
            isDisabled
            label={t('form.name.label')}
            error={errors.name}
            isRequired
            {...register('name')}
          />
        </GridItem>

        <GridItem>
          <FieldText
            isDisabled
            isRequired
            maxLength={18}
            error={errors.documentNumber}
            label={t('form.documentNumber.label')}
            {...register('documentNumber')}
          />
        </GridItem>

        <GridItem>
          <FieldText
            label={t('form.email.label')}
            error={errors.email}
            isRequired
            {...register('email')}
          />
        </GridItem>

        <GridItem>
          <Controller
            control={control}
            name="professionalId"
            render={({ field }) => (
              <FieldSelect
                isRequired
                label={t('form.professional.label')}
                options={selectProfessional.options}
                isLoading={selectProfessional.isLoading}
                error={errors.professionalId as FieldError}
                {...field}
              />
            )}
          />
        </GridItem>
      </Grid>
    </FormWrapper>
  )
}
