// Vendors
import React from 'react'
import { v4 } from 'uuid'
import { useTranslation } from 'react-i18next'

// Components
import { FormWrapper } from 'components/FormWrapper'

// Styles
import {
  Td,
  Tr,
  Box,
  Text,
  Flex,
  Image,
  Table,
  Tbody,
  Thead,
  AspectRatio,
} from '@chakra-ui/react'
import formatCurrency from 'utils/formatCurrency'
import { useCart } from 'contexts/cart'

export const ReviewOrder = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('checkout')

  const { checkout } = useCart()

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormWrapper title={t('group.reviewOrder')} titlePosition="top">
      <Table mt="6">
        <Thead bg="gray.50">
          <Tr>
            <Td>{t('table.headers.product')}</Td>
            <Td>{t('table.headers.value')}</Td>
            <Td>{t('table.headers.quantity')}</Td>
            <Td>{t('table.headers.subtotal')}</Td>
            <Td>{t('table.headers.discounts')}</Td>
            <Td>{t('table.headers.subtotalLine')}</Td>
          </Tr>
        </Thead>

        <Tbody>
          {checkout?.storeProductsList.map((product) => (
            <Tr key={v4()} h="20">
              <Td>
                <Flex alignItems="center">
                  {product.image && (
                    <AspectRatio w="98px" ratio={49 / 33}>
                      <Image
                        src={product.image}
                        alt={product.storeProductMktTitle}
                      />
                    </AspectRatio>
                  )}
                  <Text w="80" ml={product.image ? '2' : '106px'}>
                    {product.storeProductMktTitle}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Box position="relative">
                  {product.unitPrice !== product.unitPriceB2c && (
                    <Text textDecorationLine="line-through">
                      {formatCurrency(product.unitPriceB2c)}
                    </Text>
                  )}
                  <Text
                    position={
                      product.unitPrice !== product.unitPriceB2c
                        ? 'absolute'
                        : 'relative'
                    }
                  >
                    {formatCurrency(product.unitPrice)}
                  </Text>
                </Box>
              </Td>
              <Td>{String(product.quantity).padStart(2, '0')}</Td>
              <Td>{formatCurrency(product.subTotalPriceWithoutDiscount)}</Td>
              <Td>{formatCurrency(product.discountApplied)}</Td>
              <Td>{formatCurrency(product.subTotalPrice)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </FormWrapper>
  )
}
