// Vendors
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FieldError, useFormContext } from 'react-hook-form'

// Functions
import { apiList } from 'services/get'

// Components
import { FieldSelect } from 'components/FieldSelect'
import { FieldRadioButton } from 'components/FieldRadioButton'

// Styles
import { Grid, GridItem } from '@chakra-ui/react'

// Styles
import { ProductNewForm } from '../types'
import { useFormTransform } from 'hooks/useFormTransform'

// Types

export const TabLaboratorialAnalysis = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('products')
  const { selectParser } = useFormTransform()

  const {
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<ProductNewForm>()

  const storeProductHasLaboratoryAnalysisIds = getValues(
    'storeProductHasLaboratoryAnalysisIds'
  )
  const watchSampleTransportKitId = watch('sampleTransportKitId')?.value
  const [selectLaboratoryAnalysis, setSelectLaboratoryAnalysis] = useState<{
    options: { value: string; label: string }[]
  }>({ options: [] })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!watchSampleTransportKitId) return

    async function fetchData() {
      const response = await apiList<{
        sampleTransportKitAttendLaboratoryAnalysis: Array<{
          id: string
          name: string
        }>
      }>(`/app/sample_transport_kit/${watchSampleTransportKitId}`)

      if (!response) return

      const options = response.sampleTransportKitAttendLaboratoryAnalysis.map(
        (data) => ({
          value: data.id,
          label: data.name,
        })
      )

      setSelectLaboratoryAnalysis({ options })
    }

    fetchData()
  }, [watchSampleTransportKitId])

  useEffect(() => {
    if (
      !storeProductHasLaboratoryAnalysisIds ||
      !storeProductHasLaboratoryAnalysisIds.length
    )
      return
    if (typeof storeProductHasLaboratoryAnalysisIds[0] === 'object') return

    const storeProductHasLaboratoryAnalysisIdsOption = selectParser(
      storeProductHasLaboratoryAnalysisIds as string[],
      selectLaboratoryAnalysis.options
    )

    if (storeProductHasLaboratoryAnalysisIdsOption.includes(undefined)) return

    setValue(
      'storeProductHasLaboratoryAnalysisIds',
      storeProductHasLaboratoryAnalysisIdsOption
    )
  }, [
    setValue,
    selectParser,
    storeProductHasLaboratoryAnalysisIds,
    selectLaboratoryAnalysis,
  ])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const radioButtonOptions = useMemo(
    () => [
      {
        label: t('form.isLaboratoryAnalysisIncluded.options.yes'),
        value: 'true',
      },
      {
        label: t('form.isLaboratoryAnalysisIncluded.options.no'),
        value: 'false',
      },
    ],
    [t]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Grid
      my="4"
      gap="10"
      templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
    >
      <GridItem colSpan={2}>
        <Controller
          name={'storeProductHasLaboratoryAnalysisIds'}
          control={control}
          render={({ field }) => (
            <FieldSelect
              canModify={false}
              label={t('form.storeProductAttendLaboratoryAnalysisIds.label')}
              isMulti
              options={selectLaboratoryAnalysis.options}
              error={errors.storeProductHasLaboratoryAnalysisIds as FieldError}
              isRequired
              {...field}
            />
          )}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <FieldRadioButton
          canModify={false}
          control={control}
          name="isServiceIncluded"
          radioButtonDirection="row"
          label={t('form.isLaboratoryAnalysisIncluded.label')}
          options={radioButtonOptions}
        />
      </GridItem>
    </Grid>
  )
}
