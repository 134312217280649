import { Box, Heading } from '@chakra-ui/react'
import React from 'react'

type FormWrapperProps = {
  title: string
  titlePosition?: 'left' | 'top'
  children: React.ReactNode
}

export const FormWrapper = (props: FormWrapperProps) => {
  const { title, titlePosition = 'left', children } = props

  return (
    <Box
      position="relative"
      display={{ lg: titlePosition === 'left' ? 'flex' : '' }}
    >
      <Heading w="17.875rem" fontSize="xl">
        {title}
      </Heading>

      {children}
    </Box>
  )
}
