import { TFunction } from 'react-i18next'
import { yupSchema } from 'utils/yupSchema'

import * as yup from 'yup'

export const schema = (t: TFunction<'common'>) => {
  yupSchema(t)

  return yup.object().shape({
    name: yup.string().required().label(t('form.company.name.label')),

    documentNumber: yup
      .string()
      .getCharacters()
      .length(14)
      .required()
      .label(t('form.company.documentNumber.label')),

    street: yup.string().required().label(t('form.company.street.label')),

    number: yup.string().required().label(t('form.company.number.label')),

    cityId: yup
      .string()
      .required()
      .selectValue()
      .label(t('form.company.cityId.label')),

    stateId: yup
      .string()
      .selectValue()
      .required()
      .label(t('form.company.stateId.label')),

    postalCode: yup
      .string()
      .getCharacters()
      .length(8)
      .required()
      .label(t('form.company.postalCode.label')),

    complement: yup
      .string()
      .optional()
      .label(t('form.company.complement.label')),

    neighborhood: yup
      .string()
      .required()
      .label(t('form.company.neighborhood.label')),

    referencePoint: yup
      .string()
      .optional()
      .label(t('form.company.referencePoint.label')),

    employees: yup
      .array()
      .selectValues()
      .label(t('form.company.employees.label')),

    storeProductIds: yup
      .array()
      .selectValues()
      .label(t('form.company.storeProductIds.label')),

    additionalInformations: yup
      .string()
      .optional()
      .label(t('form.company.additionalInformations.label')),

    states: yup
      .array()
      .min(1)
      .required()
      .selectValues()
      .label(t('form.company.states.label')),

    cities: yup
      .array()
      .min(1)
      .required()
      .selectValues()
      .label(t('form.company.cities.label')),
  })
}
