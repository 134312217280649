// Vendors
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

// Components
import { TabGeneral } from './tabs/TabGeneral'
import { TabDetail, Tabs } from 'components/Tabs'
import { TabOperation } from './tabs/TabOperation'
import { FormFooter } from 'components/FormFooter'
import { FieldStatus } from 'components/FieldStatus'

// Styles
import { Box, Flex, useToast } from '@chakra-ui/react'

// Schemas
import { schema } from './schema'
import { useEditRecords } from 'hooks/useEditRecord'

// Types
import { CompanyBaseTestfyShow, CompanyBaseTestfyShowRecord } from './types'
import { useFormTransform } from 'hooks/useFormTransform'

type CompanyBaseTestfyFormShowProps = {
  id: string
  endpoint: string
}

export const CompanyBaseTestfyFormShow = (
  props: CompanyBaseTestfyFormShowProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint, id } = props

  const { record } = useEditRecords<
    CompanyBaseTestfyShow,
    CompanyBaseTestfyShow
  >({
    endpoint,
    currentId: id,
  })

  const recordCurrent = record.current

  const { statusParser } = useFormTransform()

  const { t } = useTranslation('common')

  const toast = useToast({ position: 'top-right' })

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    const employees = map(recordCurrent.expeditions, (expedition) => ({
      value: expedition?.id,
      label: expedition?.person?.name || 'N/A',
    }))

    const storeProductIds = map(
      recordCurrent.storeProducts,
      (storeProduct) => ({
        value: storeProduct.id,
        label: storeProduct?.name,
      })
    )

    const states = map(recordCurrent.states, (state) => ({
      value: state.id,
      label: state?.abbreviatedName,
    }))

    const cities = map(recordCurrent.cities, (city) => ({
      value: city.id,
      label: city?.name,
    }))

    const transformData: CompanyBaseTestfyShowRecord = {
      states,
      cities,
      employees,
      storeProductIds,
      name: recordCurrent.companyData?.name,
      number: recordCurrent.companyData?.address.number,
      street: recordCurrent.companyData?.address.street,
      cityId: recordCurrent.companyData?.address.cityId,
      stateId: recordCurrent.companyData?.address.stateId,
      complement: recordCurrent.companyData?.address.complement,
      postalCode: recordCurrent.companyData?.address.postalCode,
      neighborhood: recordCurrent.companyData?.address.neighborhood,
      referencePoint: recordCurrent.companyData?.address.referencePoint,
      additionalInformations: recordCurrent.companyData?.additionalInformations,
      documentNumber:
        recordCurrent.companyData?.companyHasDocuments[0].documentNumber,
      status: statusParser(recordCurrent.status),
    }

    methods.reset(transformData)
  }, [methods, recordCurrent, statusParser])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Geral',
        component: TabGeneral,
        names: [
          'name',
          'street',
          'number',
          'cityId',
          'stateId',
          'cardCnpj',
          'complement',
          'postalCode',
          'neighborhood',
          'documentNumber',
          'referencePoint',
          'socialContract',
        ],
      },
      {
        title: 'Operação',
        component: TabOperation,
        names: [
          'cities',
          'states',
          'employees',
          'storeProductIds',
          'additionalInformations',
        ],
      },
      // {
      //   title: 'Performance',
      //   component: TabPerformance,
      // },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<CompanyBaseTestfyShowRecord> =
    React.useCallback(
      async (data) => {
        try {
          const formattedData = {
            ...data,
            companyType: 'TESTFY_BASE',
          }

          // TODO: Add a type for this
          await record.update(formattedData as any)

          toast({
            title: 'Editado com sucesso',
            description: 'Base testfy editado com sucesso',
            status: 'success',
          })
        } catch (error: any) {
          if (error && error instanceof Array) {
            error.forEach((fieldError) => {
              if (fieldError.field === 'documentNumber') {
                fieldError.message = 'Verifique se o CNPJ está correto'
              }
              methods.setError(fieldError.field, {
                message: fieldError.message,
              })
            })
          }
        }
      },
      [methods, record, toast]
    )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box position="absolute" top={-6} right={0}>
          <FieldStatus
            methods={methods}
            error={methods.formState.errors.status}
            disable={record.disable}
            enable={record.enable}
          />
        </Box>

        <Tabs tabsDetails={tabsDetails} errors={methods.formState.errors} />

        <FormFooter
          recordDelete={record.delete}
          isSubmitting={methods.formState.isSubmitting}
        />
      </Flex>
    </FormProvider>
  )
}
