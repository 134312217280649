// Vendors
import React, { useMemo } from 'react'

// Styles
import { IconType } from 'react-icons'
import { FiCheck } from 'react-icons/fi'
import { Circle, HStack, Stack, Text } from '@chakra-ui/react'

// Interfaces
export type OrderTimelineItemProps = {
  title: string
  icon: IconType
  isActive?: boolean
  isError?: boolean
}

export const OrderTimelineItem = (
  props: OrderTimelineItemProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { icon: Icon, title, isActive, isError = false } = props

  const color = useMemo(() => {
    if (isActive) {
      return { bgColor: 'blue.100', color: '#1C4532' }
    }

    if (isError) {
      return { bgColor: 'red.100', color: '#931538' }
    }

    return { bgColor: 'gray.100', color: '#A0AEC0' }
  }, [isActive, isError])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Stack alignItems="center">
      <Circle zIndex={1} size="16" bg={color.bgColor} color={color.color}>
        <Icon size="28" />
      </Circle>

      <HStack>
        {isActive && <FiCheck />}
        <Text>{title}</Text>
      </HStack>
    </Stack>
  )
}
