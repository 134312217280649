// Vendors
import { kebabCase, map } from 'lodash'
import { useHistory } from 'react-router-dom'
import React, { useMemo, useCallback, useEffect, ChangeEvent } from 'react'

// Functions
import { apiShow } from 'services/get'
import { apiPatch } from 'services/patch'
import { useTranslation } from 'react-i18next'
import { useListRecords } from 'hooks/useListRecords'
import { useDialogDelete } from 'hooks/useDialogDelete'
import { useConfirmationDialog } from 'contexts/confirmationDialog'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListActions } from 'components/ListActions'
import { ButtonWithMenu } from 'components/ButtonWithMenu'
import { ListPagination } from 'components/ListPagination'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'
import { Select, Stack } from '@chakra-ui/react'

// Constants
import { ROLES_CREATABLE } from 'constants/api-roles'

// Types
import { Person } from './types'

import { PageMetaSetting } from './meta'

import { PersonPurchaseAndKit } from 'types/PersonPurchaseAndKit'

export const PageUserListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('common')
  const history = useHistory()
  const { endpoint, title } = PageMetaSetting
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<Person>({ endpoint })

  const { dialog } = useDialogDelete()
  const { getConfirmation } = useConfirmationDialog()

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    Records.list.forEach((record) => {
      const rolesFormatted = record.role?.split(',').map((role) => {
        return t(`roles.${kebabCase(role)}`)
      })

      if (!rolesFormatted?.length) {
        record.rolesFormatted = 'Usuário não tem nem um perfil ativo'
      } else {
        record.rolesFormatted = rolesFormatted.join(', ')
      }
    })
  }, [Records.list, t])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDelete = useCallback(
    async (e, record: Person) => {
      e.stopPropagation()

      try {
        const response = await apiShow<PersonPurchaseAndKit>(
          `/app/person/${record.id}/purchase_kits`
        )

        const responseForDialogKits = map(response?.kits, (kit) => {
          return {
            href: `/kits/${kit.activationCode}`,
            label: kit.activationCode,
          }
        })

        const responseForDialogPurchases = map(
          response?.purchases,
          (purchase) => {
            return {
              href: `/orders/${purchase.id}`,
              label: `#${purchase.purchaseNumber}`,
            }
          }
        )

        const { title, body, isDisabled } = dialog(
          {
            title: 'Kits',
            items: responseForDialogKits,
          },
          {
            title: 'Pedidos',
            items: responseForDialogPurchases,
          }
        )

        await getConfirmation({
          title: title,
          message: body,
          confirmText: isDisabled ? 'Desabilitar' : 'Deletar',
          onConfirm: async () => {
            try {
              if (isDisabled) {
                await apiPatch(`/app/person/${record.id}/disable`)
                Records.reload()
              } else {
                Records.delete(record.id)
              }
            } catch (error) {
              console.log(error)
            }
          },
        })
      } catch (error) {
        console.log(error)
      }
    },
    [Records, dialog, getConfirmation]
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const ListTableHeaders = useMemo(
    () => [
      {
        label: t('list_table.headers.name'),
        accessor: 'name',
      },
      {
        label: t('list_table.headers.phone'),
        accessor: 'phoneNumber',
      },
      {
        label: t('list_table.headers.email'),
        accessor: 'email',
      },
      {
        label: t('list_table.headers.profile'),
        accessor: 'rolesFormatted',
        render: {
          as: ListTableRenderAs.MULTILINE_TEXT,
        },
      },
      {
        label: t('list_table.headers.status'),
        accessor: 'status',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
    ],
    [t]
  )

  const buttonMenuActions = useMemo(
    () => [
      {
        label: 'Admin',
        onClick: () => history.push('/users/admin/new'),
      },
      {
        label: 'Backoffice',
        onClick: () => history.push('/users/backoffice/new'),
      },
      {
        label: 'Expedição',
        onClick: () => history.push('/users/expedition/new'),
      },
      {
        label: 'Financeiro',
        onClick: () => history.push('/users/finance/new'),
      },
      {
        label: 'Gestor(a) Logístico',
        onClick: () => history.push('/users/logistic-manager/new'),
      },
      {
        label: 'Motoboy',
        onClick: () => history.push('/users/logistic-agent/new'),
      },
      {
        label: 'Vendedor',
        onClick: () => history.push('/users/seller/new'),
      },
      {
        label: 'Agente laboratório',
        onClick: () => history.push('/users/laboratory-agent/new'),
      },
      {
        label: 'Gestor(a) laboratório',
        onClick: () => history.push('/users/laboratory-manager/new'),
      },
      {
        label: 'Gestor(a) de conteúdo',
        onClick: () => history.push('/users/content-manager/new'),
      },
    ],
    [history]
  )

  const ListTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/users/${Record.id}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutLoggedPageList title={title}>
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ListFilters query={Query}>
          <Select
            size="sm"
            placeholder="Perfil..."
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              Query.set({
                ...Query.current,
                type: e.target.value,
              })
            }}
          >
            {ROLES_CREATABLE.map(({ translated, slug }) => (
              <option value={slug} key={slug}>
                {translated}
              </option>
            ))}
          </Select>
        </ListFilters>

        <ListActions>
          <ButtonWithMenu label="Adicionar" actions={buttonMenuActions} />
        </ListActions>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={ListTableRowAction}
        defaultActions="edit"
        actions={[
          {
            type: 'resetAccountPassword',
            options: {
              reload: Records.reload,
            },
          },
          { type: 'delete', options: { callback: handleDelete } },
        ]}
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
