// Vendors
import React, { useState } from 'react'

// Components
import { FieldSelect } from 'components/FieldSelect'
import { FormFieldEditerFieldType } from 'components/FormFieldEditerFieldType'
import { FormFieldEditerFieldPreview } from 'components/FormFieldEditerFieldPreview'
import {
  FormFieldEditer,
  FormFieldEditerProps,
} from 'components/FormFieldEditer'
import {
  FormFieldEditerOptions,
  OptionsType,
} from 'components/FormFieldEditerOptions'

// Styles

// Interfaces
export type FormFieldEditerSelectProps = {
  index: number
  id: string
}

export const FormFieldEditerSelect = (
  props: FormFieldEditerProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { index, question } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [fieldName, setFieldName] = useState(props.question.label)
  const [options, setOptions] = useState<OptionsType[]>(props.question.options)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormFieldEditer {...props}>
      <FormFieldEditerFieldType
        index={props.index}
        handleQuestion={props.handleQuestion}
        fieldTypeLabel="SELEÇÃO"
        fieldName={{ get: fieldName, set: setFieldName }}
      >
        <FormFieldEditerOptions
          options={options}
          setOptions={setOptions}
          index={props.index}
          handleQuestion={props.handleQuestion}
        />
      </FormFieldEditerFieldType>

      <FormFieldEditerFieldPreview>
        <FieldSelect
          name={`select-${props.index}`}
          options={options}
          label={fieldName !== '' ? fieldName : 'Nome do campo'}
        />
      </FormFieldEditerFieldPreview>
    </FormFieldEditer>
  )
}
