import { Link } from 'react-router-dom'
import React, { useCallback } from 'react'
import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react'

type DependencyType = {
  title: string
  items: Array<{
    href: string
    label: string
  }>
}

type ItemsListProps = {
  totalItems: number
  dependencies: DependencyType[]
  onDismiss?: () => void
}

const ItemsList = ({ totalItems, dependencies, onDismiss }: ItemsListProps) => {
  return (
    <React.Fragment>
      <Box>
        Atualmente, {totalItems}{' '}
        {totalItems === 1 ? 'item ativo utiliza' : 'itens ativos utilizam'} este
        registro:{' '}
      </Box>

      {!!dependencies.length &&
        dependencies.map((dep) => {
          if (dep.items.length === 0) return null

          return (
            <Box key={dep.title} mt="5">
              <Text>{dep.title}</Text>

              <UnorderedList>
                {dep.items.slice(0, 10).map((item) => (
                  <Link key={item.href} to={item.href} onClick={onDismiss}>
                    <ListItem
                      color="blue.600"
                      _hover={{
                        filter: 'brightness(0.85)',
                        textDecoration: 'underline',
                      }}
                    >
                      {item.label}
                    </ListItem>
                  </Link>
                ))}
              </UnorderedList>

              {dep.items.length > 10 && (
                <Text>e outros {dep.items.length - 10} itens</Text>
              )}
            </Box>
          )
        })}
    </React.Fragment>
  )
}

export const useDialogDelete = () => {
  const dialog = useCallback((...dependencies: DependencyType[]) => {
    const totalItems = dependencies.reduce(
      (acc, curr) => acc + curr.items.length,
      0
    )

    if (totalItems > 0) {
      return {
        title: 'Este registro não pode ser excluído',
        body: (
          <ItemsList
            totalItems={totalItems}
            dependencies={dependencies}
            onDismiss={undefined}
          />
        ),
        isDisabled: true,
      }
    } else {
      return {
        title: 'Por favor confirme a exclusão.',
        isDisabled: false,
        body: (
          <Text>
            Tem certeza de que deseja deletar este item?{' '}
            <Text as="strong">Esta ação não pode ser desfeita.</Text>
          </Text>
        ),
      }
    }
  }, [])

  return { dialog }
}
