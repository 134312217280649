import { Box } from '@chakra-ui/react'
import { FieldDate } from 'components/FieldDate'
import { get } from 'lodash'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormFieldEditorProps } from '../MedicalRecord'
import { useFormFieldEditor } from '../useFormFieldEditor'

export const FormFieldEditorDate = (props: FormFieldEditorProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { question } = props
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const { rules, label, name } = useFormFieldEditor({ question })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (!name) return <></>

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <FieldDate
            dropdownMode="select"
            dateFormat="dd/MM/yyyy"
            label={label}
            name={field.name}
            error={get(errors, name)}
            selected={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </Box>
  )
}
