import { ReactNode, RefObject } from 'react'

export type NotificationContextProviderProps = {
  children: ReactNode
}

export type NotificationContextValues = {
  markAsRead: () => Promise<void>
  markAllArchive: () => Promise<void>
  fetchNotifications: () => Promise<void>
  markAsArchive: (notificationId: string) => Promise<void>
  markAsFavorite: (notificationId: string) => Promise<void>
  markAsUnArchive: (notificationId: string) => Promise<void>
  markAsUnFavorite: (notificationId: string) => Promise<void>
  handleToggle: () => void
  nextPage: (status: NotificationStatus) => void
  prevPage: (status: NotificationStatus) => void
  isOpen: boolean
  ref: RefObject<HTMLDivElement>
  amountNotificationsUnread: number
  notifications: NotificationState | undefined
  favoriteNotifications: NotificationState | undefined
}

export enum NotificationStatus {
  READ = 'READ',
  UNREAD = 'UNREAD',
  ARCHIVED = 'ARCHIVED',
}

export type NotificationItem = {
  id: string
  type: string
  title: string
  origin: string
  starred: boolean
  createdAt: string
  updatedAt: string
  description: string
  message: string | null
  createdByUserId: string
  status: NotificationStatus
  attachmentNotificationFile: any[]
  createdByUser: {
    id: string
    person: {
      id: string
      name: string
      socialName: string | null
      email: string
      userId: string
    }
  }
}

export type NotificationMeta = {
  total: number
  perPage: number
  currentPage: number
  hasPrevPage: boolean
  hasNextPage: boolean
}

export type NotificationState = Record<
  keyof typeof NotificationStatus,
  | {
      data: NotificationItem[]
      original: NotificationItem[]
      meta: NotificationMeta
    }
  | undefined
>
