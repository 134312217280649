// Vendors
import React, { useCallback, useEffect } from 'react'
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form'

// Services
import { apiPatch } from 'services/patch'

// Hooks
import { useError } from 'hooks/useError'
import { useSelectParser } from 'hooks/useSelectParser'

// Functions
import { schema } from './schema'

// Components
import { FieldSelect } from 'components/FieldSelect'
import {
  FormAddress,
  FormAddressType,
} from 'components/ModalShowEditOrder/FormAddress'

// Styles
import {
  Modal,
  Stack,
  Button,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useFormTransform } from 'hooks/useFormTransform'

type Client = {
  id: string
  userId: string
  name: string
  email: string
  phoneNumber: string
  birthdate: Date
  status: 'ENABLED' | 'DISABLED'
  createdAt: Date
  updatedAt: Date
  documentNumber?: string
}

type ModalShowEditOrderProps = {
  isOpen: boolean
  reload: () => void
  onCancel: () => void
  purchaseId: string
  delivery: Record<string, any> | null
}

type FormEditOrderKit = FormAddressType & {
  userId: number
  shippingMethod: any
}

export const ModalShowEditOrder = (props: ModalShowEditOrderProps) => {
  const { isOpen, reload, onCancel, purchaseId, delivery } = props

  const selectPatient = useSelectParser<Client>({
    accessor: {
      label: ({ name, documentNumber }) =>
        `${name} ${
          documentNumber &&
          `(${documentNumber.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})$/,
            '$1.$2.$3-$4'
          )})`
        }`,
      value: 'userId',
    },
    endpoint: '/app/client',
  })

  const selectShippingMethod = useSelectParser({
    accessor: { label: 'providerName', value: 'id' },
    endpoint: '/public/purchase/shipping_method',
  })

  const { selectParser } = useFormTransform()

  const { handleError } = useError()
  const { t } = useTranslation('common')
  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  const {
    control,
    reset,
    formState: { errors, isSubmitting },
  } = methods

  const onClose = useCallback(() => {
    methods.reset()
    onCancel()
  }, [methods, onCancel])

  const formatPayload = useCallback(
    (data: FormEditOrderKit) => {
      const {
        cityId,
        complement,
        neighborhood,
        number,
        postalCode,
        referencePoint,
        stateId,
        street,
      } = data
      return {
        userId: data.userId || delivery?.user.id,
        shippingMethodId: data.shippingMethod,
        billingAddress: {
          cityId,
          complement,
          neighborhood,
          number,
          postalCode,
          referencePoint,
          stateId,
          street,
        },
      }
    },
    [delivery]
  )

  const onSubmit = useCallback(
    async (data: FormEditOrderKit) => {
      try {
        const payload = formatPayload(data)
        await apiPatch(`/app/purchase/${purchaseId}`, payload)

        reload()
        onClose()
      } catch (error) {
        handleError(error)
      }
    },
    [purchaseId, reload, onClose, handleError, formatPayload]
  )

  useEffect(() => {
    const shippingMethod = selectParser(
      delivery?.deliveryInfo.shippingMethodId,
      selectShippingMethod.options
    )
    const userId = selectParser(delivery?.user.id, selectPatient.options)

    reset({
      shippingMethod,
      userId,
    })
  }, [
    reset,
    selectParser,
    delivery,
    selectPatient.options,
    selectShippingMethod.options,
  ])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />

      <FormProvider {...methods}>
        <ModalContent as="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalHeader>Edição do pedido</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Stack spacing={4}>
              <Controller
                control={control}
                name="userId"
                render={({ field }) => (
                  <FieldSelect
                    label="Escolha um paciente"
                    error={errors.userId as FieldError}
                    options={selectPatient.options}
                    isLoading={selectPatient.isLoading}
                    placeholder="Selecione um paciente..."
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name="shippingMethod"
                rules={{
                  required: 'Por favor, selecione um método de envio',
                }}
                render={({ field }) => (
                  <FieldSelect
                    label="Escolha um método de envio"
                    error={errors.shippingMethod as FieldError}
                    options={selectShippingMethod.options}
                    placeholder="Selecione um método de envio..."
                    {...field}
                  />
                )}
              />
              <FormAddress deliveryAddress={delivery?.deliveryAddress} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button onClick={onClose}>Cancelar</Button>
              <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
                Salvar
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </Modal>
  )
}
