import React, { useCallback, useMemo } from 'react'

import { ModalResetAccountPassword } from 'components/ModalResetAccountPassword'

import { useDisclosure } from '@chakra-ui/react'

import { ActionProps } from './types'
import { WrapperAction } from './WrapperAction'
import { MdOutlinePassword } from 'react-icons/md'

export const ResetAccountPasswordAction = <T extends unknown>({
  record,
  options,
}: ActionProps<T & { email: string; id: string }>) => {
  const modalResetAccountPasswordDisclosure = useDisclosure()

  const {
    reload,
    callback,
    isWorkspace = true,
    actionType = 'menu',
    ...optionsRest
  } = options

  if (!reload) {
    throw new Error('reload is required')
  }

  const personalDataId = useMemo(() => record?.id, [record?.id])
  const email = useMemo(() => record?.email, [record?.email])

  const cta = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement>,
      data: T & { email: string; id: string }
    ) => {
      modalResetAccountPasswordDisclosure.onOpen()

      if (callback) {
        callback(e, data)
      }
    },
    [modalResetAccountPasswordDisclosure, callback]
  )

  return (
    <>
      <ModalResetAccountPassword
        email={email}
        reload={reload}
        isWorkspace={isWorkspace}
        personalDataId={personalDataId}
        isOpen={modalResetAccountPasswordDisclosure.isOpen}
        onCancel={modalResetAccountPasswordDisclosure.onClose}
      />

      <WrapperAction
        cta={cta}
        record={record}
        options={optionsRest}
        actionType={actionType}
        icon={MdOutlinePassword}
        label={'Resetar senha da conta'}
      />
    </>
  )
}
