// Vendors
import { useHistory, Link } from 'react-router-dom'
import React, { useMemo, useCallback } from 'react'

// Functions
import { useTranslation } from 'react-i18next'
import { useListRecords } from 'hooks/useListRecords'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListActions } from 'components/ListActions'
import { ListPagination } from 'components/ListPagination'

// Styles
import { FiPlus } from 'react-icons/fi'
import { Button, Stack } from '@chakra-ui/react'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Types
import { Influencer } from './types'

import { PageMetaSetting } from './meta'

export const PageInfluencerListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('common')
  const history = useHistory()
  const { endpoint, title } = PageMetaSetting
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<Influencer>({
    endpoint,
  })

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const ListTableHeaders = useMemo(
    () => [
      {
        label: t('list_table.headers.name'),
        accessor: 'name',
      },
      {
        label: t('list_table.headers.phone'),
        accessor: 'phoneNumber',
      },
      {
        label: t('list_table.headers.email'),
        accessor: 'email',
      },
      {
        label: t('list_table.headers.associated_coupons'),
        accessor: 'totalcoupons',
      },
    ],
    [t]
  )

  const ListTableRowAction = useCallback(
    (Record) => {
      if (!Record) return
      history.push(`/influencer/${Record.id}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <LayoutLoggedPageList title={title}>
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ListFilters query={Query}></ListFilters>

        <ListActions>
          <Button as={Link} to={'/influencer/new'} leftIcon={<FiPlus />}>
            Adicionar
          </Button>
        </ListActions>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={ListTableRowAction}
        defaultActions="edit"
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
