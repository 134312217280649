import { Record } from 'types/Requests'
import { Role as RoleType } from './Role'

export enum PersonKeyEnum {
  name = 'name',
  email = 'email',
  gender = 'gender',
  status = 'status',
  companyId = 'companyId',
  birthdate = 'birthdate',
  phoneNumber = 'phoneNumber',
  documentNumber = 'documentNumber',
}

export type PersonCreate = {
  [key in PersonKeyEnum]: string | Date
}

export type Person = Record & {
  userId: string
  dependentOfUserId: string
  name: string
  email: string
  birthdate: Date
  phoneNumber: string
  language: string
  documentNumber: string | null
  gender: string
  user: User
}

export type User = Record & {
  name: string
  slug: string
  status: string
  roles: Role[]
}

export type Role = RoleType & {
  roleUser: RoleUser[]
}

type RoleUser = Record & {
  status: string
  userId: string
  roleId: string
}
