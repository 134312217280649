// Vendors
import { useCallback, useEffect, useState } from 'react'

// Functions
import { apiList } from 'services/get'

// Types
import { Pagination } from 'types/Requests'

type SelectParserType<T> = {
  accessor: { label: keyof T | ((item: T) => string); value: keyof T }
  endpoint?: string
  requestWithoutMeta?: boolean
  callback?: (data: T[]) => T[]
  modalIsOpen?: boolean
}

export type SelectType<T> = {
  value: T[keyof T]
  label: T[keyof T] | string
}

export function useSelectParser<T>(props: SelectParserType<T>) {
  const {
    accessor: { value, label },
    endpoint,
    requestWithoutMeta = false,
    callback,
    modalIsOpen,
  } = props

  const [options, setOptions] = useState<SelectType<T>[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const transform = useCallback(
    (data: T[]) => {
      if (!data) return []
      return data.map((item: T) => {
        if (typeof label === 'function') {
          return {
            label: label(item),
            value: item[value],
          }
        }

        return {
          value: item[value],
          label: item[label],
        }
      })
    },
    [label, value]
  )

  useEffect(() => {
    if (!(typeof modalIsOpen === 'undefined') && !modalIsOpen) return

    if (!endpoint) return

    setIsLoading(true)

    if (requestWithoutMeta) {
      apiList<T[]>(endpoint)
        .then((data) => {
          if (!data) return

          setOptions(transform(data))
        })
        .finally(() => {
          setIsLoading(false)
        })

      return
    }

    apiList<{ meta: Pagination; data: T[] }>(endpoint, { limit: 9999 })
      .then((res) => {
        if (!res) return

        let data = res.data

        if (callback) {
          data = callback(res.data)
        }

        const payload = transform(data)

        setOptions(payload)
      })
      .finally(() => {
        setIsLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, modalIsOpen, requestWithoutMeta])

  return { options, isLoading }
}
