// Vendors
import { map } from 'lodash'
import React, { useMemo, useCallback, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

// Functions
import { apiShow } from 'services/get'
import { apiPatch } from 'services/patch'
import { useTranslation } from 'react-i18next'
import { useListRecords } from 'hooks/useListRecords'
import { useDialogDelete } from 'hooks/useDialogDelete'
import { useConfirmationDialog } from 'contexts/confirmationDialog'

// Components
import { ListTable } from 'components/ListTable'
import { ListFilters } from 'components/ListFilters'
import { ListActions } from 'components/ListActions'
import { ListPagination } from 'components/ListPagination'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles
import { LayoutLoggedPageList } from 'layout/PrivatePageList'
import { Button, Stack } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'

// Types
import { APICompanyList, Clinic } from './types'

import { PageMetaSetting } from './meta'
import { ClinicPurchase } from 'types/ClinicPurchase'

export const PageClinicListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { t } = useTranslation('clinic')
  const history = useHistory()
  const { endpoint, title } = PageMetaSetting
  const { records, query, pagination } = useListRecords<APICompanyList>({
    endpoint,
  })

  const { getConfirmation } = useConfirmationDialog()
  const { dialog } = useDialogDelete()

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    records.list.forEach((record) => {
      record.documentNumber = record.documentNumber.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      )
    })
  }, [records.list])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDelete = useCallback(
    async (e, record: APICompanyList) => {
      e.stopPropagation()

      try {
        const response = await apiShow<ClinicPurchase[]>(
          `/app/company/${record.id}/purchase`
        )

        const responseForDialog = map(response, (purchase) => {
          return {
            href: `/orders/${purchase.id}`,
            label: `#${purchase.purchaseNumber}`,
          }
        })

        const { title, body, isDisabled } = dialog({
          title: 'Pedidos',
          items: responseForDialog,
        })

        await getConfirmation({
          title: title,
          message: body,
          confirmText: isDisabled ? 'Desabilitar' : 'Deletar',
          onConfirm: async () => {
            try {
              if (isDisabled) {
                await apiPatch(`/app/person/${record.id}/disable`)
                records.reload()
              } else {
                records.delete(record.id)
              }
            } catch (error) {
              console.log(error)
            }
          },
        })
      } catch (error) {
        console.log(error)
      }
    },
    [records, dialog, getConfirmation]
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const listTableHeaders = useMemo(
    () => [
      {
        label: t('table.headers.name'),
        accessor: 'internalIdentification',
      },
      {
        label: 'CNPJ',
        accessor: 'documentNumber',
      },
      {
        label: t('table.headers.type'),
        accessor: 'companyType',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
      {
        label: t('table.headers.status'),
        accessor: 'status',
        render: {
          as: ListTableRenderAs.BADGE,
        },
      },
    ],
    [t]
  )

  const listTableRowAction = useCallback(
    (record: Clinic) => {
      if (!record) return
      history.push(`/clinics/${record.id}`)
    },
    [history]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={title}>
      <Stack
        spacing="4"
        justify="space-between"
        direction={{ base: 'column', md: 'row' }}
      >
        <ListFilters query={query}></ListFilters>

        <ListActions>
          <Button as={Link} to={'/clinics/new'} leftIcon={<FiPlus />}>
            Adicionar
          </Button>
        </ListActions>
      </Stack>

      <ListTable
        records={records}
        headers={listTableHeaders}
        rowAction={listTableRowAction}
        defaultActions="edit"
        actions={[
          {
            type: 'order',
            options: {
              callback: (_, record) => {
                history.push(`/checkout?companyClinicId=${record?.id}`)
              },
            },
          },
          { type: 'delete', options: { callback: handleDelete } },
        ]}
      />

      <ListPagination pagination={pagination} query={query} />
    </LayoutLoggedPageList>
  )
}
