// Vendors
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

// Functions
import { useError } from 'hooks/useError'
import { useEditRecords } from 'hooks/useEditRecord'
import { useFormTransform } from 'hooks/useFormTransform'

// Components
import { TabDetail, Tabs } from 'components/Tabs'
import { FormFooter } from 'components/FormFooter'
import { FieldStatus } from 'components/FieldStatus'
import { TabGeneralShow } from '../tabs/TabGeneralShow'

// Styles
import { Box, Flex, useToast } from '@chakra-ui/react'

// Schemas
import { schema } from './schema'

// Types
import { CompanyLaboratoryShow, CompanyLaboratoryShowRecord } from './types'

type CompanyLaboratoryFormShowProps = {
  id: string
  endpoint: string
}

export const CompanyLaboratoryFormShow = (
  props: CompanyLaboratoryFormShowProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint, id } = props

  const { handleError } = useError()

  const { record } = useEditRecords<
    CompanyLaboratoryShow,
    CompanyLaboratoryShow
  >({
    endpoint,
    currentId: id,
  })

  const recordCurrent = record.current

  const { statusParser } = useFormTransform()

  const { t } = useTranslation('common')

  const toast = useToast({ position: 'top-right' })

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    const states = map(recordCurrent.states, (state) => ({
      value: state.id,
      label: state.abbreviatedName,
    }))

    const laboratoryAnalyses = map(
      recordCurrent.laboratoryAnalyses,
      (laboratoryAnalyses) => ({
        value: laboratoryAnalyses.id,
        label: laboratoryAnalyses.name,
      })
    )

    const laboratoryAgents = map(recordCurrent.laboratoryAgents, (agent) => ({
      value: agent.id,
      label: agent?.person?.name,
    }))

    const laboratoryManagers = map(
      recordCurrent.laboratoryManagers,
      (agent) => ({
        value: agent.id,
        label: agent?.person?.name,
      })
    )

    const transformData: CompanyLaboratoryShowRecord = {
      states,
      employees: [...laboratoryAgents, ...laboratoryManagers],
      laboratoryAnalyses,
      name: recordCurrent.companyData?.name,
      number: recordCurrent.companyData?.address.number,
      street: recordCurrent.companyData?.address.street,
      cityId: recordCurrent.companyData?.address.cityId,
      stateId: recordCurrent.companyData?.address.stateId,
      complement: recordCurrent.companyData?.address.complement,
      postalCode: recordCurrent.companyData?.address.postalCode,
      neighborhood: recordCurrent.companyData?.address.neighborhood,
      referencePoint: recordCurrent.companyData?.address.referencePoint,
      additionalInformations:
        recordCurrent.companyData?.additionalInformations ?? '',
      documentNumber:
        recordCurrent.companyData?.companyHasDocuments[0].documentNumber,
      status: statusParser(recordCurrent.status),
    }

    methods.reset(transformData)
  }, [methods, recordCurrent, statusParser])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Geral',
        component: TabGeneralShow,
      },
      // {
      //   title: 'Performance',
      //   component: TabPerformance,
      // },
      // {
      //   title: 'Laudos',
      //   component: TabMedicalCertificate,
      // },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  // TODO: Add type in SubmitHandler
  const onSubmit: SubmitHandler<any> = React.useCallback(
    async (data) => {
      try {
        const formattedData = {
          ...data,
          companyType: 'LABORATORY',
        }

        await record.update(formattedData)

        toast({
          title: 'Editado com sucesso',
          description: 'Laboratório editada com sucesso',
          status: 'success',
        })
      } catch (error) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            if (fieldError.field === 'documentNumber') {
              fieldError.message = 'Verifique se o CNPJ está correto'
            }
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        } else {
          handleError(error)
        }
      }
    },
    [handleError, methods, record, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box position="absolute" top={-6} right={0}>
          <FieldStatus
            methods={methods}
            error={methods.formState.errors.status}
            disable={record.disable}
            enable={record.enable}
          />
        </Box>

        <Tabs tabsDetails={tabsDetails} />

        <FormFooter
          recordDelete={record.delete}
          isSubmitting={methods.formState.isSubmitting}
        />
      </Flex>
    </FormProvider>
  )
}
