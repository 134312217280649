// Vendors
import React from 'react'
import { get } from 'lodash'
import { FieldError, useFormContext } from 'react-hook-form'

// Components
import { FieldSet } from '../../../../../components/FieldSet'
import { FieldFile } from 'components/FieldFile'
import { FieldMask } from 'components/FieldMask'
import { FieldText } from 'components/FieldText'

// Styles
import { FiUpload } from 'react-icons/fi'
import { Grid, GridItem } from '@chakra-ui/react'

// Types

export type FormCompanyInfoType = {
  name: string
  documentNumber: string
  cardCnpj: File[]
  socialContract: File[]
}

type FormCompanyInfoProps = {
  hasInputFiles?: boolean
  cardCnpjFileName?: string
  socialContractFileName?: string
}

export const FormCompanyInfo = (props: FormCompanyInfoProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    hasInputFiles = false,
    cardCnpjFileName: defaultValuecardCnpjFileName,
    socialContractFileName: defaultValuesocialContractFileName,
  } = props

  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext<FormCompanyInfoType>()

  const watchCardCnpj = watch('cardCnpj')
  const watchSocialContract = watch('socialContract')

  const cardCnpjFileName =
    get(watchCardCnpj, '[0].name') || defaultValuecardCnpjFileName
  const socialContractFileName =
    get(watchSocialContract, '[0].name') || defaultValuesocialContractFileName
  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FieldSet title="Dados da empresa">
      <Grid
        my="4"
        gap="10"
        templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
      >
        <GridItem>
          <FieldText
            maxLength={120}
            currentLength={get(watch('name'), 'length')}
            label="Razão social"
            error={errors.name}
            isRequired
            {...register('name')}
          />
        </GridItem>

        <GridItem>
          <FieldMask
            label="CNPJ"
            mask="99.999.999/9999-99"
            error={errors.documentNumber}
            isRequired
            {...register('documentNumber')}
          />
        </GridItem>

        {hasInputFiles && (
          <>
            <GridItem>
              <FieldFile
                icon={FiUpload}
                label="Imagem do cartão CNPJ"
                fileName={cardCnpjFileName}
                allowedExtensions={['jpg', 'png', 'gif', 'pdf']}
                error={errors.cardCnpj as FieldError}
                {...register('cardCnpj', {
                  required: `Imagem do cartão CNPJ é obrigatorio`,
                })}
                isRequired
              />
            </GridItem>

            <GridItem>
              <FieldFile
                icon={FiUpload}
                label="Imagem do contrato social"
                allowedExtensions={['jpg', 'png', 'gif', 'pdf']}
                fileName={socialContractFileName}
                error={errors.socialContract as FieldError}
                {...register('socialContract', {
                  required: `Imagem do contrato social é obrigatorio`,
                })}
                isRequired
              />
            </GridItem>
          </>
        )}
      </Grid>
    </FieldSet>
  )
}
