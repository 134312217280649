import React from 'react'

import { AuthContextProvider } from './auth'
import { ConfirmationDialogProvider } from './confirmationDialog'
import { FeedbackContextProvider } from './feedback'
import { GlobalConfigProvider } from './globalConfig'
import { NotificationContextProvider } from './notification'
import { SearchContextProvider } from './search'

export const AppProvider: React.FC = ({ children }) => (
  <AuthContextProvider>
    <SearchContextProvider>
      <NotificationContextProvider>
        <ConfirmationDialogProvider>
          <FeedbackContextProvider>
            <GlobalConfigProvider>{children}</GlobalConfigProvider>
          </FeedbackContextProvider>
        </ConfirmationDialogProvider>
      </NotificationContextProvider>
    </SearchContextProvider>
  </AuthContextProvider>
)
