// Vendors
import { sub } from 'date-fns'
import { get, map } from 'lodash'
import { toDate } from 'date-fns-tz'
import { useTranslation } from 'react-i18next'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

// Services
import { apiShow } from 'services/get'
import { apiPost } from 'services/post'
import { useError } from 'hooks/useError'
import { useEditRecords } from 'hooks/useEditRecord'
import { useDialogDelete } from 'hooks/useDialogDelete'
import { useConfirmationDialog } from 'contexts/confirmationDialog'

// Components
import { FieldText } from 'components/FieldText'
import { FieldMask } from 'components/FieldMask'
import { FieldDate } from 'components/FieldDate'
import { FormFooter } from 'components/FormFooter'
import { ButtonNewOrder } from 'components/ButtonNewOrder'
import { FieldRadioButton } from 'components/FieldRadioButton'

// Schemas
import { schema } from './schema'

// Styles
import {
  Box,
  Text,
  Flex,
  Grid,
  Badge,
  Button,
  GridItem,
  useToast,
} from '@chakra-ui/react'
import { ClientShowAPI, ClientShowForm } from './types'
import { PersonPurchaseAndKit } from 'types/PersonPurchaseAndKit'
import { ResponseForDialog } from 'types/ResponseForDialog'

// Types
type ClientFormShowProps = {
  id: string
  endpoint: string
}

enum Status {
  ENABLED = 'Ativo',
  DISABLED = 'Inativo',
  PENDING_ACTIVATION = 'Aguardando ativação',
  PENDING_RESET_PASSWORD = 'Aguardando redefinição de senha',
}

export const ClientFormShow = (props: ClientFormShowProps): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id, endpoint } = props

  const { t } = useTranslation('common')

  const { getConfirmation } = useConfirmationDialog()

  const {
    reset,
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
  } = useForm<ClientShowForm>({ resolver: yupResolver(schema(t)) })

  const toast = useToast({ position: 'top-right' })
  const { handleError } = useError()

  const { record } = useEditRecords<ClientShowAPI, ClientShowForm>({
    endpoint,
    currentId: id,
  })
  const recordCurrent = get(record, 'current')

  const { dialog } = useDialogDelete()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [responseForDialog, setResponseForDialog] = useState<
    ResponseForDialog | undefined
  >(undefined)

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sanitize = useCallback((data: ClientShowAPI) => {
    if (Object.keys(data).length === 0) return {}

    const birthDate = get(data, 'birthdate')

    return {
      name: get(data, 'name'),
      email: get(data, 'email'),
      gender: get(data, 'gender'),
      birthdate: toDate(birthDate),
      socialName: get(data, 'socialName'),
      phoneNumber: get(data, 'phoneNumber')?.slice(3),
      documentNumber: get(data, 'documentNumber'),
    }
  }, [])

  useEffect(() => {
    reset(sanitize(recordCurrent))
  }, [recordCurrent, reset, sanitize])

  useEffect(() => {
    const fetchData = async () => {
      const response = await apiShow<PersonPurchaseAndKit>(
        `/app/person/${id}/purchase_kits`
      )

      const responseForDialogKits = map(response?.kits, (kit) => {
        return {
          href: `/kits/${kit.activationCode}`,
          label: kit.activationCode,
        }
      })

      const responseForDialogPurchases = map(
        response?.purchases,
        (purchase) => {
          return {
            href: `/orders/${purchase.id}`,
            label: `#${purchase.purchaseNumber}`,
          }
        }
      )

      setResponseForDialog({
        kits: responseForDialogKits,
        purchases: responseForDialogPurchases,
      })
    }

    fetchData()
  }, [id])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const deleteDialog = useMemo(() => {
    if (!responseForDialog) return

    return dialog(
      {
        title: 'Kits',
        items: responseForDialog.kits || [],
      },
      {
        title: 'Pedidos',
        items: responseForDialog.purchases || [],
      }
    )
  }, [dialog, responseForDialog])

  const genresOptions = useMemo(
    () => [
      {
        label: 'Masculino',
        value: 'M',
      },
      {
        label: 'Feminino',
        value: 'F',
      },
      {
        label: 'Não informar',
        value: 'NOT_INFORMED',
      },
    ],
    []
  )

  const isPendingActivation = useMemo(() => {
    return recordCurrent?.user?.status === 'PENDING_ACTIVATION'
  }, [recordCurrent])

  const isPendingResetPassword = useMemo(() => {
    return recordCurrent?.user?.status === 'PENDING_RESET_PASSWORD'
  }, [recordCurrent])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDisabled = useCallback(async () => {
    try {
      await record.disable()
      record.reload()
    } catch (error) {
      handleError(error)
    }
  }, [handleError, record])

  const handleResentEmail = useCallback(async () => {
    try {
      await apiPost(`/app/person/${id}/activation_mail`)

      toast({
        title: 'Sucesso',
        description: 'Email reenviado com sucesso',
        status: 'success',
      })
    } catch (error: any) {
      handleError(error)
    }
  }, [handleError, id, toast])

  const onSubmit: SubmitHandler<ClientShowForm> = useCallback(
    async (data) => {
      try {
        await record.update(data)

        if (dirtyFields?.email) {
          const confirmation = await getConfirmation({
            title: 'Atenção',
            message: (
              <>
                <Text>Por favor, confirme a mudança antes de prosseguir</Text>

                <Text mt="2">
                  Você modificou o e-mail do usuário de{' '}
                  <Text as="strong">{get(recordCurrent, 'email')}</Text> para:
                </Text>

                <Box my="6" bg="#f6f6f6" p="6">
                  <Text textAlign="center">{data.email}</Text>
                </Box>

                <Text
                  fontSize="sm"
                  color="orange.500"
                  mt="4"
                  fontStyle="italic"
                >
                  Ao confirmar a mudança, o email de login do usuário será
                  modificado e o anterior deixará de funcionar.
                </Text>
              </>
            ),
          })

          if (!confirmation) return
        }

        toast({
          title: 'Sucesso',
          description: 'Cliente editado com sucesso',
          status: 'success',
        })

        record.reload()
      } catch (error: any) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [
      dirtyFields?.email,
      getConfirmation,
      record,
      recordCurrent,
      setError,
      toast,
    ]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Flex
      as="form"
      h="full"
      position="relative"
      flexDirection="column"
      justifyContent="space-between"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box position="absolute" top="-9.25rem" right="0" zIndex="20">
        <ButtonNewOrder personId={id} />
      </Box>

      {(isPendingActivation || isPendingResetPassword) && (
        <Badge
          position="absolute"
          top="-10"
          right="0"
          bg="#FEFBCB"
          color="#786400"
          borderRadius={2}
        >
          {Status[recordCurrent?.user?.status as keyof typeof Status]}
        </Badge>
      )}

      <Box>
        <Grid
          my="4"
          gap="10"
          templateColumns={{ lg: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
        >
          <GridItem colSpan={2}>
            <FieldText
              label="Nome"
              error={errors.name}
              isRequired
              {...register('name')}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldText
              label="Nome social"
              error={errors.socialName}
              {...register('socialName')}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldText
              label="E-mail"
              error={errors.email}
              isRequired
              {...register('email')}
            />
          </GridItem>

          {isPendingActivation && (
            <GridItem alignSelf="center" colSpan={1} w="full">
              <Button colorScheme="blue" w="full" onClick={handleResentEmail}>
                Reenviar email de ativação
              </Button>
            </GridItem>
          )}

          <GridItem colSpan={2}>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <FieldMask
                  label="Telefone"
                  mask="(99) 9 9999-9999"
                  isRequired
                  error={errors.phoneNumber}
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <Controller
              name="documentNumber"
              control={control}
              render={({ field }) => (
                <FieldMask
                  label="CPF"
                  mask="999.999.999-99"
                  isRequired
                  error={errors.documentNumber}
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <Controller
              name="birthdate"
              control={control}
              render={({ field }) => (
                <>
                  <FieldDate
                    label="Data de nascimento"
                    name={field.name}
                    selected={field.value}
                    maxDate={sub(new Date(), { days: 1 })}
                    onChange={field.onChange}
                    error={errors.birthdate}
                    isRequired
                  />
                </>
              )}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FieldRadioButton
              label="Gênero"
              name="gender"
              control={control}
              radioButtonDirection="row"
              options={genresOptions}
            />
          </GridItem>
        </Grid>
      </Box>

      <FormFooter
        isSubmitting={isSubmitting}
        bodyDialog={deleteDialog?.body}
        titleDialog={deleteDialog?.title}
        confirmLabel={deleteDialog?.isDisabled ? 'Desativar' : 'Deletar'}
        recordDelete={deleteDialog?.isDisabled ? handleDisabled : record.delete}
      />
    </Flex>
  )
}
