// Vendors
import React, { ChangeEvent, FocusEvent, useCallback, useState } from 'react'

// Components
import {
  FormFieldEditer,
  FormFieldEditerProps,
} from 'components/FormFieldEditer'
import { FieldText } from 'components/FieldText'
import { FieldTextArea } from 'components/FieldTextArea'

// Interfaces
export const FormFieldEditerTitle = (
  props: FormFieldEditerProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { question } = props

  const [isDirty, setIsDirty] = useState(false)
  const [formSection, setFormSection] = useState({
    title: question.label,
    description: question.value,
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target
      setFormSection((oldState) => ({ ...oldState, [name]: value }))
      setIsDirty(true)
    },
    []
  )

  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!isDirty) return

      const { name } = e.target

      if (name === 'title') {
        props.handleQuestion.update(props.index, 'label', formSection.title)
      }

      if (name === 'description') {
        props.handleQuestion.update(
          props.index,
          'value',
          formSection.description
        )
      }

      setIsDirty(false)
    },
    [formSection, props.handleQuestion, props.index, isDirty]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormFieldEditer {...props} hideFooter={true}>
      <FieldText
        name="title"
        label="Título"
        onBlur={onBlur}
        onChange={onChange}
        value={formSection.title}
      />
      <FieldTextArea
        name="description"
        label="Descrição"
        onBlur={onBlur}
        onChange={onChange}
        value={formSection.description}
      />
    </FormFieldEditer>
  )
}
