// Vendors
import { format } from 'date-fns'
import React, { useMemo, useState } from 'react'

// Functions
import formatCurrency from 'utils/formatCurrency'

// Components
import { ListTable } from 'components/ListTable'
import { CardDetails } from 'components/CardDetails'
import { ListTableRenderAs } from 'components/ListTableRowComponent'

// Styles
import { Box, Text, useDisclosure } from '@chakra-ui/react'
import { BsBag, BsCheckCircle } from 'react-icons/bs'
import { AiOutlineCreditCard } from 'react-icons/ai'
import { FiTruck } from 'react-icons/fi'
import { OrderTimelineList } from 'components/OrderTimelineList'
import { DrawerFormActivateCode } from 'components/DrawerFormActivateCode'

// Types
enum OrderStatus {
  IN_EXPEDITION,
  SENT,
  IN_TRANSIT,
  DELIVERED,
}

type OrderType = {
  price: string
  createdAt: string
  status: OrderStatus
  totalQuantity: string
  purchaseNumber: string
}

type TabOrderProps = {
  recordReload: () => void
  orderDetails: {
    order: OrderType
    purchaseHasProducts: any
  }
}

export const TabOrder = (props: TabOrderProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { orderDetails, recordReload } = props
  const { isOpen, onClose, onOpen } = useDisclosure()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [kitId, setKitId] = useState('')

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const listTableHeaders = useMemo(
    () => [
      {
        label: 'Id Kit',
        accessor: 'activationCode',
      },
      {
        label: 'Serviço',
        accessor: 'title',
      },
      {
        label: 'Paciente',
        accessor: 'patient',
      },
      {
        label: 'Status',
        accessor: 'status',
        render: { as: ListTableRenderAs.BADGE },
      },
    ],
    []
  )

  const orderTimeline = useMemo(
    () => [
      {
        icon: BsBag,
        title: 'Pedido realizado',
        isActive: Number(OrderStatus[orderDetails?.order?.status]) >= 0,
      },
      {
        icon: AiOutlineCreditCard,
        title: 'Pagamento confirmado',
        isActive: Number(OrderStatus[orderDetails?.order?.status]) >= 1,
      },
      {
        icon: FiTruck,
        title: 'Em transporte',
        isActive: Number(OrderStatus[orderDetails?.order?.status]) >= 2,
      },
      {
        icon: BsCheckCircle,
        title: 'Pedido entregue',
        isActive: Number(OrderStatus[orderDetails?.order?.status]) >= 3,
      },
    ],
    [orderDetails]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (!orderDetails) {
    return (
      <Box>
        <Text>No order details</Text>
      </Box>
    )
  }

  return (
    <Box>
      <OrderTimelineList my="8" data={orderTimeline} />

      <DrawerFormActivateCode
        isOpen={isOpen}
        onClose={onClose}
        kitId={kitId}
        recordReload={recordReload}
      />

      <CardDetails
        title="Detalhes do pedido"
        data={[
          {
            label: 'Cód pedido',
            value: orderDetails.order.purchaseNumber,
          },
          {
            label: 'Data compra',
            value: format(new Date(orderDetails.order.createdAt), 'dd/MM/yyyy'),
          },
          {
            label: 'Total do pedido',
            value: formatCurrency(+orderDetails.order.price),
          },
          {
            label: 'Quantidade',
            value: orderDetails.order.totalQuantity,
          },
        ]}
      />

      <ListTable
        headers={listTableHeaders}
        records={orderDetails.purchaseHasProducts}
        defaultActions="none"
        actions={[
          {
            type: 'activateCode',
            options: {
              notDisableWhen: { status: 'PENDING_ACTIVATION' },
              callback: (e: any, record: any) => {
                setKitId(record.id)
                onOpen()
              },
            },
          },
        ]}
      />
    </Box>
  )
}
