import React from 'react'
import { intersection } from 'lodash'

import { useAuth } from 'contexts/auth'
import { useSearch } from 'contexts/search'

import { GlobalSearchItem } from './GlobalSearchItem'

import { Accordion } from '@chakra-ui/react'

export const GlobalSearchList = () => {
  const { userRolesNames } = useAuth()
  const { handleExpand, searchModules, expandedIndex } = useSearch()

  return (
    <Accordion
      mt="8"
      allowMultiple
      index={expandedIndex}
      onChange={handleExpand}
      defaultIndex={expandedIndex}
    >
      {searchModules.map(
        (searchModule) =>
          intersection(userRolesNames, searchModule.allowedRoles).length >
            0 && (
            <GlobalSearchItem
              key={searchModule.name}
              searchModule={searchModule}
            />
          )
      )}
    </Accordion>
  )
}
