// Vendors
import { get, map } from 'lodash'
import { format, parseISO } from 'date-fns'
import { useHistory } from 'react-router-dom'
import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react'

// Functions
import { useListRecords } from 'hooks/useListRecords'
import { useSelectParser } from 'hooks/useSelectParser'
import { useTranslation } from 'react-i18next'
import { useError } from 'hooks/useError'
import { useDownload } from 'hooks/useDownload'

// Components
import { ListFilters } from 'components/ListFilters'
import { ListPagination } from 'components/ListPagination'
import { ListTable } from 'components/ListTable'
import { ListTableRenderAs } from 'components/ListTableRowComponent'
import { FieldDateRangePicker } from 'components/FieldDateRangePicker'
import { ListActions } from 'components/ListActions'

// Styles
import { HStack, Select, Stack, Button } from '@chakra-ui/react'
import { LayoutLoggedPageList } from 'layout/PrivatePageList'

// Types
import { PageMetaSetting } from './meta'
import { Laboratory } from './types'
import { useAuth } from 'contexts/auth'
import { UserRolesNames } from 'types/User'

export const PageKitsListRecords = (): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectSampleTransportKit = useSelectParser({
    accessor: { label: 'name', value: 'id' },
    endpoint: '/public/store_product?status=ENABLED',
  })

  const selectLaboratorialAnalysis = useSelectParser({
    accessor: { label: 'name', value: 'id' },
    endpoint: '/app/laboratory_analysis',
  })

  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('laboratory')
  const { handleError } = useError()
  const { onDownload } = useDownload()
  const history = useHistory()
  const { userRolesNames } = useAuth()
  const { endpoint, title } = PageMetaSetting
  const {
    records: Records,
    query: Query,
    pagination: Pagination,
  } = useListRecords<Laboratory>({
    endpoint,
    query: {
      sort: '-updatedAt',
    },
  })

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!Records) return

    Records.list.forEach((record) => {
      // We are simply getting the first purchaseHasProductHasAnalysis here
      // because all files we'll have the same estimated issue date for now.
      // In future we may have different dates for each analysis report file.

      const checkInDate = get(record, 'receiptDate')
      record.formattedDateCheckIn = checkInDate
        ? format(parseISO(checkInDate), 'dd/MM/yyyy')
        : '-'

      const reportFileEstimatedIssueDate = get(
        record,
        'purchaseHasProductHasAnalysis[0].reportFileEstimatedIssueDate'
      )
      record.formattedDateLimit = reportFileEstimatedIssueDate
        ? format(parseISO(reportFileEstimatedIssueDate), 'dd/MM/yyyy')
        : 'Sem previsão de entrega'

      const activatedAt = get(record, 'activatedAt')
      record.formattedActivatedAt = activatedAt
        ? format(parseISO(activatedAt), 'dd/MM/yyyy')
        : '-'

      record.formattedInternalLaboratoryId = record.internalLaboratoryId ?? '-'

      record.formattedIsCobranding = record.isCobranding ? 'COBRANDING' : '-'

      const purchaseHasProductHasAnalysis = get(
        record,
        'purchaseHasProductHasAnalysis'
      )
      record.formattedlaboratoryAnalysisNames = map(
        purchaseHasProductHasAnalysis,
        (analysis) => get(analysis, 'laboratoryAnalysis.name')
      )?.join(', ')

      record.status = get(record, 'logisticStatus') || get(record, 'status')
    })
  }, [Records])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const ListTableHeaders = useMemo(
    () => [
      {
        label: t('table.idKit'),
        accessor: 'activationCode',
      },
      {
        label: t('table.checkIn'),
        accessor: 'formattedDateCheckIn',
      },
      {
        label: t('table.deliveryForecast'),
        accessor: 'formattedDateLimit',
      },
      {
        label: t('table.activatedAt'),
        accessor: 'formattedActivatedAt',
      },
      {
        label: t('table.kitName'),
        accessor: 'storeProduct.mktTitle',
      },
      {
        label: t('table.buyerName'),
        accessor: 'patientName',
      },
      {
        label: t('table.kitStatus'),
        render: { as: ListTableRenderAs.BADGE },
        accessor: 'status',
      },
      {
        label: t('table.isCobranding'),
        accessor: 'formattedIsCobranding',
      },
    ],
    [t]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleExport = useCallback(async () => {
    try {
      Query.set({
        ...Query.current,
        limit: 99999,
      })

      await onDownload(
        `/app/laboratory/kit_list/export`,
        `report.json`,
        Query.current,
        { handleError: true }
      )
    } catch (error: any) {
      handleError(error)
    }
  }, [handleError, onDownload, Query])

  const ListTableRowAction = useCallback(
    (record) => {
      if (!record) return

      history.push(`/kits/${record.activationCode}`)
    },
    [history]
  )

  const fnTableActionProps = useCallback((record: any) => {
    return {
      bg: record.isCobranding
        ? 'linear-gradient(90deg, rgba(253, 201, 11, 0.144) 0.01%, rgba(254, 223, 110, 0.207217) 0.06%, rgba(255, 255, 255, 0.3) 5%);'
        : '',
    }
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <LayoutLoggedPageList title={title}>
      <Stack>
        <Stack
          spacing="4"
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
        >
          <ListFilters query={Query}>
            <FieldDateRangePicker
              isSmall
              name="filterDate"
              placeholderText="Filtrar por data"
              containerProps={{
                marginBottom: '0',
              }}
              showMonthDropdown
              dropdownMode="select"
              query={Query}
            />
          </ListFilters>

          <ListActions>
            <Button colorScheme="gray" onClick={handleExport}>
              EXPORTAR
            </Button>
          </ListActions>
        </Stack>

        <HStack>
          <Select
            size="sm"
            placeholder="Todos os status"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              const { value } = e.target
              if (value === 'PENDING_ACTIVATION') {
                Query.set({
                  ...Query.current,
                  statusKit: value,
                  status: undefined,
                })
                return
              }

              Query.set({
                ...Query.current,
                status: e.target.value,
                statusKit: undefined,
              })
            }}
          >
            <option value="KIT_ACTIVATED">Kit ativado</option>
            <option value="PENDING_REVERSE_CODE">
              Logístca reversa pendente
            </option>
            <option value="IN_ANALYSIS">Em análise</option>
            <option value="REPORT_GENERATED">Laudo gerado</option>
            <option value="KIT_ACCEPTED">Kit aceito</option>
            <option value="SAMPLE_DENIED">Amostra negada</option>
            <option value="DIVERGENT_DATA">Dados divergentes</option>
            <option value="CANCELED">Cancelados</option>
            <option value="REQUESTED_RECOLLECT">Recoleta solicitada</option>
            <option value="ATTENDED_RECOLLECT">Recoleta atendida</option>
            <option value="PENDING_ACTIVATION">Aguardando ativação</option>
          </Select>

          <Select
            size="sm"
            placeholder="Todos os produtos"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              Query.set({
                ...Query.current,
                storeProductId: e.target.value,
              })
            }}
          >
            {selectSampleTransportKit.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <Select
            size="sm"
            placeholder="Todos as análises"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              Query.set({
                ...Query.current,
                laboratoryAnalysisId: e.target.value,
              })
            }}
          >
            {selectLaboratorialAnalysis.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>

          <Select
            size="sm"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              Query.set({
                ...Query.current,
                sort: e.target.value,
              })
            }}
          >
            <option value="-updatedAt">Mais recentes</option>
            <option value="-dateLimit">Data de entrega mais próxima</option>
          </Select>
        </HStack>
      </Stack>

      <ListTable
        headers={ListTableHeaders}
        records={Records}
        rowAction={ListTableRowAction}
        defaultActions="none"
        trProps={fnTableActionProps}
        emptyMessage={`Para kits comercializados antes de 18/04 que não estiverem na lista ${'\n'} por favor procurar o Stuart e informar o ID do kit em questão`}
        actions={[
          {
            type: 'resetActivationCode',
            options: {
              reload: Records.reload,
              showWhen: (record) => {
                return record.status === 'KIT_ACTIVATED'
              },
              notDisableWhen: () => {
                const disabled = !(userRolesNames as UserRolesNames[]).includes(
                  UserRolesNames.SUPERADMIN
                )
                return disabled
              },
            },
          },
        ]}
      />

      <ListPagination pagination={Pagination} query={Query} />
    </LayoutLoggedPageList>
  )
}
