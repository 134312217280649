// Vendors
import { get } from 'lodash'
import React, { useMemo } from 'react'
import { Controller, FieldError, useFormContext } from 'react-hook-form'

// Functions
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FieldSelect } from 'components/FieldSelect'
import { FieldText } from 'components/FieldText'
import { FieldSet } from 'components/FieldSet'
import { FormCompanyInfo } from 'pages/private/Company/form/FormCompanyInfo'
import { FormCompanyAddress } from 'pages/private/Company/form/FormCompanyAddress'

// Styles
import { Box, Grid, GridItem } from '@chakra-ui/react'

// Types
import { FormOtherInfoType } from '../new/types'

export const TabGeneralNew = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext<FormOtherInfoType>()

  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectClinicType = useMemo(
    () => ({
      options: [
        {
          label: 'Repasse',
          value: 'Repasse',
        },
        {
          label: 'Cobranding',
          value: 'Cobranding',
        },
        {
          label: 'B2B',
          value: 'B2B',
        },
      ],
    }),
    []
  )

  const selectLaboratoryManager = useSelectParser({
    accessor: { label: 'name', value: 'userId' },
    endpoint: '/app/professional',
  })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <FormCompanyInfo hasInputFiles />
      <FormCompanyAddress />

      <FieldSet title="Outras informações">
        <Grid my="4" gap="10" templateColumns={{ lg: 'repeat(2, 1fr)' }}>
          <GridItem>
            <Controller
              name="employees"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  isMulti
                  label="Vínculo com profissional (Médico)"
                  error={errors.employees as FieldError}
                  isLoading={selectLaboratoryManager.isLoading}
                  options={selectLaboratoryManager.options}
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Controller
              name="clinicType"
              control={control}
              render={({ field }) => (
                <FieldSelect
                  label="Tipo de clínica"
                  error={errors.clinicType as FieldError}
                  options={selectClinicType.options}
                  isRequired
                  {...field}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <FieldText
              maxLength={120}
              currentLength={get(watch('clinicalSpecialty'), 'length')}
              label="Especialidade Clínica"
              error={errors.clinicalSpecialty}
              isRequired
              {...register('clinicalSpecialty')}
            />
          </GridItem>
        </Grid>
      </FieldSet>
    </Box>
  )
}
