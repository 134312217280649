// Vendors
import { get, map, reduce, some } from 'lodash'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

// Contexts
import { useCart } from 'contexts/cart'
import { CheckoutType } from 'contexts/cart/interface'

// Hooks
import { useSelectParser } from 'hooks/useSelectParser'

// Components
import { FieldSelect } from 'components/FieldSelect'
import { FormWrapper } from 'components/FormWrapper'

// Styles
import {
  Box,
  Flex,
  Text,
  Stack,
  Button,
  useToken,
  IconButton,
  Heading,
} from '@chakra-ui/react'
import { FiMinusCircle } from 'react-icons/fi'

// Types
import { FormCheckout } from '../../types'

type SelectedCouponType = {
  value: string
  label: string
}

const ERROR_MESSAGES = ['Coupon reached maximum usage per client']

export const Coupon = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('checkout')

  const red500 = useToken('colors', 'red.500')
  const [selectedCoupon, setSelectedCoupon] = useState<
    SelectedCouponType | undefined
  >(undefined)

  const { control, setValue, getValues } = useFormContext<FormCheckout>()

  const { fields, append, remove } = useFieldArray<FormCheckout>({
    name: 'voucherCodes',
    control,
  })

  const { addCouponToCart, removeCouponFromCart, checkout } = useCart()

  /*
  |-----------------------------------------------------------------------------
  | Options
  |-----------------------------------------------------------------------------
  |
  |
  */

  const selectCoupon = useSelectParser({
    accessor: { label: 'voucherCode', value: 'voucherCode' },
    endpoint: '/app/coupon',
  })

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isLoading, setIsLoading] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const invalidCoupon = useMemo(() => {
    if (!checkout) return false

    const errors = reduce(
      checkout?.messages?.errors,
      (acc, val) => {
        const [voucherCode, errorMessage] = val.split(':')

        return {
          ...acc,
          [voucherCode]: errorMessage,
        }
      },
      {}
    )

    return errors
  }, [checkout])

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const updateDiscountColumn = useCallback(
    (response: CheckoutType) => {
      const products = getValues('purchaseProducts')
      response.storeProductsList.forEach((item, index) => {
        setValue(
          `purchaseProducts.${index}.totalDiscount`,
          item.discountApplied
        )
        setValue(`purchaseProducts.${index}.totalPrice`, item.subTotalPrice)
      })
    },
    [getValues, setValue]
  )

  const handleRemoveCoupon = useCallback(
    async (index: number, voucherCode: string) => {
      try {
        setIsRemoving(true)
        const response = await removeCouponFromCart(voucherCode)

        if (!response) throw new Error('No response')

        updateDiscountColumn(response)
        remove(index)
      } catch (error) {
        console.error(error)
      } finally {
        setIsRemoving(false)
      }
    },
    [remove, removeCouponFromCart, updateDiscountColumn]
  )

  const handleAddCoupon = useCallback(async () => {
    if (!selectedCoupon) return

    const voucherCodes = getValues('voucherCodes')

    if (some(voucherCodes, { value: selectedCoupon.value })) return

    try {
      setIsLoading(true)

      const response = await addCouponToCart(selectedCoupon.value)

      if (!response) throw new Error('No response')

      updateDiscountColumn(response)

      append(selectedCoupon)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [addCouponToCart, append, getValues, selectedCoupon, updateDiscountColumn])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormWrapper title={t('group.coupon')}>
      <Stack spacing="32" direction={{ lg: 'row' }}>
        <Box>
          <Stack isInline alignItems="flex-end">
            <Box w="80">
              <FieldSelect
                name="coupon"
                label={t('form.couponId.label')}
                options={selectCoupon.options}
                isLoading={selectCoupon.isLoading}
                onChange={(value: any) => setSelectedCoupon(value)}
              />
            </Box>

            <Button isLoading={isLoading} onClick={handleAddCoupon}>
              {t('button.apply')}
            </Button>
          </Stack>
        </Box>

        <Stack>
          <Heading as="span" fontSize="md" fontWeight="medium">
            {t('form.couponsApplied')}
          </Heading>

          {fields.length > 0 ? (
            fields.map((field, index) => (
              <Flex key={field.id} alignItems="center">
                <Stack isInline w="80" alignItems="center">
                  <Text
                    as="span"
                    color="#1FB7BC"
                    fontWeight="bold"
                    textDecorationLine={
                      get(invalidCoupon, (field as { label: string }).label)
                        ? 'line-through'
                        : ''
                    }
                  >
                    {(field as { label: string }).label}
                  </Text>

                  {get(invalidCoupon, (field as { label: string }).label) && (
                    <Text
                      as="span"
                      isTruncated
                      fontSize="sm"
                      color="red.500"
                      fontWeight="light"
                    >
                      Cupom indisponível
                    </Text>
                  )}
                </Stack>

                <IconButton
                  size="sm"
                  variant="ghost"
                  aria-label="delete"
                  isLoading={isRemoving}
                  icon={<FiMinusCircle size="20" color={red500} />}
                  onClick={() =>
                    handleRemoveCoupon(
                      index,
                      (field as { value: string }).value
                    )
                  }
                />
              </Flex>
            ))
          ) : (
            <Text color="gray.500">{t('form.noCouponsApplied')}</Text>
          )}
        </Stack>
      </Stack>
    </FormWrapper>
  )
}
