// Vendors
import React, { forwardRef } from 'react'
import { FieldError } from 'react-hook-form'

// Styles
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
  Text,
} from '@chakra-ui/react'
import { useFormHelperText } from 'hooks/useFormHelperText'
import { Tooltip, TooltipProps } from 'components/Tooltip'

// Interfaces
export interface IFieldText extends InputProps {
  name: string
  label?: string
  error?: FieldError
  currentLength?: number
  canModify?: boolean
  helperText?:
    | string
    | {
        text: string
        color?: string
      }
  tooltipProps?: TooltipProps
  isRequired?: boolean
}

const FieldTextBase: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IFieldText
> = (props, ref) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const {
    name,
    label,
    error,
    helperText,
    currentLength,
    canModify = true,
    tooltipProps,
    isRequired,
    ...rest
  } = props

  const componentHelperText = useFormHelperText({ helperText, canModify })

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormControl isInvalid={!!error}>
      <Flex justify="space-between" alignItems="center" mb={2}>
        {!!label && (
          <FormLabel htmlFor={name} m={0}>
            {label}

            {isRequired && (
              <Text
                as="span"
                color="red.500"
                ml={1}
                fontWeight="bold"
                userSelect="none"
              >
                *
              </Text>
            )}
          </FormLabel>
        )}

        <Flex alignItems="center">
          {rest.maxLength !== undefined && currentLength !== undefined && (
            <Text
              as="span"
              fontSize="sm"
              color="gray.500"
              letterSpacing="wide"
              mr={2}
            >
              {String(currentLength).padStart(2, '0')}/
              {String(rest.maxLength).padStart(2, '0')}
            </Text>
          )}

          {tooltipProps && Object.keys(tooltipProps).length && (
            <Tooltip {...tooltipProps} />
          )}
        </Flex>
      </Flex>

      <Input id={name} name={name} ref={ref} {...rest} />

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      {(helperText || !canModify) && !error && componentHelperText}
    </FormControl>
  )
}

export const FieldText = forwardRef(FieldTextBase)
