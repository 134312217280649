// Vendors
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// Components
import { FieldMask } from 'components/FieldMask'
import { FieldText } from 'components/FieldText'
import { FieldDate } from 'components/FieldDate'
import { FieldRadioButton } from 'components/FieldRadioButton'

// Styles
import { Grid, GridItem } from '@chakra-ui/react'

// Types
import { SellerShowForm } from '../types'

export const TabPersonalData = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { t } = useTranslation('seller')

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<SellerShowForm>()

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const genresOptions = useMemo(
    () => [
      {
        label: 'Masculino',
        value: 'M',
      },
      {
        label: 'Feminino',
        value: 'F',
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Grid
      my="4"
      gap="10"
      templateColumns={{ lg: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
    >
      <GridItem colSpan={2}>
        <FieldText
          label={t('form.name.label')}
          error={errors.name}
          isRequired
          {...register('name')}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <FieldText
          canModify={false}
          label={t('form.email.label')}
          error={errors.email}
          isRequired
          {...register('email')}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <FieldMask
              label={t('form.phoneNumber.label')}
              mask="(99) 9 9999-9999"
              error={errors.phoneNumber}
              isRequired
              {...field}
            />
          )}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <Controller
          name="documentNumber"
          control={control}
          render={({ field }) => (
            <FieldMask
              label={t('form.documentNumber.label')}
              mask="999.999.999-99"
              error={errors.documentNumber}
              isRequired
              {...field}
            />
          )}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <Controller
          name="birthdate"
          control={control}
          render={({ field }) => (
            <FieldDate
              label={t('form.birthdate.label')}
              name={field.name}
              selected={field.value}
              onChange={field.onChange}
              error={errors.birthdate}
              maxDate={new Date()}
              isRequired
            />
          )}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <FieldRadioButton
          label={t('form.gender.label')}
          name="gender"
          control={control}
          radioButtonDirection="row"
          options={genresOptions}
          isRequired
        />
      </GridItem>
    </Grid>
  )
}
