// Vendors
import { find, get, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

// Functions
import { apiPut } from 'services/put'
import { useError } from 'hooks/useError'
import { useEditRecords } from 'hooks/useEditRecord'
import { useFormTransform } from 'hooks/useFormTransform'
import { useConfirmationDialog } from 'contexts/confirmationDialog'

// Components
// import { TabPoints } from './tabs/TabPoints'
// import { TabOrders } from './tabs/TabOrders'
import { TabDetail, Tabs } from 'components/Tabs'
import { FormFooter } from 'components/FormFooter'
import { FieldStatus } from 'components/FieldStatus'
import { TabPersonalData } from './tabs/TabPersonalData'
import { ButtonNewOrder } from 'components/ButtonNewOrder'

// Schemas
import { schema } from './schema'

// Styles
import { Badge, Box, Flex, Text, useToast } from '@chakra-ui/react'
import {
  ClinicEnum,
  CouncilEnum,
  PersonalDataFileType,
  ProfessionalShowAPI,
  ProfessionalShowForm,
} from './types'
import { ResponseForDialog } from 'types/ResponseForDialog'
import { apiShow } from 'services/get'
import { PersonPurchaseAndKit } from 'types/PersonPurchaseAndKit'
import { useDialogDelete } from 'hooks/useDialogDelete'

// Types

type ProfessionalFormShowProps = {
  id: string
  endpoint: string
}

type PersonalDataDocumentsReturn<T extends 'FILE' | 'VALUE'> = T extends 'VALUE'
  ? string
  : PersonalDataFileType

export const ProfessionalFormShow = (
  props: ProfessionalFormShowProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { id, endpoint } = props

  const { getConfirmation } = useConfirmationDialog()
  const { handleError } = useError()
  const { t } = useTranslation('professional')
  const toast = useToast({ position: 'top-right' })
  const { statusParser } = useFormTransform()
  const { record } = useEditRecords<ProfessionalShowAPI, any>({
    endpoint,
    currentId: id,
  })

  const recordCurrent = get(record, 'current')

  const methods = useForm<ProfessionalShowForm>({
    resolver: yupResolver(schema(t)),
  })

  const { dialog } = useDialogDelete()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [responseForDialog, setResponseForDialog] = useState<
    ResponseForDialog | undefined
  >(undefined)

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    const fetchData = async () => {
      const response = await apiShow<PersonPurchaseAndKit>(
        `/app/person/${id}/purchase_kits`
      )

      const responseForDialogKits = map(response?.kits, (kit) => {
        return {
          href: `/kits/${kit.activationCode}`,
          label: kit.activationCode,
        }
      })

      const responseForDialogPurchases = map(
        response?.purchases,
        (purchase) => {
          return {
            href: `/orders/${purchase.id}`,
            label: `#${purchase.purchaseNumber}`,
          }
        }
      )

      setResponseForDialog({
        kits: responseForDialogKits,
        purchases: responseForDialogPurchases,
      })
    }

    fetchData()
  }, [id])

  const getPersonalDataDocuments = useCallback(
    <T extends 'FILE' | 'VALUE'>(
      slug: string,
      type: T
    ): PersonalDataDocumentsReturn<T> => {
      const documents = get(recordCurrent, 'personalDataHasDocuments')

      const documentBySlug = find(documents, [
        'personalDataRequirement.slug',
        slug,
      ])

      if (!documentBySlug) return '' as PersonalDataDocumentsReturn<T>

      switch (type) {
        case 'FILE':
          return get(
            documentBySlug,
            'personalDataFile'
          ) as PersonalDataDocumentsReturn<T>
        case 'VALUE':
          return get(
            documentBySlug,
            'documentData'
          ) as PersonalDataDocumentsReturn<T>
        default:
          return '' as PersonalDataDocumentsReturn<T>
      }
    },
    [recordCurrent]
  )

  const handleDisabled = useCallback(async () => {
    try {
      await record.disable()
      record.reload()
    } catch (error) {
      handleError(error)
    }
  }, [handleError, record])

  const sanitize = useCallback((): ProfessionalShowForm => {
    let status = get(recordCurrent, 'user.status')
    const roles = get(recordCurrent, 'user.roles')
    let phoneNumber = get(recordCurrent, 'phoneNumber')
    let statusRoleProfessional = ''

    const roleProfessional = find(roles, { slug: 'professional' })

    if (phoneNumber && phoneNumber.startsWith('+')) {
      phoneNumber = phoneNumber.slice(3)
    }

    if (roleProfessional) {
      const roleUserProfessional = find(roleProfessional.roleUser, {
        roleId: roleProfessional.id,
      })

      if (roleUserProfessional) {
        statusRoleProfessional = get(roleUserProfessional, 'status')
      }
    }

    if (status !== 'PENDING_ACTIVATION') {
      status = statusParser(status)
    }

    const professionalHasSellerId = get(
      recordCurrent,
      'user.professionalHasSeller.sellerUserId'
    ) as string

    // const recordClinicId = {
    //   value: get(recordCurrent, 'user.companies.0.id') as string,
    //   label: get(
    //     recordCurrent,
    //     'user.companies.0.internalIdentification'
    //   ) as string,
    // }

    const recordClinicId = recordCurrent?.user?.companies?.map((company) => ({
      value: company.id,
      label: company.internalIdentification,
    }))

    return {
      status,
      phoneNumber,
      statusRoleProfessional,
      sellerUserId: professionalHasSellerId,
      gender: get(recordCurrent, 'gender'),
      name: get(recordCurrent, 'name'),
      email: get(recordCurrent, 'email'),
      documentNumber: get(recordCurrent, 'documentNumber'),
      birthdate: get(recordCurrent, 'birthdate'),
      [CouncilEnum.councilValue]: getPersonalDataDocuments(
        CouncilEnum.councilValue,
        'VALUE'
      ),
      [CouncilEnum.councilType]: getPersonalDataDocuments(
        CouncilEnum.councilType,
        'VALUE'
      ),
      [CouncilEnum.councilStateValue]: getPersonalDataDocuments(
        CouncilEnum.councilStateValue,
        'VALUE'
      ),
      [CouncilEnum.councilFileFront]: getPersonalDataDocuments(
        CouncilEnum.councilFileFront,
        'FILE'
      ),
      [CouncilEnum.councilFileBack]: getPersonalDataDocuments(
        CouncilEnum.councilFileBack,
        'FILE'
      ),
      [CouncilEnum.councilFileSelfie]: getPersonalDataDocuments(
        CouncilEnum.councilFileSelfie,
        'FILE'
      ),
      [ClinicEnum.recordClinicId]: recordClinicId,
    }
  }, [getPersonalDataDocuments, recordCurrent, statusParser])

  useEffect(() => {
    const newCurrent = sanitize()
    methods.reset(newCurrent)
  }, [methods, sanitize])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const deleteDialog = useMemo(() => {
    if (!responseForDialog) return

    return dialog(
      {
        title: 'Kits',
        items: responseForDialog.kits,
      },
      {
        title: 'Pedidos',
        items: responseForDialog.purchases,
      }
    )
  }, [dialog, responseForDialog])

  const badgeProps = useMemo(() => {
    if (!recordCurrent) return

    const title = recordCurrent.pendingContract
      ? 'CONTRATO PENDENTE'
      : 'CONTRATO ASSINADO'

    const GREEN = { color: '#0F6F87', bg: '#D1FBEE', borderRadius: 2 }
    const RED = { bg: '#FFE5D9', color: '#931538', borderRadius: 2 }

    const styles = recordCurrent.pendingContract ? RED : GREEN

    return {
      styles,
      title,
    }
  }, [recordCurrent])

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: t('tabs.tabPersonalData.title'),
        component: TabPersonalData,
        options: {
          id,
          reload: record.reload,
        },
      },
      // {
      //   title: t('tabs.tabOrders.title'),
      //   component: TabOrders,
      // },
      // {
      //   title: t('tabs.tabPoints.title'),
      //   component: TabPoints,
      // },
    ],
    [id, record.reload, t]
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<ProfessionalShowForm> = React.useCallback(
    async (data: any) => {
      try {
        await apiPut(`${endpoint}/${id}`, data)

        if (methods.formState.dirtyFields?.email) {
          const confirmation = await getConfirmation({
            title: 'Atenção',
            message: (
              <>
                <Text>Por favor, confirme a mudança antes de prosseguir</Text>

                <Text mt="2">
                  Você modificou o e-mail do usuário de{' '}
                  <Text as="strong">{get(recordCurrent, 'email')}</Text> para:
                </Text>

                <Box my="6" bg="#f6f6f6" p="6">
                  <Text textAlign="center">{data.email}</Text>
                </Box>

                <Text
                  fontSize="sm"
                  color="orange.500"
                  mt="4"
                  fontStyle="italic"
                >
                  Ao confirmar a mudança, o email de login do usuário será
                  modificado e o anterior deixará de funcionar.
                </Text>
              </>
            ),
          })

          if (!confirmation) return
        }

        toast({
          title: t('toast.success.title'),
          description: t('toast.success.descriptionUpdate'),
          status: 'success',
        })

        record.reload()
      } catch (error) {
        const errorHandled = handleError(error, undefined, { 422: true })

        if (errorHandled) {
          errorHandled.forEach((errorItem) => {
            const { field, message } = errorItem as {
              field: keyof ProfessionalShowForm
              message: string
            }

            methods.setError(field, { message })
          })
        }
      }
    },
    [
      endpoint,
      getConfirmation,
      handleError,
      id,
      methods,
      record,
      recordCurrent,
      t,
      toast,
    ]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        position="relative"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box position="absolute" top="-9.25rem" right="0" zIndex="20">
          <ButtonNewOrder personId={id} />
        </Box>
        <Box position="absolute" top="-9.25rem" right="44" zIndex="20">
          <Badge p={1} {...badgeProps?.styles}>
            {badgeProps?.title}
          </Badge>
        </Box>

        {get(recordCurrent, 'user.status') === 'PENDING_ACTIVATION' ? (
          <Box alignSelf="flex-end">
            <Badge p={1} colorScheme="yellow">
              Ativação pendente
            </Badge>
          </Box>
        ) : (
          <FieldStatus
            methods={methods}
            error={methods.formState.errors.status}
            disable={record.disable}
            enable={record.enable}
          />
        )}

        <Tabs tabsDetails={tabsDetails} />

        <FormFooter
          titleDialog={deleteDialog?.title}
          bodyDialog={deleteDialog?.body}
          isSubmitting={methods.formState.isSubmitting}
          confirmLabel={deleteDialog?.isDisabled ? 'Desativar' : 'Deletar'}
          recordDelete={
            deleteDialog?.isDisabled ? handleDisabled : record.delete
          }
        />
      </Flex>
    </FormProvider>
  )
}
