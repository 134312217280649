// Vendors
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

// Functions
import { useCreateRecord } from 'hooks/useCreateRecord'

// Components
import { TabDetail, Tabs } from 'components/Tabs'
import { FormFooter } from 'components/FormFooter'
import { TabGeneralNew } from '../tabs/TabGeneralNew'

// Schemas
import { schema } from './schema'

// Styles
import { Flex, useToast } from '@chakra-ui/react'

// Types
import { CompanyLaboratoryNewForm, CompanyLaboratoryNewRecord } from './types'
import { useError } from 'hooks/useError'

type CompanyLaboratoryFormNewProps = {
  endpoint: string
}

export const CompanyLaboratoryFormNew = (
  props: CompanyLaboratoryFormNewProps
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props

  const { create } = useCreateRecord({ endpoint })
  const { t } = useTranslation('common')
  const history = useHistory()
  const { handleError } = useError()

  const toast = useToast({ position: 'top-right' })

  const methods = useForm({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Geral',
        component: TabGeneralNew,
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<CompanyLaboratoryNewForm> = React.useCallback(
    async (data) => {
      try {
        const formattedData: CompanyLaboratoryNewRecord = {
          ...data,
          companyType: 'LABORATORY',
        }

        await create(formattedData)

        toast({
          title: 'Criado com sucesso',
          description: 'Laboratório criada com sucesso',
          status: 'success',
        })

        history.push('/companies')
      } catch (error) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            if (fieldError.field === 'documentNumber') {
              fieldError.message = 'Verifique se o CNPJ está correto'
            }
            methods.setError(fieldError.field, { message: fieldError.message })
          })
        } else {
          handleError(error)
        }
      }
    },
    [create, handleError, history, methods, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        h="full"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Tabs tabsDetails={tabsDetails} />

        <FormFooter isSubmitting={methods.formState.isSubmitting} />
      </Flex>
    </FormProvider>
  )
}
