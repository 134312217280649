import React, { useCallback } from 'react'
import { get } from 'lodash'

import { ModalRequestCollection } from 'components/ModalRequestCollection'

import { useDisclosure } from '@chakra-ui/react'

import { ActionProps } from './types'
import { WrapperAction } from './WrapperAction'

export const RequestCollectionAction = <T extends unknown>({
  record,
  options,
}: ActionProps<T>) => {
  const modalRequestCollectionDisclosure = useDisclosure()

  const {
    callback,
    actionType = 'menu',
    reload,
    tooltipProps,
    ...optionsRest
  } = options

  if (!reload) {
    throw new Error('reload is required')
  }

  const cta = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, data: T) => {
      modalRequestCollectionDisclosure.onOpen()

      if (callback) {
        callback(e, data)
      }
    },
    [modalRequestCollectionDisclosure, callback]
  )

  return (
    <>
      <ModalRequestCollection
        reload={reload}
        purchaseId={get(record, 'purchaseHasProduct.purchaseId')}
        activationCode={get(record, 'purchaseHasProduct.activationCode')}
        isOpen={modalRequestCollectionDisclosure.isOpen}
        onCancel={modalRequestCollectionDisclosure.onClose}
      />

      <WrapperAction
        cta={cta}
        record={record}
        options={optionsRest}
        actionType={actionType}
        label="Solicitar recoleta"
        tooltipProps={tooltipProps}
      />
    </>
  )
}
