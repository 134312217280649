import React, { useMemo } from 'react'

import { Button, ButtonGroup, Divider, Flex, Text } from '@chakra-ui/react'
import { NotificationMeta } from 'contexts/notification/types'

type FooterProps = {
  meta: NotificationMeta[]
  onNextPage: () => void
  onPrevPage: () => void
}

export const Footer = (props: FooterProps) => {
  const { onNextPage, onPrevPage, meta } = props

  const hasNextPage = useMemo(() => meta.some((m) => m.hasNextPage), [meta])

  const hasPrevPage = useMemo(() => meta.some((m) => m.hasPrevPage), [meta])

  const total = useMemo(() => meta.reduce((acc, m) => acc + m.total, 0), [meta])

  if (total === 0) {
    return null
  }

  return (
    <>
      <Divider mb="8" />
      <Flex as="footer" justifyContent="space-between" alignItems="center">
        <Text color="gray.600" fontSize="sm">
          <Text as="strong">{total}</Text> registros encontrados
        </Text>

        <ButtonGroup variant="outline" size="sm" color="gray.700">
          <Button onClick={onPrevPage} isDisabled={!hasPrevPage}>
            Anterior
          </Button>

          <Button onClick={onNextPage} isDisabled={!hasNextPage}>
            Próximo
          </Button>
        </ButtonGroup>
      </Flex>
    </>
  )
}
