// Vendors
import React from 'react'

// Components
import {
  Button as ButtonChakra,
  ButtonProps as ButtonChrakraProps,
} from '@chakra-ui/react'

// Interface
export interface ButtonComponentProps extends ButtonChrakraProps {
  text: string
}

export const Button: React.FC<ButtonComponentProps> = ({ text, ...rest }) => {
  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <ButtonChakra
      mb={{ base: '4', md: '0' }}
      w={{ base: 'full', md: 'auto' }}
      type="submit"
      size="lg"
      fontSize="md"
      fontWeight="bold"
      {...rest}
    >
      {text}
    </ButtonChakra>
  )
}
