import { Link } from 'react-router-dom'
import React, { useCallback, useMemo, useState } from 'react'

import {
  Box,
  Menu,
  Flex,
  Button,
  Spinner,
  Divider,
  Heading,
  MenuItem,
  MenuList,
  MenuGroup,
  SlideFade,
  IconButton,
  MenuButton,
} from '@chakra-ui/react'

import { useNotification } from 'contexts/notification'

import { TabMessage } from './tabs/TabMessage'
import { TabFavorite } from './tabs/TabFavorite'
import { TabDetail, Tabs } from 'components/Tabs'

import { Archive } from 'assets/icons/Archive'
import { DoubleCheck } from 'assets/icons/DoubleCheck'
import { ThreeDotsVertical } from 'assets/icons/ThreeDotsVertical'

export const NotificationModal = () => {
  const { ref, isOpen, markAllArchive, markAsRead, fetchNotifications } =
    useNotification()

  const [isArchiving, setIsArchiving] = useState(false)
  const [isReading, setIsReading] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleArchiveAll = useCallback(async () => {
    try {
      setIsArchiving(true)
      await markAllArchive()
      await fetchNotifications()
    } catch (error) {
      console.error(error)
    } finally {
      setIsArchiving(false)
    }
  }, [fetchNotifications, markAllArchive])

  const handleMarkAsRead = useCallback(async () => {
    try {
      setIsReading(true)
      await markAsRead()
      await fetchNotifications()
    } catch (error) {
      console.error(error)
    } finally {
      setIsReading(false)
    }
  }, [fetchNotifications, markAsRead])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const tabsDetails: TabDetail[] = useMemo(
    () => [
      {
        title: 'Mensagens',
        component: TabMessage,
      },
      {
        title: 'Favoritos',
        component: TabFavorite,
      },
    ],
    []
  )

  return (
    <Box>
      {isOpen && (
        <Box
          ref={ref}
          top="88px"
          left="256px"
          zIndex="overlay"
          overflowY="auto"
          overflowX="hidden"
          position="absolute"
          w={isOpen ? '80' : 0}
          transition="width 0.2s"
          borderRightRadius="6px"
          h="calc(100vh - 88px)"
          maxH="607px"
        >
          <SlideFade offsetY="0" offsetX="-100px" in={isOpen}>
            <Box
              pt="4"
              pb="3"
              bg="white"
              px="0.375rem"
              borderTopRightRadius="6px"
              border="0.5px solid#F0F0F0"
              borderBottom="0"
              boxShadow="24px 32px 24px rgba(0, 0, 0, 0.01), 11px 14px 18px rgba(0, 0, 0, 0.02), 3px 4px 10px rgba(0, 0, 0, 0.02), 0px 0px 0px rgba(0, 0, 0, 0.02)"
            >
              <Flex
                as="header"
                alignItems="center"
                justifyContent="space-between"
                h="40px"
              >
                <Heading
                  as="h1"
                  pl="2.5"
                  color="black"
                  fontSize="md"
                  fontWeight="medium"
                >
                  Notificações
                </Heading>

                <Menu>
                  <MenuButton
                    as={IconButton}
                    size="sm"
                    variant="ghost"
                    icon={<ThreeDotsVertical />}
                    aria-label="notification configuration"
                  />

                  <MenuList color="gray.800" fontSize="xs">
                    <MenuGroup
                      fontSize="xs"
                      color="gray.900"
                      fontWeight="medium"
                      title="Configurações"
                      textTransform="uppercase"
                    >
                      <MenuItem
                        onClick={handleArchiveAll}
                        icon={isArchiving ? <Spinner size="sm" /> : <Archive />}
                      >
                        Arquivar tudo
                      </MenuItem>
                      <MenuItem
                        onClick={handleMarkAsRead}
                        icon={
                          isReading ? <Spinner size="sm" /> : <DoubleCheck />
                        }
                      >
                        Marca todas como lidas
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Flex>

              <Divider
                my="1.5"
                borderColor="#C2C2C2"
                borderBottomWidth="0.5px"
              />

              <Tabs
                tabPanelProps={{
                  padding: '0 !important',
                }}
                tabListProps={{
                  borderBottomWidth: '1px',
                  height: '38px',
                }}
                tabProps={{
                  width: '100%',
                  fontSize: 'sm',
                  _selected: {
                    fontWeight: 'medium',
                    borderBottomColor: '#13A7A7',
                  },
                }}
                tabsDetails={tabsDetails}
              />
            </Box>

            <Button
              as={Link}
              h="36px"
              w="full"
              fontSize="sm"
              to="/notifications"
              colorScheme="blue"
              borderRadius="0 0 6px 0"
            >
              Ver todas notificações
            </Button>
          </SlideFade>
        </Box>
      )}
    </Box>
  )
}
