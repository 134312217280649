// Vendors
import { flatten, get, intersection, map } from 'lodash'
import React, { useCallback, useMemo, MouseEvent, useState } from 'react'

// Components
import { DrawerUpload } from '../DrawerUpload'
import { ListTable } from 'components/ListTable'
import { ListActions } from 'components/ListActions'
import { FieldTextArea } from 'components/FieldTextArea'
import { AlertDialogDelete } from 'components/AlertDialogDelete'

// Styles
import {
  Box,
  Text,
  Stack,
  Button,
  useToast,
  useDisclosure,
} from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'

// Hooks
import { useAuth } from 'contexts/auth'
import { useError } from 'hooks/useError'
import { apiDelete } from 'services/delete'
import { useDownload } from 'hooks/useDownload'
import { REPORT_DOWNLOAD_PERMISSION_ROLES } from 'constants/constants'

// Types
import { UserRolesNames } from 'types/User'
type AnalysisReportFilesType = {
  id: string
  fileName: string
  originalFileName: string
  laboratoryAnalysis?: LaboratoryAnalysisType
}

type LaboratoryAnalysisType = {
  id: string
  name: string
}

export type PurchaseHasProductHasAnalysisType = {
  analysisReportFiles: AnalysisReportFilesType[]
  laboratoryAnalysis: LaboratoryAnalysisType
}

type TabReportProps = {
  activationCode?: string
  recordReload: () => Promise<void>
  purchaseHasProductHasAnalysis?: PurchaseHasProductHasAnalysisType[]
}

const REPORT_DELETE_PERMISSION_ROLES = [
  UserRolesNames.LABORATORY_MANAGER,
  UserRolesNames.SUPERADMIN,
]

export const TabReport = (props: TabReportProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { activationCode, recordReload, purchaseHasProductHasAnalysis } = props

  const { user } = useAuth()
  const reasonDeleteRef = React.useRef<HTMLTextAreaElement>(null)
  const { onDownload } = useDownload()
  const disclosureUpload = useDisclosure()
  const disclosureDelete = useDisclosure()
  const toast = useToast({ position: 'top-right' })
  const { handleError } = useError()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [loadingDelete, setLoadingDelete] = useState(false)
  const [recordDelete, setRecordDelete] = useState<
    { laboratoryAnalysisId: string; analysisReportFileId: string } | undefined
  >(undefined)

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDownloadReport = useCallback(
    async (e: MouseEvent<HTMLButtonElement>, item: AnalysisReportFilesType) => {
      e.stopPropagation()

      try {
        if (!activationCode) {
          throw new Error('Código de ativação não encontrado')
        }

        const { id, laboratoryAnalysis, originalFileName } = item

        if (!laboratoryAnalysis) {
          throw new Error('Análise laboratorial não encontrada')
        }

        const endpointDownloadReport = `${process.env.REACT_APP_API_URL}app/reverse_logistic/${activationCode}/analysis/${laboratoryAnalysis.id}/report/${id}/download_report`

        await onDownload(endpointDownloadReport, originalFileName)
      } catch (error: any) {
        handleError(error)
      }
    },
    [activationCode, handleError, onDownload]
  )

  const handleDeleteReport = useCallback(async () => {
    if (!recordDelete) return

    try {
      setLoadingDelete(true)
      const { laboratoryAnalysisId, analysisReportFileId } = recordDelete

      await apiDelete(
        `/app/reverse_logistic/${activationCode}/analysis/${laboratoryAnalysisId}/report/${analysisReportFileId}`,
        {
          reasonDelete: reasonDeleteRef.current?.value,
        }
      )

      toast({
        title: 'Sucesso',
        description: 'Laudo excluído com sucesso',
        status: 'success',
      })

      await recordReload()
      disclosureDelete.onClose()
    } catch (error: any) {
      handleError(error)
    } finally {
      setLoadingDelete(false)
    }
    disclosureDelete.onClose()
  }, [
    activationCode,
    disclosureDelete,
    handleError,
    recordDelete,
    recordReload,
    toast,
  ])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const deleteDialog = useMemo(() => {
    const body = (
      <Stack>
        <Text>
          Tem certeza de que deseja deletar este item?{' '}
          <Text as="strong">Esta ação não pode ser desfeita.</Text>
        </Text>
        <FieldTextArea
          label="Motivo"
          name="reasonDelete"
          ref={reasonDeleteRef}
        />
      </Stack>
    )

    return {
      body,
    }
  }, [])

  const memoRecords = useMemo(() => {
    const analysisReportFiles = flatten(
      map(purchaseHasProductHasAnalysis, (item) => {
        return map(item.analysisReportFiles, (file) => {
          return {
            ...file,
            laboratoryAnalysis: item.laboratoryAnalysis,
          }
        })
      })
    )

    return {
      list: analysisReportFiles || [],
    }
  }, [purchaseHasProductHasAnalysis])

  const listTableHeaders = useMemo(
    () => [
      {
        label: 'Nome',
        accessor: 'title',
      },
      {
        label: 'Análise Laboratorial',
        accessor: 'laboratoryAnalysis.name',
      },
      {
        label: 'Comentário interno',
        accessor: 'comment',
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box>
      <AlertDialogDelete
        bodyDialog={deleteDialog.body}
        isOpen={disclosureDelete.isOpen}
        onCancel={disclosureDelete.onClose}
        isLoading={loadingDelete}
        onConfirm={handleDeleteReport}
      />

      <DrawerUpload
        recordReload={recordReload}
        isOpen={disclosureUpload.isOpen}
        onClose={disclosureUpload.onClose}
        activationCode={activationCode}
        purchaseHasProductHasAnalysis={purchaseHasProductHasAnalysis}
      />

      <Stack
        spacing="4"
        justify="flex-end"
        direction={{ base: 'column', md: 'row' }}
      >
        <ListActions>
          <Button onClick={disclosureUpload.onOpen} leftIcon={<FiPlus />}>
            Adicionar
          </Button>
        </ListActions>
      </Stack>

      <ListTable
        headers={listTableHeaders}
        records={memoRecords}
        defaultActions="none"
        actions={[
          {
            type: 'report',
            options: {
              callback: handleDownloadReport,
              notDisableWhen: () => {
                const roles = map(get(user, 'roleUser'), 'role.name')

                return (
                  intersection(roles, REPORT_DOWNLOAD_PERMISSION_ROLES)
                    .length === 0
                )
              },
            },
          },
          {
            type: 'delete',
            options: {
              tooltipProps: {
                label:
                  'Solicite a exclusão deste arquivo a um gerente de laboratório',
                shouldWrapChildren: true,
                placement: 'left',
                isDisabled:
                  intersection(
                    map(get(user, 'roleUser'), 'role.name'),
                    REPORT_DELETE_PERMISSION_ROLES
                  ).length > 0,
              },

              notDisableWhen() {
                const roles = map(get(user, 'roleUser'), 'role.name')

                return (
                  intersection(roles, REPORT_DELETE_PERMISSION_ROLES).length ===
                  0
                )
              },
              callback: (e, record) => {
                const { laboratoryAnalysis, id } = record
                setRecordDelete({
                  analysisReportFileId: id,
                  laboratoryAnalysisId: laboratoryAnalysis.id,
                })
                disclosureDelete.onOpen()
              },
            },
          },
        ]}
      />
    </Box>
  )
}
