// Vendors
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form'

// Functions
import { useSelectParser } from 'hooks/useSelectParser'
import { useCreateRecord } from 'hooks/useCreateRecord'

// Components
import { FieldMask } from 'components/FieldMask'
import { FieldText } from 'components/FieldText'
import { FieldDate } from 'components/FieldDate'
import { FormFooter } from 'components/FormFooter'
import { FieldToggle } from 'components/FieldToggle'
import { FieldSelect } from 'components/FieldSelect'
import { FieldRadioButton } from 'components/FieldRadioButton'

// Schemas
import { schema } from '../new/schema'

// Styles
import { Flex, Grid, GridItem, useToast } from '@chakra-ui/react'

// Types
import { Company } from 'types/Company'
import {
  UserLaboratoryAgentForm,
  UserLaboratoryAgentRecord,
} from '../new/types'

type TabPersonalDataProps = {
  endpoint: string
}

export const TabPersonalData = (props: TabPersonalDataProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { endpoint } = props

  const { t } = useTranslation('common')
  const history = useHistory()

  const selectCompanyLaboratory = useSelectParser<Company>({
    accessor: { value: 'id', label: 'internalIdentification' },
    endpoint: '/app/company?type=laboratory',
  })

  const { create } = useCreateRecord({ endpoint })
  const toast = useToast({ position: 'top-right' })

  const {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserLaboratoryAgentForm>({
    resolver: yupResolver(schema(t)),
  })

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const genresOptions = useMemo(
    () => [
      {
        label: 'Masculino',
        value: 'M',
      },
      {
        label: 'Feminino',
        value: 'F',
      },
    ],
    []
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit: SubmitHandler<UserLaboratoryAgentRecord> = React.useCallback(
    async (data) => {
      try {
        const formData = { ...data, roleName: 'LABORATORY_AGENT' }

        await create(formData)

        toast({
          title: 'Sucesso',
          description: 'Agente laboratório criado com sucesso',
          status: 'success',
        })

        history.push('/users')
      } catch (error) {
        if (error && error instanceof Array) {
          error.forEach((fieldError) => {
            setError(fieldError.field, { message: fieldError.message })
          })
        }
      }
    },
    [create, history, setError, toast]
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Flex
      as="form"
      h="full"
      flexDirection="column"
      justifyContent="space-between"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="status"
        control={control}
        defaultValue={true}
        render={({ field: { onChange, value, ref, name } }) => (
          <FieldToggle
            name={name}
            position="absolute"
            top={-16}
            right={0}
            ref={ref}
            isChecked={value}
            onChange={onChange}
            error={errors.status}
          />
        )}
      />

      <Grid
        my="4"
        gap="10"
        templateColumns={{ lg: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
      >
        <GridItem colSpan={2}>
          <FieldText
            label="Nome"
            error={errors.name}
            isRequired
            {...register('name')}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <FieldText
            canModify={false}
            label="E-mail"
            error={errors.email}
            isRequired
            {...register('email')}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <FieldMask
            label="Telefone"
            mask="(99) 9 9999-9999"
            error={errors.phoneNumber}
            isRequired
            {...register('phoneNumber')}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <FieldMask
            label="CPF"
            mask="999.999.999-99"
            error={errors.documentNumber}
            isRequired
            {...register('documentNumber')}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Controller
            name="birthdate"
            control={control}
            render={({ field }) => (
              <FieldDate
                label="Data de nascimento"
                name={field.name}
                selected={field.value}
                onChange={field.onChange}
                error={errors.birthdate}
                isRequired
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <FieldRadioButton
            label="Gênero"
            name="gender"
            control={control}
            radioButtonDirection="row"
            options={genresOptions}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Controller
            name="companyId"
            control={control}
            render={({ field }) => (
              <FieldSelect
                label="Vínculo com laboratório"
                error={errors.companyId as FieldError}
                isLoading={selectCompanyLaboratory.isLoading}
                options={selectCompanyLaboratory.options}
                isRequired
                {...field}
              />
            )}
          />
        </GridItem>
      </Grid>

      <FormFooter isSubmitting={isSubmitting} hasAlert />
    </Flex>
  )
}
